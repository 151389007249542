import { Injectable } from '@angular/core';
import { DestroyableComponent } from '@models/destroyable.component';
import { Subject } from 'rxjs';

@Injectable()
export class StepperService extends DestroyableComponent {
  private check = this.register(new Subject<void>());
  public check$ = this.check.asObservable();

  private isOk = this.register(new Subject<boolean>());
  public isOk$ = this.isOk.asObservable();

  constructor() {
    super();
  }

  public checkCurrentStep(): void {
    this.check.next();
  }

  public goNextIsOk(isOk: boolean): void {
    this.isOk.next(isOk);
  }
}
