import { Injectable } from '@angular/core';
import { DestroyableComponent } from '@models/destroyable.component';
import { Subject } from 'rxjs';

@Injectable()
export class SaveService extends DestroyableComponent {
  private _save = this.register(new Subject<void>());
  public save$ = this._save.asObservable();

  public save(): void {
    this._save.next();
  }
}
