export const titles = {
  tutor: [
    'first-contact.first-contact-additional-tools.titles.tutor.title1',
    'first-contact.first-contact-additional-tools.titles.tutor.title2',
    'first-contact.first-contact-additional-tools.titles.tutor.title3',
  ],
  student: [
    'first-contact.first-contact-additional-tools.titles.student.title1',
    'first-contact.first-contact-additional-tools.titles.student.title2',
    'first-contact.first-contact-additional-tools.titles.student.title3',
  ],
  methodist: [
    'first-contact.first-contact-additional-tools.titles.methodist.title1',
    'first-contact.first-contact-additional-tools.titles.methodist.title2',
    'first-contact.first-contact-additional-tools.titles.methodist.title3',
  ],
  school: [
    'first-contact.first-contact-additional-tools.titles.school.title1',
    'first-contact.first-contact-additional-tools.titles.school.title2',
    'first-contact.first-contact-additional-tools.titles.school.title3',
  ],
};
