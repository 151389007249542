export const markFormGroupTouched = (formGroup) => {
  (Object as any).values(formGroup.controls).forEach((control) => {
    control.markAsTouched();
    control.updateValueAndValidity();
    if (control.controls) {
      markFormGroupTouched(control);
    }
  });
};

export const markFormGroupUnTouched = (formGroup) => {
  (Object as any).values(formGroup.controls).forEach((control) => {
    control.markAsUntouched();
    control.updateValueAndValidity();
    if (control.controls) {
      markFormGroupUnTouched(control);
    }
  });
};

export const markFormGroupDirty = (formGroup) => {
  (Object as any).values(formGroup.controls).forEach((control) => {
    control.markAsDirty();
    control.updateValueAndValidity();
    if (control.controls) {
      markFormGroupDirty(control);
    }
  });
};

export const markFormGroupPristine = (formGroup) => {
  (Object as any).values(formGroup.controls).forEach((control) => {
    control.markAsPristine();
    control.updateValueAndValidity();
    if (control.controls) {
      markFormGroupDirty(control);
    }
  });
};
