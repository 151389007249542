<div class="widget">
  <div class="widget__header" *ngIf="deviceService.isMobile() || deviceService.isTablet()">
    <svg class="widget__icon" (click)="close()">
      <use [attr.xlink:href]="svgSetIcon('chevron-left')"></use>
    </svg>
    {{ 'notifications.title' | translate }}
  </div>

  <div class="modal"
      [class.modal_mobile]="deviceService.isMobile()"
      [class.modal_tablet]="deviceService.isTablet()"
      (mouseenter)="onMouseenter()"
      (mouseleave)="onMouseleave()">

      <div class="modal__header">
        <p class="title">{{ 'notifications.title' | translate }}</p>

        <div class="modal__minimize" (click)="close()">
          <svg width="14" height="2" viewBox="0 0 14 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1.00024H13" stroke="#A4B0C3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>

      </div>

      <div class="modal__content" #scroll>

          <!-- <ng-container *ngFor="let notice of notices.slice().reverse(); trackBy: trackByFn">

            <span class="modal__date">{{ '2020-05-10' | date }}</span>

            <div class="modal__notification">
                <app-notification [notification]="notice"></app-notification>
            </div>

          </ng-container> -->

        <app-no-content-stub *ngIf="!notificationsCount"
          [icon]="'notifications-stub'"
          [text]="'notifications.notifications-stub'">
        </app-no-content-stub>

        <ng-container *ngFor="let date of dates">

            <!-- TODO: refactor date displaying -->
            <span class="modal__date">
              <span *ngIf="getDateAlias(date) as dateAlias; else regularDate">
                {{ dateAlias | translate }}
              </span>
              <ng-template #regularDate>
                {{ convertToRegularDate(date) | dfnsFormat: 'dd MMMM'}}
              </ng-template>
            </span>

            <ng-container *ngFor="let notice of dateBlocks[date]; trackBy: trackByFn">

              <div class="modal__notification">
                <app-notification [notification]="notice"></app-notification>
              </div>

            </ng-container>

          </ng-container>

      </div>
  </div>
</div>
