import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-verification-widget',
  templateUrl: './about-verification-widget.component.html',
  styleUrls: ['./about-verification-widget.component.scss'],
})
export class AboutVerificationWidgetComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
