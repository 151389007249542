import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { OnUiButtonModule } from 'onclass-ui';
import { OnboardingSharedModule } from '../shared/onboarding-shared.module';
import { OnboardingUnknownComponent } from './onboarding-unknown.component';

@NgModule({
  declarations: [OnboardingUnknownComponent],
  imports: [CommonModule, TranslateModule, OnboardingSharedModule, OnUiButtonModule],
  exports: [OnboardingUnknownComponent],
})
export class OnboardingUnknownModule {}
