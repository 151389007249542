export enum ToolbarItemTypeEnum {
  richText,
  image,
  video,
  audio,
  check,
  delete,
}

export interface IVariantPopoverConfig {
  richText?: boolean;
  image?: boolean;
  video?: boolean;
  audio?: boolean;
  check?: boolean;
  delete?: boolean;
}
