import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StickyComponent } from './sticky.component';

@NgModule({
  declarations: [StickyComponent],
  exports: [StickyComponent],
  imports: [CommonModule],
})
export class StickyModule {}
