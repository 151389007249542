import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { FeaturesService } from '@core/services';
import { User, IUserTracker, FeatureEnum } from 'lingo2-models';
import { IListPanelOption, BulletEnum as BE } from '../models';
import { OnboardingStatusesEnum as OSE } from '../onboarding-widget.component';
import { OnboardingWidgetService } from '../onboarding-widget.service';
import { IChecklistOption } from '../shared/onboarding-checklist/onboarding-checklist.component';

const PANEL_OPTIONS: IListPanelOption[] = [
  { bullet: BE.check, label: 'onboarding.panel.top-spot' },
  { bullet: BE.check, label: 'onboarding.panel.free-classes' },
  { bullet: BE.check, label: 'onboarding.panel.safe-deal' },
];

@Component({
  selector: 'app-onboarding-teacher',
  templateUrl: './onboarding-teacher.component.html',
  styleUrls: ['./onboarding-teacher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingTeacherComponent implements OnInit {
  @Input() set me(me: User) {
    this._me = me;
    this.onboardingWidgetService.getCheckOptions(me).then((res) => {
      this.checklistOptions = res.filter((x) => !x.checked);
      this.cdr.detectChanges();
    });
    this.panelOptions = this.onboardingWidgetService.generateListPanelOptions(me, PANEL_OPTIONS);
    this.trackers = me.trackers;
    this.dueDate = me.teacher_review_at;
    this.showMobileQr = this.onboardingWidgetService.isMobileNotUsed(me);
  }

  public status = OSE.teacher;
  public isExpanded$ = this.onboardingWidgetService.isStatusExpanded$;
  public showMobileQr: boolean;
  public checklistOptions: IChecklistOption[];
  public panelOptions: IListPanelOption[];
  public trackers: IUserTracker[];
  public dueDate: Date;
  protected _me: User;

  constructor(
    private onboardingWidgetService: OnboardingWidgetService,
    private features: FeaturesService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {}

  public isTrackersAvailable(): boolean {
    return this.features.isAvailable(FeatureEnum.onboarding_trackers, this._me);
  }
}
