<app-base-notification [base]="base">

  <div [innerHTML]="'notifications.meeting_participant_joined.text' | translate : {'student': user, 'title': title}"></div>

  <on-ui-button class="primary outline"
                [label]="'notifications.meeting_participant_joined.action'"
                [routerLink]="params.meeting_join_url | relativeUrl">
  </on-ui-button>

</app-base-notification>
