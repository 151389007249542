import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClickOutsideModule } from 'ng-click-outside';
import { InViewportModule } from 'ng-in-viewport';
import { OnUiPopoverModule } from 'onclass-ui';
import { AspectRatioModule } from './aspect-ratio-selector/aspect-ratio-selector.module';
import { GridSizeSelectorModule } from './grid-size-selector/grid-size-selector.module';
import { GridToolbarComponent } from './grid-toolbar.component';

@NgModule({
  declarations: [GridToolbarComponent],
  imports: [
    CommonModule,
    InViewportModule,
    ClickOutsideModule,
    OnUiPopoverModule,

    GridSizeSelectorModule,
    AspectRatioModule,
  ],
  exports: [GridToolbarComponent],
})
export class GridToolbarModule {}
