import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgModule, OnInit } from '@angular/core';
import {
  promo_a_config,
  promo_b_config,
  promo_c_config,
  promo_d_config,
} from '@app/core/services/remote-config/constants';
import { DrawerModule } from '@core/components/debug-drawer/drawer.component';
import { AccountService, ContextService, FeaturesService, GeoInfo, PlatformService } from '@core/services';
import { RemoteConfigService } from '@core/services/remote-config/remote-config.service';
import { environment } from '@env/environment';
import { ChangableComponent } from '@models/changable.component';
import { AnyType, IAccountCheck, TenantEnum, User, UserRoleEnum, UserSegmentEnum } from 'lingo2-models';
import { OnUiButtonModule } from 'onclass-ui';
import { of } from 'rxjs';
import { mergeAll, takeUntil, tap } from 'rxjs/operators';

const EXPANDED_KEY = 'APP_DBG_EXP';

@Component({
  selector: 'app-debug-drawer',
  templateUrl: './debug-drawer.component.html',
  styleUrls: ['./debug-drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebugDrawerComponent extends ChangableComponent implements OnInit {
  public tenants = TenantEnum;
  public defaultTenant: TenantEnum;
  public tenantOverride: TenantEnum;
  public opened = false;
  public debug$ = this.contextService.debug$;
  public remoteConfig: AnyType;
  public promo_a_config = promo_a_config;
  public promo_b_config = promo_b_config;
  public promo_c_config = promo_c_config;
  public promo_d_config = promo_d_config;
  public me: User;
  public accountCheck: IAccountCheck;
  public geo: GeoInfo;

  private _expanded = new Map<string, boolean>();

  public constructor(
    private contextService: ContextService,
    private accountService: AccountService,
    private features: FeaturesService,
    private remoteConfigService: RemoteConfigService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  public ngOnInit(): void {
    this.defaultTenant = this.features.resolveTenant();

    this.onBrowserOnly(() => {
      of(this.watchTenantOverrideChanged$, this.watchMe$)
        .pipe(
          mergeAll(),
          tap(() => this.detectChanges()),
          takeUntil(this.destroyed$),
        )
        .subscribe();

      this.loadAccountCheck();

      try {
        JSON.parse(localStorage.getItem(EXPANDED_KEY) || '[]').map((key) => this._expanded.set(key, true));
      } catch (e) {
        // this.errorService.err(e);
      }

      this.accountService
        .getGeo()
        .pipe(
          tap((geo) => {
            this.geo = geo;
            this.detectChanges();
          }),
          takeUntil(this.destroyed$),
        )
        .subscribe();
    });

    this.remoteConfigService.config$
      .pipe(
        tap((config) => {
          this.remoteConfig = config;
          this.detectChanges();
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  public close() {
    this.opened = false;
    this.detectChanges();
  }

  public toggle() {
    this.opened = !this.opened;
    this.detectChanges();
  }

  public isExpanded(key: string): boolean {
    if (this._expanded.has(key)) {
      return this._expanded.get(key);
    } else {
      this._expanded.set(key, false);
      return false;
    }
  }

  public toggleExpand(key: string) {
    const isExpanded = this.isExpanded(key);
    this._expanded.set(key, !isExpanded);
    this.detectChanges();

    const expanded = [];
    this._expanded.forEach((value, _key) => {
      if (value) {
        expanded.push(_key);
      }
    });
    localStorage.setItem(EXPANDED_KEY, JSON.stringify(expanded));
  }

  public setTenantOverride(value: TenantEnum) {
    this.features.overrideTenant(value);
  }

  public get isDeveloper(): boolean {
    return AccountService.hasRole(this.me, UserRoleEnum.developer);
  }

  public get isDevEnv(): boolean {
    return (environment.env || '').startsWith('dev');
  }

  public reloadRemoteConfig() {
    this.remoteConfigService.reload();
  }

  public get roles(): string[] {
    return (this.me?.roles || []).map((r) => UserRoleEnum[r]);
  }

  public get segments(): string[] {
    return (this.me?.segments || []).map((s) => UserSegmentEnum[s.segment]);
  }

  protected get watchTenantOverrideChanged$() {
    return this.features.tenantOverride$.pipe(tap((tenant) => (this.tenantOverride = tenant)));
  }

  protected get watchMe$() {
    return this.contextService.me$.pipe(
      tap((me) => {
        this.me = me;
        this.loadAccountCheck();
      }),
    );
  }

  protected loadAccountCheck(): void {
    this.accountService
      .accountCheck()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((accountCheck) => {
        this.accountCheck = accountCheck;
        this.detectChanges();
      });
  }
}

@NgModule({
  imports: [CommonModule, DrawerModule, OnUiButtonModule],
  declarations: [DebugDrawerComponent],
  exports: [DebugDrawerComponent],
})
export class DebugDrawerModule {}
