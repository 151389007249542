import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-onboarding-progressbar',
  templateUrl: './onboarding-progressbar.component.html',
  styleUrls: ['./onboarding-progressbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingProgressbarComponent implements OnInit {
  @Input() max: number;
  @Input() value: number;
  @Input() rounded: boolean;

  constructor() {}

  ngOnInit() {}

  public get percent(): number {
    if (!this.max || !this.value) {
      return 0;
    }
    return Math.round((100 / this.max) * this.value);
  }
}
