export interface TsAppVersion {
    version: string;
    name: string;
    description?: string;
    versionLong?: string;
    versionDate: string;
    gitCommitHash?: string;
    gitCommitDate?: string;
    gitTag?: string;
};
export const versions: TsAppVersion = {
    version: '33.0.0',
    name: 'lingo2-web',
    versionDate: '2024-08-26T12:06:51.199Z',
    gitCommitHash: 'gc4679e2',
    gitCommitDate: '2024-08-26T12:04:06.000Z',
    versionLong: '33.0.0-gc4679e2',
    gitTag: 'v33.0.0',
};
export default versions;
