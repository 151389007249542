import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AccountService, ContextService, ProfileService } from '@core/services';
import { WebsocketService } from '@core/websocket';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { getMyProfile } from '@store/reducers/profile.reducer';
import {
  TeacherStatusEnum,
  User,
  UserProfile,
  UserProfileVerificationEnum,
  UserProfileVerificationEnum as VE,
  UserStatusEnum,
} from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import {
  combineLatest,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  mergeMap,
  Observable,
  takeUntil,
} from 'rxjs';

export enum OnboardingStatusesEnum {
  guest = 'guest',
  unknown = 'unknown',
  guest_student = 'guest_student',
  student = 'student',
  guest_teacher = 'guest_teacher',
  not_verified = 'not_verified',
  on_moderation = 'on_moderation',
  limited_tutor = 'limited_tutor',
  tutor = 'tutor',
  teacher = 'teacher',
  limited_teacher = 'limited_teacher',
}
@Component({
  selector: 'app-onboarding-widget',
  templateUrl: './onboarding-widget.component.html',
  styleUrls: ['./onboarding-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingWidgetComponent extends ChangableComponent implements OnInit {
  public verification_status: number;
  public verification_sent_at: Date;

  public me$: Observable<User>;
  public profile$: Observable<UserProfile>;

  public me: User;
  public profile: UserProfile;

  public onboardingStatus; // = OnboardingStatusesEnum.on_moderation;
  public states = OnboardingStatusesEnum;

  public isMobile = false;
  public debug$ = this.contextService.debug$;

  constructor(
    private profileService: ProfileService,
    private contextService: ContextService,
    private accountService: AccountService,
    private websocket: WebsocketService,
    private store: Store,
    protected readonly cdr: ChangeDetectorRef,
    public deviceService: DeviceDetectorService,
  ) {
    super(cdr);
    this.isMobile = this.deviceService.isMobile();
  }

  ngOnInit() {
    this.me$ = this.contextService.me$;
    this.profile$ = this.contextService.profile$;

    combineLatest([this.me$, this.profile$])
      .pipe(filter(([me, profile]) => !!me && !!profile))
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([me, profile]: [User, UserProfile]) => {
        this.onboardingStatus = this.getCurrentStatus(me, profile);
        this.detectChanges();
      });

    this.contextService.profile$
      .pipe(
        filter((x) => !!x),
        map((x) => x.verification_status),
        distinctUntilChanged(),
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe((verification_status) => {
        this.verification_status = verification_status || VE.incomplete;
        this.detectChanges();
      });

    // Запрашиваем профиль, только если изменился id пользователя
    this.me$
      .pipe(
        distinctUntilKeyChanged('id'),
        filter((me) => !!me),
        map((me) => {
          this.contextService.updateUser(me);
        }),
        mergeMap(() => this.store.select(getMyProfile)),
        takeUntil(this.destroyed$),
      )
      .subscribe((profile) => {
        this.contextService.updateProfile(profile);
      });
  }

  private getCurrentStatus(me: User, profile: UserProfile): OnboardingStatusesEnum {
    // Guest

    if (me?.status === UserStatusEnum.guest && !AccountService.isAsIfTeacher(me) && !AccountService.isAsIfStudent(me)) {
      return OnboardingStatusesEnum.guest;
    }

    // Unknown

    if (me?.status !== UserStatusEnum.guest && !AccountService.isAsIfTeacher(me) && !AccountService.isAsIfStudent(me)) {
      return OnboardingStatusesEnum.unknown;
    }

    // Student

    if (me?.status === UserStatusEnum.guest && AccountService.isAsIfStudent(me)) {
      return OnboardingStatusesEnum.guest_student;
    }

    if (me?.status !== UserStatusEnum.guest && AccountService.isAsIfStudent(me)) {
      return OnboardingStatusesEnum.student;
    }

    // Teacher

    if (me?.status === UserStatusEnum.guest && AccountService.isAsIfTeacher(me)) {
      return OnboardingStatusesEnum.guest_teacher;
    }

    const notVerifiedStatuses = [UserProfileVerificationEnum.incomplete, UserProfileVerificationEnum.not_verified];
    if (
      me?.status !== UserStatusEnum.guest &&
      AccountService.isAsIfTeacher(me) &&
      (!profile.verification_status || notVerifiedStatuses.includes(profile.verification_status))
    ) {
      return OnboardingStatusesEnum.not_verified;
    }

    const moderationStatuses = [UserProfileVerificationEnum.sent, UserProfileVerificationEnum.resent];
    if (
      me?.status !== UserStatusEnum.guest &&
      AccountService.isAsIfTeacher(me) &&
      moderationStatuses.includes(profile.verification_status)
    ) {
      return OnboardingStatusesEnum.on_moderation;
    }

    if (me.teacher_status === TeacherStatusEnum.limited_tutor) {
      return OnboardingStatusesEnum.limited_tutor;
    }

    if (me.teacher_status === TeacherStatusEnum.tutor) {
      return OnboardingStatusesEnum.tutor;
    }

    if (me.teacher_status === TeacherStatusEnum.teacher) {
      return OnboardingStatusesEnum.teacher;
    }

    if (me.teacher_status === TeacherStatusEnum.limited_teacher) {
      return OnboardingStatusesEnum.limited_teacher;
    }
  }
}
