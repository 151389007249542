import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { loadMyBillingPlan } from '@store/actions/profile.actions';
import { PlansCheckoutWizardDialogModule } from 'lingo2-forms';
import { AccountPlan } from 'lingo2-models';

@Component({
  selector: 'app-payment-wizard-dialog',
  standalone: true,
  imports: [CommonModule, PlansCheckoutWizardDialogModule],
  templateUrl: './payment-wizard-dialog.component.html',
  styleUrls: ['./payment-wizard-dialog.component.scss'],
})
export class PaymentWizardDialogComponent extends ChangableComponent implements OnInit {
  @Input()
  public plan: AccountPlan;

  @Output()
  public closed = new EventEmitter<boolean>();

  constructor(private store: Store, protected readonly cdr: ChangeDetectorRef) {
    super(cdr);
  }

  public ngOnInit(): void {}

  public get checkoutInfo() {
    return {
      model: {
        billing_order_id: this.plan?.details?.billing_order_id,
        billing_charge_id: this.plan?.details?.billing_prolong_charge_id,
      },
    };
  }

  public closeResumePlanModal() {
    this.closed.emit(true);
    this.detectChanges();
  }

  public onSuccessResumePlanModal() {
    this.store.dispatch(loadMyBillingPlan());
    this.closeResumePlanModal();
    this.detectChanges();
  }
}
