import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { User } from 'lingo2-models';
import { IListPanelOption, BulletEnum as BE } from '../models';
import { OnboardingStatusesEnum as OSE } from '../onboarding-widget.component';

import { OnboardingWidgetService } from '../onboarding-widget.service';
import { IChecklistOption } from '../shared/onboarding-checklist/onboarding-checklist.component';

const PANEL_OPTIONS: IListPanelOption[] = [
  { bullet: BE.dot, label: 'onboarding.panel.host-private-classes' },
  { bullet: BE.dot, label: 'onboarding.panel.feature-profile' },
  { bullet: BE.dot, label: 'onboarding.panel.host-group-classes' },
  { bullet: BE.dot, label: 'onboarding.panel.get-free-support' },
  { bullet: BE.dot, label: 'onboarding.panel.get-payments' },
];

@Component({
  selector: 'app-onboarding-not-verified',
  templateUrl: './onboarding-not-verified.component.html',
  styleUrls: ['./onboarding-not-verified.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingNotVerifiedComponent implements OnInit {
  @Input() set me(me: User) {
    this.checklistOptions = this.generateChecklistOptions(me);
    this.checkedCount = this.checklistOptions.filter((x) => x.checked).length;
  }

  public status = OSE.not_verified;
  public isExpanded$ = this.onboardingWidgetService.isStatusExpanded$;

  public checklistOptions: IChecklistOption[];
  public panelOptions = PANEL_OPTIONS;

  public checkedCount: number;

  constructor(private onboardingWidgetService: OnboardingWidgetService) {}

  ngOnInit() {}

  private generateChecklistOptions(me: User): IChecklistOption[] {
    return [
      { label: 'onboarding.checklist.sign-in', checked: true },
      { label: 'onboarding.checklist.name', route: '/profile', checked: !!(me.first_name && me.last_name) },
      { label: 'onboarding.checklist.photo', route: '/profile', checked: !!me.userpic_id },
      { label: 'onboarding.checklist.verify', route: '/profile' },
    ];
  }
}
