import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { IconsService } from '@core/services';
import { BulletEnum, IListPanelOption } from '../../models';
import { OnboardingWidgetService } from '../../onboarding-widget.service';

@Component({
  selector: 'app-onboarding-list-panel',
  templateUrl: './onboarding-list-panel.component.html',
  styleUrls: ['./onboarding-list-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingListPanelComponent implements OnInit {
  @Input() title: string;
  @Input() options: IListPanelOption[];

  public svgsetIcon = IconsService.svgsetIconUrl;
  public isExpanded$ = this.onboardingWidgetService.isPanelExpanded$;
  public bullets = BulletEnum;

  constructor(private onboardingWidgetService: OnboardingWidgetService) {}

  ngOnInit() {}

  public trackByFn(option: IListPanelOption): string {
    return option.label;
  }

  toggleExpand(): void {
    this.onboardingWidgetService.togglePanelExpanded();
  }
}
