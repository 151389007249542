<div [formGroup]="form" class="control"
  [class.stretchable]="config.isStretchable"
  [appTaskAnswerVariantPopover]="config"
  (selected)="onVariantTypeSelected($event)"
  (checked)="onCheckIsCorrect()"
  (deleted)="onVariantDeleted()">

<!-- <div [formGroup]="form" class="control">  -->

  <ng-container [ngSwitch]="selectedVariantType">

    <ng-container *ngSwitchCase="variantTypes.image">
      <app-slide-image formControlName="image_id"
        [isError]="isError"
        [isModalOpened]="isModalOpened"
        (modalOpened)="onModalOpened($event)">
      </app-slide-image>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <app-slide-rich-text
        formControlName="text"
        class="text"
        [placeholder]="placeholder"
        [isError]="isError" [fitText]="fitText"
        [hideAuthFocus]="true"
        [isModalOpened]="isModalOpened"
        (modalOpened)="onModalOpened($event)"
        [isCentered]="config.isCentered"
        [isRichText]="isRichText"
        (changed)="onChanged()"
        (blured)="onBlur()">
      </app-slide-rich-text>
    </ng-container>

  </ng-container>

</div>
