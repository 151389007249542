<div class="dropzone dropzone-container"
  [class.has-error]="isError"
  [class.uploading]="isUploading"
  [dropzone]="dropzoneConfig"
  [disabled]="isDropzoneDisabled"
  (init)="onUploadInit($event)"
  (addedFile)="onAddedFile($event)"
  (sending)="onUploadStart($event)"
  (uploadProgress)="onUploadProgress($event)"
  (error)="onUploadError($event)"
  (success)="onUploadSuccess($event)">
  <div *ngIf="isDropzoneDisabled" class="close-btn" (click)="onCloseSizeAlert()">
    <svg width="16" height="16" fill="#A4B0C3" viewBox="0 0 15 16">
      <use [attr.xlink:href]="svgsetIcon('close')"></use>
    </svg>
  </div>
  <div class="dz-default dz-message">
    <div *ngIf="!isUploading && !isDropzoneDisabled" class="placeholder">
      <svg class="placeholder-icon">
        <use [attr.xlink:href]="fileTypeConfig.icon"></use>
      </svg>
      <div class="placeholder-text">
        <div>{{ fileTypeConfig.placeholder | translate }}</div>
        <div class="placeholder-link" (click)="showMediaModal($event)">{{ 'constructor2.dropzone.placeholder2' | translate }}</div>
      </div>
    </div>
    <app-file-loader *ngIf="isUploading" [percent]="percent"></app-file-loader>
    <div *ngIf="isDropzoneDisabled" class="size-alert">
      <svg width="50" height="50" viewBox="0 0 50 50">
        <use [attr.xlink:href]="svgsetIcon('media-file')"></use>
      </svg>
      <span class="title-message">{{ 'constructor2.dropzone.size-file-title' | translate }}</span>
      <span class="message">{{ 'constructor2.dropzone.size-file-message' | translate }}</span>
    </div>
  </div>
</div>

<on-ui-progress-bar [value]="percent"></on-ui-progress-bar>

<app-media-file-selector
  *ngIf="isMediaModalOpen"
  type="image"
  [purpose]="filePurpose"
  (changed)="setImage($event)"
  (closed)="closeMediaModal()"
></app-media-file-selector>
<!-- FIle
<pre>
  {{ file | json }}
</pre>

Dropzone Config
<pre>
  {{ dropzoneConfig | json }}
</pre> -->
