import { Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NotificationsService } from '@app/notifications/notifications.service';
import { HeaderComponent } from '@core/header/header.component';
import { environment } from '@env/environment';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { getLastHttpError } from '@store/reducers/general.reducer';
import { expandWindow, isChatWindowExpanded } from 'lingo2-chat-app';
import { EContentOrder, FeatureEnum, Meeting, User, UserStatusEnum } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { takeUntil } from 'rxjs/operators';
import {
  ContextService,
  CrispChatService,
  FeaturesService,
  MeetingsService,
  PlatformService,
  ScreenService,
  UtilsService,
} from '../../services';

@Component({
  selector: 'app-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent extends ChangableComponent implements OnInit, AfterViewInit {
  public isSmallScreen = this.screenService.isSmallScreen;
  public isWideScreen = this.screenService.isWideScreen;
  public isFooterVisible = true;
  public isChatVisible = false;
  public isNotificationsVisible = false;

  public isHttpErrorsVisible = false;
  public httpErrorsString: string;
  public stage = environment.env === 'staging' || environment.env === 'dev';

  public alternativeChatState = false;
  public unreadMessageCount: number;
  public meeting: Meeting;
  public showMeetBtn = false;
  public isChatExpanded = false;
  public fileFromChat = null;

  @ViewChild('app_header', { read: ViewContainerRef }) private headerContainerRef: ViewContainerRef;
  private headerComponentRef: ComponentRef<HeaderComponent>;
  private me: User;

  constructor(
    private contextService: ContextService,
    private screenService: ScreenService,
    private location: Location,
    private features: FeaturesService,
    public crispChat: CrispChatService,
    public utilsService: UtilsService,
    private readonly store: Store,
    private router: Router,
    public deviceService: DeviceDetectorService,
    public meetingsService: MeetingsService,
    public notificationsService: NotificationsService,
    protected readonly platform: PlatformService,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super(cdr, platform);
  }

  public ngOnInit() {
    this.screenService.width$.pipe(takeUntil(this.destroyed$)).subscribe((width) => {
      this.isSmallScreen = this.screenService.isSmallScreen;
      this.isWideScreen = this.screenService.isWideScreen;
      this.isFooterVisible = width < 870 || this.deviceService.isMobile();
    });

    this.store
      .select(isChatWindowExpanded)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isChatExpanded) => {
        this.isChatExpanded = isChatExpanded;
        this.detectChanges();
      });

    if (this.isBrowser) {
      this.contextService.me$.pipe(takeUntil(this.destroyed$)).subscribe((me) => {
        this.me = me;
        this.isChatVisible = me && me?.status !== UserStatusEnum.guest;
        this.isNotificationsVisible = me && me?.status !== UserStatusEnum.guest;
        this.detectChanges();
      });

      this.router.events.pipe(takeUntil(this.destroyed$)).subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (event.url.split('/')[1] !== 'u') {
            document.scrollingElement.scrollTop = 0;
          }
        }
      });

      this.store
        .select(getLastHttpError)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((error) => {
          if (error) {
            this.isHttpErrorsVisible = true;
            this.httpErrorsString = error.message;
            this.detectChanges();
          }
        });
    }

    this.meetingsService
      .getMeetings(
        {
          upcoming: true, // те, в которых мне предстоит заниматься
          order: EContentOrder.date_asc, // сортировка по возрастанию дат
        },
        { page: 1, pageSize: 1 },
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        this.meeting = res.results.map((m) => new Meeting(m))[0];
        this.detectChanges();
      });

    this.features.tenant$.pipe(takeUntil(this.destroyed$)).subscribe(() => this.detectChanges());

    this.showMeetBtn = ['main', 'lessons', 'teachers', 'study', 'feed', 'events'].includes(
      this.location.path().split('/')[1],
    );
  }

  public ngAfterViewInit() {
    void this.loadEmptyComponent().catch();
  }

  // TODO ленивая загрузка компонента
  private async loadEmptyComponent(): Promise<void> {
    if (!this.headerContainerRef) {
      return;
    }
    const { HeaderComponent } = await import('../../../core/header/header.component');
    this.headerContainerRef?.clear();
    this.headerComponentRef = this.headerContainerRef?.createComponent(HeaderComponent);
    this.headerComponentRef.changeDetectorRef.detectChanges();
  }

  public get isAvailableChat(): boolean {
    return this.features.isAvailable(FeatureEnum.chat) && this.isBrowser;
  }

  public get isAvailableNotifications(): boolean {
    return this.features.isAvailable(FeatureEnum.notifications) && this.isBrowser;
  }

  public get isAvailableHttpErrors(): boolean {
    return this.features.isAvailable(FeatureEnum.httpErrors);
  }

  public onChatWindowStatusChanged(status: boolean) {
    this.alternativeChatState = status;
    this.screenService.setBodyFixed(status);
  }

  public toggleChatWindow() {
    this.notificationsService.closeWidget();
    this.store.dispatch(expandWindow({ expand: !this.isChatExpanded }));
    this.detectChanges();
  }

  public openProfile(e) {
    this.router.navigateByUrl(`/u/${e.slug}`).then();
    if (!this.deviceService.isDesktop()) {
      this.setTimeout(() => {
        this.onChatWindowStatusChanged(false);
        this.store.dispatch(expandWindow({ expand: false }));
      }, 200);
    }
  }

  public onMouseenter() {
    this.screenService.setBodyFixed(true);
  }

  public onMouseleave() {
    this.screenService.setBodyFixed(false);
  }

  public set unreadCount(count) {
    this.unreadMessageCount = count;
    this.detectChanges();
  }

  public get unreadCount() {
    return this.unreadMessageCount;
  }

  public openFileFromChat(event) {
    this.store.dispatch(expandWindow({ expand: false }));
    this.fileFromChat = event;
  }

  public closeFileFromChat() {
    this.store.dispatch(expandWindow({ expand: true }));
    this.fileFromChat = null;
  }
}
