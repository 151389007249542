<section class="first-contact-tools" [class.mobile]="deviceService.isMobile()">
  <h3 class="title">{{ 'first-contact.first-contact-tools.title' | translate }}</h3>
  <div class="video-wrap">
    <video class="video" autoplay="autoplay" loop playsinline>
      <source src="/assets/images/landings/common/intro-video.mp4" type="video/mp4" />
    </video>
  </div>
  <p class="text" [innerHTML]="text | translate"></p>

  <ng-container *ngIf="isDisplay">
    <div class="action">
      <on-ui-button label="first-contact.first-contact-tools.action"
                    class="third"
                    (onclick)="onCreateLesson()">
      </on-ui-button>
    </div>
  </ng-container>
</section>
