import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createPagedResultsFromResponse } from '@core/helpers/request';
import { environment } from '@env/environment';
import { DestroyableComponent } from '@models/destroyable.component';
import {
  BillingOperation,
  Collection,
  CollectionDetailsType,
  CollectionItem,
  CollectionItemDetailsType,
  CollectionLicenseEnum,
  IFindCollectionFilter,
  IPagedResults,
  IPagination,
  IStatusResponse,
  otherSubjectId,
} from 'lingo2-models';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IValidationMessages } from './collection.validator';

export const DEFAULT_COLLECTIONS_DETAILS: CollectionDetailsType[] = [
  'id',
  'slug',
  'subject',
  'title',
  'excerpt',
  'type',
  'category',
  'multi_cover:md',
  'type',
  'changed_at',
  'author:sm',
  'author_id',
  'discount',
  'price_tiers',
  'access',
  'stats',
  'visit_info',
  'description',
  'language_id',
  'subject_id',
  'cover_id',
  'valid',
  'moderation_status',
  'options',
];

export interface ICollectionPublishResolution {
  resolution: 'incomplete' | 'ok';

  warnings?: Array<IValidationMessages<Collection>>;

  collection?: Collection;
}

export interface IContentViewCounter {
  result: boolean;
  view_count: number;
}

@Injectable({
  providedIn: 'root',
})
export class CollectionsService extends DestroyableComponent {
  public constructor(private http: HttpClient) {
    super();
  }

  public collectionFullTitle(item: Collection): string {
    if (!item) {
      return '';
    }

    let subject = '';
    if ('subject' in item) {
      if (+item.subject.id === otherSubjectId) {
        subject = item.subject_other_name || '';
      } else {
        subject = item.subject.title || '';
      }
    }

    const titleParts = [];
    if (subject) {
      titleParts.push(`${subject}, `);
    }
    titleParts.push(item.title);
    if (item.excerpt) {
      titleParts.push(`, ${item.excerpt}`);
    }

    return titleParts.join('');
  }

  /**
   * Поиск записей по фильтру
   */
  public findCollections(
    filter: Partial<IFindCollectionFilter>,
    pagination: IPagination,
    details: CollectionDetailsType[],
  ): Observable<IPagedResults<Collection[]>> {
    const url = `${environment.content_url}/collections`;
    const params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter', JSON.stringify(filter))
      .set('details', JSON.stringify(details));
    return this.http.get<Collection[]>(url, { params, observe: 'response' }).pipe(
      map(this.handleCollectionsResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Поиск записи по SLUG
   */
  public getBySlug(slug: string, details: CollectionDetailsType[]): Observable<Collection> {
    const _slug = slug.split('-').reverse()[0];
    const url = `${environment.content_url}/collection/slug/${_slug}`;
    const params = new HttpParams().set('details', JSON.stringify(details));
    return this.http
      .get<Collection>(url, { params, observe: 'response' })
      .pipe(map(this.handleCollectionResponse), catchError(this.handleError));
  }

  /**
   * Поиск записи по ID
   */
  public getById(id: string, details?: CollectionDetailsType[]): Observable<Collection> {
    const url = `${environment.content_url}/collection/${id}`;
    const params = new HttpParams().set('details', JSON.stringify(details));
    return this.http.get<Collection>(url, { params, observe: 'response' }).pipe(
      map(this.handleCollectionResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Поиск записи по ID для редактирования
   */
  public getForEdit(id: string): Observable<Collection> {
    const url = `${environment.content_url}/collection/${id}/edit`;
    const params = new HttpParams().set('details', JSON.stringify(DEFAULT_COLLECTIONS_DETAILS));
    return this.http.get<Collection>(url, { params, observe: 'response' }).pipe(
      map(this.handleCollectionResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Создать запись
   */
  public createCollection(values: Partial<Collection>): Observable<Collection> {
    const url = `${environment.content_url}/collection`;
    return this.http.post<Collection>(url, values, { observe: 'response' }).pipe(
      map(this.handleCollectionResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Обновить запись
   */
  public updateCollection(id: string, values: Partial<Collection>): Observable<Collection> {
    const url = `${environment.content_url}/collection/${id}`;
    return this.http.put<Collection>(url, values, { observe: 'response' }).pipe(
      map(this.handleCollectionResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Проверить на возможность публикации
   */
  public checkPublish(id: string): Observable<ICollectionPublishResolution> {
    const url = `${environment.content_url}/collection/${id}/publish/check`;
    return this.http.post<ICollectionPublishResolution>(url, {}, { observe: 'response' }).pipe(
      map((response) => {
        const result = response.body;
        if (result.collection) {
          result.collection = new Collection(result.collection);
        }
        return result;
      }),
      // catchError(this.handleError),
    );
  }

  /**
   * Опубликовать (открыть)
   */
  public publish(id: string): Observable<ICollectionPublishResolution> {
    const url = `${environment.content_url}/collection/${id}/publish`;
    return this.http.post<ICollectionPublishResolution>(url, {}, { observe: 'response' }).pipe(
      map((response) => {
        const result = response.body;
        if (result.collection) {
          result.collection = new Collection(result.collection);
        }
        return result;
      }),
      // catchError(this.handleError),
    );
  }

  /**
   * Снять с публикации (закрыть)
   */
  public unPublish(id: string): Observable<Collection> {
    const url = `${environment.content_url}/collection/${id}/unpublish`;
    return this.http.post<Collection>(url, {}, { observe: 'response' }).pipe(
      map(this.handleCollectionResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Отправить запись в архив
   */
  public archive(id: string): Observable<Collection> {
    const url = `${environment.content_url}/collection/${id}`;
    return this.http.delete<Collection>(url, { observe: 'response' }).pipe(
      map(this.handleCollectionResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Восстановить архивную запись
   */
  public restore(id: string): Observable<Collection> {
    const url = `${environment.content_url}/collection/${id}/restore`;
    return this.http.put<Collection>(url, {}, { observe: 'response' }).pipe(
      map(this.handleCollectionResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Окончательно удалить запись
   */
  public remove(id: string): Observable<Collection> {
    const url = `${environment.content_url}/collection/${id}/confirm`;
    return this.http.delete<Collection>(url, { observe: 'response' }).pipe(
      map(this.handleCollectionResponse),
      // catchError(this.handleError),
    );
  }

  /** */
  public purchase(id: string, license: CollectionLicenseEnum): Observable<BillingOperation> {
    const url = `${environment.content_url}/collection/${id}/purchase`;
    return this.http.post<BillingOperation>(url, { license }, { observe: 'body' });
  }

  public getItemsById(id: string, details: CollectionItemDetailsType[]): Observable<IPagedResults<CollectionItem[]>> {
    const url = `${environment.content_url}/collection/${id}/items`;
    const params = new HttpParams().set('details', JSON.stringify(details));
    return this.http.get<CollectionItem[]>(url, { params, observe: 'response' }).pipe(
      map(this.handleCollectionItemsResponse),
      // catchError(this.handleError),
    );
  }

  public addItems(id: string, items: CollectionItem[]): Observable<CollectionItem[]> {
    const url = `${environment.content_url}/collection/${id}/item`;
    return this.http.post<CollectionItem[]>(url, { items }, { observe: 'response' }).pipe(
      map((response) => response.body),
      // map(this.handleCollectionItemsResponse),
      // catchError(this.handleError),
    );
  }

  public updateItems(id: string, items: CollectionItem[]): Observable<CollectionItem[]> {
    const url = `${environment.content_url}/collection/${id}/items`;
    return this.http.put<CollectionItem[]>(url, { items }, { observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  public removeItem(id: string, item_id: string): Observable<any> {
    const url = `${environment.content_url}/collection/${id}/item/${item_id}`;
    return this.http.delete<any>(url, { observe: 'body' });
    // .pipe(
    //   map((response) => response.body),
    //   // map(this.handleCollectionItemsResponse),
    //   // catchError(this.handleError),
    // )
  }

  /**
   * Добавить в закладки
   */
  public addBookmark(id: string): Observable<IStatusResponse> {
    const url = `${environment.content_url}/collection/${id}/bookmark`;
    return this.http.post<IStatusResponse>(url, {}, { observe: 'body' });
  }

  /**
   * Удалить из закладок
   */
  public removeBookmark(id: string): Observable<IStatusResponse> {
    const url = `${environment.content_url}/collection/${id}/bookmark`;
    return this.http.delete<IStatusResponse>(url, { observe: 'body' });
  }

  /**
   * Добавить просмотр
   */
  public addView(id: string): Observable<IContentViewCounter> {
    const url = `${environment.content_url}/collection/${id}/view`;
    return this.http.post<IContentViewCounter>(url, {}, { observe: 'body' });
  }

  private handleCollectionResponse(response: HttpResponse<Collection>): Collection {
    return new Collection(response.body);
  }

  private handleCollectionsResponse(response: HttpResponse<Collection[]>): IPagedResults<Collection[]> {
    return createPagedResultsFromResponse(response, (values) => new Collection(values));
  }

  private handleCollectionItemsResponse(response: HttpResponse<CollectionItem[]>): IPagedResults<CollectionItem[]> {
    return createPagedResultsFromResponse(response, (values) => new CollectionItem(values));
  }

  private handleError(error: HttpErrorResponse) {
    console.error('CollectionsService:server error:', error);
    if (error.error instanceof Error) {
      const errMessage = error.error.message;
      return throwError(errMessage);
      // Use the following instead if using lite-server
      // return throwError(err.text() || 'backend server error');
    }
    return throwError(error || 'Node.js server error');
  }
}
