import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TaskAnswerVariantPopoverModule } from '../../task-answer-variant-popover/task-answer-variant-popover.module';
import { SlideRichTextModule } from '../slide-rich-text/slide-rich-text.module';
import { SlideQuestionComponent } from './slide-question.component';

@NgModule({
  declarations: [SlideQuestionComponent],
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, SlideRichTextModule, TaskAnswerVariantPopoverModule],
  exports: [SlideQuestionComponent],
})
export class SlideQuestionModule {}
