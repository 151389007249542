<app-base-notification [base]="base">

  <div [innerHTML]="'notifications.profile_declined.text' | translate"></div>

  <on-ui-button class="primary"
                [label]="'notifications.profile_declined.action'"
                [routerLink]="'/profile'">
  </on-ui-button>

</app-base-notification>
