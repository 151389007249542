import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { OnUiButtonModule } from 'onclass-ui';
import { OnboardingSharedModule } from '../shared/onboarding-shared.module';
import { OnboardingGuestStudentComponent } from './onboarding-guest-student.component';

@NgModule({
  declarations: [OnboardingGuestStudentComponent],
  imports: [CommonModule, TranslateModule, OnboardingSharedModule, OnUiButtonModule],
  exports: [OnboardingGuestStudentComponent],
})
export class OnboardingGuestStudentModule {}
