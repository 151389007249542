import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PaymentWizardDialogComponent } from '@app/account/finance-page/components/payment-wizard-dialog/payment-wizard-dialog.component';
import { FinanceCardsPageModule } from '@app/account/finance-page/finance-cards-page/finance-cards-page.module';
import { WidgetWelcomeModule } from '@app/library/panes/widgets/widget-welcome/widget-welcome.component';
import { NotificationsModule } from '@app/notifications/notifications.module';
import { BannerPlanComponent } from '@core/components/banner-plan/banner-plan.component';
import { ButtonFabModule } from '@core/components/button-fab/button-fab.module';
import { MeetingFormWrapperModule } from '@core/components/meeting-form-wrapper/meeting-form-wrapper.module';
import { MobileModalModule } from '@core/components/mobile-modal/mobile-modal.module';
import { ModalModule } from '@core/components/modal/modal.module';
import { NearestMeetBtnModule } from '@core/components/nearest-meet-btn/nearest-meet-btn.module';
import { RedPointComponent } from '@core/components/red-points/red-point/red-point.component';
import { RedPointDirective } from '@core/components/red-points/red-point/red-point.directive';
import { RedPointsComponent } from '@core/components/red-points/red-points.component';
import { HeaderComponent } from '@core/header/header.component';
import { TranslateModule } from '@ngx-translate/core';
import { HttpErrorsModule } from '@shared/http-errors';
import { ChatAppModule } from 'lingo2-chat-app';
import { IconModule } from 'lingo2-forms';
import { ClickOutsideModule } from 'ng-click-outside';

// внешние модули
import { OnUiCoverModule, OnUiSpriteModule } from 'onclass-ui';
import { CurrencySelectorModule } from '../../components/currency-selector/currency-selector.module';
import { LanguageSelectorModule } from '../../components/language-selector/language-selector.module';

// компоненты
import { FooterComponent } from './footer/footer.component';
import { MainLayoutComponent } from './main-layout.component';
import { LeftSidebarComponent } from './sidebar/left-sidebar.component';
import { RightSidebarComponent } from './sidebar/right-sidebar.component';

// сервисы
import { SidebarService } from './sidebar/sidebar.service';

@NgModule({
  declarations: [FooterComponent, LeftSidebarComponent, RightSidebarComponent, MainLayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    IconModule,
    TranslateModule,
    LanguageSelectorModule,
    CurrencySelectorModule,
    ClickOutsideModule,
    NotificationsModule,
    ChatAppModule,
    ButtonFabModule,
    NearestMeetBtnModule,
    FormsModule,
    OnUiSpriteModule,
    RedPointsComponent,
    RedPointComponent,
    RedPointDirective,
    BannerPlanComponent,
    HttpErrorsModule,
    HeaderComponent,
    OnUiCoverModule,
    MeetingFormWrapperModule,
    ModalModule,
    MobileModalModule,
    WidgetWelcomeModule,
    PaymentWizardDialogComponent,
    FinanceCardsPageModule,
  ],
  exports: [LeftSidebarComponent, RightSidebarComponent, MainLayoutComponent, FooterComponent],
  providers: [SidebarService],
})
export class MainLayoutModule {}
