import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Injector, NgModule } from '@angular/core';
import { FirstContactModule } from '@app/first-contact/first-contact.module';
import { MobileModalModule } from '@core/components/mobile-modal/mobile-modal.module';
import { ModalModule } from '@core/components/modal/modal.module';
import { TranslateModule } from '@ngx-translate/core';
import { User } from 'lingo2-models';
import { OnUiButtonModule } from 'onclass-ui';
import { AbstractPane } from '../../models/abstract-pane';

@Component({
  selector: 'app-widget-welcome',
  templateUrl: './widget-welcome.component.html',
  styleUrls: ['./widget-welcome.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetWelcomeComponent extends AbstractPane {
  protected me: User;
  public openModal = false;

  constructor(protected readonly inject: Injector) {
    super(inject);
  }

  public onToggleModal() {
    this.openModal = !this.openModal;
  }

  protected onContentLoaded(viaQuery?: boolean): void {
    this.isVisible = true;
  }
}

@NgModule({
  declarations: [WidgetWelcomeComponent],
  imports: [CommonModule, TranslateModule, ModalModule, FirstContactModule, OnUiButtonModule, MobileModalModule],
  exports: [WidgetWelcomeComponent],
})
export class WidgetWelcomeModule {}
