import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { SpinnersModule } from 'lingo2-forms';
import { PaymentWidgetBillingModule } from '../payment-widget-billing/payment-widget-billing.module';
import { PaymentWidgetMerchantProcessingComponent } from './payment-widget-merchant-processing.component';
// import { PaymentWidgetMerchantProcessingService } from './payment-widget-merchant-processing.service';

// TODO Convert to DynamicModule with config
@NgModule({
  declarations: [PaymentWidgetMerchantProcessingComponent],
  imports: [CommonModule, SpinnersModule, TranslateModule, PaymentWidgetBillingModule],
  providers: [
    // PaymentWidgetMerchantProcessingService
  ],
  exports: [PaymentWidgetMerchantProcessingComponent],
})
export class PaymentWidgetMerchantProcessingModule {}
