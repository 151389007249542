import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, Input } from '@angular/core';
import { PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { ERedPointsEvents } from '@store/models';
import { getRedPoints } from '@store/reducers/profile.reducer';
import { filter, takeUntil } from 'rxjs';

@Directive({
  selector: '[appRedPoint]',
  standalone: true,
})
export class RedPointDirective extends ChangableComponent implements AfterViewInit {
  @Input() set event(event: ERedPointsEvents) {
    this._event = event;
  }
  public visible: boolean;
  private _event: ERedPointsEvents;

  constructor(
    private el: ElementRef,
    private store: Store,
    protected cdr: ChangeDetectorRef,
    protected platform: PlatformService,
  ) {
    super(cdr, platform);
    this.el.nativeElement.style.display = 'none';
  }

  ngAfterViewInit() {
    this.store
      .select(getRedPoints)
      .pipe(
        filter((redPoints) => redPoints[this._event] !== undefined),
        takeUntil(this.destroyed$),
      )
      .subscribe((res) => {
        this.visible = res[this._event];
        this.el.nativeElement.style.display = this.visible ? 'block' : 'none';
        this.detectChanges();
      });
  }
}
