import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ImageCropModule } from '@app/collections-constructor/components/image-crop/image-crop.module';
import { MediaFileSelectorModule } from '@core/components/media-file-selector/media-file-selector.module';
import { SpinnersModule } from '@core/components/spinners/spinners.module';
import { TranslateModule } from '@ngx-translate/core';
import { DropzoneModule } from 'ngx-dropzone-wrapper';

import { ImageUploaderComponent } from './image-uploader.component';

@NgModule({
  declarations: [ImageUploaderComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    DropzoneModule,
    SpinnersModule,
    MediaFileSelectorModule,
    ImageCropModule,
  ],
  exports: [ImageUploaderComponent],
})
export class ImageUploaderModule {}
