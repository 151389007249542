export const titles = {
  tutor: '',
  student: 'first-contact.meeting-types.titles.student',
  methodist: '',
  school: 'first-contact.meeting-types.titles.school',
};

const tutorList = [
  {
    type: 'single',
    title: 'first-contact.meeting-types.tutor-list.title1',
    text: 'first-contact.meeting-types.tutor-list.text1',
  },
  {
    type: 'regular',
    title: 'first-contact.meeting-types.tutor-list.title2',
    text: 'first-contact.meeting-types.tutor-list.text2',
  },
  {
    type: 'course',
    title: 'first-contact.meeting-types.tutor-list.title3',
    text: 'first-contact.meeting-types.tutor-list.text3',
  },
  {
    type: 'mini-course',
    title: 'first-contact.meeting-types.tutor-list.title4',
    text: 'first-contact.meeting-types.tutor-list.text4',
  },
  {
    type: 'webinar',
    title: 'first-contact.meeting-types.tutor-list.title5',
    text: 'first-contact.meeting-types.tutor-list.text5',
  },
  {
    type: 'stream',
    title: 'first-contact.meeting-types.tutor-list.title6',
    text: 'first-contact.meeting-types.tutor-list.text6',
  },
];

const studentList = [
  {
    type: 'group',
    title: 'first-contact.meeting-types.student-list.title1',
    text: 'first-contact.meeting-types.student-list.text1',
  },
  {
    type: 'single',
    title: 'first-contact.meeting-types.student-list.title2',
    text: 'first-contact.meeting-types.student-list.text2',
  },
  {
    type: 'course',
    title: 'first-contact.meeting-types.student-list.title3',
    text: 'first-contact.meeting-types.student-list.text3',
  },
  {
    type: 'individual',
    title: 'first-contact.meeting-types.student-list.title4',
    text: 'first-contact.meeting-types.student-list.text4',
  },
  {
    type: 'regular',
    title: 'first-contact.meeting-types.student-list.title5',
    text: 'first-contact.meeting-types.student-list.text5',
  },
  {
    type: 'mini-course',
    title: 'first-contact.meeting-types.student-list.title6',
    text: 'first-contact.meeting-types.student-list.text6',
  },
];

const methodistList = [
  {
    type: 'card',
    title: 'first-contact.meeting-types.methodist-list.title1',
    text: 'first-contact.meeting-types.methodist-list.text1',
  },
  {
    type: 'secure',
    title: 'first-contact.meeting-types.methodist-list.title2',
    text: 'first-contact.meeting-types.methodist-list.text2',
  },
  {
    type: 'promotion',
    title: 'first-contact.meeting-types.methodist-list.title3',
    text: 'first-contact.meeting-types.methodist-list.text3',
  },
];

const schoolList = [
  {
    type: 'lessons',
    title: 'first-contact.meeting-types.school-list.title1',
    text: 'first-contact.meeting-types.school-list.text1',
  },
  {
    type: 'collections',
    title: 'first-contact.meeting-types.school-list.title2',
    text: 'first-contact.meeting-types.school-list.text2',
  },
  {
    type: 'webinars',
    title: 'first-contact.meeting-types.school-list.title3',
    text: 'first-contact.meeting-types.school-list.text3',
  },
  {
    type: 'interactive',
    title: 'first-contact.meeting-types.school-list.title4',
    text: 'first-contact.meeting-types.school-list.text4',
  },
  {
    type: 'hometasks',
    title: 'first-contact.meeting-types.school-list.title5',
    text: 'first-contact.meeting-types.school-list.text5',
  },
  {
    type: 'courses',
    title: 'first-contact.meeting-types.school-list.title6',
    text: 'first-contact.meeting-types.school-list.text6',
  },
];

export const meetingsTypesList = {
  tutor: tutorList,
  student: studentList,
  methodist: methodistList,
  school: schoolList,
};
