import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { EventActionEnum, EventCategoryEnum } from '@app/core/services/analytics';
import { AnalyticsService, FeaturesService, PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { DeviceDetectorService } from 'ngx-device-detector';

export enum UserRoles {
  tutor = 'tutor',
  student = 'student',
  methodist = 'methodist',
  school = 'school',
}

@Component({
  selector: 'app-first-contact',
  templateUrl: './first-contact.component.html',
  styleUrls: ['./first-contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirstContactComponent extends ChangableComponent implements OnInit {
  public selectedRole: UserRoles = UserRoles.student;

  public constructor(
    public deviceService: DeviceDetectorService,
    protected analytics: AnalyticsService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform?: PlatformService,
  ) {
    super(cdr, platform);
  }

  public ngOnInit(): void {
    this.analytics.event(EventActionEnum.view_first_contact, EventCategoryEnum.engagement);
  }

  public onSelectedRole(event: UserRoles) {
    this.selectedRole = event;
    this.markForCheck();
  }

  public get isLimitedVersion() {
    return FeaturesService.isLimitedVersion;
  }
}
