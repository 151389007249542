import { Injectable } from '@angular/core';
import { MeetingsService } from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom, filter } from 'rxjs/operators';

import { getClassroom } from '../classroom/classroom.selectors';
import * as MeetingsAction from './meetings.actions';
import {
  getClassroomMeetingsDetails,
  getClassroomMeetingsFilter,
  getClassroomMeetingsPagination,
} from './meetings.selectors';

@Injectable()
export class ClassroomMeetingsEffects {
  constructor(private actions$: Actions, private meetingsService: MeetingsService, private store: Store) {}

  loadClassroomMeetings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingsAction.loadClassroomMeetings),
      switchMap(() => this.store.select(getClassroom)),
      filter((x) => !!x),
      map((x) => x.id),
      withLatestFrom(this.store.select(getClassroomMeetingsDetails)),
      withLatestFrom(this.store.select(getClassroomMeetingsFilter)),
      withLatestFrom(this.store.select(getClassroomMeetingsPagination)),
      switchMap(([[[classroom_id, _details], _filter], _pagination]) => {
        _filter = { ...filter, classroom_id };
        return this.meetingsService.getMeetings(_filter, _pagination, _details).pipe(
          map((response) => MeetingsAction.loadClassroomMeetingsSuccess({ response })),
          catchError((error: Error) => of(MeetingsAction.requestFail({ error: error.message }))),
        );
      }),
    ),
  );

  loadNextPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeetingsAction.loadNextPageClassroomMeetings),
      switchMap(() => [MeetingsAction.loadClassroomMeetings()]),
    ),
  );
}
