import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { SlideTextareaComponent } from './slide-textarea.component';

@NgModule({
  declarations: [SlideTextareaComponent],
  imports: [CommonModule, ReactiveFormsModule, TextFieldModule],
  exports: [SlideTextareaComponent],
})
export class SlideTextareaModule {}
