<app-onboarding-user-status
  [onboardingStatus]="status">
</app-onboarding-user-status>

<div class="buttons">
  <on-ui-button class="primary"
                label="onboarding.want-to-teach"
                (onclick)="becomeTeacher()">
  </on-ui-button>
  <on-ui-button class="secondary"
                label="onboarding.want-to-learn"
                (click)="becomeStudent()">
  </on-ui-button>
</div>
