import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DestroyableComponent } from '@models/destroyable.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-layout',
  templateUrl: './clean-layout.component.html',
  styleUrls: ['./clean-layout.component.scss'],
})
export class CleanLayoutComponent extends DestroyableComponent implements OnInit {
  public pageStyle = '';

  constructor(private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.route.url.pipe(takeUntil(this.destroyed$)).subscribe((url) => {
      // logger.log('CleanLayoutComponent -> route.url -> snapshot=', this.route.snapshot);
      const data = this.route.snapshot.data;
      this.pageStyle = data.page_style || '';
    });
  }
}
