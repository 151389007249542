import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PlatformService } from '@app/core/services';
import { WebsocketService } from '@app/core/websocket';
import { ChangableComponent } from '@app/models/changable.component';
import { Store } from '@ngrx/store';
import { formatDistanceToNow } from 'date-fns';
import { NoticeTypeEnum } from 'lingo2-models';
import { OnUiButtonModule } from 'onclass-ui';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-system-notifications',
  standalone: true,
  imports: [CommonModule, OnUiButtonModule],
  templateUrl: './refresh-notification.component.html',
  styleUrls: ['./refresh-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemNotificationsComponent extends ChangableComponent implements OnInit {
  public isUpdateNeeded = false;
  public passedTimeString: string;
  public startTime: Date;

  public constructor(
    protected readonly websocket: WebsocketService,
    protected readonly platform: PlatformService,
    protected readonly store: Store,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super(cdr, platform);
  }

  public ngOnInit(): void {
    if (!this.isBrowser) {
      return;
    }

    this.websocket.onSystemAction.pipe(takeUntil(this.destroyed$)).subscribe((action) => {
      if (!this.isUpdateNeeded) {
        this.isUpdateNeeded = action.type === NoticeTypeEnum.site_update;
        this.startTime = new Date();

        this.setInterval(() => {
          this.passedTimeString = formatDistanceToNow(this.startTime);
          this.detectChanges();
        }, 60000);
        this.detectChanges();
      }
    });
  }

  public refreshPage() {
    window?.location?.reload();
  }
}
