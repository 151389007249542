const tutorFaq = [
  {
    question: 'first-contact.faq.list.question1',
    answer: 'first-contact.faq.list.answer1',
  },
  {
    question: 'first-contact.faq.list.question2',
    answer: 'first-contact.faq.list.answer2',
  },
  {
    question: 'first-contact.faq.list.question3',
    answer: 'first-contact.faq.list.answer3',
  },
  {
    question: 'first-contact.faq.list.question4',
    answer: 'first-contact.faq.list.answer4',
  },
  {
    question: 'first-contact.faq.list.question5',
    answer: 'first-contact.faq.list.answer5',
  },
  {
    question: 'first-contact.faq.list.question6',
    answer: 'first-contact.faq.list.answer6',
  },
  {
    question: 'first-contact.faq.list.question7',
    answer: 'first-contact.faq.list.answer7',
  },
  {
    question: 'first-contact.faq.list.question8',
    answer: 'first-contact.faq.list.answer8',
  },
  {
    question: 'first-contact.faq.list.question9',
    answer: 'first-contact.faq.list.answer9',
  },
  {
    question: 'first-contact.faq.list.question10',
    answer: 'first-contact.faq.list.answer10',
  },
  {
    question: 'first-contact.faq.list.question11',
    answer: 'first-contact.faq.list.answer11',
  },
  {
    question: 'first-contact.faq.list.question12',
    answer: 'first-contact.faq.list.answer12',
  },
  {
    question: 'first-contact.faq.list.question13',
    answer: 'first-contact.faq.list.answer13',
  },
];

const student = [
  {
    question: 'first-contact.faq.list.question2',
    answer: 'first-contact.faq.list.answer2',
  },
  {
    question: 'first-contact.faq.list.question14',
    answer: 'first-contact.faq.list.answer14',
  },
  {
    question: 'first-contact.faq.list.question15',
    answer: 'first-contact.faq.list.answer15',
  },
  {
    question: 'first-contact.faq.list.question16',
    answer: 'first-contact.faq.list.answer16',
  },
  {
    question: 'first-contact.faq.list.question17',
    answer: 'first-contact.faq.list.answer17',
  },
  {
    question: 'first-contact.faq.list.question18',
    answer: 'first-contact.faq.list.answer18',
  },
  {
    question: 'first-contact.faq.list.question19',
    answer: 'first-contact.faq.list.answer19',
  },
];

const methodist = [];

const school = [];

export const faq = {
  tutor: tutorFaq,
  student,
  methodist,
  school,
};
