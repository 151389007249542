import { EContentPanelRoute } from 'lingo2-models';

export const UNCONTROLLED_ROUTES = []; // [ EContentPanelRoute.news ] - роуты, для которых не выставляется дисциплина

export interface IMenuItem {
  id: string;
  label: string;
  icon: string;
  route: EContentPanelRoute;
  url?: string;
}
