import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPagedResults, Lesson, IFindLessonsFilter, IPagination } from 'lingo2-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

export type LessonDetailsType =
  | keyof Lesson
  | 'content'
  | 'stats'
  | 'visit_info'
  | 'game'
  | 'cover:sm'
  | 'cover:md'
  | 'author:xs'
  | 'author:sm'
  | 'author:status'
  | 'template'
  | 'templateMeta';

@Injectable({
  providedIn: 'root',
})
export class LessonsService {
  constructor(private http: HttpClient) {}

  /**
   * Уроки по фильтру
   */
  public getLessons(
    filter: Partial<IFindLessonsFilter>,
    pagination: IPagination,
    details?: LessonDetailsType[],
  ): Observable<IPagedResults<Lesson[]>> {
    const url = `${environment.content_url}/lessons/`;
    let params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter', JSON.stringify(filter));
    if (details && details.length) {
      params = params.set('details', JSON.stringify(details));
    }

    return this.http.get<Lesson[]>(url, { params, observe: 'response' }).pipe(
      map(this.handleItemsResponse),
      // catchError(this.handleError),
    );
  }

  /** типа - домашние задания! */
  public getTasks = this.getLessons;

  /**
   * Создать урок
   */
  public createLesson(values: Partial<Lesson>): Observable<Lesson> {
    const url = `${environment.content_url}/lesson/`;

    return this.http.post<Lesson>(url, values, { observe: 'response' }).pipe(
      map(this.handleItemResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Обновить урок
   */
  public updateLesson(id: string, values: Partial<Lesson>): Observable<Lesson> {
    const url = `${environment.content_url}/lesson/${id}`;
    return this.http.put<Lesson>(url, values, { observe: 'response' }).pipe(
      map(this.handleItemResponse),
      // catchError(this.handleError),
    );
  }

  private handleItemResponse(response: HttpResponse<Lesson>): Lesson {
    return new Lesson(response.body);
  }

  private handleItemsResponse(response: HttpResponse<Lesson[]>): IPagedResults<Lesson[]> {
    return {
      results: response.body.map((i) => new Lesson(i)),
      page: +response.headers.get('X-Pagination-Page'),
      pageSize: +response.headers.get('X-Pagination-PageSize'),
      total: +response.headers.get('X-Pagination-Total'),
      totalPages: +response.headers.get('X-Pagination-TotalPages'),
    };
  }
}
