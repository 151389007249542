import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { IRedPoint, RedPointTypeEnum } from 'lingo2-models';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RedPointsService {
  constructor(private http: HttpClient) {}
  /**
   * Красные точки
   */
  public getRedPoints(): Observable<IRedPoint[]> {
    const url = `${environment.messaging_url}/red-point`;
    return this.http.get<IRedPoint[]>(url, { observe: 'response' }).pipe(map((response) => response.body));
  }

  /**
   * Убрать одну красную точку
   */
  public removeRedPoint(redPoint: RedPointTypeEnum): Observable<IRedPoint> {
    const url = `${environment.messaging_url}/red-point?type=${redPoint}`;
    return this.http.delete<IRedPoint>(url, { observe: 'response' }).pipe(map((response) => response.body));
  }

  /**
   * Убрать все красные точки
   */
  public removeAllRedPoints(): Observable<IRedPoint> {
    const url = `${environment.messaging_url}/red-point`;
    return this.http.delete<IRedPoint>(url, { observe: 'response' }).pipe(map((response) => response.body));
  }
}
