<app-base-notification [base]="base">
  <div clickToRelativeUrl [innerHTML]="'notifications.vacation_reminder.text' | translate: {
      'begin_at': params.vacation_begin_at | date: 'dd MMMM',
      'end_at': params.vacation_end_at | date: 'dd MMMM',
  }"></div>

  <on-ui-button class="primary"
                [label]="'notifications.vacation_reminder.action'"
                [routerLink]="'/schedule/calendar'">
  </on-ui-button>
</app-base-notification>

