<app-base-notification [base]="base">

  <div>
    {{'notifications.meeting_reservation.text' | translate}}
    <a class="link-base" [href]="params.meeting_join_url">
      {{params.meeting_title + ' ' + (params.meeting_begin_at | date : 'MM/dd/yyyy HH:mm')}}
    </a>
  </div>

  <a [href]="params.meeting_join_url">
    <on-ui-button class="primary outline" label="notifications.meeting_reservation.action"></on-ui-button>
  </a>

</app-base-notification>