import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import {
  IUserScheduleFilter,
  EventStatusEnum,
  SingleEvent,
  RegularSchedule,
  UserServiceScheduleRequest,
  UserServiceScheduleResponse,
  SignupScheduleDigestRequest,
  SignupScheduleDigestResponse,
  UserVacation,
  UpdateUserVacationRequest,
  UpdateUserVacationResponse,
} from 'lingo2-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export type UserServiceReservationEvent = Pick<SingleEvent, 'account_id' | 'begin_at' | 'user_service_id'>;

/** Запрос на добавление в календарь планируемых занятий по услуге */
export class UserServiceReservationRequest {
  public events: UserServiceReservationEvent[];
}

/** Запрос на удаление из календаря ранее запланированных занятий по услуге */
export class UserServiceReservationCancelRequest {
  public events: Array<Pick<SingleEvent, 'id' | 'account_id'>>;
}

export interface IFindSchoolScheduleFilter {
  account_id?: string[];
  date_from: Date;
  date_to: Date;
}

@Injectable({
  providedIn: 'root',
})
export class ScheduleService {
  public constructor(private http: HttpClient) {}

  /** Расписание текущего пользователя */
  public getSchedule(filter: Partial<IUserScheduleFilter>): Observable<SingleEvent[]> {
    const url = `${environment.account_url}/schedule/2/schedule`;
    const params = new HttpParams().set('filter', JSON.stringify(filter));
    return this.http
      .get<SingleEvent[]>(url, { params, observe: 'body' })
      .pipe(map((events) => (events || []).map((e) => new SingleEvent(e))));
  }

  /** Расписание школы */
  public findSchoolSchedule(school_id: string, filter: IFindSchoolScheduleFilter): Observable<SingleEvent[]> {
    const url = `${environment.account_url}/schedule/2/schedule/school/${school_id}`;
    const params = new HttpParams().set('filter', JSON.stringify(filter));
    return this.http
      .get<SingleEvent[]>(url, { params, observe: 'body' })
      .pipe(map((events) => (events || []).map((e) => new SingleEvent(e))));
  }

  /**
   * Дайджест свободного времени преподавателя
   * Чтобы при записи на занятие сразу перелистнуть календарь на ближайшее время
   * и ограничить в календаре максимальное время
   */
  public getScheduleDigest(request: SignupScheduleDigestRequest): Observable<SignupScheduleDigestResponse> {
    const url = `${environment.account_url}/schedule/2/digest`;
    return this.http
      .post<SignupScheduleDigestResponse>(url, request, { observe: 'body' })
      .pipe(map((response) => new SignupScheduleDigestResponse(response)));
  }

  /**
   * Комбинированное время текущего пользователя (ученика) и указанных пользоватей (преподавателей)
   */
  public getCombinedSchedule(filter: Partial<IUserScheduleFilter>): Observable<SingleEvent[]> {
    const url = `${environment.account_url}/schedule/2/combined`;
    const params = new HttpParams().set('filter', JSON.stringify(filter));
    return this.http.get<SingleEvent[]>(url, { params, observe: 'response' }).pipe(map(this.handleEventsResponse));
  }

  /** Отметить рабочий/нерабочий час */
  public markHours(status: EventStatusEnum, begin_at: Date, end_at: Date): Observable<any> {
    const url = `${environment.account_url}/schedule/2/mark-hours`;
    return this.http
      .post(url, { status, begin_at, end_at }, { observe: 'response' })
      .pipe(map((response) => response.body));
  }

  /** Получить регулярное расписание */
  public getRegular(): Observable<RegularSchedule> {
    const url = `${environment.account_url}/schedule/2/regular`;
    return this.http.get(url, { observe: 'response' }).pipe(map((response) => new RegularSchedule(response.body)));
  }

  /** Создать/изменить регулярное расписание */
  public updateRegular(
    values: Pick<RegularSchedule, 'timezone' | 'signup_gap_hours' | 'weekdays'>,
  ): Observable<RegularSchedule> {
    const url = `${environment.account_url}/schedule/2/regular`;
    return this.http
      .put(url, values, { observe: 'response' })
      .pipe(map((response) => new RegularSchedule(response.body)));
  }

  /** Получить данные об отпуске */
  public getVacation(): Observable<UserVacation> {
    const url = `${environment.account_url}/schedule/2/vacation`;
    return this.http.get<UserVacation>(url, { observe: 'body' }).pipe(map((response) => new UserVacation(response)));
  }

  /** Сохранить данные об отпуске */
  public updateVacation(values: UpdateUserVacationRequest): Observable<UpdateUserVacationResponse> {
    const url = `${environment.account_url}/schedule/2/vacation`;
    return this.http
      .post<UpdateUserVacationResponse>(url, values, { observe: 'body' })
      .pipe(map((response) => new UpdateUserVacationResponse(response)));
  }

  /**
   * Получить расписание для указанной услуги и указанного ученика
   */
  public findServiceSignupSchedule(request: UserServiceScheduleRequest): Observable<UserServiceScheduleResponse> {
    const url = `${environment.account_url}/schedule/2/service-signup`;
    return this.http
      .post<UserServiceScheduleResponse>(url, request, { observe: 'body' })
      .pipe(map((response) => new UserServiceScheduleResponse(response)));
  }

  private handleEventsResponse(response: HttpResponse<SingleEvent[]>): SingleEvent[] {
    return response.body.map((e) => new SingleEvent(e));
  }
}
