import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { OnUiCoverModule } from 'onclass-ui';
import { OnboardingPersonalInfoComponent } from './onboarding-personal-info.component';

@NgModule({
  declarations: [OnboardingPersonalInfoComponent],
  imports: [CommonModule, TranslateModule, RouterModule, OnUiCoverModule],
  exports: [OnboardingPersonalInfoComponent],
})
export class OnboardingPersonalInfoModule {}
