import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { IFileType, IconsService, FilesService } from '@core/services';
import { DestroyableComponent } from '@models/destroyable.component';
import { Store } from '@ngrx/store';
import { IImageFile, ImageSizeEnum } from 'lingo2-models';
import { changeFav } from 'src/app/store/actions/media-gallery.actions';

interface MediaImageGalleryItem {
  image: IFileType;
  width: number;
  url: string;
  star: boolean;
}

@Component({
  selector: 'app-media-image-gallery',
  templateUrl: './media-image-gallery.component.html',
  styleUrls: ['./media-image-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaImageGalleryComponent extends DestroyableComponent {
  @Input() disableStars: boolean;
  @Input() public set images(images: IFileType[]) {
    this.prepareGallery(images);
  }
  @Output() choose = new EventEmitter<IFileType>();
  @Output() configured = new EventEmitter<any>();
  public galleryImages0: MediaImageGalleryItem[] = [];
  public galleryImages1: MediaImageGalleryItem[] = [];
  public thumbnailHeight = 90;
  public emptyThumbnailWidth = 90;
  public thumbnailOffset = 10;
  public svgsetIcon = IconsService.svgsetIconUrl;

  public galleryScrollPages: any[] = [];
  public scrollPageWidth = 200;
  // public galleryScrollWidth = 0;

  constructor(private cdr: ChangeDetectorRef, private store: Store) {
    super();
  }

  public thumbnailFile(image: IFileType): IImageFile {
    const sizes = ['md'];
    let _imageFile = null;
    sizes.map((size) => {
      if (!_imageFile) {
        const _file = (image.images || []).find((_image) => _image.size === size);
        if (_file) {
          _imageFile = _file;
        }
      }
    });
    return _imageFile;
  }

  protected prepareGallery(images: IFileType[]) {
    const expectedSize = ImageSizeEnum.md;
    const _images: MediaImageGalleryItem[] = images.map((image: IFileType & { flags: { is_fav: boolean } }) => {
      const imgMd = image.images?.find((img) => img.size === expectedSize);
      const url = imgMd?.url || FilesService.getFileUrlBySize(image.id, expectedSize);
      return {
        image,
        width: this.thumbnailWidth(image),
        url,
        star: image && image.flags && image.flags.is_fav,
      };
    });

    this.galleryImages0 = _images.filter((image, index) => index % 2 === 0);
    this.galleryImages1 = _images.filter((image, index) => index % 2 === 1);

    const width0 =
      this.galleryImages0.reduce((width, item) => width + item.width, 0) +
      (this.galleryImages0.length - 1) * this.thumbnailOffset;

    const width1 =
      this.galleryImages1.reduce((width, item) => width + item.width, 0) +
      (this.galleryImages1.length - 1) * this.thumbnailOffset;

    const galleryScrollWidth = Math.max(width0, width1);
    const galleryScrollPages = Math.max(1, Math.floor(galleryScrollWidth / this.scrollPageWidth));
    this.configured.emit({
      galleryScrollWidth,
      galleryScrollPages,
    });

    this.cdr.markForCheck();
  }

  public trackByFn(index: number, item: MediaImageGalleryItem) {
    return item.image.id;
  }

  public thumbnailUrl(image: IFileType): string {
    const imageFile = this.thumbnailFile(image);
    return imageFile ? this.assetsUrl(imageFile.url) : null;
  }

  public thumbnailWidth(image: IFileType): number {
    const imageFile = this.thumbnailFile(image);
    return imageFile
      ? Math.round(
          (parseInt(String(imageFile.height), 10) / parseInt(String(imageFile.width), 10)) * this.thumbnailHeight,
        )
      : this.emptyThumbnailWidth;
  }

  public chooseImage(file: IFileType) {
    this.choose.emit(file);
  }

  public onStar(event: Event, item: MediaImageGalleryItem) {
    event.preventDefault();
    event.stopPropagation();
    this.store.dispatch(changeFav({ file_id: item.image.id }));
    return false;
  }
}
