import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CachedService, CacheTtlRule, PlatformService } from '@core/services';
import { environment } from '@env/environment';
import { DestroyableService } from '@models/destroyable.service';
import {
  CSchool,
  CSchoolParticipant,
  IChangeSchoolParticipantQuery,
  IFindSchoolFilter,
  IPagedResults,
  IPagination,
  IStatusResponse,
  SchoolDetailsType,
} from 'lingo2-models';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

const schools_url = `${environment.account_url}/school`;

@Injectable({
  providedIn: 'root',
})
export class SchoolsService extends DestroyableService<CSchool> {
  private currentSchool = this.register(new BehaviorSubject<CSchool>(null));
  public currentSchool$ = this.currentSchool.asObservable();

  private reloadSchools = this.register(new BehaviorSubject<boolean>(false));
  public reloadSchools$ = this.reloadSchools.asObservable();

  constructor(
    private http: HttpClient,
    protected readonly platform: PlatformService,
    private cachedService: CachedService,
  ) {
    super(CSchool, platform);
  }

  public findSchools(
    filter: Partial<IFindSchoolFilter>,
    pagination: IPagination,
  ): Observable<IPagedResults<CSchool[]>> {
    const url = `${schools_url}/`;
    delete filter.language_id;
    const params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter', JSON.stringify(filter));
    return this.http
      .get<CSchool[]>(url, { params, observe: 'response' })
      .pipe(map(this.handleItemsResponse.bind(this)));
  }

  public getSchoolByIdOrSlug(
    id_or_slug: string,
    details?: SchoolDetailsType[],
    ttl?: CacheTtlRule,
  ): Observable<CSchool> {
    const url = `${schools_url}/${id_or_slug}`;
    const params = new HttpParams();
    if (details) {
      params.set('details', JSON.stringify(details));
    }
    const source$ = this.http
      .get<CSchool>(url, { observe: 'body', params })
      .pipe(map((response) => this.handleItemBody(response)));

    return !ttl ? source$ : this.cachedService.shared$(['School', id_or_slug, details], source$, ttl).pipe(first());
  }

  public static getSchoolPostfix(slug: string): string {
    const [postfix, ...others] = slug.split('-').reverse();
    return postfix;
  }

  public createSchool(values: Partial<CSchool>): Observable<CSchool> {
    const url = `${schools_url}/`;
    return this.http.post<CSchool>(url, values, { observe: 'body' }).pipe(map(this.handleItemBody.bind(this)));
  }

  /**
   * Удаление школы (на самом деле переводит в draft)
   */
  public removeSchool(school_id: string): Observable<CSchool> {
    const url = `${schools_url}/${school_id}`;
    return this.http.delete<CSchool>(url, { observe: 'body' }).pipe(map(this.handleItemBody.bind(this)));
  }

  /**
   * Публикация школы (на самом деле переводит в publish)
   */
  public publish(school_id: string): Observable<CSchool> {
    const url = `${schools_url}/${school_id}/publish`;
    return this.http.post<CSchool>(url, { observe: 'body' }).pipe(map(this.handleItemBody.bind(this)));
  }

  /**
   * Обновить школу.
   *
   * Школы обновляются через этот метод. Если поле не установлено, то оно не будет обновлятся,
   * поэтому дополнительные методы для обновления школ не требуются
   */
  public updateSchool(id: string, values: Partial<CSchool>): Observable<CSchool> {
    const url = `${schools_url}/${id}`;
    return this.http.put<CSchool>(url, values, { observe: 'body' }).pipe(map(this.handleItemBody.bind(this)));
  }

  /**
   * Добавить пользователя в школу
   *
   * Если не указать параметры account_id и role, то в школу
   * будет добавлен текущий пользователь в качестве ученика.
   */
  public createParticipant(school_id: string, params?: IChangeSchoolParticipantQuery): Observable<CSchool> {
    const url = `${schools_url}/${school_id}/participants`;
    return this.http.post<CSchool>(url, params, { observe: 'body' }).pipe(map(this.handleItemBody.bind(this)));
  }

  /**
   * Обновить пользователя в школе
   */
  public updateParticipant(
    participant_id: string,
    values: Partial<CSchoolParticipant>,
  ): Observable<CSchoolParticipant> {
    const url = `${schools_url}/participants/${participant_id}`;
    return this.http
      .put<CSchoolParticipant>(url, values, { observe: 'body' })
      .pipe(map((item) => SchoolsService.mapItem(item, CSchoolParticipant)));
  }

  /**
   * Удалить пользователя из школы
   */
  public removeParticipant(participant_id: string): Observable<CSchool> {
    const url = `${schools_url}/participants/${participant_id}`;
    return this.http.delete<CSchool>(url, { observe: 'body' }).pipe(map(this.handleItemBody.bind(this)));
  }

  /**
   * Пригласить пользователей по email
   */
  public inviteParticipantsByEmail(school_id: string, emails: string[]): Observable<CSchool> {
    const url = `${schools_url}/${school_id}/invite-by-email`;
    return this.http.post<CSchool>(url, { emails }, { observe: 'body' }).pipe(map(this.handleItemBody.bind(this)));
  }

  /**
   * Добавить в закладки
   *
   * @param id school id
   */
  public addBookmark(id: string): Observable<IStatusResponse> {
    const url = `${schools_url}/${id}/bookmark`;
    return this.http.post<IStatusResponse>(url, {}, { observe: 'body' });
  }

  /**
   * Удалить из закладок
   *
   * @param id school id
   */
  public removeBookmark(id: string): Observable<IStatusResponse> {
    const url = `${schools_url}/${id}/bookmark`;
    return this.http.delete<IStatusResponse>(url, { observe: 'body' });
  }

  /**
   * url школы
   */
  public static schoolRoute(school: CSchool | Partial<CSchool>, fullUrl = true): string[] {
    return [fullUrl ? environment.default_host : '', 'schools', school?.urlPart || school?.slug];
  }

  /**
   * текущая школа
   */
  public setCurrentSchool(school: CSchool) {
    this.currentSchool.next(school);
  }

  /**
   * перезагрузка списка школ
   */
  public setReloadSchoolsState() {
    this.reloadSchools.next(true);
    this.setTimeout(() => this.reloadSchools.next(false), 200);
  }
}
