<div class="verification-widget-user"
     [ngClass]="displayMode"
     [class.mobile]="deviceService.isMobile()">
  <div class="avatar">
    <on-ui-cover [cover]="cover"></on-ui-cover>
    <svg class="status-icon" [appPopover]="popoverNotVerified" [disabledPopover]="!isPopoverNeeded" >
      <use [attr.xlink:href]="svgsetIcon(statusIcons[verification_status])"></use>
    </svg>
  </div>

  <div class="name" *ngIf="showUserName">
    <p *ngIf="userFullName" class="full-name">{{ userFullName }}</p>
    <ng-template [ngIf]="!userFullName">
      <span *ngIf="isGuest" class="link bold" (click)="authGuardedNavigate('/profile')">
        {{ 'verification-widget.no-name' | translate }}
      </span>
      <a *ngIf="!isGuest" routerLink="/profile/about" class="link bold">
        {{ 'verification-widget.no-name' | translate }}
      </a>
    </ng-template>
    <span class="description">{{ 'verification-widget.profile' | translate }}</span>
  </div>
</div>

<ng-template #popoverNotVerified>
  <app-not-verified-widget></app-not-verified-widget>
</ng-template>
