export const advantagesList = [
  {
    type: 'conditions',
    title: 'first-contact.school-advantages.list.title1',
    text: 'first-contact.school-advantages.list.text1',
  },
  {
    type: 'safety',
    title: 'first-contact.school-advantages.list.title2',
    text: 'first-contact.school-advantages.list.text2',
  },
  {
    type: 'marketing',
    title: 'first-contact.school-advantages.list.title3',
    text: 'first-contact.school-advantages.list.text3',
  },
];
