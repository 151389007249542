import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { IMyMeetingStartedNotice, INoticeParams, MeetingTypeEnum } from 'lingo2-models';
import { EModifier, IBaseNotification } from '../../base-notification/base-notification.component';

@Component({
  selector: 'app-my-meeting-started',
  templateUrl: './my-meeting-started.component.html',
  styles: [''],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyMeetingStartedComponent {
  public params: IMyMeetingStartedNotice;
  public base: IBaseNotification;
  public meetingType = MeetingTypeEnum;

  @Input() set notification(notification: INoticeParams) {
    this.params = notification.params;
    this.base = {
      title: 'notifications.my_meeting_started.title',
      icon: 'notice-clock',
      created_at: notification.created_at,
      modifier: EModifier.blue,
    };
  }

  public get participant() {
    return `<a class='user-fullname-link' target='_blank' href='${this.params.participant_url}'>${this.params.participant_fullname}</a>`;
  }

  public get title() {
    return `<a class='link-base' href='${this.params.meeting_join_url}'>${this.params.meeting_title}</a>`;
  }
}
