import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SpinnersModule } from '@core/components/spinners/spinners.module';
import { AboutVerificationWidgetModule } from '@core/widgets/about-verification-widget/about-verification-widget.module';
import { NotVerifiedWidgetModule } from '@core/widgets/not-verified-widget/not-verified-widget.module';
import { TranslateModule } from '@ngx-translate/core';
import { OnUiCoverModule, OnUiPopoverModule } from 'onclass-ui';
import { VerificationWidgetSpinnerComponent } from './verification-widget-spinner/verification-widget-spinner.component';
import { VerificationWidgetStatusComponent } from './verification-widget-status/verification-widget-status.component';
import { VerificationWidgetUserComponent } from './verification-widget-user/verification-widget-user.component';
import { VerificationWidgetComponent } from './verification-widget.component';

@NgModule({
  declarations: [
    VerificationWidgetComponent,
    VerificationWidgetUserComponent,
    VerificationWidgetStatusComponent,
    VerificationWidgetSpinnerComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    OnUiPopoverModule,
    AboutVerificationWidgetModule,
    NotVerifiedWidgetModule,
    SpinnersModule,
    OnUiCoverModule,
  ],
  exports: [VerificationWidgetComponent],
})
export class VerificationWidgetModule {}
