<app-base-notification [base]="base">

  <div [innerHTML]="'notifications.classroom-leave.text' | translate: {'student': user, 'title': title}"></div>

  <on-ui-button class="primary outline"
                [label]="'notifications.classroom-leave.action'"
                (click)="navigate()">
  </on-ui-button>

</app-base-notification>

