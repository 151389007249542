import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { UserRoles } from '@app/first-contact/first-contact.component';
import { PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { freeLessonsList } from './first-contact-free-lesson.variables';

@Component({
  selector: 'app-first-contact-free-lesson',
  templateUrl: './first-contact-free-lesson.component.html',
  styleUrls: ['./first-contact-free-lesson.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirstContactFreeLessonComponent extends ChangableComponent {
  @Input() selectedRole: UserRoles;
  public freeLessonsList = freeLessonsList;

  constructor(
    protected readonly cdr: ChangeDetectorRef,
    public deviceService: DeviceDetectorService,
    protected readonly platform?: PlatformService,
  ) {
    super(cdr, platform);
  }
}
