import {
  Component,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  Inject,
  PLATFORM_ID,
  OnInit,
  OnDestroy,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit,
  ChangeDetectionStrategy,
  AfterViewChecked,
} from '@angular/core';
import { ChangableComponent } from '@app/models/changable.component';
import { Store } from '@ngrx/store';
import { getCurrentSlide } from 'lingo2-game-lib';
import { takeUntil } from 'rxjs/operators';
import { IconsService, PlatformService, ScreenService } from '../../services';
import { ModalStackService } from './modal-stack.service';

@Component({
  selector: 'app-modal-ad',
  template: '<ng-content *ifIsBrowser></ng-content>',
})
export class ModalAdComponent {}

@Component({
  selector: 'app-modal-body',
  template: '<ng-content *ifIsBrowser></ng-content>',
})
export class ModalBodyComponent {}

@Component({
  selector: 'app-modal-title',
  template: '<ng-content *ifIsBrowser></ng-content>',
})
export class ModalTitleComponent {}

@Component({
  selector: 'app-modal-actions',
  template: '<ng-content *ifIsBrowser></ng-content>',
})
export class ModalActionsComponent {}

const DEFAULT_MARGIN_BOTTOM = 150;

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent extends ChangableComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  @Input() public canClose = true;
  @Input() public closeInside: boolean;
  @Input() public additionalHeight = 0;
  @Input() public short: boolean;
  @Input() public long: boolean;
  @Input() public thin: boolean;
  @Input() public wide: boolean;
  @Input() public middle: boolean;
  @Input() public schedule: boolean;
  @Input() public narrow: boolean;
  @Input() public narrow2: boolean;
  @Input() public auth: boolean;
  @Input() public authPage: boolean;
  @Input() public wide2: boolean;
  @Input() public scale: boolean;
  @Input() public prevNext: boolean;
  @Input() public reachedPrev: boolean;
  @Input() public reachedNext: boolean;
  @Input() public complain: boolean;
  @Input() public editor: boolean;
  @Input() public canGoBack: boolean;
  @Input() public showToFooter: boolean;
  @Output() public closed = new EventEmitter<boolean>();
  @Output() public togglePrev = new EventEmitter<boolean>();
  @Output() public toggleNext = new EventEmitter<boolean>();
  public isVisible = true;
  public scrollTop = 0;
  public hoverFlag = false;
  public scaleNumber = 1;
  public svgSetIcon = IconsService.svgsetIconUrl;
  private modalId: number;
  private heightCenter = 1;
  private is_open = false;
  private readonly element;
  private _answerState: string;

  @ViewChild('gridCenter', { read: '' }) protected gridCenterRef: ElementRef;
  @ViewChild('scrollHolder', { static: true }) protected scrollHolder: ElementRef<HTMLElement>;
  @ViewChild('modalBody') protected modalBody: ElementRef<HTMLElement>;

  public constructor(
    private screenService: ScreenService,
    private modalStackService: ModalStackService,
    private el: ElementRef,
    @Inject(PLATFORM_ID) private platformId: object,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
    private readonly store: Store,
  ) {
    super(cdr, platform);
    this.element = el.nativeElement;
    this.canClose = !!this.canClose;
  }

  @Input()
  public set answerState(state: string) {
    this._answerState = state;
  }
  public get answerState(): string {
    return this._answerState;
  }

  public get scaleValue() {
    return Math.round(this.scaleNumber * 100);
  }

  public get calcHeader() {
    if (this.scaleNumber > 1) {
      return Math.round((this.scaleNumber - 1) * 10 * (this.heightCenter / 20) + 100);
    }
    if (this.showToFooter) {
      return window.innerHeight * 0.6;
    }
    if (this.complain) {
      return 150;
    }
    return 100;
  }

  public get isReachedPrev() {
    return this.reachedPrev || false;
  }

  public get isReachedNext() {
    return this.reachedNext || false;
  }

  public ngOnInit() {
    if (this.isBrowser) {
      document.querySelector('.app').appendChild(this.element);
      if (this.scale) {
        this.scaleNumber = +localStorage.getItem('scaleNumber') || 1;
      }
    }
    this.modalId = this.modalStackService.pushStack();
    this.modalStackService.updated$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.isVisible = this.modalStackService.isVisible(this.modalId);
      this.detectChanges();
    });

    this.open();
    this.store
      .select(getCurrentSlide)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this.scrollHolder) {
          this.scrollHolder.nativeElement.scrollTop = 0;
        }
      });
  }

  public ngAfterViewInit() {
    this.heightCenter = this.gridCenterRef?.nativeElement?.offsetHeight;
    this.detectChanges();
  }

  public ngAfterViewChecked() {
    this.setTimeout(() => {
      this.detectChanges();
    }, 100);
  }

  public get customHeight() {
    return (
      this.modalBody?.nativeElement?.offsetHeight * this.scaleNumber + DEFAULT_MARGIN_BOTTOM + this.additionalHeight
    );
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
    if (this.isBrowser) {
      this.element.remove();
    }
    this.modalStackService.popStack();
    this.close();
  }

  public hover(event) {
    if (event.type === 'mouseover') {
      this.hoverFlag = true;
    } else if (event.type === 'mouseout') {
      this.hoverFlag = false;
    }
  }

  /**
   * open modal
   */
  public open(): void {
    if (!this.is_open) {
      if (this.isBrowser) {
        if (this.scale) {
          this.scaleNumber = +localStorage.getItem('scaleNumber') || 1;
        }
        if (this.element?.style) {
          this.element.style.display = 'block';
        }
        const scrollAllContent = document.querySelector('.app-body-scroll>.ng-scrollbar-wrapper>scrollbar-control');
        if (scrollAllContent) {
          scrollAllContent.setAttribute('style', 'display: none');
        }
      }

      this.screenService.setBodyFixed(true);
      this.is_open = true;
    }
  }

  /**
   * close modal
   */
  public close(): void {
    if (this.is_open) {
      if (this.isBrowser) {
        const scrollAllContent = document.querySelector('.app-body-scroll>.ng-scrollbar-wrapper>scrollbar-control');
        if (scrollAllContent) {
          scrollAllContent.setAttribute('style', 'display: flex');
        }
        if (this.scale) {
          localStorage.setItem('scaleNumber', String(this.scaleNumber));
        }
      }
      if (!this.modalStackService.visibleCount) {
        this.screenService.setBodyFixed(false);
      } else {
        this.setTimeout(() => {
          if (document.getElementsByTagName('app-modal').length === 0) {
            if (document.body.classList.contains('fixed')) {
              this.screenService.setBodyFixed(false);
            }
          }
        }, 50);
      }

      this.is_open = false;
    }
  }

  public onClose(event?: Event) {
    if (event) {
      event.preventDefault();
      // С event.cancelBubble = true не срабатывает clickOutside в дропдаунах зеленой панели
      // event.cancelBubble = true;
    }
    if (this.canClose) {
      this.closed.emit(true);
    }
  }

  public onCloseBtn(event?: Event) {
    if (event) {
      event.preventDefault();
      event.cancelBubble = true;
    }
    this.closed.emit(true);
  }

  public scalePlus() {
    if (this.scaleNumber < 1.5) {
      this.scaleNumber = Math.round((this.scaleNumber + 0.1) * 10) / 10;
    }
  }

  public scaleMinus() {
    if (this.scaleNumber > 0.5) {
      this.scaleNumber = Math.round((this.scaleNumber - 0.1) * 10) / 10;
    }
  }

  public onPrev() {
    this.togglePrev.emit(true);
  }

  public onNext() {
    this.toggleNext.emit(true);
  }
}
