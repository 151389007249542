import { UrlSegment } from '@angular/router';
import { CLibraryFilterData } from '@app/store/models';
import {
  ContentTypeEnum,
  EContentModeType,
  EContentOrder,
  EContentPanelRoute,
  IContentPane,
  Router as BaseRouter,
  IPaneFilterOptions,
  Content,
  UserService,
  UserServiceCategory,
  Collection,
} from 'lingo2-models';
import { IFilter } from './lingo2-models/content';

interface IGetLinkOptions {
  subjectCode: string;
  typeCode: string;
}

const defaultOptions: IPaneFilterOptions = {
  baseRoute: EContentPanelRoute.lessons,
  contentMode: EContentModeType.all,
  contentOrder: EContentOrder.popularity_week,
  subjectCodes: [],
  contentTypeCodes: [],
  languageCodes: [],
};

export class LibraryRouter extends BaseRouter {
  constructor(options?: Partial<IPaneFilterOptions>) {
    super();
    this.options = {
      ...defaultOptions,
      ...options,
    };
  }

  private options: IPaneFilterOptions;

  /**
   * Павел:
   * WTF!? Почему базовый роутер вычисляет create_url если это вообще больше от конкретной панели зависит
   *
   * Миша:
   * Ответ на вопрос:
   * 1. Компонент AbstractPane может размещатся как в библиотеке, так и в других местах.
   * 2. Неправильно заставлять AbstractPane ЗНАТЬ о том, кто его родитель.
   * 3. Также неправильно заставлять BasePane знать о том, ГДЕ он находится, потому что его заботит только то, КАК он выглядит.
   * 4. Поэтому в этой ситуации (для этого поля) используется мост: (https://refactoring.guru/ru/design-patterns/bridge),
   * где в качестве абстракции выступает AbstractPane, а в качестве реализации - этот класс.
   */
  public getCreateLinkByPane(pane: IContentPane): string {
    if (pane) {
      switch (pane.format as string) {
        case 'teacher-of-the-day':
        case 'teachers':
          return null;

        case 'subjects':
          return '/constructor';

        case 'card-micro':
        case 'card-mini':
        case 'card-mid':
        case 'card-universal':
        case 'recent':
          let type = '';
          if (pane.contentFilter) {
            if (pane.contentFilter.content_type instanceof Array) {
              type = BaseRouter.getNameByContentType(pane.contentFilter.content_type[0]);
            } else {
              type = BaseRouter.getNameByContentType(pane.contentFilter.content_type);
            }
          }
          return type ? `/constructor/${type}` : '/constructor';
      }
    }
    return null;
  }

  public getSeeAllLinkByPane(pane: IContentPane): string {
    if (pane) {
      switch (pane.format as string) {
        case 'subjects':
          return `/${this.options.baseRoute}/subjects`;

        case 'card-micro':
        case 'card-mini':
        case 'card-mid':
        case 'card-universal':
        case 'recent':
          if (pane.contentFilter) {
            const libRouter = new LibraryRouter(this.options);
            // Types
            let types: string[] = [];
            if (pane.contentFilter.content_type instanceof Array) {
              types = pane.contentFilter.content_type.map((ct) => BaseRouter.getNameByContentType(ct));
            } else {
              types = [BaseRouter.getNameByContentType(pane.contentFilter.content_type)];
            }
            libRouter.setContentTypes(types);
            return libRouter.getLink().path;
          }
          break;
      }
    }
    return null;
  }

  public setBaseRoute(route: EContentPanelRoute) {
    this.options.baseRoute = route;
  }

  public getBaseRoute() {
    return this.options.baseRoute;
  }

  public addSubject(code: string) {
    this.options.subjectCodes.push(code);
  }

  public setSubject(code: string) {
    this.options.subjectCodes = [code];
  }

  public removeSubject(code: string) {
    this.options.subjectCodes = this.options.subjectCodes.filter((subject) => subject !== code);
  }

  public addContentTypeByCode(code: string) {
    this.options.contentTypeCodes.push(code);
  }

  public setContentTypeByItem(item: ContentTypeEnum | UserServiceCategory) {
    let code = '';
    if (typeof item === 'number') {
      code = BaseRouter.getNameByContentType(item);
    } else {
      code = item.code;
    }
    if (code) {
      this.options.contentTypeCodes = [code];
    }
  }

  public addContentTypeByTypeId(contentType: ContentTypeEnum) {
    const code = BaseRouter.getNameByContentType(contentType);
    this.options.contentTypeCodes.push(code);
  }

  public removeContentTypeByName(code: string) {
    this.options.contentTypeCodes = this.options.contentTypeCodes.filter((type) => type !== code);
  }

  public removeContentTypeById(contentTypeName: string) {
    const code = BaseRouter.getNameByContentType(ContentTypeEnum[contentTypeName]);
    this.options.contentTypeCodes = this.options.contentTypeCodes.filter((type) => type !== code);
  }

  public removeLanguage(code: string) {
    this.options.languageCodes = this.options.languageCodes.filter((language) => language !== code);
  }

  public setTerm(term: string) {
    this.options.term = term;
  }

  public switchSubject(code: string) {
    if (this.options.subjectCodes.indexOf(code) > -1) {
      this.removeSubject(code);
    } else {
      this.addSubject(code);
    }
  }

  public switchType(code: string) {
    if (this.options.contentTypeCodes.indexOf(code) > -1) {
      this.removeContentTypeByName(code);
    } else {
      this.addContentTypeByCode(code);
    }
  }

  public setContentMode(contentMode: EContentModeType) {
    this.options.contentMode = contentMode;
  }

  public setSubjects(subjectCodes: string[] = []) {
    if (!!subjectCodes.length) {
      if (localStorage || false) {
        if (localStorage.getItem('subjectCodes')) {
          localStorage.removeItem('subjectCodes');
        }
      }
    }
    this.options.subjectCodes = [...subjectCodes];
  }

  public getSubjects() {
    return this.options.subjectCodes;
  }

  public setContentTypes(contentTypeCodes: string[]) {
    this.options.contentTypeCodes = contentTypeCodes;
  }

  public getContentTypes() {
    return this.options.contentTypeCodes;
  }

  public setOrder(order: EContentOrder) {
    this.options.contentOrder = order;
  }

  public setLanguageCodes(codes: string[]) {
    this.options.languageCodes = [...codes];
  }

  public setLibraryFilterData(libraryFilterData: CLibraryFilterData) {
    this.setBaseRoute(libraryFilterData.baseRoute);
    this.setSubjects(libraryFilterData.subjectCodes);
    this.setContentTypes(libraryFilterData.contentTypeCodes);
    this.setLanguageCodes(libraryFilterData.languageCodes);
  }

  /** Полная ссылка на страницу контента */
  public static contentRouteUniversal(item: Content | Partial<Content>): string {
    if (item) {
      const subjectCode = item.subject.code || '-';
      const typeCode = item.type ? LibraryRouter.getNameByContentType(item.type) : '';
      const title = (item.title || '')
        .replace(/[\\\/:;{}\[\]()_,-.!?'"`+#&=]/g, '')
        .trim()
        .replace(/\s+/g, '-');
      return [
        '/' + subjectCode,
        EContentPanelRoute.lessons,
        typeCode,
        // item.author.slug,
        (title.length ? title + '-' : '') + item.slug,
      ].join('/');
    } else {
      return null;
    }
  }

  /** Полная ссылка на страницу индивидуального занятия */
  public static userServiceRouteUniversal(item: UserService): string {
    if (item) {
      const subjectCode = item.subject?.code || '-';
      const typeCode = item.category?.code || '-';
      const title = (item.title || '')
        .replace(/[\\\/:;{}\[\]()_,-.!?'"`+#&=]/g, '')
        .trim()
        .replace(/\s+/g, '-');
      return [
        '/' + subjectCode,
        EContentPanelRoute.classes,
        typeCode,
        (title.length ? title + '-' : '') + item.slug,
      ].join('/');
    } else {
      return null;
    }
  }

  /** Полная ссылка на страницу коллекции */
  public static collectionRouteUniversal(item: Collection): string {
    if (item && item.slug) {
      const subjectCode = item.subject?.code || '-';
      const typeCode = 'collection'; // item.category?.code || '-';
      const title = (item.title || '')
        .replace(/[\\\/:;{}\[\]()_,-.!?'"`+#&=]/g, '')
        .trim()
        .replace(/\s+/g, '-');
      return [
        '/' + subjectCode,
        EContentPanelRoute.collections,
        typeCode,
        (title.length ? title + '-' : '') + item.slug,
      ].join('/');
    }
    return null;
  }

  public static convertContentTypesToFilter(contentCodes: string[]): IFilter[] {
    return contentCodes
      .map((_cc) => BaseRouter.getContentTypeByName(_cc))
      .filter((_ct) => _ct)
      .map((_ct) => ({
        title: `content-types-title.${ContentTypeEnum[_ct]}`,
        code: `${ContentTypeEnum[_ct]}`,
        type: 'contentType',
      }));
  }

  public getLink(options?: Partial<IGetLinkOptions>): UrlSegment {
    let subjectCodes = [...(this.options.subjectCodes || [])];
    let typesMap = [...(this.options.contentTypeCodes || [])];
    if (options?.subjectCode) {
      subjectCodes = LibraryRouter.switchLine(subjectCodes, options.subjectCode);
    }
    if (options?.typeCode) {
      typesMap = LibraryRouter.switchLine(typesMap, options.typeCode);
    }
    subjectCodes = LibraryRouter.cleanMap(subjectCodes);
    typesMap = LibraryRouter.cleanMap(typesMap);
    let url = '';
    if (subjectCodes?.length > 0) {
      url += '/' + subjectCodes.join(',');
    }
    /**
     * @todo
     * Исправление для задач:
     * https://app.clickup.com/t/1phkcgz
     * https://app.clickup.com/t/1pc23ya
     *
     * Но портится url, возможно имеет смысл обсудить.
     */
    // const baseRoute = this.options.baseRoute === EContentPanelRoute.main ? '' : this.options.baseRoute;
    const baseRoute = this.options.baseRoute;
    url += `/${baseRoute}`;
    if (typesMap && typesMap.length > 0) {
      if (!subjectCodes || subjectCodes.length < 1) {
        url += '/-';
      }
      url += '/' + typesMap.join(',');
    }

    const mode = LibraryRouter.contentModeTypeUrlMap[this.options.contentMode];
    if (mode) {
      url += '/' + mode;
    }

    const segment = new UrlSegment(url, {});
    if (this.options.contentOrder !== EContentOrder.popularity_week) {
      // const order = this.options.contentOrder; // || EContentOrder.popularity_week;
      segment.parameters = {
        ...segment.parameters,
        order: this.options.contentOrder,
      };
    }
    if (this.options.languageCodes && this.options.languageCodes.length > 0) {
      const languages = this.options.languageCodes.join(',');
      segment.parameters = {
        ...segment.parameters,
        languages,
      };
      // return new UrlSegment(url, { order, languages });
    }
    if (this.options.term) {
      segment.parameters = {
        ...segment.parameters,
        term: this.options.term,
      };
    }

    return segment;
  }

  public getUpLink(): UrlSegment {
    const upperRouter = this.getUpRouter();
    return upperRouter ? upperRouter.getLink() : this.getLink();
  }

  public getUpRouter() {
    /**
     * Сделано по реальной задаче:
     * https://app.clickup.com/t/12w2y4n
     *
     * Переделано по задаче:
     * https://app.clickup.com/t/1p67kjq
     */
    if (
      this.options.contentTypeCodes?.length === 0 &&
      this.options.subjectCodes?.length === 0 &&
      this.options.baseRoute === EContentPanelRoute.main
    ) {
      return null;
    }
    const upperRouter = new LibraryRouter(this.options);
    if (this.options.contentTypeCodes && this.options.contentTypeCodes.length > 0) {
      upperRouter.setContentTypes([]);
    } else {
      if (this.options.baseRoute !== EContentPanelRoute.main) {
        upperRouter.setBaseRoute(EContentPanelRoute.main);
        // if (this.options.subjectCodes && this.options.subjectCodes.length > 0) {
        //   upperRouter.setSubjects([]);
        // } else {
        //   upperRouter.setBaseRoute(EContentPanelRoute.main);
        // }
      } else {
        if (this.options.subjectCodes && this.options.subjectCodes.length > 0) {
          upperRouter.setSubjects([]);
        } else {
          upperRouter.setBaseRoute(EContentPanelRoute.main);
        }
      }
    }
    return upperRouter;
  }

  private static cleanMap(map: string[]) {
    return Array.from(new Set(map)).filter((s) => s);
  }

  private static switchLine(map: string[], line: string) {
    if (!map || !line) {
      return map;
    }
    if (map.indexOf(line) > -1) {
      return map.filter((type) => type !== line);
    } else {
      return [...map, line];
    }
  }
}
