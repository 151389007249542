import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService, AuthService, FilesService, IconsService } from '@core/services';
import { User, UserProfileVerificationEnum as VE, UserStatusEnum } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiCover } from 'onclass-ui';

enum StatusIcons {
  not_verified = 'status-not-verified',
  on_moderation = 'status-on-moderation',
  modified = 'status-modified',
  verified = 'status-verified',
}

@Component({
  selector: 'app-verification-widget-user',
  templateUrl: './verification-widget-user.component.html',
  styleUrls: ['./verification-widget-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerificationWidgetUserComponent implements OnInit {
  @Input() public user: User;
  @Input() public verification_status: VE;
  @Input() public showUserName: boolean;
  @Input() public displayMode: string;
  public statusIcons = {
    [VE.incomplete]: StatusIcons.not_verified,
    [VE.not_verified]: StatusIcons.not_verified,
    [VE.sent]: StatusIcons.on_moderation,
    [VE.approved]: StatusIcons.verified,
    [VE.declined]: StatusIcons.not_verified,
    [VE.changed]: StatusIcons.modified,
    [VE.resent]: StatusIcons.on_moderation,
    [VE.redeclined]: StatusIcons.verified,
  };
  private popoverStatuses = [VE.incomplete, VE.not_verified, VE.declined, VE.redeclined];
  public verifications = VE;
  public svgsetIcon = IconsService.svgsetIconUrl;

  constructor(public deviceService: DeviceDetectorService, private authService: AuthService, private router: Router) {}

  ngOnInit() {}

  public get cover(): OnUiCover {
    return {
      type: 'avatar',
      img: FilesService.getFileUrlBySize(this.user?.userpic_id),
      title: AccountService.getUserFullName(this.user),
      form: 'circle',
    };
  }

  public get userFullName(): string {
    return AccountService.getUserFullName(this.user);
  }

  public get isPopoverNeeded(): boolean {
    return this.popoverStatuses.includes(this.verification_status);
  }

  public accountRoute(extra: string[] = []): string[] {
    return AccountService.accountRoute(this.user, extra);
  }

  public get isGuest(): boolean {
    return this.user?.status === UserStatusEnum.guest;
  }

  public authGuardedNavigate(url: string) {
    this.authService.showAuthModal(() => {
      this.navigateByUrl(url);
    });
  }

  public navigateByUrl(url: string) {
    if (url) {
      this.router.navigateByUrl(url);
    }
  }
}
