import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { IMeetingReservationNotice, INoticeParams } from 'lingo2-models';
import { EModifier, IBaseNotification } from '../../base-notification/base-notification.component';

@Component({
  selector: 'app-meeting-reservation',
  templateUrl: './meeting-reservation.component.html',
  styleUrls: ['./meeting-reservation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeetingReservationComponent {
  public params: IMeetingReservationNotice;
  public base: IBaseNotification;

  @Input() set notification(notification: INoticeParams) {
    this.params = notification.params as IMeetingReservationNotice;
    this.base = {
      title: 'notifications.meeting_reservation.title',
      icon: 'notice-card',
      created_at: notification.created_at,
      modifier: EModifier.yellow,
    };
  }
}
