import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AlertModule } from '@app/core/components/alert/alert.module';
import { TranslateModule } from '@ngx-translate/core';
import { OnUiButtonModule } from 'onclass-ui';
import { InvalidAlertComponent } from './invalid-alert.component';

@NgModule({
  declarations: [InvalidAlertComponent],
  imports: [CommonModule, TranslateModule, AlertModule, OnUiButtonModule],
  exports: [InvalidAlertComponent],
})
export class InvalidAlertModule {}
