import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {
  OnboardingTrackersModule,
  OnboardingListPanelModule,
  OnboardingChecklistModule,
  OnboardingPersonalInfoModule,
  OnboardingProgressbarModule,
  OnboardingQrCodeModule,
  OnboardingUserStatusModule,
  OnboardingTitleModule,
} from './index';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    OnboardingTrackersModule,
    OnboardingListPanelModule,
    OnboardingChecklistModule,
    OnboardingPersonalInfoModule,
    OnboardingProgressbarModule,
    OnboardingQrCodeModule,
    OnboardingUserStatusModule,
    OnboardingTitleModule,
  ],
  exports: [
    OnboardingTrackersModule,
    OnboardingListPanelModule,
    OnboardingChecklistModule,
    OnboardingPersonalInfoModule,
    OnboardingProgressbarModule,
    OnboardingQrCodeModule,
    OnboardingUserStatusModule,
    OnboardingTitleModule,
  ],
})
export class OnboardingSharedModule {}
