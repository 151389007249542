<div class="about">
  <span class="about__title">
    <b>
      {{ 'about-verification-widget.verified-title' | translate }}
    </b>
  </span>

  <ul class="about__list">
    <li>- {{'about-verification-widget.text-1' | translate}}</li>
    <li>- {{'about-verification-widget.text-2' | translate}}</li>
    <li>- {{'about-verification-widget.text-3' | translate}}</li>
    <li>- {{'about-verification-widget.text-4-1' | translate}} <span class="link blue">onclass.com</span> {{'about-verification-widget.text-4-2' | translate}}</li>
    <li>- {{'about-verification-widget.text-5' | translate}}</li>
    <li>- {{'about-verification-widget.text-6' | translate}}</li>
  </ul>

  <b>{{ 'about-verification-widget.not-verified-title' | translate }}</b>

  <ul class="about__list">
    <li>- {{'about-verification-widget.text-1' | translate}}</li>
  </ul>

</div>