import { Injectable, NgZone } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { PlatformService } from '@core/services/platform.service';
import { environment } from '@env/environment';
import { DestroyableComponent } from '@models/destroyable.component';
import { AnyType } from 'lingo2-models';
import { takeUntil } from 'rxjs/operators';

declare let gtag: (...arg) => void;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService extends DestroyableComponent {
  private inited = false;

  public constructor(private router: Router, protected readonly platform: PlatformService, private zone: NgZone) {
    super(platform);
    this.init();
  }

  protected init() {
    this.onBrowserOnly(() => {
      if (environment.ga_id && window['gtag']) {
        this.inited = true;
        this.router.events.pipe(takeUntil(this.destroyed$)).subscribe((event) => {
          if (event instanceof NavigationEnd) {
            this.pageView(event.urlAfterRedirects);
          }
        });
      }
    });
  }

  /** Зарегистрировать просмотр страницы */
  public pageView(url: string, referer?: string) {
    if (this.inited) {
      this.zone.runOutsideAngular(() => {
        gtag('config', environment.ga_id, { page_path: url });
      });
    }
  }

  /**
   * Отправить событие в Google Analitics
   *
   * @see https://analytics.google.com/analytics/web/#/report-home/a157703396w221919393p210918229 для OnClass
   * @see https://developers.google.com/analytics/devguides/collection/gtagjs/events?hl=ru
   *
   * @param action  это строка, которая указывается в отчетах Google Аналитики как действие по событию
   * @param eventCategory категория события
   * @param eventLabel ярлык события
   * @param eventValue ценность события, выраженная целым неотрицательным числом
   */
  public event(action: string, eventCategory: string, eventLabel?: string, eventValue?: number) {
    if (this.inited) {
      this.zone.runOutsideAngular(() => {
        const params: AnyType = {
          event_category: eventCategory,
        };
        if (typeof eventLabel !== 'undefined') {
          params.event_label = eventLabel;
        }
        if (typeof eventValue !== 'undefined') {
          params.value = eventValue;
        }
        gtag('event', action, params);
      });
    }
  }
}
