import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OnboardingSharedModule } from '@core/widgets/onboarding-widget/shared/onboarding-shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from 'lingo2-forms';
import { OnUiButtonModule } from 'onclass-ui';
import { ProfileFillCardComponent } from './profile-fill-card.component';

@NgModule({
  declarations: [ProfileFillCardComponent],
  imports: [CommonModule, TranslateModule, IconModule, OnboardingSharedModule, OnUiButtonModule],
  exports: [ProfileFillCardComponent],
})
export class ProfileFillCardModule {}
