import { createAction, props } from '@ngrx/store';
import { IPagedResults, Classroom, IFindClassroomsFilter } from 'lingo2-models';

export const requestFail = createAction('[Classrooms] Request: fail', props<{ error: string }>());

export const loadClassrooms = createAction('[Classrooms] Load classrooms');
export const loadClassroomsSuccess = createAction(
  '[Classrooms] Load classrooms: success',
  props<{ response: IPagedResults<Classroom[]> }>(),
);

export const clearClassrooms = createAction('[Classrooms] Clear');

export const loadNextPageClassrooms = createAction('[Classrooms] Load next page');

export const setClassroomsFilter = createAction(
  '[Classrooms] Set filter',
  props<{ filter: Partial<IFindClassroomsFilter> }>(),
);
