import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { ChangableComponent } from '@app/models/changable.component';
import { IconsService, PlatformService, ScreenService } from '@core/services';
import { AnyType, GenericNotice, NoticeTypeEnum } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { takeUntil } from 'rxjs/operators';
import { NotificationsService } from '../notifications.service';

const getYMDDate = (date: Date | string): string => {
  date = typeof date === 'string' ? new Date(date) : date;
  const year = date.getFullYear();
  let month: any = date.getMonth() + 1;
  let day: any = date.getDate();
  month = month < 10 ? '0' + month : month;
  day = day < 10 ? '0' + day : day;
  return `${year}-${month}-${day}`;
};

const sortNotices = (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime();

@Component({
  selector: 'app-notifications-widget',
  templateUrl: './notifications-widget.component.html',
  styleUrls: ['./notifications-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsWidgetComponent extends ChangableComponent implements OnInit, OnDestroy {
  @ViewChild('scroll')
  private scrollElement: ElementRef;

  public dateBlocks = {};
  public dates: string[];
  public initialNotices: Array<GenericNotice<AnyType>>;
  public svgSetIcon = IconsService.svgsetIconUrl;

  constructor(
    private notificationsService: NotificationsService,
    private screenService: ScreenService,
    public deviceService: DeviceDetectorService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngOnInit() {
    this.initialNotices = this.notificationsService.getNotices();

    this.initialNotices.forEach((notice) => {
      this.addNoticeToDateBlock(notice);
    });

    // Subscribe to single notice
    this.notificationsService.notice$.pipe(takeUntil(this.destroyed$)).subscribe((notice: GenericNotice<AnyType>) => {
      this.addNoticeToDateBlock(notice);
      this.scrollTop();
    });

    // Subscribe to bulk notices
    this.notificationsService.notices$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((notices: Array<GenericNotice<AnyType>>) => {
        notices.forEach((notice) => {
          this.addNoticeToDateBlock(notice);
        });
        this.scrollTop();
      });
  }

  public get notificationsCount() {
    return this.notificationsService.getNotices()?.length;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.screenService.setBodyFixed(false);
  }

  public getDateAlias(date: string): string {
    const now = new Date();
    const today = getYMDDate(now);
    const yesterday = getYMDDate(new Date(now.getTime() - 24 * 60 * 60 * 1000));

    if (date === today) {
      return 'notifications.today';
    }

    if (date === yesterday) {
      return 'notifications.yesterday';
    }

    return null;
  }

  public convertToRegularDate(date: string): Date {
    return new Date(date);
  }

  public close(): void {
    this.notificationsService.closeWidget();
    this.screenService.setBodyFixed(false);
  }

  public trackByFn(notice: GenericNotice<AnyType>) {
    return notice.id;
  }

  public noticeType(type: NoticeTypeEnum): string {
    return NoticeTypeEnum[type] || type.toString();
  }

  private scrollTop(): void {
    this.setTimeout(() => {
      this.scrollElement.nativeElement.scrollTop = 0;
    }, 0);
  }

  private addNoticeToDateBlock(notice: GenericNotice<AnyType>): void {
    const date = getYMDDate(notice.created_at);

    if (!this.dateBlocks[date]) {
      this.dateBlocks[date] = [];
    }

    this.dateBlocks[date] = [...this.dateBlocks[date], notice].sort(sortNotices);
    this.dates = Object.keys(this.dateBlocks).sort().reverse();
    this.detectChanges();
  }

  public onMouseenter() {
    this.screenService.setBodyFixed(true);
  }

  public onMouseleave() {
    this.screenService.setBodyFixed(false);
  }
}
