import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FeaturesService } from '@core/services/features.service';
import { AuthService } from '@core/services/lingo2-account/auth.service';
import { ConfigService } from '@core/services/lingo2-content/config.service';
import { PlatformService } from '@core/services/platform.service';
import { DestroyableComponent } from '@models/destroyable.component';
import { Store } from '@ngrx/store';
import { CLibraryFilterData } from '@store/models';
import { getLibraryRouteData } from '@store/reducers/library.reducer';
import { EContentPanelRoute, SubjectsRegistry, TenantEnum } from 'lingo2-models';
import { Subject as BaseSubject } from 'lingo2-models/dist/content/subject';
import { uniqBy } from 'lodash';
import { combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

const onClassMePages = [
  '/my-classes',
  '/my-library',
  '/my-vocabulary',
  '/schedule',
  '/u/',
  '/profile',
  '/me',
  '/classes/join',
  '/classrooms',
  '/constructor',
  '/go/class',
  '/auth',
];

@Injectable({
  providedIn: 'root',
})
export class UtilsService extends DestroyableComponent {
  public selectedSubjects: BaseSubject[] = [];
  public isContentPage: boolean;
  private _subjectsRegistry: SubjectsRegistry;
  private libraryRouterData: CLibraryFilterData;

  constructor(
    private router: Router,
    public store: Store,
    protected configService: ConfigService,
    protected features: FeaturesService,
    protected authService: AuthService,
    protected readonly platform: PlatformService,
  ) {
    super(platform);
    this.router.events.pipe(takeUntil(this.destroyed$)).subscribe((route) => {
      if (route instanceof NavigationEnd) {
        if (this.features.tenant === TenantEnum.onclass_me && route.url !== '/') {
          const ifRoute = onClassMePages.find((el) => route.url.includes(el));
          if (!ifRoute) {
            this.router.navigate([EContentPanelRoute['my-classes/dashboard']]).then();
            return;
          }
        }
        this.calcContentPage();
      }
    });

    combineLatest([this.configService.subjectsV2$, this.store.select(getLibraryRouteData).pipe(filter((v) => !!v))])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([subjectsRegistry, libraryRouterData]) => {
        this._subjectsRegistry = subjectsRegistry;
        this.libraryRouterData = libraryRouterData;
        this.selectedSubjects = this.findSubjectsByCode(libraryRouterData?.subjectCodes || []);
      });
  }

  private findSubjectsByCode(subjectsCodes: string[]): BaseSubject[] {
    return uniqBy(
      [
        // ...this._subjectsRegistry.other,
        ...this._subjectsRegistry.user,
        ...this._subjectsRegistry.native,
        ...this._subjectsRegistry.foreign,
        ...this._subjectsRegistry.non_categorized,
        ...this._subjectsRegistry.recent,
      ].filter((_s) => !!subjectsCodes.find((_sc) => _sc === _s.code)),
      'id',
    );
  }

  // @TODO: Возможно можно упростить
  public calcContentPage(checkUrl?: string) {
    let counter = 0;
    let url: string;

    if (checkUrl) {
      url = checkUrl;
    } else {
      url = this.router.url;
    }

    const isContentPath = (path) => url.indexOf(path) === 0;
    const hasContentTypeCodes = !this.libraryRouterData.contentTypeCodes.length;
    const hasSubjectCodes = !!this.libraryRouterData.subjectCodes.length;
    const isEmptyItem = !this.libraryRouterData.item || this.libraryRouterData.item === '';
    const isBaseRoute = (route) => this.libraryRouterData.baseRoute === route;
    const isNotRestrictedPath = !(
      url.indexOf('/favorites') !== -1 ||
      url.indexOf('/my-vocabulary') !== -1 ||
      url.indexOf('/my-library') !== -1 ||
      url.indexOf('/my-classes') !== -1 ||
      url.indexOf('/u/') !== -1
    );

    const contentRoutes = ['/main', '/classes', '/tutors', '/lessons', '/collections', '/schools'];

    contentRoutes.forEach((route) => {
      if (url.indexOf(route) !== -1) {
        if (isContentPath(route)) {
          if (isContentPath('/schools')) {
            if (hasContentTypeCodes) {
              counter++;
            } else {
              return;
            }
          }
          if (url.indexOf('/join/') === -1) {
            counter++;
          }
        } else {
          if (isNotRestrictedPath) {
            if (
              isBaseRoute('main') ||
              isBaseRoute('classes') ||
              isBaseRoute('lessons') ||
              isBaseRoute('tutors') ||
              isBaseRoute('collections') ||
              isBaseRoute('schools')
            ) {
              if ((hasContentTypeCodes || hasSubjectCodes) && isEmptyItem) {
                counter++;
              }
            }
          }
        }
      }
    });

    if (checkUrl) {
      return counter > 0;
    } else {
      this.isContentPage = counter > 0;
    }
  }

  /** Монстр который парсит регулярные выражения и конвертирует их в маску (ngx-mask) для input */
  public regexToMask(regex) {
    if (!regex) {
      return null;
    }
    return new RegExp(regex).source
      .replace(/^\^|\$$/g, '')
      .replace(/\\d/g, '0')
      .replace(/\\w/g, 'S')
      .replace(/LT/g, 'U{2}')
      .replace(/\\-/g, '-')
      .replace(/\(([^)]*)\)|\[([^^])\]|\\([\\/.(){}[\]])/g, '$1$2$3')
      .replace(/\[(\w\-\w)(\w\-\w)?\]\{(\d+),?(\d+)?\}/gi, function (_, t1, t2, c1, c2) {
        let str = '';
        let counter = c1;
        if (c2) {
          counter = c2;
        }
        if (t1 === 'A-Z') {
          if (t2 && t2 === 'a-z') {
            str = str + `S{${counter}}`;
          } else if (t2 && t2 === '0-9') {
            str = str + `A{${counter}}`;
          } else {
            str = str + `U{${counter}}`;
          }
        } else if (t1 === 'a-z') {
          if (t2 && t2 === 'A-Z') {
            str = str + `S{${counter}}`;
          } else if (t2 && t2 === '0-9') {
            str = str + `A{${counter}}`;
          } else {
            str = str + `L{${counter}}`;
          }
        } else if (t1 === '0-9') {
          if (t2 && t2 === 'a-z') {
            str = str + `A{${counter}}`;
          } else if (t2 && t2 === 'A-Z') {
            str = str + `A{${counter}}`;
          } else {
            str = str + `0{${counter}}`;
          }
        } else {
          str = str + `A{${counter}}`;
        }
        return str;
      });
  }
}
