import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FeaturesService } from '@core/services';
import { FeatureEnum, IUserTracker, User } from 'lingo2-models';
import { IListPanelOption } from '../models';
import { OnboardingStatusesEnum as OSE } from '../onboarding-widget.component';
import { OnboardingWidgetService } from '../onboarding-widget.service';
import { IChecklistOption } from '../shared/onboarding-checklist/onboarding-checklist.component';

@Component({
  selector: 'app-onboarding-tutor',
  templateUrl: './onboarding-tutor.component.html',
  styleUrls: ['./onboarding-tutor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingTutorComponent implements OnInit {
  @Input() set me(me: User) {
    this._me = me;
    this.onboardingWidgetService.getCheckOptions(me).then((res) => {
      this.checklistOptions = res.filter((x) => !x.checked);
      this.cdr.detectChanges();
    });
    this.panelOptions = this.onboardingWidgetService.generateListPanelOptions(me);
    this.trackers = me.trackers;
    this.showMobileQr = this.onboardingWidgetService.isMobileNotUsed(me);
  }

  public status = OSE.tutor;
  public isExpanded$ = this.onboardingWidgetService.isStatusExpanded$;
  public showMobileQr: boolean;
  public checklistOptions: IChecklistOption[];
  public panelOptions: IListPanelOption[];
  public trackers: IUserTracker[];
  protected _me: User;

  constructor(
    private onboardingWidgetService: OnboardingWidgetService,
    private features: FeaturesService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {}

  public isTrackersAvailable(): boolean {
    return this.features.isAvailable(FeatureEnum.onboarding_trackers, this._me);
  }
}
