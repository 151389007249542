import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IconsService } from '@app/core/services';

@Component({
  selector: 'app-validation-error',
  templateUrl: './validation-error.component.html',
  styleUrls: ['./validation-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationErrorComponent implements OnInit {
  @Input() text: string;
  @Input() imageSrc: string;

  public svgsetIcon = IconsService.svgsetIconUrl;

  constructor() {}

  ngOnInit(): void {}
}
