import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventActionEnum, EventCategoryEnum } from '@app/core/services/analytics';
import { RemoteConfigType } from '@app/core/services/remote-config/constants';
import { RemoteConfigService } from '@app/core/services/remote-config/remote-config.service';
import { UserRoles } from '@app/first-contact/first-contact.component';
import { AnalyticsService, AuthService, BillingService, PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { takeUntil } from 'rxjs';
import { texts } from './first-contact-tools.variables';

@Component({
  selector: 'app-first-contact-tools',
  templateUrl: './first-contact-tools.component.html',
  styleUrls: ['./first-contact-tools.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirstContactToolsComponent extends ChangableComponent implements OnInit {
  @Input()
  public selectedRole: UserRoles;

  public texts = texts;

  private remoteConfig: RemoteConfigType;

  public constructor(
    public deviceService: DeviceDetectorService,
    private authService: AuthService,
    private billingService: BillingService,
    private remoteConfigService: RemoteConfigService,
    private analytics: AnalyticsService,
    private router: Router,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  public ngOnInit(): void {
    this.onBrowserOnly(() => {
      this.remoteConfigService.config$.pipe(takeUntil(this.destroyed$)).subscribe((config) => {
        this.remoteConfig = config;
        this.detectChanges();
      });
    });
  }

  public get text(): string {
    return this.texts[this.selectedRole];
  }

  public get isDisplay(): boolean {
    return this.selectedRole !== UserRoles.student;
  }

  public onCreateLesson() {
    const options = {
      caller: 'app-first-contact-tools',
      reason: 'create lesson',
    };

    this.analytics.event(EventActionEnum.lesson_creating, EventCategoryEnum.content, options.caller);

    const _continue = () => {
      if (this.remoteConfig?.createLesson__paywall) {
        // показать выбор тарифа, а если тариф есть, то отправить на целевую страницу
        this.billingService.showPaywall(() => this.goConstructor(), options);
      } else {
        // сразу отправить на целевую страницу
        this.goConstructor();
      }
    };

    if (this.remoteConfig?.createLesson__auth) {
      // показать диалог авторизации, а после авторизации продолжить
      this.authService.showAuthModal(() => _continue(), options);
    } else {
      // продолжить даже если не авторизован
      _continue();
    }
  }

  protected goConstructor() {
    if (this.isBrowser) {
      // если получится - открыть в отдельном окне
      try {
        const url = this.router.serializeUrl(this.router.createUrlTree(['/constructor']));
        window.open(url, '_blank');
      } catch (e) {
        // если в отдельном окне не открылось, то перейти в конструктор в текущем окне
        void this.router.navigate(['/constructor']);
      }
    }
  }
}
