<div class="toolbar">
  <div class="toolbar-item toolbar-item-image" *ngIf="columns > 0"
       [class.active]="aspectPopupOpen"
       [appPopover]="aspectRatioPopover" position="top" [popoverOnly]="true"
       (opened)="onAspectRatioPopoverOpened($event)">
    <svg class="icon-stretch">
      <use [attr.xlink:href]="svgsetIcon('stretch')"></use>
    </svg>

    <ng-template #aspectRatioPopover>
      <app-aspect-ratio-selector
        class="spect-ratio-selector"
        [aspectRatio]="aspectRatio || 'square'"
        (changed)="onAspectRatioChanged($event)"></app-aspect-ratio-selector>
    </ng-template>

  </div>

  <div class="toolbar-item toolbar-item-image"
       [class.active]="gridsizePopupOpen"
       [appPopover]="gridSizePopover" position="right-bottom" [popoverOnly]="true"
       (opened)="onGridSizePopoverOpened($event)">
    <svg class="icon-table">
      <use [attr.xlink:href]="svgsetIcon('table')"></use>
    </svg>

    <ng-template #gridSizePopover>
      <app-grid-size-selector [width]="columns"
                              [height]="rows"
                              [maxWidth]="4"
                              [maxHeight]="4"
                              (changed)="onGridsizeChanged($event)">
      </app-grid-size-selector>
    </ng-template>
  </div>

</div>

<div #wizardPointer inViewport (inViewportAction)="wizardPointerViewportChange($event)"
     class="wizard-pointer"
     *ngIf="wizard.active"
     [class.point-gridsize]="wizard.step === 'choose-gridsize'"
     [class.point-aspect]="wizard.step === 'choose-aspect'">
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.2519 6.24994C15.5616 6.24994 15.0019 6.80958 15.0019 7.49993V6.66661C15.0019 5.97626 14.4423 5.41662 13.7519 5.41662C13.0616 5.41662 12.5019 5.97626 12.5019 6.66661V5.83329C12.5019 5.14294 11.9423 4.5833 11.2519 4.5833C10.5616 4.5833 10.0019 5.14294 10.0019 5.83329V1.66662C10.0019 0.976273 9.44229 0.416626 8.75194 0.416626C8.06159 0.416626 7.50195 0.976273 7.50195 1.66662V12.0833L4.60863 10.6366C4.31937 10.492 4.00039 10.4166 3.67695 10.4166C3.02801 10.4166 2.50196 10.9427 2.50196 11.5916V11.5966C2.50129 11.9091 2.62516 12.209 2.84613 12.4299L8.23444 17.8182C9.36659 18.9492 10.9017 19.5841 12.5019 19.5832C15.2633 19.5832 17.5019 17.3446 17.5019 14.5832V7.49993C17.5019 6.80958 16.9423 6.24994 16.2519 6.24994Z"
      fill="#F0BEB4"></path>
    <path
      d="M10.4184 4.90796V9.58296C10.4184 9.81308 10.2318 9.99964 10.0017 9.99964V5.83296C10.0001 5.47886 10.1521 5.1414 10.4184 4.90796Z"
      fill="#FFB74D"></path>
    <path
      d="M12.9184 5.74133V9.583C12.9184 9.81312 12.7318 9.99968 12.5017 9.99968V6.66637C12.5001 6.31219 12.6521 5.97473 12.9184 5.74133Z"
      fill="#FFB74D"></path>
    <path
      d="M15.4184 6.57471V9.58302C15.4184 9.81314 15.2318 9.9997 15.0017 9.9997V7.4997C15.0001 7.14556 15.1521 6.80814 15.4184 6.57471Z"
      fill="#FFB74D"></path>
    <path
      d="M12.4991 20C10.7873 20.0048 9.14465 19.3245 7.93742 18.1108L2.54911 12.7225C1.92642 12.0994 1.92669 11.0895 2.54977 10.4668C2.84809 10.1687 3.25235 10.0008 3.67411 9.99998C4.06196 10.0003 4.44442 10.0904 4.79161 10.2633L7.08242 11.4091V1.66668C7.08246 0.74621 7.82863 0 8.7491 0C9.66957 0 10.4158 0.74621 10.4158 1.66668V4.39085C11.2128 3.93034 12.2322 4.20308 12.6927 5.00007C12.7446 5.08999 12.7881 5.18452 12.8224 5.28253C13.5873 4.77042 14.6224 4.97534 15.1346 5.74018C15.2128 5.85698 15.2759 5.98323 15.3224 6.11585C16.0875 5.60405 17.1226 5.8094 17.6344 6.57448C17.8176 6.84839 17.9155 7.17046 17.9157 7.50002V14.5833C17.9121 17.5733 15.4891 19.9963 12.4991 20ZM3.67411 10.8333C3.25345 10.8345 2.91341 11.1765 2.91462 11.5971C2.9152 11.7983 2.99528 11.991 3.13747 12.1333L8.52578 17.5216C9.57746 18.5785 11.0082 19.1708 12.4991 19.1666C15.029 19.1634 17.0792 17.1132 17.0824 14.5833V7.49999C17.0824 7.03975 16.7093 6.66667 16.2491 6.66667C15.7889 6.66667 15.4158 7.03975 15.4158 7.49999V9.5833C15.4158 9.81342 15.2292 9.99998 14.9991 9.99998C14.769 9.99998 14.5824 9.81342 14.5824 9.5833V6.66663C14.5824 6.20639 14.2093 5.83331 13.7491 5.83331C13.2889 5.83331 12.9158 6.20639 12.9158 6.66663V9.5833C12.9158 9.81342 12.7292 9.99998 12.4991 9.99998C12.269 9.99998 12.0824 9.81342 12.0824 9.5833V5.83331C12.0824 5.37308 11.7093 4.99999 11.2491 4.99999C10.7889 4.99999 10.4158 5.37308 10.4158 5.83331V9.5833C10.4158 9.81342 10.2292 9.99998 9.9991 9.99998C9.76898 9.99998 9.58242 9.81342 9.58242 9.5833V1.66668C9.58242 1.20644 9.20933 0.833358 8.7491 0.833358C8.28887 0.833358 7.91578 1.20644 7.91578 1.66668V12.0833C7.91578 12.3135 7.72922 12.5 7.4991 12.5C7.4343 12.5 7.37039 12.4849 7.31246 12.4558L4.41579 11.0092C4.1854 10.8943 3.93157 10.8341 3.67411 10.8333Z"
      fill="#1E252B"></path>
  </svg>
</div>
