import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PlatformService } from '@core/services';
import { DestroyableComponent } from '@models/destroyable.component';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-http-errors',
  templateUrl: './http-errors.component.html',
  styleUrls: ['./http-errors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HttpErrorsComponent extends DestroyableComponent implements OnInit, OnDestroy {
  @Input() error: any;

  public showError = false;

  constructor(
    protected cdr: ChangeDetectorRef,
    public deviceService: DeviceDetectorService,
    protected readonly platform: PlatformService,
  ) {
    super(platform);
  }

  ngOnInit() {}

  ngOnDestroy(): void {}

  public openError() {
    this.showError = true;
  }

  public closeError() {
    this.showError = false;
  }

  public refreshPage() {
    window?.location?.reload();
  }

  public get isDesktop(): boolean {
    return this.deviceService.isDesktop();
  }

  protected init() {}
}
