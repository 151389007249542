import { transition, trigger, useAnimation } from '@angular/animations';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ChangableComponent } from '@models/changable.component';
import { slideIn, slideOut } from '../../animations';
import { IconsService, PlatformService, ScreenService } from '../../services';

@Component({
  selector: 'app-mobile-modal-ad',
  template: '<ng-content></ng-content>',
})
export class MobileModalAdComponent {}

@Component({
  selector: 'app-mobile-modal-title',
  template: '<ng-content></ng-content>',
})
export class MobileModalTitleComponent {}

@Component({
  selector: 'app-mobile-modal-body',
  template: '<ng-content></ng-content>',
})
export class MobileModalBodyComponent {}

@Component({
  selector: 'app-mobile-modal-actions',
  template: '<ng-content></ng-content>',
})
export class MobileModalActionsComponent {}

@Component({
  selector: 'app-mobile-modal',
  templateUrl: './mobile-modal.component.html',
  styleUrls: ['./mobile-modal.component.scss'],
  animations: [
    trigger('modalAnimation', [
      transition(':enter', useAnimation(slideIn, { params: { time: '200ms', offset: '100vw' } })),
      transition(':leave', useAnimation(slideOut, { params: { time: '200ms', offset: '100vw' } })),
    ]),
  ],
})
export class MobileModalComponent extends ChangableComponent implements OnInit, OnDestroy {
  @Input() titleVisible = true;
  @Input() footerVisible = false;
  @Input() withCloseButton = false;
  @Input() customTitle: string;

  @Output() closed = new EventEmitter<boolean>();
  public svgsetIcon = IconsService.svgsetIconUrl;

  private _open = false;
  private element: Element;
  private _scrollY: number;

  constructor(
    private el: ElementRef,
    private screenService: ScreenService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
    this.element = el.nativeElement;
  }

  ngOnInit() {
    this.onBrowserOnly(() => {
      document.querySelector('.app').appendChild(this.element);
      /**  Это необходимо для того чтобы запомнить положение скролла до открытия модального окна.
       * При фиксированном позиционировании слетает положение скролла. Фиксированное позиционирование
       * необходимо для IPhone, без него под модалкой прокручивается контент. Все только ради IPhone
       * */
      this._scrollY = window.scrollY;
      this.screenService.setBodyFixed(true);

      this.setTimeout(() => {
        this._open = true;
        this.detectChanges();
      }, 100);
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.onBrowserOnly(() => {
      this.element.remove();
      this.screenService.setBodyFixed(false);
      /** Восстанавливает положение скролла */
      window.scroll(0, this._scrollY);
    });
  }

  public get open(): boolean {
    return this._open;
  }

  public onClose() {
    this._open = false;
    this.setTimeout(() => {
      this.closed.emit(true);
      this.detectChanges();
    }, 200);
  }
}
