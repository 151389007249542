import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UserRoles } from '@app/first-contact/first-contact.component';
import { PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-first-contact-user-services',
  templateUrl: './first-contact-user-services.component.html',
  styleUrls: ['./first-contact-user-services.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirstContactUserServicesComponent extends ChangableComponent implements OnInit {
  @Input() selectedRole: UserRoles;
  public isViewport = false;

  constructor(
    protected readonly cdr: ChangeDetectorRef,
    public deviceService: DeviceDetectorService,
    protected readonly platform?: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngOnInit(): void {}

  public onInViewportChange(e) {
    if (!e.visible) {
      return;
    }
    this.isViewport = e.visible;
    this.markForCheck();
  }
}
