import { DOCUMENT, Location, registerLocaleData } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeRu from '@angular/common/locales/ru';
import { Inject, Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { DestroyableComponent } from '@app/models/destroyable.component';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import de from 'date-fns/locale/de';
import enUS from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import ru from 'date-fns/locale/ru';
import { Language } from 'lingo2-models';
import { DateFnsConfigurationService } from 'lingo2-ngx-date-fns';
import { CookieService } from 'ngx-cookie';
import { BehaviorSubject, Observable, of, zip } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { PlatformService } from './platform.service';
import { RequestService } from './request.service';

registerLocaleData(localeRu);
registerLocaleData(localeEn);

@Injectable({
  providedIn: 'root',
})
export class LanguageService extends DestroyableComponent {
  protected _language = this.register(new BehaviorSubject<Language>(null));
  public language$ = this._language.asObservable().pipe(filter((v) => !!v));

  private languages: Language[];
  private _languages = this.register(new BehaviorSubject<Language[]>(null));
  public languages$ = this._languages.asObservable().pipe(filter((v) => !!v));

  private lang_cookie_key = 'APP_LANG';

  constructor(
    protected cookieService: CookieService,
    protected translateService: TranslateService,
    protected dateFnsConfig: DateFnsConfigurationService,
    protected dateAdapter: DateAdapter<any>,
    protected request: RequestService,
    protected location: Location,
    protected http: HttpClient,
    @Inject(DOCUMENT) protected document: any,
    protected readonly platform: PlatformService,
  ) {
    super(platform);

    this.getUiLanguages(this.dirtyLocale)
      .pipe(
        tap((languages) => {
          this.languages = languages;
          this._languages.next(this.languages);
          this.init();
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  public changeLanguage(language: Language, redirect = true) {
    this.saveUiLanguageToCookie(language.code)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (redirect) {
          this.redirectToHost(language.url);
        }
      });
  }

  public get storedLanguage(): Language {
    const code = this.loadLangFromCookie();
    return code ? (this.languages || []).find((lang) => lang.code === code) : null;
  }

  protected set language(language: Language) {
    this.reflectLangInHtml(language.code);

    // сначала загрузить языковые ресурсы, затем сменить код языка в приложении
    zip(this.loadTranslations(language.code), this.configureDate(language.code))
      .pipe(tap(() => this._language.next(language)))
      .pipe(takeUntil(this.destroyed$))
      .subscribe();
  }

  /**
   * 'Грязный код' текущей локали из имени домена
   */
  protected get dirtyLocale(): string {
    const hostname = this.request.hostname;
    const parts = hostname.split('.');
    // @sample 'ru.onclass.com' -> ['ru', 'onclass', 'com']
    // @sample 'onclass.com' -> ['onclass', 'com']
    return parts.length === 3 ? parts[0] : environment.default_language;
  }

  protected init() {
    // максимально простая логика - язык определяется по текущему домену
    const language = this.routeLanguage;
    if (language) {
      this.language = language;
    }
  }

  protected get routeLanguage(): Language {
    const languages = this._languages.getValue();
    const host = this.request.host;

    const httpHost = 'http://' + host.split('//')[1];
    const httpsHost = 'https://' + host.split('//')[1];

    // на продакшене из-за использования upstream в nginx теряется протокол HTTPS
    const language = languages.find((l) => l.url === httpHost || l.url === httpsHost);
    // logger.debug(
    //   'LanguageService::routeLanguage',
    //   'language for host ' + host + ' is ' + (language ? language.title : 'undefined'),
    // );

    if (language) {
      return language;
    }

    if (this.isBrowser) {
      this.redirectToHost(environment.default_host);
    }

    // если редирект не случился - использовать язык по-умолчанию
    return languages.find((l) => l.code === environment.default_language);
  }

  public redirectToHost(host: string) {
    if (this.request.host === host) {
      // logger.debug('LanguageService::redirectToHost', 'skip redirect, already on host ' + host);
      return;
    }

    /**
     * серверный редирект не делать, чтобы не нарушать правила локализации мультиязычных сервисов
     * вместо серверного редиректа используются мета-теги <link rel="alternate" hreflang="<lang>" href="https://<lang>.onclass.com/">
     *
     * @see MetaService.setAlternateLanguageUrl
     */

    this.onBrowserOnly(() => {
      const path = this.location.path(true);
      const url = host + path;
      // logger.debug('LanguageService::redirectToHost', 'window.location.href=' + host);
      window.location.href = url;
    });
  }

  /**
   * Прописывает атрибут lang у элемента html
   * Пример
   * <html lang='en'>
   */
  private reflectLangInHtml(languageCode: string) {
    this.document.documentElement.lang = languageCode;
  }

  protected loadTranslations(languageCode: string): Observable<any> {
    if (languageCode !== this.translateService.currentLang) {
      // logger.debug('LanguageService:configureTranslate', 'required translations for language=' + languageCode);
      return this.translateService.use(languageCode);
    } else {
      // logger.debug('LanguageService:configureTranslate', 'translations already is ' + languageCode);
      return of(true);
    }
  }

  protected configureDate(languageCode: string): Observable<any> {
    this.dateAdapter.setLocale(languageCode);

    const dateFnsLocaleCode = this.dateFnsConfig.locale() ? this.dateFnsConfig.locale().code : '';

    // tslint:disable:object-literal-key-quotes
    const locales = {
      ru,
      it,
      de,
      fr,
      es,
      // 'en': enUS,
      // 'en-US': enUS,
    };
    if (languageCode in locales) {
      if (dateFnsLocaleCode !== languageCode) {
        // logger.debug('LanguageService::configureDateFns()', 'dateFnsConfig set locale = ' + languageCode);
        this.dateFnsConfig.setLocale(locales[languageCode]); // TODO dynamic import
      }
      return of(true);
    }

    if ('en-US' !== dateFnsLocaleCode) {
      // logger.debug('LanguageService::configureDateFns()', 'dateFnsConfig set locale = en-US (default)');
      this.dateFnsConfig.setLocale(enUS); // TODO dynamic import
    }
    return of(true);
  }

  /**
   * сохраняет код языка в cookie для редиректов на языковые поддомены и для локализации meet.onclass.com
   */
  protected saveUiLanguageToCookie(languageCode: string): Observable<boolean> {
    // Пока вернул сохранения в куки с фронта так как с бэка куки не проставляются
    this.cookieService.put(this.lang_cookie_key, languageCode, { domain: this.cookieDomain });

    // TODO: Проверить почему не проставляются куки в бэке
    const url = `${environment.account_url}/ui_language`;

    const body = {
      ui_language: languageCode,
      domain: this.cookieDomain,
      cookie_key: this.lang_cookie_key,
    };

    // Кука языка устанавливается на бэке
    return this.http.put<boolean>(url, body, { observe: 'body' });
  }

  protected loadLangFromCookie(): string {
    return this.cookieService.get(this.lang_cookie_key);
  }

  protected get cookieDomain(): string {
    return '.' + environment.cookie_domain;
  }

  protected getUiLanguages(locale: string): Observable<Language[]> {
    const url = `${environment.content_url}/reference/ui-languages`;
    const params = new HttpParams().set('locale', locale);
    return this.http.get<Language[]>(url, { params, observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }
}
