<div class="subject-selector" *ngIf="!isLimitedVersion" [class.mobile]="deviceService.isMobile()" [class.hidden-block]="!showSelector">
  <label class="select" [class.focus]="focus">
    <input class="input"
           [(ngModel)]="searchTerm"
           [placeholder]="placeholder"
           (focus)="onFocus()"
           (blur)="onBlur()"
           (keyup)="onSearchSubjects()">
    <ng-container *ngIf="!selectedSubjects.length">
      <svg width="14" height="8" viewBox="0 0 14 8" stroke="#ffffff">
        <use [attr.xlink:href]="svgsetIcon('chevron-down')"></use>
      </svg>
    </ng-container>

    <ng-container *ngIf="selectedSubjects.length">
      <div class="clear-filter-wrap"
           [attr.data-test]="'clear-filter'"
           (click)="onClearSubject($event)">
        <svg viewBox="0 0 12 12" class="clear-filter">
          <use [attr.xlink:href]="svgsetIcon('close-sidebar')"></use>
        </svg>
      </div>
    </ng-container>
  </label>

  <ng-template [ngIf]="contentDisplay">
    <div class="shade" (click)="onCloseContent()"></div>
    <div class="options">
      <div class="content">
        <ng-template [ngIf]="!searchTerm" [ngIfElse]="searchContent">
          <ng-container *ngTemplateOutlet="defaultTemplate"></ng-container>
        </ng-template>
        <ng-template #searchContent>
          <ng-container *ngTemplateOutlet="searchBlock"></ng-container>
        </ng-template>
      </div>
      <ng-template [ngIf]="!isTeacher">
        <a [routerLink]="'/profile'" class="footer">
          <span class="footer-text">{{ 'subject-selector-in-header.text' | translate }}</span>
          <span class="footer-link">{{ 'subject-selector-in-header.link' | translate }}</span>
        </a>
      </ng-template>
    </div>
  </ng-template>
</div>

<ng-template #defaultTemplate>
  <section class="default-template">
    <ng-template [ngIf]="!displayCategory" [ngIfElse]="subjectsFiltered">
      <ng-template [ngIf]="!!recentList.length">
        <ng-container *ngTemplateOutlet="recentBlock"></ng-container>
      </ng-template>
      <ng-container *ngTemplateOutlet="categoryBlock"></ng-container>
    </ng-template>

    <ng-template #subjectsFiltered>
      <ng-container *ngTemplateOutlet="categoryList"></ng-container>
    </ng-template>
  </section>
</ng-template>

<ng-template #recentBlock>
  <section class="recent-block">
    <span class="recent-label">{{ 'subject-selector-in-header.recent' | translate }}</span>
    <ng-container *ngTemplateOutlet="subjectsList; context:{ subjectsList: recentList, favoriteShowed: true }"></ng-container>
  </section>
</ng-template>

<ng-template #categoryBlock>
  <ng-container *ngTemplateOutlet="tabs"></ng-container>

  <ng-template [ngIf]="tabsActive === 'all'">
    <section class="category-block">
      <div class="category-item" (click)="onOpenCategory('foreign')">
        <span class="category-label">{{ 'subject-selector-in-header.category.foreign' | translate }}</span>
        <svg width="6" height="12" viewBox="0 0 8 14" stroke="#1E252B">
          <use [attr.xlink:href]="svgsetIcon('chevron-right')"></use>
        </svg>
      </div>

      <div class="category-item" (click)="onOpenCategory('native')">
        <span class="category-label">{{ 'subject-selector-in-header.category.native' | translate }}</span>
        <svg width="6" height="12" viewBox="0 0 8 14" stroke="#1E252B">
          <use [attr.xlink:href]="svgsetIcon('chevron-right')"></use>
        </svg>
      </div>

      <div class="category-item" (click)="onOpenCategory('others')">
        <span class="category-label">{{ 'subject-selector-in-header.category.others' | translate }}</span>
        <svg width="6" height="12" viewBox="0 0 8 14" stroke="#1E252B">
          <use [attr.xlink:href]="svgsetIcon('chevron-right')"></use>
        </svg>
      </div>
    </section>
  </ng-template>
  <ng-template [ngIf]="tabsActive === 'favorites'">
    <div class="favorites-block">
      <ng-container *ngTemplateOutlet="subjectsList; context:{ subjectsList: favoritedSubjects(), favoriteShowed: true }"></ng-container>
    </div>
  </ng-template>
</ng-template>

<ng-template #tabs>
  <div class="tabs">
    <div class="tab"
         [class.active]="tabsActive === 'all'"
         (click)="tabsActive = 'all'">{{ 'subject-selector-in-header.tabs.all' | translate }}</div>
    <div class="tab"
         [class.active]="tabsActive === 'favorites'"
         (click)="tabsActive = 'favorites'">{{ 'subject-selector-in-header.tabs.favorites' | translate }}</div>
  </div>
</ng-template>

<ng-template #categoryList>
  <div class="category-list">
    <div class="back-action" (click)="displayCategory = null">
      <svg width="8" height="14" viewBox="0 0 8 14" stroke="#A4B0C3">
        <use [attr.xlink:href]="svgsetIcon('chevron-left')"></use>
      </svg>
      <span class="back-label medium">{{ backLabel | translate }}</span>
    </div>
    <div class="subjects-list">
      <ng-container *ngTemplateOutlet="subjectsList; context:{ subjectsList: filteredList(), favoriteShowed: true }"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #searchBlock>
  <section class="search-block">
    <ng-container *ngTemplateOutlet="subjectsList; context:{ subjectsList: searchList, favoriteShowed: true }"></ng-container>
  </section>
</ng-template>

<ng-template #subjectsList let-subjectsList="subjectsList" let-favoriteShowed="favoriteShowed">
  <div class="subjects-list">
    <div class="scroll">
      <ul class="subjects-list-wrap">
        <li *ngFor="let subject of subjectsList; trackBy: trackByFn" class="subjects-list-item">
          <div class="subject"
               (click)="onSelectSubject(subject)">
            <span class="subject-title">
              {{ subject.title }}
              <span
                *ngIf="isNativeSubject(subject)"
                class="subject-category">{{'subject-filters.native-language' | translate}}</span>
              <span
                *ngIf="isForeignSubject(subject)"
                class="subject-category">{{'subject-filters.foreign-language' | translate}}</span>
            </span>
            <ng-template [ngIf]="favoriteShowed">
              <div class="favorite-icon-wrap"
                   [class.active]="isFavorite(subject.id)"
                   (click)="onMakeFavorite($event, subject)">
                <svg viewBox="0 0 24 21" class="favorite-icon" >
                  <use [attr.xlink:href]="svgsetIcon('favorites')"></use>
                </svg>
              </div>
            </ng-template>
          </div>
        </li>
      </ul>
    </div>
  </div>
</ng-template>
