<section class="first-contact-capabilities" [class.mobile]="deviceService.isMobile()">
  <h2 class="title" [innerHTML]="title() | translate"></h2>

  <div class="capabilities-wrap">
    <div *ngFor="let item of capabilities()" class="capabilities-item">
      <span class="count">{{ item.count }}</span>
      <div class="text-wrap">
        <h4 class="capability-title">{{ item.title | translate }}</h4>
        <p class="capability-text">{{ item.text | translate }}</p>
      </div>
    </div>
  </div>
</section>
