import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromLesson from './lesson/lesson.reducer';
import * as fromLibrary from './library/library.reducer';
import * as fromSidebar from './sidebar/sidebar.reducer';
import * as fromStats from './stats/stats.reducer';
export interface ContentEditorState {
  lesson: fromLesson.LessonState;
  library: fromLibrary.LibraryState;
  sidebar: fromSidebar.SidebarState;
  stats: fromStats.StatsState;
}

export const reducers: ActionReducerMap<ContentEditorState> = {
  lesson: fromLesson.reducer,
  library: fromLibrary.reducer,
  sidebar: fromSidebar.reducer,
  stats: fromStats.reducer,
};

const thisFeatureKey = 'content-editor';
export const contentEditorFeatureKey = thisFeatureKey;

export const getContentEditorState = createFeatureSelector<ContentEditorState>(thisFeatureKey);
