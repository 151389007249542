import { Injectable, OnDestroy } from '@angular/core';
import { AnyType } from 'lingo2-models';
import { isArray, isNumber, isObject, isString } from 'lodash-es';
import * as NodeCache from 'node-cache';
import { ReplaySubject, Observable, Subscription, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { PlatformService } from './platform.service';

type KeyType =
  | string
  | [string, string]
  | [string, string, string[]]
  | { entity: string; id: string; details?: string[] };

const DEFAULT_TTL_SEC = 30;
const LOAD_TIME_TTL_SEC = 10;
const FOREVER_TTL_SEC = 60 * 60;

/**
 * Время хранения данных в кэше
 *
 * FOREVER = надолго, чтобы хватило на несколько загрузок страницы = FOREVER_TTL_SEC
 * LOAD_TIME = на короткое время, приблизительно равное времени загрузки страницы = LOAD_TIME_TTL_SEC
 * false = использовать значение по-умолчанию = DEFAULT_TTL_SEC
 * number = точное время в секундах
 */
export type CacheTtlRule = 'FOREVER' | 'LOAD_TIME' | false | number;

@Injectable({
  providedIn: 'root',
})
export class CachedService implements OnDestroy {
  private cache: NodeCache;
  private cachedValues$ = new Map<string, ReplaySubject<AnyType>>();
  private destroyed$ = new Subject<boolean>();
  private listeners$$ = new Map<string, Subscription>();

  public constructor(protected readonly platform: PlatformService) {
    if (platform.isBrowser) {
      /** Cache has to work only in browser as a feature */
      this.cache = new NodeCache({
        stdTTL: DEFAULT_TTL_SEC, // seconds
        forceString: false,
        useClones: false,
        deleteOnExpire: true,
      });

      // https://github.com/node-cache/node-cache/blob/master/README.md#events
      this.cache.on('del', (key) => this.clearListener(key));
      this.cache.on('expired', (key) => this.clearListener(key));
      this.cache.on('flush', () => this.clearAllListeners());
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public shared$<T>(key: KeyType, source$: Observable<T>, ttlSec?: CacheTtlRule): Observable<T> {
    if (!this.cache) {
      return source$;
    }
    const _key = CachedService.getKeyString(key) + '$';

    let value$ = this.cachedValues$.get(_key);
    if (!value$) {
      value$ = new ReplaySubject<T>(null);
      this.cachedValues$.set(_key, value$);
      // logger.debug('CachedService shared$(' + _key + ') -> value$ prepared');
    }

    let _ttlSec;
    if (isNumber(ttlSec)) {
      _ttlSec = ttlSec;
    } else {
      _ttlSec = {
        LOAD_TIME: LOAD_TIME_TTL_SEC,
        FOREVER: FOREVER_TTL_SEC,
      }[ttlSec as string];
    }

    if (!this.cache.has(_key)) {
      // эта часть кода выполнится, если значение не было определено или исчезло из кэша
      this.cache.set(_key, true, _ttlSec);
      const listener$$ = source$
        .pipe(
          tap((_value) => {
            // logger.debug('CachedService shared$(' + _key + ') -> source$ sended value', _value);
            value$.next(_value);
          }),
          takeUntil(this.destroyed$),
        )
        .subscribe();

      this.listeners$$.set(_key, listener$$);
    }

    // logger.debug('CachedService shared$(' + _key + ') -> await for value$');
    return value$.asObservable();
  }

  public get<T>(key: KeyType): T {
    if (!this.cache) {
      return undefined;
    }
    const _key = CachedService.getKeyString(key) + '$';
    const value = this.cache.get(_key);
    // logger.debug('CachedService get(' + _key + ') -> ', value);
    return typeof value !== 'undefined' && value !== true ? (value as T) : undefined;
    // return this.cache.has(_key) ? this.cache.get(_key) : undefined;
  }

  public set<T>(key: KeyType, value: T, ttlSec: CacheTtlRule) {
    if (!this.cache) {
      return;
    }
    let _ttlSec;
    if (isNumber(ttlSec)) {
      _ttlSec = ttlSec;
    } else {
      _ttlSec = {
        LOAD_TIME: LOAD_TIME_TTL_SEC,
        FOREVER: FOREVER_TTL_SEC,
      }[ttlSec as string];
    }

    const _key = CachedService.getKeyString(key) + '$';
    let value$ = this.cachedValues$.get(_key);
    if (!value$) {
      value$ = new ReplaySubject<T>(null);
      this.cachedValues$.set(_key, value$);
    }
    // logger.debug('CachedService set(' + _key + ') -> ', value);
    this.cache.set(_key, value, _ttlSec);
    value$.next(value);
  }

  public clear(key: KeyType) {
    const _key = CachedService.getKeyString(key);
    this.cache.del(_key);
    this.cache.del(_key + '$');
  }

  protected static getKeyString(key: KeyType): string {
    if (isString(key)) {
      return key;
    }
    if (isObject(key) && 'entity' in key && 'id' in key) {
      const { entity, id, details } = key;
      return [String(entity), String(id), (details || []).sort().join(',')].join(':');
    }
    if (isArray(key)) {
      const [entity, id, details] = key;
      return [String(entity), String(id), (details || []).sort().join(',')].join(':');
    }
    return JSON.stringify(key);
  }

  protected clearListener(key: string) {
    if (this.listeners$$.has(key)) {
      this.listeners$$.get(key).unsubscribe();
      this.listeners$$.delete(key);
    }
  }

  protected clearAllListeners() {
    this.listeners$$.forEach((listener$$, key) => this.clearListener(key));
  }
}
