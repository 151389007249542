import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TeacherBadgesWidgetModule } from '@core/widgets/teacher-badges-widget/teacher-badges-widget.module';

// Local shared

// Onboarding states
import { OnboardingGuestStudentModule } from './onboarding-guest-student/onboarding-guest-student.module';
import { OnboardingGuestTeacherModule } from './onboarding-guest-teacher/onboarding-guest-teacher.module';
import { OnboardingGuestModule } from './onboarding-guest/onboarding-guest.module';
import { OnboardingLimitedTeacherModule } from './onboarding-limited-teacher/onboarding-limited-teacher.module';
import { OnboardingLimitedTutorModule } from './onboarding-limited-tutor/onboarding-limited-tutor.module';
import { OnboardingNotVerifiedModule } from './onboarding-not-verified/onboarding-not-verified.module';
import { OnboardingOnModerationModule } from './onboarding-on-moderation/onboarding-on-moderation.module';
import { OnboardingStudentModule } from './onboarding-student/onboarding-student.module';
import { OnboardingTeacherModule } from './onboarding-teacher/onboarding-teacher.module';
import { OnboardingTutorModule } from './onboarding-tutor/onboarding-tutor.module';
import { OnboardingUnknownModule } from './onboarding-unknown/onboarding-unknown.module';
import { OnboardingWidgetComponent } from './onboarding-widget.component';
import { OnboardingSharedModule } from './shared/onboarding-shared.module';
@NgModule({
  declarations: [OnboardingWidgetComponent],
  imports: [
    CommonModule,
    TeacherBadgesWidgetModule,

    OnboardingSharedModule,

    OnboardingGuestModule,
    OnboardingGuestTeacherModule,
    OnboardingLimitedTeacherModule,
    OnboardingLimitedTutorModule,
    OnboardingNotVerifiedModule,
    OnboardingOnModerationModule,
    OnboardingTeacherModule,
    OnboardingTutorModule,
    OnboardingGuestStudentModule,
    OnboardingStudentModule,
    OnboardingUnknownModule,
  ],
  exports: [OnboardingWidgetComponent],
})
export class OnboardingWidgetModule {}
