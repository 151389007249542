import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DateFnsModule } from 'lingo2-ngx-date-fns';

import { OnboardingUserStatusComponent } from './onboarding-user-status.component';

@NgModule({
  declarations: [OnboardingUserStatusComponent],
  imports: [CommonModule, DateFnsModule, TranslateModule],
  exports: [OnboardingUserStatusComponent],
})
export class OnboardingUserStatusModule {}
