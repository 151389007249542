/**
 * Статусы ошибок
 */
export const enum HttpStatuses {
  BadRequestError = 400,
  UnauthorizedError = 401,
  ForbiddenError = 403,
  NotFoundError = 404,
  InternalServerError = 500,
  BadGatewayError = 502,
  GatewayTimeoutError = 504,
}

/**
 * Статусы и коды ошибок
 */
const errors = {
  BadRequestError: { status: HttpStatuses.BadRequestError, code: 'BadRequestError' },
  ValidationError: { status: HttpStatuses.BadRequestError, code: 'ValidationError' },
  UnauthorizedError: { status: HttpStatuses.UnauthorizedError, code: 'UnauthorizedError' },
  ForbiddenError: { status: HttpStatuses.ForbiddenError, code: 'ForbiddenError' },
  NotFoundError: { status: HttpStatuses.NotFoundError, code: 'NotFoundError', message: 'Object not found' },
  InternalServerError: { status: HttpStatuses.InternalServerError, code: 'InternalServerError' },
  BadGatewayError: { status: HttpStatuses.BadGatewayError, code: 'BadGatewayError' },
  GatewayTimeoutError: { status: HttpStatuses.GatewayTimeoutError, code: 'GatewayTimeoutError' },
};

/**
 * Базовый класс для ошибок
 */
export class BaseError extends Error {
  public code!: string;
  public status!: HttpStatuses;
  public details: any;

  constructor(...args: any[]) {
    super(...args);
    this.init(errors.InternalServerError, args);
    Error.captureStackTrace(this, BaseError);
  }

  protected init(error: any, args: any) {
    this.name = error.code;
    this.message = args[0] || error.message;

    this.code = error.code;
    this.status = error.status;
    this.details = args[1] || null;
  }

  public humanizeStatus() {
    return this.status;
  }

  public humanizeDetails() {
    const result: any = {
      code: this.code,
      message: this.message,
    };

    if (process.env.NODE_ENV !== 'production') {
      result.details = this.details;
    }

    return result;
  }

  public humanize() {
    return [this.humanizeStatus(), this.humanizeDetails()];
  }
}

/**
 * Некорректный запрос
 */
export class BadRequestError extends BaseError {
  constructor(...args: any[]) {
    super(...args);
    this.init(errors.BadRequestError, args);
    Error.captureStackTrace(this, BadRequestError);
  }
}

/**
 * Запрос не авторизован, токен отстутствует или не является корректными
 */
export class UnauthorizedError extends BaseError {
  constructor(...args: any[]) {
    super(...args);
    this.init(errors.UnauthorizedError, args);
    Error.captureStackTrace(this, UnauthorizedError);
  }
}

/**
 * Доступ запрещен
 */
export class ForbiddenError extends BaseError {
  constructor(...args: any[]) {
    super(...args);
    this.init(errors.ForbiddenError, args);
    Error.captureStackTrace(this, ForbiddenError);
  }
}

/**
 * Данные не найдены
 */
export class NotFoundError extends BaseError {
  constructor(...args: any[]) {
    super(...args);
    this.init(errors.NotFoundError, args);
    Error.captureStackTrace(this, NotFoundError);
  }
}

/**
 * Некорректные данные, ошибка валидации данных
 */
export class ValidationError extends BaseError {
  constructor(...args: any[]) {
    super(...args);
    this.init(errors.ValidationError, args);
    Error.captureStackTrace(this, NotFoundError);
  }
}

/**
 * Внутренняя ошибка сервера
 */
export class InternalServerError extends BaseError {
  constructor(...args: any[]) {
    super(...args);
    this.init(errors.InternalServerError, args);
    Error.captureStackTrace(this, InternalServerError);
  }
}

/**
 * Ошибка удаленного сервера
 */
export class BadGatewayError extends BaseError {
  constructor(...args: any[]) {
    super(...args);
    this.init(errors.BadGatewayError, args);
    Error.captureStackTrace(this, BadGatewayError);
  }
}

/**
 * Ошибка соединения с удаленным сервером
 */
export class GatewayTimeoutError extends BaseError {
  constructor(...args: any[]) {
    super(...args);
    this.init(errors.GatewayTimeoutError, args);
    Error.captureStackTrace(this, GatewayTimeoutError);
  }
}
