import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { ITeacherRescheduledMeetingNotice, INoticeParams } from 'lingo2-models';
import { EModifier, IBaseNotification } from '../../base-notification/base-notification.component';

@Component({
  selector: 'app-teacher-rescheduled-meeting',
  templateUrl: './teacher-rescheduled-meeting.component.html',
  styleUrls: ['./teacher-rescheduled-meeting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeacherRescheduledMeetingComponent {
  public params: ITeacherRescheduledMeetingNotice;
  public base: IBaseNotification;

  @Input() set notification(notification: INoticeParams) {
    this.params = notification.params as ITeacherRescheduledMeetingNotice;
    this.base = {
      title: 'notifications.teacher_rescheduled_meeting.title',
      icon: 'notice-change',
      created_at: notification.created_at,
      modifier: EModifier.yellow,
    };
  }
}
