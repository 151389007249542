<div class="gallery"><!-- [style.width]="galleryScrollWidth + 'px'" -->
  <div class="gallery-row">
    <div *ngFor="let item of galleryImages0; trackBy: trackByFn; let i = index" class="gallery-item" (click)="chooseImage(item.image)">
      <div *ngIf="!item.url" class="gallery-absent-item-img"></div>
      <div *ngIf="item.image.isProcessing" class="gallery-processing-item-img">
        <spinner1 class="spinner-inner"></spinner1>
      </div>
      <img *ngIf="item.url" [src]="item.url" class="gallery-item-img">
      <!--
      <div class="remove">
        <svg viewBox="0 0 18 20" class="remove-ico" (click)="onRemoveImg(i)">
          <use xlink:href="/assets/icons/svgset/basket-2.svg#basket"></use>
        </svg>
      </div>
      -->
    </div>
  </div>
  <div class="gallery-row">
    <div *ngFor="let item of galleryImages1; trackBy: trackByFn; let i = index" class="gallery-item" (click)="chooseImage(item.image)">
      <div *ngIf="!item.url" class="gallery-absent-item-img"></div>
      <div *ngIf="item.image.isProcessing" class="gallery-processing-item-img">
        <spinner1 class="spinner-inner"></spinner1>
      </div>
      <img *ngIf="item.url" [src]="item.url" class="gallery-item-img">
      <!--
      <div class="remove">
        <svg viewBox="0 0 18 20" class="remove-ico" (click)="onRemoveImg(i)">
          <use xlink:href="/assets/icons/svgset/basket-2.svg#basket"></use>
        </svg>
      </div>
      -->
    </div>
  </div>
</div>
