<div class="notifications">

    <app-notifications-widget *ngIf="isOpened$ | async"></app-notifications-widget>

    <app-toaster *ngIf="!isMobile"></app-toaster>

    <div class="notifications__button" *ngIf="isDesktop">
        <app-button-fab
          [icon]="'bell'"
          [counter]="unreadCount$ | async"
          [isToggled]="isOpened$ | async"
          (click)="toggle()">
        </app-button-fab>
    </div>

    <div class="debug" *ngIf="debug$ | async">
      <button (click)="getMockNotices()">mock</button><br/>
      <button (click)="getLastNotices()">last</button>
    </div>

</div>
