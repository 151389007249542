import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
// import { PaymentWidgetBillingService } from './payment-widget-billing.service';

// TODO Convert to DynamicModule with config
@NgModule({
  imports: [HttpClientModule, TranslateModule],
  providers: [
    // PaymentWidgetBillingService,
  ],
  exports: [],
})
export class PaymentWidgetBillingModule {}
