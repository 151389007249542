export const freeLessonsList = [
  {
    type: 'popular-content',
    title: 'first-contact.first-contact-free-lesson.list.title1',
    text: 'first-contact.first-contact-free-lesson.list.text1',
  },
  {
    type: 'what-to-see',
    title: 'first-contact.first-contact-free-lesson.list.title2',
    text: 'first-contact.first-contact-free-lesson.list.text2',
  },
  {
    type: 'what-to-listen',
    title: 'first-contact.first-contact-free-lesson.list.title3',
    text: 'first-contact.first-contact-free-lesson.list.text3',
  },
  {
    type: 'exercise-of-the-day',
    title: 'first-contact.first-contact-free-lesson.list.title4',
    text: 'first-contact.first-contact-free-lesson.list.text4',
  },
];
