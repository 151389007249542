<div *ngIf="isActive" class="vacation-informer" [class.mobile]="deviceService.isMobile()">
  <div class="phrase-text">
    <div>{{ 'vacation-informer.vacation-dates' | translate : {
      'begin_at': vacation.vacation_begin_at | dfnsFormat: 'dd MMMM',
      'end_at': vacation.vacation_end_at | dfnsFormat: 'dd MMMM'
      } }}</div>
    <div *ngIf="vacation?.working_events_count">
      {{ 'vacation-informer.events-count' | translate : {'count': vacation.working_events_count} }}
    </div>
  </div>

  <div class="actions-wrap">
    <div class="button-wrap">
      <on-ui-button (click)="goSchedule()"
                    class="primary"
                    [class.shaking-button]="shaking"
                    [label]="'vacation-informer.go-to-calendar'">
      </on-ui-button>
    </div>
  </div>
</div>
