<div *ngIf="visibleSuggest" class="suggest-add-card" [class.mobile]="deviceService.isMobile()">
  <div class="info">
    <span class="text">{{ 'suggest-add-card.text' | translate }}</span>
    <on-ui-button class="primary outline"
                  state="default"
                  label="suggest-add-card.action"
                  (onclick)="openNewCardForm()">
    </on-ui-button>
  </div>
  <div class="close-action" (click)="onToggleAlert()">
    <svg width="20" height="20" viewBox="0 0 15 16" fill="#A4B0C3">
      <use [attr.xlink:href]="svgsetIcon('close')"></use>
    </svg>
  </div>
</div>

<app-alert *ngIf="openAlert" type="info">
  <ng-container alert-title>{{ 'collections-constructor.texts.teacher-need-card-link' | translate }}</ng-container>
  <ng-container alert-body>{{ 'collections-constructor.texts.teacher-need-card' | translate }}</ng-container>
  <ng-container alert-buttons>
    <on-ui-button class="primary outline"
                  label="collections-constructor.incomplete-alert.later"
                  state="default"
                  (onclick)="closeSuggest()">
    </on-ui-button>
    <on-ui-button class="primary"
                  label="collections-constructor.texts.teacher-need-card-btn"
                  state="default"
                  (onclick)="openNewCardForm()">
    </on-ui-button>
  </ng-container>
</app-alert>

<app-modal *ngIf="newCardOpen && !deviceService.isMobile()" [canClose]="false" (closed)="closeNewCardForm()">
  <app-modal-body>
    <app-finance-card-create (finished)="closeNewCardForm()"></app-finance-card-create>
  </app-modal-body>
</app-modal>

<app-mobile-modal *ngIf="newCardOpen && deviceService.isMobile()" (closed)="closeNewCardForm()">
  <app-mobile-modal-body class="finance-mobile">
    <app-finance-card-create (finished)="closeNewCardForm()"></app-finance-card-create>
  </app-mobile-modal-body>
</app-mobile-modal>
