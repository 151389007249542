import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClickOutsideModule } from 'ng-click-outside';
import { AlertSingleComponent } from './alert-single.component';

@NgModule({
  declarations: [AlertSingleComponent],
  imports: [CommonModule, ClickOutsideModule],
  exports: [AlertSingleComponent],
})
export class AlertSingleModule {}
