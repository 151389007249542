import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { OnUiPopoverModule } from 'onclass-ui';
import { ValidationErrorComponent } from './validation-error.component';

@NgModule({
  declarations: [ValidationErrorComponent],
  imports: [CommonModule, TranslateModule, OnUiPopoverModule],
  exports: [ValidationErrorComponent],
})
export class ValidationErrorModule {}
