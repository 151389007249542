<ng-template [ngIf]="me$ | async">
  <ng-template [ngIf]="!floating" [ngIfElse]="left_sidebar_floating">
    <ng-container *ngTemplateOutlet="new_left_sidebar"></ng-container>
  </ng-template>

  <ng-template #left_sidebar_floating>
    <ng-container *ngTemplateOutlet="mobile_menu"></ng-container>
  </ng-template>
</ng-template>

<ng-template #new_left_sidebar>
  <section class="left-sidebar">
    <nav class="navigate">
      <div class="top">
        <div #scroll class="scroll" [class.active]="scrollActive">
          <div class="menu-with-pins">
            <ng-container *ngTemplateOutlet="menuList; context: { list: topMenuList }"></ng-container>
            <ng-container *ngIf="pinnedClassesList?.length > 0">
              <ng-container *ngTemplateOutlet="pinList; context: { list: pinnedClassesList }"></ng-container>
            </ng-container>
          </div>
        </div>

        <ng-template [ngIf]="displayAction">
          <div class="more" [class.active]="scrollActive">
            <div class="more-action" (click)="onScroll()">
              <svg width="12" height="7" viewBox="0 0 12 7" stroke="#41A1D9">
                <use [attr.xlink:href]="svgsetIcon('chevron-up')"></use>
              </svg>
            </div>
            <span class="state normal">{{ scrollActive ? 'Свернуть' : 'Ещё' }}</span>
          </div>
        </ng-template>
      </div>

      <div class="bottom">
        <ng-container *ngTemplateOutlet="menuList; context: { list: bottomMenuList }"></ng-container>
      </div>
    </nav>
  </section>
</ng-template>

<ng-template #mobile_menu>
  <div class="left-sidebar-floating" [class.visible]="visible">
    <div class="shade" (click)="closeSidebar()"></div>

    <div class="menu" [class.active]="scrollActive">
      <div class="logo"></div>
      <ng-container *ngTemplateOutlet="menuList; context: { list: topMenuList }"></ng-container>
      <ng-container *ngIf="pinnedClassesList?.length > 0">
        <ng-container *ngTemplateOutlet="pinList; context: { list: pinnedClassesList }"></ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #menuList let-list="list">
  <ul class="menu-list">
    <ng-container *ngFor="let itemMenu of list; let i = index; trackBy: trackMenuByFn">
      <ng-container [ngSwitch]="itemMenu.name">

        <ng-container *ngSwitchCase="'sidebar-quick-class'">
          <li class="menu-item" (click)="onQuickMeeting()">
            <span class="link">
              <div class="menu-link primary">
                <span class="icon-wrap">
                  <svg width="30" height="30" viewBox="0 0 30 30">
                    <use [attr.xlink:href]="svgsetIcon(itemMenu.name)"></use>
                  </svg>
                </span>
                <span class="label normal is-virtual-class">{{ itemMenu.title | translate }}</span>
                <span class="tooltip normal">{{ itemMenu.title | translate }}</span>
              </div>
            </span>
          </li>
        </ng-container>

        <ng-container *ngSwitchCase="'sidebar-schedule-class'">
          <li class="menu-item" (click)="onCheckScheduleMeeting()">
            <span class="link">
              <div class="menu-link primary-outline">
                <span class="icon-wrap">
                  <svg width="30" height="30" viewBox="0 0 30 30">
                    <use [attr.xlink:href]="svgsetIcon(itemMenu.name)"></use>
                  </svg>
                </span>
                <span class="label normal is-virtual-class">{{ itemMenu.title | translate }}</span>
                <span class="tooltip normal">{{ itemMenu.title | translate }}</span>
              </div>
            </span>
          </li>
        </ng-container>

        <ng-container *ngSwitchCase="'sidebar-notifications'">
          <li *ngIf="itemMenu.route" class="menu-item" routerLinkActive="active" [class.active2]="isActiveRoute(itemMenu)" (click)="onMenuClick()">
            <a [routerLink]="itemMenu.route" class="link">
              <div class="menu-link">
                <span class="icon-wrap">
                  <svg width="30" height="30" viewBox="0 0 30 30">
                    <use [attr.xlink:href]="svgsetIcon(itemMenu.name)"></use>
                  </svg>
                  <div *ngIf="width < 1500 && width > 990" appRedPoint class="red-point" [event]="redPointEvent(itemMenu.name)"></div>
                  <span *ngIf="(unreadNotificationsCount$ | async) > 0" class="badge">{{ unreadNotificationsCount$ | async }}</span>
                </span>
                <span class="label normal">
                  {{ itemMenu.title | translate }}
                  <div *ngIf="(width > 1500 || width < 990)" appRedPoint class="red-point" [event]="redPointEvent(itemMenu.name)"></div>
                  <span *ngIf="(unreadNotificationsCount$ | async) > 0" class="badge">{{ unreadNotificationsCount$ | async }}</span>
                </span>
                <span class="tooltip normal">{{ itemMenu.title | translate }}</span>
              </div>
            </a>
          </li>
        </ng-container>

        <ng-container *ngSwitchCase="'banner-plan'">
          <li class="menu-item" [class.mobile-me]="isMobileMeVersion" (click)="onMenuClick()">
            <app-banner-plan [mini]="width <= 1500 && width > 990"></app-banner-plan>
          </li>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <li *ngIf="itemMenu.route" class="menu-item" routerLinkActive="active" [class.active2]="isActiveRoute(itemMenu)" (click)="onMenuClick()">
            <a [routerLink]="itemMenu.route" class="link">
              <div class="menu-link">
                <span class="icon-wrap">
                  <svg width="30" height="30" viewBox="0 0 30 30">
                    <use [attr.xlink:href]="svgsetIcon(itemMenu.name)"></use>
                  </svg>
                  <div *ngIf="width < 1500 && width > 990" appRedPoint class="red-point" [event]="redPointEvent(itemMenu.name)"></div>
                </span>
                <span class="label normal">
                  {{ itemMenu.title | translate }}
                  <div *ngIf="(width > 1500 || width < 990)" appRedPoint class="red-point" [event]="redPointEvent(itemMenu.name)"></div>
                </span>
                <span class="tooltip normal">{{ itemMenu.title | translate }}</span>
              </div>
            </a>
          </li>
        </ng-container>

        <ng-template [ngIf]="itemMenu.separator">
          <li class="menu-item">
            <hr class="separator">
          </li>
        </ng-template>
      </ng-container>
    </ng-container>

    <ng-template [ngIf]="isMobileMeVersion">
      <li class="menu-item" (click)="closeSidebar()" [class.mobile-me]="isMobileMeVersion">
        <app-widget-welcome></app-widget-welcome>
      </li>
    </ng-template>
  </ul>
</ng-template>

<ng-template #pinList let-list="list">
  <ul class="menu-list pin-list">
    <ng-container *ngFor="let itemPin of list; trackBy: trackPinByFn">
      <li class="menu-item pin-item">
        <a (click)="navigate(itemPin.entity_id)" class="link">
          <div class="menu-link">
            <div class="cover-wrap">
              <on-ui-cover [cover]="getPinCover(itemPin.entity.cover_id)"></on-ui-cover>
            </div>
            <div class="pin-title-block">
              <span class="label">
                {{ itemPin.title ? itemPin.title : ('classrooms.classroom.title' | translate)}}
              </span>
            </div>
          </div>
        </a>
        <app-icon
          (click)="unpinClass(itemPin)"
          class="pin-icon"
          [icon]="'pin'"
          [width]="25"
          [height]="25"
        ></app-icon>
      </li>
    </ng-container>
  </ul>
</ng-template>

<ng-template [ngIf]="meetingFormOpened">
  <app-modal *ngIf="!deviceService.isMobile()" (closed)="closeMeetingForm()">
    <app-modal-body>
      <app-meeting-form-wrapper (saved)="closeMeetingForm()" [caller]="'app-left-sidebar'"></app-meeting-form-wrapper>
    </app-modal-body>
  </app-modal>

  <app-mobile-modal *ngIf="deviceService.isMobile()" (closed)="closeMeetingForm()">
    <app-mobile-modal-title>{{ 'lingo2-forms.meetings.form-title' | translate }}</app-mobile-modal-title>
    <app-mobile-modal-body>
      <app-meeting-form-wrapper (saved)="closeMeetingForm()" [caller]="'app-left-sidebar'"></app-meeting-form-wrapper>
    </app-mobile-modal-body>
  </app-mobile-modal>
</ng-template>

<ng-template [ngIf]="openResumePlanModal">
  <app-payment-wizard-dialog [plan]="plan" (closed)="toggleResumePlan()"></app-payment-wizard-dialog>
</ng-template>

