import { Location } from '@angular/common';
import { Injectable, NgZone } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DestroyableComponent } from '@app/models/destroyable.component';
import { environment } from '@env/environment';
import { Metrika } from '@le2xx/ng-yandex-metrika';
import { takeUntil } from 'rxjs';
import { PlatformService } from '../platform.service';

@Injectable({
  providedIn: 'root',
})
export class YandexMetrikaService extends DestroyableComponent {
  private inited = false;

  public constructor(
    protected readonly platform: PlatformService,
    private location: Location,
    private router: Router,
    private metrika: Metrika,
    private zone: NgZone,
  ) {
    super(platform);
    this.init();
  }

  // https://developer.aliyun.com/mirror/npm/package/@le2xx/ng-yandex-metrika-ecommerce
  private init() {
    this.onBrowserOnly(() => {
      if (environment.metrika_id) {
        this.inited = true;
        let prevPath = this.location.path();
        this.router.events.pipe(takeUntil(this.destroyed$)).subscribe((event) => {
          if (event instanceof NavigationEnd) {
            const newPath = this.location.path();
            this.pageView(newPath, prevPath);
            prevPath = newPath;
          }
        });
      }
    });
  }

  /** Зарегистрировать просмотр страницы */
  public pageView(url: string, referer?: string) {
    if (this.inited) {
      this.zone.runOutsideAngular(() => {
        this.metrika.hit(url, { referer }).catch(() => null);
      });
    }
  }

  /**
   * Отправить событие в Yandex Metrika
   */
  public event(action: string, eventCategory: string, eventLabel?: string, eventValue?: number) {
    if (this.init) {
      this.zone.runOutsideAngular(() => {
        /* TODO */
      });
    }
  }
}
