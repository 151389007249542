import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum WidgetsEnum {
  notifications = 'notifications',
  chat = 'chat',
}

@Injectable({
  providedIn: 'root',
})
export class WidgetService {
  private activeWidget = new Subject<string>();
  public activeWidget$ = this.activeWidget.asObservable();

  constructor() {}

  public setActive(type: string): void {
    this.activeWidget.next(type);
  }
}
