<app-onboarding-user-status
  [onboardingStatus]="status"
  >
</app-onboarding-user-status>

<app-onboarding-progressbar [max]="checklistOptions.length" [value]="checkedCount" *ngIf="(isExpanded$ | async) === false"></app-onboarding-progressbar>

<div class="expandable" *ngIf="isExpanded$ | async">

  <app-onboarding-title icon="emoji-smile" [title]="'onboarding.start-teaching' | translate">
  </app-onboarding-title>

  <div class="progressbar">
    <app-onboarding-progressbar [max]="checklistOptions.length" [value]="checkedCount" [rounded]="true"></app-onboarding-progressbar>
  </div>

  <app-onboarding-checklist
    [options]="checklistOptions">
  </app-onboarding-checklist>

  <app-onboarding-list-panel [options]="panelOptions" title="onboarding.what-verified-can"></app-onboarding-list-panel>

</div>