import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountService } from '@core/services/lingo2-account/account.service';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import {
  IPagedResults,
  otherSubjectId,
  MeetingTypeEnum,
  IMeetingSession,
  IPagination,
  Meeting,
  IFindMeetingsFilter,
  MeetingParticipant,
  MeetingDetailsType,
  MeetingCheckoutRequest,
  MeetingCheckoutResponse,
  MeetingCancelConfirmation,
  MeetingCancelResponse,
  MeetingLeaveResponse,
  MeetingLeaveConfirmation,
  MeetingRescheduleConfirmation,
  MeetingRescheduleResponse,
  MeetingJoinResponse,
  FindMeetingsFilter,
} from 'lingo2-models';
import { DateFnsConfigurationService, FormatPipe } from 'lingo2-ngx-date-fns';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { createPagedResultsFromResponse } from '../../helpers/request';
import { IContentBlock } from '../interfaces';

export type MeetingsBlockType = IContentBlock<Meeting, IFindMeetingsFilter>;

export interface IMeetingsStats {
  /** @see PublishStatusEnum */
  [key: string]: number;
  total: number;
}

@Injectable({
  providedIn: 'root',
})
export class MeetingsService {
  private dateFormat = new FormatPipe(this.dateConfig, null);

  public constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private dateConfig: DateFnsConfigurationService,
  ) {}

  public static meetingTypeName(meeting: Meeting): string {
    return (
      {
        [MeetingTypeEnum.single]: 'meeting-type.single',
        [MeetingTypeEnum.group]: 'meeting-type.group',
        [MeetingTypeEnum.webinar]: 'meeting-type.webinar',
        [MeetingTypeEnum.stream]: 'meeting-type.stream',
      }[meeting?.type] || '--'
    );
  }

  public meetingFullTitle(meeting: Meeting): string {
    if (!meeting) {
      return null;
    }

    let subject = '';
    if (meeting.subject) {
      if (+meeting.subject.id === otherSubjectId) {
        subject = meeting.subject_other_name || '---';
      } else {
        subject = (meeting.subject ? meeting.subject.title : null) || '---';
      }
    }
    const level = meeting.level ? meeting.level.title : this.translate.instant('subject-levels.any-level');

    let dateFormatted = '';
    if (meeting.begin_at && meeting.end_at) {
      dateFormatted = [
        this.dateFormat.transform(new Date(meeting.begin_at), 'PPP HH:mm'),
        this.dateFormat.transform(new Date(meeting.end_at), 'HH:mm'),
      ].join(' — ');
    }
    const authorName = AccountService.getUserFullName(meeting.author);

    // title subject date level author | classes -- OnClass
    const titleParts = [meeting.title, ', ', subject];
    if (dateFormatted) {
      titleParts.push(', ', dateFormatted);
    }
    titleParts.push(', ', level);
    if (authorName) {
      titleParts.push(', ', authorName);
    }
    return titleParts.join('');
  }

  /**
   * Новые последние митинги (классы)
   *
   * @deprecated Ввести сортировку по времени
   */
  public getLatestMeetings(
    filter: Partial<IFindMeetingsFilter>,
    pagination: IPagination,
    details?: MeetingDetailsType[],
  ): Observable<IPagedResults<Meeting[]>> {
    const url = `${environment.content_url}/meetings/`;
    let params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter', JSON.stringify(filter));
    if (details && details.length) {
      params = params.set('details', JSON.stringify(details));
    }
    return this.http.get<Meeting[]>(url, { params, observe: 'response' }).pipe(
      map(this.handleMeetingsResponse.bind(this)),
      // catchError(this.handleError),
    );
  }

  /**
   * Митинги (классы)
   */
  public getMeetings(
    filter: Partial<IFindMeetingsFilter> | FindMeetingsFilter,
    pagination: IPagination,
    details?: MeetingDetailsType[],
  ): Observable<IPagedResults<Meeting[]>> {
    const url = `${environment.content_url}/meetings/`;
    let params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter', JSON.stringify(filter));
    if (details && details.length) {
      params = params.set('details', JSON.stringify(details));
    }
    return this.http.get<Meeting[]>(url, { params, observe: 'response' }).pipe(
      map(this.handleMeetingsResponse.bind(this)),
      // catchError(this.handleError),
    );
  }

  /**
   * Один митинг по URL
   */
  public getMeetingBySlug(slug: string, details?: MeetingDetailsType[]): Observable<Meeting> {
    const _slug = slug.split('-').reverse()[0];
    const url = `${environment.content_url}/meeting/u/${_slug}`;
    let params = new HttpParams();
    if (details && details.length) {
      params = params.set('details', JSON.stringify(details));
    }
    return this.http.get<Meeting>(url, { params, observe: 'response' }).pipe(
      map(this.handleMeetingResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Один митинг по ID
   */
  public getMeetingById(id: string, details?: MeetingDetailsType[]): Observable<Meeting> {
    const url = `${environment.content_url}/meeting/${id}`;
    let params = new HttpParams();
    if (details && details.length) {
      params = params.set('details', JSON.stringify(details));
    }
    return this.http.get<Meeting>(url, { params, observe: 'response' }).pipe(
      map(this.handleMeetingResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Информация о сессии митинга
   */
  public getMeetingSession(id: string): Observable<IMeetingSession> {
    const url = `${environment.content_url}/meeting/${id}/session`;
    return this.http.post<IMeetingSession>(url, {}, { observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  /**
   * Создание быстрого митинга
   */
  public createMeetingQuickSession(values: Partial<Meeting>): Observable<IMeetingSession> {
    const url = `${environment.content_url}/meeting/quick-session`;
    return this.http.post<IMeetingSession>(url, values, { observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  public getMeetingParticipants(id: string, pagination: IPagination): Observable<IPagedResults<MeetingParticipant[]>> {
    const url = `${environment.content_url}/meeting/${id}/participants`;
    return this.http.get<MeetingParticipant[]>(url, { observe: 'response' }).pipe(
      map((response) => ({
        results: response.body.map((i) => new MeetingParticipant(i)),
        page: +response.headers.get('X-Pagination-Page'),
        pageSize: +response.headers.get('X-Pagination-PageSize'),
        total: +response.headers.get('X-Pagination-Total'),
        totalPages: +response.headers.get('X-Pagination-TotalPages'),
      })),
      // catchError(this.handleError),
    );
  }

  /**
   * Статистика по митингам
   */
  public getStats(user_id?: string): Observable<IMeetingsStats> {
    const url = `${environment.content_url}/meeting/stats/${user_id}`;
    return this.http.get<IMeetingsStats>(url, { observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  /** Запрос на удаление митинга */
  public requestMeetingCancel(meeting_id: string): Observable<MeetingCancelConfirmation> {
    const url = `${environment.content_url}/meeting/${meeting_id}/cancel`;
    return this.http
      .post<MeetingCancelConfirmation>(url, { observe: 'body' })
      .pipe(map((response) => new MeetingCancelConfirmation(response)));
  }

  /** Подтверждение удаления митинга */
  public confirmMeetingCancel(meeting_id: string, confirmation_id: string): Observable<MeetingCancelResponse> {
    const url = `${environment.content_url}/meeting/${meeting_id}/cancel/confirm/${confirmation_id}`;
    return this.http
      .post<MeetingCancelResponse>(url, {}, { observe: 'body' })
      .pipe(map((response) => new MeetingCancelResponse(response)));
  }

  /** Запрос на выход из митинга */
  public requestMeetingLeave(meeting_id: string): Observable<MeetingLeaveConfirmation> {
    const url = `${environment.content_url}/meeting/${meeting_id}/leave`;
    return this.http
      .post<MeetingLeaveConfirmation>(url, {}, { observe: 'body' })
      .pipe(map((response) => new MeetingLeaveConfirmation(response)));
  }

  /** Подтверждение выхода из митинга */
  public confirmMeetingLeave(meeting_id: string, confirmation_id: string): Observable<MeetingLeaveResponse> {
    const url = `${environment.content_url}/meeting/${meeting_id}/leave/confirm/${confirmation_id}`;
    return this.http
      .post<MeetingLeaveResponse>(url, {}, { observe: 'body' })
      .pipe(map((response) => new MeetingLeaveResponse(response)));
  }

  /** Запрос на перенос времени митинга */
  public requestMeetingReschedule(meeting_id: string, begin_at: Date): Observable<MeetingRescheduleConfirmation> {
    const url = `${environment.content_url}/meeting/${meeting_id}/reschedule`;
    return this.http
      .post<MeetingRescheduleConfirmation>(url, { begin_at }, { observe: 'body' })
      .pipe(map((response) => new MeetingRescheduleConfirmation(response)));
  }

  /** Подтверждение переноса времени митинга */
  public confirmMeetingReschedule(
    meeting_id: string,
    begin_at: Date,
    confirmation_id: string,
  ): Observable<MeetingRescheduleResponse> {
    const url = `${environment.content_url}/meeting/${meeting_id}/reschedule/confirm/${confirmation_id}`;
    return this.http
      .post<MeetingRescheduleResponse>(url, { begin_at }, { observe: 'body' })
      .pipe(map((response) => new MeetingRescheduleResponse(response)));
  }

  /** Согласие с переносом времени митинга */
  public acceptMeetingReschedule(meeting_id: string): Observable<any> {
    const url = `${environment.content_url}/meeting/${meeting_id}/reschedule/accept`;
    return this.http.post<any>(url, {}, { observe: 'response' }).pipe(map((response) => response.body));
  }

  /** Отказ от переноса времени митинга */
  public declineMeetingReschedule(meeting_id: string): Observable<any> {
    const url = `${environment.content_url}/meeting/${meeting_id}/reschedule/decline`;
    return this.http.post<any>(url, {}, { observe: 'response' }).pipe(map((response) => response.body));
  }

  /** Отмена запроса на перенос времени митинга */
  public withdrawMeetingReschedule(meeting_id: string): Observable<any> {
    const url = `${environment.content_url}/meeting/${meeting_id}/reschedule`;
    return this.http.delete<any>(url, { observe: 'response' }).pipe(map((response) => response.body));
  }

  public createMeeting(values: Partial<Meeting>): Observable<Meeting> {
    const url = `${environment.content_url}/meeting/`;
    return this.http.post<Meeting>(url, values, { observe: 'response' }).pipe(
      map(this.handleMeetingResponse),
      // catchError(this.handleError),
    );
  }

  public updateMeeting(id: string, values: Partial<Meeting>): Observable<Meeting> {
    const url = `${environment.content_url}/meeting/${id}`;
    return this.http.put<Meeting>(url, values, { observe: 'response' }).pipe(
      map(this.handleMeetingResponse),
      // catchError(this.handleError),
    );
  }

  // добавление участников в митинг
  public inviteUsers(
    id,
    participants: Array<Partial<MeetingParticipant>>,
    details?: MeetingDetailsType[],
  ): Observable<Meeting> {
    const url = `${environment.content_url}/meeting/${id}/participants/invite`;
    let params = new HttpParams();
    if (details && details.length) {
      params = params.set('details', JSON.stringify(details));
    }
    return this.http.post<Meeting>(url, { participants }, { params, observe: 'response' }).pipe(
      map(this.handleMeetingResponse),
      // catchError(this.handleError),
    );
  }

  public joinMeeting(id: string): Observable<MeetingJoinResponse> {
    const url = `${environment.content_url}/meeting/${id}/participants/join`;
    return this.http.post<MeetingJoinResponse>(url, {}, { observe: 'body' }).pipe(
      map((response) => new MeetingJoinResponse(response)),
      // catchError(this.handleError),
    );
  }

  /**
   * Добавить в закладки
   */
  public addBookmark(id: string): Observable<Meeting> {
    const url = `${environment.content_url}/meeting/${id}/bookmark`;
    return this.http.post<Meeting>(url, {}, { observe: 'response' }).pipe(
      map(this.handleMeetingResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Удалить из закладок
   */
  public removeBookmark(id: string): Observable<Meeting> {
    const url = `${environment.content_url}/meeting/${id}/bookmark`;
    return this.http.delete<Meeting>(url, { observe: 'response' }).pipe(
      map(this.handleMeetingResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Принять приглашение на митинг
   */
  public acceptMeeting(id: string): Observable<[Meeting, MeetingParticipant]> {
    const url = `${environment.content_url}/meeting/${id}/participants/accept`;
    return this.http.post(url, {}, { observe: 'body' }).pipe(
      map((body: { meeting: Partial<Meeting>; participant: Partial<MeetingParticipant> }) => {
        const { meeting, participant } = body;
        return [meeting ? new Meeting(meeting) : null, participant ? new MeetingParticipant(participant) : null];
      }),
    );
  }

  /**
   * Отклонить приглашение на митинг
   */
  public declineMeeting(id: string): Observable<MeetingParticipant> {
    // TODO [Meeting, MeetingParticipant]
    const url = `${environment.content_url}/meeting/${id}/participants/decline`;
    return this.http.post(url, {}, { observe: 'response' }).pipe(map((response: any) => response.participant));
  }

  /**
   * Проверка доступности времени для митинга
   */
  public checkThatTimeIsAvailableForMeeting(
    from: Date,
    to: Date,
    user_id: string,
    meeting_id?: string,
    details?: MeetingDetailsType[],
  ): Observable<{ result: boolean; meeting: Meeting }> {
    meeting_id = meeting_id || '-';
    let params = new HttpParams();
    if (details && details.length) {
      params = params.set('details', JSON.stringify(details));
    }
    const url = `${environment.content_url}/meeting/${meeting_id}/available-for/${user_id}`;
    return this.http.post(url, { from, to }, { params, observe: 'body' }).pipe(
      map((body: any) => body),
      catchError(async (err: any) => false),
    );
  }

  /** Информация для оплаты митинга */
  public getMeetingCheckoutDetails(request: MeetingCheckoutRequest): Observable<MeetingCheckoutResponse> {
    const url = `${environment.content_url}/meeting/checkout`;
    return this.http
      .post<MeetingCheckoutResponse>(url, request, { observe: 'body' })
      .pipe(map((response) => new MeetingCheckoutResponse(response)));
  }

  private handleMeetingResponse(response: HttpResponse<Meeting>): Meeting {
    return new Meeting(response.body);
  }

  private handleMeetingsResponse(response: HttpResponse<Meeting[]>): IPagedResults<Meeting[]> {
    return createPagedResultsFromResponse(response, (item) => new Meeting(item));
  }

  // TODO в случае 401 оишбки - сгенерить новый токен и повторить запрос
  // private handleError(error: HttpErrorResponse) {
  //   console.error('server error:', error);
  //   if (error.error instanceof Error) {
  //     const errMessage = error.error.message;
  //     return throwError(errMessage);
  //     // Use the following instead if using lite-server
  //     // return throwError(err.text() || 'backend server error');
  //   }
  //   return throwError(error || 'Node.js server error');
  // }
}
