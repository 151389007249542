import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MediaFileSelectorModule } from '@app/core/components/media-file-selector/media-file-selector.module';

import { AspectRatioSelectorComponent } from './aspect-ratio-selector.component';

@NgModule({
  declarations: [AspectRatioSelectorComponent],
  imports: [CommonModule, MediaFileSelectorModule],
  exports: [AspectRatioSelectorComponent],
})
export class AspectRatioModule {}
