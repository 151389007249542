import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonFabComponent } from './button-fab.component';

@NgModule({
  declarations: [ButtonFabComponent],
  imports: [CommonModule],
  exports: [ButtonFabComponent],
})
export class ButtonFabModule {}
