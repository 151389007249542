<article class="first-contact-widget" [class.mobile]="deviceService.isMobile()">
  <h3 class="title">{{ 'first-contact.widget.title' | translate }}</h3>
  <p class="text">{{ 'first-contact.widget.text' | translate }}</p>
  <on-ui-button class="third"
                label="first-contact.widget.action-label"
                (onclick)="onToggleModal()">
  </on-ui-button>
  <!--<span class="close-action" (click)="onClose()">{{ 'first-contact.widget.action-close' | translate }}</span>-->
</article>

<app-modal *ngIf="openModal && !deviceService.isMobile()" [canClose]="false" (closed)="onToggleModal()">
  <app-modal-body>
    <app-first-contact></app-first-contact>
  </app-modal-body>
</app-modal>

<app-mobile-modal *ngIf="openModal && deviceService.isMobile()" (closed)="onToggleModal()">
  <app-mobile-modal-body>
    <app-first-contact></app-first-contact>
  </app-mobile-modal-body>
</app-mobile-modal>
