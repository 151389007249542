<section class="first-contact-meeting-types" [class.mobile]="deviceService.isMobile()">
  <h2 *ngIf="title()" class="title">{{ title() | translate }}</h2>

  <div class="types-list">
    <div *ngFor="let typeItem of typesList()" class="type-item">
      <div class="icon-wrap">
        <svg class="icon" viewBox="0 0 46 46">
          <use [attr.xlink:href]="iconName(typeItem.type)"></use>
        </svg>
      </div>
      <h4 class="title-item" [innerHTML]="typeItem.title | translate"></h4>
      <p class="text-item">{{ typeItem.text | translate }}</p>
    </div>
  </div>
</section>

<svg class="svg-sprite" xmlns="http://www.w3.org/2000/svg">
  <symbol id="meeting-types-single" viewBox="0 0 46 46" fill="none">
    <path d="M17.25 21.083L23 26.833L42.1667 7.66638" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M40.25 23V36.4167C40.25 37.4333 39.8461 38.4084 39.1272 39.1272C38.4084 39.8461 37.4333 40.25 36.4167 40.25H9.58333C8.56667 40.25 7.59165 39.8461 6.87276 39.1272C6.15387 38.4084 5.75 37.4333 5.75 36.4167V9.58333C5.75 8.56667 6.15387 7.59165 6.87276 6.87276C7.59165 6.15387 8.56667 5.75 9.58333 5.75H30.6667" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  </symbol>

  <symbol id="meeting-types-regular" viewBox="0 0 46 46" fill="none">
    <path d="M44.084 7.66699V19.167H32.584" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1.91602 38.333V26.833H13.416" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.72685 17.2496C7.69892 14.5026 9.35102 12.0466 11.529 10.1108C13.707 8.17503 16.3398 6.8225 19.1819 6.17944C22.024 5.53639 24.9826 5.62377 27.7818 6.43343C30.581 7.24309 33.1294 8.74865 35.1894 10.8096L44.0827 19.1663M1.91602 26.8329L10.8093 35.1896C12.8693 37.2506 15.4177 38.7561 18.2169 39.5658C21.0161 40.3755 23.9747 40.4628 26.8168 39.8198C29.6589 39.1767 32.2917 37.8242 34.4697 35.8884C36.6477 33.9526 38.2998 31.4966 39.2719 28.7496" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  </symbol>

  <symbol id="meeting-types-course" viewBox="0 0 46 46" fill="none">
    <path d="M35.8546 8.60645H10.6068C8.61483 8.60645 7 10.2213 7 12.2133V37.461C7 39.453 8.61483 41.0678 10.6068 41.0678H35.8546C37.8466 41.0678 39.4614 39.453 39.4614 37.461V12.2133C39.4614 10.2213 37.8466 8.60645 35.8546 8.60645Z" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M30.4443 5V12.2136" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.0166 5V12.2136" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7 19.4268H39.4614" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  </symbol>

  <symbol id="meeting-types-mini-course" viewBox="0 0 46 46" fill="none">
    <path d="M15.3336 11.5H40.2503" stroke="#5AB3E4" stroke-width="3.38298" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.3336 23H40.2503" stroke="#5AB3E4" stroke-width="3.38298" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.3336 34.5H40.2503" stroke="#5AB3E4" stroke-width="3.38298" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.75 11.5H5.76958" stroke="#5AB3E4" stroke-width="3.38298" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.75 23H5.76958" stroke="#5AB3E4" stroke-width="3.38298" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.75 34.5H5.76958" stroke="#5AB3E4" stroke-width="3.38298" stroke-linecap="round" stroke-linejoin="round"/>
  </symbol>

  <symbol id="meeting-types-webinar" viewBox="0 0 46 46" fill="none">
    <path d="M40.25 22.0417C40.2566 24.5714 39.6656 27.0669 38.525 29.325C37.1727 32.0308 35.0937 34.3067 32.5209 35.8978C29.9482 37.4888 26.9833 38.3322 23.9583 38.3333C21.4286 38.3399 18.9331 37.7489 16.675 36.6083L5.75 40.25L9.39167 29.325C8.25112 27.0669 7.66007 24.5714 7.66667 22.0417C7.66784 19.0167 8.51117 16.0518 10.1022 13.479C11.6933 10.9063 13.9692 8.82733 16.675 7.47499C18.9331 6.33445 21.4286 5.7434 23.9583 5.74999H24.9167C28.9117 5.97039 32.685 7.65662 35.5142 10.4858C38.3434 13.315 40.0296 17.0883 40.25 21.0833V22.0417Z" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  </symbol>

  <symbol id="meeting-types-stream" viewBox="0 0 46 46" fill="none">
    <path d="M23 3.75C21.6076 3.75 20.2723 4.30312 19.2877 5.28769C18.3031 6.27226 17.75 7.60761 17.75 9V23C17.75 24.3924 18.3031 25.7277 19.2877 26.7123C20.2723 27.6969 21.6076 28.25 23 28.25C24.3924 28.25 25.7277 27.6969 26.7123 26.7123C27.6969 25.7277 28.25 24.3924 28.25 23V9C28.25 7.60761 27.6969 6.27226 26.7123 5.28769C25.7277 4.30312 24.3924 3.75 23 3.75V3.75Z" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M35.25 19.5V23C35.25 26.2489 33.9594 29.3647 31.6621 31.6621C29.3647 33.9594 26.2489 35.25 23 35.25C19.7511 35.25 16.6353 33.9594 14.3379 31.6621C12.0406 29.3647 10.75 26.2489 10.75 23V19.5" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M23 35.25V42.25" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16 42.25H30" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  </symbol>

  <symbol id="meeting-types-group" viewBox="0 0 46 46" fill="none">
    <path d="M32.5836 40.25V36.4167C32.5836 34.3833 31.7759 32.4333 30.3381 30.9955C28.9003 29.5577 26.9503 28.75 24.917 28.75H9.58363C7.5503 28.75 5.60026 29.5577 4.16248 30.9955C2.7247 32.4333 1.91696 34.3833 1.91696 36.4167V40.25" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.2495 21.0833C21.4837 21.0833 24.9162 17.6508 24.9162 13.4167C24.9162 9.18248 21.4837 5.75 17.2495 5.75C13.0153 5.75 9.58286 9.18248 9.58286 13.4167C9.58286 17.6508 13.0153 21.0833 17.2495 21.0833Z" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M44.0834 40.2504V36.4171C44.0822 34.7184 43.5168 33.0682 42.4761 31.7257C41.4353 30.3831 39.9782 29.4242 38.3334 28.9996" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M30.6665 5.99957C32.3157 6.42181 33.7774 7.38091 34.8212 8.72566C35.865 10.0704 36.4316 11.7243 36.4316 13.4266C36.4316 15.129 35.865 16.7829 34.8212 18.1276C33.7774 19.4724 32.3157 20.4315 30.6665 20.8537" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  </symbol>

  <symbol id="meeting-types-individual" viewBox="0 0 46 46" fill="none">
    <path d="M38.333 40.25V36.4167C38.333 34.3833 37.5253 32.4333 36.0875 30.9955C34.6497 29.5577 32.6997 28.75 30.6664 28.75H15.333C13.2997 28.75 11.3496 29.5577 9.91187 30.9955C8.47409 32.4333 7.66635 34.3833 7.66635 36.4167V40.25" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M23.0003 21.0833C27.2345 21.0833 30.667 17.6509 30.667 13.4167C30.667 9.18248 27.2345 5.75 23.0003 5.75C18.7661 5.75 15.3336 9.18248 15.3336 13.4167C15.3336 17.6509 18.7661 21.0833 23.0003 21.0833Z" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  </symbol>

  <symbol id="meeting-types-card" viewBox="0 0 46 46" fill="none">
    <path d="M40.2503 7.66699H5.75033C3.63323 7.66699 1.91699 9.38323 1.91699 11.5003V34.5003C1.91699 36.6174 3.63323 38.3337 5.75033 38.3337H40.2503C42.3674 38.3337 44.0837 36.6174 44.0837 34.5003V11.5003C44.0837 9.38323 42.3674 7.66699 40.2503 7.66699Z" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1.91699 19.167H44.0837" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  </symbol>

  <symbol id="meeting-types-secure" viewBox="0 0 46 46" fill="none">
    <path d="M7.66732 5.75H38.334C39.3506 5.75 40.3257 6.15387 41.0446 6.87276C41.7635 7.59165 42.1673 8.56667 42.1673 9.58333V21.0833C42.1673 26.1666 40.148 31.0418 36.5535 34.6362C32.9591 38.2307 28.084 40.25 23.0007 40.25C20.4836 40.25 17.9913 39.7542 15.6659 38.791C13.3405 37.8278 11.2276 36.416 9.44777 34.6362C5.85332 31.0418 3.83398 26.1666 3.83398 21.0833V9.58333C3.83398 8.56667 4.23785 7.59165 4.95674 6.87276C5.67563 6.15387 6.65065 5.75 7.66732 5.75V5.75Z" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.334 19.166L23.0007 26.8327L30.6673 19.166" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  </symbol>

  <symbol id="meeting-types-promotion" viewBox="0 0 46 46" fill="none">
    <path d="M44.0827 11.5L25.8743 29.7083L16.291 20.125L1.91602 34.5" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M32.584 11.5H44.084V23" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  </symbol>

  <symbol id="meeting-types-lessons" viewBox="0 0 46 46" fill="none">
    <path d="M4 6H15.1031C17.0663 6 18.9491 6.77986 20.3372 8.16802C21.7254 9.55619 22.5052 11.4389 22.5052 13.4021V39.3094C22.5052 37.8371 21.9204 36.425 20.8792 35.3839C19.8381 34.3428 18.426 33.7579 16.9537 33.7579H4V6Z" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M41.0101 6H29.907C27.9438 6 26.0611 6.77986 24.6729 8.16802C23.2847 9.55619 22.5049 11.4389 22.5049 13.4021V39.3094C22.5049 37.8371 23.0898 36.425 24.1309 35.3839C25.172 34.3428 26.5841 33.7579 28.0565 33.7579H41.0101V6Z" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  </symbol>

  <symbol id="meeting-types-collections" viewBox="0 0 46 46" fill="none">
    <path d="M40.25 15.334V40.2507H5.75V15.334" stroke="#5AB3E4" stroke-width="2.9363" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M44.0832 5.75H1.9165V15.3333H44.0832V5.75Z" stroke="#5AB3E4" stroke-width="2.9363" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.1665 23H26.8332" stroke="#5AB3E4" stroke-width="2.9363" stroke-linecap="round" stroke-linejoin="round"/>
  </symbol>

  <symbol id="meeting-types-webinars" viewBox="0 0 46 46" fill="none">
    <path d="M44.0837 14.1572L30.667 23.5228L44.0837 32.8883V14.1572Z" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M26.8332 10.4102H5.74984C3.63275 10.4102 1.9165 12.0874 1.9165 14.1564V32.8874C1.9165 34.9564 3.63275 36.6336 5.74984 36.6336H26.8332C28.9503 36.6336 30.6665 34.9564 30.6665 32.8874V14.1564C30.6665 12.0874 28.9503 10.4102 26.8332 10.4102Z" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  </symbol>

  <symbol id="meeting-types-interactive" viewBox="0 0 46 46" fill="none">
    <path d="M4 5.75L17.5508 38.2758L22.3617 24.1117L36.5258 19.3008L4 5.75Z" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M24.9165 24.917L36.4165 36.417" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  </symbol>

  <symbol id="meeting-types-hometasks" viewBox="0 0 46 46" fill="none">
    <path d="M5.75 17.2497L23 3.83301L40.25 17.2497V38.333C40.25 39.3497 39.8461 40.3247 39.1272 41.0436C38.4084 41.7625 37.4333 42.1663 36.4167 42.1663H9.58333C8.56667 42.1663 7.59165 41.7625 6.87276 41.0436C6.15387 40.3247 5.75 39.3497 5.75 38.333V17.2497Z" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.25 42.1667V23H28.75V42.1667" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  </symbol>

  <symbol id="meeting-types-courses" viewBox="0 0 46 46" fill="none">
    <g clip-path="url(#clip0_1159_6696)">
      <path d="M9.50118 36.2243H5.40953C4.32435 36.2243 3.28362 35.7941 2.51629 35.0284C1.74896 34.2627 1.31787 33.2241 1.31787 32.1412V15.8087C1.31787 14.7258 1.74896 13.6872 2.51629 12.9215C3.28362 12.1558 4.32435 11.7256 5.40953 11.7256H11.9357M29.9595 11.7256H34.0511C35.1363 11.7256 36.177 12.1558 36.9443 12.9215C37.7117 13.6872 38.1428 14.7258 38.1428 15.8087V32.1412C38.1428 33.2241 37.7117 34.2627 36.9443 35.0284C36.177 35.7941 35.1363 36.2243 34.0511 36.2243H27.5249" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M43.7402 28.4116V19.9834" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M21.7766 11.7256L13.5933 23.9749H25.8682L17.6849 36.2243" stroke="#5AB3E4" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_1159_6696">
        <rect width="46" height="46" fill="white"/>
      </clipPath>
    </defs>
  </symbol>
</svg>
