<div class="dropdown" (clickOutside)="closeList()">
  <div class="label strong" (click)="toggleList()">
    <span class="symbol strong">{{ currency?.symbol }}</span>
    <span class="title">{{ currency?.title | translate }}</span>
  </div>
  <ng-template [ngIf]="!isSmallScreen">
    <div class="options" [class.open]="listOpened">
      <ng-container *ngTemplateOutlet="option"></ng-container>
    </div>
  </ng-template>
</div>

<ng-template #option>
  <div *ngFor="let option of currencies" class="option"
       (click)="setCurrency(option.id)">
    <span class="symbol">{{ option.symbol }}</span>
    <span class="title">{{ option.title | translate }}</span>
  </div>
</ng-template>

<ng-template [ngIf]="isSmallScreen">
  <app-mobile-modal *ngIf="listOpened" (closed)="toggleList()" >
    <app-mobile-modal-title>{{ 'language-selector.choose-language' | translate }}</app-mobile-modal-title>
    <app-mobile-modal-body>
      <div class="modal-options-wrap">
        <ng-container *ngTemplateOutlet="option"></ng-container>
      </div>
    </app-mobile-modal-body>
  </app-mobile-modal>
</ng-template>
