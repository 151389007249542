import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ContextService, FeaturesService, PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { FeatureEnum, IUserTracker, User, UserTrackerEnum } from 'lingo2-models';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-onboarding-trackers',
  templateUrl: './onboarding-trackers.component.html',
  styleUrls: ['./onboarding-trackers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingTrackersComponent extends ChangableComponent implements OnInit {
  @Input() trackers: IUserTracker[];
  @Input() dueDate: Date;

  public currentDate = new Date();
  public dateFnsOptions = { unit: 'day' };
  protected _me: User;

  public constructor(
    protected contextService: ContextService,
    protected features: FeaturesService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  public ngOnInit() {
    this.contextService.me$
      .pipe(
        tap((me) => {
          this._me = me;
          this.detectChanges();
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  public get meetingsTracker(): IUserTracker {
    return this.trackers && this.trackers.find((x) => x.tracker === UserTrackerEnum.monthly_meetings_provided);
  }

  public get lessonsTracker(): IUserTracker {
    return this.trackers && this.trackers.find((x) => x.tracker === UserTrackerEnum.monthly_lessons_published);
  }

  public get remainDays(): Date {
    // Возвращаем текущую дату, если срок уже прошел, но статус еще не поменялся
    return this.dueDate > this.currentDate ? this.dueDate : this.currentDate;
  }

  public get isAvailableMobileApps(): boolean {
    return this.features.isAvailable(FeatureEnum.mobile_apps, this._me);
  }

  public get isAvailableOnboardingTrackers(): boolean {
    return this.features.isAvailable(FeatureEnum.onboarding_trackers, this._me);
  }
}
