/**
 * https://app.clickup.com/t/8693zu39f
 * https://console.firebase.google.com/u/0/project/onclass-11649/config/env/firebase
 * https://firebase.google.com/docs/remote-config/get-started?platform=web
 * https://weiyuan-liu.medium.com/a-b-testing-and-remote-config-made-easy-for-angular-41caa331f866
 */

export interface IPromoConfig20240604 {
  /**
   * Лендинг <app-promo-me>
   * Что делать по кнопке "Try for free"
   * Варианты:
   * * signin
   * * choose_role
   */
  appPromoMe__freeOffer__action: string;

  /**
   * Лендинг <app-promo-me>
   * Показать информацию о тарифах после установки роли
   * Варианты:
   * * true
   * * false
   */
  appPromoMe__onRoleChoosen__paywall: boolean;

  /**
   * Модальное окно авторизации <app-auth-modal>
   * Показать информацию о тарифах после авторизации
   * Варианты:
   * * true
   * * false
   */
  authModal__onAuthSuccess__paywall: boolean;

  /**
   * Кнопка "Virtual Class"
   * Требуется платный тариф
   * * true
   * * false
   */
  quickMeeting__paywall: boolean;

  /**
   * Кнопка "Virtual Class"
   * Требуется авторизация
   * * true
   * * false
   */
  quickMeeting__auth: boolean;

  /**
   * Кнопка "Schedule a class"
   * Требуется платный тариф
   * * true
   * * false
   */
  scheduleMeeting__paywall: boolean;

  /**
   * Кнопка "Schedule a class"
   * Требуется авторизация
   * * true
   * * false
   */
  scheduleMeeting__auth: boolean;

  /**
   * Кнопка "Create lesson"
   * Требуется авторизация
   * * true
   * * false
   */
  createLesson__auth: boolean;

  /**
   * Кнопка "Create lesson"
   * Требуется платный тариф
   * * true
   * * false
   */
  createLesson__paywall: boolean;

  /**
   * Кнопка "Publish" в конструкторе уроков
   * Требуется авторизация
   * * true
   * * false
   */
  publishLesson__auth: boolean;

  /**
   * Кнопка "Publish" в конструкторе уроков
   * Требуется платный тариф
   * * true
   * * false
   */
  publishLesson__paywall: boolean;

  /**
   * Кнопка "Save term" в словаре
   * Требуется авторизация
   * * true
   * * false
   */
  createVocabulary__auth: boolean;

  /**
   * Кнопка "Save term" в словаре
   * Требуется платный тариф
   * * true
   * * false
   */
  createVocabulary__paywall: boolean;

  // calendar_workingTime__auth: boolean;
  // calendar_workingTime__paywall: boolean;
}

/**
 * Детали конфигурации для режима appPromoMe__freeOffer__config='promo_a_config'
 *
 * На лендинге регистрация
 * Пейвол на любое действие
 */
export const promo_a_config: IPromoConfig20240604 = {
  appPromoMe__freeOffer__action: 'signin',
  appPromoMe__onRoleChoosen__paywall: false,

  authModal__onAuthSuccess__paywall: false,

  quickMeeting__auth: true,
  quickMeeting__paywall: true,

  scheduleMeeting__auth: true,
  scheduleMeeting__paywall: true,

  createLesson__auth: true,
  createLesson__paywall: true,

  publishLesson__auth: true,
  publishLesson__paywall: true,

  createVocabulary__auth: true,
  createVocabulary__paywall: false,
};

/**
 * Детали конфигурации для режима appPromoMe__freeOffer__config='promo_b_config'
 *
 * На лендинге регистрация
 * Даем пользоваться для некоторых функций
 * Пейволл на финальные функции
 */
export const promo_b_config: IPromoConfig20240604 = {
  appPromoMe__freeOffer__action: 'signin',
  appPromoMe__onRoleChoosen__paywall: false,
  authModal__onAuthSuccess__paywall: false,

  quickMeeting__auth: true,
  quickMeeting__paywall: false,

  scheduleMeeting__auth: true,
  scheduleMeeting__paywall: true,

  createLesson__auth: true,
  createLesson__paywall: false,

  publishLesson__auth: true,
  publishLesson__paywall: true,

  // --- не известно

  createVocabulary__auth: true,
  createVocabulary__paywall: false,
};

/**
 * Детали конфигурации для режима appPromoMe__freeOffer__config='promo_c_config'
 *
 * На лендинге спрашиваем роль
 * Регистрация на любое действие, а потом пейвол
 */
export const promo_c_config: IPromoConfig20240604 = {
  appPromoMe__freeOffer__action: 'choose_role',
  appPromoMe__onRoleChoosen__paywall: false,

  authModal__onAuthSuccess__paywall: false,

  quickMeeting__auth: true,
  quickMeeting__paywall: true,

  scheduleMeeting__auth: true,
  scheduleMeeting__paywall: true,

  createLesson__auth: true,
  createLesson__paywall: true,

  publishLesson__auth: true,
  publishLesson__paywall: true,

  // --- не известно

  createVocabulary__auth: true,
  createVocabulary__paywall: false,
};

/**
 * Детали конфигурации для режима appPromoMe__freeOffer__config='promo_d_config'
 *
 * На лендинге спрашиваем роль
 * Даем пользоваться для некоторых функций
 * Регистрация + пейволл на финальные функции
 */
export const promo_d_config: IPromoConfig20240604 = {
  appPromoMe__freeOffer__action: 'choose_role',
  appPromoMe__onRoleChoosen__paywall: false,

  authModal__onAuthSuccess__paywall: false,

  quickMeeting__auth: false,
  quickMeeting__paywall: false,

  scheduleMeeting__auth: true,
  scheduleMeeting__paywall: true,

  createLesson__auth: false,
  createLesson__paywall: false,

  publishLesson__auth: true,
  publishLesson__paywall: true,

  // --- не известно

  createVocabulary__auth: true,
  createVocabulary__paywall: false,
};

const promoConfig20240604Defaults: IPromoConfig20240604 = {
  appPromoMe__freeOffer__action: 'signin',
  appPromoMe__onRoleChoosen__paywall: false,
  authModal__onAuthSuccess__paywall: false,
  quickMeeting__auth: false,
  quickMeeting__paywall: false,
  scheduleMeeting__auth: false,
  scheduleMeeting__paywall: false,
  createLesson__auth: false,
  createLesson__paywall: false,
  publishLesson__auth: false,
  publishLesson__paywall: false,
  createVocabulary__auth: false,
  createVocabulary__paywall: false,
};

export const remoteConfigDefaults = {
  /**
   * Лендинг <app-promo-me>
   * Какой вариант поведения выбрать по кнопке "Try for free"
   */
  appPromoMe__freeOffer__config: 'promo_d_config',

  /**
   * Лендинг <app-promo-me>
   * Какие варианты поведения доступны для конфигурирования кнопки "Try for free"
   */
  appPromoMe__freeOffer__configs: ['promo_a_config', 'promo_b_config', 'promo_c_config', 'promo_d_config'],

  /**
   * Сайдбар
   * Баннер тарифа и баланса
   * Варианты условий отображения:
   * * false = не показывать
   * * true = показать (есть внутренняя логика отображения)
   */
  appLeftSidebar__bannerPlan__show: true,

  /**
   * Сайдбар
   * Кнопка "Virtual Class"
   * Варианты условий отображения:
   * * false = не показывать
   * * true = показать
   */
  appLeftSidebar__quickMeeting__show: true,

  /**
   * Сайдбар
   * Кнопка "Schedule a class"
   * Варианты условий отображения:
   * * false = не показывать
   * * true = показать
   */
  appLeftSidebar__scheduleMeeting__show: true,

  /**
   * Подвал
   * Кнопка "Virtual Class"
   * Варианты условий отображения:
   * * false = не показывать
   * * true = показать
   */
  appFooter__quickMeeting__show: true,

  /**
   * Подвал
   * Кнопка "Schedule a class"
   * Варианты условий отображения:
   * * false = не показывать
   * * true = показать
   */
  appFooter__scheduleMeeting__show: true,

  /**
   * Компонента <app-paywall-plans>
   * Варианты условий отображения блока "Триальный тариф":
   * * false = не показывать
   * * true = показать
   */
  paywallPlans__trialPlan__show: true,

  ...promoConfig20240604Defaults,
};

export type RemoteConfigType = typeof remoteConfigDefaults & Partial<IPromoConfig20240604>;
