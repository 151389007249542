import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { WebsocketService } from '@app/core/websocket';
import { BillingService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { BillingOperationStatusEnum, BillingPaymentProviderEnum, CSchool, BillingTransaction } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-finance-card-create',
  templateUrl: './finance-card-create.component.html',
  styleUrls: ['./finance-card-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinanceCardCreateComponent extends ChangableComponent implements OnInit {
  @Input() provider: BillingPaymentProviderEnum;
  @Input() country: string;
  @Input() public set school(school: CSchool) {
    this._school = school;
  }
  @Output() finished = new EventEmitter<boolean>();

  public error: string;
  public loaded = false;
  public loading = true;
  public safeTinkoffPaymentUrl: SafeResourceUrl;

  private _billingTransaction: BillingTransaction;
  private _school: CSchool;

  public constructor(
    private billingService: BillingService,
    private sanitizer: DomSanitizer,
    private websocketService: WebsocketService,
    public deviceService: DeviceDetectorService,
    protected cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  public ngOnInit() {
    this.provider = BillingPaymentProviderEnum.tinkoff;

    switch (this.provider) {
      case BillingPaymentProviderEnum.tinkoff:
        const details = !!this._school ? { school_id: this._school?.id } : {};
        this.billingService
          .prepareCardAttachment(details)
          .pipe(
            tap(
              (billingTransaction) => {
                if (billingTransaction.status === BillingOperationStatusEnum.pending) {
                  this.safeTinkoffPaymentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
                    billingTransaction.details.paymentUrl,
                  );
                  this.loaded = true;
                } else {
                  this.error = billingTransaction.details.error;
                }
                this.loading = false;
                this.listenForFinish(billingTransaction.billing_operation_id);
                this.markForCheck();
              },
              (err: any) => {
                this.loading = false;
                this.error = 'Operation is temporary unavailable';
                this.markForCheck();
              },
            ),
          )
          .pipe(takeUntil(this.destroyed$))
          .subscribe();
        break;
    }
  }

  public listenForFinish(id) {
    if (!id) {
      return;
    }
    /** RACE! */
    this.websocketService
      .onBillingOperation(id) // изменение состояния операции
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        if (data.status.toString() === BillingOperationStatusEnum.succeed.toString()) {
          this.finished.emit(true);
        } else {
          this.finished.emit(false);
        }
      });
    const checker = this.setInterval(() => {
      this.billingService
        .getBillingOperation(id)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((data) => {
          switch (data.status) {
            case BillingOperationStatusEnum.succeed:
              this.finished.emit(true);
              checker.unsubscribe();
              break;
            case BillingOperationStatusEnum.failed:
              this.finished.emit(false);
              checker.unsubscribe();
              break;
          }
        });
    }, 5000);
  }

  public authorizeCard() {
    // switch (this.provider) {
    //   case BillingPaymentProviderEnum.tinkoff:
    //     this.subscription.add(
    //       this.billingService.confirmTinkoffPayment(this.tinkoffPrePayment.PaymentId)
    //         .subscribe(
    //           (response) => {
    //             logger.log({ response });
    //             // this.tinkoffPrePayment = prePayment;
    //             // this.safeTinkoffPaymentFormUrl = this.sanitizer.bypassSecurityTrustResourceUrl(prePayment.payment_form_url);
    //             // this.loading = false;
    //             // this.loaded = true;
    //           },
    //           (err) => {
    //             this.loading = false;
    //             this.error = 'Operation is temporary unavailable';
    //           }));
    //     break;
    // }
  }
}
