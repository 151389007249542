<ng-template [ngIf]="deviceService.isDesktop()" [ngIfElse]="mobile">
  <div *ngIf="me" class="user-menu" [class.expand]="expandMenuState">
    <a *ngIf="!userpicUrl && !isGuest" [routerLink]="'/profile'"
       class="add-avatar-text">{{ 'user-menu.add-avatar' | translate }}</a>
    <div class="avatar-wrap">
      <ng-template [ngIf]="isGuest" [ngIfElse]="profileLinkRef">
        <ng-container *ngTemplateOutlet="avatar"></ng-container>
      </ng-template>
      <ng-template #profileLinkRef>
        <a [routerLink]="accountRoute(me)">
          <ng-container *ngTemplateOutlet="avatar"></ng-container>
        </a>
      </ng-template>
    </div>
    <div class="menu-block">
      <ng-container *ngTemplateOutlet="menu"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #menu>
  <div class="menu">
    <div class="header" *ngIf="!isProfilePage || !deviceService.isMobile() || !isTeacher">
      <div class="header-avatar-wrap" (click)="closeMenu()">
        <ng-template [ngIf]="isGuest" [ngIfElse]="userpicLinkRef">
          <ng-container *ngTemplateOutlet="headerAvatar"></ng-container>
        </ng-template>
        <ng-template #userpicLinkRef>
          <a [routerLink]="accountRoute(me)">
            <ng-container *ngTemplateOutlet="headerAvatar"></ng-container>
          </a>
        </ng-template>

      </div>
      <div class="name-wrap">
        <span *ngIf="name" class="name">{{ name }}</span>
        <ng-template [ngIf]="!name">
          <span *ngIf="isGuest" (click)="authGuardedNavigate('/profile')" class="name old question link">
            {{ 'user-menu.question' | translate }}</span>
          <a *ngIf="!isGuest" routerLink="/profile" class="name question">
            {{ 'user-menu.question' | translate }}</a>
        </ng-template>


        <span class="country">{{ country }}</span>
      </div>
    </div>
    <ng-container *ngIf="isProfilePage && deviceService.isMobile()">
      <app-verification-widget *ngIf="isTeacher" [user]="me" view="mini-header"></app-verification-widget>
    </ng-container>
    <ul class="menu-list">
      <li class="menu-item" *ngIf="!isGuest" (click)="closeMenu()">
        <a [routerLink]="accountRoute(me)" class="item-text">
          {{ 'user-menu.items.personal-profile' | translate }}</a>

        <span *ngIf="isGuest" class="link item-text"
              data-test="edit-profile"
              (click)="authGuardedNavigate('/profile')">
          {{ 'user-menu.items.edit-profile' | translate }}
        </span>
        <a *ngIf="!isGuest" class="link item-text active" data-test="edit-profile" routerLink="/profile">
          {{ 'user-menu.items.edit-profile' | translate }}
          <i *ngIf="isPopoverNeeded && isTeacher" class="red-dot"></i>
        </a>
      </li>

      <li class="menu-item" (click)="closeMenu()">
        <hr class="separator">
      </li>

      <li *ngIf="isGuest || !isTeacher" class="menu-item" (click)="becomeTeacher()">
        <span class="item-text">
          {{ 'user-menu.items.take-up-teaching' | translate }}
        </span>
      </li>
      <li *ngIf="!isGuest" class="menu-item" (click)="closeMenu()">
        <a [routerLink]="isTeacher ? '/me/finance' : '/me/finance/cards'"
           class="item-text">{{ 'user-menu.items.payments' | translate }}</a>
      </li>
      <li class="menu-item">
        <hr class="separator">
      </li>
      <li class="menu-item" *ngIf="!isLimitedVersion">
        <span class="item-text" (click)="onToggleIntroductionModal()">
          {{ 'user-menu.items.introduction' | translate }}
        </span>
      </li>
      <li class="menu-item" *ngIf="isTeacher && !isLimitedVersion">
        <span class="item-text" (click)="onToggleRulesModal()">{{ 'user-menu.items.rules' | translate }}</span>
      </li>
      <li class="menu-item">
        <span class="item-text" (click)="openCrispChat()">{{ 'user-menu.items.support' | translate }}</span>
      </li>
      <li *ngIf="!isGuest" class="menu-item" (click)="closeMenu()">
        <a [routerLink]="'/me/settings'" class="item-text">{{ 'user-menu.items.settings' | translate }}</a>
      </li>

      <ng-template [ngIf]="!deviceService.isMobile()">
        <li *ngIf="!isGuest" class="menu-item" (click)="closeMenu()">
          <a [routerLink]="'/auth/signout'" class="item-text">{{ 'user-menu.items.signout' | translate }}</a>
        </li>
        <li *ngIf="isGuest" class="menu-item" (click)="closeMenu()">
          <span class="item-text" (click)="authGuardedNavigate('')">
            {{ 'user-menu.items.signin' | translate }}
          </span>
        </li>
      </ng-template>
    </ul>
  </div>
</ng-template>

<ng-template #mobile>
  <div class="mobile-user-menu" [class.display]="expandMenuState">
    <div class="avatar-wrap" (click)="onToggleExpand()">
      <ng-container *ngTemplateOutlet="avatar"></ng-container>
    </div>

    <div class="shade" (click)="onToggleExpand()"></div>
    <div class="menu-block">
      <ng-container *ngTemplateOutlet="menu"></ng-container>

      <div class="footer-block">
        <div class="selectors">
          <app-language-selector (changeOpenStateSelector)="closeMenu()"></app-language-selector>
          <app-currency-selector *ngIf="isAvailableProfileFinance" (click)="closeMenu()"></app-currency-selector>
        </div>
        <div class="actions-block" *ngIf="deviceService.isMobile()">
          <div *ngIf="!isGuest" (click)="closeMenu()">
            <on-ui-button class="primary outline" label="user-menu.items.signout" [routerLink]="'/auth/signout'"></on-ui-button>
          </div>
          <div *ngIf="isGuest" (click)="closeMenu()">
            <on-ui-button class="secondary" label="user-menu.items.signin" (click)="authGuardedNavigate('')"></on-ui-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #avatar>
  <img *ngIf="userpicUrl" [src]="userpicUrl" [alt]="name" class="avatar">
  <svg *ngIf="!userpicUrl" fill="#CECFD6" class="avatar">
    <use [attr.xlink:href]="svgsetIcon('guest-4')"></use>
  </svg>
</ng-template>

<ng-template #headerAvatar>
  <img *ngIf="userpicUrl" [src]="userpicUrl" [alt]="name" class="header-avatar">
  <svg *ngIf="!userpicUrl" fill="#CECFD6" class="header-avatar">
    <use [attr.xlink:href]="svgsetIcon('guest-4')"></use>
  </svg>
</ng-template>

<app-modal *ngIf="openIntroductionModal && !deviceService.isMobile()" [canClose]="false" (closed)="onToggleIntroductionModal()">
  <app-modal-body>
    <app-first-contact></app-first-contact>
  </app-modal-body>
</app-modal>
<app-mobile-modal *ngIf="openIntroductionModal && deviceService.isMobile()" (closed)="onToggleIntroductionModal()">
  <app-mobile-modal-body>
    <app-first-contact></app-first-contact>
  </app-mobile-modal-body>
</app-mobile-modal>

<app-modal *ngIf="!deviceService.isMobile() && isRulesModalOpened"
           class="rules-modal"
           (closed)="onToggleRulesModal()">
  <app-modal-body>
    <app-rules (onAgree)="onToggleRulesModal()"></app-rules>
  </app-modal-body>
</app-modal>

<app-mobile-modal *ngIf="deviceService.isMobile() && isRulesModalOpened"
                  class="rules-modal"
                  (closed)="onToggleRulesModal()">
  <app-mobile-modal-body>
    <app-rules (onAgree)="onToggleRulesModal()"></app-rules>
  </app-mobile-modal-body>
</app-mobile-modal>
