import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TranslateModule } from '@ngx-translate/core';
import { OnUiPopoverModule } from 'onclass-ui';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { MatAutocompleteTriggerAccessorDirective } from './directives/mat-autocomplete-trigger-accessor.directive';
import { MultiSelectComponent } from './multi-select.component';
import { HighlightPipe } from './pipes/highlight.pipe';

@NgModule({
  declarations: [MultiSelectComponent, HighlightPipe, MatAutocompleteTriggerAccessorDirective, CheckboxComponent],
  imports: [CommonModule, TranslateModule, MatAutocompleteModule, ReactiveFormsModule, OnUiPopoverModule],
  exports: [MultiSelectComponent],
})
export class MultiSelectModule {}
