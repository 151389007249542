<div class="window" [class.open]="opened"
     (clickOutside)="onClose($event)"
     [clickOutsideEnabled]="clickOutsideEnabled">
  <div class="header">
    <div class="tabs-block">
      <ul class="tabs">
        <li *ngIf="(recentImages$ | async).length" class="tab" [class.active]="tab === 'recent'" (click)="tab = 'recent'">
          <p class="tab-name">{{ 'media-gallery.tab-recent' | translate }}</p>
        </li>
        <li class="tab" [class.active]="tab === 'load'" (click)="tab = 'load'">
          <p class="tab-name">{{ 'media-gallery.tab-upload' | translate }}</p>
        </li>
        <li class="tab" [class.active]="tab === 'online'" (click)="tab = 'online'; setFocus()">
          <p class="tab-name">{{ 'media-gallery.tab-from-web' | translate }}</p>
        </li>
      </ul>
    </div>

    <div class="search-block" *ngIf="tab === 'online'">
      <div class="search-wrap" [class.on-focus]="isFocus">
        <svg class="search-image-icon"><use [attr.xlink:href]="svgSetIcon('search')"></use></svg>
        <input
          #search
          type="text"
          class="input"
          (focus)="onFocus()"
          (blur)="onFocus()"
          (keyup.enter)="onlineSearch($event)"
          [value]="onlineSearchQuery$ | async"
          placeholder="{{ 'media-gallery.input-search-placeholder' | translate }}">
      </div>
    </div>

    <!--
    <div>
      reachesLeftFlag={{ reachesLeftFlag }} reachesRightFlag={{ reachesRightFlag }}
    </div>
    -->
  </div>

  <div class="tab-content tab-load" *ngIf="tab == 'load'">
    <!-- This dropzone must be CSSed -->
    <div class="dropzone-wrap">
      <ng-template [ngIf]="is_dropzone_configured">
        <div class="dropzone dropzone-container"
             #dropzone
             [dropzone]="dropzoneConfig"
             (click)="onClick($event)"
             (init)="onUploadInit($event)"
             (maxFilesExceeded)="onDropZoneMaxFileExceeded($event)"
             (uploadProgress)="onUploadProgress($event)"
             (sending)="onUploadStart($event)"
             (error)="onUploadError($event)"
             (success)="onUploadSuccess($event)">
          <div class="dz-default dz-message">
            <div  *ngIf="type === 'image' && !imageUrl && !is_uploading" class="image-placeholder">
              <svg class="placeholder-icon" viewBox="0 0 56 56">
                <use [attr.xlink:href]="svgSetIcon('uploader-placeholder')"></use>
              </svg>
              <div class="placeholder-text-wrap">
                <span class="placeholder-text">{{ 'meetings.dz-placeholder-text' | translate }}</span>&ensp;
                <span class="placeholder-text action">
                  {{ 'meetings.dz-placeholder-link1' | translate }}
                </span>
              </div>
            </div>

            <svg *ngIf="type === 'audio' && !audioPlayerConfig?.singleTrack?.length && !is_uploading" class="icon-cloud-sound">
              <use [attr.xlink:href]="'/assets/icons/cloud.svg#sound'"></use>
            </svg>

            <spinner1 class="spinner1" *ngIf="is_uploading"></spinner1>

            <div *ngIf="type === 'image' && imageUrl" class="dropzone-image">
              <img [src]="imageUrl" alt="">
            </div>
          </div>
        </div>

        <div class="dropzone-tools" *ngIf="is_dropzone_configured && isBrowser && type === 'audio'" (click)="onClick($event)">
          <on-ui-audio-player *ngIf="audioPlayerConfig?.singleTrack?.length > 0" [audioPlayerConfig]="audioPlayerConfig"></on-ui-audio-player>
          <div class="dropzone-tools-item">{{ 'constructor.form-file-placeholder1' | translate }}</div>
          <div class="dropzone-tools-item link" (click)="onClick2($event)">
            {{ (isDragScrollEnabled ? 'constructor.form-file-placeholder2' : 'constructor.form-file-placeholder3') | translate }}
          </div>
        </div>
      </ng-template>
    </div>

    <!-- Legacy -->
    <div *ngIf="deviceService.isDesktop()"
         class="gallery-wrap"
         [style.width]="isDragScrollEnabled ? galleryWidth + 'px' : width + 'px'">

      <div *ngIf="reachesLeftFlag && isDragScrollEnabled" class="flap left">
        <div class="slide-btn" (click)="moveLeft()">
          <svg viewBox="0 0 12 22" class="slide-btn-ico" xmlns="http://www.w3.org/2000/svg">
            <use [attr.xlink:href]="'/assets/icons/arrow.svg#arrow'"></use>
          </svg>
        </div>
      </div>
      <div *ngIf="reachesRightFlag && isDragScrollEnabled" class="flap right">
        <div class="slide-btn right" (click)="moveRight()">
          <svg viewBox="0 0 12 22" class="slide-btn-ico" xmlns="http://www.w3.org/2000/svg">
            <use [attr.xlink:href]="'/assets/icons/arrow.svg#arrow'"></use>
          </svg>
        </div>
      </div>

      <ng-template [ngIf]="images.length > 0">
        <ng-template [ngIf]="isDragScrollEnabled" [ngIfElse]="nav2">
            <drag-scroll #gallery
                       class="gallery-scroll"
                       snap-disabled="true"
                       scroll-x-wheel-enabled="true"
                       (reachesLeftBound)="reachesLeftFlag = !$event"
                       (reachesRightBound)="reachesRightFlag = !$event">
            <div *ngFor="let drag of galleryScrollPages" drag-scroll-item class="gallery-scroll-page"></div>
            <app-media-image-gallery [images]="images" (choose)="onChooseImage($event)" (configured)="onGalleryConfigured($event)"></app-media-image-gallery>
          </drag-scroll>
        </ng-template>
        <ng-template #nav2>
          <div class="gallery-scroll mobile">
            <app-media-image-gallery [images]="images" (choose)="onChooseImage($event)" (configured)="onGalleryConfigured($event)"></app-media-image-gallery>
          </div>
        </ng-template>
      </ng-template>
    </div>
  </div>

  <!-- New school -->
  <div class="tab-content tab-recent" *ngIf="tab == 'recent'">
    <app-gallery-wrap
      [containerWidth]="componentWidth"
      [images]="recentImages$ | async"
      (onchoose)="onChooseImage($event)">
    </app-gallery-wrap>
  </div>

  <div class="tab-content tab-online" *ngIf="tab === 'online'">
    <ng-container *ngIf="(onlineSearchInProgress$ | async) === false && (onlineImages$ | async).length == 0">
      <div class="online-search-placeholder">
        {{ 'media-gallery.online-search-placeholder' | translate }}
      </div>
      <div class="search-wrap" [class.on-focus]="isFocus">
      <span (click)="onSearchIconClick($event)">
        <svg class="search-image-icon"><use [attr.xlink:href]=" svgSetIcon('search')"></use></svg>
      </span>
        <input
          type="text"
          class="input"
          [value]="onlineSearchQuery$ | async"
          (focus)="onFocus()"
          (blur)="onFocus()"
          (keyup.enter)="onlineSearch($event)"
          (keyup)="onlineSearchTextChange($event)"
          placeholder="{{ 'media-gallery.input-search-placeholder' | translate }}">
      </div>
    </ng-container>

    <div class="spinner-wrapper" *ngIf="(onlineSearchInProgress$ | async) === true">
      <spinner1></spinner1>
    </div>
    <div class="lupe-wrapper" *ngIf="(onlineSearchInProgress$ | async) === true && (onlineImages$ | async).length == 0">
      <svg class="lupe-icon" width="58" height="58" viewBox="0 0 24 23">
        <use [attr.xlink:href]="svgSetIcon('search')"></use>
      </svg>
    </div>
    <app-gallery-wrap
      *ngIf="(onlineSearchInProgress$ | async) === false"
      [containerWidth]="isDragScrollEnabled ? componentWidth : width"
      [disableStars]="true"
      [images]="onlineImages$ | async"
      (onchoose)="onChooseOnlineImage($event)" >
    </app-gallery-wrap>
  </div>

  <app-alert-single type="warning" *ngIf="showAlert$ | async" (closed)="onCloseMediaWarning($event)">
    <ng-container alert-title>{{ 'media-gallery.warning-title' | translate }}</ng-container>
    <ng-container alert-body>
      <div [innerHTML]="mediaAlertText$ | async"></div>
    </ng-container>
    <ng-container alert-buttons>
      <on-ui-button class="primary outline"
                    label="media-gallery.warning-button">
      </on-ui-button>
    </ng-container>
  </app-alert-single>
</div>

<on-ui-progress-bar [value]="percent"></on-ui-progress-bar>
