<div *ngIf="isUpdateNeeded" class="notification color">
  <p class="phrase-text normal">Уже {{ passedTimeString }} готова новая версия сайта. Обновите страницу!</p>

  <div class="actions-wrap">
    <on-ui-button (click)="refreshPage()"
                  class="primary outline"
                  state="default"
                  label="Обновить">
    </on-ui-button>
  </div>
</div>
