import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CountdownModule } from '@app/shared';
import { ZonedDatePipe } from '@core/pipes/zoned-time-to-utc.pipe';
import { NearestMeetWidgetComponent } from '@core/widgets/nearest-meet-widget/nearest-meet-widget.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormatPipeModule } from 'lingo2-ngx-date-fns';
import { OnUiButtonModule, OnUiCoverModule } from 'onclass-ui';

@NgModule({
  declarations: [NearestMeetWidgetComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormatPipeModule,
    RouterModule,
    CountdownModule,
    OnUiCoverModule,
    OnUiButtonModule,
    ZonedDatePipe,
  ],
  exports: [NearestMeetWidgetComponent],
})
export class NearestMeetWidgetModule {}
