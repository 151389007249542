import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { IUserAccess, IChangePasswordRequest, ITeachingSubject, UserProfile } from 'lingo2-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IChangeResult {
  id: string;
  status: 'changed' | 'code_required' | 'error' | 'code_failed' | false;
  model?: 'password' | 'new_password';
  error?: string;
  statusText?: string;

  /** длина кода подтверждения для смены данных */
  code_length?: number;

  /** способ доставки кода подтверждения для смены данных */
  media?: 'email' | 'sms' | 'telegram' | 'vk' | 'push';
}

export interface IVerificationResponse {
  status: 'error' | 'ok';
  error?: 'No profile' | 'Not ready' | 'Already verified' | 'Wrong case';
}

export interface ICheckChangePasswordResponse {
  /** Требуется указать старый пароль */
  old_password: boolean;
}

export function subjectPseudoId(subject: ITeachingSubject): string {
  return subject.subject_other_id ? subject.subject_other_id.split('-')[0] : subject.subject_id.toString();
}

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private version = 1;

  constructor(private http: HttpClient) {}

  public getProfile(user_id): Observable<UserProfile> {
    const url = `${environment.account_url}/profile/${this.version}/profile/${user_id}`;
    return this.http
      .get<UserProfile>(url, { observe: 'response' })
      .pipe(map((response) => new UserProfile(response.body)));
  }

  public updateProfile(profile: Partial<UserProfile>): Observable<UserProfile> {
    const url = `${environment.account_url}/profile/${this.version}/profile`;
    return this.http
      .put<UserProfile>(url, profile, { observe: 'response' })
      .pipe(map((response) => new UserProfile(response.body)));
  }

  public getSettings(user_id?: string): Observable<IUserAccess> {
    const url = `${environment.account_url}/profile/${this.version}/settings`;
    return this.http.get<IUserAccess>(url, { observe: 'response' }).pipe(map((response) => response.body));
  }

  public changeMobilePhone(mobile_phone: string): Observable<IChangeResult> {
    const url = `${environment.account_url}/profile/${this.version}/settings/mobile-phone`;
    return this.http
      .put<IChangeResult>(url, { mobile_phone }, { observe: 'response' })
      .pipe(map((response) => response.body));
  }

  public confirmMobilePhone(id, code): Observable<IChangeResult> {
    const url = `${environment.account_url}/profile/${this.version}/settings/mobile-phone/confirm`;
    return this.http
      .put<IChangeResult>(url, { id, code }, { observe: 'response' })
      .pipe(map((response) => response.body));
  }

  public resendMobilePhoneChange(id): Observable<IChangeResult> {
    const url = `${environment.account_url}/profile/${this.version}/settings/mobile-phone/confirm/${id}`;
    return this.http.post<IChangeResult>(url, {}, { observe: 'response' }).pipe(map((response) => response.body));
  }

  public cancelMobilePhoneChange(id): Observable<IChangeResult> {
    const url = `${environment.account_url}/profile/${this.version}/settings/mobile-phone/confirm/${id}`;
    return this.http.delete<IChangeResult>(url, { observe: 'response' }).pipe(map((response) => response.body));
  }

  public changeEmail(email): Observable<IChangeResult> {
    const url = `${environment.account_url}/profile/${this.version}/settings/email`;
    return this.http.put<IChangeResult>(url, { email }, { observe: 'response' }).pipe(map((response) => response.body));
  }

  public confirmEmail(id, code): Observable<IChangeResult> {
    const url = `${environment.account_url}/profile/${this.version}/settings/email/confirm`;
    return this.http
      .put<IChangeResult>(url, { id, code }, { observe: 'response' })
      .pipe(map((response) => response.body));
  }

  public resendEmailChange(id): Observable<IChangeResult> {
    const url = `${environment.account_url}/profile/${this.version}/settings/email/confirm/${id}`;
    return this.http.post<IChangeResult>(url, {}, { observe: 'response' }).pipe(map((response) => response.body));
  }

  public cancelEmailChange(id): Observable<IChangeResult> {
    const url = `${environment.account_url}/profile/${this.version}/settings/email/confirm/${id}`;
    return this.http.delete<IChangeResult>(url, { observe: 'response' }).pipe(map((response) => response.body));
  }

  public checkChangePassword(): Observable<ICheckChangePasswordResponse> {
    const url = `${environment.account_url}/profile/${this.version}/settings/password`;
    return this.http
      .get<ICheckChangePasswordResponse>(url, { observe: 'response' })
      .pipe(map((response) => response.body));
  }

  public changePassword(values: IChangePasswordRequest): Observable<IChangeResult> {
    const url = `${environment.account_url}/profile/${this.version}/settings/password`;
    return this.http.put<IChangeResult>(url, values, { observe: 'response' }).pipe(map((response) => response.body));
  }

  /** Отправить профиль на верификацию */
  public sendToVerification(): Observable<IVerificationResponse> {
    const url = `${environment.account_url}/profile/${this.version}/profile/verify`;
    return this.http
      .post<IVerificationResponse>(url, {}, { observe: 'response' })
      .pipe(map((response) => response.body));
  }

  /** Отменить отправку профиля на верификацию */
  public cancelVerification(): Observable<IVerificationResponse> {
    const url = `${environment.account_url}/profile/${this.version}/profile/cancel`;
    return this.http
      .post<IVerificationResponse>(url, {}, { observe: 'response' })
      .pipe(map((response) => response.body));
  }

  // private handleItemResponse(response: HttpResponse<User>): User {
  //   return new User(response.body);
  // }
  //
  // private handleItemsResponse(response: HttpResponse<User[]>): IPagedResults<User[]> {
  //   // const totalRecords = +res.headers.get('X-InlineCount');
  //   return {
  //     results: response.body.map((i) => new User(i)),
  //     page: 1,
  //     pageSize: 10,
  //     total: 10,
  //   };
  // }
}
