<div class="checklist">
  <div class="option" [class.option_checked]="option.checked" *ngFor="let option of options; trackBy: trackByFn">
    <!-- TODO: refactor -->
    <ng-container *ngIf="!option.checked; else checked">
      <div class="option__icon"></div>

      <ng-container *ngIf="option.route">
        <ng-container *ngIf="getOuterRoute(option.route) as outerRoute; else innerRoute">
          <a class="option__link" [href]="outerRoute" target="_blank">{{ option.label | translate }}</a>
        </ng-container>
        <ng-template #innerRoute>
          <a class="option__link" [routerLink]="option.route">{{ option.label | translate }}</a>
        </ng-template>
      </ng-container>

      <span class="option__label" *ngIf="!option.route">{{ option.label | translate }}</span>
    </ng-container>

    <ng-template #checked>
      <svg class="option__icon">
        <use [attr.xlink:href]="svgsetIcon('onboarding-check')"></use>
      </svg>
      <span class="option__label">{{ option.label | translate }}</span>
    </ng-template>
    
  </div>
</div>