import { Injectable } from '@angular/core';
import { Lesson, ContentTypeEnum, LessonSlideContentTypeEnum as LSCTE } from 'lingo2-models';

const exerciseTypes = [
  LSCTE.TaskCheckList,
  LSCTE.TaskFillGaps,
  LSCTE.TaskMatchPairs,
  LSCTE.TaskTable,
  LSCTE.TaskRadioList,
];

/**
 * TODO:
 * Перенести сервис вычисления категории на бэкенд
 * Не дублировать
 */

@Injectable()
export class DetectLessonTypeService {
  private someSectionOfType(types: LSCTE[], lesson: Lesson): boolean {
    return lesson.slides.some(
      (slide) => slide.sections && slide.sections.some((section) => types.includes(section.content.type)),
    );
  }

  private everySectionOfType(types: LSCTE[], lesson: Lesson): boolean {
    return lesson.slides.every(
      (slide) => slide.sections && slide.sections.every((section) => types.includes(section.content.type)),
    );
  }

  private noneSectionOfType(types: LSCTE[], lesson: Lesson): boolean {
    return !this.someSectionOfType(types, lesson);
  }

  private isOnlyOneSection(lesson: Lesson): boolean {
    return lesson.slides.length === 1 && lesson.slides[0].sections.length === 1;
  }

  private checks = [
    // {
    //   id: ContentTypeEnum.news,
    //   check: (lesson: Lesson) =>
    //     lesson.slides.length === 1
    //     && this.noneSectionOfType(exerciseTypes, lesson)
    // },
    // {
    //   id: ContentTypeEnum.reading,
    //   check: (lesson: Lesson) =>
    //     this.someSectionOfType([LSCTE.Theory], lesson) &&
    //     this.noneSectionOfType([...exerciseTypes, LSCTE.Video, LSCTE.Audio], lesson),
    // },
    {
      id: ContentTypeEnum.video,
      check: (lesson: Lesson) => this.everySectionOfType([LSCTE.Video], lesson) && this.isOnlyOneSection(lesson),
    },
    {
      id: ContentTypeEnum.audio,
      check: (lesson: Lesson) => this.everySectionOfType([LSCTE.Audio], lesson) && this.isOnlyOneSection(lesson),
    },
    {
      id: ContentTypeEnum.image,
      check: (lesson: Lesson) => this.everySectionOfType([LSCTE.Image], lesson) && this.isOnlyOneSection(lesson),
    },
    {
      id: ContentTypeEnum.exercise,
      check: (lesson: Lesson) => this.someSectionOfType([...exerciseTypes], lesson),
    },
    {
      id: ContentTypeEnum.theory,
      check: (lesson: Lesson) => this.noneSectionOfType(exerciseTypes, lesson),
    },
    {
      id: ContentTypeEnum.theory_exercise,
      check: (lesson: Lesson) => this.someSectionOfType([...exerciseTypes], lesson),
    },
    {
      id: ContentTypeEnum.audio_lesson,
      check: (lesson: Lesson) =>
        (this.someSectionOfType([...exerciseTypes], lesson) && this.someSectionOfType([LSCTE.Audio], lesson)) ||
        (this.everySectionOfType([LSCTE.Audio], lesson) && !this.isOnlyOneSection(lesson)),
    },
    {
      id: ContentTypeEnum.reading_lesson,
      check: (lesson: Lesson) =>
        this.someSectionOfType([...exerciseTypes], lesson) &&
        this.someSectionOfType([LSCTE.Theory], lesson) &&
        this.noneSectionOfType([LSCTE.Audio, LSCTE.Video], lesson),
    },
    {
      id: ContentTypeEnum.video_lesson,
      check: (lesson: Lesson) =>
        this.someSectionOfType([...exerciseTypes], lesson) && this.someSectionOfType([LSCTE.Video], lesson),
    },
    // {
    //   id: ContentTypeEnum.vocabulary,
    //   check: (lesson: Lesson) =>
    //     this.someSectionOfType([LSCTE.], lesson);
    // },
  ];

  constructor() {}

  public getSuitableContentTypes(lesson: Lesson): ContentTypeEnum[] {
    return this.checks.filter((x) => x.check(lesson)).map((x) => x.id);
  }
}
