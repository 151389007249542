import { createReducer, on, Action, createSelector } from '@ngrx/store';
import {
  IBasicContent,
  IPriceTier,
  Language,
  Subject,
  UserService,
  UserServiceCategory,
  CPriceTier,
  Collection,
  Meeting,
  BillingPlan,
} from 'lingo2-models';
import { values as _values } from 'lodash';
import { AppState } from '..';
import * as ContentAction from '../actions/content.actions';
import * as ProfileAction from '../actions/profile.actions';
import { IMeetingCollection, getElementByIdOrSlug } from '../models';

export interface State {
  billingPlans: BillingPlan[];
  currentItem: IBasicContent;
  languages: Language[];
  meetings: IMeetingCollection;
  priceTiers: CPriceTier[];
  prices: IPriceTier[];
  subjects: Subject[];
  userServices: UserService[];
  collections: Collection[];
  userServiceCategories: UserServiceCategory[];
}

const initialState: State = {
  billingPlans: [],
  currentItem: null,
  languages: [],
  meetings: {},
  priceTiers: [],
  prices: [],
  subjects: [],
  userServices: [],
  collections: [],
  userServiceCategories: [],
};

const contentReducer = createReducer(
  initialState,
  on(ContentAction.setCurrentItem, (store, { item }) => ({
    ...store,
    currentItem: item,
  })),
  on(ContentAction.clearContent, () => ({ ...initialState })),
  on(ContentAction.setLanguages, (store, { languages }) => ({
    ...store,
    languages,
  })),
  on(ContentAction.setPrices, (store, { prices }) => ({
    ...store,
    prices,
  })),
  on(ContentAction.setPriceTiers, (store, { priceTiers }) => ({
    ...store,
    priceTiers,
  })),
  on(ContentAction.setSubjects, (store, { subjects }) => ({
    ...store,
    subjects,
  })),
  on(ContentAction.setBillingPlans, (store, { billingPlans }) => ({
    ...store,
    billingPlans,
  })),
  on(ContentAction.loadUserServiceSuccess, (store, { userService }) => {
    if (!userService) {
      return store;
    }
    const userServices = store.userServices.filter((s) => s.id !== userService.id);
    userServices.push(userService);
    return {
      ...store,
      userServices,
    };
  }),
  on(ContentAction.loadCollectionSuccess, (store, { collection }) => {
    if (!collection) {
      return store;
    }
    const collections = store.collections.filter((s) => s.id !== collection.id);
    collections.push(collection);
    return {
      ...store,
      collections,
    };
  }),
  on(ProfileAction.loadMyMeetingsSuccess, (store, { meetingResponse }) => {
    const meetings: IMeetingCollection = {
      ...store.meetings,
    };
    meetingResponse.results.forEach((m) => {
      meetings[m.id] = m;
    });
    return {
      ...store,
      meetings,
    };
  }),
  on(ContentAction.loadMeetingSuccess, (store, { meeting }) => {
    if (!meeting) {
      return store;
    }
    return {
      ...store,
      meetings: {
        ...store.meetings,
        [meeting.id]: {
          ...meeting,
        },
      },
    };
  }),
  on(ContentAction.setUserServiceCategories, (store, { userServiceCategories }) => ({
    ...store,
    userServiceCategories,
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return contentReducer(state, action);
}

const thisFeatureKey = 'content';
export const contentFeatureKey = thisFeatureKey;

/** Selectors */
export const getCurrentItem = (state: AppState) => state[thisFeatureKey].currentItem;
export const getMeetingsCollection = (state: AppState) => state[thisFeatureKey].meetings;
export const getMeetings = (state: AppState) => _values(getMeetingsCollection(state)).map((m) => new Meeting(m));
export const getLanguages = (state: AppState) => state[thisFeatureKey].languages;
export const getSubjects = (state: AppState) => state[thisFeatureKey].subjects;
export const getPriceTiers = (state: AppState) => state[thisFeatureKey].priceTiers.map((_pt) => new CPriceTier(_pt));
export const getUserServices = (state: AppState) => state[thisFeatureKey].userServices;
export const getCollections = (state: AppState) => state[thisFeatureKey].collections;
export const getUserServiceCategories = (state: AppState) => state[thisFeatureKey].userServiceCategories;

export const getUserService = (appState: AppState, props: { user_service_id: string }) => {
  if (!props || !props.user_service_id) {
    return null;
  }
  const state = appState[thisFeatureKey];
  return state.userServices.find((s) => s.id === props.user_service_id);
};

export const getMeeting = (state: AppState, props: { meeting_id?: string; slug?: string }) => {
  const meeting = getElementByIdOrSlug(state[thisFeatureKey].meetings, props.meeting_id, props.slug);
  return meeting ? new Meeting(meeting) : null;
};

export const getCollection = (appState: AppState, props: { collection_id?: string }) => {
  if (!props || !props.collection_id) {
    return null;
  }
  const state = appState[thisFeatureKey];
  return state.collections.find((s) => s.id === props.collection_id);
};

export const getPriceTier = createSelector(
  (state: AppState) => state[thisFeatureKey].priceTiers,
  (priceTiers: CPriceTier[], props: { id: number }): CPriceTier => {
    if (priceTiers.length === 0) {
      return null;
    }
    let priceTier = priceTiers.find((pt) => pt.id === props.id);
    if (!priceTier) {
      priceTier = priceTiers[0];
    }
    return new CPriceTier(priceTier);
  },
);

export const getPrice = createSelector(
  (state: AppState) => state[thisFeatureKey].prices,
  (prices: IPriceTier[], props: { id: number }): IPriceTier => {
    if (prices.length === 0) {
      return null;
    }
    let price = prices.find((pt) => pt.id === props.id);
    if (!price) {
      price = prices[0];
    }
    return price;
  },
);

export const getPreparedBusinessPlans = createSelector(
  (state: AppState) => state[thisFeatureKey].billingPlans,
  getPriceTiers,
  (billingPlans, priceTiers): BillingPlan[] => {
    if (billingPlans.length === 0 || priceTiers.length === 0) {
      return [];
    }
    return billingPlans;
  },
);
