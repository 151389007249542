import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { WebsocketService } from '@core/websocket';
import { ChangableComponent } from '@models/changable.component';
import { TranslateModule } from '@ngx-translate/core';
import { subHours } from 'date-fns';
import { IEntityUpdate, User, UserVacation } from 'lingo2-models';
import { FormatPipeModule } from 'lingo2-ngx-date-fns';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiButtonModule } from 'onclass-ui';
import { of, Subject } from 'rxjs';
import { catchError, filter, mergeAll, switchMap, takeUntil, tap, throttleTime } from 'rxjs/operators';
import { ContextService, PlatformService, ScheduleService } from '../../services';

@Component({
  selector: 'app-vacation-informer',
  standalone: true,
  imports: [CommonModule, OnUiButtonModule, TranslateModule, FormatPipeModule],
  templateUrl: './vacation-informer.component.html',
  styleUrls: ['./vacation-informer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VacationInformerComponent extends ChangableComponent implements OnInit, OnDestroy {
  public vacation: UserVacation;
  public shaking: boolean;
  protected _me: User;
  protected reloadVacation$ = this.register(new Subject<void>());
  protected watching_user_id: string;

  public constructor(
    public deviceService: DeviceDetectorService,
    private scheduleService: ScheduleService,
    private ws: WebsocketService,
    private contextService: ContextService,
    private router: Router,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  public ngOnInit(): void {
    this.onBrowserOnly(() => {
      of(...[this.watchReloadVacation$, this.watchMeUpdated$, this.watchMyScheduleUpdated$, this.watchMe$])
        .pipe(mergeAll(), takeUntil(this.destroyed$))
        .subscribe();
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.stopWatchingSchedule(this.watching_user_id);
  }

  public get isActive(): boolean {
    return (
      this.vacation?.is_vacation &&
      !!this.vacation?.vacation_begin_at &&
      !!this.vacation?.vacation_end_at &&
      (subHours(this.vacation?.vacation_begin_at, 48).getTime() > Date.now() || // за 48 часов до отпуска
        this.vacation?.vacation_end_at.getTime() > Date.now()) // до окончания отпуска
    );
  }

  private addClassToContent() {
    const elem = document.querySelector('.app-body');
    if (!elem) {
      return;
    }
    if (this.isActive) {
      elem.classList.add('vacation-informer');
    } else {
      elem.classList.remove('vacation-informer');
    }
  }

  public goSchedule() {
    if (this.router.url === '/schedule/vacation') {
      this.shaking = true;
      this.setTimeout(() => {
        this.shaking = false;
        this.detectChanges();
      }, 1000);
    } else {
      this.router.navigate(['/schedule', 'vacation']).catch();
    }
  }

  protected markVacationForReload(): void {
    this.reloadVacation$.next(null);
    /** @see watchReloadVacation$ */
  }

  protected get watchReloadVacation$() {
    return this.reloadVacation$.pipe(
      throttleTime(1000, undefined, { leading: true, trailing: true }),
      switchMap(() => this.scheduleService.getVacation()),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 404) {
          return of(new UserVacation({}));
        }
        throw err;
      }),
      tap((vacation: UserVacation) => {
        this.vacation = vacation;
        this.addClassToContent();
        this.detectChanges();
      }),
    );
  }

  protected get watchMeUpdated$() {
    return this.ws.onAccountUpdate.pipe(
      filter((update) => update.account_id === this._me?.id),
      tap(() => this.markVacationForReload()),
    );
  }

  protected get watchMyScheduleUpdated$() {
    return this.ws.on<IEntityUpdate>('update').pipe(
      filter((update) => update.entity === 'schedule' && this._me?.id === update.id),
      tap(() => this.markVacationForReload()),
    );
  }

  protected get watchMe$() {
    return this.contextService.me$.pipe(
      tap((me) => {
        this._me = me;
        if (me?.id) {
          this.markVacationForReload();
          this.startWatchingSchedule(me?.id);
        }
      }),
    );
  }

  protected startWatchingSchedule(user_id) {
    if (this.watching_user_id !== user_id) {
      this.stopWatchingSchedule(this.watching_user_id);
      this.ws.startWatching('schedule', user_id);
      this.watching_user_id = user_id;
    }
  }

  protected stopWatchingSchedule(user_id) {
    this.ws.stopWatching('schedule', user_id);
  }
}
