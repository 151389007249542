<div class="status" [class.status_expanded]="isExpanded$ | async">
  <div class="status__meta">

    <span>{{ (isVerified ? 'onboarding.statuses.rank' : 'onboarding.statuses.status') | translate }}:</span>

    <ng-container [ngSwitch]="onboardingStatus">

      <ng-container *ngSwitchCase="states.guest">
        <b>{{ 'onboarding.statuses.auth-required' | translate }}</b>
      </ng-container>

      <ng-container *ngSwitchCase="states.guest_student">
        <b>{{ 'onboarding.statuses.auth-required' | translate }}</b>
      </ng-container>

      <ng-container *ngSwitchCase="states.student">
        <b>{{ 'onboarding.statuses.student' | translate }}</b>
      </ng-container>

      <ng-container *ngSwitchCase="states.guest_teacher">
        <b>{{ 'onboarding.statuses.auth-required' | translate }}</b>
      </ng-container>

      <ng-container *ngSwitchCase="states.not_verified">
        <b>{{ 'onboarding.statuses.not-verified' | translate }}</b>
      </ng-container>

      <ng-container *ngSwitchCase="states.on_moderation">
        <b>{{ 'onboarding.statuses.on-moderation' | translate }}</b>
        <span class="subtitle" *ngIf="moderationTime">{{moderationTime | dfnsFormatDistanceToNow }}</span>
      </ng-container>

      <ng-container *ngSwitchCase="states.limited_tutor">
        <b>{{ 'onboarding.statuses.tutor' | translate }}</b>
        <span class="subtitle subtitle_warning">{{ 'onboarding.statuses.limited-access' | translate }}</span>
      </ng-container>

      <ng-container *ngSwitchCase="states.tutor">
        <b>{{ 'onboarding.statuses.tutor' | translate }}</b>
        <span class="subtitle subtitle_success">{{ 'onboarding.statuses.upgrade' | translate }}</span>
      </ng-container>

      <ng-container *ngSwitchCase="states.teacher">
        <b>{{ 'onboarding.statuses.teacher' | translate }}</b>
      </ng-container>

      <ng-container *ngSwitchCase="states.limited_teacher">
        <b>{{ 'onboarding.statuses.teacher' | translate }}</b>
        <span class="subtitle subtitle_warning">{{ 'onboarding.statuses.limited-access' | translate }}</span>
      </ng-container>

    </ng-container>

  </div>

  <div class="status__actions" *ngIf="showIcon">
    <svg class="status__icon" (click)="toggleExpand()">
      <use [attr.xlink:href]="svgsetIcon('onboarding-arrow')"></use>
    </svg>
  </div>

</div>