import { createSelector } from '@ngrx/store';
import { getClassroomsState, ClassroomsState } from '..';
import { MeetingsState } from './meetings.reducer';

export const getClassroomMeetingsState = createSelector(getClassroomsState, (state: ClassroomsState) => state.meetings);

export const getClassroomMeetings = createSelector(getClassroomMeetingsState, (state: MeetingsState) => state.items);

export const getClassroomMeetingsDetails = createSelector(
  getClassroomMeetingsState,
  (state: MeetingsState) => state.details,
);

export const getClassroomMeetingsFilter = createSelector(
  getClassroomMeetingsState,
  (state: MeetingsState) => state.filter,
);

export const getClassroomMeetingsPagination = createSelector(
  getClassroomMeetingsState,
  (state: MeetingsState) => state.pagination,
);

export const getClassroomMeetingsLoading = createSelector(
  getClassroomMeetingsState,
  (state: MeetingsState) => state.loading,
);

export const getClassroomMeetingsLoaded = createSelector(
  getClassroomMeetingsState,
  (state: MeetingsState) => state.loaded,
);

export const getClassroomMeetingIsNotEmpty = createSelector(
  getClassroomMeetingsState,
  (state: MeetingsState) => state.items && !!state.items.length,
);
