import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { EventActionEnum, EventCategoryEnum } from '@app/core/services/analytics';
import {
  AnalyticsService,
  AuthModalModeType,
  AuthService,
  ContextService,
  FeaturesService,
  IconsService,
  PlatformService,
  ProfileService,
} from '@core/services';
import { WebsocketService } from '@core/websocket';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { setProfile } from '@store/actions/profile.actions';
import { User, UserProfileVerificationEnum as VE, UserStatusEnum } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';

export type View = 'default' | 'compact' | 'mini' | 'mini-header';

enum StatusActions {
  send = 'send',
  cancel = 'cancel',
  profile = 'profile',
  edit = 'edit',
  check = 'check',
}
@Component({
  selector: 'app-verification-widget',
  templateUrl: './verification-widget.component.html',
  styleUrls: ['./verification-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerificationWidgetComponent extends ChangableComponent implements OnInit, OnDestroy {
  @Input() public user: User;
  @Input() public view: View = 'default';
  @Output() check = new EventEmitter<boolean>();
  public svgsetIcon = IconsService.svgsetIconUrl;
  public verification_status: VE;
  public verifications = VE;
  public isLoading = false;
  private isChecking = false;

  private actions = {
    [VE.incomplete]: StatusActions.profile,
    [VE.not_verified]: StatusActions.send,
    [VE.sent]: StatusActions.cancel,
    [VE.approved]: StatusActions.edit,
    [VE.declined]: StatusActions.edit,
    [VE.changed]: StatusActions.send,
    [VE.resent]: StatusActions.cancel,
    [VE.redeclined]: StatusActions.edit,
  };

  // отладка
  private isDisabled: boolean;

  constructor(
    public deviceService: DeviceDetectorService,
    private authService: AuthService,
    private profileService: ProfileService,
    private contextService: ContextService,
    private websocket: WebsocketService,
    private router: Router,
    private analytics: AnalyticsService,
    private location: Location,
    private store: Store,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngOnInit() {
    this.contextService.profile$
      .pipe(
        filter((x) => !!x),
        map((x) => x.verification_status),
        distinctUntilChanged(),
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe((verification_status) => {
        this.verification_status = verification_status || VE.incomplete;
        this.isChecking = false;
        this.check.emit(this.isChecking);
        this.markForCheck();
      });
  }

  public get action() {
    return this.actions[this.verification_status];
  }

  public get isCompact() {
    return this.view === 'compact';
  }

  public get isMini() {
    return this.view === 'mini';
  }

  public get isMiniHeader() {
    return this.view === 'mini-header';
  }

  public toggleChecking(): void {
    this.isChecking = !this.isChecking;
    this.check.emit(this.isChecking);
  }

  public sendToVerification(): void {
    const oldStatus = this.verification_status;

    this.isLoading = true;
    this.profileService
      .sendToVerification()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        if (res.status === 'ok') {
          if (oldStatus === VE.not_verified) {
            // отправился первый раз
            this.analytics.event(
              EventActionEnum.verification_sent,
              EventCategoryEnum.engagement,
              'app-verification-widget',
            );
            this.location.go(this.location.path() + '#verification-sent');
          } else {
            // отправился повторно
            this.analytics.event(
              EventActionEnum.verification_resent,
              EventCategoryEnum.engagement,
              'app-verification-widget',
            );
            this.location.go(this.location.path() + '#verification-resent');
          }

          this.updateProfile();
        }
        this.isLoading = false;
        this.cdr.markForCheck();
      });
  }

  public cancelVerification(): void {
    this.isLoading = true;
    this.profileService
      .cancelVerification()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        if (res.status === 'ok') {
          this.analytics.event(
            EventActionEnum.verification_canceled,
            EventCategoryEnum.engagement,
            'app-verification-widget',
          );
          this.location.go(this.location.path() + '#verification-canceled');

          this.updateProfile();
        }
        this.isLoading = false;
      });
  }

  private updateProfile(): void {
    this.profileService
      .getProfile(this.user.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((profile) => {
        this.contextService.updateProfile(profile);
        this.store.dispatch(setProfile({ profile }));
        this.markForCheck();
      });
  }

  public authorize(mode: AuthModalModeType) {
    this.authService.setAuthModalMode(mode);
    this.authService.showAuthModal();
    // routerLink="/me/edit//"
  }

  public get isGuest(): boolean {
    return this.user?.status === UserStatusEnum.guest;
  }

  public get isVisible() {
    return !FeaturesService.isLimitedVersion;
  }

  public authGuardedNavigate(url: string) {
    this.authService.showAuthModal(() => {
      this.navigateByUrl(url);
    });
  }

  public navigateByUrl(url: string) {
    if (url) {
      this.router.navigateByUrl(url);
    }
  }
}
