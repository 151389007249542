import { createAction, props } from '@ngrx/store';
import { User, UserProfile } from 'lingo2-models';

export const loadUserById = createAction('[Users] Load user by id', props<{ user_id: string }>());
export const loadUsersById = createAction('[Users] Load users by id', props<{ users_id: string[] }>());
export const loadCurrentUserBySlug = createAction('[Users] Load current user by slug', props<{ slug: string }>());

export const setUser = createAction('[Users] Add user', props<{ user: User; statsUpdate?: boolean }>());
export const setMeAsCurrentUser = createAction('[Users] Set me as current user');
export const setCurrentUser = createAction('[Users] Set current user', props<{ user: Partial<User> }>());
export const setCurrentProfile = createAction('[Users] Set current profile', props<{ profile: UserProfile }>());

export const subscribeToUser = createAction('[Users] Subscribe', props<{ user_id: string }>());
export const unsubscribeFromUser = createAction('[Users] Unsubscribe', props<{ user_id: string }>());
export const anyUserUpdateActionComplete = createAction(
  '[Users] Any user action complete',
  props<{ user_id: string }>(),
);
export const updateUserStats = createAction('[Users] Update user stats', props<{ user_id: string; force?: boolean }>());
export const updateContentStats = createAction('[Users] Update content stats', props<{ user_id: string }>());
export const updateContentStatsComplete = createAction(
  '[Users] Update content stats complete',
  props<{ user_id: string; stats: any }>(),
);
export const updateMeetingStats = createAction('[Users] Update meeting stats', props<{ user_id: string }>());
export const updateMeetingStatsComplete = createAction(
  '[Users] Update meeting stats complete',
  props<{ user_id: string; stats: any }>(),
);
export const updateGameStats = createAction('[Users] Update game stats', props<{ user_id: string }>());
export const updateGameStatsComplete = createAction(
  '[Users] Update game stats complete',
  props<{ user_id: string; stats: any }>(),
);

export const loadUsers = createAction('[Users] Load users', props<{ user_ids: string[] }>());
export const loadUsersSuccess = createAction('[Users] Load users success', props<{ users: User[] }>());

export const loadTeachers = createAction('[Users] Load all teachers');
export const loadTeachersSuccess = createAction('[Users] Load all teachers success', props<{ teachers: User[] }>());
export const loadMySubscriptions = createAction('[Users] Load my subscriptions');
export const loadMySubscriptionsSuccess = createAction(
  '[Users] Load my subscriptions success',
  props<{ subscriptions: User[] }>(),
);
export const clearUser = createAction('[Users] clear');
