import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { UserProfileVerificationEnum as VE } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';

enum DescriptionsEnum {
  incomplete = 'incomplete',
  about = 'about',
  changed = 'changed',
  resent = 'resent',
  declined = 'declined',
}

@Component({
  selector: 'app-verification-widget-status',
  templateUrl: './verification-widget-status.component.html',
  styleUrls: ['./verification-widget-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerificationWidgetStatusComponent implements OnInit {
  @Input() public verification_status: VE;
  @Input() public displayMode: string;
  public statuses = VE;
  public statusDescriptions = {
    [VE.incomplete]: DescriptionsEnum.incomplete,
    [VE.not_verified]: DescriptionsEnum.about,
    [VE.sent]: DescriptionsEnum.about,
    [VE.approved]: DescriptionsEnum.about,
    [VE.declined]: DescriptionsEnum.declined,
    [VE.changed]: DescriptionsEnum.changed,
    [VE.resent]: DescriptionsEnum.resent,
    [VE.redeclined]: DescriptionsEnum.declined,
  };
  public statusTitles = {
    [VE.incomplete]: 'verification-widget.status.incomplete',
    [VE.not_verified]: 'verification-widget.status.not_verified',
    [VE.sent]: 'verification-widget.status.sent',
    [VE.approved]: 'verification-widget.status.approved',
    [VE.declined]: 'verification-widget.status.declined',
    [VE.changed]: 'verification-widget.status.changed',
    [VE.resent]: 'verification-widget.status.resent',
    [VE.redeclined]: 'verification-widget.status.redeclined',
  };

  constructor(public deviceService: DeviceDetectorService) {}

  ngOnInit() {}
}
