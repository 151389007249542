<div [formGroup]="form" class="auth-input-field">
  <div class="input-field">
    <label class="label"
           [ngClass]="{'label--focus': focusField,
                       'label--warn': warnField && showValidation,
                       'label--valid': !warnField && showValidation}">
      <div *ngIf="displayLeftIco" class="input-ico-wrap">
        <svg class="input-ico" viewBox="0 0 24 24">
          <use [attr.xlink:href]="svgSetIcon('auth-field-' + type)"></use>
        </svg>

      </div>
      <input *ngIf="!isPhone && !isCountry"
             [type]="showPassword ? 'text' : type"
             [attr.data-test]="dataTestName"
             [formControlName]="controlName" (change)="onChange($event)"
             (focusin)="focusField = true"
             (focusout)="focusField = false"
             [placeholder]="placeholderValue | translate"
             (keydown.enter)="onEnter.emit(true)"
             class="input normal">
      <ngx-intl-phone-input
        *ngIf="isPhone && !isCountry"
        [cssClass]="'custom'"
        [searchCountryFlag]="true"
        [enablePlaceholder]="true"
        [selectFirstCountry]="false"
        [separateDialCode]="false"
        [selectedCountryISO]="countryCode"
        [phoneValidation]="true" [useMask]="true" [showMaskTyped]="true"
        [inputId]="'country-code-wrap'"
        [formControlName]="controlName"
      ></ngx-intl-phone-input>
      <div *ngIf="isPassword && !isCountry" class="input-ico-wrap" (click)="toggleShowPassword()">
        <svg width="16" height="16" class="input-ico input-ico--btn" [ngClass]="{'input-ico--show': showPassword}">
          <use [attr.xlink:href]="svgSetIcon(showPassword ? 'auth-eye-opened' : 'auth-eye-closed')"></use>
        </svg>
      </div>
      <on-ui-autocomplete *ngIf="isCountry" [formControlName]="controlName" [class]="'transparent'"
                    [placeholder]="'profile-form.billing.residence_country' | translate"
                    [options]="options" (changed)="onChange($event)">
      </on-ui-autocomplete>
    </label>
    <ng-template [ngIf]="warnField && showValidation && message">
      <div class="warn-message normal">{{ message | translate }}</div>
    </ng-template>
  </div>
</div>
