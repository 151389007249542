import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { ModalModule } from '@app/core/components/modal/modal.module';
import { PipesModule } from '@app/core/pipes/pipes.module';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ClickOutsideModule } from 'ng-click-outside';
import { SlideRichTextComponent } from './slide-rich-text.component';

@NgModule({
  declarations: [SlideRichTextComponent],
  imports: [CommonModule, ReactiveFormsModule, EditorModule, ModalModule, ClickOutsideModule, PipesModule],
  exports: [SlideRichTextComponent],
})
export class SlideRichTextModule {}
