import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CLibraryFilterData } from '@app/store/models';
import { environment } from '@env/environment';
import { DestroyableComponent } from '@models/destroyable.component';
import { TranslateService } from '@ngx-translate/core';
import {
  IFindContentFilter,
  LessonContentType,
  ContentTypeEnum,
  IPagedResults,
  Content,
  Lesson,
  Video as VideoContent,
  Audio as AudioContent,
  Image as ImageContent,
  Text as TextContent,
  // Document as DocumentContent,
  Vocabulary,
  ILikeDislikeResponse,
  otherSubjectId,
  MutableContentBase,
  IContentPane,
  IPagination,
  IContentStats,
  IContentFilterBlock,
  IUserContentStats,
  User,
  getContentInstanceByItem,
  ContentDetailsType,
  IContentBlock,
  IStatusResponse,
  IResponse,
} from 'lingo2-models';
import { EntityTypeEnum } from 'lingo2-models';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { createPagedResultsFromResponse } from '../../helpers/request';
import { ContextService } from '../context.service';

export type ContentBlockType = IContentBlock<Content, IFindContentFilter>;

export interface IContentStatsResponse extends IContentStats {
  /** @see PublishStatusEnum */
  [key: string]: number;
  template: number;
  total: number;
}

interface IChangedStats {
  id: string;
  stats: Partial<IContentStats>;
}

@Injectable({
  providedIn: 'root',
})
export class ContentService extends DestroyableComponent {
  public static contentTypeTitle: any = {
    [ContentTypeEnum.audio_lesson]: 'content-type-title.audio-lesson',
    [ContentTypeEnum.video_lesson]: 'content-type-title.video-lesson',
    [ContentTypeEnum.reading_lesson]: 'content-type-title.reading-lesson',
    [ContentTypeEnum.exercise]: 'content-type-title.exercise',
    [ContentTypeEnum.theory]: 'content-type-title.theory',
    [ContentTypeEnum.video]: 'content-type-title.video',
    [ContentTypeEnum.audio]: 'content-type-title.audio',
    [ContentTypeEnum.image]: 'content-type-title.image',
    [ContentTypeEnum.article]: 'content-type-title.article',
    [ContentTypeEnum.news]: 'content-type-title.news',
    [ContentTypeEnum.vocabulary]: 'content-type-title.vocabulary',
    [ContentTypeEnum.theory_exercise]: 'content-type-title.theory-exercise',
    [ContentTypeEnum.reading]: 'content-type-title.reading',
  };

  /** @deprecated use lingo2-models/Router.getNameByContentType */
  public static contentTypesTitle: any = {
    [ContentTypeEnum.audio_lesson]: 'content-types-title.audio-lesson',
    [ContentTypeEnum.video_lesson]: 'content-types-title.video-lesson',
    [ContentTypeEnum.reading_lesson]: 'content-types-title.reading-lesson',
    [ContentTypeEnum.exercise]: 'content-types-title.exercise',
    [ContentTypeEnum.theory]: 'content-types-title.theory',
    [ContentTypeEnum.video]: 'content-types-title.video',
    [ContentTypeEnum.audio]: 'content-types-title.audio',
    [ContentTypeEnum.image]: 'content-types-title.image',
    [ContentTypeEnum.article]: 'content-types-title.article',
    [ContentTypeEnum.news]: 'content-types-title.news',
    [ContentTypeEnum.vocabulary]: 'content-types-title.vocabulary',
    [ContentTypeEnum.theory_exercise]: 'content-types-title.theory-exercise',
    [ContentTypeEnum.reading]: 'content-type-title.reading',
  };

  private me: User;
  public change = this.register(new BehaviorSubject<IChangedStats>(null));
  public change$ = this.change.asObservable();

  public constructor(
    private http: HttpClient,
    private contextService: ContextService,
    private translate: TranslateService,
  ) {
    super();
    this.contextService.me$.pipe(takeUntil(this.destroyed$)).subscribe((me) => (this.me = me));
  }

  public static cloneContent(
    item: MutableContentBase,
  ): Lesson | AudioContent | VideoContent | ImageContent | TextContent | Vocabulary {
    if (LessonContentType.includes(item.type)) {
      return new Lesson({ ...(item as any) });
    }

    switch (item.type) {
      case ContentTypeEnum.article:
      case ContentTypeEnum.news:
        return new TextContent({ ...(item as any) });

      case ContentTypeEnum.video:
        return new VideoContent({ ...(item as any) });

      case ContentTypeEnum.audio:
        return new AudioContent({ ...(item as any) });

      case ContentTypeEnum.image:
        return new ImageContent({ ...(item as any) });

      // case ContentTypeEnum.document:
      //   return new DocumentContent({ ...item as any });

      case ContentTypeEnum.vocabulary:
        return new Vocabulary({ ...(item as any) });
    }
  }

  public contentFullTitle(item: Content | Partial<Content>): string {
    if (!item) {
      return '';
    }

    let subject = '';
    if ('subject' in item) {
      if (+item.subject.id === otherSubjectId) {
        subject = item.subject_other_name || '';
      } else {
        subject = item.subject.title || '';
      }
    }
    let _level: string;
    if ('level_other_name' in item && !!(item as any).level_other_name) {
      _level = (item as any).level_other_name;
    } else {
      _level =
        'level' in item && (item as any).level
          ? (item as any).level.title
          : this.translate.instant('subject-levels.any-level');
    }
    const titleParts = [];
    if (subject) {
      titleParts.push(`${subject}, `);
    }
    titleParts.push(item.title);
    if (item.sub_title) {
      titleParts.push(`, ${item.sub_title}`);
    }
    if (_level) {
      titleParts.push(` (${_level})`);
    }

    return titleParts.join('');
  }

  /**
   * @deprecated use getFeaturings
   * Получение блоков контента URL
   */
  public getContentLibraryPanes(filter: Partial<CLibraryFilterData>): Observable<IContentPane[]> {
    const url = `${environment.content_url}/panes`;
    const params = new HttpParams().set('filter', JSON.stringify(filter));
    return this.http.get<IContentPane[]>(url, { params, observe: 'body' });
  }

  /**
   * Получение блоков контента URL
   */
  public getFeaturings(filter: Partial<CLibraryFilterData>): Observable<IResponse<IContentPane[]>> {
    const url = `${environment.content_url}/featuring`;
    const params = new HttpParams().set('filter', JSON.stringify(filter));
    return this.http.get<IResponse<IContentPane[]>>(url, { params, observe: 'body' });
  }

  /**
   * Поиск записи по автору и URL
   *
   * @deprecated Возможно, такого метода уже нет на бэке.
   */
  public getContentByAuthorAndSlug(
    user_slug: string,
    content_slug: string,
    full = false,
    details?: ContentDetailsType[],
  ) {
    const _content_slug = content_slug.split('-').reverse()[0];
    const url = `${environment.content_url}/u/${user_slug}/${_content_slug}`;
    let params = new HttpParams().set('full', full ? '1' : '0');
    if (details && details.length) {
      params = params.set('details', JSON.stringify(details));
    }
    return this.http.get<Content>(url, { params, observe: 'response' }).pipe(
      map(this.handleContentResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Поиск записи по SLUG
   */
  public getContentBySlug(content_slug: string, full = false, details?: ContentDetailsType[]) {
    let _content_slug = content_slug.split('-').reverse()[0];
    let _old_content_slug = content_slug.split('-')[0]; // древний формат URL контента - slug был в перед title
    if (_content_slug === '' && _old_content_slug !== '') {
      _content_slug = _old_content_slug;
      _old_content_slug = '';
    }
    const url = `${environment.content_url}/slug/${_content_slug}`;
    let params = new HttpParams().set('full', full ? '1' : '0').set('x-slug', _old_content_slug);
    if (details && details.length) {
      params = params.set('details', JSON.stringify(details));
    }
    return this.http.get(url, { params, observe: 'response' }).pipe(
      map(this.handleContentResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Поиск записи по ID
   */
  public getById(id: string, full = false, details?: ContentDetailsType[]) {
    const url = `${environment.content_url}/content/${id}`;
    let params = new HttpParams().set('full', full ? '1' : '0');
    if (details && details.length) {
      params = params.set('details', JSON.stringify(details));
    }
    return this.http.get(url, { params, observe: 'response' }).pipe(
      map(this.handleContentResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Поиск записи по ID для редактирования
   */
  public getForEdit(id: string): Observable<Content> {
    const url = `${environment.content_url}/content/${id}/edit`;
    return this.http.get<Content>(url, { observe: 'response' }).pipe(
      map(this.handleContentResponse),
      // catchError(this.handleError),
    );
  }

  /** Блоки фильтров контента (subjects/categories) */
  public getContentFilterBlocks(
    section: 'subjects' | 'categories',
    filter: Partial<IFindContentFilter>,
  ): Observable<IContentFilterBlock[]> {
    const url = `${environment.content_url}/content_filters/${section}`;
    const params = new HttpParams().set('user_id', this.me?.id).set('filter', JSON.stringify(filter));
    return this.http.get<IContentFilterBlock[]>(url, { params, observe: 'body' });
  }

  /**
   * Поиск записей по фильтру
   */
  public getContent(
    filter: Partial<IFindContentFilter>,
    pagination: IPagination,
    full = false,
    details?: ContentDetailsType[],
  ): Observable<IPagedResults<Content[]>> {
    const url = `${environment.content_url}/content/`;
    let params = new HttpParams()
      .set('full', full ? '1' : '0')
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter', JSON.stringify(filter));
    if (details && details.length) {
      params = params.set('details', JSON.stringify(details));
    }
    return this.http.get<Content[]>(url, { params, observe: 'response' }).pipe(
      map(this.handleContentsResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Статистика по контенту
   */
  public getStats(user_id?: string): Observable<IContentStats> {
    const url = user_id ? `${environment.content_url}/stats/${user_id}` : `${environment.content_url}/stats`;
    return this.http.get<IContentStats>(url, { observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  /**
   * Статистика по контенту пользователя
   */
  public getUserStats(user_id: string): Observable<IUserContentStats> {
    const url = `${environment.content_url}/stats/${user_id}`;
    return this.http.get<IUserContentStats>(url, { observe: 'body' });
  }

  /**
   * Статистика по играм
   */
  public getGameStats(user_id?: string): Observable<IContentStatsResponse> {
    const url = `${environment.content_url}/game/stats/${user_id}`;
    return this.http.get<IContentStatsResponse>(url, { observe: 'response' }).pipe(
      map((response) => response.body as any),
      // catchError(this.handleError),
    );
  }

  /**
   * Создать запись
   */
  public createContent(values: Partial<Content>): Observable<Content> {
    const url = `${environment.content_url}/content`;
    return this.http.post<Content>(url, values, { observe: 'response' }).pipe(
      map(this.handleContentResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Обновить запись
   */
  public updateContent(id: string, values: Partial<Content>): Observable<Content> {
    const url = `${environment.content_url}/content/${id}`;
    return this.http.put<Content>(url, values, { observe: 'response' }).pipe(
      map(this.handleContentResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Лучшие записи
   *
   * @deprecated Перенести в фильтр, использовать обычный find
   */
  public getTop(
    filter: Partial<IFindContentFilter>,
    pagination: IPagination,
    details?: ContentDetailsType[],
  ): Observable<IPagedResults<Content[]>> {
    const url = `${environment.content_url}/top`;
    let params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter', JSON.stringify(filter));
    if (details && details.length) {
      params = params.set('details', JSON.stringify(details));
    }
    return this.http.get<Content[]>(url, { params, observe: 'response' }).pipe(
      map(this.handleContentsResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Рекомендуемые записи
   *
   * @deprecated Перенести в фильтр, использовать обычный find
   */
  public getRecommended(
    filter: Partial<IFindContentFilter>,
    pagination: IPagination,
    details?: ContentDetailsType[],
  ): Observable<IPagedResults<Content[]>> {
    const url = `${environment.content_url}/recommended`;
    let params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter', JSON.stringify(filter));
    if (details && details.length) {
      params = params.set('details', JSON.stringify(details));
    }
    return this.http.get<Content[]>(url, { params, observe: 'response' }).pipe(
      map(this.handleContentsResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Лента записей
   */
  public getFeed(
    filter: Partial<IFindContentFilter>,
    pagination: IPagination,
    details?: ContentDetailsType[],
  ): Observable<IPagedResults<Content[]>> {
    const url = `${environment.content_url}/feed/`;
    let params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter', JSON.stringify(filter));
    if (details && details.length) {
      params = params.set('details', JSON.stringify(details));
    }
    return this.http.get<Content[]>(url, { params, observe: 'response' }).pipe(
      map(this.handleContentsResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Записи, похожие на указанную
   *
   * @deprecated Перенести в фильтр, использовать обычный find
   */
  public getRelated(
    id: string,
    filter: Partial<IFindContentFilter>,
    pagination: IPagination,
    details?: ContentDetailsType[],
  ): Observable<IPagedResults<Content[]>> {
    const url = `${environment.content_url}/content/${id}/related`;
    let params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter', JSON.stringify(filter));
    if (details && details.length) {
      params = params.set('details', JSON.stringify(details));
    }
    return this.http.get<Content[]>(url, { params, observe: 'response' }).pipe(
      map(this.handleContentsResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Записи, добавленные в избранное
   *
   * @deprecated Перенести в фильтр, использовать обычный find
   */
  public getBookmarked(
    filter: Partial<IFindContentFilter>,
    pagination: IPagination,
    details?: ContentDetailsType[],
  ): Observable<IPagedResults<Content[]>> {
    const url = `${environment.content_url}/bookmarks/`;
    let params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter', JSON.stringify(filter));
    if (details && details.length) {
      params = params.set('details', JSON.stringify(details));
    }
    return this.http.get<Content[]>(url, { params, observe: 'response' }).pipe(
      map(this.handleContentsResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Добавить просмотр
   */
  public addView(id: string) {
    const url = `${environment.content_url}/content/${id}/view`;
    return this.http.post<{ view_count: number }>(url, {}, { observe: 'response' }).pipe(
      map((viewResponse) => viewResponse.body),
      // catchError(this.handleError),
    );
  }

  /**
   * Опубликовать (открыть)
   */
  public publish(id: string): Observable<Content> {
    const url = `${environment.content_url}/content/${id}/publish`;
    return this.http.post<Content>(url, {}, { observe: 'response' }).pipe(
      map(this.handleContentResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Снять с публикации (закрыть)
   */
  public unPublish(id: string): Observable<Content> {
    const url = `${environment.content_url}/content/${id}/unpublish`;
    return this.http.post<Content>(url, {}, { observe: 'response' }).pipe(
      map(this.handleContentResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Отправить запись в архив
   */
  public archive(id: string): Observable<Content> {
    const url = `${environment.content_url}/content/${id}`;
    return this.http.delete<Content>(url, { observe: 'response' }).pipe(
      map(this.handleContentResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Восстановить архивную запись
   */
  public restore(id: string): Observable<Content> {
    const url = `${environment.content_url}/content/${id}/restore`;
    return this.http.put<Content>(url, {}, { observe: 'response' }).pipe(
      map(this.handleContentResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Окончательно удалить запись
   */
  public remove(id: string): Observable<Content> {
    const url = `${environment.content_url}/content/${id}/confirm`;
    return this.http.delete<Content>(url, { observe: 'response' }).pipe(
      map(this.handleContentResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Сгенерировать уроки по шаблону
   */
  public generate(id: string): Observable<Content> {
    const url = `${environment.content_url}/content/${id}/generate`;
    return this.http.post<Content>(url, {}, { observe: 'response' }).pipe(
      map(this.handleContentResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Опубликовать уроки по шаблону
   */
  public massPublish(id: string, status: boolean): Observable<Content> {
    const url = `${environment.content_url}/content/${id}/mass-publish`;
    return this.http.post<Content>(url, { status }, { observe: 'response' }).pipe(
      map(this.handleContentResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Удалить уроки по шаблону
   */
  public massDelete(id: string): Observable<Content> {
    const url = `${environment.content_url}/content/${id}/mass-delete`;
    return this.http.delete<Content>(url, { observe: 'response' }).pipe(
      map(this.handleContentResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Поставить лайк
   */
  public like(id: string): Observable<ILikeDislikeResponse> {
    const url = `${environment.content_url}/content/${id}/like`;
    return this.http.post<ILikeDislikeResponse>(url, {}, { observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  /**
   * Поставить дизлайк
   */
  public dislike(id: string): Observable<ILikeDislikeResponse> {
    const url = `${environment.content_url}/content/${id}/dislike`;
    return this.http.post<ILikeDislikeResponse>(url, {}, { observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  /**
   * Убрать лайк/дизлайк
   */
  public unLike(id: string): Observable<ILikeDislikeResponse> {
    const url = `${environment.content_url}/content/${id}/like`;
    return this.http.delete<ILikeDislikeResponse>(url, { observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  /**
   * Свичнуть закладку для дисциплины
   */
  public setBookmark(id: string, status: boolean, entity_type: EntityTypeEnum): Observable<boolean> {
    const type = EntityTypeEnum[entity_type];
    const url = `${environment.content_url}/${type}/${id}/bookmark`;
    if (status) {
      return this.http.post(url, {}, { observe: 'response' }).pipe(
        map((response) => (response.body as any).result as boolean),
        // catchError(this.handleError),
      );
    } else {
      return this.http.delete(url, { observe: 'response' }).pipe(
        map((response) => (response.body as any).result as boolean),
        // catchError(this.handleError),
      );
    }
  }

  /**
   * Добавить в закладки
   */
  public addBookmark(id: string): Observable<Content> {
    const url = `${environment.content_url}/content/${id}/bookmark`;
    return this.http.post<Content>(url, {}, { observe: 'response' }).pipe(
      map(this.handleContentResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Удалить из закладок
   */
  public removeBookmark(id: string) {
    const url = `${environment.content_url}/content/${id}/bookmark`;
    return this.http.delete(url, { observe: 'response' }).pipe(
      map(this.handleContentResponse),
      // catchError(this.handleError),
    );
  }

  /** Опубликовать контент в ленте публикаций школы */
  public addToSchoolContent(school_id: string, id: string): Observable<IStatusResponse> {
    const url = `${environment.content_url}/school/${school_id}/${id}`;
    return this.http.post<IStatusResponse>(url, {}, { observe: 'body' });
  }

  private handleContentResponse(response: HttpResponse<any>) {
    return getContentInstanceByItem(response.body);
  }

  private handleContentsResponse(response: HttpResponse<Content[]>): IPagedResults<Content[]> {
    // const totalRecords = +res.headers.get('X-InlineCount');
    return createPagedResultsFromResponse(response);
  }

  private handleError(error: HttpErrorResponse) {
    console.error('server error:', error);
    if (error.error instanceof Error) {
      const errMessage = error.error.message;
      return throwError(errMessage);
      // Use the following instead if using lite-server
      // return throwError(err.text() || 'backend server error');
    }
    return throwError(error || 'Node.js server error');
  }
}
