const processesListTutor = [
  {
    title: 'first-contact.processes.list-tutor.title1',
    text: 'first-contact.processes.list-tutor.text1',
    linkLabel: 'first-contact.processes.list-tutor.link1',
    link: '/constructor',
    image: 'content',
  },
  {
    title: 'first-contact.processes.list-tutor.title2',
    text: 'first-contact.processes.list-tutor.text2',
    linkLabel: 'first-contact.processes.list-tutor.link2',
    link: '/classes/create',
    image: 'meeting',
  },
  {
    title: 'first-contact.processes.list-tutor.title3',
    text: 'first-contact.processes.list-tutor.text2',
    linkLabel: 'first-contact.processes.list-tutor.link3',
    link: '/schools',
    image: 'school',
  },
];

const processesListMethodist = [
  {
    title: 'first-contact.processes.list-methodist.title1',
    text: 'first-contact.processes.list-methodist.text1',
    linkLabel: 'first-contact.processes.list-methodist.link1',
    link: '/constructor',
    image: 'content',
  },
  {
    title: 'first-contact.processes.list-methodist.title2',
    text: 'first-contact.processes.list-methodist.text2',
    linkLabel: 'first-contact.processes.list-methodist.link2',
    link: '/classes/create',
    image: 'meeting',
  },
  {
    title: 'first-contact.processes.list-methodist.title3',
    text: 'first-contact.processes.list-methodist.text3',
    linkLabel: 'first-contact.processes.list-methodist.link3',
    link: '/schools',
    image: 'school',
  },
];

export const processesList = {
  student: [],
  tutor: processesListTutor,
  methodist: processesListMethodist,
  school: [],
};
