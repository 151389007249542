import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TeacherBadgesWidgetModule } from '@core/widgets/teacher-badges-widget/teacher-badges-widget.module';
import { TranslateModule } from '@ngx-translate/core';

import { OnboardingSharedModule } from '../shared/onboarding-shared.module';

import { OnboardingLimitedTutorComponent } from './onboarding-limited-tutor.component';

@NgModule({
  declarations: [OnboardingLimitedTutorComponent],
  imports: [CommonModule, TranslateModule, TeacherBadgesWidgetModule, OnboardingSharedModule],
  exports: [OnboardingLimitedTutorComponent],
})
export class OnboardingLimitedTutorModule {}
