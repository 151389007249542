import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { IconsService, AccountService, FilesService } from '@core/services';
import { DestroyableComponent } from '@models/destroyable.component';
import { ImageSizeEnum, User, UserProfileVerificationEnum as VE } from 'lingo2-models';
import { OnUiCover } from 'onclass-ui';

enum StatusIcons {
  not_verified = 'status-not-verified',
  on_moderation = 'status-on-moderation',
  modified = 'status-modified',
  verified = 'status-verified',
}

@Component({
  selector: 'app-onboarding-personal-info',
  templateUrl: './onboarding-personal-info.component.html',
  styleUrls: ['./onboarding-personal-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingPersonalInfoComponent extends DestroyableComponent implements OnInit {
  // TODO: convert to common widget

  @Input() public user: User;
  @Input() public verification_status: number;

  public statusIcons = {
    [VE.incomplete]: StatusIcons.not_verified,
    [VE.not_verified]: StatusIcons.not_verified,
    [VE.sent]: StatusIcons.on_moderation,
    [VE.approved]: StatusIcons.verified,
    [VE.declined]: StatusIcons.not_verified,
    [VE.changed]: StatusIcons.modified,
    [VE.resent]: StatusIcons.on_moderation,
    [VE.redeclined]: StatusIcons.verified,
  };

  private popoverStatuses = [VE.incomplete, VE.not_verified, VE.declined, VE.redeclined];

  public svgsetIcon = IconsService.svgsetIconUrl;

  constructor() {
    super();
  }

  ngOnInit() {}

  public get userpicUrl(): string {
    return FilesService.getFileUrlBySize(this.user?.userpic_id, ImageSizeEnum.md);
  }

  public get userFullName(): string {
    return AccountService.getUserFullName(this.user);
  }

  public get accountRoute(): string {
    return AccountService.accountRoute(this.user).join('/');
  }

  public get isGuest(): boolean {
    return AccountService.isGuest(this.user);
  }

  public get isAsIfTeacher(): boolean {
    return AccountService.isAsIfTeacher(this.user);
  }

  public get isPopoverNeeded(): boolean {
    return this.popoverStatuses.includes(this.verification_status);
  }

  public get avatar(): OnUiCover {
    return {
      type: 'avatar',
      img: this.userpicUrl,
      title: this.userFullName,
      link: this.accountRoute,
      form: 'circle',
    };
  }
}
