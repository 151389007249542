import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DateFnsModule } from 'lingo2-ngx-date-fns';

import { OnboardingDonutChartModule } from './onboarding-donut-chart/onboarding-donut-chart.module';
import { OnboardingTrackersComponent } from './onboarding-trackers.component';

@NgModule({
  declarations: [OnboardingTrackersComponent],
  imports: [CommonModule, TranslateModule, DateFnsModule, OnboardingDonutChartModule],
  exports: [OnboardingTrackersComponent],
})
export class OnboardingTrackersModule {}
