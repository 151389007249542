import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { advantagesList } from './first-contact-school-advantages.variables';

@Component({
  selector: 'app-first-contact-school-advantages',
  templateUrl: './first-contact-school-advantages.component.html',
  styleUrls: ['./first-contact-school-advantages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirstContactSchoolAdvantagesComponent extends ChangableComponent implements OnInit {
  public advantagesList = advantagesList;

  constructor(
    protected readonly cdr: ChangeDetectorRef,
    public deviceService: DeviceDetectorService,
    protected readonly platform?: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngOnInit(): void {}
}
