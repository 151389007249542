<app-modal *ngIf="imageURL"
           [middle]="true">
  <app-modal-body>
    <app-modal-title>
      <h3> {{ 'meetings.edit-cover' | translate }} </h3>
      <div class="popover__close" (click)="onClose()">
        <svg class="icon">
          <use [attr.xlink:href]="'#close-sm'"></use>
        </svg>
      </div>
    </app-modal-title>
    <spinner1 *ngIf="loading"></spinner1>
    <image-cropper
      [imageURL]="imageURL"
      [maintainAspectRatio]="true"
      [aspectRatio]="aspectRatio"
      format="png|jpeg"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
    ></image-cropper>
    <app-modal-actions class="center">
      <on-ui-button class="primary"
                    data-test="select-area"
                    label="meetings.select-area"
                    (onclick)="onCropImage()">
      </on-ui-button>
    </app-modal-actions>
  </app-modal-body>
</app-modal>
