export const titles = {
  tutor: 'first-contact.constructor.titles.tutor',
  student: 'first-contact.constructor.titles.student',
  methodist: 'first-contact.constructor.titles.methodist',
  school: 'first-contact.constructor.titles.school',
};

export const subtitles = {
  tutor: 'first-contact.constructor.subtitles.tutor',
  methodist: 'first-contact.constructor.subtitles.methodist',
};

const constructorListTutors = [
  {
    type: 'theory',
    title: 'first-contact.constructor.list-tutors.title1',
    text: 'first-contact.constructor.list-tutors.text1',
  },
  {
    type: 'exercises',
    title: 'first-contact.constructor.list-tutors.title2',
    text: 'first-contact.constructor.list-tutors.text2',
  },
  {
    type: 'media',
    title: 'first-contact.constructor.list-tutors.title3',
    text: 'first-contact.constructor.list-tutors.text3',
  },
  {
    type: 'vocabulary',
    title: 'first-contact.constructor.list-tutors.title4',
    text: 'first-contact.constructor.list-tutors.text4',
  },
];

const constructorListStudent = [
  {
    type: 'theory',
    title: 'first-contact.constructor.list-student.title1',
    text: 'first-contact.constructor.list-student.text1',
  },
  {
    type: 'exercises',
    title: 'first-contact.constructor.list-student.title2',
    text: 'first-contact.constructor.list-student.text2',
  },
  {
    type: 'media',
    title: 'first-contact.constructor.list-student.title3',
    text: 'first-contact.constructor.list-student.text3',
  },
  {
    type: 'vocabulary',
    title: 'first-contact.constructor.list-student.title4',
    text: 'first-contact.constructor.list-student.text4',
  },
];

const constructorListMethodist = constructorListTutors;

const constructorListSchool = [
  {
    type: 'online-products',
    title: 'first-contact.constructor.list-school.title1',
    text: 'first-contact.constructor.list-school.text1',
  },
  {
    type: 'school',
    title: 'first-contact.constructor.list-school.title2',
    text: 'first-contact.constructor.list-school.text2',
  },
  {
    type: 'employees',
    title: 'first-contact.constructor.list-school.title3',
    text: 'first-contact.constructor.list-school.text3',
  },
  {
    type: 'schedule',
    title: 'first-contact.constructor.list-school.title4',
    text: 'first-contact.constructor.list-school.text4',
  },
  {
    type: 'distributes-profits',
    title: 'first-contact.constructor.list-school.title5',
    text: 'first-contact.constructor.list-school.text5',
  },
  {
    type: 'control',
    title: 'first-contact.constructor.list-school.title6',
    text: 'first-contact.constructor.list-school.text6',
  },
];

export const constructorList = {
  tutor: constructorListTutors,
  student: constructorListStudent,
  methodist: constructorListMethodist,
  school: constructorListSchool,
};
