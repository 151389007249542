<section class="sticky"
         [style.height]="heightContainer"
         [class.desktop]="deviceService.isDesktop()"
         [class.mobile]="deviceService.isMobile() || deviceService.isTablet()">
  <div #sticky class="container"
       [class.stuck-desktop]="stuckForDesktop"
       [class.shadow]="stuckForDesktop && stuckStateForDesktop"
       [class.stuck]="stuckState && !stuckForDesktop" [style.top]="stuckTop + 'px'" [style.z-index]="stuckIndex">
    <ng-content></ng-content>
  </div>
</section>
