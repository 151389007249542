import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Lesson, GameResults, GameModeEnum, GameScenarioEnum, Game } from 'lingo2-models';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GameService {
  protected protocol_version = 1;

  constructor(private http: HttpClient) {}

  /**
   * Подготовить превью урока по ID урока
   *
   * @param contentId string
   * @param mode GameModeEnum
   * @param scenario GameScenarioEnum
   */
  public prepareGameByLessonId(contentId: string, mode?: GameModeEnum, scenario?: GameScenarioEnum): Observable<Game> {
    const url = `${environment.content_url}/game/prepare/${contentId}`; // TODO ?mode=${mode}
    return this.http.post<Game>(url, { mode, scenario }, { observe: 'response' }).pipe(
      map(this.handleItemResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Подготовить превью урока по содержимому урока
   *
   * @param lesson Partial<Lesson>
   */
  public prepareGameByContent(lesson: Partial<Lesson>): Observable<Game> {
    const url = `${environment.content_url}/game/preview`;
    return this.http.post<Game>(url, { lesson }, { observe: 'response' }).pipe(
      map(this.handleItemResponse),
      // catchError(this.handleError),
    );
  }

  public getGameById(id: string): Observable<Game> {
    const url = `${environment.content_url}/game/${id}`;
    return this.http.get<Game>(url, { observe: 'response' }).pipe(
      map(this.handleItemResponse),
      // catchError(of("Content api exception")),
    );
  }

  /**
   * Обновить прогресс участника игры
   *
   * @param game_id string
   * @param results GameResults
   */
  public updateParticipant(game_id: string, results: Partial<GameResults | any>): Observable<any> {
    const url = `${environment.content_url}/game/${game_id}/participant`;
    return this.http
      .put(url, results, { observe: 'response' })
      .pipe(catchError(() => of({ type: '[Game API] Update participant error' })));
  }

  private handleItemResponse(response: HttpResponse<Game>): Game {
    // logger.log('get game:', JSON.stringify(response.body) );
    return new Game(response.body);
  }

  // TODO в случае 401 оишбки - сгенерить новый токен и повторить запрос
  // private handleError(error: HttpErrorResponse) {
  //   console.error('server error:', error);
  //   if (error.error instanceof Error) {
  //     const errMessage = error.error.message;
  //     return throwError(errMessage);
  //     // Use the following instead if using lite-server
  //     // return throwError(err.text() || 'backend server error');
  //   }
  //   return throwError(error || 'Node.js server error');
  // }
}
