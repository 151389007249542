import { ClassroomsBlockType } from '@core/services';
import { Action, createReducer, on } from '@ngrx/store';

const DEFAULT_DETAILS: ClassroomDetailsType[] = [
  'id',
  'slug',
  'title',
  'cover:md',
  // TODO 'author:sm',
  'description',
  'stats',
  'visit_info',
  'created_at',
  'participants:some:sm',
  'author_id',
  'archived_at',
];

import { ClassroomDetailsType } from 'lingo2-models';
import * as ClassroomsAction from './classrooms.actions';

export interface ClassroomsListState extends ClassroomsBlockType {
  error: string;
  details: ClassroomDetailsType[];
  infiniteScroll: boolean;
}

const PAGE_SIZE = 10;

const initialState: ClassroomsListState = {
  items: [],
  loading: false,
  loaded: false,
  filter: {},
  pagination: {
    page: 1,
    pageSize: PAGE_SIZE,
    total: 0,
    totalPages: 0,
  },
  error: null,
  details: DEFAULT_DETAILS,
  infiniteScroll: true,
};

const contentReducer = createReducer(
  initialState,

  on(ClassroomsAction.requestFail, (state, { error }) => ({ ...state, loading: false, error })),

  on(ClassroomsAction.loadClassrooms, (state) => ({ ...state, loading: true, error: null })),

  on(ClassroomsAction.loadClassroomsSuccess, (state, { response }) => {
    const uniqueItems = response.results.filter((x) => !state.items.some((item) => item.id === x.id));
    return {
      ...state,
      items: state.infiniteScroll ? [...state.items, ...uniqueItems] : [...uniqueItems],
      pagination: {
        ...state.pagination,
        total: response.total,
        totalPages: response.totalPages,
        page: response.page,
      },
      loading: false,
      loaded: true,
    };
  }),

  on(ClassroomsAction.clearClassrooms, (state) => ({ ...initialState })),

  on(ClassroomsAction.loadNextPageClassrooms, (state) => {
    const isAvailable = state.pagination.page < state.pagination.totalPages;
    const page = isAvailable ? state.pagination.page + 1 : state.pagination.page;
    return {
      ...state,
      pagination: {
        ...state.pagination,
        page,
      },
    };
  }),

  on(ClassroomsAction.setClassroomsFilter, (state, { filter }) => ({
    ...state,
    filter: {
      ...state.filter,
      ...filter,
    },
  })),
);

export function reducer(state = initialState, action: Action) {
  return contentReducer(state, action);
}
