import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AuthService } from '@core/services';

import { IListPanelOption, BulletEnum as BE } from '../models';
import { OnboardingStatusesEnum as OSE } from '../onboarding-widget.component';

import { OnboardingWidgetService } from '../onboarding-widget.service';
import { IChecklistOption } from '../shared/onboarding-checklist/onboarding-checklist.component';

const CHECKLIST_OPTIONS: IChecklistOption[] = [
  { label: 'onboarding.checklist.sign-in' },
  { label: 'onboarding.checklist.name' },
  { label: 'onboarding.checklist.photo' },
  { label: 'onboarding.checklist.verify' },
];

const PANEL_OPTIONS: IListPanelOption[] = [
  { bullet: BE.dot, label: 'onboarding.panel.host-classes' },
  { bullet: BE.dot, label: 'onboarding.panel.invite-students' },
  { bullet: BE.dot, label: 'onboarding.panel.chat' },
  { bullet: BE.dot, label: 'onboarding.panel.create-content' },
];

@Component({
  selector: 'app-onboarding-guest-teacher',
  templateUrl: './onboarding-guest-teacher.component.html',
  styleUrls: ['./onboarding-guest-teacher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingGuestTeacherComponent implements OnInit {
  public status = OSE.guest_teacher;
  public isExpanded$ = this.onboardingWidgetService.isStatusExpanded$;

  public checklistOptions = CHECKLIST_OPTIONS;
  public panelOptions = PANEL_OPTIONS;

  constructor(private authService: AuthService, private onboardingWidgetService: OnboardingWidgetService) {}

  ngOnInit() {}

  public signIn() {
    this.authService.showAuthModal();
  }
}
