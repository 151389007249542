import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UserRoles } from '@app/first-contact/first-contact.component';
import { PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { stepsList } from './first-contact-steps.variables';

@Component({
  selector: 'app-first-contact-steps',
  templateUrl: './first-contact-steps.component.html',
  styleUrls: ['./first-contact-steps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirstContactStepsComponent extends ChangableComponent implements OnInit {
  @Input() selectedRole: UserRoles;
  public stepsList = stepsList;

  constructor(
    protected readonly cdr: ChangeDetectorRef,
    public deviceService: DeviceDetectorService,
    protected readonly platform?: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngOnInit(): void {}
}
