import { ChangeDetectionStrategy, Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { IconsService, PlatformService } from '@core/services';
import { DestroyableComponent } from '@models/destroyable.component';
import { LessonSlideImageAspectRatioEnum } from 'lingo2-models';
import { InViewportDirective } from 'ng-in-viewport';

type SlideTableFormWizardStep = 'choose-gridsize' | 'choose-aspect';

interface ISlideTableFormWizard {
  active: boolean;
  step: SlideTableFormWizardStep;
}

export interface IGridSize {
  width: number;
  height: number;
}

@Component({
  selector: 'app-grid-toolbar',
  templateUrl: './grid-toolbar.component.html',
  styleUrls: ['./grid-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridToolbarComponent extends DestroyableComponent implements OnInit {
  @Input() rows: number;
  @Input() columns: number;
  @Input() aspectRatio: LessonSlideImageAspectRatioEnum;

  @Output() gridSizeChanged = new EventEmitter<IGridSize>();
  @Output() aspectRatioChanged = new EventEmitter<LessonSlideImageAspectRatioEnum>();

  @ViewChild('wizardPointer', { read: InViewportDirective }) wizardPointer: InViewportDirective;

  public svgsetIcon = IconsService.svgsetIconUrl;

  public aspectPopupOpen = false;
  public gridsizePopupOpen = false;

  public wizard: ISlideTableFormWizard = {
    active: false,
    step: null,
  };

  constructor(protected readonly platform: PlatformService) {
    super(platform);
  }

  ngOnInit() {
    this.setupWizard();
  }

  protected setupWizard() {
    // TODO: animate wizard
    // this.wizard.active = (this.rows === 0  || this.columns === 0 || !this.aspectRatio);
    // this.wizard.step = (this.rows === 0  || this.columns === 0) ? 'choose-gridsize' : 'choose-aspect';
    // if (this.wizardPointer && this.wizardPointer.isInViewport) {
    //   this.wizardPointerViewportChange(true);
    // }
  }

  public wizardPointerViewportChange(inViewport) {
    if (inViewport.visible && this.wizard.active) {
      if (this.wizard.step === 'choose-gridsize') {
        this.setTimeout(() => {
          this.gridsizePopupOpen = true;
        }, 1000);
      }
      if (this.wizard.step === 'choose-aspect') {
        this.setTimeout(() => {
          this.gridsizePopupOpen = false;
          this.aspectPopupOpen = true;
        }, 1000);
      }
    }
  }

  public onGridsizeChanged(value: IGridSize) {
    this.setupWizard();
    this.gridSizeChanged.emit(value);
  }

  public onAspectRatioChanged(value: LessonSlideImageAspectRatioEnum) {
    this.aspectRatio = value;
    this.setupWizard();
    this.aspectRatioChanged.emit(value);
  }

  public onGridSizePopoverOpened(isOpened: boolean): void {
    this.gridsizePopupOpen = isOpened;
  }

  public onAspectRatioPopoverOpened(isOpened: boolean): void {
    this.aspectPopupOpen = isOpened;
  }
}
