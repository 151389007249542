import { Component, EventEmitter, Input, Output } from '@angular/core';

export type AspectRatioType = 'wide' | 'horizontal' | 'square' | 'vertical';

@Component({
  selector: 'app-aspect-ratio-selector',
  templateUrl: './aspect-ratio-selector.component.html',
  styleUrls: ['./aspect-ratio-selector.component.scss'],
})
export class AspectRatioSelectorComponent {
  @Input() public set aspectRatio(value: AspectRatioType) {
    this._aspectRatio = value;
  }
  public get aspectRatio(): AspectRatioType {
    return this._aspectRatio;
  }
  @Output() changed = new EventEmitter<AspectRatioType>();

  private _aspectRatio: AspectRatioType;

  public setAspectRatio(value: AspectRatioType, e?: Event) {
    if (e) {
      e.preventDefault();
      e.cancelBubble = true;
    }
    this._aspectRatio = value;
    this.changed.emit(this._aspectRatio);
  }

  public get aspectRatioRangeCx(): number {
    switch (this._aspectRatio) {
      case 'wide':
        return 14;

      case 'horizontal':
        return 74;

      case 'square':
        return 136;

      case 'vertical':
        return 196;
    }
  }
}
