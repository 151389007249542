import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { INoticeParams, MeetingComplaintReportedNoticeParams } from 'lingo2-models';
import { EModifier, IBaseNotification } from '../../base-notification/base-notification.component';

@Component({
  selector: 'app-meeting-complaint-reported',
  templateUrl: './meeting-complaint-reported.component.html',
  styles: [''],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeetingComplaintReportedComponent {
  public params: MeetingComplaintReportedNoticeParams;
  public base: IBaseNotification;

  @Input() set notification(notification: INoticeParams) {
    this.params = notification.params;
    this.base = {
      title: 'notifications.meeting_complaint_reported.title',
      icon: 'notice-cancel',
      created_at: notification.created_at,
      modifier: EModifier.yellow,
    };
  }

  public get student() {
    return `<a class='user-fullname-link' target='_blank' href='${this.params.student_url}'>${this.params.student_fullname}</a>`;
  }

  public get title() {
    return `<a class='link-base' href='${this.params.meeting_join_url}'>${this.params.meeting_title}</a>`;
  }
}
