<div *ngIf="isActive" class="suggest-language-change">
  <p class="phrase-text">{{ suggestedContent }}</p>

  <div class="actions-wrap">
    <div class="select-wrap">
      <on-ui-select [(ngModel)]="selectedLanguageId" [options]="languagesOptions"></on-ui-select>
    </div>
    <div class="button-wrap">
      <on-ui-button (click)="acceptSuggestedLanguage()"
                    class="primary outline"
                    state="default"
                    [label]="buttonTitle">
      </on-ui-button>
    </div>
  </div>

  <div class="close-action" (click)="disableLanguageSuggest()">
    <svg width="20" height="20" viewBox="0 0 12 12" stroke="#A4B0C3">
      <use [attr.xlink:href]="svgSetIcon('close-sidebar')"></use>
    </svg>
  </div>
</div>
