import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-verified-widget',
  templateUrl: './not-verified-widget.component.html',
  styleUrls: ['./not-verified-widget.component.scss'],
})
export class NotVerifiedWidgetComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
