import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MediaFileSelectorModule } from '@app/core/components/media-file-selector/media-file-selector.module';
import { TranslateModule } from '@ngx-translate/core';
import { BlockingSpinnerModule } from '../../blocking-spinner/blocking-spinner.module';
import { ImageCropModule } from '../../image-crop/image-crop.module';
import { SlideImageComponent } from './slide-image.component';

@NgModule({
  declarations: [SlideImageComponent],
  imports: [CommonModule, MediaFileSelectorModule, BlockingSpinnerModule, TranslateModule, ImageCropModule],
  exports: [SlideImageComponent],
})
export class SlideImageModule {}
