import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFindTasksFilter, Task, IPagedResults, IPagination } from 'lingo2-models';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  constructor(private http: HttpClient) {}

  /**
   * Домашние задания
   *
   * @param filter
   * @param pagination
   */
  public getTasks(filter: Partial<IFindTasksFilter>, pagination: IPagination): Observable<IPagedResults<Task[]>> {
    const url = `${environment.content_url}/tasks/`;
    const params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter', JSON.stringify(filter));
    return this.http.get<Task[]>(url, { params, observe: 'response' }).pipe(
      map(this.handleTasksResponse),
      // catchError(this.handleError),
    );
  }

  private handleTaskResponse(response: HttpResponse<Task>): Task {
    return response.body;
  }

  private handleTasksResponse(response: HttpResponse<Task[]>): IPagedResults<Task[]> {
    // const totalRecords = +res.headers.get('X-InlineCount');
    const content = response.body;
    return {
      results: content,
      page: +response.headers.get('X-Pagination-Page'),
      pageSize: +response.headers.get('X-Pagination-PageSize'),
      total: +response.headers.get('X-Pagination-Total'),
      totalPages: +response.headers.get('X-Pagination-TotalPages'),
    };
  }

  private handleError(error: HttpErrorResponse) {
    console.error('server error:', error);
    if (error.error instanceof Error) {
      const errMessage = error.error.message;
      return throwError(errMessage);
      // Use the following instead if using lite-server
      // return throwError(err.text() || 'backend server error');
    }
    return throwError(error || 'Node.js server error');
  }
}
