import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { TranslateModule } from '@ngx-translate/core';
import { AgreementTermsFieldModule } from 'lingo2-forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiButtonModule, OnUiCheckboxModule } from 'onclass-ui';

@Component({
  selector: 'app-rules',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    OnUiButtonModule,
    OnUiCheckboxModule,
    TranslateModule,
    AgreementTermsFieldModule,
  ],
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss'],
})
export class RulesComponent extends ChangableComponent implements OnInit {
  public form: UntypedFormGroup;

  @Output() onAgree = new EventEmitter<boolean>();

  public constructor(
    public deviceService: DeviceDetectorService,
    protected fb: UntypedFormBuilder,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      agreement: [!!localStorage.getItem('rules_warning')],
    });
  }

  public hideWidget() {
    localStorage.setItem('rules_warning', 'hide');
    this.onAgree.emit(true);
  }
}
