import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { OnUiButtonModule } from 'onclass-ui';
import { OnboardingSharedModule } from '../shared/onboarding-shared.module';
import { OnboardingGuestComponent } from './onboarding-guest.component';

@NgModule({
  declarations: [OnboardingGuestComponent],
  imports: [CommonModule, TranslateModule, OnboardingSharedModule, OnUiButtonModule],
  exports: [OnboardingGuestComponent],
})
export class OnboardingGuestModule {}
