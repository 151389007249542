import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { voiceCodes } from '@app/vocabulary/vocabulary.variables';
import { environment } from '@env/environment';
import { IItemResponse } from 'lingo2-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OpenAiService {
  public constructor(private http: HttpClient) {}

  public translateWord(text: string, sourceLang: string, targetLang: string): Observable<string> {
    const url = `${environment.content_url}/proxy/openai`;
    const prompt = `Translate this word from ${sourceLang} to ${targetLang}: ${text}`;

    const data = {
      prompt,
      max_tokens: 1024,
      n: 1,
      stop: null,
      temperature: 0.7,
    };
    return this.http.post<{ text: string }>(url, data).pipe(map((response) => response.text));
  }

  public generateSpeech(text: string, lang_id: number) {
    const url = `${environment.content_url}/proxy/playht`;
    const voice = voiceCodes.find((el) => el.code == lang_id)
      ? voiceCodes.find((el) => el.code == lang_id)?.codeMale
      : 'en-GB-RyanNeural';
    const payload = { text, voice };
    return this.http
      .post(url, payload, { observe: 'body' })
      .pipe(map((response: IItemResponse<string>) => response.item));
  }
}
