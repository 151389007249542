<ng-container *ngIf="debug$ | async">
  <div>
    <button (click)="onboardingStatus = states.guest">Guest</button>
    <button (click)="onboardingStatus = states.unknown">Unknown</button>
    <button (click)="onboardingStatus = states.guest_teacher">Guest teacher</button>
    <button (click)="onboardingStatus = states.not_verified">Not verified</button>
    <button (click)="onboardingStatus = states.on_moderation">On moderation</button>
    <button (click)="onboardingStatus = states.limited_tutor">Limited tutor</button>
    <button (click)="onboardingStatus = states.tutor">Tutor</button>
    <button (click)="onboardingStatus = states.teacher">Teacher</button>
    <button (click)="onboardingStatus = states.limited_teacher">Limited teacher</button>
    <button (click)="onboardingStatus = states.guest_student">Guest student</button>
    <button (click)="onboardingStatus = states.student">Student</button>
  </div>
</ng-container>


<div class="onboarding" *ngIf="(me$ | async) as me">
  <app-onboarding-personal-info [user]="me"
                                [verification_status]="verification_status">
  </app-onboarding-personal-info>

  <ng-container [ngSwitch]="onboardingStatus">
    <app-onboarding-guest *ngSwitchCase="states.guest"></app-onboarding-guest>
    <app-onboarding-unknown *ngSwitchCase="states.unknown"></app-onboarding-unknown>
    <app-onboarding-guest-student *ngSwitchCase="states.guest_student"></app-onboarding-guest-student>
    <app-onboarding-student *ngSwitchCase="states.student" [me]="me"></app-onboarding-student>
    <app-onboarding-guest-teacher *ngSwitchCase="states.guest_teacher"></app-onboarding-guest-teacher>
    <app-onboarding-not-verified *ngSwitchCase="states.not_verified" [me]="me"></app-onboarding-not-verified>
    <app-onboarding-on-moderation *ngSwitchCase="states.on_moderation" [me]="me"></app-onboarding-on-moderation>
    <app-onboarding-limited-tutor *ngSwitchCase="states.limited_tutor" [me]="me"></app-onboarding-limited-tutor>
    <app-onboarding-tutor *ngSwitchCase="states.tutor" [me]="me"></app-onboarding-tutor>
    <app-onboarding-teacher *ngSwitchCase="states.teacher" [me]="me"></app-onboarding-teacher>
    <app-onboarding-limited-teacher *ngSwitchCase="states.limited_teacher" [me]="me"></app-onboarding-limited-teacher>
  </ng-container>
</div>
