import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { OnboardingChecklistComponent } from './onboarding-checklist.component';

@NgModule({
  declarations: [OnboardingChecklistComponent],
  imports: [CommonModule, RouterModule, TranslateModule],
  exports: [OnboardingChecklistComponent],
})
export class OnboardingChecklistModule {}
