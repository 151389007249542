import {
  Country,
  TeacherStatusEnum,
  User,
  Meeting,
  EContentModeType,
  EContentOrder,
  IPaneFilterOptions,
  EContentPanelRoute,
  IBasicContent,
  IBasicCollection,
} from 'lingo2-models';

export function insertIntoCollection<T extends { id: string }>(items: T[], collection: ILoadableCollection<T>) {
  return items.reduce((acc, item) => {
    acc[item.id] = {
      data: item,
      loaded: true,
      loading: false,
    };
    return acc;
  }, collection);
}

export function removeFromCollection<T extends { id: string }>(comment_id: string, collection: ILoadableCollection<T>) {
  delete collection[comment_id];
  return collection;
}

/** Для коллекций лучше использовать этот интерфейс */
export interface ILoadableCollection<T> {
  [key: string]: {
    loading: boolean;
    loaded: boolean;
    data: T;
  };
}
/** @deprecated Use ILoadableCollection instead */
export type IUserCollection = IBasicCollection<Partial<User>>;

/** @deprecated Use ILoadableCollection instead */
export type IMeetingCollection = IBasicCollection<Partial<Meeting> & Partial<ILoadableData>>;

export enum EWidgetStatType {
  Status,
  Value,
  Rating,
  Text,
  Date,
  TimeDistance,
  Country,
  Separator,
}

/** @deprecated */
export interface ILoadableData {
  loading: boolean;
  loaded: boolean;
}

export interface IWidgetStat {
  icon: string;
  label: string;
  type: EWidgetStatType;
  link?: string;
  status?: TeacherStatusEnum;
  value?: number;
  delta?: number;
  text?: string;
  date?: Date;
  rating?: { rate: number; votes: number };
  country?: Country;
}

export function getElementByIdOrSlug<T extends Partial<IBasicContent>>(
  items: IBasicCollection<T>,
  id?: string,
  slug?: string,
) {
  if (!id && !slug) {
    return null;
  }

  if (id && items[id]) {
    return items[id];
  }

  if (slug) {
    return Object.values(items).find((m) => m.slug === slug);
  }

  return null;
}

export class CLibraryFilterData implements IPaneFilterOptions {
  public readonly url: string;
  public readonly contentMode: EContentModeType;
  public readonly subjectsMap?: number[];
  public readonly contentTypeCodes: string[];
  public readonly languageCodes?: string[];
  public readonly contentOrder?: EContentOrder;
  public readonly term?: string;
  public readonly item?: string;
  public readonly isDefaultOnlyLanguages: boolean;
  public readonly baseRoute: EContentPanelRoute;
  public readonly subjectCodes?: string[];

  constructor(values?: Partial<CLibraryFilterData>) {
    const instance: any = this;
    Object.keys(values).forEach((p: keyof Partial<CLibraryFilterData>) => {
      if (p in values) {
        instance[p] = values[p];
      }
    });
  }
}

export enum ERedPointsEvents {
  'update_schedule' = 'update_schedule',
  'classroom_new_invitation' = 'classroom_new_invitation',
  'content_comment' = 'content_comment',
  'sidebar_my_classes' = 'sidebar_my_classes',
  'sidebar_my_library' = 'sidebar_my_library',
  'my_library_lessons' = 'my_library_lessons',
  'my_library_services' = 'my_library_services',
  'my_library_collections' = 'my_library_collections',
  'my_lesson_moderation' = 'my_lesson_moderation',
  'my_lesson_published' = 'my_lesson_published',
  'my_collection_moderation' = 'my_collection_moderation',
  'my_collection_published' = 'my_collection_published',
  'my_user_service_moderation' = 'my_user_service_moderation',
  'my_user_service_published' = 'my_user_service_published',
  'content_commented' = 'content_commented',
  'content_published' = 'content_published',
  'collection_purchased' = 'collection_purchased',
  'bookmark' = 'bookmark',
}

export interface IRedPointsEvents {
  [ERedPointsEvents.update_schedule]?: boolean;
  [ERedPointsEvents.classroom_new_invitation]?: boolean;
  [ERedPointsEvents.content_comment]?: boolean;
  [ERedPointsEvents.sidebar_my_classes]?: boolean;
  [ERedPointsEvents.sidebar_my_library]?: boolean;
  [ERedPointsEvents.my_library_lessons]?: boolean;
  [ERedPointsEvents.my_library_services]?: boolean;
  [ERedPointsEvents.my_library_collections]?: boolean;
  [ERedPointsEvents.my_lesson_moderation]?: boolean;
  [ERedPointsEvents.my_lesson_published]?: boolean;
  [ERedPointsEvents.my_collection_moderation]?: boolean;
  [ERedPointsEvents.my_collection_published]?: boolean;
  [ERedPointsEvents.my_user_service_moderation]?: boolean;
  [ERedPointsEvents.my_user_service_published]?: boolean;
  [ERedPointsEvents.content_commented]?: boolean;
  [ERedPointsEvents.content_published]?: boolean;
  [ERedPointsEvents.collection_purchased]?: boolean;
  [ERedPointsEvents.bookmark]?: boolean;
}
