import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { logger } from '@core/helpers/logger';
import {
  AccountService,
  ClassroomsService,
  FeaturesService,
  FilesService,
  IconsService,
  MeetingsService,
  PlatformService,
} from '@core/services';
import { WebsocketService } from '@core/websocket';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { getMe } from '@store/reducers/profile.reducer';
import { differenceInSeconds } from 'date-fns';
import {
  EContentOrder,
  EContentPanelRoute,
  EDashboardMeetingGroup,
  FeatureEnum,
  ImageSizeEnum,
  Meeting,
  otherSubjectId,
  Router as ContentRouter,
  User,
  UserStatusEnum,
} from 'lingo2-models';
import { OnUiCover } from 'onclass-ui';
import {
  catchError,
  debounceTime,
  filter,
  map,
  mergeAll,
  Observable,
  of,
  Subject,
  Subscription,
  switchMap,
  takeUntil,
  tap,
  timer,
} from 'rxjs';

interface MeetData {
  id?: string;
  link?: string;
  classroomLink?: string;
  authorLink?: string;
  cover?: OnUiCover;
  title?: string;
  subject?: string;
  onAir?: boolean;
  participants_count?: number;
  participants_limit?: number;
  meetingTypeName?: string;
  is_author?: boolean;
  authorFullName?: string;
  beginDate?: Date;
}

@Component({
  selector: 'app-nearest-meet-widget',
  templateUrl: './nearest-meet-widget.component.html',
  styleUrls: ['./nearest-meet-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NearestMeetWidgetComponent extends ChangableComponent implements OnInit, OnDestroy {
  public me: User;
  public meetData: MeetData = {};
  public isEventsPage: boolean;
  public svgSetIcon = IconsService.svgsetIconUrl;

  private meeting_id: string;
  private refreshMeeting$ = this.register(new Subject<boolean>());
  private meetStatusOnAirForce$$: Subscription;

  public constructor(
    private router: Router,
    private websocketService: WebsocketService,
    private meetingsService: MeetingsService,
    private accountService: AccountService,
    private features: FeaturesService,
    private readonly store: Store,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  public ngOnInit(): void {
    if (!this.isVisible) {
      return;
    }
    this.router.events.pipe(takeUntil(this.destroyed$)).subscribe((res) => {
      if (res instanceof NavigationEnd) {
        this.isEventsPage = res.url.includes('/my-classes/events');
        this.detectChanges();
      }
    });

    this.onBrowserOnly(() => {
      of(this.watchMe$, this.watchMeetingUpdated$, this.watchRefreshMeeting$)
        .pipe(mergeAll())
        .pipe(takeUntil(this.destroyed$))
        .subscribe();
    });
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
    this.stopWatching();
  }

  public get isStartCountdown(): boolean {
    return this.meetData.beginDate && differenceInSeconds(this.meetData.beginDate, new Date()) < 3600;
  }

  public toggleMinimize() {
    if (localStorage.getItem('minimized')) {
      localStorage.removeItem('minimized');
    } else {
      localStorage.setItem('minimized', 'true');
    }
  }

  public get isMinimized() {
    return !!localStorage.getItem('minimized');
  }

  public get isVisible() {
    return this.features.isAvailable(FeatureEnum.nearest_meet_widget);
  }

  public goToEvents() {
    this.router.navigate([EContentPanelRoute['my-classes/events']]).then();
  }

  private setMeetStatusOnAirForce() {
    this.meetStatusOnAirForce$$?.unsubscribe();
    this.meetStatusOnAirForce$$ = timer(this.meetData.beginDate)
      .pipe(
        tap(() => {
          this.meetData.onAir = true;
          this.detectChanges();
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  private constructMeetData(meeting: Meeting) {
    if (!meeting) {
      return;
    }

    if (this.meeting_id !== meeting.id) {
      if (this.meeting_id) {
        this.stopWatching();
      }
      this.meeting_id = meeting.id;
      this.startWatching();
    }

    this.meetData.id = meeting.id;
    this.meetData.cover = {
      img: FilesService.getFileUrlBySize(meeting.cover_id, ImageSizeEnum.wide),
      type: 'cover',
      aspect: '16/9',
    };
    this.meetData.subject = meeting.subject
      ? +meeting.subject?.id === otherSubjectId
        ? meeting.subject_other_name
        : meeting.subject?.title
      : null;
    this.meetData.onAir = meeting.has?.meetingGroup === EDashboardMeetingGroup.going;
    this.meetData.beginDate = meeting.begin_at as Date;
    if (!this.meetData.onAir) {
      this.setMeetStatusOnAirForce();
    }
    this.meetData.title = meeting.title;
    this.meetData.participants_count = meeting.participants_count;
    this.meetData.participants_limit = meeting.participants_limit;
    this.meetData.meetingTypeName = MeetingsService.meetingTypeName(meeting);
    this.meetData.is_author = meeting.is?.owner;
    if (!this.meetData.is_author) {
      this.meetData.authorFullName = AccountService.getUserFullName(meeting.author);
    }
    this.meetData.authorLink = AccountService.accountRoute(meeting.author).join('/');
    this.meetData.link = this.router.createUrlTree(ContentRouter.meetingJoinRoute(meeting)).toString();
    this.meetData.classroomLink = this.router
      .createUrlTree(ClassroomsService.classroomRoute(meeting.classroom))
      .toString();
    this.detectChanges();
  }

  private startWatching() {
    this.websocketService.startWatching('meeting', this.meeting_id);
  }

  private stopWatching() {
    this.websocketService.stopWatching('meeting', this.meeting_id);
  }

  private get watchMe$(): Observable<User> {
    return this.store.select(getMe).pipe(
      filter((el) => el?.status !== UserStatusEnum.guest),
      tap((me) => {
        const oldMeId = this.me?.id || null;
        const newMeId = me?.id || null;
        this.me = me;
        if (this.me && oldMeId !== newMeId) {
          this.refreshMeeting$.next(true);
        } else {
          this.meetData = {};
        }
        this.detectChanges();
      }),
    );
  }

  private get watchMeetingUpdated$() {
    return this.websocketService
      .onEntityUpdate('meeting', this.meeting_id)
      .pipe(tap(() => this.refreshMeeting$.next(true)));
  }

  private get watchRefreshMeeting$() {
    return this.refreshMeeting$.pipe(
      debounceTime(2543),
      switchMap(() => this.findNearestMeeting$()),
    );
  }

  private findNearestMeeting$() {
    return this.meetingsService
      .getMeetings(
        {
          upcoming: true, // те, в которых мне предстоит заниматься
          order: EContentOrder.date_asc, // сортировка по возрастанию дат
        },
        { page: 1, pageSize: 1 },
      )
      .pipe(
        map((response) => (response.results || []).pop()),
        filter((meeting) => !!meeting),
        switchMap((meeting: Meeting) => this.extendAuthor$(meeting)),
        tap((meeting) => this.constructMeetData(meeting)),
      );
  }

  private extendAuthor$<T extends { author_id: string; author?: User }>(entity: T): Observable<T> {
    if (!entity || !entity.author_id || entity.author) {
      return of(entity);
    }
    return this.accountService.getUserById(entity.author_id, 'LOAD_TIME').pipe(
      map((account) => {
        entity.author = account;
        return entity;
      }),
      catchError((err) => {
        logger.error('extendAuthor$', err);
        return of(entity);
      }),
    );
  }
}
