import { Injectable } from '@angular/core';
import { DestroyableComponent } from '@models/destroyable.component';
import { Notice } from 'lingo2-models';
import { BehaviorSubject, Subject } from 'rxjs';
import { GreetingTypeEnum, Greeting } from './greeting-notice/greeting-notice.component';
import { Toast } from './toaster/toaster.component';

const getYMDDate = (date: Date | string): string => {
  date = typeof date === 'string' ? new Date(date) : date;
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

const geTimeOfDay = (): string => {
  const hour = new Date().getHours();
  if (hour >= 0 && hour < 5) {
    return 'night';
  }
  if (hour >= 5 && hour < 12) {
    return 'morning';
  }
  if (hour >= 12 && hour < 18) {
    return 'afternoon';
  }
  if (hour >= 18 && hour < 24) {
    return 'evening';
  }
};

const initialDayTimeGreetings = {
  date: getYMDDate(new Date()),
  user_id: null,
  morning: false,
  afternoon: false,
  evening: false,
  night: false,
};

@Injectable({
  providedIn: 'root',
})
export class NotificationsService extends DestroyableComponent {
  private dayTimeGreetings;

  private _unreadCount = 0;
  private unreadCount = this.register(new BehaviorSubject<number>(0));
  public unreadCount$ = this.unreadCount.asObservable();

  private _isOpened = false;
  private isOpened = this.register(new BehaviorSubject<boolean>(false));
  public isOpened$ = this.isOpened.asObservable();

  private _notices: Notice[] = [];

  private notices = this.register(new Subject<Notice[]>());
  public notices$ = this.notices.asObservable();

  private notice = this.register(new Subject<Notice>());
  public notice$ = this.notice.asObservable();

  private greeting = this.register(new Subject<Greeting>());
  public greeting$ = this.greeting.asObservable();

  private _initialToasts: Toast[] = [];

  private toast = this.register(new Subject<Toast>());
  public toast$ = this.toast.asObservable();

  private toasts = this.register(new Subject<Toast[]>());
  public toasts$ = this.toasts.asObservable();

  public setUnreadCount(count: number): void {
    this._unreadCount = count;
    this.unreadCount.next(this._unreadCount);
  }

  public openWidget(): void {
    this._isOpened = true;
    this.isOpened.next(this._isOpened);
  }

  public closeWidget(): void {
    this._isOpened = false;
    this.isOpened.next(this._isOpened);
  }

  public toggleWidget(): void {
    this._isOpened = !this._isOpened;
    this.isOpened.next(this._isOpened);
  }

  public pushNotice(notice: Notice): void {
    this._notices.push(notice);
    this.notice.next(notice);
  }

  public pushNotices(notices: Notice[]): void {
    this._notices = [...this._notices, ...notices];
    this.notices.next(notices);
  }

  public getNotices(): Notice[] {
    return this._notices;
  }

  public getUniqueNotices = (notices: Notice[]): Notice[] =>
    notices.filter((n) => n.id && !this._notices.find((x) => x.id === n.id)) || [];

  public clearNotices() {
    this._notices = [];
  }

  public pushToast(toast: Toast): void {
    this.toast.next(toast);
  }

  public pushToasts(toasts: Toast[]): void {
    this.toasts.next(toasts);
  }

  public getInitialToasts(): Toast[] {
    return this._initialToasts;
  }

  public clearInitialToasts(): void {
    this._initialToasts = [];
  }

  public pushWelcomeGreeting(): void {
    this._initialToasts.push({ type: GreetingTypeEnum.welcome });
  }

  public pushDayTimeGreeting(username: string, user_id: string): void {
    const timeOfDay = geTimeOfDay();
    this.refreshDayTimeState(user_id);

    if (!this.dayTimeGreetings[timeOfDay]) {
      this._initialToasts.push({ type: GreetingTypeEnum[timeOfDay], username });
    }

    this.setDayTimeState(timeOfDay);
  }

  private refreshDayTimeState(user_id: string): void {
    const currentDate = getYMDDate(new Date());
    this.dayTimeGreetings = localStorage ? JSON.parse(localStorage.getItem('dayTimeGreetings')) : null;

    if (!this.dayTimeGreetings) {
      this.dayTimeGreetings = { ...initialDayTimeGreetings };
    }

    if (currentDate !== this.dayTimeGreetings.date || user_id !== this.dayTimeGreetings.user_id) {
      this.dayTimeGreetings = { ...initialDayTimeGreetings };
    }

    this.dayTimeGreetings.user_id = user_id;
    this.dayTimeGreetings.date = currentDate;
    if (localStorage || false) {
      localStorage.setItem('dayTimeGreetings', JSON.stringify(this.dayTimeGreetings));
    }
  }

  private setDayTimeState(timeOfDay: string): void {
    this.dayTimeGreetings[timeOfDay] = true;
    if (localStorage || false) {
      localStorage.setItem('dayTimeGreetings', JSON.stringify(this.dayTimeGreetings));
    }
  }
}
