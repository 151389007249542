import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { EPopularityPeriod, IContentPopularityParams, IContentStats } from 'lingo2-models';

export interface ContentPopularityDebug {
  params: IContentPopularityParams;
  content_id: string;
  popularity: number;
  popularity_by_period: number;
  stats: Partial<IContentStats>;
}

@Injectable({
  providedIn: 'root',
})
export class RatingService {
  public constructor(private http: HttpClient) {}

  public getContentRating(content_id: string) {
    const url = `${environment.content_url}/rating/${content_id}`;
    return this.http.get<ContentPopularityDebug>(url, { observe: 'body' });
  }

  public getAccountRating(account_id: string) {
    const url = `${environment.account_url}/rating/${account_id}`;
    return this.http.get<
      Array<{
        period_start_at: Date;
        period: EPopularityPeriod;
        popularity: number;
        debug: any;
      }>
    >(url, { observe: 'body' });
  }

  public updateAccountRating(account_id: string) {
    const url = `${environment.account_url}/rating/${account_id}/update`;
    return this.http.post(url, null, { observe: 'body' });
  }
}
