<app-alert *ngIf="alertVisible" type="warning" [btnposition]="'center'">
  <div alert-icon>
    <svg width="34" height="36" viewBox="0 0 34 36">
      <use [attr.xlink:href]="svgsetIcon('emoji-think')"></use>
    </svg>
  </div>
  <ng-container alert-title>{{ 'timezone-notifier.title' | translate }}</ng-container>
  <ng-container alert-body>
    {{ 'timezone-notifier.question' | translate: { timezone: browserTimezone, offset: browserTimezoneOffset } }}
  </ng-container>
  <ng-container alert-buttons>
    <on-ui-button label="timezone-notifier.btn-skip"
                  state="default"
                  class="transparent"
                  (onclick)="skip()">

    </on-ui-button>
    <on-ui-button label="timezone-notifier.btn-save"
                  state="default"
                  class="primary center"
                  (onclick)="save()">
    </on-ui-button>
  </ng-container>
</app-alert>
