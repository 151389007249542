import { createAction, props } from '@ngrx/store';
import {
  BillingPlan,
  Collection,
  CPriceTier,
  IBasicContent,
  IPriceTier,
  Language,
  Meeting,
  Subject,
  UserService,
  UserServiceCategory,
} from 'lingo2-models';

export const setCurrentItem = createAction('[Content] Set current item', props<{ item: IBasicContent }>());
export const clearContent = createAction('[Content] clear data');
export const setLanguages = createAction('[Content] Set languages', props<{ languages: Language[] }>());
export const setPriceTiers = createAction('[Content] Set price tiers', props<{ priceTiers: CPriceTier[] }>());
export const setPrices = createAction('[Content] Set prices', props<{ prices: IPriceTier[] }>());
export const loadBillingPlans = createAction('[Content] Load billing plans');
export const setBillingPlans = createAction('[Content] Set billing plans', props<{ billingPlans: BillingPlan[] }>());
export const setSubjects = createAction('[Content] Set subjects', props<{ subjects: Subject[] }>());
export const loadUserService = createAction('[Content] Load user service', props<{ user_service_id: string }>());
export const loadUserServiceSuccess = createAction(
  '[Content] Load user service success',
  props<{ userService: UserService }>(),
);
export const setUserServiceCategories = createAction(
  '[Content] Set user service categories',
  props<{ userServiceCategories: UserServiceCategory[] }>(),
);
export const loadCollection = createAction('[Content] Load user collection', props<{ collection_id: string }>());
export const loadCollectionSuccess = createAction(
  '[Content] Load user collection success',
  props<{ collection: Collection }>(),
);

export const loadMeeting = createAction(
  '[Content] Load single meeting',
  props<{ meeting_id?: string; slug?: string }>(),
);
export const loadMeetingSuccess = createAction('[Content] Load single meeting success', props<{ meeting: Meeting }>());
