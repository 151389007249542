import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { toolsList } from './first-contact-school-tools.variables';

@Component({
  selector: 'app-first-contact-school-tools',
  templateUrl: './first-contact-school-tools.component.html',
  styleUrls: ['./first-contact-school-tools.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirstContactSchoolToolsComponent extends ChangableComponent implements OnInit {
  public toolsList = toolsList;

  constructor(
    private router: Router,
    public deviceService: DeviceDetectorService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform?: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngOnInit(): void {}

  public onNavigate(route: string) {
    this.router.navigate([route]).then();
  }
}
