import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

export interface IGridSize {
  width: number;
  height: number;
}

@Component({
  selector: 'app-grid-size-selector',
  templateUrl: './grid-size-selector.component.html',
  styleUrls: ['./grid-size-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridSizeSelectorComponent {
  @Input() width: number;
  @Input() height: number;
  @Input() set maxWidth(value: number) {
    this.rows = new Array(value).fill(0).map((v, i) => i + 1);
  }
  @Input() set maxHeight(value: number) {
    this.columns = new Array(value).fill(0).map((v, i) => i + 1);
  }

  public rows: number[] = [];
  public columns: number[] = [];
  @Output() changed = new EventEmitter<IGridSize>();

  public onCellActivate(row: number, column: number, e?: Event) {
    if (e) {
      e.preventDefault();
      e.cancelBubble = true;
    }
    this.width = column;
    this.height = row;
    this.changed.emit({ width: this.width, height: this.height });
  }

  public cellClass(row: number, column: number): any {
    const classes: any = {};
    const maxWidth = this.columns.length;
    const maxHeight = this.rows.length;

    if (row === 1 && column === 1) {
      classes['top-left'] = true;
    }
    if (row === 1 && column === maxWidth) {
      classes['top-right'] = true;
    }
    if (row === maxHeight && column === 1) {
      classes['bottom-left'] = true;
    }
    if (row === maxHeight && column === maxWidth) {
      classes['bottom-right'] = true;
    }
    if (row <= this.height && column <= this.width) {
      classes['active'] = true;
    }
    return classes;
  }
}
