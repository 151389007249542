import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'relativeUrl',
})
export class RelativeUrlPipe implements PipeTransform {
  transform(path: string): string {
    if (!path || 'http' !== path.substring(0, 4)) {
      return path;
    }

    // return new URL(path).pathname;
    return path.replace(/^http[s]?:\/\/.+?\//, '/');
  }
}
