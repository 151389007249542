import { createAction, props } from '@ngrx/store';
import { Lesson, IPagination, IFindContentFilter } from 'lingo2-models';

export const requestFail = createAction('[Constructor] Request: fail', props<{ error: string }>());

// Library

export const loadLibraryContent = createAction('[Constructor] Load library');
export const loadLibraryContentSuccess = createAction(
  '[Constructor] Load library: success',
  props<{ items: Lesson[]; pagination: IPagination }>(),
);

// content_type: ContentTypeEnum | ContentTypeEnum[];
// publish_status: PublishStatusEnum | PublishStatusEnum[];

export const setLibraryFilter = createAction(
  '[Constructor] Set library filter',
  props<{ filter: Partial<IFindContentFilter> }>(),
);
export const setLibraryPagination = createAction(
  '[Constructor] Set library pagination',
  props<{ pagination: Partial<IPagination> }>(),
);
