import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TeacherBadgesWidgetModule } from '@core/widgets/teacher-badges-widget/teacher-badges-widget.module';
import { TranslateModule } from '@ngx-translate/core';

import { OnboardingSharedModule } from '../shared/onboarding-shared.module';

import { OnboardingTeacherComponent } from './onboarding-teacher.component';

@NgModule({
  declarations: [OnboardingTeacherComponent],
  imports: [CommonModule, TranslateModule, TeacherBadgesWidgetModule, OnboardingSharedModule],
  exports: [OnboardingTeacherComponent],
})
export class OnboardingTeacherModule {}
