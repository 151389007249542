import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { INoticeParams, VacationReminderNoticeParams } from 'lingo2-models';
import { EModifier, IBaseNotification } from '../../base-notification/base-notification.component';

@Component({
  selector: 'app-vacation-reminder',
  templateUrl: './vacation-reminder.component.html',
  styles: [''],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VacationReminderComponent {
  public params: VacationReminderNoticeParams;
  public base: IBaseNotification;

  @Input() set notification(notification: INoticeParams) {
    this.params = VacationReminderNoticeParams.createInstance(notification.params);
    this.base = {
      title: 'notifications.vacation_reminder.title',
      icon: 'notice-vacation',
      created_at: notification.created_at,
      modifier: null,
    };
  }
}
