import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CheckboxModule } from './checkbox/checkbox.module';
import { FormFieldModule } from './form-field/form-field.module';
import { ImageUploaderModule } from './image-uploader/image-uploader.module';
import { MultiSelectModule } from './multi-select/multi-select.module';
import { RadiosModule } from './radios/radios.module';
import { SearchInputModule } from './search-input/search-input.module';

@NgModule({
  declarations: [],
  imports: [
    FormFieldModule,
    CommonModule,
    RadiosModule,
    CheckboxModule,
    ImageUploaderModule,
    SearchInputModule,
    MultiSelectModule,
  ],
  exports: [FormFieldModule, RadiosModule, CheckboxModule, ImageUploaderModule, SearchInputModule, MultiSelectModule],
})
export class FormControlsModule {}
