import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserRoles } from '@app/first-contact/first-contact.component';
import { PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { processesList } from './first-contact-processes.variables';

@Component({
  selector: 'app-first-contact-processes',
  templateUrl: './first-contact-processes.component.html',
  styleUrls: ['./first-contact-processes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirstContactProcessesComponent extends ChangableComponent {
  @Input() selectedRole: UserRoles;
  public _processesList = processesList;

  constructor(
    private router: Router,
    protected readonly cdr: ChangeDetectorRef,
    public deviceService: DeviceDetectorService,
    protected readonly platform?: PlatformService,
  ) {
    super(cdr, platform);
  }

  public processesList() {
    return this._processesList[this.selectedRole];
  }

  public onNavigate(route: string) {
    this.router.navigate([route]).then();
  }
}
