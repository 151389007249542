import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { IconsService } from '@core/services/icons.service';

export enum GreetingTypeEnum {
  welcome = 'welcome',
  morning = 'morning',
  afternoon = 'afternoon',
  evening = 'evening',
  night = 'night',
}

export interface GreetingParams {
  title: string;
  icon: string;
}

interface GreetingsParamsList {
  [key: string]: GreetingParams;
}

export interface Greeting {
  type: string;
  username?: string;
}

@Component({
  selector: 'app-greeting-notice',
  templateUrl: './greeting-notice.component.html',
  styleUrls: ['./greeting-notice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GreetingNoticeComponent implements OnInit {
  @Input() greeting: Greeting;

  public greetingsParamsList: GreetingsParamsList = {
    [GreetingTypeEnum.welcome]: {
      title: 'greetings.welcome',
      icon: 'emoji-party',
    },
    [GreetingTypeEnum.morning]: {
      title: 'greetings.morning',
      icon: 'morning',
    },
    [GreetingTypeEnum.afternoon]: {
      title: 'greetings.afternoon',
      icon: 'afternoon',
    },
    [GreetingTypeEnum.evening]: {
      title: 'greetings.evening',
      icon: 'evening',
    },
    [GreetingTypeEnum.night]: {
      title: 'greetings.night',
      icon: 'night',
    },
  };

  public params: GreetingParams;

  public svgsetIcon = IconsService.svgsetIconUrl;

  constructor() {}

  ngOnInit() {
    this.params = this.greetingsParamsList[this.greeting.type];
  }
}
