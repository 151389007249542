import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { PlatformService } from '@app/core/services';
import { ChangableComponent } from '@models/changable.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sticky',
  templateUrl: './sticky.component.html',
  styleUrls: ['./sticky.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StickyComponent extends ChangableComponent implements OnInit {
  @Input() stuckForDesktop: boolean;
  @Input() set scrollThreshold(scrollThreshold: number) {
    this._scrollThreshold = scrollThreshold;
    this.setContainerHeight();
  }

  @Input() set afterScrollThreshold(afterScrollThreshold: number) {
    this.stuckTop = afterScrollThreshold;
    if (this.stuckTop) {
      this.stuckIndex = 10;
    }
  }

  @Input() set onlyMobile(onlyMobile: boolean) {
    this._onlyMobile = onlyMobile;
  }

  public get onlyMobile() {
    return this._onlyMobile;
  }

  public heightContainer = 'unset';
  public stuckState = false;
  public stuckStateForDesktop = false;
  public stuckTop = 0;
  public stuckIndex: number;
  public _onlyMobile: boolean;

  private _scrollThreshold: number;

  @ViewChild('sticky') private stickyRef: ElementRef;
  private subscriptionScroll: Subscription;

  constructor(
    public deviceService: DeviceDetectorService,
    protected readonly platform: PlatformService,
    protected readonly router: Router,
    protected cdr: ChangeDetectorRef,
  ) {
    super(cdr, platform);
  }

  ngOnInit(): void {
    if (this.onlyMobile) {
      if (this.deviceService.isMobile() && this.isBrowser) {
        this.stickyEvent();
      }
    } else {
      if (this.isBrowser) {
        if (this.stuckForDesktop) {
          this.setTimeout(() => {
            this.heightContainer = this.router.url.includes('/main') ? `40px` : `78px`;
            this.detectChanges();
          }, 100);

          this.router.events.pipe(takeUntil(this.destroyed$)).subscribe((res) => {
            if (res instanceof NavigationEnd) {
              this.heightContainer = res.url.includes('/main') ? `40px` : `78px`;
              this.detectChanges();
            }
          });
        }
        this.stickyEvent();
      }
    }
  }

  private setContainerHeight(): void {
    if (!this.stuckForDesktop) {
      if (this.stickyRef) {
        this.heightContainer = `${this.stickyRef.nativeElement.offsetHeight}px`;
      } else {
        this.heightContainer = 'unset';
      }
      this.detectChanges();
    }
  }

  private stickyEvent() {
    this.subscriptionScroll = fromEvent(window, 'scroll')
      .pipe(debounceTime(10), takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this.stuckForDesktop) {
          this.stuckStateForDesktop = window.scrollY > this._scrollThreshold;
          this.detectChanges();
          return;
        }

        if (window.scrollY >= this._scrollThreshold) {
          this.stuckState = true;
          this.setContainerHeight();
          return;
        }
        if (this.stuckState) {
          this.stuckState = false;
          this.heightContainer = 'unset';
          this.detectChanges();
        }
      });
  }
}
