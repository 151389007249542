import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TaskAnswerVariantPopoverModule } from '../../task-answer-variant-popover/task-answer-variant-popover.module';
import { SlideImageModule } from '../slide-image/slide-image.module';
import { SlideRichTextModule } from '../slide-rich-text/slide-rich-text.module';
import { SlideVariantComponent } from './slide-variant.component';

@NgModule({
  declarations: [SlideVariantComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SlideRichTextModule,
    SlideImageModule,
    TaskAnswerVariantPopoverModule,
    TranslateModule,
  ],
  exports: [SlideVariantComponent],
})
export class SlideVariantModule {}
