import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Meeting, MeetingDetailsType, UserService, UserServiceDetailsType } from 'lingo2-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface IMeetingUserService {
  meeting?: Meeting;
  userService?: UserService;
}

@Injectable({
  providedIn: 'root',
})
export class MeetingsServicesService {
  constructor(private http: HttpClient) {}

  /**
   * Один митинг ИЛИ user_service по URL
   */
  public getBySlug(
    slug: string,
    meetingDetails: MeetingDetailsType[],
    userServiceDetails: UserServiceDetailsType[],
  ): Observable<IMeetingUserService> {
    const _slug = slug.split('-').reverse()[0];
    const url = `${environment.content_url}/meeting-service/u/${_slug}`;
    const params = new HttpParams()
      .set('meeting', JSON.stringify(meetingDetails))
      .set('service', JSON.stringify(userServiceDetails));
    return this.http.get<IMeetingUserService>(url, { params, observe: 'response' }).pipe(map(this.handleResponse));
  }

  private handleResponse(response: HttpResponse<IMeetingUserService>): IMeetingUserService {
    if (response.body.meeting) {
      return {
        meeting: new Meeting(response.body.meeting),
      };
    }
    if (response.body.userService) {
      return {
        userService: new UserService(response.body.userService),
      };
    }
    return null;
  }
}
