import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from '@abacritt/angularx-social-login';
import { OverlayModule } from '@angular/cdk/overlay';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Compiler, Injectable, NgModule } from '@angular/core';
import { MatNativeDateModule, MatDateFormats, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JitCompilerFactory } from '@angular/platform-browser-dynamic';
import { AuthModalModule } from '@core/components/auth-modal/auth-modal.module';
import { CookieNotifierModule } from '@core/components/cookie-notifier/cookie-notifier.module';
import { SeoModule } from '@core/components/seo/seo.module';
import { SpinnersModule } from '@core/components/spinners/spinners.module';
import { AuthGuard, GuestGuard, BrowserGuard, TenantOverrideGuard } from '@core/guards';
import { httpInterceptorProviders } from '@core/interceptors';
import {
  LanguageService,
  AccountService,
  ProfileService,
  BillingService,
  ConfigService,
  MeetingsService,
  ContextService,
  DropzoneConfig,
  FilesService,
  FeaturesService,
  PlatformService,
  ClassroomsService,
  AuthService,
} from '@core/services';
import { HttpLoaderFactory } from '@core/services/i18n';
import { WebsocketService } from '@core/websocket';
import { environment } from '@env/environment'; // Angular CLI environment
import { BuilderModule } from '@le2xx/angular-builder-io';
import { MetrikaModule } from '@le2xx/ng-yandex-metrika';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools'; // Debug only
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ApplicationDialogsModule } from '@shared/application-dialogs/application-dialogs.component';
import {
  Lingo2FormsModule,
  AbstractFilesService,
  AbstractScreenService,
  AbstractAccountService,
  AbstractBillingService,
  AbstractFeaturesService,
  AbstractMeetingsService,
  AbstractClassroomsService,
  AbstractDropzoneConfig,
  AbstractUserService,
  AbstractLanguageService,
  AbstractConfigService,
  AbstractProfileService,
  AbstractWebsocketService,
  AbstractBillingV2Service,
  AbstractAuthService,
  AbstractCurrenciesService,
} from 'lingo2-forms';
import { InViewportModule } from 'ng-in-viewport';
import { CookieModule } from 'ngx-cookie';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { RequestCache, RequestCacheWithMap, ScreenService } from './core/services';

// Store
// TODO: Make this conditional, depends on NODE_ENV
import { ROOT_REDUCERS, metaReducers } from './store';
import { UserEffects, ProfileEffects, ContentEffects, FeaturingEffects } from './store/effects';

// JustInTime компилятор - чтобы lingo2-game-lib отрабатывал слайды типа 'fill-the-gaps'
export function createJitCompiler() {
  const _JitCompilerFactory = JitCompilerFactory as any;
  return new _JitCompilerFactory([]).createCompiler([
    {
      // ??? useDebug: false,
      useJit: true, // boolean;
      // defaultEncapsulation: ? // ViewEncapsulation;
      // providers: ? // StaticProvider[];
      // missingTranslation: ? // MissingTranslationStrategy;
      // preserveWhitespaces: ? // boolean;
    },
  ]);
}

// Date
// eslint-disable-next-line import/order
import { DateFnsModule } from 'lingo2-ngx-date-fns';
import { OnUiSpriteModule } from 'onclass-ui';
import { BillingV2Service } from '@core/services/lingo2-account/billing-v2.service';
import { NearestMeetWidgetModule } from '@core/widgets';
import { DebugDrawerModule } from '@core/components/debug-drawer/debug-drawer.component';
import { LastVersionComponent } from '@core/components/last-version/last-version.component';

// Firebase
import { remoteConfigProviders } from '@core/services/remote-config/providers';
import { RemoteConfigService } from '@core/services/remote-config/remote-config.service';
import { remoteConfigModules } from '@core/services/remote-config/modules';
import { CurrenciesService } from '@core/services/currencies.service';

const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { day: 'numeric', month: 'numeric', year: 'numeric' },
  },
  display: {
    dateInput: { day: 'numeric', month: 'long', year: 'numeric' },
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};

export function SameServiceFactory(service: Injectable) {
  return service;
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'lingo2-web' }),
    CookieModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, PlatformService],
      },
    }),
    DateFnsModule.forRoot(),
    InViewportModule,
    SpinnersModule,
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    !environment.production
      ? StoreDevtoolsModule.instrument({
          maxAge: environment.production ? 0 : 50, // Retains last 50 states, reduced to keep memory free
          logOnly: !!environment.production, // Restrict extension to log-only mode
        })
      : [],
    EffectsModule.forRoot([UserEffects, ProfileEffects, ContentEffects, FeaturingEffects]),
    AppRoutingModule,
    MetrikaModule.forRoot([
      {
        id: environment.metrika_id,
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: environment.metrika_webvisor,
      },
    ]),
    AuthModalModule,
    MatNativeDateModule,
    OverlayModule,
    SeoModule,
    SocialLoginModule,
    Lingo2FormsModule.forRoot({
      accountService: { provide: AbstractAccountService, useFactory: SameServiceFactory, deps: [AccountService] },
      billingService: { provide: AbstractBillingService, useFactory: SameServiceFactory, deps: [BillingService] },
      billingV2Service: { provide: AbstractBillingV2Service, useFactory: SameServiceFactory, deps: [BillingV2Service] },
      currencyService: {
        provide: AbstractCurrenciesService,
        useFactory: SameServiceFactory,
        deps: [CurrenciesService],
      },
      classroomsService: {
        provide: AbstractClassroomsService,
        useFactory: SameServiceFactory,
        deps: [ClassroomsService],
      },
      userService: { provide: AbstractUserService, useFactory: SameServiceFactory, deps: [ContextService] },
      featuresService: {
        provide: AbstractFeaturesService,
        useFactory: SameServiceFactory,
        deps: [FeaturesService],
      },
      authService: { provide: AbstractAuthService, useFactory: SameServiceFactory, deps: [AuthService] },
      filesService: { provide: AbstractFilesService, useFactory: SameServiceFactory, deps: [FilesService] },
      meetingsService: {
        provide: AbstractMeetingsService,
        useFactory: SameServiceFactory,
        deps: [MeetingsService],
      },
      screenService: { provide: AbstractScreenService, useFactory: SameServiceFactory, deps: [ScreenService] },
      dropzoneConfig: { provide: AbstractDropzoneConfig, useFactory: SameServiceFactory, deps: [DropzoneConfig] },
      languageService: {
        provide: AbstractLanguageService,
        useFactory: SameServiceFactory,
        deps: [LanguageService],
      },
      configService: { provide: AbstractConfigService, useFactory: SameServiceFactory, deps: [ConfigService] },
      profileService: { provide: AbstractProfileService, useFactory: SameServiceFactory, deps: [ProfileService] },
      websocketService: {
        provide: AbstractWebsocketService,
        useFactory: SameServiceFactory,
        deps: [WebsocketService],
      },
    }),
    BrowserAnimationsModule,
    CookieNotifierModule,
    NearestMeetWidgetModule,
    OnUiSpriteModule,
    BuilderModule.forRoot(environment.builderio_api_key),
    ApplicationDialogsModule,
    DebugDrawerModule,
    LastVersionComponent,
    ...remoteConfigModules,
  ],
  providers: [
    AuthGuard,
    GuestGuard,
    BrowserGuard,
    TenantOverrideGuard,
    { provide: Compiler, useFactory: createJitCompiler },
    // { provide: 'SocialAuthServiceConfig', useFactory: authServiceConfigProvider },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '680468905548-063m8kpb85oj6p9686bsgajbdsvgbvr4.apps.googleusercontent.com',
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('447106395962762'),
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    { provide: RequestCache, useClass: RequestCacheWithMap },
    ...httpInterceptorProviders,
    ...remoteConfigProviders,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private remoteConfigService: RemoteConfigService) {
    void remoteConfigService.init();
  }
}
