import { InjectionToken } from '@angular/core';
import { logger } from '@app/core/helpers/logger';
import { environment } from '@env/environment';
import { ActionReducer, ActionReducerMap, MetaReducer, Action } from '@ngrx/store';
import * as contentReducer from './reducers/content.reducer';
import * as featuringReducer from './reducers/featuring.reducer';
import * as generalReducer from './reducers/general.reducer';
import * as profileReducer from './reducers/profile.reducer';
import * as userReducer from './reducers/users.reducer';

// Здесь инициализируются только те сторы, для которых при старте нет компонентов.
// Т.к. чат и медиа-галерея инициализируются при старте (компоненты стартуют одновременно со страницей),
// то в их инициализации здесь нет необходимости.
//
// Профили же используют ленивую загрузку,
// поэтому наполнение начальными данными происходит до их появления на странице.
// Так же требуется модификация в app.module.ts, если мы хотим работать с эффектами при старте приложения.
// (ангуляр-вей, не меньше 10 файлов надо настроить, чтобы работало...)
// https://github.com/ngrx/platform/blob/master/projects/example-app/src/app/books/reducers/index.ts#L27
export interface AppState {
  [profileReducer.profileFeatureKey]: profileReducer.State;
  [userReducer.userFeatureKey]: userReducer.State;
  [contentReducer.contentFeatureKey]: contentReducer.State;
  [generalReducer.generalFeatureKey]: generalReducer.State;
  [featuringReducer.featuringFeatureKey]: featuringReducer.State;
}

// https://github.com/ngrx/platform/blob/master/projects/example-app/src/app/reducers/index.ts#L36
export const ROOT_REDUCERS = new InjectionToken<ActionReducerMap<AppState, Action>>('Root reducers token', {
  factory: () => ({
    [profileReducer.profileFeatureKey]: profileReducer.reducer,
    [userReducer.userFeatureKey]: userReducer.reducer,
    [contentReducer.contentFeatureKey]: contentReducer.reducer,
    [generalReducer.generalFeatureKey]: generalReducer.reducer,
    [featuringReducer.featuringFeatureKey]: featuringReducer.reducer,
  }),
});

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  if (environment.store_debug) {
    return function (state, action) {
      logger.info('state', state);
      logger.info('action', action);

      return reducer(state, action);
    };
  }
  return (state, action) => reducer(state, action);
}

// Мета редусер - редусер, который может выполнятся ПЕРЕД всеми. Например, для синхронизации каких-то значений.
// Главное помнить, что это такой же редусер, как и все. Никакого асинхрона!
export const metaReducers: Array<MetaReducer<AppState>> = !environment.production ? [debug] : [];
