export const titles = {
  student: 'first-contact.first-contact-selector.titles.student',
  tutor: 'first-contact.first-contact-selector.titles.tutor',
  methodist: 'first-contact.first-contact-selector.titles.methodist',
  school: 'first-contact.first-contact-selector.titles.school',
};

export const lists = {
  student: [
    'first-contact.first-contact-selector.list.student.item1',
    'first-contact.first-contact-selector.list.student.item2',
    'first-contact.first-contact-selector.list.student.item3',
    'first-contact.first-contact-selector.list.student.item4',
  ],
  tutor: [
    'first-contact.first-contact-selector.list.tutor.item1',
    'first-contact.first-contact-selector.list.tutor.item2',
    'first-contact.first-contact-selector.list.tutor.item3',
  ],
  methodist: [
    'first-contact.first-contact-selector.list.methodist.item1',
    'first-contact.first-contact-selector.list.methodist.item2',
    'first-contact.first-contact-selector.list.methodist.item3',
    'first-contact.first-contact-selector.list.methodist.item4',
  ],
  school: [
    'first-contact.first-contact-selector.list.school.item1',
    'first-contact.first-contact-selector.list.school.item2',
    'first-contact.first-contact-selector.list.school.item3',
    'first-contact.first-contact-selector.list.school.item4',
  ],
};

export const listsForMe = {
  student: [],
  tutor: [
    'first-contact.first-contact-selector.list.tutor.item1',
    'first-contact.first-contact-selector.list.tutor.item2',
  ],
};

export const texts = {
  student: 'first-contact.first-contact-selector.texts.student',
  tutor: 'first-contact.first-contact-selector.texts.tutor',
  methodist: 'first-contact.first-contact-selector.texts.methodist',
  school: 'first-contact.first-contact-selector.texts.school',
};
