import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventActionEnum, EventCategoryEnum } from '@app/core/services/analytics';
import { RemoteConfigType } from '@app/core/services/remote-config/constants';
import { RemoteConfigService } from '@app/core/services/remote-config/remote-config.service';
import { LibraryRouter } from '@app/models/library.router';
import { IMenuItem, UNCONTROLLED_ROUTES } from '@app/models/routes';
import { getLibraryRouteData } from '@app/store/reducers/library.reducer';
import { menuItems, menuItemsMe } from '@core/layouts/main/footer/footer.constants';
import {
  AccessActionEnum,
  AccountService,
  AnalyticsService,
  AuthService,
  BillingService,
  FeaturesService,
  IconsService,
  PlatformService,
  RequestService,
  ScreenService,
} from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { getMe } from '@store/reducers/profile.reducer';
import { expandWindow, getSendbirdUnreadCount, isChatWindowExpanded, whenChatUpdated } from 'lingo2-chat-app';
import { User, UserStatusEnum } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent extends ChangableComponent implements OnInit, OnDestroy {
  public svgSetIcon = IconsService.svgsetIconUrl;
  public menuItems: IMenuItem[];
  public me: User;
  public unreadCount$ = this.store.select(getSendbirdUnreadCount);
  public isChatExpanded: boolean;
  public isPortrait = true;
  public showCreatePopup: boolean;
  public meetingFormOpened: boolean;
  public isTeacher: boolean;

  private libraryRouter: LibraryRouter = new LibraryRouter();
  private remoteConfig: RemoteConfigType;

  public constructor(
    public deviceService: DeviceDetectorService,
    private accountService: AccountService,
    private authService: AuthService,
    private store: Store,
    private screenService: ScreenService,
    private router: Router,
    private billingService: BillingService,
    private requestService: RequestService,
    private remoteConfigService: RemoteConfigService,
    private analytics: AnalyticsService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  public ngOnInit() {
    this.menuItems = this.isLimitedVersion ? menuItemsMe : menuItems;

    this.store
      .select(getMe)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((me) => {
        this.me = me;
        this.isTeacher = AccountService.isTeacher(me);
        this.detectChanges();
      });

    this.store
      .select(isChatWindowExpanded)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isChatExpanded) => {
        this.isChatExpanded = isChatExpanded;
        this.detectChanges();
      });

    this.store
      .select(whenChatUpdated)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.detectChanges();
      });

    this.store
      .select(getLibraryRouteData)
      .pipe(
        filter((d) => !!d),
        takeUntil(this.destroyed$),
      )
      .subscribe((data) => {
        this.libraryRouter.setBaseRoute(data.baseRoute);
        this.libraryRouter.setSubjects(data.subjectCodes);
        this.updateRoutes();
      });

    this.screenService.options.pipe(takeUntil(this.destroyed$)).subscribe((options) => {
      this.isPortrait = options.isPortrait;
      this.detectChanges();
    });

    this.remoteConfigService.config$.pipe(takeUntil(this.destroyed$)).subscribe((config) => {
      this.remoteConfig = config;
      this.detectChanges();
    });

    this.updateRoutes();
  }

  public toggleChat() {
    this.store.dispatch(expandWindow({ expand: !this.isChatExpanded }));
    this.screenService.setBodyFixed(this.isChatExpanded);
    this.detectChanges();
  }

  public closeChat(e) {
    e.stopPropagation();
    this.showCreatePopup = false;
    this.store.dispatch(expandWindow({ expand: false }));
    this.screenService.setBodyFixed(false);
    this.detectChanges();
  }

  private updateRoutes() {
    this.menuItems = this.menuItems.map((item) => {
      this.libraryRouter.setBaseRoute(item.route);
      let url = `/${item.route}`;
      if (!UNCONTROLLED_ROUTES.includes(item.route)) {
        url = this.libraryRouter.getLink().path;
      }
      return {
        ...item,
        url,
      };
    });

    if (!this.isTeacher) {
      this.menuItems = this.menuItems.filter((el) => el.id !== 'my-library');
    }
    this.detectChanges();
  }

  public toggleCreateModal() {
    if (!this.authService.isAuthenticated) {
      this.authService.showAuthModal();
    } else {
      this.showCreatePopup = !this.showCreatePopup;
    }
  }

  public get scheduleMeetingVisible(): boolean {
    return (
      this.accountService.can(AccessActionEnum.canCreateMeeting, this.me) &&
      !!this.remoteConfig?.appFooter__scheduleMeeting__show
    );
  }

  public get quickMeetingVisible(): boolean {
    return (
      this.accountService.can(AccessActionEnum.canCreateDraftMeeting, this.me) &&
      !!this.remoteConfig?.appFooter__quickMeeting__show
    );
  }

  public onScheduleMeeting() {
    const options = {
      caller: 'app-footer',
      reason: 'schedule meeting',
    };

    this.analytics.event(EventActionEnum.meeting_scheduling, EventCategoryEnum.service, options.caller);

    const _continue = () => {
      if (this.remoteConfig?.scheduleMeeting__paywall) {
        // показать выбор тарифа, а если тариф есть, то отправить на целевую страницу
        this.billingService.showPaywall(() => this.openMeetingForm(), options);
      } else {
        // сразу отправить на целевую страницу
        this.openMeetingForm();
      }
    };

    if (this.remoteConfig?.scheduleMeeting__auth) {
      // показать диалог авторизации, а после авторизации продолжить
      this.authService.showAuthModal(() => _continue(), options);
    } else {
      // продолжить даже если не авторизован
      _continue();
    }
  }

  /** Создание быстрого митинга */
  public onQuickMeeting() {
    const options = {
      caller: 'app-footer',
      reason: 'create quick meeting',
    };

    this.analytics.event(EventActionEnum.meeting_quick_creating, EventCategoryEnum.service, options.caller);

    const _continue = () => {
      if (this.remoteConfig?.quickMeeting__paywall) {
        // показать выбор тарифа, а если тариф есть, то отправить на целевую страницу
        this.billingService.showPaywall(() => this.goDraftMeeting(), options);
      } else {
        // сразу отправить на целевую страницу
        this.goDraftMeeting();
      }
    };

    if (this.remoteConfig?.quickMeeting__auth) {
      // показать диалог авторизации, а после авторизации продолжить
      this.authService.showAuthModal(() => _continue(), options);
    } else {
      // продолжить даже если не авторизован
      _continue();
    }
  }

  protected openMeetingForm() {
    this.meetingFormOpened = true;
    this.detectChanges();
  }

  public closeMeetingForm() {
    this.meetingFormOpened = false;
    this.detectChanges();
  }

  private goDraftMeeting() {
    try {
      const url = this.requestService.host + this.router.createUrlTree(['/go/class']).toString();
      window.open(url, '_blank');
    } catch (e) {
      this.router.navigateByUrl('/go/class');
    }
  }

  public get isLimitedVersion() {
    return FeaturesService.isLimitedVersion;
  }

  public get isGuest() {
    return this.me?.status === UserStatusEnum.guest;
  }

  public trackByFn(item) {
    return item.id;
  }
}
