import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ContextService, FeaturesService, PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { FeatureEnum, User } from 'lingo2-models';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-onboarding-qr-code',
  templateUrl: './onboarding-qr-code.component.html',
  styleUrls: ['./onboarding-qr-code.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingQrCodeComponent extends ChangableComponent implements OnInit {
  protected _me: User;

  public constructor(
    protected contextService: ContextService,
    protected features: FeaturesService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  public ngOnInit() {
    this.contextService.me$
      .pipe(
        tap((me) => {
          this._me = me;
          this.detectChanges();
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  public get isAvailableMobileApp(): boolean {
    return this.features.isAvailable(FeatureEnum.mobile_apps, this._me);
  }
}
