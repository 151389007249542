import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PortalArticleType } from '@models/lingo2-models/content';
import { IFindArticlesFilter } from 'lingo2-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ArticleService {
  constructor(private http: HttpClient) {}

  /**
   * Поиск одной статьи по фильтру
   */
  public findArticle(filter: Partial<IFindArticlesFilter>): Observable<PortalArticleType> {
    const url = `${environment.content_url}/article`;
    const params = new HttpParams().set('filter', JSON.stringify(filter));
    return this.http.get<PortalArticleType>(url, { params, observe: 'response' }).pipe(
      map(this.handleSingleResponse),
      // catchError(this.handleError),
    );
  }

  private handleSingleResponse(response: HttpResponse<PortalArticleType>): PortalArticleType {
    return response.body;
  }
}
