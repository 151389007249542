import { createAction, props } from '@ngrx/store';

import { SidebarState } from './sidebar.reducer';

// Load UI state from localStorage

export const loadUIFilters = createAction('[Constructor] Load UI filters');
export const loadUIFiltersSuccess = createAction(
  '[Constructor] Load UI filters: success',
  props<{ sidebar: Partial<SidebarState> }>(),
);

// Save UI state to localStorage

export const saveUIFilters = createAction('[Constructor] Save UI filters', props<{ sidebar: Partial<SidebarState> }>());
export const saveUIFiltersSuccess = createAction(
  '[Constructor] Save UI filters: success',
  props<{ sidebar: Partial<SidebarState> }>(),
);
