import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';

type AlertType = 'info' | 'warning' | 'error' | 'success';

@Component({
  selector: 'app-alert-single',
  templateUrl: './alert-single.component.html',
  styleUrls: ['./alert-single.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertSingleComponent extends ChangableComponent implements OnInit, OnDestroy {
  @Input() type: AlertType = 'info';
  @Output() closed = new EventEmitter<boolean>();
  private element: any;
  private _timer: any;
  private _clickOutsideEnabled = false;

  constructor(
    private el: ElementRef,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
    this.element = el.nativeElement;
  }

  ngOnInit() {
    this.onBrowserOnly(() => {
      document.querySelector('.app').appendChild(this.element);
      this.clickOutsideEnabled = true;
      this.markForCheck();
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.closed.emit(true);
    this.onBrowserOnly(() => {
      this.element.remove();
    });
  }

  get clickOutsideEnabled(): boolean {
    return this._clickOutsideEnabled;
  }

  set clickOutsideEnabled(value: boolean) {
    if (value) {
      this.clearTimeout(this._timer);
      this._timer = this.setTimeout(() => {
        this._clickOutsideEnabled = true;
        this.markForCheck();
      }, 200);
    } else {
      this._clickOutsideEnabled = false;
      this.markForCheck();
    }
  }

  public closeAlert(e: Event) {
    this.closed.emit(true);
  }
}
