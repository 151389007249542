import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { OnboardingQrCodeComponent } from './onboarding-qr-code.component';

@NgModule({
  declarations: [OnboardingQrCodeComponent],
  imports: [CommonModule],
  exports: [OnboardingQrCodeComponent],
})
export class OnboardingQrCodeModule {}
