/// <reference types="@types/dom-mediacapture-record" />

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { logger } from '@core/helpers/logger';
import { FilesService, IconsService, IFileType, PlatformService, ScreenService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { FilePurposeEnum, SourceTypeEnum } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-record-video',
  templateUrl: './record-video.component.html',
  styleUrls: ['./record-video.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecordVideoComponent),
      multi: true,
    },
  ],
})
export class RecordVideoComponent extends ChangableComponent implements OnChanges, OnDestroy {
  @ViewChild('video', { static: false }) videoElement: ElementRef;
  @ViewChild('recordedVideo') recordedEl: ElementRef;
  @Input() file_id: string;
  @Input() is_disabled: boolean;
  @Input() otherOnAir: boolean;
  @Output() fileChanged = this.register(new EventEmitter<string>());
  @Output() recordStarted = this.register(new EventEmitter<boolean>());
  @Output() overwrite = this.register(new EventEmitter<boolean>());

  isRecording = false;
  isLessVideo: boolean;
  isBigVideo: boolean;
  isDisabled: boolean;
  cameraLoad = true;
  recordingDuration: Date;
  mediaUrl: string;
  loading: boolean;
  fileLoading: boolean;
  noCamera: boolean;
  isSafari = this.screenService.isSafari;
  svgSetIcon = IconsService.svgsetIconUrl;
  showMicSetting = false;

  private isRecordingEnabled: boolean;
  private recorder: MediaRecorder;
  private $destroy = this.register(new Subject());

  constructor(
    public deviceService: DeviceDetectorService,
    protected filesService: FilesService,
    protected screenService: ScreenService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.$destroy.next(true);
    if (this.videoElement) {
      this.onDestroyRecordVideo(this.videoElement.nativeElement);
    }
  }

  onDestroyRecordVideo(videoElem) {
    const stream = videoElem.srcObject;
    this.stop(stream);
    videoElem.srcObject = null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.file_id) {
      if (this.file_id) {
        this.filesService
          .getFileById(this.file_id)
          .pipe(takeUntil(this.$destroy))
          .subscribe((file) => {
            this.mediaUrl = this.pickMedalUrl(file);
            if (this.mediaUrl) {
              this.setTimeout(() => {
                this.recordedEl.nativeElement.src = this.mediaUrl;
              }, 1000);
            }
          });
      } else {
        this.showLiveVideo();
      }
    } else {
      this.showLiveVideo();
    }
  }

  private propagateChange: any = () => {};

  writeValue(value: string): void {
    this.mediaUrl = value;
    this.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
    this.markForCheck();
  }

  registerOnTouched(fn: any): void {}

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.markForCheck();
  }

  onChanged(value: string): void {
    this.mediaUrl = value;
    this.propagateChange(value);
    this.fileChanged.emit(value);
    this.markForCheck();
  }

  startRecording(stream) {
    this.recorder = new MediaRecorder(stream);
    const data = [];
    this.recorder.ondataavailable = (event) => data.push(event.data);
    this.recorder.start();

    const stopped = new Promise((resolve, reject) => {
      this.recorder.onstop = resolve;
      this.recorder.onpause = resolve;
      this.recorder.onerror = (event) => reject(event);
    });

    return Promise.all([stopped]).then(() => data);
  }

  onAccessClick(): void {
    this.showMicSetting = true;
  }

  onCloseSettingModal(): void {
    this.showMicSetting = false;
  }

  onClickStart() {
    this.isRecording = true;
    this.isLessVideo = false;
    this.isBigVideo = false;
    this.recordStarted.emit(true);
    this.otherOnAir = false;
    const recordingStart = new Date();
    const intervalId = this.setInterval(() => {
      this.recordingDuration = new Date(
        0,
        0,
        0,
        0,
        new Date().getMinutes() - recordingStart.getMinutes(),
        new Date().getSeconds() - recordingStart.getSeconds(),
      );
      this.detectChanges();
    }, 1000);
    this.startRecording(this.videoElement.nativeElement.captureStream())
      .then((recordedChunks) => {
        // после завершения записи (отображение в представлении, ссылка для скачивания)
        this.clearInterval(intervalId);
        const recordedBlob = new Blob(recordedChunks, { type: 'video/mp4' });

        this.recordingDuration = null;
        this.isRecording = false;
        this.markForCheck();
        this.saveFile(recordedBlob);
      })
      .catch(console.error);
  }

  onClickStop() {
    this.recorder.stop();
    const min = this.recordingDuration.getMinutes() + this.recordingDuration.getSeconds() / 60;
    this.isBigVideo = min > 2;
    this.isLessVideo = min < 0.5;
    this.isRecordingEnabled = false;
    this.recordStarted.emit(false);
    this.markForCheck();
  }

  onClickOverwrite() {
    this.mediaUrl = null;
    this.cameraLoad = true;
    this.isRecording = false;
    this.overwrite.emit(true);
    if (!this.isRecordingEnabled) {
      this.turnOnCamera();
    }
    this.markForCheck();
  }

  stop(stream) {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
  }

  /**
   * выбрать наиболее подходящий формат
   */
  protected pickMedalUrl(file: IFileType): string {
    return file.storage_file_url;
    // return file.media.find((_file) => _file.format === MediaFormatEnum.mp4)?.url;
  }

  /**
   * save blob video
   */
  private saveFile(recordedBlob: Blob): void {
    if (this.isLessVideo || this.isBigVideo) {
      this.showLiveVideo();
      return;
    }
    this.fileLoading = true;
    this.filesService
      .uploadFileAsBlob(recordedBlob, {
        target: 'video',
        purpose: FilePurposeEnum.profileVideo,
        source: SourceTypeEnum.webcam,
        filename: 'RecordedVideo.mp4',
      })
      .pipe(takeUntil(this.$destroy))
      .subscribe((file) => {
        this.mediaUrl = this.pickMedalUrl(file);
        if (this.mediaUrl && this.recordedEl) {
          this.recordedEl.nativeElement.src = this.mediaUrl;
        }
        this.fileLoading = false;
        this.fileChanged.emit(file.id);
        this.markForCheck();
      });
  }

  /**
   * turn on camera
   */
  private turnOnCamera(): void {
    if (this.isRecording) {
      return;
    }
    navigator.mediaDevices
      ?.getUserMedia({ video: true, audio: true })
      .then((stream) => {
        // получение стрима с камеры
        if (this.videoElement) {
          const videoEl = this.videoElement.nativeElement;
          this.isRecordingEnabled = true;
          videoEl.srcObject = stream;
          videoEl.captureStream = videoEl.captureStream || videoEl.mozCaptureStream;
        }
      })
      .catch(() => {
        this.noCamera = true;
      })
      .finally(() => {
        this.cameraLoad = false;
        this.detectChanges();
      });
  }

  /**
   * turn on video
   */
  private showLiveVideo(): void {
    this.detectChanges();
    if (!this.mediaUrl && this.videoElement) {
      this.turnOnCamera();
    }
  }
}
