import { Injectable, Injector } from '@angular/core';
import { MeetingDetailsType, SingleEvent } from 'lingo2-models';
import { Observable } from 'rxjs';
import { EntityExtenderService, ExtendExtendableType } from '../entity-extender.service';
import { fullUserServiceDetails } from '../lingo2-content/user-services.service';

/**
 * Сервис для дополнения сущностей другими связанными сущностями
 */
@Injectable({
  providedIn: 'root',
})
export class SimpleEventExtenderService extends EntityExtenderService {
  protected userServiceDetails = fullUserServiceDetails;
  protected meetingDetails: MeetingDetailsType[] = [
    'id',
    'slug',
    'title',
    'cover',
    'author:md',
    'subject',
    'type',
    // 'level',
    'language',
    'begin_at',
    'end_at',
    'duration',
    'description',
    // 'keywords',
    'options',
    'reservations_count',
    'participants:sm',
    'participants_limit',
    'participants_count',
    'charging',
    'price',
    'currency_id',
    'visit_info',
    'can',
    'is',
    'has',
    'category_id',
    // 'published_at',
    'created_at',
    'status',
    'started_at',
    'finished_at',
    'user_service_id',
    'user_service',
    'classroom',
    'options',
  ];

  public constructor(protected injector: Injector) {
    super(injector);
  }

  public extend$(events: SingleEvent[], details: ExtendExtendableType[] = []): Observable<SingleEvent[]> {
    return super.extend$(events, details) as Observable<SingleEvent[]>;
  }

  // protected mapper(item: SingleEvent, extensions: Extensions): SingleEvent {
  //   ?
  // }
}
