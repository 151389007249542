<section class="first-contact-faq" [class.mobile]="deviceService.isMobile()">
  <h2 class="title">FAQ</h2>

  <ul class="list">
    <li *ngFor="let item of faqList(); let i = index"
        class="list-item"
        [class.selected]="selectedFaqItem === i"
        (click)="onSelect(i)">
      <h4 class="question">{{ item.question | translate }}</h4>
      <p class="answer">{{ item.answer | translate }}</p>
    </li>
  </ul>
</section>
