<div class="slide-image">
  <input type="image"
    *ngIf="imageUrl"
    placeholder="Image Placeholder"
    class="image-input"
    [src]="imageUrl" alt="">

  <span *ngIf="!imageUrl">{{ 'constructor2.slide-image.label' | translate}}</span>

  <app-blocking-spinner [@spinner] *ngIf="isLoading"></app-blocking-spinner>

  <div class="actions" [ngClass]="actionsClasses" *ngIf="imageUrl">
    <div class="select-image" (click)="openMediaModal($event)">
      <svg viewBox="0 0 36 29">
        <use [attr.xlink:href]="svgsetIcon('sidebar-constructor')"></use>
      </svg>
    </div>
    <div class="crop-image" (click)="openCropModal($event)">
      <svg viewBox="0 0 36 29">
        <use [attr.xlink:href]="svgsetIcon('crop')"></use>
      </svg>
    </div>
  </div>

  <app-media-file-selector
    *ngIf="isModalOpened"
    type="image"
    [purpose]="filePurpose"
    (changed)="setImage($event)"
    (closed)="closeMediaModal()"
  ></app-media-file-selector>

  <app-image-crop
    *ngIf="showCropImage"
    [imageURL]="image.url"
    [ratio]="aspectRatio"
    (changeImage)="onImageChange($event)">
  </app-image-crop>
</div>
