<div class="verification-widget" *ngIf="isVisible"
     [class.compact]="isCompact"
     [class.mini]="isMini"
     [class.mini-header]="isMiniHeader"
     [class.mobile]="deviceService.isMobile()">
  <div class="user">
    <app-verification-widget-user [verification_status]="verification_status"
                                  [user]="user"
                                  [displayMode]="view"
                                  [showUserName]="true">
    </app-verification-widget-user>
  </div>

  <div class="status">
    <app-verification-widget-status [verification_status]="verification_status"
                                    [displayMode]="view">
    </app-verification-widget-status>

    <ng-template [ngIf]="deviceService.isMobile()">
      <ng-container *ngTemplateOutlet="actions"></ng-container>
    </ng-template>
  </div>

  <ng-template [ngIf]="!deviceService.isMobile()">
    <ng-container *ngTemplateOutlet="actions"></ng-container>
  </ng-template>

  <app-verification-widget-spinner *ngIf="isLoading"></app-verification-widget-spinner>
</div>

<ng-template #actions>
  <div class="actions" *ngIf="verification_status !== verifications.not_verified">
    <span *ngIf="action === 'send'"
          class="a-btn-verification-send"
          (click)="sendToVerification()">
      {{ 'verification-widget.actions.send' | translate }}
    </span>
    <span *ngIf="action === 'cancel'"
          class="a-btn-verification-cancel"
          data-test="verification-cancel-btn"
          (click)="cancelVerification()">
      {{ 'verification-widget.actions.cancel' | translate }}
    </span>
    <span *ngIf="verification_status ===  verifications.approved && !isMiniHeader && !isMini">
      <a routerLink="/profile" class="edit-link normal">{{ 'verification-widget.edit-link' | translate }}</a>
    </span>
  </div>
</ng-template>
