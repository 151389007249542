<app-base-notification [base]="base">

  <ng-container [ngSwitch]="params.meeting_type">
    <span *ngSwitchCase="meetingType.single">
      <div [innerHTML]="'notifications.meeting_started.text' | translate: {'teacher': teacher}"></div>
    </span>

    <span *ngSwitchCase="meetingType.group">
      <div [innerHTML]="'notifications.meeting_started.text_group' | translate: {'teacher': teacher, 'title': title}"></div>
    </span>

    <span *ngSwitchCase="meetingType.webinar">
      <div [innerHTML]="'notifications.meeting_started.text_group' | translate: {'teacher': teacher, 'title': title}"></div>
    </span>

    <span *ngSwitchCase="meetingType.stream">
      <div [innerHTML]="'notifications.meeting_started.text_group' | translate: {'teacher': teacher, 'title': title}"></div>
    </span>
  </ng-container>

  <on-ui-button class="primary"
                [label]="'notifications.meeting_started.action'"
                [routerLink]="params.meeting_join_url | relativeUrl">
  </on-ui-button>

</app-base-notification>

