import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

// компоненты
import { TranslateModule } from '@ngx-translate/core';
import { CircleToSquareSpinnerComponent } from './circle-to-square-spinner/circle-to-square-spinner.component';
import { ClockSpinnerComponent } from './clock-spinner/clock-spinner.component';
import { LoadingBarComponent } from './loading-bar/loading-bar.component';
import { LoadingBarService } from './loading-bar/loading-bar.service';
import { ServerLoadingBarService } from './loading-bar/server-loading-bar.service';
import { LoadingLine } from './loading-line';
import { Spinner1 } from './spinner1';
import { Spinner2 } from './spinner2';
import { Spinner3 } from './spinner3';

// сервисы

@NgModule({
  declarations: [
    Spinner1,
    Spinner2,
    Spinner3,
    LoadingLine,
    CircleToSquareSpinnerComponent,
    ClockSpinnerComponent,
    LoadingBarComponent,
  ],
  imports: [CommonModule, TranslateModule],
  exports: [
    Spinner1,
    Spinner2,
    Spinner3,
    LoadingLine,
    CircleToSquareSpinnerComponent,
    ClockSpinnerComponent,
    LoadingBarComponent,
  ],
  providers: [LoadingBarService],
})
export class SpinnersModule {
  static forServer(): ModuleWithProviders<SpinnersModule> {
    return {
      ngModule: SpinnersModule,
      providers: [{ provide: LoadingBarService, useClass: ServerLoadingBarService }],
    };
  }
}
