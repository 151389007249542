import { Injectable } from '@angular/core';
import { BillingService, PaywallModalOptions } from '@app/core/services';
import { SendToModalData } from '@app/library/panes/grid-card-universal/grid-card.service';
import { DestroyableComponent } from '@app/models/destroyable.component';
import { Store } from '@ngrx/store';
import {
  closeMeetingRescheduleWindow,
  loadMyBillingPlan,
  openMeetingRescheduleWindow,
} from '@store/actions/profile.actions';
import { getMeetingRescheduleId } from '@store/reducers/profile.reducer';
import { BehaviorSubject, filter, map, Observable, takeUntil } from 'rxjs';

export interface UserServiceCheckoutOptions {
  /** @see UserServiceCheckoutWizardDialogComponent.teacherId */
  teacherId?: string;

  /** @see UserServiceCheckoutWizardDialogComponent.userServiceId */
  userServiceId?: string;

  /** Откуда вызвали */
  caller?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ApplicationDialogsService extends DestroyableComponent {
  /**
   * состояния диалогов можно хранить тут, а не в store
   */

  public userServiceCheckoutOptions$: Observable<UserServiceCheckoutOptions>;
  public userServiceCheckoutOpened$: Observable<boolean>;
  private _userServiceCheckoutOptions = new BehaviorSubject<UserServiceCheckoutOptions>(null);

  public gridCardModalOptions$: Observable<SendToModalData>;
  public gridCardModalOpened$: Observable<boolean>;
  private _gridCardModalOptions = new BehaviorSubject<SendToModalData>(null);

  public paywallModalOptions$: Observable<PaywallModalOptions>;
  public paywallModalOpened$: Observable<boolean>;
  private _paywallModalOptions = new BehaviorSubject<PaywallModalOptions>(null);

  public constructor(protected readonly billingService: BillingService, protected readonly store: Store) {
    super();

    this.userServiceCheckoutOptions$ = this._userServiceCheckoutOptions.asObservable();
    this.userServiceCheckoutOpened$ = this._userServiceCheckoutOptions.pipe(map((options) => !!options));

    this.gridCardModalOptions$ = this._gridCardModalOptions.asObservable();
    this.gridCardModalOpened$ = this._gridCardModalOptions.pipe(map((options) => !!options));

    this.paywallModalOptions$ = this._paywallModalOptions.asObservable();
    this.paywallModalOpened$ = this._paywallModalOptions.pipe(map((options) => !!options));

    this.billingService.paywallOptions$
      .pipe(
        filter((options) => !!options),
        takeUntil(this.destroyed$),
      )
      .subscribe((options) => this.openPaywallModal(options));
  }

  public get meetingToRescheduleId$(): Observable<string> {
    // @todo зачем для этого использовать store?
    return this.store.select(getMeetingRescheduleId);
  }

  /** Открыть диалог переноса времени митинга */
  public openRescheduleMeetingModal(meeting_id: string) {
    // @todo зачем для этого использовать store?
    this.store.dispatch(openMeetingRescheduleWindow({ meeting_id }));
  }

  /** Закрыть диалог переноса времени митинга */
  public closeRescheduleMeetingModal() {
    // @todo зачем для этого использовать store?
    this.store.dispatch(closeMeetingRescheduleWindow());
  }

  /** Открыть диалог покупки услуги UserServiceCheckoutWizardDialogComponent */
  public openUserServiceCheckoutWizard(options: UserServiceCheckoutOptions) {
    if (!options.userServiceId && !options.teacherId) {
      console.error('Invalid UserServiceCheckoutOptions: ' + JSON.stringify(options));
      throw new Error('Invalid UserServiceCheckoutOptions');
    }
    this._userServiceCheckoutOptions.next(options);
  }

  /** Закрыть диалог покупки услуги UserServiceCheckoutWizardDialogComponent */
  public closeUserServiceCheckoutWizard() {
    this._userServiceCheckoutOptions.next(null);
  }

  /** Открыть модалку карточек */
  public openGridCardModal(options: SendToModalData) {
    if (!options.data && !options.type && !options.state) {
      console.error('Invalid GridModalOptions: ' + JSON.stringify(options));
      throw new Error('Invalid GridModalOptions');
    }
    this._gridCardModalOptions.next(options);
  }

  public openPaywallModal(options: PaywallModalOptions) {
    if (!options.caller || !options.reason) {
      console.error('Invalid PaywallModalOptions: ' + JSON.stringify(options));
      throw new Error('Invalid PaywallModalOptions');
    }
    this._paywallModalOptions.next(options);
  }

  public closePaywallModal() {
    this._paywallModalOptions.next(null);
    this.store.dispatch(loadMyBillingPlan());
  }
}
