import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ContextService } from '@core/services';
import { User } from 'lingo2-models';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { IListPanelOption } from '../models';
import { OnboardingStatusesEnum as OSE } from '../onboarding-widget.component';

import { OnboardingWidgetService } from '../onboarding-widget.service';
import { IChecklistOption } from '../shared/onboarding-checklist/onboarding-checklist.component';

@Component({
  selector: 'app-onboarding-on-moderation',
  templateUrl: './onboarding-on-moderation.component.html',
  styleUrls: ['./onboarding-on-moderation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingOnModerationComponent implements OnInit, OnDestroy {
  @Input() set me(me: User) {
    this.onboardingWidgetService.getCheckOptions(me).then((res) => {
      this.checklistOptions = res;
      this.cdr.detectChanges();
    });
    this.panelOptions = this.onboardingWidgetService.generateListPanelOptions(me);
  }

  public status = OSE.on_moderation;
  public isExpanded$ = this.onboardingWidgetService.isStatusExpanded$;

  public checklistOptions: IChecklistOption[];
  public panelOptions: IListPanelOption[];

  public verification_sent_at: Date;

  private ngUnsubscribe = new Subject();

  constructor(
    private contextService: ContextService,
    private cdr: ChangeDetectorRef,
    private onboardingWidgetService: OnboardingWidgetService,
  ) {}

  ngOnInit() {
    this.contextService.profile$
      .pipe(
        filter((x) => !!(x && x.verification_sent_at)),
        map((x) => x.verification_sent_at),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((verification_sent_at) => {
        this.verification_sent_at = verification_sent_at;
        this.cdr.markForCheck();
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
