import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { ClassroomNewInvitation2NoticeParams, INoticeParams } from 'lingo2-models';
import { IBaseNotification, EModifier } from '../../base-notification/base-notification.component';

@Component({
  selector: 'app-classroom-new-invitation-2',
  templateUrl: './classroom-new-invitation-2.component.html',
  styles: [''],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClassroomNewInvitation2Component {
  public params: ClassroomNewInvitation2NoticeParams;
  public base: IBaseNotification;

  @Input() set notification(notification: INoticeParams) {
    this.params = ClassroomNewInvitation2NoticeParams.createInstance(notification.params);

    this.base = {
      title: 'notifications.classroom_new_invitation_2.title',
      icon: 'notice-invite',
      created_at: notification.created_at,
      modifier: EModifier.yellow,
    };
  }

  public get user_fullname() {
    return `<a class='user-fullname-link' target='_blank' href='${this.params.user_url}'>${this.params.user_fullname}</a>`;
  }

  public get classroom_title() {
    return `<a class='link-base' href='${this.params.classroom_url}'>${this.params.classroom_title}</a>`;
  }
}
