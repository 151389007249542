import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
  SocialUser,
} from '@abacritt/angularx-social-login';
import { DOCUMENT, Location } from '@angular/common';
import { Component, Inject, OnInit, NgZone } from '@angular/core';
import {
  NavigationCancel,
  Event as NavigationEvent,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { LoadingBarService } from '@core/components/spinners/loading-bar/loading-bar.service';
import { logger } from '@core/helpers/logger';
import {
  MetaService,
  LanguageService,
  AuthService,
  ContextService,
  ConfigService,
  AnalyticsService,
  BillingService,
  CrispChatService,
  ScreenService,
  IconsService,
  RequestService,
  PlatformService,
  FeaturesService,
} from '@core/services/';
import { FacebookAppId, GoogleClientId } from '@core/services/social-auth.service';
import { WebsocketService } from '@core/websocket';
import { environment } from '@env/environment';
import { DestroyableComponent } from '@models/destroyable.component';
import { WINDOW } from '@ng-web-apis/common';
import { Store } from '@ngrx/store';
import * as ContentAction from '@store/actions/content.actions';
import * as FeaturingActions from '@store/actions/featuring.actions';
import * as ProfileAction from '@store/actions/profile.actions';
import { setProfile } from '@store/actions/profile.actions';
import * as UserAction from '@store/actions/users.actions';
import { getMyProfile } from '@store/reducers/profile.reducer';
import { resetMe } from 'lingo2-chat-app';
import { User, Language, SocialNetworkEnum, ISocialSignupRequest } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { combineLatest, Observable, of, filter, takeUntil, tap } from 'rxjs';
import { catchError, distinctUntilChanged, first, pluck, switchMap } from 'rxjs/operators';
import { ServiceWorkerMainComponent } from './core/services/sw/sw-main.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends DestroyableComponent implements OnInit {
  private inited = false;
  private meId: string;
  private initialNavigationDone = false;
  private splashLoaderRemoved = false;

  public constructor(
    private authService: AuthService, // dont remove, must be created
    private socialAuthService: SocialAuthService,
    private billingService: BillingService, // dont remove, must be created
    private analytics: AnalyticsService, // dont remove, must be created
    // Server requirements
    @Inject(WINDOW) private readonly windowRef: Window,
    private languageService: LanguageService,
    private requestService: RequestService,
    private contextService: ContextService,
    private configService: ConfigService,
    private loadingBar: LoadingBarService,
    private deviceService: DeviceDetectorService,
    private router: Router,
    private store: Store,
    @Inject(DOCUMENT) private document: any,
    private crisp: CrispChatService,
    private icons: IconsService,
    private screenService: ScreenService,
    private location: Location,
    private websocketService: WebsocketService,
    private metaService: MetaService,
    private zone: NgZone,
    private serviceWorker: ServiceWorkerMainComponent,
    protected readonly platform: PlatformService,
  ) {
    super(platform);
  }

  protected get watchMe$(): Observable<User> {
    return this.authService.user$.pipe(
      tap((me) => {
        if (me && this.meId !== me.id) {
          this.meId = me.id;
          this.billingService.setUser(me);
        }
      }),
    );
  }

  protected get watchLanguage$(): Observable<Language> {
    return this.languageService.language$.pipe(
      tap((language) => {
        this.metaService.updateLanguage(language);
        this.store.dispatch(ProfileAction.setLanguages({ languages: [language.code] }));
        this.configService.onLocaleChanged(language.code); // TODO загружает много данных, отрефакторить
      }),
    );
  }

  protected get watchLanguages$(): Observable<Language[]> {
    return this.languageService.languages$.pipe(
      tap((languages) => {
        this.metaService.setLanguages(languages);
      }),
    );
  }

  public ngOnInit(): void {
    this.socialAuthService.authState
      .pipe(
        tap((socialuser) => {
          logger.log('socialauthservice login success', socialuser);
          if (socialuser) {
            this.completeSocialLogin(socialuser);
          } else {
            this.authService.logOut(true).then(() => {});
          }
        }),
        catchError((error) => {
          logger.log('socialauthservice login error', error);
          return of(null);
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe();

    this.reflectEnvInHtml();

    if (this.isBrowser) {
      this.loadingBar.navigationStart(10);
      this.setTimeout(() => this.loadingBar.navigationProgress(25), 250);
      this.setTimeout(() => this.loadingBar.navigationComplete(), 500);
    } else {
      this.removeSplashLoader();
    }

    combineLatest([this.watchMe$, this.watchLanguage$, this.watchLanguages$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.ngOnInitContinue());

    this.router.events
      .pipe(tap((event) => this.updateNavigationLoadingbar(event)))
      .pipe(takeUntil(this.destroyed$))
      .subscribe();
  }

  /** Продолжение ngOnInit */
  private ngOnInitContinue() {
    if (this.inited) {
      return;
    }
    this.inited = true;

    this.enableAngularRouting();
    this.removeSplashLoader();

    if (this.isBrowser) {
      this.websocketService.onAccountUpdate.pipe(takeUntil(this.destroyed$)).subscribe((message) => {
        /** Обновление учётной записи */
        const _user = { id: message.account_id } as User;
        this.authService.refreshUser(_user);
      });

      this.websocketService.onBalanceUpdate
        .pipe(
          /** Обновление финансовых настроек */
          switchMap(() => this.billingService.getSettings$(true)),
          takeUntil(this.destroyed$),
        )
        .subscribe();

      this.websocketService.onVerification.pipe(takeUntil(this.destroyed$)).subscribe((message) => {
        /** Обновление статуса верификации профиля */
        this.store
          .select(getMyProfile)
          .pipe(first())
          .pipe(takeUntil(this.destroyed$))
          // eslint-disable-next-line rxjs/no-nested-subscribe
          .subscribe((profile) => {
            profile.verification_status = message.verification_status;
            this.store.dispatch(setProfile({ profile }));
          });
      });

      this.router.events.pipe(takeUntil(this.destroyed$)).subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (event.url === '/auth/signout') {
            this.store.dispatch(ProfileAction.userLogout());
            this.store.dispatch(ContentAction.clearContent());
            this.store.dispatch(FeaturingActions.clearFeaturing());
            this.store.dispatch(UserAction.clearUser());
            this.store.dispatch(resetMe({}));
          }
        }
      });

      this.patchUiLanguage();

      this.websocketService.onReferenceUpdate.pipe(takeUntil(this.destroyed$)).subscribe((update) => {
        /** Перезагрузка изменённого справочника */
        this.configService.onVersionChanged(update.entity, update.version);
      });

      this.websocketService.status$
        .pipe(
          switchMap((isOnline) => {
            if (isOnline) {
              return this.billingService.getSettings$(true);
            } else {
              return of(null);
            }
          }),
          takeUntil(this.destroyed$),
        )
        .subscribe();

      this.crisp.init();

      this.icons.inject();
    }
  }

  /**
   * Для пользователей, которые ни разу не указывали свой язык интерфейса - сохранить в базу данных
   * текущий язык интерфейса как основной
   */
  private patchUiLanguage() {
    combineLatest([
      this.watchMe$.pipe(
        filter((me) => !!me),
        pluck('ui_language'),
        distinctUntilChanged(),
      ),
      this.watchLanguage$,
    ])
      .pipe(
        tap(([my_locale, language]) => {
          if (my_locale === null) {
            this.languageService.changeLanguage(language, false);
          }
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  /** немного поменять интерфейс, чтобы отличить сервер от продакшена */
  private reflectEnvInHtml() {
    const env = environment.env;
    if (this.isBrowser && env !== 'production') {
      this.zone.runOutsideAngular(() => {
        const body = document.getElementsByTagName('body')[0];
        body.classList.add(env);

        const faviconUrl = this.requestService.host + `/favicon-${env}.png`;
        document.querySelectorAll("link[rel='icon']").forEach((node) => node.parentNode.removeChild(node));
        document.querySelectorAll("link[rel='shortcut icon']").forEach((node) => node.parentNode.removeChild(node));
        const favicon = document.createElement('link');
        favicon.rel = 'shortcut icon';
        favicon.type = 'image/png';
        favicon.href = faviconUrl;
        document.head.appendChild(favicon);
      });
    }
  }

  private updateNavigationLoadingbar(event: NavigationEvent) {
    if (event instanceof NavigationStart) {
      this.loadingBar.navigationStart(25);
    }
    if (event instanceof NavigationEnd) {
      if (event.url.split('/')[1] !== 'u') {
        document.querySelector('html').scrollTop = 0;
      }
      this.loadingBar.navigationComplete();
    }
    if (event instanceof NavigationCancel) {
      this.loadingBar.navigationStop();
    }
    if (event instanceof NavigationError) {
      this.loadingBar.navigationStop();
    }
  }

  private enableAngularRouting() {
    if (!this.initialNavigationDone) {
      this.initialNavigationDone = true;
      this.router.initialNavigation();
    }
  }

  private removeSplashLoader() {
    if (!this.splashLoaderRemoved) {
      this.splashLoaderRemoved = true;
      this.zone.runOutsideAngular(() => {
        const splash = this.document.getElementById('splash-loader');
        if (splash) {
          splash.remove();
        }
        const body = this.document.getElementsByTagName('body')[0];
        body.classList.remove('splash-loader');
      });
    }
  }

  protected completeSocialLogin(socialUser: SocialUser) {
    let { network, network_app_id, access_token } = {} as any;

    switch (socialUser.provider) {
      case GoogleLoginProvider.PROVIDER_ID:
        network = SocialNetworkEnum.google;
        network_app_id = GoogleClientId;
        access_token = socialUser.idToken;
        break;

      case FacebookLoginProvider.PROVIDER_ID:
        network = SocialNetworkEnum.facebook;
        network_app_id = FacebookAppId;
        access_token = socialUser.authToken;
        break;

      default:
        logger.warn(socialUser.provider, 'not implemented');
        return;
    }

    const values: ISocialSignupRequest = {
      network,
      network_app_id,
      network_account_id: socialUser.id,
      email: socialUser.email,
      first_name: socialUser.firstName,
      last_name: socialUser.lastName,
      picture: socialUser.photoUrl,
      access_token,
    };
    this.authService
      .socialSignup(values)
      .then(() => {})
      .catch((error) => {});
  }

  public get isLimitedVersion() {
    return FeaturesService.isLimitedVersion;
  }

  public get isNonWidgetPage(): boolean {
    return this.router.url.includes('/profile') || this.router.url.includes('/constructor') || this.router.url.includes('/content/collections/edit');
  }
}
