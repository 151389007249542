<app-onboarding-user-status
  [onboardingStatus]="status">
</app-onboarding-user-status>

<div class="badges">
  <app-teacher-badges-widget ></app-teacher-badges-widget>
</div>

<div class="expandable" *ngIf="isExpanded$ | async">

  <app-onboarding-title [title]="'onboarding.start-teaching-and-earn' | translate">
  </app-onboarding-title>

  <app-onboarding-checklist
    [options]="checklistOptions">
  </app-onboarding-checklist>

  <app-onboarding-qr-code *ngIf="showMobileQr"></app-onboarding-qr-code>

  <app-onboarding-list-panel [options]="panelOptions" title="onboarding.now-available"></app-onboarding-list-panel>

</div>
