import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideModule } from 'ng-click-outside';
import { SubjectSelectorComponent } from './subject-selector.component';

@NgModule({
  imports: [CommonModule, TranslateModule, ClickOutsideModule, FormsModule, RouterModule],
  declarations: [SubjectSelectorComponent],
  exports: [SubjectSelectorComponent],
})
export class SubjectSelectorModule {}
