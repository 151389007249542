import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { INoticeParams } from 'lingo2-models';
import { EModifier, IBaseNotification } from '../../base-notification/base-notification.component';

@Component({
  selector: 'app-billing-plan-will-be-prolong-soon',
  templateUrl: './billing-plan-will-be-prolong-soon.component.html',
  styles: [''],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillingPlanWillBeProlongSoonComponent {
  public params: INoticeParams;
  public base: IBaseNotification;

  @Input() set notification(notification: INoticeParams) {
    this.params = notification.params;
    this.base = {
      title: 'notifications.billing_plan_will_be_prolong_soon.title',
      icon: 'notice-cancel',
      created_at: notification.created_at,
      modifier: EModifier.yellow,
    };
  }
}
