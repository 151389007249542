<app-sticky [scrollThreshold]="scrollThreshold" [afterScrollThreshold]="0" [onlyMobile]="false">
<div class="header" [class.expand-user-menu]="expandUserMenuState" [class.profile-header]="isProfilePage">
  <app-system-notifications class="translation-section notify"></app-system-notifications>
  <app-suggest-language-change class="translation-section"></app-suggest-language-change>
  <app-timezone-notifier></app-timezone-notifier>
  <app-vacation-informer class="translation-section"></app-vacation-informer>
  <app-suggest-add-card *ngIf="!isMeVersion" class="translation-section"></app-suggest-add-card>
  <div class="row">
    <div class="content">
      <div class="menu" (click)="toggleLeftSidebar()">
        <ng-container *ngIf="isProfilePage && !isAllStepsFilled; else defaultMenu">
          <svg class="left-sidebar-icon unfilled-icon" viewBox="0 0 29 24">
            <use [attr.xlink:href]="svgSetIcon('left-sidebar-menu-unfilled')"></use>
          </svg>
        </ng-container>
        <ng-template #defaultMenu>
          <svg class="left-sidebar-icon" viewBox="0 0 18 16">
            <use [attr.xlink:href]="svgSetIcon('left-sidebar-menu')"></use>
          </svg>
        </ng-template>
        <ng-container *ngIf="isSmallScreen && isAvailableNotifications">
          <div class="unread" *ngIf="unreadNotificationsCount$ | async as unread">
            {{ unread < 99 ? unread : 99 }}
          </div>
        </ng-container>
      </div>

      <div class="brand">
        <a routerLink="/" class="logo" aria-label="OnClass"></a>
      </div>

      <div class="left-spacer">
        <div class="profile-bread-crumbs" *ngIf="isBrowser && (isProfilePage || isConstructorPage) && !deviceService.isMobile()">
          <p (click)="goToMain()">{{ 'library.breadcrumbs.home' | translate }}</p>
          <app-icon class="chevron" [width]="6" [height]="10" [icon]="'chevron-right'"></app-icon>
          <p *ngIf="isProfilePage">{{ 'onboarding.steps-widget.profile' | translate }}</p>
          <p *ngIf="isConstructorPage">{{ 'constructor2.steps.step-1.label' | translate }}</p>
        </div>
        <ng-template [ngIf]="isBrowser && !isSystemPages && (!isCommonLanding || !isGuest)">
          <app-subject-selector></app-subject-selector>
        </ng-template>

        <div *ngIf="isCommonLanding && isGuest && !deviceService.isDesktop()" class="sign-wrap">
          <span (click)="authorize('social')" class="sign-action">{{ 'auth.signin.action1' | translate }}</span>
          <span (click)="authorize('signup')" class="sign-action">{{ 'auth.signup.action1' | translate }}</span>
        </div>
      </div>

      <div *ngIf="!isProfilePage && !isConstructorPage && !isMeVersion"
           #menuHolder
           class="nav" itemscope itemtype="http://schema.org/SiteNavigationElement">
        <span *ngFor="let item of menuItems"
              [attr.title]="item.label | translate">
          <a *ngIf="isMenuItemEnabled(item)"
             class="nav-item"
             itemprop="url"
             [attr.data-test]="item.id"
             [attr.title]="item.label | translate"
             [routerLink]="item.url"
             routerLinkActive="active">
            <div class="nav-item-icon-wrap">
              <svg class="nav-item-icon" [ngClass]="item.id">
                <use [attr.xlink:href]="svgSetIcon(item.icon)"></use>
              </svg>
            </div>
            <div class="nav-item-name medium">{{ item.label | translate }}</div>
          </a>
        </span>

        <span class="nav-item"
              *ngIf="showMoreMenu"
              [appPopover]="popoverContent"
              [styles]="{ background: '#5AB3E4', padding: '10px' }"
              position="bottom"
              [showCloseButton]="false">
          <div class="nav-item-icon-wrap">
            <svg class="nav-item-icon show-more">
              <use [attr.xlink:href]="svgSetIcon('header-more')"></use>
            </svg>
          </div>
          <div class="nav-item-name medium">Ещё</div>
        </span>
      </div>

      <div class="progressbar" *ngIf="isProfilePage && !isOnModify && false">
          <span>{{ 'onboarding.steps-widget.steps-checked' | translate : {
            'checked-steps': checkedSteps ? checkedSteps : 0,
            'all_steps': maxSteps
          } }}</span>
        <app-onboarding-progressbar
          [max]="maxSteps" [value]="checkedSteps"
          [rounded]="true">
        </app-onboarding-progressbar>
        <span
          *ngIf="!deviceService.isMobile()">{{ 'onboarding.steps-widget.percent-checked' | translate : {'percent': percent} }}</span>
      </div>
      <ng-container *ngIf="isProfilePage && isOnModify && !isMeVersion">
        <div class="verification-send-block-on-header" *ngIf="isOnModify === 'send'">
          <p class="verification-send-text"
             *ngIf="!deviceService.isMobile()">{{ 'verification-widget.description.changed' | translate }}</p>
          <on-ui-button [state]="btnState"
                        class="third"
                        data-test="verification-send"
                        label="verification-widget.actions.send"
                        (click)="sendToVerification()">
          </on-ui-button>
        </div>
        <div class="verification-send-block-on-header" *ngIf="isOnModify === 'cancel'">
          <p class="verification-send-text"
             *ngIf="!deviceService.isMobile()">{{ 'verification-widget.description.resent' | translate }}</p>
          <on-ui-button [state]="btnState"
                        class="third"
                        data-test="verification-cancel"
                        label="verification-widget.actions.cancel"
                        (click)="cancelVerification()">
          </on-ui-button>
        </div>
      </ng-container>

      <div class="right-spacer">

        <div class="user" (clickOutside)="options_open = false">
          <ng-template [ngIf]="me && isGuest">
            <div class="auth">
              <span (click)="authorize('social')"
                    data-test="sign-in"
                    class="signin">{{ 'auth.signin.action1' | translate }}</span>
              <span (click)="authorize('signup')"
                    data-test="sign-up"
                    class="signin">{{ 'auth.signup.action1' | translate }}</span>
            </div>
          </ng-template>
          <div class="avatar-status-block" [class.avatar-status-block-mob]="!deviceService.isDesktop()">
            <app-user-menu [me]="me"
                           [verification_status_value]="verification_status"
                           (expandedMenu)="expandedUserMenuState($event)">
            </app-user-menu>
            <app-icon [icon]="isNeedAction"
                      [width]="20"
                      [height]="20"
                      *ngIf="isNeedAction"
                      class="status-icon">
            </app-icon>
          </div>
        </div>

        <ng-template [ngIf]="deviceService.isDesktop()">
          <app-language-selector></app-language-selector>
        </ng-template>
      </div>

      <ng-template [ngIf]="deviceService.isDesktop()">
        <app-currency-selector *ngIf="isAvailableProfileFinance && !isProfilePage"></app-currency-selector>
      </ng-template>
    </div>
  </div>
</div>
</app-sticky>
<ng-template #popoverContent>
  <div class="more-item">
    <span *ngFor="let item of menus"
          [attr.title]="item.label | translate">
    <a *ngIf="isMenuItemEnabled(item)"
       class="nav-item"
       itemprop="url"
       [attr.data-test]="item.id"
       [attr.title]="item.label | translate"
       [routerLink]="item.url" routerLinkActive="active">
      <div class="nav-item-icon-wrap">
        <svg class="nav-item-icon" [ngClass]="item.id">
          <use [attr.xlink:href]="svgSetIcon(item.icon)"></use>
        </svg>
      </div>
      <div class="nav-item-name medium">{{ item.label | translate }}</div>
    </a>
  </span>
  </div>
</ng-template>
