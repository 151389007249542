import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { User } from 'lingo2-models';
import { Observable } from 'rxjs';
import { AuthService } from '../services/';

/**
 *  Добавляет заголовок 'Authorization' для авторизации пользователя
 *  (disabled) Добавляет заголовок 'X-User' для визуальной отладки запросов в случаях, если на клиенте сбои авторизации
 */
@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  authPrefix = 'Bearer';

  constructor(private auth: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = this.auth.accessToken;
    if (!!accessToken) {
      // const user = AuthInterceptor.parseUserFromAccessToken(accessToken);
      request = request.clone({
        setHeaders: {
          // (disabled) 'X-User': user ? `${user.slug}/${user.id}` : 'none', // TODO !production || debug
          Authorization: `${this.authPrefix} ${accessToken}`,
        },
      });
    }
    return next.handle(request);
  }

  public static parseUserFromAccessToken(token: string): User {
    try {
      const token_decoded = jwtDecode<any>(token);
      if (!token_decoded.subject || !token_decoded.subject.id) {
        console.error('Lingo2AuthTokenInterceptor:parseUserFromAccessToken', 'token is wrong', token);
      }
      return new User(token_decoded.subject);
    } catch (e) {
      console.error('Lingo2AuthTokenInterceptor:parseUserFromAccessToken', e);
    }
  }
}
