<app-onboarding-user-status
  [onboardingStatus]="status"
  >
</app-onboarding-user-status>

<div class="expandable" *ngIf="isExpanded$ | async">

  <app-onboarding-title [title]="'onboarding.access-knowledge' | translate">
  </app-onboarding-title>

  <app-onboarding-checklist
    [options]="checklistOptions">
  </app-onboarding-checklist>

  <div class="buttons">
    <on-ui-button class="primary"
                  label="onboarding.sign-in"
                  (onclick)="signIn()">
    </on-ui-button>
  </div>

  <app-onboarding-list-panel [options]="panelOptions" title="onboarding.what-authorized-can"></app-onboarding-list-panel>

</div>
