import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AccountService, UserServiceContractsService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { AnyType, ContractSignedUpNoticeParams, EUserServiceType, INoticeParams } from 'lingo2-models';
import { takeUntil } from 'rxjs/operators';
import { IBaseNotification, EModifier } from '../../base-notification/base-notification.component';

@Component({
  selector: 'app-contract-signed-up',
  templateUrl: './contract-signed-up.component.html',
  styleUrls: ['./contract-signed-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractSignedUpComponent extends ChangableComponent {
  public params: ContractSignedUpNoticeParams;
  public base: IBaseNotification;
  public studentLink: string;
  public studentName: string;

  @Input() set notification(notification: INoticeParams) {
    this.params = ContractSignedUpNoticeParams.createInstance(notification.params);
    this.prepareNotify(notification);
  }

  public constructor(
    private contractService: UserServiceContractsService,
    private accountService: AccountService,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  public get isSingle() {
    return this.params.service_type === EUserServiceType.single;
  }

  public get isCourse() {
    return this.params.service_type === EUserServiceType.course || this.params.service_type === EUserServiceType.mini;
  }

  public get isRegular() {
    return this.params.service_type === EUserServiceType.regular;
  }

  private prepareNotify(notify: INoticeParams) {
    this.base = {
      title: 'notifications.contract-signed-up.title',
      icon: 'notice-clock',
      created_at: notify.created_at,
      modifier: EModifier.blue,
    };

    this.accountService
      .getUserById(this.params.user_id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((user) => {
        this.studentLink = AccountService.accountRoute(user).join('/');
        this.studentName = AccountService.getUserName(user);
        this.detectChanges();
      });
  }
}
