import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { INoticeParams, MeetingComplaintResolvedReporterNoticeParams } from 'lingo2-models';
import { IBaseNotification } from '../../base-notification/base-notification.component';

@Component({
  selector: 'app-meeting-complaint-resolved-reporter',
  templateUrl: './meeting-complaint-resolved-reporter.component.html',
  styles: [''],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeetingComplaintResolvedReporterComponent {
  public params: MeetingComplaintResolvedReporterNoticeParams;
  public base: IBaseNotification;

  @Input() set notification(notification: INoticeParams) {
    this.params = notification.params;
    this.base = {
      title: 'notifications.meeting_complaint_resolved_reporter.title',
      icon: 'notice-rating',
      created_at: notification.created_at,
      modifier: null,
    };
  }

  public get title() {
    return `<a class='link-base' href='${this.params.meeting_join_url}'>${this.params.meeting_title}</a>`;
  }
}
