import { createAction, props } from '@ngrx/store';
import { Lesson, LessonSlide, ILessonSlideSection, LessonSlideContentTypeEnum } from 'lingo2-models';

import { ILessonComplete } from '../../content-form-page/_models';
import { ILessonVisitInfo } from './lesson.models';

export const requestFail = createAction('[Constructor] Request: fail', props<{ error: string }>());

// Lesson CRUD

export const loadLesson = createAction('[Constructor] Load lesson', props<{ id: string }>());
export const loadLessonSuccess = createAction('[Constructor] Load lesson: success', props<{ lesson: Lesson }>());

export const createLesson = createAction('[Constructor] Create lesson', props<{ lesson: Partial<Lesson> }>());
export const createLessonSuccess = createAction('[Constructor] Create lesson: success', props<{ lesson: Lesson }>());

export const createLessonBySlide = createAction(
  '[Constructor] Create lesson by slide',
  props<{ [key: string]: any }>(),
);
export const createLessonBySlideSuccess = createAction(
  '[Constructor] Create lesson by slide: success',
  props<{ lesson: Lesson }>(),
);

export const createLessonBySection = createAction(
  '[Constructor] Create lesson by section',
  props<{ section_type: LessonSlideContentTypeEnum }>(),
);
export const createLessonBySectionSuccess = createAction(
  '[Constructor] Create lesson by section: success',
  props<{ lesson: Lesson }>(),
);

export const updateLesson = createAction(
  '[Constructor] Update lesson',
  props<{ id: string; values: Partial<Lesson> }>(),
);
export const updateLessonFail = createAction('[Constructor] Update lesson: fail', props<{ error: string }>());
export const updateLessonSuccess = createAction('[Constructor] Update lesson: success', props<{ lesson: Lesson }>());

export const removeLesson = createAction('[Constructor] Remove lesson', props<{ id: string }>());
export const removeLessonSuccess = createAction('[Constructor] Remove lesson: success', props<{ lesson: Lesson }>());

export const setLesson = createAction('[Constructor] Set lesson', props<{ lesson: Lesson }>());
export const newLesson = createAction('[Content editor] New lesson');
export const clearLesson = createAction('[Content editor] Clear lesson');

export const publishLesson = createAction('[Content editor] Publish lesson', props<{ id: string }>());
export const publishLessonFail = createAction('[Content editor] Publish lesson: fail', props<{ error: string }>());
export const publishLessonSuccess = createAction(
  '[Content editor] Publish lesson: success',
  props<{ lesson: Lesson }>(),
);

export const saveLesson = createAction('[Content editor] Save lesson');

export const validateLesson = createAction('[Content editor] Validate lesson', props<{ validity: ILessonComplete }>());

export const setIsEditing = createAction('[Constructor] Set isEditing', props<{ isEditing: boolean }>());
export const setIsErrorsVisible = createAction(
  '[Constructor] Set isErrorsVisible',
  props<{ isErrorsVisible: boolean }>(),
);

// Slide CRUD

export const loadSlide = createAction('[Constructor] Load slide', props<{ id: string }>());
export const loadSlideSuccess = createAction('[Constructor] Load slide: success', props<{ slide: LessonSlide }>());

export const createSlide = createAction('[Constructor] Create slide', props<{ values?: Partial<LessonSlide> }>());
export const createSlideSuccess = createAction('[Constructor] Create slide: success', props<{ slide: LessonSlide }>());

export const updateSlide = createAction(
  '[Constructor] Update slide',
  props<{ id: string; values: Partial<LessonSlide> }>(),
);
export const updateSlideFail = createAction('[Constructor] Update slide: fail', props<{ error: string }>());
export const updateSlideSuccess = createAction(
  '[Constructor] Update slide: success',
  props<{ id: string; slide: LessonSlide }>(),
);

export const removeSlide = createAction('[Constructor] Remove slide', props<{ id: string }>());
export const removeSlideSuccess = createAction('[Constructor] Remove slide: success', props<{ id: string }>());

export const newSlide = createAction('[Content editor] New slide');

export const copySlide = createAction('[Constructor] Copy slide', props<{ values?: Partial<LessonSlide> }>());
export const copySlideSuccess = createAction('[Constructor] Copy slide: success', props<{ slide: LessonSlide }>());

export const updateSlides = createAction(
  '[Constructor] Update slides',
  props<{ slides: Array<Partial<LessonSlide>> }>(),
);
export const updateSlidesSuccess = createAction(
  '[Constructor] Update slides: success',
  props<{ slides: LessonSlide[] }>(),
);

// Section CRUD

export const loadSection = createAction('[Constructor] Load section', props<{ id: string }>());
export const loadSectionSuccess = createAction(
  '[Constructor] Load section: success',
  props<{ section: ILessonSlideSection }>(),
);

export const createSection = createAction(
  '[Constructor] Create section',
  props<{ slide_id: string; section_type: LessonSlideContentTypeEnum }>(),
);
export const createSectionSuccess = createAction(
  '[Constructor] Create section: success',
  props<{ slide_id: string; section: ILessonSlideSection }>(),
);

export const updateSection = createAction(
  '[Constructor] Update section',
  props<{ id: string; values: Partial<ILessonSlideSection> }>(),
);
export const updateSectionFail = createAction('[Constructor] Update section: fail', props<{ error: string }>());
export const updateSectionSuccess = createAction(
  '[Constructor] Update section: success',
  props<{ id: string; section: ILessonSlideSection }>(),
);

export const removeSection = createAction('[Constructor] Remove section', props<{ id: string }>());
export const removeSectionSuccess = createAction('[Constructor] Remove section: success', props<{ id: string }>());

export const copySection = createAction(
  '[Constructor] Copy section',
  props<{ values?: Partial<ILessonSlideSection> }>(),
);
export const copySectionSuccess = createAction(
  '[Constructor] Copy section: success',
  props<{ section: ILessonSlideSection }>(),
);

export const updateSections = createAction(
  '[Constructor] Update sections',
  props<{ sections: Array<Partial<ILessonSlideSection>> }>(),
);
export const updateValidateSections = createAction(
  '[Constructor] Update validate sections',
  props<{ slide_id: string; show_validate: boolean }>(),
);
export const updateSectionsSuccess = createAction(
  '[Constructor] Update sections: success',
  props<{ sections: ILessonSlideSection[] }>(),
);

// Visit Info

export const setLessonVisitInfo = createAction(
  '[Content editor] Set lesson visit info',
  props<{ visit_info: Partial<ILessonVisitInfo> }>(),
);
