import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createPagedResultsFromResponse } from '@core/helpers/request';
import { IContentBlock } from '@core/services/interfaces';
import { environment } from '@env/environment';
import { DestroyableComponent } from '@models/destroyable.component';
import {
  Classroom,
  ClassroomParticipant,
  IFindClassroomsFilter,
  IPagination,
  IPagedResults,
  IStatusResponse,
  ClassroomParticipantDetailsType,
  ClassroomDetailsType,
  ClassroomParticipantRoleEnum,
  MeetingAcceptStatusEnum,
} from 'lingo2-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountService } from '../lingo2-account/account.service';

export type ClassroomsBlockType = IContentBlock<Classroom, IFindClassroomsFilter>;

export type ClassroomParticipantsBlockType = IContentBlock<ClassroomParticipant, IFindClassroomParticipantsFilter>;

export const defaultClassroomDetails: ClassroomDetailsType[] = [
  'id',
  'slug',
  'author_id',
  'author',
  'author:userpic',
  'access',
  'title',
  'description',
  'stats',
  'type',
  'cover_id',
  'cover:md',
  'participants:some:sm',
  'visit_info',
  'can',
  'last_meeting_id',
  'last_meeting',
  'next_meeting_id',
  'next_meeting',
  'user_service_id',
  // 'user_service',
  'user_service_contract_id',
  // 'user_service_contract',
];

export interface IFindClassroomParticipantsFilter {
  classroom_id: string | string[];
  user_id: string | string[];
  role: ClassroomParticipantRoleEnum | ClassroomParticipantRoleEnum[];
  accept_status: MeetingAcceptStatusEnum | MeetingAcceptStatusEnum[];
}

@Injectable({
  providedIn: 'root',
})
export class ClassroomsService extends DestroyableComponent {
  public constructor(private http: HttpClient) {
    super();
  }

  public classroomFullTitle(classroom: Classroom): string {
    if (!classroom) {
      return null;
    }

    const authorName = AccountService.getUserFullName(classroom.author);

    // title subject date level author | classes -- OnClass
    const titleParts = [classroom.title];
    if (authorName) {
      titleParts.push(', ', authorName);
    }
    return titleParts.join('');
  }

  public static classroomRoute(classroom: Classroom): string[] {
    const title = (classroom?.title || '')
      .replace(/[\\\/:;{}\[\]()_,-.!?'"`+#&=]/g, '')
      .trim()
      .replace(/\s+/g, '-');
    return ['/classrooms', (title.length ? title + '-' : '') + classroom?.slug];
  }

  /**
   * Учебные классы
   */
  public getClassrooms(
    filter: Partial<IFindClassroomsFilter>,
    pagination: IPagination,
    details: ClassroomDetailsType[],
  ): Observable<IPagedResults<Classroom[]>> {
    const url = `${environment.content_url}/classrooms/`;
    const params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter', JSON.stringify(filter))
      .set('details', JSON.stringify(details));
    return this.http.get<Classroom[]>(url, { params, observe: 'response' }).pipe(
      map(this.handleClassroomsResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Один учебный класс по ID
   */
  public getClassroomById(id: string, details: ClassroomDetailsType[]): Observable<Classroom> {
    const url = `${environment.content_url}/classroom/${id}`;
    const params = new HttpParams().set('details', JSON.stringify(details));

    return this.http.get<Classroom>(url, { params, observe: 'response' }).pipe(
      map(this.handleClassroomResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Один учебный класс по URL
   */
  public getClassroomBySlug(slug: string, details: ClassroomDetailsType[]): Observable<Classroom> {
    const _slug = slug.split('-').reverse()[0];
    const url = `${environment.content_url}/classroom/u/${_slug}`;
    const params = new HttpParams().set('details', JSON.stringify(details));

    return this.http.get<Classroom>(url, { params, observe: 'response' }).pipe(
      map(this.handleClassroomResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Участники учебного класса
   */
  public getClassroomParticipants(
    id: string,
    pagination: IPagination,
    details: ClassroomParticipantDetailsType[],
    filter: Partial<IFindClassroomParticipantsFilter> = {},
  ): Observable<IPagedResults<ClassroomParticipant[]>> {
    const url = `${environment.content_url}/classroom/${id}/participants`;
    const params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('details', JSON.stringify(details))
      .set('filter', JSON.stringify(filter));
    return this.http.get<ClassroomParticipant[]>(url, { params, observe: 'response' }).pipe(
      map(this.handleClassroomParticipantsResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Покинуть класс
   */
  public leaveClassroom(id: string): Observable<Partial<Classroom>> {
    const url = `${environment.content_url}/classroom/${id}/participants`;
    return this.http.delete<Classroom>(url, { observe: 'response' }).pipe(
      map(this.handleClassroomResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Выгнать из класса
   */
  public kickFromClassroom(id: string, participantId: string): Observable<Partial<Classroom>> {
    const url = `${environment.content_url}/classroom/${id}/participants/${participantId}`;
    return this.http.delete<Classroom>(url, { observe: 'response' }).pipe(
      map(this.handleClassroomResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Удалить учебный класс
   */
  public deleteClassroom(id: string): Observable<IStatusResponse> {
    const url = `${environment.content_url}/classroom/${id}`;
    return this.http.delete<IStatusResponse>(url, { observe: 'body' });
  }

  /**
   * Восстановить учебный класс
   */
  public restoreClassroom(id: string): Observable<Classroom> {
    const url = `${environment.content_url}/classroom/${id}/restore`;
    return this.http.post(url, {}, { observe: 'body' }).pipe(map(this.handleClassroomResponse));
  }

  /**
   * Создать учебный класс
   */
  public createClassroom(values: Partial<Classroom>): Observable<Classroom> {
    const url = `${environment.content_url}/classroom/`;
    return this.http.post<Classroom>(url, values, { observe: 'response' }).pipe(
      map(this.handleClassroomResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Изменить учебный класс
   */
  public updateClassroom(id: string, values: Partial<Classroom>): Observable<Classroom> {
    const url = `${environment.content_url}/classroom/${id}`;
    return this.http.put<Classroom>(url, values, { observe: 'response' }).pipe(
      map(this.handleClassroomResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Пригласить участников в учебный класс
   */
  public inviteUsers(
    id,
    participants: Array<Partial<ClassroomParticipant>>,
    emails: string[],
    details: ClassroomParticipantDetailsType[],
  ): Observable<[Classroom, ClassroomParticipant[]]> {
    const url = `${environment.content_url}/classroom/${id}/participants/invite`;
    const params = new HttpParams().set('details', JSON.stringify(details));
    return this.http.post(url, { participants, emails }, { params, observe: 'response' }).pipe(
      map((response: any) => {
        const { classroom, participants: _participants } = response.body;
        return [new Classroom(classroom), _participants.map((item) => new ClassroomParticipant(item))];
      }),
      // catchError(this.handleError),
    );
  }

  /**
   * Принять приглашение в учебный класс
   */
  public acceptInvitation(id: string): Observable<[Classroom, ClassroomParticipant]> {
    const url = `${environment.content_url}/classroom/${id}/participants/accept`;
    return this.http.post(url, {}, { observe: 'response' }).pipe(
      map((response: any) => {
        const { classroom, participant } = response.body;
        return [new Classroom(classroom), new ClassroomParticipant(participant)];
      }),
    );
  }

  private handleClassroomResponse(response: HttpResponse<Classroom>): Classroom {
    return new Classroom(response.body);
  }

  private handleClassroomsResponse(response: HttpResponse<Classroom[]>): IPagedResults<Classroom[]> {
    return createPagedResultsFromResponse(response, (item) => new Classroom(item));
  }

  private handleClassroomParticipantsResponse(
    response: HttpResponse<ClassroomParticipant[]>,
  ): IPagedResults<ClassroomParticipant[]> {
    return createPagedResultsFromResponse(response, (item) => new ClassroomParticipant(item));
  }
}
