import { EContentPanelRoute, FeatureEnum } from 'lingo2-models';

export interface IMenuItem {
  name: string;
  width?: string;
  height?: string;
  title?: string;
  route?: string;
  feature?: FeatureEnum;
  separator?: boolean;
}

export const topMenuList: IMenuItem[] = [
  {
    name: 'menu-home',
    title: 'menu.top.main',
    route: '/' + EContentPanelRoute.main,
  },
  // {
  //   name: 'my-feed',
  //   title: 'menu.left-sidebar.my-feed',
  //   route: '/my-feed/feed',
  // },
  {
    name: 'my-classes',
    title: 'menu.left-sidebar.my-classes',
    route: '/my-classes/dashboard',
  },
  {
    name: 'my-library',
    title: 'menu.left-sidebar.my-library',
    route: '/my-library/lessons',
  },
  {
    name: 'my-vocabulary',
    title: 'menu.left-sidebar.my-vocabulary',
    route: '/my-vocabulary/main',
  },
  // {
  //   name: 'separator',
  //   separator: true,
  // },
  // {
  //   name: 'sidebar-classrooms',
  //   title: 'menu.left-sidebar.classrooms',
  //   route: '/classrooms',
  // },
  // {
  //   name: 'sidebar-dashboard',
  //   title: 'menu.left-sidebar.dashboard',
  //   route: '/dashboard/main',
  // },
  // {
  //   name: 'sidebar-events',
  //   title: 'menu.left-sidebar.events',
  //   route: '/dashboard/events',
  // },
  // {
  //   name: 'sidebar-teachers-room',
  //   title: 'menu.left-sidebar.teachers-room',
  //   route: '/me/teacher',
  // },
  // {
  //   name: 'sidebar-collections',
  //   title: 'menu.left-sidebar.collections',
  //   route: '/collections',
  // },
  {
    name: 'sidebar-schedule',
    title: 'menu.left-sidebar.schedule',
    route: '/schedule/calendar',
  },
  // {
  //   name: 'sidebar-constructor',
  //   title: 'menu.left-sidebar.collections',
  //   route: '/constructor',
  // },
  {
    name: 'sidebar-favorites2',
    title: 'menu.left-sidebar.favorites',
    route: '/favorites/lessons',
  },
  {
    name: 'sidebar-notifications',
    title: 'menu.left-sidebar.notifications',
    route: '/notifications',
  },
  // {
  //   name: 'separator',
  //   separator: true,
  // },
  {
    name: 'sidebar-users',
    title: 'users.tabs-subscriptions',
    route: '/users',
  },
  // {
  //   name: 'sidebar-schools',
  //   title: 'menu.left-sidebar.schools',
  //   route: '/schools',
  // },
  {
    name: 'sidebar-quick-class',
    title: 'menu.left-sidebar.study-create',
    // route: '/go/class',
  },
  {
    name: 'sidebar-schedule-class',
    title: 'my-classes.actions.schedule-class',
  },
  {
    /** Специальный пункт меню который выводится как баннер <app-banner-plan> */
    name: 'banner-plan',
  },
];

export const bottomMenuList: IMenuItem[] = [
  // { name: 'sidebar-faq', title: 'menu.left-sidebar.faq', route: '/faq' },
  { name: 'sidebar-crisp', title: 'menu.left-sidebar.support' },
];

export const classesSubmenuList: IMenuItem[] = [
  {
    name: '_',
    title: 'menu.left-sidebar.classes-submenu.webinars',
    route: '/' + EContentPanelRoute.classes,
  },
  {
    name: '_',
    title: 'menu.left-sidebar.classes-submenu.streams',
    route: '/' + EContentPanelRoute.classes,
  },
  {
    name: '_',
    title: 'menu.left-sidebar.classes-submenu.conversation-clubs',
    route: '/' + EContentPanelRoute.classes,
  }, // speaking-clubs
  {
    name: '_',
    title: 'menu.left-sidebar.classes-submenu.group-lessons',
    route: '/' + EContentPanelRoute.classes,
  },
  {
    name: '_',
    title: 'menu.left-sidebar.classes-submenu.private-lessons',
    route: '/' + EContentPanelRoute.classes,
  }, // individual-lessons
  {
    name: '_',
    separator: true,
  },
  {
    name: '_',
    title: 'menu.left-sidebar.classes-submenu.schools',
    route: '/' + EContentPanelRoute.classes,
  },
  {
    name: '_',
    title: 'menu.left-sidebar.classes-submenu.groups',
    route: '/' + EContentPanelRoute.classes,
  },
  {
    name: '_',
    title: 'menu.left-sidebar.classes-submenu.marathons',
    route: '/' + EContentPanelRoute.classes,
  },
  {
    name: '_',
    separator: true,
  },
  {
    name: '_',
    title: 'menu.left-sidebar.classes-submenu.lesson-collections',
    route: '/' + EContentPanelRoute.classes,
  },
  {
    name: '_',
    title: 'menu.left-sidebar.classes-submenu.courses',
    route: '/' + EContentPanelRoute.classes,
  },
];
