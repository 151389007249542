import { Pipe, PipeTransform } from '@angular/core';
import { utcToZonedTime } from 'date-fns-tz';
import { DateFnsInputDate } from 'lingo2-ngx-date-fns';

@Pipe({
  name: 'zonedDate',
  standalone: true,
})
export class ZonedDatePipe implements PipeTransform {
  transform(date: Date | number, timeZone: string): DateFnsInputDate {
    if (date && timeZone) {
      return utcToZonedTime(date, timeZone);
    }
    return date;
  }
}
