import {
  Component,
  OnInit,
  OnDestroy,
  forwardRef,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UtilsService } from '@app/content-editor/content-form-page/_services';
import { ILessonSlideTaskAnswerVariant } from 'lingo2-models';
import { Subject } from 'rxjs';
import { takeUntil, filter, debounceTime } from 'rxjs/operators';

import {
  IVariantPopoverConfig,
  ToolbarItemTypeEnum,
} from '../../task-answer-variant-popover/task-answer-variant-popover.models';

export interface ISlideVariantConfig extends IVariantPopoverConfig {
  isCentered: boolean;
  isStretchable?: boolean;
}

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 || 0;
    const v = c === 'x' ? r : (r && 0x3) || 0x8;
    return v.toString(16);
  });
}
@Component({
  selector: 'app-slide-variant',
  templateUrl: './slide-variant.component.html',
  styleUrls: ['./slide-variant.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SlideVariantComponent),
      multi: true,
    },
  ],
})
export class SlideVariantComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() isError = false;
  @Input() placeholder = '';
  @Input() config: ISlideVariantConfig;
  @Input() fitText = false;

  @Output() changed = new EventEmitter<ILessonSlideTaskAnswerVariant>();
  @Output() deleted = new EventEmitter<void>();
  @Output() checked = new EventEmitter<boolean>();

  public value: ILessonSlideTaskAnswerVariant;
  public isDisabled: boolean;

  public form: UntypedFormGroup;
  public selectedVariantType: ToolbarItemTypeEnum;
  public variantTypes = ToolbarItemTypeEnum;
  public variantActions = false;

  public isModalOpened = false;

  private ngUnsubscribe = new Subject();

  constructor(protected fb: UntypedFormBuilder, protected utilsService: UtilsService) {}

  public ngOnInit(): void {
    this.form = this.fb.group(
      {
        id: [uuidv4()],
        is_correct: [''],
        text: [''],
        image_id: [''],
      },
      { updateOn: 'change' },
    );

    this.form.valueChanges
      .pipe(
        filter((value) => value.text !== this.value.text || value.image_id !== this.value.image_id),
        debounceTime(200),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        this.onChanged();
      });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  private propagateChange: any = () => {};
  private propagateTouched: any = () => {};

  writeValue(value: ILessonSlideTaskAnswerVariant): void {
    this.value = value;
    this.selectedVariantType = this.findSelectedType(value);
    this.form.patchValue(value);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onChanged(): void {
    this.value = this.form.value;
    this.propagateChange(this.value);
    this.changed.emit(this.value);
  }

  onBlur(): void {
    this.propagateTouched();
  }

  private findSelectedType(value: ILessonSlideTaskAnswerVariant): ToolbarItemTypeEnum {
    return value.image_id ? ToolbarItemTypeEnum.image : null;
  }

  public get isRichText() {
    return this.selectedVariantType === ToolbarItemTypeEnum.richText;
  }

  public onCheckIsCorrect() {
    const is_correct = !this.form.value.is_correct;
    this.checked.emit(is_correct);
  }

  public onVariantTypeSelected(type: ToolbarItemTypeEnum): void {
    this.selectedVariantType = type;
    this.isModalOpened = true;
    this.enableActiveControl(type);
    this.onChanged();
  }

  public onVariantDeleted(): void {
    this.deleted.emit();
  }

  public onModalOpened(isOpened: boolean): void {
    this.isModalOpened = isOpened;
  }

  private enableActiveControl(type: ToolbarItemTypeEnum): void {
    if (type === this.variantTypes.image) {
      this.form.controls.text.reset();
      this.form.controls.text.disable();
      this.form.controls.image_id.enable();
    } else {
      this.form.controls.text.enable();
      this.form.controls.image_id.reset();
      this.form.controls.image_id.disable();
    }
  }
}
