import { createAction, props } from '@ngrx/store';
import { CLibraryFilterData } from '@store/models';
import { IContentPane, IResponse } from 'lingo2-models';

export const loadFeaturings = createAction(
  '[Featuring] Load Featurings',
  props<{
    contentFilter: Partial<CLibraryFilterData>;
  }>(),
);

export const loadFeaturingsSuccess = createAction(
  '[Featuring] Load Featurings success',
  props<{ featuring: IResponse<IContentPane[]>; key: string; time: number }>(),
);

export const clearFeaturing = createAction('[Featuring] clear');
