<div class="record-video">
  <div class="video-wrapper" [class.disabled]="isDisabled">
    <div [hidden]="mediaUrl">
      <video class="video"
             data-test="video"
             #video
             autoplay
             [muted]="true"></video>
      <span *ngIf="recordingDuration" class="stopwatch">{{recordingDuration | date:'mm:ss'}}</span>
    </div>
    <div [hidden]="!mediaUrl">
      <video class="video"
             data-test="recorded-video"
             #recordedVideo controls></video>
    </div>
  </div>
  <div class="row" *ngIf="!mediaUrl; else overwrite">
    <on-ui-button class="danger"
                  data-test="start-stop-video"
                  iconPosition="before"
                  [disabled]="isDisabled || fileLoading || cameraLoad"
                  [label]="isRecording ? 'profile-form.teaching.stop-record' : 'profile-form.teaching.start-record'"
                  (onclick)="isRecording ? onClickStop() : onClickStart()">
      <svg class="icon">
        <use [attr.xlink:href]="svgSetIcon( isRecording ? 'video-stop':'video-record')"></use>
      </svg>
    </on-ui-button>

    <div class="mic-enable" *ngIf="!fileLoading">
      <span class="enable-info">{{'profile-form.teaching.allow-video' | translate}}</span>
      <span class="link-base"
            data-test="setting-video"
            (click)="onAccessClick()">&nbsp;{{'profile-form.teaching.camera-access' | translate}}</span>
    </div>
    <div class="loader" *ngIf="fileLoading">
      <spinner3></spinner3>
      <span>{{'profile-form.teaching.loading-video' | translate}}</span>
    </div>
  </div>
  <div class="error-holder">
    <div *ngIf="isLessVideo" class="v-error">
      {{'profile-form.teaching.short-video' | translate}}
    </div>
    <div *ngIf="isBigVideo" class="v-error">
      {{'profile-form.teaching.long-video' | translate}}
    </div>
  </div>
  <ng-template #overwrite>
    <div class="btn-row">
      <on-ui-button class="primary"
                    [disabled]="isDisabled"
                    label="profile-form.teaching.overwrite"
                    data-test="overwrite-video"
                    (onclick)="onClickOverwrite()">
      </on-ui-button>
    </div>
  </ng-template>
</div>

<app-modal *ngIf="showMicSetting && !deviceService.isMobile()"
           [canClose]="false"
           (closed)="onCloseSettingModal()">
  <app-modal-body>
    <app-microphone-setting></app-microphone-setting>
  </app-modal-body>
</app-modal>
