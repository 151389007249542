import { Component, Input } from '@angular/core';

@Component({
  selector: 'spinner2',
  template: '<div class="spinner2"><div class="dot-floating"></div></div>',
  styleUrls: ['./spinner2.scss'],
})
export class Spinner2 {
  @Input()
  public active = false;
}
