import { IContentBlock } from '@core/services/interfaces';
import { Action, createReducer, on } from '@ngrx/store';
import { Lesson, IFindContentFilter } from 'lingo2-models';

export type LibraryBlock = IContentBlock<Lesson, IFindContentFilter>;

import * as LessonAction from './library.actions';

export interface LibraryState extends LibraryBlock {
  error: string;
}

const PAGE_SIZE = 10;

const initialState: LibraryState = {
  items: [],
  loading: false,
  loaded: false,
  filter: {
    content_type: [],
  },
  pagination: {
    page: 1,
    pageSize: PAGE_SIZE,
    total: 0,
    totalPages: 0,
  },
  error: null,
};

// const filter: Partial<IFindContentFilter> = {
//   ...this.items.filter,
//   author_id: this.me.id,
//   term: this.term,
// };

const contentReducer = createReducer(
  initialState,
  on(LessonAction.requestFail, (state, { error }) => ({ ...state, loading: false, error })),

  // Library

  on(LessonAction.loadLibraryContent, (state) => ({ ...state, loading: true, loaded: false, error: null })),

  on(LessonAction.loadLibraryContentSuccess, (state, { items, pagination }) => ({
    ...state,
    items,
    pagination,
    loading: false,
    loaded: true,
  })),

  on(LessonAction.setLibraryFilter, (state, { filter }) => ({ ...state, filter: { ...state.filter, ...filter } })),

  on(LessonAction.setLibraryPagination, (state, { pagination }) => ({
    ...state,
    pagination: { ...state.pagination, ...pagination },
  })),
);

export function reducer(state = initialState, action: Action) {
  return contentReducer(state, action);
}
