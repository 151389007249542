import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MediaFileSelectorModule } from '@app/core/components/media-file-selector/media-file-selector.module';
import { TranslateModule } from '@ngx-translate/core';
import { DropzoneModule as NgxDropzoneModule } from 'ngx-dropzone-wrapper';

import { OnUiProgressBarModule } from 'onclass-ui';
import { DropzoneComponent } from './dropzone.component';
import { FileLoaderComponent } from './file-loader/file-loader.component';

@NgModule({
  declarations: [DropzoneComponent, FileLoaderComponent],
  imports: [CommonModule, NgxDropzoneModule, TranslateModule, MediaFileSelectorModule, OnUiProgressBarModule],
  exports: [DropzoneComponent],
})
export class DropzoneModule {}
