import { CommonModule, Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { clearClassroomMeetings, loadClassroomMeetings } from '@app/classrooms/_store/meetings';
import { FirstContactModule } from '@app/first-contact/first-contact.module';
import { LibraryRouter } from '@app/models/library.router';
import { IMenuItem, UNCONTROLLED_ROUTES } from '@app/models/routes';
import { NotificationsService } from '@app/notifications/notifications.service';
import { IconModule } from '@app/shared';
import { getLibraryRouteData } from '@app/store/reducers/library.reducer';
import { CurrencySelectorModule } from '@core/components/currency-selector/currency-selector.module';
import { LanguageSelectorModule } from '@core/components/language-selector/language-selector.module';
import { MobileModalModule } from '@core/components/mobile-modal/mobile-modal.module';
import { ModalModule } from '@core/components/modal/modal.module';
import { StickyModule } from '@core/components/sticky/sticky.module';
import { SubjectSelectorModule } from '@core/components/subject-selector-header/subject-selector.module';
import { TimezoneNotifierComponent } from '@core/components/timezone-notifier/timezone-notifier.component';
import { VacationInformerComponent } from '@core/components/vacation-informer/vacation-informer.component';
import { SuggestAddCardComponent } from '@core/header/suggest-add-card/suggest-add-card.component';
import { SuggestLanguageChangeComponent } from '@core/header/suggest-language-change/suggest-language-change.component';
import { SystemNotificationsComponent } from '@core/header/system-notifications/system-notifications.component';
import { UserMenuComponent } from '@core/header/user-menu/user-menu.component';
import { NotVerifiedWidgetModule, VerificationWidgetModule } from '@core/widgets';
import { OnboardingSharedModule } from '@core/widgets/onboarding-widget/shared/onboarding-shared.module';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { setProfile } from '@store/actions/profile.actions';
import { getMyProfile } from '@store/reducers/profile.reducer';
import {
  EContentPanelRoute,
  FeatureEnum,
  IUserAccess,
  TenantEnum,
  User,
  UserAchievementEnum,
  UserProfile,
  UserSegmentEnum,
  UserStatusEnum,
} from 'lingo2-models';
import { UserProfileVerificationEnum as VE } from 'lingo2-models/dist/account/enums/user-profile-verification-enum';
import { cloneDeep } from 'lodash';
import { ClickOutsideModule } from 'ng-click-outside';
import { DeviceDetectorService } from 'ngx-device-detector';
import {
  OnUiAutocompleteModule,
  OnUiButtonModule,
  OnUiButtonState,
  OnUiPopoverModule,
  OnUiSelectModule,
} from 'onclass-ui';
import { filter, forkJoin, switchMap, takeUntil } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { SidebarService } from '../layouts/main/sidebar/sidebar.service';
import {
  AccountService,
  AuthModalModeType,
  AuthService,
  ContextService,
  FeaturesService,
  AnalyticsService,
  IconsService,
  PlatformService,
  ProfileService,
  ScreenService,
} from '../services';
import { EventActionEnum, EventCategoryEnum } from '../services/analytics';

enum StatusActions {
  send = 'send',
  cancel = 'cancel',
}

const menuItems: IMenuItem[] = [
  {
    id: 'classes',
    label: 'menu.top.classes',
    icon: 'header-classes',
    route: EContentPanelRoute.classes,
  },
  {
    id: 'teachers',
    label: 'menu.top.teachers',
    icon: 'header-teachers',
    route: EContentPanelRoute.tutors,
  },
  {
    id: 'library',
    label: 'menu.top.library',
    icon: 'header-lesson',
    route: EContentPanelRoute.lessons,
  },
  {
    id: 'collections',
    icon: 'header-collections',
    label: 'library.base-pages.collections',
    route: EContentPanelRoute.collections,
  },
  {
    id: 'schools',
    icon: 'header-schools',
    label: 'menu.left-sidebar.schools',
    route: EContentPanelRoute.schools,
  },

  // {
  //   id: 'dashboard',
  //   label: 'menu.top.dashboard',
  //   icon: 'header-dashboard',
  //   route: '/dashboard',
  // },
  // {
  //   id: 'games',
  //   label: 'menu.top.games',
  //   icon: 'header-games',
  //   route: '/games',
  // }
];

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    LanguageSelectorModule,
    CurrencySelectorModule,
    RouterModule,
    ClickOutsideModule,
    SubjectSelectorModule,
    MobileModalModule,
    OnUiAutocompleteModule,
    FormsModule,
    IconModule,
    OnboardingSharedModule,
    VerificationWidgetModule,
    OnUiButtonModule,
    OnUiPopoverModule,
    NotVerifiedWidgetModule,
    ModalModule,
    FirstContactModule,
    OnUiSelectModule,
    UserMenuComponent,
    SystemNotificationsComponent,
    SuggestLanguageChangeComponent,
    SuggestAddCardComponent,
    TimezoneNotifierComponent,
    StickyModule,
    VacationInformerComponent,
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent extends ChangableComponent implements AfterViewInit, OnDestroy {
  @ViewChild('menuHolder') menuHolder: ElementRef<HTMLElement>;
  public isSmallScreen = this.screenService.isSmallScreen;
  public isWideScreen = this.screenService.isWideScreen;
  public me: User;
  public isGuest: boolean;
  public profile: UserProfile;
  public settings: IUserAccess;
  public userpicUrl: string;
  public options_open = false;
  public showMoreMenu = false;
  public svgSetIcon = IconsService.svgsetIconUrl;
  public accountRoute = AccountService.accountRoute;
  public menuItems = cloneDeep(menuItems);
  public menus = [];
  public unreadNotificationsCount$ = this.notificationsService.unreadCount$;
  public expandUserMenuState = false;
  public checkedSteps: number;
  public scrollThreshold = 0;
  public maxSteps: number;
  private libraryRouter: LibraryRouter = new LibraryRouter();
  public verification_status: VE;
  public isLoading = false;
  public btnState: OnUiButtonState = 'default';

  public constructor(
    private authService: AuthService,
    private contextService: ContextService,
    private profileService: ProfileService,
    private screenService: ScreenService,
    private sidebarService: SidebarService,
    private features: FeaturesService,
    private analytics: AnalyticsService,
    private notificationsService: NotificationsService,
    private location: Location,
    private readonly store: Store,
    private router: Router,
    public deviceService: DeviceDetectorService,
    protected cdr: ChangeDetectorRef,
    protected platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  public ngAfterViewInit() {
    this.contextService.me$
      .pipe(
        filter((me) => !!me),
        tap((me) => {
          this.me = me;
          this.isGuest = me.status === UserStatusEnum.guest;
          this.detectChanges();
        }),
        switchMap((me) =>
          forkJoin([this.store.select(getMyProfile).pipe(first()), this.profileService.getSettings(me?.id)]),
        ),
        takeUntil(this.destroyed$),
      )
      .subscribe(([profile, settings]) => {
        this.profile = profile;
        this.contextService.patchProfile(this.profile);
        this.settings = settings;
        this.verification_status = profile?.verification_status;
        this.maxSteps = AccountService.isTeacher(this.me) || AccountService.isAsIfTeacher(this.me) ? 6 : 2;
        this.setAllSteps();
        this.detectChanges();
        this.prepare();
        this.updateRoutes();
      });

    this.screenService.width$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.isSmallScreen = this.screenService.isSmallScreen;
      this.isWideScreen = this.screenService.isWideScreen;
      this.hideMenuItems();
    });

    this.store
      .select(getLibraryRouteData)
      .pipe(
        filter((d) => !!d),
        takeUntil(this.destroyed$),
      )
      .subscribe((data) => {
        this.libraryRouter.setBaseRoute(data.baseRoute);
        this.libraryRouter.setSubjects(data.subjectCodes);
        this.updateRoutes();
        this.hideMenuItems();
      });
  }

  public get isMeVersion() {
    return this.features.tenant === TenantEnum.onclass_me;
  }

  public get isAvailableProfileFinance() {
    return this.features.isAvailable(FeatureEnum.profile_finance, this.me);
  }

  public get isProfilePage(): boolean {
    return this.router.url.includes('/profile');
  }

  public get isConstructorPage(): boolean {
    return this.router.url.includes('/constructor');
  }

  public get isSystemPages(): boolean {
    const urlList = [
      '/profile',
      '/u/',
      '/teaching/',
      '/events',
      '/schedule',
      '/users',
      '/join',
      '/settings',
      '/finance',
      '/my-vocabulary',
      '/schedule/',
      '/constructor/',
      '/content/collections/',
    ];
    return !!urlList.find((url) => this.router.url.includes(url));
  }

  public get isTeacher(): boolean {
    return AccountService.isAsIfTeacher(this.me);
  }

  public get isOnModify() {
    if (this.verification_status === VE.changed) {
      return StatusActions.send;
    } else if (this.verification_status === VE.resent || this.verification_status === VE.sent) {
      return StatusActions.cancel;
    }
    return;
  }

  public get isCommonLanding(): boolean {
    return this.router.url === '/';
  }

  public get isNeedAction(): string {
    if (this.isMeVersion) {
      return '';
    }
    return !this.isGuest && this.isTeacher ? this.getStatusIcon(this.verification_status) : '';
  }

  public getStatusIcon(status: VE): string {
    switch (status) {
      case VE.incomplete:
      case VE.not_verified:
      case VE.changed:
      case VE.declined:
      case VE.redeclined:
        return 'status-need-action';
      case VE.sent:
      case VE.resent:
        return 'status-on-moderation-no-border';
      default:
        return '';
    }
  }

  public get percent(): number {
    if (!this.maxSteps || !this.checkedSteps) {
      return 0;
    }
    return Math.round((100 / this.maxSteps) * this.checkedSteps);
  }

  private setAllSteps(): boolean {
    const segments = this.isTeacher
      ? [UserSegmentEnum.has_approved_bank_card, UserSegmentEnum.use_mobile_app]
      : [UserSegmentEnum.use_mobile_app];

    const achievements = this.isTeacher
      ? [UserAchievementEnum.added_working_time_once, UserAchievementEnum.created_user_services_once]
      : [];

    const filteredSegments = this.me?.segments
      .filter((_a) => segments.includes(_a.segment))
      .sort((a, b) => {
        const _a = new Date(a.created_at);
        const _b = new Date(b.created_at);
        return _b > _a ? 1 : -1;
      });

    const filteredAchievements = this.me?.achievements
      .filter((_a) => achievements.includes(_a.achievement))
      .sort((a, b) => {
        const _a = new Date(a.created_at);
        const _b = new Date(b.created_at);
        return _b > _a ? 1 : -1;
      });

    const isTeacherAllStepsFilled = AccountService.isTeacherAllStepsFilled(this.me, this.profile, this.settings)
      ? 1
      : 0;

    const teachingSubjects = this.profile?.teaching_subjects?.length > 0 ? 1 : 0;

    this.checkedSteps =
      filteredSegments.length + filteredAchievements.length + teachingSubjects + isTeacherAllStepsFilled;

    return this.checkedSteps === this.maxSteps;
  }

  public get isAllStepsFilled() {
    return this.checkedSteps === this.maxSteps;
  }

  private prepare() {
    this.userpicUrl = this.me && this.me.userpic && this.me.userpic.sm ? this.assetsUrl(this.me.userpic.sm.url) : null;
  }

  public authorize(mode: AuthModalModeType) {
    this.authService.setAuthModalMode(mode);
    this.authService.showAuthModal(() => {
      this.store.dispatch(clearClassroomMeetings());
      this.store.dispatch(loadClassroomMeetings());
    });
  }

  public isMenuItemEnabled(item: IMenuItem): boolean {
    // '/feed' доступен только после явной или неявной авторизации
    return item.id === 'feed' ? !!this.me : true;
  }

  public toggleLeftSidebar() {
    this.sidebarService.toggleLeftSidebar();
  }

  public get isAvailableNotifications(): boolean {
    return this.features.isAvailable(FeatureEnum.notifications, this.me);
  }

  public expandedUserMenuState(state: boolean) {
    this.expandUserMenuState = state;
    this.detectChanges();
  }

  private updateRoutes(menuRoutes = menuItems) {
    this.menuItems = this.setBaseRoutes(menuRoutes);
  }

  public goToMain(): void {
    this.router.navigate([EContentPanelRoute.main]).then();
  }

  private setBaseRoutes(menuRoutes = menuItems) {
    return menuRoutes.map((item) => {
      this.libraryRouter.setBaseRoute(item.route);
      let url = `/${item.route}`;
      if (!UNCONTROLLED_ROUTES.includes(item.route)) {
        url = this.libraryRouter.getLink().path;
      }
      return {
        ...item,
        url,
      };
    });
  }

  /**
   * hide or show menu items
   */
  private hideMenuItems(): void {
    if (this.isServer) {
      return;
    }
    // Если большой экран или моб, даже не пытаемся скрывать и сбрасываем переменные
    const tempMenu = cloneDeep(this.menuItems);

    if (window.innerWidth > 1200 || window.innerWidth <= 870) {
      this.menuItems = cloneDeep(menuItems);
      this.updateRoutes(this.menuItems);
      this.menus = [];
      this.showMoreMenu = false;
      return;
    }

    if (this.menuHolder) {
      const el = this.menuHolder.nativeElement; // Элемент блока с меню
      const elChildrenWidth = 90;
      const parEl = el.parentElement; // Элемент хедера;
      const parElChild: any = parEl.children; // Список элементов хедера;
      let freeSpace: number = parEl.clientWidth - 40;
      for (const child of parElChild) {
        if (!child.classList.contains('nav')) {
          if (child.classList.contains('right-spacer')) {
            freeSpace = freeSpace - child.firstElementChild.clientWidth;
          } else {
            freeSpace = freeSpace - child.clientWidth;
          }
        }
      }
      const freeSpaceCount: number =
        Math.floor(freeSpace / elChildrenWidth) > menuItems.length
          ? menuItems.length
          : Math.floor(freeSpace / elChildrenWidth);

      if (freeSpaceCount < menuItems.length) {
        const count: number = freeSpaceCount - 1;
        if (tempMenu.slice(count, this.menuItems.length).length) {
          this.menus = [...tempMenu.slice(count, this.menuItems.length)];
        } else {
          this.menus = [...tempMenu.slice(count, this.menuItems.length), ...this.menus];
        }
        this.updateRoutes(this.menuItems.splice(count, this.menuItems.length - count));
      } else {
        this.updateRoutes(menuItems);
        this.menus = [];
      }
    }
    this.showMoreMenu = this.menus.length > 0;
    this.detectChanges();
  }

  public sendToVerification(): void {
    const oldStatus = this.verification_status;
    this.btnState = 'progress';

    this.isLoading = true;
    this.profileService
      .sendToVerification()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        if (res.status === 'ok') {
          if (oldStatus === VE.not_verified) {
            // отправился первый раз
            this.analytics.event(EventActionEnum.verification_sent, EventCategoryEnum.engagement, 'app-header');
            this.location.go(this.location.path() + '#verification-sent');
          } else {
            // отправился повторно
            this.analytics.event(EventActionEnum.verification_resent, EventCategoryEnum.engagement, 'app-header');
            this.location.go(this.location.path() + '#verification-resent');
          }

          this.updateProfile();
        }

        this.btnState = 'done';
        this.setTimeout(() => {
          this.btnState = 'default';
        }, 1000);
        this.isLoading = false;
        this.detectChanges();
      });
  }

  public cancelVerification(): void {
    this.isLoading = true;
    this.btnState = 'progress';
    this.profileService
      .cancelVerification()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        if (res.status === 'ok') {
          this.analytics.event(EventActionEnum.verification_canceled, EventCategoryEnum.engagement, 'app-header');
          this.location.go(this.location.path() + '#verification-canceled');

          this.updateProfile();
        }
        this.btnState = 'done';
        this.setTimeout(() => {
          this.btnState = 'default';
        }, 1000);
        this.isLoading = false;
      });
  }

  private updateProfile(): void {
    this.profileService
      .getProfile(this.me?.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((profile) => {
        this.contextService.updateProfile(profile);
        this.store.dispatch(setProfile({ profile }));
        this.detectChanges();
      });
  }
}
