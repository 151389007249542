import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { INoticeParams } from 'lingo2-models';
import { EModifier, IBaseNotification } from '../../base-notification/base-notification.component';

@Component({
  selector: 'app-meeting-purchase-canceled',
  templateUrl: './meeting-purchase-canceled.component.html',
  styles: [''],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeetingPurchaseCanceledComponent {
  public params: INoticeParams;
  public base: IBaseNotification;

  @Input() set notification(notification: INoticeParams) {
    this.params = notification.params;
    this.base = {
      title: 'notifications.meeting_purchase_was_canceled.title',
      icon: 'notice-cancel',
      created_at: notification.created_at,
      modifier: EModifier.yellow,
    };
  }

  public get title() {
    return `<a class='link-base' href='${this.params.meeting_join_url}'>${this.params.meeting_title}</a>`;
  }
}
