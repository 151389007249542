import { AfterViewInit, Component, ComponentRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HeaderComponent } from '@core/header/header.component';
import { DestroyableComponent } from '@models/destroyable.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { takeUntil } from 'rxjs/operators';
import { AuthService, ContextService } from '../../services';

@Component({
  selector: 'app-layout',
  templateUrl: './landing-layout.component.html',
  styleUrls: ['./landing-layout.component.scss'],
})
export class LandingLayoutComponent extends DestroyableComponent implements OnInit, AfterViewInit {
  public pageStyle = '';
  public debug$ = this.contextService.debug$;

  @ViewChild('app_header', { read: ViewContainerRef }) private headerContainerRef: ViewContainerRef;
  private headerComponentRef: ComponentRef<HeaderComponent>;

  constructor(
    private route: ActivatedRoute,
    private contextService: ContextService,
    private authService: AuthService,
    public deviceService: DeviceDetectorService,
  ) {
    super();
  }

  ngOnInit() {
    this.route.url.pipe(takeUntil(this.destroyed$)).subscribe((url) => {
      // logger.log('LandingLayoutComponent -> route.url -> snapshot=', this.route.snapshot);
      const data = this.route.snapshot.data;
      this.pageStyle = data.page_style || '';
    });
  }

  ngAfterViewInit() {
    void this.loadEmptyComponent().catch();
  }

  // TODO ленивая загрузка компонента
  private async loadEmptyComponent(): Promise<void> {
    if (!this.headerContainerRef) {
      return;
    }
    const { HeaderComponent } = await import('../../../core/header/header.component');
    this.headerContainerRef?.clear();
    this.headerComponentRef = this.headerContainerRef?.createComponent(HeaderComponent);
    this.headerComponentRef.changeDetectorRef.detectChanges();
  }

  public get isShowLanding(): boolean {
    return !this.authService.isShowAuthModal || this.deviceService.isDesktop();
  }
}
