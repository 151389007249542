import { Injectable } from '@angular/core';
import { LoadingBarService } from '@core/components/spinners/loading-bar/loading-bar.service';

@Injectable({
  providedIn: 'root',
})
export class ServerLoadingBarService extends LoadingBarService {
  public navigationStart(percent = 0) {
    return;
  }

  public navigationProgress(percent: number) {
    return;
  }

  public navigationComplete() {
    return;
  }

  public navigationStop() {
    return;
  }

  public fetchingStart(percent = 0) {
    return;
  }

  public fetchingProgress(percent: number) {
    return;
  }

  public fetchingComplete(percent = 100) {
    return;
  }

  public fetchingStop() {
    return;
  }
}
