import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@app/shared';
import {
  MobileModalComponent,
  MobileModalTitleComponent,
  MobileModalBodyComponent,
  MobileModalActionsComponent,
  MobileModalAdComponent,
} from './mobile-modal.component';

@NgModule({
  declarations: [
    MobileModalComponent,
    MobileModalTitleComponent,
    MobileModalBodyComponent,
    MobileModalActionsComponent,
    MobileModalAdComponent,
  ],
  imports: [CommonModule, IconModule],
  exports: [
    MobileModalComponent,
    MobileModalTitleComponent,
    MobileModalBodyComponent,
    MobileModalActionsComponent,
    MobileModalAdComponent,
  ],
  providers: [],
})
export class MobileModalModule {}
