import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

// внешние модули
import { MobileModalModule } from '../mobile-modal/mobile-modal.module';
import { ModalModule } from '../modal/modal.module';

// компоненты
import { LanguageSelectorComponent } from './language-selector.component';

@NgModule({
  declarations: [LanguageSelectorComponent],
  imports: [CommonModule, ModalModule, MobileModalModule, TranslateModule],
  exports: [LanguageSelectorComponent],
})
export class LanguageSelectorModule {}
