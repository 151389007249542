import { trigger, transition, useAnimation, animate, keyframes, style } from '@angular/animations';
import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { Notice } from 'lingo2-models';
import { Subscription } from 'rxjs';
import { debounceTime, map, filter, takeUntil } from 'rxjs/operators';
import { moveFadeOut } from 'src/app/core/animations';

import { Greeting } from '../greeting-notice/greeting-notice.component';
import { NotificationsService } from '../notifications.service';

const MAX_VISIBLE_COUNT = 3;
export const DEBOUNCE_TIME = 1000;
const DISPLAYING_TIME = 10 * 1000;

export type Toast = Notice | Greeting;

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('toast', [
      transition(':enter', [
        animate(
          '400ms ease-in-out',
          keyframes([
            style({ transform: 'translateY(100%)', offset: 0 }),
            style({ transform: 'translateY(-10%)', offset: 0.5 }),
            style({ transform: 'translateY(0%)', offset: 1 }),
          ]),
        ),
      ]),
      transition(':leave', useAnimation(moveFadeOut, { params: { time: '300ms', slide: '-50%' } })),
    ]),
  ],
})
export class ToasterComponent extends ChangableComponent implements OnInit, OnDestroy {
  public toasts: Toast[] = [];

  private _startCountdown: Subscription;
  private _showAtIntervals: Subscription;
  private bulkThrottleTime = 0;

  constructor(
    private notificationsService: NotificationsService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngOnInit() {
    const initialToasts = this.notificationsService.getInitialToasts().slice(0, 3);

    if (initialToasts.length) {
      // Показ других уведомлений будет задержан на время, достаточное для показа приветствий
      this.bulkThrottleTime = initialToasts.length * DEBOUNCE_TIME;
      this.showAtIntervals(initialToasts);
    }

    // Subscribe to single toast

    this.notificationsService.toast$
      .pipe(debounceTime(DEBOUNCE_TIME))
      .pipe(takeUntil(this.destroyed$))
      .subscribe((toast: Toast) => {
        this.updateToasts(toast);
      });

    // Subscribe to bulk toasts

    this.notificationsService.toasts$
      .pipe(
        map((toasts: Toast[]) => toasts.slice(0, 3)),
        filter((toasts) => !!toasts.length),
        debounceTime(this.bulkThrottleTime),
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe((toasts: Toast[]) => {
        this.bulkThrottleTime = 0;
        this.showAtIntervals(toasts);
      });

    // Clear toasts if notification widget is opened

    this.notificationsService.isOpened$
      .pipe(filter((isOpened) => isOpened))
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.clearToasts();
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.notificationsService.clearInitialToasts();
  }

  public trackByFn(notice: Notice) {
    return notice.id;
  }

  public deleteToast(index: number): void {
    this.toasts.splice(index, 1);
  }

  private updateToasts(toast: Toast): void {
    this.toasts.push(toast);

    if (this.toasts.length > MAX_VISIBLE_COUNT) {
      this.toasts.shift();
    }

    this.startCountdown();
    this.cdr.markForCheck();
  }

  private startCountdown(): void {
    this.onBrowserOnly(() => {
      this.clearTimeout(this._startCountdown);
      this._startCountdown = this.setTimeout(() => {
        this.toasts = [];
        this.cdr.markForCheck();
      }, DISPLAYING_TIME);
    });
  }

  private showAtIntervals(toasts: Toast[]): void {
    if (!this.platform.isBrowser) {
      return;
    }

    let n = 0;

    this.clearInterval(this._showAtIntervals);
    this._showAtIntervals = this.setInterval(() => {
      if (n > toasts.length - 1) {
        this.clearInterval(this._showAtIntervals);
      } else {
        this.updateToasts(toasts[n]);
        n++;
      }
    }, DEBOUNCE_TIME);
  }

  private clearToasts() {
    this.toasts = [];
    this.clearTimeout(this._startCountdown);
    this.clearInterval(this._showAtIntervals);
    this.markForCheck();
  }
}
