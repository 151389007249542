import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OnboardingDonutChartComponent } from './onboarding-donut-chart.component';

@NgModule({
  declarations: [OnboardingDonutChartComponent],
  imports: [CommonModule],
  exports: [OnboardingDonutChartComponent],
})
export class OnboardingDonutChartModule {}
