import { Injectable } from '@angular/core';
import { DestroyableComponent } from '@models/destroyable.component';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class NotificationService extends DestroyableComponent {
  private hasClose = this.register(new BehaviorSubject<boolean>(false));
  public hasClose$ = this.hasClose.asObservable();

  private closed = this.register(new Subject<void>());
  public closed$ = this.closed.asObservable();

  public setHasClose(value: boolean): void {
    this.hasClose.next(value);
  }

  public close(): void {
    this.closed.next();
  }
}
