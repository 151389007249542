<section class="first-contact-constructor" [class.mobile]="deviceService.isMobile()" [class.limited]="isLimitedVersion">
  <h2 class="title">{{ title() | translate }}</h2>
  <p class="subtitle" [innerHTML]="subtitle() | translate"></p>

  <div class="constructor-list"
       [class.is-viewport]="isViewport"
       inViewport
       (inViewportAction)="onInViewportChange($event)">
    <div *ngFor="let item of constructorList()" class="constructor-item">
      <h3 class="item-title">{{ item.title | translate }}</h3>

      <div class="slide" [ngClass]="item.type"></div>

      <p class="item-text">{{ item.text | translate }}</p>
    </div>
  </div>
</section>
