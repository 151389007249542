<div class="editor-wrapper"
     [class.has-background]="value || isFocused"
     [class.centered]="isCentered"
     [class.focused]="isFocused"
     [class.error]="isError"
     (click)="focus()"
     (clickOutside)="blur()">
  <div class="placeholder" *ngIf="!value && placeholder">{{placeholder}}</div>
  <editor class="rich-editor simple" [class.fittext]="fitText"
          [init]="defaultConfig" (input)="changeEditor($event)" (paste)="onPasteEditor($event)"
          (onFocus)="focus()" (onInit)="initEditor($event)"
          [formControl]="formControl"
          (onBlur)="onBlur()">
  </editor>

</div>

<app-modal
  *ngIf="isModalOpened"
  (closed)="closeModal()"
  editor="true"
  canClose="true"
  wide="true"
  middle="true"
  showToFooter="true"
  shadeLight="true">
  <app-modal-body>
    <div class='outside-toolbar-wrapper'>
      <div [attr.id]="editorId" class='outside-toolbar'></div>
    </div>
    <editor class="rich-editor modal"
            [init]="dialogConfig" (input)="changeEditor($event)" (onPaste)="onPasteEditor($event)"
            (onInit)="initEditor($event)"
            [formControl]="formControlDialog">
    </editor>
  </app-modal-body>
</app-modal>
