import { Injectable, NgZone } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { logger } from '@app/core/helpers/logger';
import { environment } from '@env/environment';
import { DestroyableComponent } from '@models/destroyable.component';
import { AnyType } from 'lingo2-models';
import { takeUntil } from 'rxjs/operators';
import { PlatformService } from '../platform.service';

declare let fbq: (...arg) => void;

@Injectable({
  providedIn: 'root',
})
export class FacebookAnalyticsService extends DestroyableComponent {
  private inited = false;

  public constructor(private router: Router, protected readonly platform: PlatformService, private zone: NgZone) {
    super(platform);
    this.init();
  }

  protected init() {
    this.onBrowserOnly(() => {
      if (environment.fb_id && window['fbq']) {
        this.inited = true;
        this.router.events.pipe(takeUntil(this.destroyed$)).subscribe((event) => {
          if (event instanceof NavigationEnd) {
            this.pageView(event.urlAfterRedirects);
          }
        });
      }
    });
  }

  /** Зарегистрировать просмотр страницы */
  public pageView(url: string, referer?: string) {
    if (this.inited) {
      this.zone.runOutsideAngular(() => {
        this.event('PageView');
      });
    }
  }

  /**
   * Отправить событие в Facebook Analitics
   *
   * @see https://www.facebook.com/business/help/402791146561655?id=1205376682832142
   *
   * @param action код события
   * @param params параметры события
   */
  public event(action: string, params?: AnyType) {
    if (this.inited) {
      this.zone.runOutsideAngular(() => {
        if (params) {
          logger.log('fbq::track', action, params);
          fbq('track', action, params);
        } else {
          logger.log('fbq::track', action);
          fbq('track', action);
        }
      });
    }
  }
}
