import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { logger } from '@core/helpers/logger';
import { DragScrollComponent } from '@le2xx/ngx-drag-scroll';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import {
  isOnlineSearchInProgress,
  getOnlineImages,
  getRecentImages,
  isShowWarning,
  getWarningText,
  getOnlineSearchQuery,
  getSelectedFile,
  getUploadedFileStatus,
} from '@store/reducers/media-gallery.reducer';
import {
  MediaFormatEnum,
  IFindFileFilter,
  FileTypeEnum,
  SourceTypeEnum,
  IPagination,
  FilePurposeEnum,
  ImageSizeEnum,
} from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { audioPlayerDefaultConfig, OnUiAudioPlayerConfig } from 'onclass-ui';
import { Observable } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import * as MediaAction from '../../../store/actions/media-gallery.actions';
import { DropzoneConfig, FilesService, IFileType, IconsService, ScreenService, PlatformService } from '../../services';

type TabType = 'load' | 'star' | 'recent' | 'online';
type MediaFileSelectorComponentTypeType = 'audio' | 'image';

@Component({
  selector: 'app-media-file-selector',
  templateUrl: './media-file-selector.component.html',
  styleUrls: ['./media-file-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaFileSelectorComponent extends ChangableComponent implements OnInit, OnDestroy {
  @Input() set type(type: MediaFileSelectorComponentTypeType) {
    this._type = type;
    this.configureDropzone();
  }
  get type(): MediaFileSelectorComponentTypeType {
    return this._type;
  }
  @Input() set purpose(purpose: FilePurposeEnum) {
    this._purpose = purpose;
    this.configureDropzone();
  }
  @ViewChild('dropzone') dropzone: ElementRef;

  @Output() changed = new EventEmitter<IFileType>();
  @Output() closed = new EventEmitter<boolean>();
  @ViewChild('gallery', { read: DragScrollComponent }) ds: DragScrollComponent;
  @ViewChild('search') searchElement: ElementRef;

  public svgSetIcon = IconsService.svgsetIconUrl;
  public opened: boolean;
  public images: IFileType[] = [];
  public tab: TabType;
  public reachesLeftFlag: boolean;
  public reachesRightFlag: boolean;
  public is_uploading = false;
  public is_dropzone_configured = false;
  public dropzoneConfig: DropzoneConfigInterface = {
    paramName: 'file',
    uploadMultiple: false,
    clickable: true,
    autoReset: 1,
    maxFiles: 10,
    errorReset: null,
    cancelReset: null,
    dictDefaultMessage: '',
    url: '/',
  };
  public imageUrl = '';
  public pagination: IPagination = {
    page: 1,
    pageSize: 50,
    total: null,
    totalPages: null,
  };
  public filter: Partial<IFindFileFilter> = {};
  public term = '';
  public galleryScrollWidth = 0;
  public galleryScrollPages: any[] = [0];
  public isDragScrollEnabled = !(this.deviceService.isMobile() || this.deviceService.isTablet());
  public width = 0;
  public audioPlayerConfig: OnUiAudioPlayerConfig = {
    ...audioPlayerDefaultConfig,
    autoPlay: true,
  };
  public isFocus: boolean;
  private readonly _element: any;
  private _image: IFileType;
  private _audio: IFileType;
  private _type: MediaFileSelectorComponentTypeType;
  private _purpose: FilePurposeEnum;
  private _timer;
  private _onGalleryConfigured;
  private _clickOutsideEnabled = false;
  private _onlineSearchQuery = '';

  public onlineSearchInProgress$: Observable<boolean>;
  public onlineSearchQuery$: Observable<string>;
  public onlineImages$: Observable<IFileType[]>;
  public recentImages$: Observable<IFileType[]>;
  public showAlert$: Observable<boolean>;
  public mediaAlertText$: Observable<string>;
  public percent = 0;

  constructor(
    private el: ElementRef,
    private dzConfig: DropzoneConfig,
    private filesService: FilesService,
    private screenService: ScreenService,
    private store: Store,
    public deviceService: DeviceDetectorService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);

    this._element = el.nativeElement;

    // Store configuration
    this.recentImages$ = this.store.select(getRecentImages);
    this.showAlert$ = this.store.select(isShowWarning);
    this.mediaAlertText$ = this.store.select(getWarningText);
    this.onlineSearchQuery$ = this.store.select(getOnlineSearchQuery);

    // Online search
    this.onlineSearchInProgress$ = this.store.select(isOnlineSearchInProgress);
    this.onlineImages$ = this.store.select(getOnlineImages);
  }

  ngOnInit() {
    this.filter.file_purpose = this._purpose;

    this.store.dispatch(MediaAction.loadRecentImages({ filter: this.filter }));
    this.store.dispatch(MediaAction.loadStarImages({ filter: this.filter }));
    // Reset selected file on close
    this.store.dispatch(MediaAction.selectFile({ file: null }));

    this.store
      .select(getSelectedFile)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((file: IFileType) => {
        if (file) {
          if (file.type === FileTypeEnum.image) {
            this._image = file;
            this.prepareImage();
            this.markForCheck();
            this.changed.emit(file);
          }
        }
      });

    this.configureDropzone();
    this.onBrowserOnly(() => {
      document.querySelector('.app').appendChild(this._element);
    });

    this.setTimeout(() => {
      this.opened = true;
      this.clickOutsideEnabled = true;
      this.markForCheck();
    }, 100);

    this.loadFiles();

    this.screenService.width$.pipe(takeUntil(this.destroyed$)).subscribe((width) => {
      this.width = width;
      this.markForCheck();
    });

    this.recentImages$.pipe(takeUntil(this.destroyed$)).subscribe((res) => {
      this.tab = !res.length ? 'load' : 'recent';
      this.detectChanges();
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.onBrowserOnly(() => this._element.remove());
  }

  get clickOutsideEnabled(): boolean {
    return this._clickOutsideEnabled;
  }

  set clickOutsideEnabled(value: boolean) {
    if (value) {
      this.clearTimeout(this._timer);
      this._timer = this.setTimeout(() => {
        this._clickOutsideEnabled = true;
        this.markForCheck();
      }, 200);
    } else {
      this._clickOutsideEnabled = false;
      this.markForCheck();
    }
  }

  get galleryWidth(): number {
    return (
      this.width -
      190 /* картинка */ -
      20 /* проавый отступ картинки */ -
      40 /* внешние отступы виджета */ -
      40 /* внутренние отступы виджета */ -
      30 /* ??? */
    );
  }

  get componentWidth(): number {
    return this.width - 40 /* внешние отступы виджета */ - 40 /* внутренние отступы виджета */ - 30 /* ??? */;
  }

  public configureDropzone() {
    switch (this._type) {
      case 'image':
        this.dropzoneConfig = this.dzConfig.combine(this.dropzoneConfig, {
          acceptedFiles: 'image/jpeg,image/jpg,image/png',
          maxFilesize: 5 * 1024 * 1024, // 5Mb
          url: this.dzConfig.url + '/file/upload+process?purpose=' + this._purpose,
        });
        this.is_dropzone_configured = true;
        break;

      case 'audio':
        this.dropzoneConfig = this.dzConfig.combine(this.dropzoneConfig, {
          // https://www.sitepoint.com/mime-types-complete-list/
          // https://developer.mozilla.org/ru/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
          acceptedFiles: 'audio/aiff,audio/x-ms-wma,audio/mp3,audio/mpeg', // ? audio/wav audio/ogg audio/webm
          maxFilesize: 100 * 1024 * 1024, // 100Mb
          url: this.dzConfig.url + '/file/upload+process?purpose=' + FilePurposeEnum.audio,
        });
        this.is_dropzone_configured = true;
        break;
    }
    this.markForCheck();
  }

  public onGalleryConfigured(config: any) {
    this.clearTimeout(this._onGalleryConfigured);
    this._onGalleryConfigured = this.setTimeout(() => {
      const { galleryScrollWidth, galleryScrollPages } = config;
      this.galleryScrollWidth = galleryScrollWidth;
      this.galleryScrollPages = [...Array(galleryScrollPages)];
      this.markForCheck();
    }, 0);
  }

  protected loadFiles() {
    const filter: Partial<IFindFileFilter> = {
      type: this.type === 'audio' ? FileTypeEnum.audio : FileTypeEnum.image,
      term: this.term,
      source: SourceTypeEnum.upload,
      file_purpose: this.type === 'audio' ? null : this._purpose,
    };

    this.filesService
      .getRecentFiles(filter, this.pagination)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        switch (this.type) {
          case 'image':
            this.images = response.results;
            this.pagination.page = response.page;
            this.pagination.pageSize = response.pageSize;
            this.pagination.totalPages = response.totalPages;
            this.pagination.total = response.total;
            this.markForCheck();
            break;
          case 'audio':
            break;
        }
      });
  }

  // public onRemoveFile(file: FileType) {
  //   // this.images.splice(index, 1);
  // }
  //
  // public onStar(index: number) {
  //   // this.images[index].star = !this.images[index].star;
  // }

  public onClose(event) {
    if ((event.target as HTMLElement).classList.contains('gallery-item-img')) {
      return;
    }
    // TODO: Code to close panel
    this.opened = false;
    this.clickOutsideEnabled = false;
    this.markForCheck();

    this.setTimeout(() => {
      this.closed.emit(false);
      this.markForCheck();
    }, 200);

    // Reset selected file on close
    this.store.dispatch(MediaAction.selectFile({ file: null }));
  }

  public moveLeft() {
    this.ds.moveLeft();
    this.markForCheck();
  }

  public moveRight() {
    this.ds.moveRight();
    this.markForCheck();
  }

  public onFocus() {
    this.isFocus = !this.isFocus;
  }

  public setFocus() {
    setTimeout(() => {
      this.searchElement.nativeElement.focus();
    }, 0);
  }

  public onClick(args: any) {
    // logger.log('MediaFileSelectorComponent:dropzone:onClick', args);
    this._clickOutsideEnabled = false;
    this._timer = this.setTimeout(() => {
      this._clickOutsideEnabled = true;
      this.markForCheck();
    }, 200);
  }

  public onClick2(args: any) {
    // logger.log('MediaFileSelectorComponent:dropzone:onClick', args);
    this._clickOutsideEnabled = false;
    this._timer = this.setTimeout(() => {
      this.dropzone.nativeElement.click();
      this._clickOutsideEnabled = true;
      this.markForCheck();
    }, 200);
  }

  public onUploadInit(args: any): void {
    // logger.log('MediaFileSelectorComponent:dropzone:onUploadInit', args);
  }

  public onUploadProgress(args: any): void {
    logger.log('dropzone:onUploadProgress', args);
    const [, file] = args;
    this.percent = file;
  }

  public onUploadStart(args: any): void {
    // logger.log('MediaFileSelectorComponent:dropzone:onUploadStart', args);
    this.is_uploading = true;
    this.markForCheck();
  }

  public onUploadError(args: any): void {
    // logger.log('MediaFileSelectorComponent:dropzone:onUploadError:', args);
    this.is_uploading = false;
    this.markForCheck();
  }

  public onUploadSuccess(args: any): void {
    // logger.log('MediaFileSelectorComponent:dropzone:onUploadSuccess', args);
    this.is_uploading = false;
    const [, uploadedFile] = args;
    switch (this._type) {
      case 'image':
        this._image = uploadedFile;
        this.prepareImage();
        this.changed.emit(uploadedFile);
        this.pagination.page = 1;
        this.loadFiles();
        break;

      case 'audio':
        this._audio = uploadedFile;
        this.prepareAudioSources();
        this.changed.emit(uploadedFile);
        this.pagination.page = 1;
        this.loadFiles();
        break;
    }
    this.markForCheck();
  }

  protected prepareImage() {
    if (this._image && this._image.id) {
      const _file = FilesService.getFileUrlBySize(this._image.id, ImageSizeEnum.md);
      this.imageUrl = _file ? this.assetsUrl(_file) : null;
    } else {
      this.imageUrl = '';
    }
  }

  protected prepareAudioSources() {
    if (!this._audio || !this._audio.media) {
      return;
    }
    const mp3File = this._audio.media.find((image) => image.format === MediaFormatEnum.mp3);
    this.audioPlayerConfig.singleTrack[0].link = mp3File.url;
  }

  public onChooseImage(file: IFileType) {
    this._image = file;
    this.prepareImage();
    this.markForCheck();
    this.store.dispatch(MediaAction.selectFile({ file }));
  }

  public chooseAudio(file: IFileType, event: Event) {
    this._audio = file;
    this.prepareAudioSources();
    this.markForCheck();
    this.changed.emit(file);
  }

  public onSearchIconClick(e: Event) {
    e.stopPropagation();
    e.preventDefault();
    this.store.dispatch(MediaAction.loadOnlineImages({ query: this._onlineSearchQuery }));
  }

  public onlineSearchTextChange({ target }: Event) {
    this._onlineSearchQuery = (target as any).value;
  }

  public onlineSearch({ target }: Event) {
    const value = (target as any).value.trim();
    if (value) {
      this.store.dispatch(MediaAction.loadOnlineImages({ query: value }));
    }
  }

  public onChooseOnlineImage(file: IFileType) {
    this.store.dispatch(MediaAction.uploadImageFromUrlInProgress({ status: 'progress' }));
    const image = file.images.filter((i) => i.size === 'md')[0];
    this.store.dispatch(MediaAction.uploadImageFromUrl({ id: file.id, url: image.url, purpose: this._purpose }));
  }

  public onDropZoneMaxFileExceeded(file: any) {
    // DropZone file object
    this.store.dispatch(
      MediaAction.showMediaWarning({
        text: `You can't attach more than ${this.dropzoneConfig.maxFiles} files in one time.`,
      }),
    );
  }

  public onCloseMediaWarning(event) {
    this.store.dispatch(MediaAction.hideMediaWarning());
  }
}
