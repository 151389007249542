import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { LessonSlideImageAspectRatioEnum } from 'lingo2-models';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-crop',
  templateUrl: './image-crop.component.html',
  styleUrls: ['./image-crop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageCropComponent extends ChangableComponent {
  @Input() imageURL = '';
  @Input() ratio?: LessonSlideImageAspectRatioEnum;
  @Output() changeImage: EventEmitter<{ type: string; data?: string }> = new EventEmitter<any>();
  croppedImage = '';
  loading = true;

  constructor(protected readonly cdr: ChangeDetectorRef, protected readonly platform: PlatformService) {
    super(cdr, platform);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded(): void {
    this.loading = false;
  }

  onCropImage(): void {
    this.changeImage.emit({ type: 'imageCropped', data: this.croppedImage });
  }

  onClose(): void {
    this.changeImage.emit({ type: 'cancelCropped' });
  }

  get aspectRatio() {
    switch (this.ratio) {
      case LessonSlideImageAspectRatioEnum.vertical:
        return Number((1 / 3).toFixed(2));
      case LessonSlideImageAspectRatioEnum.wide:
        return Number((154 / 19).toFixed(2));
      case LessonSlideImageAspectRatioEnum.square:
        return 1;
      case LessonSlideImageAspectRatioEnum.horizontal:
        return Number((16 / 9).toFixed(2));
    }
  }
}
