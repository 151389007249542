import { HttpResponse } from '@angular/common/http';
import { Directive } from '@angular/core';
import { PlatformService } from '@app/core/services';
import { IPagedResults, IPagination } from 'lingo2-models';
import { DestroyableComponent } from './destroyable.component';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class DestroyableService<T> extends DestroyableComponent {
  constructor(private itemConstructor?: GenericConstructor<T>, protected readonly platform?: PlatformService) {
    super(platform);
  }

  protected handleItemBody(item: T): T {
    return this.map(item);
  }

  protected handleItemsResponse(response: HttpResponse<T[]>): IPagedResults<T[]> {
    const pagination: IPagination = {
      page: +response.headers.get('X-Pagination-Page'),
      pageSize: +response.headers.get('X-Pagination-PageSize'),
      total: +response.headers.get('X-Pagination-Total'),
      totalPages: +response.headers.get('X-Pagination-TotalPages'),
    };
    // const totalRecords = +res.headers.get('X-InlineCount');
    return {
      results: response.body.map((i) => this.map(i)),
      pagination,
      ...pagination,
    };
  }

  protected map(item: Partial<T>): T {
    if (this.itemConstructor) {
      return new this.itemConstructor(item);
    }
    return item as T;
  }

  protected static mapItem<SubT>(item: Partial<SubT>, elementConstructor: GenericConstructor<SubT>): SubT {
    if (elementConstructor) {
      return new elementConstructor(item);
    }
    return item as SubT;
  }
}

type GenericConstructor<T> = new (values?: Partial<T>) => T;
