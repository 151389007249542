<app-onboarding-user-status
  [onboardingStatus]="status">
</app-onboarding-user-status>

<div class="badges">
  <app-teacher-badges-widget ></app-teacher-badges-widget>
</div>

<div class="expandable" *ngIf="isExpanded$ | async">

  <app-onboarding-checklist
    [options]="checklistOptions">
  </app-onboarding-checklist>

  <app-onboarding-qr-code *ngIf="showMobileQr"></app-onboarding-qr-code>

  <app-onboarding-title *ngIf="isTrackersAvailable()" [title]="'onboarding.upgrade-profile' | translate">
  </app-onboarding-title>

  <app-onboarding-trackers [trackers]="trackers"></app-onboarding-trackers>

  <app-onboarding-list-panel [options]="panelOptions" title="onboarding.what-teacher-rank-provide"></app-onboarding-list-panel>

</div>
