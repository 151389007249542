<div class="popover" [class.hidden]="variantActions" [@toolbar] (clickOutside)="onOutsideClick()" [clickOutsideEnabled]="clickOutsideEnabled">
  <div class="toolbar">
    <div *ngIf="config.richText" class="toolbar-item toolbar-item-text"
      (click)="select(itemTypes.richText)">
      <svg>
        <use [attr.xlink:href]="svgsetIcon('text')"></use>
      </svg>
    </div>
    <div *ngIf="config.image" class="toolbar-item toolbar-item-image"
      (click)="select(itemTypes.image)">
      <svg>
        <use [attr.xlink:href]="svgsetIcon('img')"></use>
      </svg>
    </div>
    <div *ngIf="config.audio" class="toolbar-item toolbar-item-sound"
      (click)="select(itemTypes.audio)">
      <svg>
        <use [attr.xlink:href]="svgsetIcon('sound')"></use>
      </svg>
    </div>
    <div *ngIf="config.video" class="toolbar-item toolbar-item-video"
      (click)="select(itemTypes.video)">
      <svg>
        <use [attr.xlink:href]="svgsetIcon('video')"></use>
      </svg>
    </div>
    <!-- <div *ngIf="config.formula" class="toolbar-item toolbar-item-formula"
      (click)="select('formula')">
      <svg>
        <use [attr.xlink:href]="svgsetIcon('formula')"></use>
      </svg>
    </div> -->
    <div *ngIf="config.check" class="toolbar-item toolbar-item-checkbox" (click)="check()">
      <svg>
        <use [attr.xlink:href]="svgsetIcon('checkbox')"></use>
      </svg>
    </div>
    <div *ngIf="config.delete" class="toolbar-item toolbar-item-basket" (click)="delete()">
      <svg>
        <use [attr.xlink:href]="svgsetIcon('basket')"></use>
      </svg>
    </div>
  </div>
</div>
