import { createAction, props } from '@ngrx/store';
import { UploadingImageStatus } from '@store/reducers/media-gallery.reducer';
import { IPagination, FilePurposeEnum, IFindFileFilter } from 'lingo2-models';
import { IFileType } from 'src/app/core/services';

export const dummy = createAction('[Media Gallery] Dummy');

export const showMediaWarning = createAction('[Media Gallery] Show media warning', props<{ text: string }>());
export const hideMediaWarning = createAction('[Media Gallery] Hide media warning');

export const loadRecentImages = createAction(
  '[Media Gallery] Load recent images',
  props<{ filter?: Partial<IFindFileFilter> }>(),
);
export const loadRecentImagesSuccess = createAction(
  '[Media Gallery] Load recent images success',
  props<{ images: IFileType[]; pagination: IPagination }>(),
);

export const loadStarImages = createAction(
  '[Media Gallery] Load favorite images',
  props<{ filter?: Partial<IFindFileFilter> }>(),
);
export const loadStarImagesSuccess = createAction(
  '[Media Gallery] Load favorite images success',
  props<{ images: IFileType[]; pagination: IPagination }>(),
);

export const loadUploadedImages = createAction(
  '[Media Gallery] Load uploaded images',
  props<{ filter?: Partial<IFindFileFilter> }>(),
);
export const loadUploadedImagesSuccess = createAction(
  '[Media Gallery] Load uploaded images success',
  props<{ images: IFileType[]; pagination: IPagination }>(),
);

export const loadOnlineImages = createAction('[Media Gallery] Load online images', props<{ query: string }>());
export const loadOnlineImagesSuccess = createAction(
  '[Media Gallery] Load online images finished',
  props<{ images: string[] }>(),
);
export const uploadImageFromUrl = createAction(
  '[Media Gallery] Upload image by url',
  props<{ id: string; url: string; purpose?: FilePurposeEnum }>(),
);
export const uploadImageFromUrlInProgress = createAction(
  '[Media Gallery] Upload image by url in progress',
  props<{ status: UploadingImageStatus }>(),
);
export const uploadImageFromUrlSuccess = createAction(
  '[Media Gallery] Upload image by url success',
  props<{ file: IFileType }>(),
);

export const selectFile = createAction('[Media Gallery] Select image in gallery', props<{ file: IFileType }>());

export const uploadImageByData = createAction(
  '[Media Gallery] Upload image by data',
  props<{ data: any; ext: string; purpose?: FilePurposeEnum }>(),
);
export const uploadImageByDataSuccess = createAction(
  '[Media Gallery] Upload image by data finished',
  props<{ file: IFileType }>(),
);

export const updateFileInLists = createAction('[Media Gallery] Update file in lists', props<{ file: IFileType }>());
export const changeFav = createAction('[Media Gallery] Change fav', props<{ file_id: string }>());
