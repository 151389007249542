import { createReducer, Action, on } from '@ngrx/store';
import { FileTypeEnum, IPagination, IFindFileFilter, MediaFormatEnum } from 'lingo2-models';
import { uniqueId } from 'lodash';
import { IFileType } from 'src/app/core/services';
import { AppState } from '..';
import * as MediaAction from '../actions/media-gallery.actions';
import { State as ProfileState, profileFeatureKey } from './profile.reducer';

export type UploadingImageStatus = 'init' | 'progress' | 'done';

/* States */
export interface State {
  lastUploadedFile: IFileType;
  mediaWarningText: string;
  recentImages: IFileType[];
  recentPagination: IPagination;
  recentFilter: Partial<IFindFileFilter>;
  starImages: IFileType[];
  starPagination: IPagination;
  starFilter: Partial<IFindFileFilter>;
  // onlineGallery: IFileType[];
  onlineSearchInProgress: boolean;
  uploadingImageId: string;
  onlineSearchResponse: string[];
  onlineSearchPagination: IPagination;
  onlineSearchQuery: string;
  selectedFile: IFileType;
  uploadingImageStatus: UploadingImageStatus;
}

const initialState: State = {
  lastUploadedFile: null,
  mediaWarningText: '',
  recentImages: [],
  recentPagination: {
    page: 1,
    pageSize: 50,
    total: null,
    totalPages: null,
  },
  recentFilter: null,
  starImages: [],
  starPagination: {
    page: 1,
    pageSize: 50,
    total: null,
    totalPages: null,
  },
  starFilter: null,
  // onlineGallery: [],
  onlineSearchInProgress: false,
  onlineSearchQuery: '',
  uploadingImageId: '',
  onlineSearchResponse: [],
  onlineSearchPagination: {
    page: 1,
    pageSize: 50,
    total: null,
    totalPages: null,
  },
  selectedFile: null,
  uploadingImageStatus: 'init',
};

/* Reducers */
const mediaGalleryReducer = createReducer(
  initialState,
  on(MediaAction.showMediaWarning, (store, { text }) => ({
    ...store,
    mediaWarningText: text,
  })),
  on(MediaAction.hideMediaWarning, (store) => ({
    ...store,
    mediaWarningText: '',
  })),
  on(MediaAction.loadRecentImages, (store, { filter }) => ({
    ...store,
    recentFilter: filter,
  })),
  on(MediaAction.loadRecentImagesSuccess, (store, { images, pagination }) => ({
    ...store,
    recentImages: images,
    recentPagination: pagination,
  })),
  on(MediaAction.loadStarImages, (store, { filter }) => ({
    ...store,
    starFilter: filter,
  })),
  on(MediaAction.loadStarImagesSuccess, (store, { images, pagination }) => ({
    ...store,
    starImages: images,
    starPagination: pagination,
  })),
  on(MediaAction.loadOnlineImages, (store, { query }) => ({
    ...store,
    onlineSearchInProgress: true,
    onlineSearchQuery: query,
  })),
  on(MediaAction.loadOnlineImagesSuccess, (store, { images }) => ({
    ...store,
    onlineSearchInProgress: false,
    onlineSearchResponse: images,
  })),
  on(MediaAction.uploadImageFromUrl, (store, { id }) => ({
    ...store,
    uploadingImageId: id,
  })),
  on(MediaAction.uploadImageFromUrlInProgress, (store, { status }) => ({
    ...store,
    uploadingImageStatus: status,
  })),
  on(MediaAction.uploadImageFromUrlSuccess, (store, { file }) => ({
    ...store,
    uploadingImageId: '',
    lastUploadedFile: file,
    selectedFile: file,
  })),
  on(MediaAction.uploadImageByDataSuccess, (store, { file }) => ({
    ...store,
    lastUploadedFile: file,
    selectedFile: file,
  })),
  on(MediaAction.selectFile, (store, { file }) => ({
    ...store,
    selectedFile: file,
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return mediaGalleryReducer(state, action);
}

/* Key */
const thisFeatureKey = 'mediaGallery';
export const mediaGalleryFeatureKey = thisFeatureKey;
export const getState = (state: AppState) => state[thisFeatureKey];

/* Selectors */
export const getSelectedFile = (state: AppState) => state[thisFeatureKey].selectedFile;

export const isShowWarning = (state: AppState) => state[thisFeatureKey].mediaWarningText.length > 0;
export const getWarningText = (state: AppState) => state[thisFeatureKey].mediaWarningText;

export const getRecentImages = (state: AppState) => state[thisFeatureKey].recentImages;
export const getRecentPagination = (state: AppState) => state[thisFeatureKey].recentPagination;
export const getRecentFilter = (state: AppState) => state[thisFeatureKey].recentFilter;

export const getStarImages = (state: AppState) => state[thisFeatureKey].starImages;
export const getStarPagination = (state: AppState) => state[thisFeatureKey].starPagination;
export const getStarFilter = (state: AppState) => state[thisFeatureKey].starFilter;

export const getLastUploadedFile = (state: AppState) => state[thisFeatureKey].lastUploadedFile;
export const getUploadedFileStatus = (state: AppState) => state[thisFeatureKey].uploadingImageStatus;
export const getOnlineSearchQuery = (state: AppState) => state[thisFeatureKey].onlineSearchQuery;
export const isOnlineSearchInProgress = (state: AppState) => state[thisFeatureKey].onlineSearchInProgress;
export const isUploadingImageInProgress = (state: AppState) =>
  state[thisFeatureKey].uploadingImageInProgress.length > 0;
export const getOnlineImages = (state: State): IFileType[] => {
  const thisState = state[thisFeatureKey] as State;
  const profileState = state[profileFeatureKey] as ProfileState;
  /*
  export interface IFileType {
    id: string;
    author_id: string;
    filename: string;
    type: FileTypeEnum;
    images?: IImageThumbnail[];
    media?: IMediaFile[];
  }

  export interface IImageThumbnail {
    id?: string;
    url?: string;
    size: string;
    width: number;
    height: number;
    round?: boolean;
    format?: string;
  }

 */
  return thisState.onlineSearchResponse
    .map((url) => {
      if (!url) {
        return null;
      }
      const urlData = url.split('/');
      const filename = urlData[urlData.length - 1].split('?')[0];
      return {
        id: uniqueId(),
        filename,
        author_id: profileState.me?.id,
        media: [
          {
            format: MediaFormatEnum.original,
            url,
          },
        ],
        type: FileTypeEnum.image,
        images: [
          {
            size: 'md',
            url,
            height: 100,
            width: 100,
          },
        ],
        isProcessing: url === thisState.uploadingImageId,
      };
    })
    .filter(Boolean);
};
