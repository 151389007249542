import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalModule } from '@core/components/modal/modal.module';
import { SpinnersModule } from '@core/components/spinners/spinners.module';
import { TranslateModule } from '@ngx-translate/core';
import { ImageCropperModule } from 'ngx-image-cropper';
import { OnUiButtonModule } from 'onclass-ui';
import { ImageCropComponent } from './image-crop.component';

@NgModule({
  declarations: [ImageCropComponent],
  imports: [CommonModule, ImageCropperModule, ModalModule, TranslateModule, SpinnersModule, OnUiButtonModule],
  exports: [ImageCropComponent],
})
export class ImageCropModule {}
