import { Overlay, OverlayRef, OverlayPositionBuilder } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  Directive,
  HostListener,
  Input,
  OnInit,
  OnDestroy,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Output,
} from '@angular/core';
import { TaskAnswerVariantPopoverComponent } from './task-answer-variant-popover.component';
import { IVariantPopoverConfig, ToolbarItemTypeEnum } from './task-answer-variant-popover.models';

const OFFSET = 8;

@Directive({
  selector: '[appTaskAnswerVariantPopover]',
})
export class TaskAnswerVariantPopoverDirective implements OnInit, OnDestroy {
  @Input('appTaskAnswerVariantPopover') public config: IVariantPopoverConfig = {
    richText: true,
  };

  @Output() public selected = new EventEmitter<ToolbarItemTypeEnum>();
  @Output() public checked = new EventEmitter<boolean>();
  @Output() public deleted = new EventEmitter<boolean>();

  private overlayRef: OverlayRef;
  private isOpened = false;

  public constructor(
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private overlay: Overlay,
  ) {}

  public ngOnInit() {}

  public ngOnDestroy() {
    this.hide();
  }

  @HostListener('click', ['$event']) public onClick() {
    if (!this.isOpened) {
      this.show();
    } else {
      this.hide();
    }
  }

  private init(): void {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withLockedPosition()
      .withPositions([
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'bottom',
          offsetY: -OFFSET,
        },
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });
  }

  private destroy(): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }

  private show() {
    this.init();

    this.isOpened = true;

    const popoverPortal = new ComponentPortal(TaskAnswerVariantPopoverComponent);
    const popoverRef: ComponentRef<TaskAnswerVariantPopoverComponent> = this.overlayRef.attach(popoverPortal);

    popoverRef.instance.config = this.config;
    popoverRef.instance.onClose = () => {
      this.hide();
    };

    popoverRef.instance.onSelect = (type: ToolbarItemTypeEnum) => {
      this.selected.emit(type);
      if (type === ToolbarItemTypeEnum.richText || type === ToolbarItemTypeEnum.image) {
        this.hide();
      }
    };
    popoverRef.instance.onCheck = () => {
      this.checked.emit(true);
      // this.hide();
    };
    popoverRef.instance.onDelete = () => {
      this.deleted.emit(true);
      // this.hide();
    };
  }

  private hide() {
    this.isOpened = false;
    this.destroy();
  }
}
