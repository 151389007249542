export const toolsList = [
  {
    type: 'chat ',
    title: 'first-contact.school-tools.list.title1',
    text: 'first-contact.school-tools.list.text1',
  },
  {
    type: 'app',
    title: 'first-contact.school-tools.list.title2',
    text: 'first-contact.school-tools.list.text2',
  },
  {
    type: 'statistics',
    title: 'first-contact.school-tools.list.title3',
    text: 'first-contact.school-tools.list.text3',
  },
  {
    type: 'payments',
    title: 'first-contact.school-tools.list.title4',
    text: 'first-contact.school-tools.list.text4',
  },
];
