<section class="first-contact-selector" [class.mobile]="deviceService.isMobile()">
  <div class="header">
    <div class="icon"></div>
    <div class="role-selector">
      <div *ngFor="let role of roles; let i = index" class="role-selector-item">
        <on-ui-button [label]="role.label"
                      [class]="role.selected ? 'primary' : 'primary outline'"
                      (onclick)="onSelectedRole(i)">
        </on-ui-button>
      </div>
    </div>
  </div>

  <div class="selector-block" [class.limited]="isStudentOnMe">
    <div class="text-block">
      <h3 *ngIf="!isStudentOnMe" class="title" [innerHTML]="title | translate"></h3>
      <h3 *ngIf="isStudentOnMe" class="title">{{stripHtml(title | translate)}}</h3>

      <ul class="list" *ngIf="list.length">
        <li *ngFor="let item of list" class="list-item">{{ item | translate }}</li>
      </ul>
    </div>
    <div class="image-block" [ngClass]="isLimitedVersion ? 'student' : role()"></div>
  </div>

  <p class="text">{{ text() | translate }}</p>
</section>
