import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PlatformService } from '@core/services/platform.service';
import { DestroyableComponent } from '@models/destroyable.component';
import { Store } from '@ngrx/store';
import { setMe } from 'lingo2-chat-app';
import { User, UserProfile } from 'lingo2-models';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { loadMeSuccess } from 'src/app/store/actions/profile.actions';
import { setCurrentProfile } from 'src/app/store/actions/users.actions';
import * as UsersAction from '../../store/actions/users.actions';

const DEBUG_ENABLE_PASSPHRASE = '1';

@Injectable({
  providedIn: 'root',
})
export class ContextService extends DestroyableComponent {
  private meSubject = this.register(new ReplaySubject<User>(1));
  public me$ = this.meSubject.asObservable();
  private _me: User;

  private userSubject = this.register(new ReplaySubject<User>(1));
  public user$ = this.userSubject.asObservable();
  private _user: User;

  private profileSubject = this.register(new ReplaySubject<UserProfile>(1));
  public profile$ = this.profileSubject.asObservable();
  private _profile: UserProfile;

  public debugSubject = this.register(new ReplaySubject<boolean>(1));
  public debug$ = this.debugSubject.asObservable();

  constructor(private route: ActivatedRoute, private store: Store, protected readonly platform: PlatformService) {
    super(platform);
    this.init();
  }

  protected init() {
    this.onBrowserOnly(() => {
      this.initDebugMode();
    });
  }

  protected initDebugMode() {
    this.debug = this.debug;
    this.route.queryParamMap.pipe(takeUntil(this.destroyed$)).subscribe((query) => {
      if (query.has('debug')) {
        this.debug = String(query.get('debug'));
      }
    });
    if (!window['debug']) {
      window['debug'] = {
        // @example: in console type `debug.enable(1)`
        enable: (passphrase) => {
          this.debug = String(passphrase);
        },
        // @example: in console type `debug.disable()`
        disable: () => {
          this.debug = null;
        },
      };
    }
  }

  public updateMe(me: User) {
    this.store.dispatch(loadMeSuccess({ me }));
    this.meSubject.next((this._me = me));

    if (this.isBrowser) {
      this.store.dispatch(setMe({ me }));
    }
  }

  public updateUser(user: User) {
    UsersAction.setCurrentUser({ user });
    this.userSubject.next((this._user = user));
  }

  public updateProfile(profile: UserProfile) {
    this.store.dispatch(setCurrentProfile({ profile }));
    this.profileSubject.next((this._profile = profile));
  }

  public patchProfile(patch: Partial<UserProfile>) {
    const profile = this._profile;
    this._profile = new UserProfile({ ...profile, ...patch });
    this.profileSubject.next(this._profile);
  }

  protected get debug(): string {
    if (localStorage || false) {
      return localStorage.getItem('APP_DBG');
    }
    return null;
  }

  protected set debug(passphrase: string) {
    if (passphrase) {
      this.debugSubject.next(passphrase === DEBUG_ENABLE_PASSPHRASE);
      if (localStorage || false) {
        localStorage.setItem('APP_DBG', passphrase);
      }
    } else {
      this.debugSubject.next(false);
      if (localStorage || false) {
        localStorage.removeItem('APP_DBG');
      }
    }
  }
}
