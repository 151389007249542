import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MobileModalModule } from '@core/components/mobile-modal/mobile-modal.module';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideModule } from 'ng-click-outside';

// компоненты
import { CurrencySelectorComponent } from './currency-selector.component';

@NgModule({
  declarations: [CurrencySelectorComponent],
  imports: [CommonModule, TranslateModule, ClickOutsideModule, MobileModalModule],
  exports: [CurrencySelectorComponent],
})
export class CurrencySelectorModule {}
