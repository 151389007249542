import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { NotVerifiedWidgetComponent } from './not-verified-widget.component';

@NgModule({
  declarations: [NotVerifiedWidgetComponent],
  imports: [CommonModule, TranslateModule],
  exports: [NotVerifiedWidgetComponent],
})
export class NotVerifiedWidgetModule {}
