import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { DestroyableComponent } from '@app/models/destroyable.component';
import { PlatformService } from '@core/services';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { getMe } from '@store/reducers/profile.reducer';
import { User, UserStatusEnum } from 'lingo2-models';
import { takeUntil, tap } from 'rxjs';

export interface PushNotificationUserSubscriptionDetails {
  userId: string;
  slug: string;
  firstName?: string;
  lastName?: string;
  timezone?: string;
}

export interface PushNotificationSubscriptionDetails extends PushNotificationUserSubscriptionDetails {
  endpoint: string;
  p256dh: string;
  auth: string;
  expirationTime?: Date;
}

@Injectable({ providedIn: 'root' })
export class ServiceWorkerMainComponent extends DestroyableComponent {
  private readonly serverPublicKey = environment.webpush.public_key;
  private readonly subscriptionLocalstoreKey = 'user_device_subscription';

  constructor(
    private http: HttpClient,
    private serviceWorkerPush: SwPush,
    private serviceWorkerUpdate: SwUpdate,
    private store: Store,
    protected readonly platform: PlatformService,
  ) {
    super(platform);
    if (!this.isBrowser) {
      return;
    }
    if (!this.serverPublicKey) {
      // TODO ключ не задан, пожаловаться?
      return;
    }
    if (!this.serviceWorkerUpdate.isEnabled) {
      // TODO what should happen when service worker is not supported by browser
      return;
    }

    const storedSubscriptionValue = localStorage.getItem(this.subscriptionLocalstoreKey);
    if (!storedSubscriptionValue) {
      this.store
        .select(getMe)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((me: User) => {
          if (me && me.status !== UserStatusEnum.guest) {
            // TODO check feature web_push_notifications
            this.subscribeNotifications({
              slug: me.slug,
              userId: me.id,
              firstName: me.first_name,
              lastName: me.last_name,
              timezone: me.timezone,
            });
          }
        });
    }
  }

  private sendSubscription(sub: PushNotificationSubscriptionDetails) {
    const url = `${environment.notificator_url}/user-device-subscription`;
    this.http
      .post(url, sub, { observe: 'body' })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        localStorage.setItem(this.subscriptionLocalstoreKey, JSON.stringify(sub));
      });
  }

  private sendSubscriptionFailed(error: Error) {
    const url = `${environment.notificator_url}/user-device-subscription/failed`;
    this.http.post(url, { error }, { observe: 'body' }).pipe(takeUntil(this.destroyed$)).subscribe();
  }

  private subscribeNotifications(pushNotificationDetails: PushNotificationUserSubscriptionDetails): void {
    if (this.serviceWorkerPush.isEnabled) {
      this.serviceWorkerPush
        .requestSubscription({
          serverPublicKey: this.serverPublicKey,
        })
        .then((sub: PushSubscription) => {
          const subJson = sub.toJSON();
          this.sendSubscription({
            ...pushNotificationDetails,
            endpoint: sub.endpoint,
            auth: subJson.keys['auth'],
            p256dh: subJson.keys['p256dh'],
          });
        })
        .catch((error) => this.sendSubscriptionFailed(error));
    }

    this.serviceWorkerPush?.messages
      .pipe(
        tap((res: { notification: { data: { userId: string; pushNotificationId: string } } }) => {
          try {
            const url = `${environment.notificator_url}/push-notification/delivery-status`;
            const { notification } = res;
            this.http
              .patch(url, {
                userId: notification?.data?.userId,
                pushNotificationId: notification?.data?.pushNotificationId,
              })
              .pipe(takeUntil(this.destroyed$))
              // eslint-disable-next-line rxjs/no-nested-subscribe
              .subscribe();
          } catch (e) {
            console.error(e);
            /** NOP */
          }
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }
}
