import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SidebarService } from '@core/layouts/main/sidebar/sidebar.service';
import {
  AccountService,
  ContextService,
  FeaturesService,
  PlatformService,
  ProfileService,
  ScreenService,
} from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { getMe, getMyProfile } from '@store/reducers/profile.reducer';
import {
  FeatureEnum, IAccountCheck,
  IUserAccess,
  User,
  UserAchievementEnum,
  UserProfile,
  UserProfileVerificationEnum,
  UserSegmentEnum,
} from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { takeUntil, forkJoin, switchMap } from 'rxjs';
import { first, tap } from 'rxjs/operators';

export enum ProfileStepsEnum {
  personal = 'personal',
  teaching = 'teaching',
  services = 'services',
  schedule = 'schedule',
  payinfo = 'payinfo',
  card = 'card', // СТарый биллинг с банк картами
  payout = 'payout', // Новый биллинг
  payment = 'payment', // Новый биллинг
  application = 'application',
  main = 'main',
  about = 'about',
  contacts = 'contacts',
  education = 'education',
  experience = 'experience',
}

interface IMenuItem {
  name: string;
  route?: ProfileStepsEnum;
  subMenu?: IMenuItem[];
  disabled?: boolean;
}

@Component({
  selector: 'app-profile-left-sidebar',
  templateUrl: './profile-left-sidebar.component.html',
  styleUrls: ['./profile-left-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileLeftSidebarComponent extends ChangableComponent implements OnInit {
  public me: User;
  public userProfile: UserProfile;
  public userAccountCheck: IAccountCheck;
  public userSettings: IUserAccess;
  public verification_status: UserProfileVerificationEnum;
  public hasSegment = AccountService.hasSegment;
  public hasAchievement = AccountService.hasAchievement;
  public visible = false;
  private _clickOutsideEnabled = false;
  private _timer: any;
  public profileMenuList: IMenuItem[];
  public profileMenuListTeacher: IMenuItem[] = [
    {
      name: 'profile-steps.personal-information',
      route: ProfileStepsEnum.personal,
      disabled: false,
      subMenu: [
        { name: 'profile-steps.acquaintance', route: ProfileStepsEnum.main },
        { name: 'profile-steps.about-you', route: ProfileStepsEnum.about },
        { name: 'profile-steps.contacts', route: ProfileStepsEnum.contacts },
        { name: 'profile-steps.education', route: ProfileStepsEnum.education },
        { name: 'profile-steps.work-experience', route: ProfileStepsEnum.experience },
      ],
    },
    { name: 'profile-steps.teaching', route: ProfileStepsEnum.teaching, disabled: false },
    {
      name: 'profile-steps.payment-info',
      route: ProfileStepsEnum.payment,
      disabled: false,
      subMenu: [
        { name: 'profile-steps.payout-info', route: ProfileStepsEnum.card, disabled: this.isAvailableBillingV2 },
        {
          name: 'profile-steps.payin-info',
          route: ProfileStepsEnum.payment,
          disabled: !(this.isAvailableBillingV2 && this.isAvailablePayout),
        },
        {
          name: 'profile-steps.payout-info',
          route: ProfileStepsEnum.payout,
          disabled: !(this.isAvailableBillingV2 && this.isAvailablePayout),
        },
      ],
    },
    {
      name: 'profile-steps.services',
      route: ProfileStepsEnum.services,
      disabled: !this.features.isAvailable(FeatureEnum.profile_step_services),
    },
    {
      name: 'profile-steps.schedule',
      route: ProfileStepsEnum.schedule,
      disabled: !this.features.isAvailable(FeatureEnum.profile_step_calendar),
    },
    {
      name: 'profile-steps.application',
      route: ProfileStepsEnum.application,
      disabled: !this.features.isAvailable(FeatureEnum.profile_step_app),
    },
  ];

  public profileMenuListStudent: IMenuItem[] = [
    {
      name: 'profile-steps.personal-information',
      route: ProfileStepsEnum.personal,
      disabled: false,
      subMenu: [
        { name: 'profile-steps.acquaintance', route: ProfileStepsEnum.main },
        { name: 'profile-steps.about-you', route: ProfileStepsEnum.about, disabled: this.isLimitedVersion },
        { name: 'profile-steps.contacts', route: ProfileStepsEnum.contacts, disabled: this.isLimitedVersion },
      ],
    },
    {
      name: 'profile-steps.application',
      route: ProfileStepsEnum.application,
      disabled: !this.features.isAvailable(FeatureEnum.profile_step_app),
    },
  ];

  public activeTab: ProfileStepsEnum | string;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public screenService: ScreenService,
    public features: FeaturesService,
    public profileService: ProfileService,
    public accountService: AccountService,
    public contextService: ContextService,
    public sidebarService: SidebarService,
    public store: Store,
    public deviceService: DeviceDetectorService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngOnInit() {
    this.store
      .select(getMe)
      .pipe(
        tap((me) => {
          this.me = me;
          this.profileMenuList = this.isTeacher ? this.teacherMenu : this.studentMenu;
        }),
        switchMap((me) =>
          forkJoin([
            this.profileService.getProfile(me.id),
            this.profileService.getSettings(me.id),
            this.accountService.accountCheck(),
          ]),
        ),
        takeUntil(this.destroyed$),
      )
      .subscribe(([profile, settings, accountCheck]) => {
        this.userProfile = profile;
        this.userAccountCheck = accountCheck;
        this.verification_status = profile?.verification_status;
        this.userSettings = settings;
        this.detectChanges();
      });

    this.route.paramMap.pipe(takeUntil(this.destroyed$)).subscribe((query) => {
      this.activeTab = query.get('step') ? query.get('step') : ProfileStepsEnum.main;
      this.detectChanges();
    });

    this.sidebarService.leftState$.pipe(takeUntil(this.destroyed$)).subscribe((visible) => {
      this.visible = visible;
      this.screenService.setBodyFixed(visible);
      this.clickOutsideEnabled = visible;
      this.detectChanges();
    });
  }

  public get teacherMenu() {
    return this.profileMenuListTeacher.filter((item) => {
      if (item.subMenu) {
        item.subMenu = item.subMenu.filter((subitem) => !subitem.disabled);
      }

      if (!item.disabled) {
        return item;
      }
    });
  }

  public get studentMenu() {
    return this.profileMenuListStudent.filter((item) => {
      if (item.subMenu) {
        item.subMenu = item.subMenu.filter((subitem) => !subitem.disabled);
      }

      if (!item.disabled) {
        return item;
      }
    });
  }

  public get isAvailableBillingV2() {
    return this.features.isAvailable(FeatureEnum.billing_v2);
  }

  public get isAvailablePayout() {
    return this.features.isAvailable(FeatureEnum.finance_payouts);
  }

  public get clickOutsideEnabled(): boolean {
    return this._clickOutsideEnabled;
  }

  public set clickOutsideEnabled(value: boolean) {
    this.onBrowserOnly(() => {
      if (value) {
        this.clearTimeout(this._timer);
        this._timer = this.setTimeout(() => {
          this._clickOutsideEnabled = true;
          this.detectChanges();
        }, 200);
      } else {
        this._clickOutsideEnabled = false;
        this.detectChanges();
      }
    });
  }

  public closeSidebar() {
    this.sidebarService.setLeftSidebarState(false);
  }

  public navigateTo(url: string): void {
    if (url === ProfileStepsEnum.personal) {
      url = ProfileStepsEnum.main;
    } else if (url === ProfileStepsEnum.payinfo) {
      url = ProfileStepsEnum.payment;
    }
    this.router.navigate(['/profile/' + url]);
    this.closeSidebar();
  }

  public isActive(step: IMenuItem): boolean {
    const ifSubMenu = step?.subMenu?.find((el) => el.route === this.activeTab);
    return this.activeTab === step.route || !!ifSubMenu;
  }

  public isChecked(step: IMenuItem) {
    switch (step.route) {
      case ProfileStepsEnum.personal:
        return AccountService.isTeacherAllStepsFilled(this.me, this.userProfile, this.userSettings);
      case ProfileStepsEnum.teaching:
        return this.userProfile?.teaching_subjects?.length > 0;
      case ProfileStepsEnum.services:
        return this.hasAchievement(this.me, UserAchievementEnum.created_user_services_once);
      case ProfileStepsEnum.schedule:
        return !this.userAccountCheck?.no_working_hours;
      case ProfileStepsEnum.payment:
        return !this.userAccountCheck?.no_payins && !this.userAccountCheck?.no_payouts;
      case ProfileStepsEnum.payinfo:
        return !this.userAccountCheck?.no_payins;
      case ProfileStepsEnum.payout:
        return !this.userAccountCheck?.no_payouts;
      case ProfileStepsEnum.application:
        return this.hasSegment(this.me, UserSegmentEnum.use_mobile_app);
      case ProfileStepsEnum.main:
        return !!(this.me.first_name && this.me.last_name && this.me.country_id && this.me.userpic_id);
      case ProfileStepsEnum.about:
        return !!(this.me?.spoken_languages?.length > 0 && this.userProfile?.birth_date && this.userProfile?.about);
      case ProfileStepsEnum.contacts:
        return !!(this.userSettings?.email_confirmed && this.userSettings?.mobile_phone_confirmed);
      case ProfileStepsEnum.education:
        return this.userProfile?.education_schools?.length > 0;
      case ProfileStepsEnum.experience:
        return this.userProfile?.work_experience === null || this.userProfile?.work_experience?.length > 0;
    }
  }

  public isClickable(step: IMenuItem) {
    if (this.isLimitedVersion) {
      return true;
    }
    if (this.isTeacher) {
      switch (step.route) {
        case ProfileStepsEnum.services:
        case ProfileStepsEnum.schedule:
        case ProfileStepsEnum.payinfo:
        case ProfileStepsEnum.card:
        case ProfileStepsEnum.application:
          return (
            this.hasSegment(this.me, UserSegmentEnum.has_verified_teacher_profile) ||
            this.verification_status === UserProfileVerificationEnum.sent ||
            this.verification_status === UserProfileVerificationEnum.resent
          );
        case ProfileStepsEnum.teaching:
          return (
            !!(this.me.first_name && this.me.last_name && this.me.country_id && this.me.userpic_id) &&
            !!(this.me?.spoken_languages.length > 0 && this.userProfile?.birth_date && this.userProfile?.about) &&
            !!(this.userSettings?.email_confirmed && this.userSettings?.mobile_phone_confirmed) &&
            this.userProfile?.education_schools?.length > 0 &&
            (this.userProfile?.work_experience === null || this.userProfile?.work_experience?.length > 0)
          );
        default:
          return true;
      }
    } else {
      return true;
    }
  }

  public get isTeacher(): boolean {
    return AccountService.isAsIfTeacher(this.me);
  }

  public get isLimitedVersion(): boolean {
    return FeaturesService.isLimitedVersion;
  }

  public isLimited(subItem: IMenuItem) {
    return this.isLimitedVersion && subItem.name === 'profile-steps.acquaintance' && !this.isTeacher;
  }
}
