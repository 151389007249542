<div class="gallery-wrap" [style.width]="containerWidth + 'px'">

  <div *ngIf="(reachesLeftFlag && isDragScrollEnabled)" class="flap flap--left">
    <div class="slide-btn" (click)="moveLeft()">
      <svg viewBox="0 0 12 22" class="slide-btn-ico" xmlns="http://www.w3.org/2000/svg">
        <use [attr.xlink:href]="'/assets/icons/arrow.svg#arrow'"></use>
      </svg>
    </div>
  </div>
  <div *ngIf="(reachesRightFlag && isDragScrollEnabled)" class="flap flap--right">
    <div class="slide-btn slide-btn--right" (click)="moveRight()">
      <svg viewBox="0 0 12 22" class="slide-btn-ico" xmlns="http://www.w3.org/2000/svg">
        <use [attr.xlink:href]="'/assets/icons/arrow.svg#arrow'"></use>
      </svg>
    </div>
  </div>

  <ng-template [ngIf]="images.length > 0">
    <ng-template [ngIf]="isDragScrollEnabled" [ngIfElse]="nav2">
      <drag-scroll #gallery class="gallery-scroll" snap-disabled="true" scroll-x-wheel-enabled="true"
        (reachesLeftBound)="reachesLeftFlag = !$event" (reachesRightBound)="reachesRightFlag = !$event">
        <div *ngFor="let drag of galleryScrollPages" drag-scroll-item class="gallery-scroll-page"></div>
        <app-media-image-gallery [images]="images" (choose)="onChooseImage($event)" [disableStars]="disableStars"
          (configured)="onGalleryConfigured($event)"></app-media-image-gallery>
      </drag-scroll>
    </ng-template>
    <ng-template #nav2>
      <div class="gallery-scroll mobile">
        <app-media-image-gallery [images]="images" (choose)="onChooseImage($event)" [disableStars]="disableStars"
          (configured)="onGalleryConfigured($event)"></app-media-image-gallery>
      </div>
    </ng-template>
  </ng-template>
</div>
