import { Injectable } from '@angular/core';
import { DestroyableComponent } from '@models/destroyable.component';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService extends DestroyableComponent {
  private leftVisible = false;
  private leftStateSubject = this.register(new BehaviorSubject<boolean>(this.leftVisible));
  public leftState$ = this.leftStateSubject.asObservable();

  private rightVisible = false;
  private rightStateSubject = this.register(new BehaviorSubject<boolean>(this.rightVisible));
  public rightState$ = this.rightStateSubject.asObservable();

  public setLeftSidebarState(visible: boolean) {
    this.leftVisible = visible;
    this.leftStateSubject.next(this.leftVisible);
  }

  public toggleLeftSidebar() {
    this.leftVisible = !this.leftVisible;
    this.leftStateSubject.next(this.leftVisible);
  }

  public setRightSidebarState(visible: boolean) {
    this.rightVisible = visible;
    this.rightStateSubject.next(this.rightVisible);
  }

  public toggleRightSidebar() {
    this.rightVisible = !this.rightVisible;
    this.rightStateSubject.next(this.rightVisible);
  }
}
