import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ChangableComponent } from '@models/changable.component';
import { currencies, Currency, CurrencyEnum } from 'lingo2-models';
import { takeUntil } from 'rxjs/operators';
import { AccountService, BillingService, ContextService, ScreenService } from '../../services';

@Component({
  selector: 'app-currency-selector',
  templateUrl: './currency-selector.component.html',
  styleUrls: ['./currency-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencySelectorComponent extends ChangableComponent implements OnInit {
  public currency: Currency;
  public currencies: Currency[];
  public listOpened = false;
  public isSmallScreen = this.screenService.isSmallScreen;

  constructor(
    private accountService: AccountService,
    private billingService: BillingService,
    private contextService: ContextService,
    private screenService: ScreenService,
    protected cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  ngOnInit() {
    this.currencies = [
      currencies[CurrencyEnum.usd],
      currencies[CurrencyEnum.eur],
      currencies[CurrencyEnum.rub],
      currencies[CurrencyEnum.try],
      currencies[CurrencyEnum.amd],
    ];

    this.contextService.me$.pipe(takeUntil(this.destroyed$)).subscribe((me) => {
      if (!me) {
        return;
      }
      this.currency = currencies[me.currency_id];
      this.markForCheck();
    });
  }

  public setCurrency(currency_id: CurrencyEnum) {
    this.toggleList();

    this.accountService
      .updateAccount({ currency_id })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((account) => {
        location.reload();
      });
  }

  public toggleList() {
    this.listOpened = !this.listOpened;
    this.markForCheck();
  }

  public closeList() {
    if (this.isSmallScreen) {
      return;
    }
    this.listOpened = false;
    this.markForCheck();
  }
}
