<ng-container *ngIf="deviceService.isDesktop(); else mobile_menu">
  <section class="profile-left-sidebar">
    <ng-container *ngTemplateOutlet="menu"></ng-container>
  </section>
</ng-container>

<ng-template #mobile_menu>
  <div class="left-sidebar-floating" [class.visible]="visible">
    <div class="shade" (click)="closeSidebar()"></div>
    <section class="profile-left-sidebar menu">
      <ng-container *ngTemplateOutlet="menu"></ng-container>
    </section>
  </div>
</ng-template>

<ng-template #menu>
  <nav class="navigate">
    <ul class="main-list">
      <li class="main-list-item" [class.limited]="isLimitedVersion" *ngFor="let item of profileMenuList; let i = index">
        <div class="list-title"
             [class.active]="isActive(item)"
             [class.checked]="isChecked(item)">
          <div><p>{{ i + 1 }}</p></div>
          <p (click)="isClickable(item) ? navigateTo(item.route) : ''">{{ item.name | translate }}</p>
        </div>
        <ng-container *ngIf="item.subMenu?.length>0">
          <ul>
            <li *ngFor="let subItem of item.subMenu"
                [class.limited]="isLimited(subItem)">
              <div class="list-sub-title"
                   [attr.data-test]="subItem.route"
                   [class.active]="isActive(subItem)"
                   [class.checked]="isChecked(subItem)">
                <div></div>
                <p (click)="navigateTo(subItem.route)">{{ subItem.name | translate }}</p>
              </div>
            </li>
          </ul>
        </ng-container>
      </li>
    </ul>
  </nav>
</ng-template>
