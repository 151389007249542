import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { logger } from '@app/core/helpers/logger';
import { ContextService, DropzoneConfig, FilesService, IconsService, IFileType } from '@core/services';
import { DestroyableComponent } from '@models/destroyable.component';
import { FilePurposeEnum } from 'lingo2-models';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { takeUntil } from 'rxjs/operators';

export enum DropzoneFileTypesEnum {
  audio,
  image,
}

export type DropzoneFileType = 'audio' | 'image';

interface IFileTypeConfig {
  types: string;
  purpose: FilePurposeEnum;
  icon: string;
  placeholder: string;
}
// https://www.sitepoint.com/mime-types-complete-list/
// tslint:disable-next-line: max-line-length
// https://developer.mozilla.org/ru/docs/Web/HTTP/Basics_of_HTTP/MIME_types/%D0%9F%D0%BE%D0%BB%D0%BD%D1%8B%D0%B9_%D1%81%D0%BF%D0%B8%D1%81%D0%BE%D0%BA_%D1%82%D0%B8%D0%BF%D0%BE%D0%B2_MIME

@Component({
  selector: 'app-dropzone',
  templateUrl: './dropzone.component.html',
  styleUrls: ['./dropzone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropzoneComponent extends DestroyableComponent implements OnInit {
  @Input() fileType: DropzoneFileType;
  @Input() isError = false;
  @Output() uploaded = new EventEmitter<IFileType>();

  public debug = false;
  public dropzoneConfig: DropzoneConfigInterface = {
    acceptedFiles: null,
    maxFilesize: 100 * 1024 * 1024, // 100Mb
    paramName: 'file',
    uploadMultiple: false,
    clickable: true,
    autoReset: 1,
    errorReset: 1,
    cancelReset: 1,
    dictDefaultMessage: '',
    timeout: 600 * 1000, // 600s = 10m
    createImageThumbnails: false,
  };

  public isUploading = false;
  public isDropzoneDisabled = false;
  public isMediaModalOpen = false;
  public file: IFileType; // TODO File!
  public percent = 0;
  public svgsetIcon = IconsService.svgsetIconUrl;

  public fileTypeConfigs: { [key: string]: IFileTypeConfig };

  public fileTypeConfig: IFileTypeConfig;
  public filePurpose = FilePurposeEnum.content;

  public constructor(
    protected dzConfig: DropzoneConfig,
    protected filesService: FilesService,
    protected cdr: ChangeDetectorRef,
    protected contextService: ContextService,
  ) {
    super();
    this.fileTypeConfigs = {
      audio: {
        types: 'audio/aiff,audio/x-ms-wma,audio/mp3,audio/mpeg', // ? audio/wav audio/ogg audio/webm
        purpose: FilePurposeEnum.audio,
        icon: '/assets/icons/cloud.svg#podcast',
        placeholder: 'constructor2.dropzone.audio-placeholder1',
      },
      image: {
        types: 'image/jpeg,image/jpg,image/png',
        purpose: FilePurposeEnum.content,
        icon: '/assets/icons/cloud.svg#image',
        placeholder: 'constructor2.dropzone.image-placeholder1',
      },
    };
  }

  ngOnInit() {
    this.fileTypeConfig = this.fileTypeConfigs[this.fileType];
    this.configureDropzone();

    this.contextService.debug$.pipe(takeUntil(this.destroyed$)).subscribe((debug) => (this.debug = debug));
  }

  private configureDropzone() {
    this.dropzoneConfig = this.dzConfig.combine(this.dropzoneConfig, {
      acceptedFiles: this.fileTypeConfig.types,
      url: this.dzConfig.url + '/file/upload+process?purpose=' + this.fileTypeConfig.purpose, // особый URL для загрузки
    });
  }

  public onCloseSizeAlert() {
    this.isDropzoneDisabled = false;
  }

  public onUploadInit(args: any): void {
    // logger.log('dropzone:onUploadInit', args);
  }

  public onAddedFile(args: any): void {
    logger.log('dropzone:onAddedFile', args);
    if (args.size > this.dropzoneConfig.maxFilesize) {
      this.isUploading = false;
      this.isDropzoneDisabled = true;
    }
  }

  public onUploadStart(args: any): void {
    // logger.log('dropzone:onUploadStart', args);
    this.isUploading = true;
  }

  public onUploadError(args: any): void {
    console.error('dropzone:onUploadError', args);
    this.isUploading = false;
  }

  public onUploadProgress(args: any): void {
    logger.log('dropzone:onUploadProgress', args);
    const [, file] = args;
    this.percent = file;
  }

  public onUploadSuccess(args: any): void {
    logger.log('dropzone:onUploadSuccess', args);
    this.isUploading = false;
    // this.state.isMetaFormOpened = true;
    const [, file] = args;

    this.file = file;
    this.uploaded.emit(this.file);
  }

  public get isInvalid(): boolean {
    return false;
    // return !!this.warnings.find((w) => w.property === 'content.media');
  }

  // Image

  public showMediaModal(event: Event) {
    if (this.fileType !== 'image') {
      return;
    }
    event.preventDefault();
    event.cancelBubble = true;
    this.isMediaModalOpen = true;
  }

  public setImage(file: IFileType) {
    this.file = file;
    this.uploaded.emit(this.file);
  }

  public closeMediaModal() {
    this.isMediaModalOpen = false;
  }
}
