import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SpinnersModule } from '@app/core/components/spinners/spinners.module';
import { BlockingSpinnerComponent } from './blocking-spinner.component';

@NgModule({
  declarations: [BlockingSpinnerComponent],
  imports: [CommonModule, SpinnersModule],
  exports: [BlockingSpinnerComponent],
})
export class BlockingSpinnerModule {}
