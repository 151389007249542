<ng-template [ngIf]="isVisible">
  <ng-content select="app-modal-ad"></ng-content>
</ng-template>

<div class="modal" [class.hide]="!isVisible" *ngIf="isBrowser">
  <div class="modal-virtual-scroll" #scrollHolder
       [class.hover]="hoverFlag"
       [class.none]="complain || authPage">
    <div [style.height.px]="customHeight">
      <div class="window">
        <div class="grid-wrap">
          <div class="grid-header" (click)="onClose($event)"></div>
          <div class="grid-center" #gridCenter [ngStyle]="{'transform': 'scale(' + scaleNumber + ')'}">
            <div class="grid-left" (click)="onClose($event)"></div>
            <div class="modal-body shadow"
                 #modalBody
                 [class.short]="short"
                 [class.long]="long"
                 [class.thin]="thin"
                 [class.wide]="wide"
                 [class.middle]="middle"
                 [class.schedule]="schedule"
                 [class.narrow]="narrow"
                 [class.narrow2]="narrow2"
                 [class.auth]="auth"
                 [class.wide2]="wide2"
                 [class.complain]="complain"
                 [class.editor]="editor"
                 [class.canGoBack]="canGoBack"
                 [ngClass]="answerState ? answerState : ''">
              <div *ngIf="complain" class="inner-close-btn" (click)="onCloseBtn($event)">
                <svg class="inner-close-btn-icon" viewBox="0 0 15 16">
                  <use [attr.xlink:href]="svgSetIcon('close')"></use>
                </svg>
              </div>

              <div class="tools-block" [style.transform]="'translateY(' + scrollTop + 'px)'">
                <div *ngIf="!closeInside && !authPage" class="close-btn"
                     (click)="onCloseBtn($event)"
                     (mouseover)="hover($event)"
                     (mouseout)="hover($event)">
                  <svg class="close-btn-icon" viewBox="0 0 38 38">
                    <use [attr.xlink:href]="svgSetIcon('modal-close')"></use>
                  </svg>
                </div>

                <div *ngIf="scale" class="scale-block">
                  <svg class="scale-btn" (click)="scalePlus()">
                    <use [attr.xlink:href]="svgSetIcon('zoom-plus')"></use>
                  </svg>
                  <div class="scale-btn scale-btn--one-to-one bold" (click)="scaleNumber = 1">{{ scaleValue }}%</div>
                  <svg class="scale-btn" (click)="scaleMinus()">
                    <use [attr.xlink:href]="svgSetIcon('zoom-minus')"></use>
                  </svg>
                </div>
              </div>

              <div *ngIf="prevNext"
                   class="prev-btn"
                   [ngStyle]="{'transform': 'translateY(' + scrollTop + 'px)'}"
                   [ngClass]="{ 'prev-btn--disabled': isReachedPrev }"
                   (click)="onPrev()">
                <svg class="prev-btn-icon">
                  <use [attr.xlink:href]="svgSetIcon('modal-arrow')"></use>
                </svg>
              </div>

              <div *ngIf="prevNext"
                   class="prev-btn prev-btn--next"
                   [ngStyle]="{'transform': 'rotate(180deg) translateY(' + -scrollTop + 'px)'}"
                   [ngClass]="{ 'prev-btn--disabled': isReachedNext }"
                   (click)="onNext()">
                <svg class="prev-btn-icon">
                  <use [attr.xlink:href]="svgSetIcon('modal-arrow')"></use>
                </svg>
              </div>

              <ng-content select="app-modal-body"></ng-content>
            </div>
            <div class="grid-right" (click)="onClose($event)"></div>
          </div>
          <div class="grid-bottom" [ngStyle]="{'min-height': calcHeader + 'px'}"
               [class.hide-footer]="showToFooter"
               (click)="onClose($event)"></div>
        </div>
      </div>
    </div>
  </div>
</div>
