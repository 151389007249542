import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, ViewRef } from '@angular/core';
import { PlatformService } from '@core/services';
import { differenceInSeconds } from 'date-fns';

function secondsToHMS(secs) {
  if (secs < 3600) {
    return new Date(secs * 1000).toISOString().substr(14, 5);
  } else {
    return new Date(secs * 1000).toISOString().substr(11, 8);
  }
}

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountdownComponent implements OnDestroy {
  @Input() set date(date: Date) {
    this._date = date;
    this.init();
  }
  @Input() minSeconds = 70; // за 70 секунд до завершения показать minTitle
  @Input() minTitle: string;

  public time: string;

  private _date: Date;
  private timeInterval: any;

  constructor(protected platform: PlatformService, protected cdr: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    if (this.timeInterval) {
      clearInterval(this.timeInterval);
    }
  }

  protected init() {
    if (!this.platform.isBrowser) {
      return;
    }
    this.tick();
    if (this.time) {
      this.timeInterval = setInterval(() => this.tick(), 1000);
    }
  }

  protected tick() {
    const seconds = this._date ? differenceInSeconds(this._date, new Date()) : 0;
    if (seconds < 70) {
      if (this.timeInterval) {
        clearInterval(this.timeInterval);
      }
      this.time = null;
    } else {
      this.time = secondsToHMS(seconds);
    }
    this.detectDataChanges();
  }

  private detectDataChanges() {
    if (this.cdr && !(this.cdr as ViewRef).destroyed) {
      this.cdr.markForCheck();
    }
  }
}
