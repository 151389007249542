import { getMergedRoute } from '@app/store/reducers/router.reducer';
import { createSelector } from '@ngrx/store';
import { Lesson, LessonSlide } from 'lingo2-models';
import { getContentEditorState, ContentEditorState } from '..';
import { LessonState } from './lesson.reducer';

const getLessonState = createSelector(getContentEditorState, (state: ContentEditorState) => state.lesson);

export const getLesson = createSelector(getLessonState, (state: LessonState) => state.entity);

export const getLessonId = createSelector(getLessonState, (state: LessonState) => !!state.entity && state.entity.id);

export const getLessonLoading = createSelector(getLessonState, (state: LessonState) => state.loading);

export const getLessonCreating = createSelector(getLessonState, (state: LessonState) => state.creating);

export const getLessonCreated = createSelector(getLessonState, (state: LessonState) => state.created);

export const getLessonPublishing = createSelector(getLessonState, (state: LessonState) => state.publishing);

export const getLessonPublished = createSelector(getLessonState, (state: LessonState) => state.published);

export const getLessonSaving = createSelector(getLessonState, (state: LessonState) => state.saving);

export const getLessonSaved = createSelector(getLessonState, (state: LessonState) => state.saved);

export const getLessonError = createSelector(getLessonState, (state: LessonState) => state.error);

export const getIsEditing = createSelector(getLessonState, (state: LessonState) => state.isEditing);

export const getIsErrorsVisible = createSelector(getLessonState, (state: LessonState) => state.isErrorsVisible);

export const getLessonValidity = createSelector(getLessonState, (state: LessonState) => state.validity);

export const getLessonVisitInfo = createSelector(getLessonState, (state: LessonState) => state.visit_info);

export const getLessonTitle = createSelector(getLesson, (lesson: Lesson) => lesson && lesson.title);

export const getSlides = createSelector(getLesson, (lesson: Lesson) =>
  lesson && lesson.slides ? [...lesson.slides].sort((a, b) => a.sort_order - b.sort_order) : [],
);

export const getSlideByRoute = createSelector(getMergedRoute, getLesson, (route, lesson: Lesson) => {
  const slide_id = route.params.slide_id;
  return lesson && lesson.slides.find((x) => x.id === slide_id);
});

export const getSlideSectionsByRoute = createSelector(
  getSlideByRoute,
  (slide: LessonSlide) => slide && slide.sections && [...slide.sections].sort((a, b) => a.sort_order - b.sort_order),
);

export const getIsMemorizer = createSelector(
  getLesson,
  (lesson: Lesson) => false,
  // TODO: подставить тип меморайзера
  // Типы меморайзера из прошлой реализации
  // ContentTypeEnum.vocabulary,
  // ContentTypeEnum.memorizer,

  // const memorizerTypes = [
  //   LessonSlideContentTypeEnum.???
  // ];
  // return lesson.slides && lesson.slides.length && !!lesson.slides.find(slide =>
  //   slide && slide.sections && !!slide.sections.find(section =>
  //     memorizerTypes.includes(section.content.type)
  //   )
  // );
);
