import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CropType } from '@app/collections-constructor/components/image-crop/image-crop.component';
import { logger } from '@app/core/helpers/logger';
import { ConfigService, DropzoneConfig, FilesService, IconsService, IFileType } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { FilePurposeEnum } from 'lingo2-models';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { takeUntil } from 'rxjs/operators';

type DisplayType = 'normal' | 'rectangular' | 'wide' | 'high' | 'small' | 'medium';
type PositionType = 'center' | 'top' | 'bottom';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageUploaderComponent),
      multi: true,
    },
  ],
})
export class ImageUploaderComponent extends ChangableComponent implements OnInit, ControlValueAccessor {
  @Input() error = false;
  @Input() canRemove = false;
  @Input() textPosition: PositionType = 'center';
  @Input() display: DisplayType = 'normal';
  @Input() cropRatio: CropType = 'square';
  @Input() set autoCrop(value: boolean) {
    this._autoCrop = value;
  }

  public get autoCrop() {
    return this._autoCrop;
  }
  @Output() changed = new EventEmitter<string>();

  public showCropImage = false;
  public value: string;
  public _autoCrop = false;
  public isDisabled: boolean;

  public svgSetIcon = IconsService.svgsetIconUrl;

  public mediaModal: {
    opened: boolean;
  };

  public dropzoneConfig: DropzoneConfigInterface = {
    acceptedFiles: 'image/jpeg,image/jpg,image/png',
    paramName: 'file',
    uploadMultiple: false,
    clickable: false,
    autoReset: 1,
    errorReset: null,
    cancelReset: null,
    dictDefaultMessage: '',
    timeout: 120 * 1000, // 120s = 2m
    createImageThumbnails: false,
  };
  public cover_file: IFileType;
  public cover_uploading = false;
  public filePurpose = FilePurposeEnum.content;

  constructor(
    protected configService: ConfigService,
    protected filesService: FilesService,
    protected dzConfig: DropzoneConfig,
    protected store: Store,
    protected cdr: ChangeDetectorRef,
  ) {
    super(cdr);
    this.mediaModal = {
      opened: false,
    };
  }

  ngOnInit(): void {
    this.configureDropzone();
  }

  private propagateChange: any = () => {};
  private propagateTouched: any = () => {};

  writeValue(value: string): void {
    this.value = value;
    if (this.value) {
      this.loadCover(this.value);
    } else {
      this.cover_file = null;
    }
  }

  registerOnChange(fn): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn): void {
    this.propagateTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onChange(value: string): void {
    this.value = value;
    this.propagateChange(value);
    this.changed.emit(value);
    this.closeMediaModal();
    this.detectChanges();
  }

  onBlur(): void {
    this.propagateTouched();
  }

  onCrop(evt?: MouseEvent): void {
    if (this.cover_file) {
      if (evt) {
        this.onClick(evt);
      }
      this.showCropImage = true;
    }
  }

  onImageChange(evt: { type: string; data?: string }): void {
    if (evt.type === 'imageCropped') {
      this.cover_uploading = true;
      this.filesService
        .uploadFileByData(evt.data, this.filePurpose, String(Date.now()))
        .pipe(takeUntil(this.destroyed$))
        .subscribe((file) => {
          this.cover_uploading = false;
          this.setCover(file, 'cropped');
        });
      this.showCropImage = false;
    } else if (evt.type === 'cancelCropped') {
      this.showCropImage = false;
    }
  }

  protected configureDropzone() {
    this.dropzoneConfig = this.dzConfig.combine(this.dropzoneConfig, {
      url: [this.dzConfig.url, '/file/upload+process?purpose=', FilePurposeEnum.content].join(''),
    });
  }

  public get coverUrl(): string {
    if (!this.cover_file) {
      return null;
    }
    const arrRectangle = ['wide', 'high', 'rectangular'];
    const mode = arrRectangle.indexOf(this.display) !== -1 ? 'wide' : this.display === 'small' ? 'sm' : 'mdX2';
    const image = this.cover_file.images.find((img) => img.size === mode);
    return image ? image.url : this.cover_file.storage_file_url;
  }

  public get wideCoverUrl(): string {
    if (!this.cover_file) {
      return null;
    }
    return this.cover_file.storage_file_url;
  }

  protected loadCover(id: string) {
    if (!id || (this.cover_file && this.cover_file.id === id)) {
      return;
    }
    this.filesService
      .getFileById(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (file) => {
          this.cover_file = file;
          this.detectChanges();
        },
        error: (err) => {
          logger.error(err);
        },
      });
  }

  public onUploadInit() {}

  public onUploadStart() {
    this.cover_uploading = true;
  }

  public onUploadError() {
    this.cover_uploading = false;
  }

  public onUploadSuccess(args): void {
    this.cover_uploading = false;
    const [, cover] = args;
    this.cover_file = cover;
    this.onChange(cover.id);
  }

  public onClick(args) {
    args.preventDefault();
    args.cancelBubble = true;
  }

  public showMediaModal(event: Event) {
    this.onClick(event);
    this.mediaModal.opened = true;
  }

  public removeCover(event: Event) {
    this.onClick(event);
    this.cover_file = null;
    this.onChange(null);
  }

  public closeMediaModal() {
    this.mediaModal.opened = false;
  }

  public setCover(cover: IFileType, type: 'cropped' | 'loaded') {
    this.cover_file = cover;
    if (type === 'loaded') {
      this.onCrop(null);
    }
    this.onChange(cover.id);
  }
}
