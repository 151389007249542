import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { User } from 'lingo2-models';
import { IListPanelOption } from '../models';
import { OnboardingStatusesEnum as OSE } from '../onboarding-widget.component';

import { OnboardingWidgetService } from '../onboarding-widget.service';
import { IChecklistOption } from '../shared/onboarding-checklist/onboarding-checklist.component';

@Component({
  selector: 'app-onboarding-limited-tutor',
  templateUrl: './onboarding-limited-tutor.component.html',
  styleUrls: ['./onboarding-limited-tutor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingLimitedTutorComponent implements OnInit {
  @Input() set me(me: User) {
    this.onboardingWidgetService.getCheckOptions(me).then((res) => {
      this.checklistOptions = res;
      this.cdr.detectChanges();
    });
    this.panelOptions = this.onboardingWidgetService.generateListPanelOptions(me);
    this.showMobileQr = this.onboardingWidgetService.isMobileNotUsed(me);
  }

  public status = OSE.limited_tutor;
  public isExpanded$ = this.onboardingWidgetService.isStatusExpanded$;
  public showMobileQr: boolean;
  public checklistOptions: IChecklistOption[];
  public panelOptions: IListPanelOption[];

  constructor(private onboardingWidgetService: OnboardingWidgetService, protected readonly cdr: ChangeDetectorRef) {}

  ngOnInit() {}
}
