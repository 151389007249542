import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BillingPlan, CurrencyEnum, PlanPeriodEnum } from 'lingo2-models';
import { isArray } from 'lodash-es';
import { EventActionEnum, EventCategoryEnum } from './constants';
import { FacebookAnalyticsService } from './facebook-analytics.service';
import { GoogleAnalyticsService } from './google-analytics.service';
import { YandexMetrikaService } from './yandex-metrika.service';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  public constructor(
    private ga: GoogleAnalyticsService,
    private fb: FacebookAnalyticsService,
    private metrika: YandexMetrikaService,
    private translate: TranslateService,
  ) {}

  /** Зарегистрировать просмотр страницы */
  public pageView(url: string, referer?: string) {
    this.ga.pageView(url, referer);
    this.fb.pageView(url, referer);
    this.metrika.pageView(url, referer);
  }

  /**
   * Отправить событие
   *
   * @param action  это строка, которая указывается в отчетах Google Аналитики как действие по событию
   * @param eventCategory категория события
   * @param eventLabel ярлык события
   * @param eventValue ценность события, выраженная целым неотрицательным числом
   */
  public event(
    action: EventActionEnum,
    eventCategory: EventCategoryEnum,
    eventLabel?: string | string[],
    eventValue?: number,
  ) {
    if (isArray(eventLabel)) {
      eventLabel = eventLabel
        .map((s) => (s === null || typeof s === 'undefined' ? '' : String(s)))
        .filter((s) => s.length)
        .join('/');
    }

    this.ga.event(action, eventCategory, eventLabel, eventValue);
    this.fb.event(action, { eventCategory, eventLabel, eventValue });
    this.metrika.event(action, eventCategory, eventLabel, eventValue);
  }

  /** Метка тарифного плана для событий */
  public planLabel(plan: BillingPlan): string {
    const price = plan.priceTier?.getAmountByCurrency(CurrencyEnum.usd);
    return [this.translate.instant(plan.details?.title), price ? `$${price}` : '', PlanPeriodEnum[plan.period]]
      .filter((s) => !!s)
      .join(' ');
  }

  /** Ценность тарифного плана для событий */
  public planValue(plan: BillingPlan): number {
    return plan.priceTier?.getAmountByCurrency(CurrencyEnum.usd);
  }
}
