import { createReducer, on, Action, createSelector } from '@ngrx/store';
import { ERedPointsEvents, IRedPointsEvents } from '@store/models';
import { addYears, startOfWeek, endOfWeek, addWeeks, addMonths, addDays } from 'date-fns';
import {
  ModerationStatusEnum,
  MeetingTypeEnum,
  Meeting,
  SingleEvent,
  IUserScheduleFilter,
  UserService,
  IPagedResults,
  User,
  IMeetingRescheduleResponse,
  UserProfile,
  EDashboardMeetingGroup,
  CurrencyEnum,
  PinnedEntity,
  EntityTypeEnum,
  IUserAccess,
  AccountPlan,
} from 'lingo2-models';
import { uniq as _uniq } from 'lodash';
import { MeetingsBlockType } from 'src/app/core/services';
import { AppState } from '..';
import * as ProfileAction from '../actions/profile.actions';

export interface State {
  languages: string[];
  me: User;
  myCurrency: CurrencyEnum;
  myProfile: UserProfile;
  mySettings: IUserAccess;
  myMeetings: MeetingsBlockType;
  myBillingPlan: AccountPlan;
  force: boolean;
  meetingToReschedule: string;
  meetingRescheduleResult: IMeetingRescheduleResponse;
  mySchedule: {
    loading: boolean;
    items: SingleEvent[];
    filter: Partial<IUserScheduleFilter>;
    currentDay: Date;
    isToday: boolean;
  };
  myServices: IPagedResults<UserService[]>;
  pins: PinnedEntity[];
  redPoints: IRedPointsEvents;
}

const initialState: State = {
  languages: [],
  me: null,
  myBillingPlan: null,
  myCurrency: CurrencyEnum.usd,
  myProfile: null,
  mySettings: null,
  myMeetings: {
    items: [],
    loading: false,
    loaded: false,
    filter: {
      participated: true,
      moderation_status: [
        ModerationStatusEnum.approved,
        ModerationStatusEnum.hardDeclined,
        ModerationStatusEnum.softDeclined,
        ModerationStatusEnum.default,
      ],
      type: [MeetingTypeEnum.single, MeetingTypeEnum.group, MeetingTypeEnum.webinar, MeetingTypeEnum.stream],
      begin_after: addYears(new Date(), -1),
      end_before: addYears(new Date(), 1),
    },
    pagination: {
      page: 1,
      pageSize: 50,
      total: 0,
      totalPages: 0,
    },
  },
  force: false,
  meetingToReschedule: null,
  meetingRescheduleResult: null,
  mySchedule: {
    loading: false,
    items: [],
    filter: {
      date_from: startOfWeek(new Date()),
      date_to: endOfWeek(new Date()),
    },
    currentDay: new Date(),
    isToday: true,
  },
  myServices: {
    page: 0,
    pageSize: 50,
    total: 0,
    totalPages: 0,
    results: [],
  },
  pins: [],
  redPoints: {
    ...Object.keys(ERedPointsEvents).reduce((acc, e) => ({ ...acc, [ERedPointsEvents[e]]: false }), {}),
  },
};

/* Reducers */
const profileReducer = createReducer(
  initialState,
  on(ProfileAction.userLogout, () => initialState),
  on(ProfileAction.loadMeSuccess, (state, { me }) => ({
    ...state,
    me,
  })),
  on(ProfileAction.loadMyProfileSuccess, (state, { profile }) => ({
    ...state,
    myProfile: profile,
  })),
  on(ProfileAction.loadMySettingsSuccess, (state, { settings }) => ({
    ...state,
    mySettings: settings,
  })),
  on(ProfileAction.loadMyMeetings, (state, { page, force }) => ({
    ...state,
    force: !!force,
    myMeetings: {
      ...state.myMeetings,
      loading: true,
    },
  })),
  on(ProfileAction.setMyCurrency, (state, { currency_id }) => ({
    ...state,
    myCurrency: currency_id,
  })),
  on(ProfileAction.loadMyMeetingsSuccess, (state, { meetingResponse }) => {
    const myMeetings = {
      ...state.myMeetings,
      pagination: {
        ...state.myMeetings.pagination,
      },
    };
    const items = meetingResponse.results;
    if (state.force) {
      return {
        ...state,
        force: false,
        myMeetings: {
          ...initialState.myMeetings,
          loading: false,
          loaded: true,
          items,
          pagination: {
            ...meetingResponse.pagination,
            total: meetingResponse.total,
            totalPages: meetingResponse.totalPages,
            page: meetingResponse.page,
            pageSize: initialState.myMeetings.pagination.pageSize,
          },
        },
      };
    }
    if (!myMeetings.loaded || meetingResponse.page > myMeetings.pagination.page) {
      myMeetings.items = [...myMeetings.items, ...items];
      myMeetings.pagination.total = meetingResponse.total;
      myMeetings.pagination.totalPages = meetingResponse.totalPages;
      myMeetings.pagination.page = meetingResponse.page;
      myMeetings.loading = false;
      myMeetings.loaded = true;
    }
    myMeetings.loading = false;
    return {
      ...state,
      myMeetings,
    };
  }),
  on(ProfileAction.setMyScheduleLoading, (state, { stopLoading }) => ({
    ...state,
    mySchedule: {
      ...state.mySchedule,
      loading: !stopLoading,
    },
  })),
  on(ProfileAction.loadMySchedule, (state, { date, weekShift, monthShift }) => {
    if (!date) {
      date = state.mySchedule.currentDay || new Date();
    }
    if (weekShift) {
      date = addWeeks(date, weekShift);
    }
    if (monthShift) {
      date = addMonths(date, monthShift);
    }
    const filter = {
      date_from: startOfWeek(date),
      date_to: endOfWeek(date),
    };
    return {
      ...state,
      mySchedule: {
        ...state.mySchedule,
        loading: true,
        filter,
        currentDay: date,
      },
    };
  }),
  on(ProfileAction.loadMyScheduleSuccess, (state, { events }) => ({
    ...state,
    mySchedule: {
      ...state.mySchedule,
      loading: false,
      items: events,
    },
  })),
  on(ProfileAction.loadMyServicesSuccess, (state, { services }) => ({
    ...state,
    myServices: services,
  })),
  on(ProfileAction.openMeetingRescheduleWindow, (state, { meeting_id }) => ({
    ...state,
    meetingToReschedule: meeting_id,
  })),
  on(ProfileAction.closeMeetingRescheduleWindow, (state) => ({
    ...state,
    meetingToReschedule: initialState.meetingToReschedule,
  })),
  on(ProfileAction.requestMeetingRescheduleSuccess, (state, { data }) => ({
    ...state,
    meetingToReschedule: initialState.meetingToReschedule,
    meetingRescheduleResult: data,
  })),
  on(ProfileAction.closeMeetingRescheduleAlert, (state) => ({
    ...state,
    meetingRescheduleResult: initialState.meetingRescheduleResult,
  })),
  on(ProfileAction.setLanguages, (state, { languages }) => ({
    ...state,
    languages: _uniq(state.languages.concat(languages)).filter((s) => !!s),
  })),
  on(ProfileAction.loadPinsSuccess, (state, { pins }) => ({
    ...state,
    pins,
  })),
  on(ProfileAction.setRedPoints, (state, { points }) => ({
    ...state,
    redPoints: {
      ...state.redPoints,
      ...points,
      sidebar_my_classes: points.update_schedule || points.classroom_new_invitation,
      sidebar_my_library:
        points.my_lesson_moderation ||
        points.my_lesson_published ||
        points.my_collection_moderation ||
        points.my_collection_published ||
        points.collection_purchased ||
        points.my_user_service_moderation ||
        points.my_user_service_published,
      my_library_lessons: points.my_lesson_moderation || points.my_lesson_published,
      my_library_services: points.my_user_service_moderation || points.my_user_service_published,
      my_library_collections:
        points.my_collection_moderation || points.my_collection_published || points.collection_purchased,
    },
  })),
  on(ProfileAction.setMyBillingPlan, (state, { myBillingPlan }) => ({
    ...state,
    myBillingPlan,
  })),
  on(ProfileAction.setProfile, (state, { profile }) => ({
    ...state,
    myProfile: profile,
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return profileReducer(state, action);
}

/* Key */
const thisFeatureKey = 'profile';
export const profileFeatureKey = thisFeatureKey;

/* Selectors */
export const getUserLanguages = (state: AppState) => state[thisFeatureKey].languages;
export const isMeetingsLoading = (state: AppState): boolean => state[thisFeatureKey].myMeetings.loading;
export const isMyScheduleLoading = (state: AppState): boolean => state[thisFeatureKey].mySchedule.loading;
export const getMeetingRescheduleId = (state: AppState): string => state[thisFeatureKey].meetingToReschedule;
export const getMeetingForReschedule = (state: AppState): Meeting =>
  state[thisFeatureKey].myMeetings.items.find((m) => m.id === getMeetingRescheduleId(state));
export const getMeetingRescheduleResult = (state: AppState): any => {
  const thisState = state[thisFeatureKey];
  let data = thisState.meetingRescheduleResult;
  if (data) {
    data = {
      ...thisState.meetingRescheduleResult,
    };
    if (data.meeting_id && !data.meeting) {
      data.meeting = thisState.myMeetings.items.find((m) => m.id === data.meeting_id);
    }
  }
  return data;
};
export const compareMeetingsByDate = (a: Meeting, b: Meeting): number =>
  (b?.begin_at as Date)?.getTime() - (a?.begin_at as Date)?.getTime();

export const compareMeetingsByDateASC = (a: Meeting, b: Meeting): number =>
  (a?.begin_at as Date)?.getTime() - (b?.begin_at as Date)?.getTime();
//  @typescript-eslint/semi
export const getMe = (state: AppState) => state[thisFeatureKey].me;
// const myId = state[thisFeatureKey].myId;
// const users = getUsers(state);
// if (!myId || !users) { return null; }
// return users[myId];
export const getMyCurrency = (state: AppState) => state[thisFeatureKey].myCurrency;
export const getMyProfile = (state: AppState) => state[thisFeatureKey].myProfile;
export const getMySettings = (state: AppState) => state[thisFeatureKey].mySettings;
export const getMyMeetingsObject = (state: AppState): MeetingsBlockType => state[thisFeatureKey].myMeetings;
export const getMyMeetings = (state: AppState): Meeting[] => state[thisFeatureKey].myMeetings.items;
export const getMyPins = (state: AppState): PinnedEntity[] =>
  state[thisFeatureKey].pins.map((p) => new PinnedEntity(p));
export const getPinById = createSelector(
  getMyPins,
  (pins: PinnedEntity[], props: { entity_id: string; type: EntityTypeEnum }): boolean => {
    if (pins.length === 0) {
      return false;
    }
    const entity = pins.find((pin) => pin.entity_id === props.entity_id && pin.type === props.type);
    return !!entity;
  },
);
export const getMyFutureMeetingsAsTeacher = createSelector(getMyMeetings, getMe, (meetings, user): Meeting[] =>
  meetings
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    .filter((meeting: Meeting) => meeting.is?.owner && meeting.has?.meetingGroup === EDashboardMeetingGroup.upcoming)
    .sort(compareMeetingsByDate),
);
export const getMyGoingMeetingsAsTeacher = createSelector(getMyMeetings, getMe, (meetings, user): Meeting[] =>
  meetings
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    .filter((meeting: Meeting) => meeting.is?.owner && meeting.has?.meetingGroup === EDashboardMeetingGroup.going)
    .sort(compareMeetingsByDate),
);
export const getMyPastMeetingsAsTeacher = createSelector(getMyMeetings, getMe, (meetings, user): Meeting[] =>
  meetings
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    .filter((meeting: Meeting) => meeting.is?.owner && meeting.has?.meetingGroup === EDashboardMeetingGroup.past)
    .sort(compareMeetingsByDate),
);
export const getMyFutureMeetingsAsStudent = createSelector(getMyMeetings, getMe, (meetings, user): Meeting[] =>
  meetings
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    .filter((meeting: Meeting) => !meeting.is?.owner && meeting.has?.meetingGroup === EDashboardMeetingGroup.upcoming)
    .sort(compareMeetingsByDate),
);
export const getMyGoingMeetingsAsStudent = createSelector(getMyMeetings, getMe, (meetings, user): Meeting[] =>
  meetings
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    .filter((meeting: Meeting) => !meeting.is?.owner && meeting.has?.meetingGroup === EDashboardMeetingGroup.going)
    .sort(compareMeetingsByDate),
);
export const getMyPastMeetingsAsStudent = createSelector(getMyMeetings, getMe, (meetings, user): Meeting[] =>
  meetings
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    .filter((meeting: Meeting) => !meeting.is?.owner && meeting.has?.meetingGroup === EDashboardMeetingGroup.past)
    .sort(compareMeetingsByDate),
);

export const getMyFutureConfirmRequireMeetingsAsTeacher = createSelector(
  getMyMeetings,
  getMe,
  (meetings, user): Meeting[] =>
    meetings
      .filter(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        (meeting: Meeting) => meeting.is?.owner && meeting.has?.meetingGroup === EDashboardMeetingGroup.unconfirmed,
      )
      .sort(compareMeetingsByDate),
);
export const getMyFutureConfirmRequireMeetingsAsStudent = createSelector(
  getMyMeetings,
  getMe,
  (meetings, user): Meeting[] =>
    meetings
      .filter(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        (meeting: Meeting) => !meeting.is?.owner && meeting.has?.meetingGroup === EDashboardMeetingGroup.unconfirmed,
      )
      .sort(compareMeetingsByDate),
);

export const getMyDeclinedMeetingsAsTeacher = createSelector(getMyMeetings, getMe, (meetings, user): Meeting[] =>
  meetings
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    .filter((meeting: Meeting) => meeting.is?.owner && meeting.has?.meetingGroup === EDashboardMeetingGroup.canceled)
    .sort(compareMeetingsByDate),
);
export const getMyDeclinedMeetingsAsStudent = createSelector(getMyMeetings, getMe, (meetings, user): Meeting[] =>
  meetings
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    .filter((meeting: Meeting) => !meeting.is?.owner && meeting.has?.meetingGroup === EDashboardMeetingGroup.canceled)
    .sort(compareMeetingsByDate),
);
export const getMyMeetingsCount = (state: AppState): number => state[thisFeatureKey].myMeetings.items.length;
/** Billing */
export const getMyBillingPlan = (state: AppState) => state[thisFeatureKey].myBillingPlan;
// Services
export const getMyServices = (state: AppState): UserService[] => state[thisFeatureKey].myServices.results;
// Schedule
export const getMyScheduleCurrentDay = (state: AppState): Date => state[thisFeatureKey].mySchedule.currentDay;
export const getMyScheduleWeekdays = (state: AppState): Date[] => {
  const thisState: State = state[thisFeatureKey];
  const weekStart = startOfWeek(thisState.mySchedule.currentDay || new Date());
  const week = [];
  for (let i = 0; i < 7; i++) {
    week.push(addDays(weekStart, i));
  }
  return week;
};
export const getMyScheduleItems = (state: AppState): SingleEvent[] => state[thisFeatureKey].mySchedule.items;
export const getMeetingToReschedule = createSelector(
  getMeetingRescheduleId,
  getMyMeetings,
  (meeting_id, meetings): Meeting => {
    if (!meeting_id) {
      return null;
    }
    const meeting = meetings.find((m) => m.id === meeting_id);
    return meeting;
  },
);

export const getRedPoints = (state: AppState) => state[thisFeatureKey].redPoints;
