import { Injectable } from '@angular/core';
import { OpenAiService } from '@core/services/lingo2-content/open-ai.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IFindFileFilter, FileTypeEnum, FilePurposeEnum } from 'lingo2-models';
import { of } from 'rxjs';
import { switchMap, map, withLatestFrom, concatMap, tap } from 'rxjs/operators';
import { FilesService } from 'src/app/core/services';

import * as MediaAction from '../actions/media-gallery.actions';
import { uploadImageFromUrlInProgress } from '../actions/media-gallery.actions';
import {
  getRecentPagination,
  getStarPagination,
  getRecentFilter,
  getStarFilter,
} from '../reducers/media-gallery.reducer';

@Injectable()
export class MediaGalleryEffects {
  public loadRecentImages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaAction.loadRecentImages),
      withLatestFrom(this.store.select(getRecentFilter)),
      withLatestFrom(this.store.select(getRecentPagination)),
      switchMap(([[, filter], pagination]) => {
        filter = filter || {};
        const _filter: Partial<IFindFileFilter> = {
          ...filter,
          type: FileTypeEnum.image,
          term: '',
        };
        return this.filesService.getRecentFiles(_filter, pagination).pipe(
          map(({ results, page, pageSize, totalPages, total }) =>
            MediaAction.loadRecentImagesSuccess({
              images: results,
              pagination: {
                page,
                pageSize,
                total,
                totalPages,
              },
            }),
          ),
        );
      }),
    ),
  );

  public loadStarImages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaAction.loadStarImages),
      withLatestFrom(this.store.select(getStarFilter)),
      withLatestFrom(this.store.select(getStarPagination)),
      switchMap(([[, filter], pagination]) => {
        filter = filter || {};
        const _filter: Partial<IFindFileFilter> = {
          ...filter,
          type: FileTypeEnum.image,
          term: '',
          flags: {
            is_fav: true,
          },
        };
        return this.filesService.getRecentFiles(_filter, pagination).pipe(
          map(({ results, page, pageSize, totalPages, total }) =>
            MediaAction.loadStarImagesSuccess({
              images: results,
              pagination: {
                page,
                pageSize,
                total,
                totalPages,
              },
            }),
          ),
        );
      }),
    ),
  );

  public loadOnlineImages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaAction.loadOnlineImages),
      map(({ query }) => query),
      // switchMap((query) => this.openAiService.translateWord(query, '', 'english')),
      switchMap((query) =>
        this.filesService
          .getOnlineFiles(query)
          .pipe(map(({ results }) => MediaAction.loadOnlineImagesSuccess({ images: results }))),
      ),
    ),
  );

  public uploadImageByUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaAction.uploadImageFromUrl),
      // map(({ url, target }) => ({ url, target })),
      switchMap(({ id, url, purpose }) => {
        purpose = purpose || FilePurposeEnum.content;
        const extension = new URLSearchParams(url).get('fm');
        const clearUrl = encodeURI(url);
        return this.filesService.uploadFileByUrl(clearUrl, purpose, extension).pipe(
          tap(() => this.store.dispatch(uploadImageFromUrlInProgress({ status: 'done' }))),
          map((file) => MediaAction.uploadImageFromUrlSuccess({ file })),
        );
      }),
    ),
  );

  public uploadImageByData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaAction.uploadImageByData),
      switchMap(({ data, purpose, ext }) => {
        purpose = purpose || FilePurposeEnum.content;
        return this.filesService
          .uploadFileByData(data, purpose, ext)
          .pipe(map((file) => MediaAction.uploadImageByDataSuccess({ file })));
      }),
    ),
  );

  public reloadRecentImagesWhenUpload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaAction.uploadImageFromUrlSuccess),
      concatMap(() => of(MediaAction.uploadImageFromUrlInProgress({ status: 'done' }))),
      concatMap(() => of(MediaAction.loadRecentImages({}))),
    ),
  );

  public changeFav$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaAction.changeFav),
      switchMap(({ file_id }) =>
        this.filesService.changeFav(file_id).pipe(
          map(() => {
            this.store.dispatch(MediaAction.loadStarImages({}));
            return MediaAction.loadRecentImages({});
          }),
        ),
      ),
    ),
  );

  public constructor(
    private actions$: Actions,
    private openAiService: OpenAiService,
    private filesService: FilesService,
    private store: Store,
  ) {}
}
