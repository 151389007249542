import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { UserRoles } from '@app/first-contact/first-contact.component';
import { FeaturesService, PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { titles, subtitles, constructorList } from './first-contact-constructor.variables';

@Component({
  selector: 'app-first-contact-constructor',
  templateUrl: './first-contact-constructor.component.html',
  styleUrls: ['./first-contact-constructor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirstContactConstructorComponent extends ChangableComponent {
  @Input() selectedRole: UserRoles;
  public titles = titles;
  public subtitles = subtitles;
  public isViewport = false;

  private _constructorList = constructorList;

  constructor(
    protected readonly cdr: ChangeDetectorRef,
    public deviceService: DeviceDetectorService,
    protected readonly platform?: PlatformService,
  ) {
    super(cdr, platform);
  }

  public title(): string {
    return this.titles[this.selectedRole];
  }

  public subtitle(): string {
    return this.subtitles[this.selectedRole];
  }

  public constructorList() {
    return this._constructorList[this.selectedRole];
  }

  public onInViewportChange(e) {
    if (!e.visible) {
      return;
    }
    this.isViewport = e.visible;
    this.markForCheck();
  }

  public get isLimitedVersion() {
    return FeaturesService.isLimitedVersion;
  }
}
