import { Injectable, NgZone } from '@angular/core';
import { environment } from '@env/environment';
import { DestroyableComponent } from '@models/destroyable.component';
import { User, IBillingSettings } from 'lingo2-models';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ContextService } from './context.service';
import { AccountService } from './lingo2-account/account.service';
import { BillingService } from './lingo2-account/billing.service';
import { PlatformService } from './platform.service';

declare let $crisp: any[];

/**
 * @see https://help.crisp.chat/en/article/how-to-use-dollarcrisp-javascript-sdk-10ud15y/
 *
 * @see https://app.crisp.chat/website/2ca1e19f-89ee-4892-9cb5-725c1949d64a/
 */

@Injectable({
  providedIn: 'root',
})
export class CrispChatService extends DestroyableComponent {
  private enabledSubject = this.register(new ReplaySubject<boolean>(1));
  public enabled$ = this.enabledSubject.asObservable();

  private openedSubject = this.register(new ReplaySubject<boolean>(1));
  public opened$ = this.openedSubject.asObservable();

  private me: User;
  private inited = false;
  private supportDetailsInited = false;
  private billingSettings: IBillingSettings;

  constructor(
    private contextService: ContextService,
    private billingService: BillingService,
    private zone: NgZone,
    protected readonly platform: PlatformService,
  ) {
    super(platform);

    this.contextService.me$.pipe(takeUntil(this.destroyed$)).subscribe((me) => {
      this.me = me;
      const meId = this.me ? this.me.id : null;
      if (meId && this.sessionUserId !== meId) {
        this.resetSession(meId);
      }
    });

    this.billingService
      .getSettings$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((settings) => {
        this.billingSettings = settings;
        // this.setSupportDetails();
      });
  }

  public init() {
    if (this.isBrowser && environment.crisp_website_id) {
      if (!this.inited) {
        this.inited = true;
        this.zone.runOutsideAngular(() => {
          window['$crisp'] = [];
          $crisp.push(['do', 'chat:hide']);
          window['CRISP_WEBSITE_ID'] = environment.crisp_website_id;

          const s = document.createElement('script') as any;
          s.src = 'https://client.crisp.chat/l.js';
          s.async = 1;
          document.getElementsByTagName('head')[0].appendChild(s);
          s.onload = () => {
            this.zone.run(() => {
              this.enabledSubject.next(true);
            });
          };

          $crisp.push(['safe', true]);
          $crisp.push(['on', 'chat:initiated', () => this.setSupportDetails()]);
          $crisp.push([
            'on',
            'chat:opened',
            () => {
              this.setSupportDetails();
              this.onChatOpened();
            },
          ]);
          $crisp.push(['on', 'chat:closed', () => this.onChatClosed()]);

          this.closeChat();
        });
      }
    }
  }

  public openChat() {
    if (this.inited) {
      $crisp.push(['do', 'chat:show']);
      this.setTimeout(() => {
        $crisp.push(['do', 'chat:open']);
      }, 100);
    }
  }

  public closeChat() {
    if (this.inited) {
      $crisp.push(['do', 'chat:close']);
      $crisp.push(['do', 'chat:hide']);
    }
  }

  /* отправить сообщение в чат */
  public sayToChat(message: string) {
    this.init();
    try {
      if (this.inited) {
        $crisp.push(['do', 'message:send', ['text', message]]);
      }
    } catch (e) {}
  }

  public resetSession(id: string) {
    if (this.isBrowser && this.inited) {
      $crisp.push(['do', 'session:reset']);
      this.closeChat();

      this.sessionUserId = id;
      if (id) {
        this.setSupportDetails(true);
      }
    }
  }

  private setSupportDetails(force = false) {
    if (this.isBrowser && this.inited && this.me) {
      if (this.supportDetailsInited && !force) {
        return;
      }
      this.supportDetailsInited = true;

      /*
      Примеры
      $crisp.push(['set', 'user:nickname', [user.user_name]]);
      $crisp.push(['set', 'user:avatar', [user.user_pick]]);
      // engine.push(['set', 'user:email', ['??']]);
      // engine.push(['set', 'session:segments', [['?']]])
      $crisp.push(['set', 'session:data', [[
        ['user_id', user.user_id],
        ['user_language', siteCookie.user_native_lang_name],
        ['dict_language', siteCookie.user_learning_lang_name],
        ['subscription_status', subscription.status],
        ['subscription_plan', subscription.plan],
        ['subscription_purchase_date', subscription.purchase_date],
        ['subscription_expiry_date', subscription.expiry_date],
      ]]]);
      */

      $crisp.push(['set', 'user:nickname', [this.username]]);
      $crisp.push(['set', 'user:avatar', [this.userpicUrl]]);
      // $crisp.push(['set', 'user:email', [???]]);
      $crisp.push(['set', 'user:email', [this.userEmail]]);
      const sessionData = [['user_id', this.me.id]];
      if (this.billingSettings && this.billingSettings.plan) {
        sessionData.push([
          'plan_id',
          this.billingSettings.plan.plan ? this.billingSettings.plan.plan.id : this.billingSettings.plan.plan_id,
        ]);
        // sessionData.push(['plan_name', this.billingSettings.plan.name]);
        sessionData.push(['plan_expires_at', this.billingSettings.plan.expires_at.toISOString()]);
      }
      $crisp.push(['set', 'session:data', [sessionData]]);
    }
  }

  public get username(): string {
    return this.me ? AccountService.getUserFullName(this.me) : '';
  }

  public get userpicUrl(): string {
    return this.me && this.me.userpic && this.me.userpic.sm ? this.assetsUrl(this.me.userpic.sm.url) : null;
  }

  public get userEmail(): string {
    return this.me ? `${this.me.id}@${environment.cookie_domain}` : '';
  }

  private set opened(state: boolean) {
    if (this.isBrowser && localStorage) {
      localStorage.setItem('CRISP_CHAT_OPENED', state ? 'Y' : 'N');
    }
  }

  private get opened(): boolean {
    if (this.isBrowser && localStorage) {
      return localStorage.getItem('CRISP_CHAT_OPENED') === 'Y';
    }
    return false;
  }

  private set sessionUserId(id: string) {
    if (this.isBrowser && localStorage) {
      localStorage.setItem('CRISP_CHAT_SID', id);
    }
  }

  private get sessionUserId(): string {
    if (this.isBrowser && localStorage) {
      return localStorage.getItem('CRISP_CHAT_SID');
    }
    return null;
  }

  protected onChatClosed() {
    $crisp.push(['do', 'chat:hide']);
    this.opened = false;
    this.openedSubject.next(this.opened);
  }

  protected onChatOpened() {
    $crisp.push(['do', 'chat:show']);
    this.opened = true;
    this.openedSubject.next(this.opened);
  }
}
