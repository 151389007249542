import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { EventActionEnum, EventCategoryEnum } from '@app/core/services/analytics';
import { FirstContactModule } from '@app/first-contact/first-contact.module';
import { RulesComponent } from '@app/landings/article/rules/rules.component';
import { CurrencySelectorModule } from '@core/components/currency-selector/currency-selector.module';
import { LanguageSelectorModule } from '@core/components/language-selector/language-selector.module';
import { MobileModalModule } from '@core/components/mobile-modal/mobile-modal.module';
import { ModalModule } from '@core/components/modal/modal.module';
import {
  AccountService,
  AuthService,
  ContextService,
  CrispChatService,
  FeaturesService,
  FilesService,
  AnalyticsService,
  IconsService,
} from '@core/services';
import { VerificationWidgetModule } from '@core/widgets';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { setUserRole } from 'lingo2-game-lib';
import { FeatureEnum, User, UserStatusEnum } from 'lingo2-models';
import { UserProfileVerificationEnum as VE } from 'lingo2-models/dist/account/enums/user-profile-verification-enum';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiButtonModule } from 'onclass-ui';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-user-menu',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    VerificationWidgetModule,
    LanguageSelectorModule,
    CurrencySelectorModule,
    ModalModule,
    MobileModalModule,
    FirstContactModule,
    RulesComponent,
    OnUiButtonModule,
  ],
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuComponent extends ChangableComponent implements OnInit {
  @Input() verification_status_value: VE;
  @Input() public set me(me: User) {
    this._me = me;
    this.prepare();
  }
  public get me(): User {
    return this._me;
  }

  @Output() expandedMenu = new EventEmitter<boolean>();
  public accountRoute = AccountService.accountRoute;
  public userpicUrl: string;
  public name: string;
  public country: string;
  public isGuest: boolean;
  public isTeacher: boolean;
  public svgsetIcon = IconsService.svgsetIconUrl;
  public expandMenuState = false;
  public openIntroductionModal = false;
  public isRulesModalOpened: boolean;
  public isLimitedVersion = FeaturesService.isLimitedVersion;

  private popoverStatuses = [VE.incomplete, VE.not_verified, VE.declined, VE.sent, VE.changed, VE.resent];
  private _me: User;

  constructor(
    private accountService: AccountService,
    private contextService: ContextService,
    private features: FeaturesService,
    private crispChat: CrispChatService,
    private authService: AuthService,
    private analytics: AnalyticsService,
    private router: Router,
    protected readonly store: Store,
    protected cdr: ChangeDetectorRef,
    public deviceService: DeviceDetectorService,
  ) {
    super(cdr);
  }

  ngOnInit() {}

  private prepare() {
    this.userpicUrl = FilesService.getFileUrlBySize(this._me?.userpic_id);
    this.name = AccountService.getUserFullName(this._me);
    this.country = this._me && this._me.country ? this._me.country.title : null;
    this.isGuest = !this._me || this._me.status === UserStatusEnum.guest;
    this.isTeacher = AccountService.isAsIfTeacher(this._me);
    this.store.dispatch(setUserRole({ isTeacher: this.isTeacher }));
  }

  public closeMenu() {
    this.expandMenuState = false;
    this.detectChanges();
  }

  public get isProfilePage(): boolean {
    return this.router.url.includes('/profile');
  }

  public openCrispChat() {
    this.crispChat.openChat();
    this.expandMenuState = false;
    this.detectChanges();
  }

  public onToggleIntroductionModal() {
    this.openIntroductionModal = !this.openIntroductionModal;
    this.expandMenuState = false;
    this.detectChanges();
  }

  public becomeTeacher() {
    this.closeMenu();
    if (this.isGuest) {
      this.authService.showAuthModal(() => {
        this.markAsTeacher();
      });
    } else {
      this.markAsTeacher();
    }
    this.detectChanges();
  }

  /** пометить меня как 'хочу стать преподом' */
  public markAsTeacher() {
    this.analytics.event(EventActionEnum.become_teacher, EventCategoryEnum.engagement, 'app-user-menu');

    this.accountService
      .markAsTeacher()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (account) => {
          this.contextService.updateMe(account); // обновить мой аккаунт
          this.navigateByUrl('/profile');
        },
        (err: any) => {
          console.error('UserMenuComponent::markAsTeacher()', err);
          this.detectChanges();
        },
      );
  }

  public authGuardedNavigate(url: string) {
    this.authService.showAuthModal(() => {
      this.navigateByUrl(url);
    });
  }

  public navigateByUrl(url: string) {
    if (url) {
      this.router.navigateByUrl(url).then(() => true);
    }
  }

  public navigate(route: string[]) {
    if (route) {
      this.router.navigate(route).then(() => true);
    }
  }

  public get isAvailableProfileFinance(): boolean {
    return this.features.isAvailable(FeatureEnum.profile_finance, this._me);
  }

  public onToggleExpand() {
    this.expandMenuState = !this.expandMenuState;
    setTimeout(() => {
      this.expandedMenu.emit(this.expandMenuState);
    }, 200);
    this.detectChanges();
  }

  public get isPopoverNeeded(): boolean {
    return this.popoverStatuses.includes(this.verification_status_value);
  }

  public onToggleRulesModal() {
    this.isRulesModalOpened = !this.isRulesModalOpened;
    this.expandMenuState = false;
    this.detectChanges();
  }
}
