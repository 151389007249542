import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CheckboxModule } from '@app/shared';
import { MobileModalModule } from '@core/components/mobile-modal/mobile-modal.module';
import { ModalModule } from '@core/components/modal/modal.module';
import { RecaptchaModule } from '@le2xx/ng-recaptcha';
import { TranslateModule } from '@ngx-translate/core';
import { AgreementTermsFieldModule } from 'lingo2-forms';
import { NgOtpInputModule } from 'ng-otp-input';
import { CountdownModule } from 'ngx-countdown';
import { NgxIntlPhoneInputModule } from 'ngx-intl-phone-input';
import { OnUiAutocompleteModule, OnUiButtonModule, OnUiSelectModule } from 'onclass-ui';
import { CoreModule } from '../../core.module';
import { AuthInputFieldComponent } from './auth-input-field/auth-input-field.component';
import { AuthModalComponent } from './auth-modal.component';
import { GoogleSigninButtonDirective } from './google-auth-button.directive';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    TranslateModule,
    CoreModule,
    FormsModule,
    RecaptchaModule,
    NgOtpInputModule,
    CountdownModule,
    CheckboxModule,
    ModalModule,
    MobileModalModule,
    OnUiButtonModule,
    AgreementTermsFieldModule,
    OnUiSelectModule,
    OnUiAutocompleteModule,
    NgxIntlPhoneInputModule,
  ],
  exports: [AuthModalComponent, AuthInputFieldComponent],
  declarations: [AuthModalComponent, AuthInputFieldComponent, GoogleSigninButtonDirective],
  providers: [],
})
export class AuthModalModule {}
