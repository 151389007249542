import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NumberRoundPipe } from '@core/pipes/number-round.pipe';
import { ArraySortPipe } from './array-sort.pipe';
import { CheckPreviousPipe } from './check-previous.pipe';
import { CutTextPipe } from './cut.pipe';
import { Nl2BrPipe } from './nl2br.pipe';
import { ReplacePipe } from './replace.pipe';
import { SanitizeHtmlPipe } from './safe-html.pipe';
import { SecToTimeFormatPipe } from './sec-to-time-format.pipe';
import { StriptagsPipe } from './striptags.pipe';
import { TrimPipe } from './trim.pipe';
import { TypeofPipe } from './typeof.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    ArraySortPipe,
    CutTextPipe,
    SanitizeHtmlPipe,
    Nl2BrPipe,
    SecToTimeFormatPipe,
    StriptagsPipe,
    TrimPipe,
    ReplacePipe,
    TypeofPipe,
    CheckPreviousPipe,
    NumberRoundPipe,
  ],
  exports: [
    ArraySortPipe,
    CutTextPipe,
    SanitizeHtmlPipe,
    Nl2BrPipe,
    SecToTimeFormatPipe,
    StriptagsPipe,
    TrimPipe,
    ReplacePipe,
    TypeofPipe,
    CheckPreviousPipe,
    NumberRoundPipe,
  ],
})
export class PipesModule {}
