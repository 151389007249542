<app-skeleton *ngIf="!meeting || !references" [class.mobile]="deviceService.isMobile()" [stats]="'meeting-form'"></app-skeleton>

<ng-container *ngIf="references && meeting">
  <app-meeting-form
    [meeting]="meeting"
    [locale]="dateFnsConfig.locale()?.code"
    [references]="references"
    [creditsAmount]="creditsAmount"
    [modalDisplayMode]="true"
    [preset]="preset"
    [cover]="cover"
    [specifiedDate]="!!day"
    (buyCredits)="openBuyCreditsModal()"
    (changeCover)="onCoverChange($event)"
    (openPaywall)="buyOrUpgradePlan()"
    (saved)="onMeetingSaved($event)">
    <ng-container image-upload>
      <form [formGroup]="form">
        <app-image-uploader formControlName="cover_id"
                            [display]="'wide'" [autoCrop]="true"
                            [cropRatio]="'wide'">
        </app-image-uploader>
      </form>
    </ng-container>
    <ng-container timezone>
      <app-timezone></app-timezone>
    </ng-container>
  </app-meeting-form>
</ng-container>

<ng-template [ngIf]="buyCreditsModalOpened">
  <app-finance-plan-buy-credits-form-v2
    *ngIf="isAvailableBillingV2"
    [plan]="currentPlan"
    (success)="onSuccessBuyCreditsModal()"
    (close)="onCloseBuyCreditsModal()"
    (activatePlan)="getPlanForCredits()">
  </app-finance-plan-buy-credits-form-v2>

  <app-modal *ngIf="!deviceService.isMobile() && !isAvailableBillingV2" (closed)="onCloseBuyCreditsModal()">
    <app-modal-body>
      <app-finance-plan-buy-credits-form [plan]="currentPlan"
                                         (activatePlan)="getPlanForCredits()">
      </app-finance-plan-buy-credits-form>
    </app-modal-body>
  </app-modal>

  <app-mobile-modal *ngIf="deviceService.isMobile() && !isAvailableBillingV2" (closed)="onCloseBuyCreditsModal()">
    <app-mobile-modal-body>
      <app-finance-plan-buy-credits-form [plan]="currentPlan"
                                         (activatePlan)="getPlanForCredits()">
      </app-finance-plan-buy-credits-form>
    </app-mobile-modal-body>
  </app-mobile-modal>
</ng-template>
