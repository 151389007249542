import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClickOutsideModule } from 'ng-click-outside';

import { TaskAnswerVariantPopoverComponent } from './task-answer-variant-popover.component';
import { TaskAnswerVariantPopoverDirective } from './task-answer-variant-popover.directive';

@NgModule({
  declarations: [TaskAnswerVariantPopoverDirective, TaskAnswerVariantPopoverComponent],
  imports: [CommonModule, ClickOutsideModule],
  exports: [TaskAnswerVariantPopoverDirective],
})
export class TaskAnswerVariantPopoverModule {}
