<section class="first-contact-choose-tutor" [class.mobile]="deviceService.isMobile()">
  <h2 class="title" [innerHTML]="'first-contact.choose-tutor.title' | translate"></h2>
  <div class="image-header"></div>

  <div class="list">
    <div *ngFor="let item of chooseTutorList" class="list-item">
      <h2 class="choose-tutor-title">{{ item.title | translate }}</h2>
      <div class="item-block">
        <div class="image" [ngClass]="item.type"></div>
        <p class="choose-tutor-text">{{ item.text | translate }}</p>
      </div>
    </div>
  </div>
</section>
