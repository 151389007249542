import { IContentBlock } from '@core/services/interfaces';
import { Action, createReducer, on } from '@ngrx/store';
import { Lesson, IFindContentFilter } from 'lingo2-models';

export type LibraryBlock = IContentBlock<Lesson, IFindContentFilter>;

import * as SidebarAction from './sidebar.actions';

export interface SidebarState {
  sortOpen: boolean;
  typeOpen: boolean;
}

const initialState: SidebarState = {
  sortOpen: true,
  typeOpen: true,
};

const contentReducer = createReducer(
  initialState,

  on(SidebarAction.loadUIFiltersSuccess, (state, { sidebar }) => ({ ...state, ...sidebar })),

  on(SidebarAction.saveUIFiltersSuccess, (state, { sidebar }) => ({ ...state, ...sidebar })),
);

export function reducer(state = initialState, action: Action) {
  return contentReducer(state, action);
}
