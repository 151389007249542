import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { INoticeParams } from 'lingo2-models';
import { EModifier, IBaseNotification } from '../../base-notification/base-notification.component';

@Component({
  selector: 'app-meeting-participant-joined',
  templateUrl: './meeting-participant-joined.component.html',
  styles: [''],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeetingParticipantJoinedComponent {
  public params: INoticeParams;
  public base: IBaseNotification;

  @Input() set notification(notification: INoticeParams) {
    this.params = notification.params;
    this.base = {
      title: 'notifications.meeting_participant_joined.title',
      icon: 'notice-clock',
      created_at: notification.created_at,
      modifier: EModifier.blue,
    };
  }

  public get user() {
    return `<a class='user-fullname-link' target='_blank' href='${this.params.user_url}'>${this.params.user_fullname}</a>`;
  }

  public get title() {
    return `<a class='link-base' href='${this.params.meeting_join_url}'>${this.params.meeting_title}</a>`;
  }
}
