import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DestroyableComponent } from '@models/destroyable.component';
import { TranslateService } from '@ngx-translate/core';
import { isUUID } from 'class-validator';
import { ImageSizeEnum, Language } from 'lingo2-models';
import { zip } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { logger } from '../helpers/logger';
import { FilesService } from './lingo2-files/files.service';
import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root',
})
export class MetaService extends DestroyableComponent {
  private titleSuffix = ' | OnClass';
  private languages: Language[];
  private language: Language;
  private metaNode: any;

  public constructor(
    private translate: TranslateService,
    private meta: Meta,
    private title: Title,
    @Inject(DOCUMENT) private document,
    protected readonly platform: PlatformService,
  ) {
    super(platform);
    this.metaNode = this.document.getElementById('meta');
  }

  public setLanguages(languages: Language[]) {
    this.languages = languages;
  }

  public updateLanguage(language: Language) {
    this.language = language;
    this.setDefaultMeta();
  }

  public setUrl(url: string) {
    const _url = this.language.url + url;
    this.meta.updateTag({ property: 'og:url', content: _url || '' }, "property='og:url'");
    // logger.log('MetaService og:url', _url);

    this.setCanonicalUrl(url);

    this.setAlternateLanguageUrl(url);
  }

  public setCanonicalUrl(url: string) {
    // <link rel="canoncal" href="...">
    const _url = this.language.url + url;
    this.removeMetaLink('canonical');
    this.createMetaLink('canonical', _url);
  }

  protected removeMetaLink(rel: string) {
    const nodes = this.document.querySelectorAll('link[rel=' + rel + ']');
    if (nodes) {
      nodes.forEach((node) => node.parentNode.removeChild(node));
    }
  }

  protected createMetaLink(rel: string, href: string) {
    const link = this.document.createElement('link');
    link.rel = rel;
    link.href = href;
    this.metaNode.parentNode.insertBefore(link, this.metaNode);
  }

  public setAlternateLanguageUrl(url: string) {
    // <link rel="alternate" hreflang="en" href="https://onclass.com/">
    // <link rel="alternate" hreflang="ru" href="https://ru.onclass.com/">

    this.removeAlternativeLanguageLinks();
    for (const lang of this.languages) {
      const _url = lang.url + url;
      this.createAlternativeLanguageLink(lang.code, _url);
    }
  }

  protected removeAlternativeLanguageLinks() {
    const nodes = this.document.querySelectorAll('link[rel=alternate][hreflang]');
    if (nodes) {
      nodes.forEach((node) => node.parentNode.removeChild(node));
    }
  }

  protected createAlternativeLanguageLink(language: string, href: string) {
    const link = this.document.createElement('link');
    link.rel = 'alternate';
    link.hreflang = language;
    link.href = href;
    this.metaNode.parentNode.insertBefore(link, this.metaNode);
  }

  public getTitle(): string {
    const title = this.title.getTitle();
    const suffix = title.substring(title.length - this.titleSuffix.length, title.length);
    if (suffix === this.titleSuffix) {
      return title.substring(0, title.length - this.titleSuffix.length);
    } else {
      return title;
    }
  }

  public setTitle(values: string[], glue = ' — ') {
    this._setTitle(values.join(glue) + ' | OnClass'); // установка заголовка без перевода

    const _values = values.filter((v) => v.length).map((v) => this.translate.get(v));
    zip(..._values)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((translations) => {
        // установка заголовка с переводом
        this._setTitle(translations.filter((v) => v.length).join(glue) + this.titleSuffix);
      });
  }

  private _setTitle(title: string) {
    this.title.setTitle(title);
    this.meta.updateTag({ property: 'og:title', content: title }, "property='og:title'");
    this.meta.updateTag({ property: 'twitter:title', content: title }, "property='twitter:title'");
    // logger.log('MetaService title', title);
  }

  public setKeywords(keywords: string[], glue = ',') {
    const _values = keywords.filter((v) => v.length).map((v) => this.translate.get(v));
    zip(..._values)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((translations) => {
        // установка ключевиков с переводами
        this._setKeywords(translations.filter((v) => v.length));
      });
  }

  public _setKeywords(keywords: string[]) {
    if (typeof keywords === 'string') {
      keywords = JSON.parse(keywords);
    }
    this.meta.updateTag({ name: 'keywords', content: (keywords || []).join(',') }, "name='keywords'");
    // logger.log('MetaService meta.keywords', (keywords || []).join(','));
  }

  public setDescriptions(descriptions: string[], glue = ' ') {
    const _values = descriptions.filter((v) => v.length).map((v) => this.translate.get(v));
    zip(..._values)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((translations) => {
        // установка дескрипшина с переводом
        this.setDescription(translations.filter((v) => v.length).join(glue));
      });
  }

  public setDescription(description: string) {
    this.meta.updateTag({ name: 'description', content: description }, "name='description'");
    this.meta.updateTag({ property: 'og:description', content: description }, "property='og:description'");
    this.meta.updateTag({ property: 'twitter:description', content: description }, "property='twitter:description'");
    // logger.log('MetaService meta.description', description);
  }

  /**
   * @param image_id_or_url - uuid or url
   */
  public setImage(image_id_or_url: string, type = '', width?: number, height?: number) {
    let imageUrl = image_id_or_url;

    if (isUUID(image_id_or_url, 4)) {
      imageUrl = FilesService.getFileUrlBySize(image_id_or_url, ImageSizeEnum.thumb);
    } else {
      // logger.debug('MetaService setImage: ', 'not uuid', image_id_or_url);
    }

    if (type === '' || type === 'og:image:url') {
      this.meta.updateTag({ property: 'og:image:url', content: imageUrl || '' }, "property='og:image:url'");
      this.meta.updateTag(
        { property: 'og:image:width', content: (width || '1440').toString() },
        "property='og:image:width'",
      );
      this.meta.updateTag(
        { property: 'og:image:height', content: (height || '800').toString() },
        "property='og:image:height'",
      );
      // logger.log('MetaService meta.og:image', imageUrl, { width, height });
    }

    if (type === '' || type === 'twitter:image') {
      this.meta.updateTag({ property: 'twitter:image', content: imageUrl || '' }, "property='twitter:image'");
      this.meta.updateTag(
        { property: 'twitter:image:width', content: (width || '1440').toString() },
        "property='twitter:image:width'",
      );
      this.meta.updateTag(
        { property: 'twitter:image:height', content: (height || '800').toString() },
        "property='twitter:image:height'",
      );
      // logger.log('MetaService meta.twitter:image', imageUrl, { width, height });
    }

    if (type === '' || type === 'vk:image') {
      this.meta.updateTag({ property: 'vk:image', content: imageUrl || '' }, "property='vk:image'");
      // logger.log('MetaService meta.vk:image', imageUrl, { width, height });
    }

    this.removeMetaLink('image_src');
    this.createMetaLink('image_src', imageUrl);
  }

  public setTypeMedia() {
    // TODO https://ogp.me/#types
    // TODO this.meta.updateTag({ property: 'og:type', content: 'media'}, `property='og:type'`);
    // // logger.log('MetaService meta.og:type', 'media');
  }

  public setTypeProfile() {
    // TODO https://ogp.me/#types
    this.meta.updateTag({ property: 'og:type', content: 'profile' }, "property='og:type'");
    // logger.log('MetaService meta.og:type', 'profile');
  }

  public setTypeArticle() {
    // TODO https://ogp.me/#types
    //   article:published_time - datetime - When the article was first published.
    //   article:modified_time - datetime - When the article was last changed.
    //   article:expiration_time - datetime - When the article is out of date after.
    //   article:author - profile array - Writers of the article.
    //   article:section - string - A high-level section name. E.g. Technology
    //   article:tag - string array - Tag words associated with this article.
    this.meta.updateTag({ property: 'og:type', content: 'article' }, "property='og:type'");
    // logger.log('MetaService meta.og:type', 'article');
  }

  public noIndex() {
    this.removeNoIndex();

    const meta = this.document.createElement('meta');
    meta.name = 'robots';
    meta.content = 'noindex';
    this.metaNode.parentNode.insertBefore(meta, this.metaNode);
  }

  public removeNoIndex() {
    const nodes = this.document.querySelectorAll('meta[name=robots]');
    if (nodes) {
      nodes.forEach((node) => node.parentNode.removeChild(node));
    }
  }

  protected setDefaultMeta() {
    // logger.log('MetaService set default meta');
    this._setTitle('OnClass'); // default Title
    this.setUrl('');
    this.setDescription(''); // TODO default description
    this.setKeywords([]); // TODO default keywords
    this.setImage(this.assetsUrl('/assets/images/onclass-online-teaching-platform.png'), '', 1000, 522);
    this.removeNoIndex();

    this.meta.updateTag({ property: 'og:type', content: 'website' }, "property='og:type'");
    this.meta.updateTag({ property: 'og:site_name', content: 'OnClass' }, "property='og:site_name'");
    // TODO <meta property='vk:app_id' content='2027420' />
    // TODO <meta name='yandex-verification' content='dfe924bfeec0a2ad' />
    // TODO <meta property='facebook_app_id' content='217598598309697' />
    // TODO <meta property='fb:app_id' content='217598598309697' />
    // TODO <meta property='fb:pages' content='309669250171'/>
  }

  public reset() {
    this.onBrowserOnly(() => {
      this.setDefaultMeta();
    });
  }
}
