<div class="file-loader">
  <div class="wrap">
    <svg class="progress-bar" viewBox="0 0 142 142">
      <circle r="70" cx="71" cy="71" fill="transparent" stroke="#EEEEEE" stroke-width="1"></circle>
      <circle r="69" cx="71" cy="71" fill="transparent" stroke="#5AB3E4" stroke-width="3"
              stroke-dasharray="433.32" stroke-dashoffset="0"
              [style.stroke-dashoffset.px]="progress">
      </circle>
    </svg>
    <span class="percent bold">{{ percentCeil }}%</span>
  </div>

  <span class="message normal">{{ 'constructor2.dropzone.please-wait' | translate }}</span>
</div>
