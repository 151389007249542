import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { RouterOutlet, Router } from '@angular/router';
import { FeaturesService, IconsService, ScreenService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { takeUntil } from 'rxjs/operators';
import { SidebarService } from './sidebar.service';

@Component({
  selector: 'app-right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RightSidebarComponent extends ChangableComponent implements OnInit {
  @Input() floating: boolean;
  public visible = false;
  public svgsetIcon = IconsService.svgsetIconUrl;

  constructor(
    private sidebarService: SidebarService,
    private screenService: ScreenService,
    private router: Router,
    private features: FeaturesService,
    protected cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  ngOnInit() {
    this.sidebarService.rightState$.pipe(takeUntil(this.destroyed$)).subscribe((visible) => {
      this.visible = visible && !this.isSystemPages;
      this.screenService.setBodyFixed(this.visible);
      this.detectChanges();
    });

    this.features.tenant$.pipe(takeUntil(this.destroyed$)).subscribe(() => this.detectChanges());
  }

  public get isSystemPages() {
    const urlList = ['/finance', '/settings', '/my-classes/', '/my-library/', '/events', '/schedule', '/users'];
    return !!urlList.find((url) => this.router.url.includes(url));
  }

  public isOutletActivated(outlet: RouterOutlet): boolean {
    return outlet.isActivated;
  }

  public onToggleSidebar() {
    this.sidebarService.toggleRightSidebar();
  }

  get isMainPage(): boolean {
    const mainUrls = ['/feed', '/dashboard', '/me/teacher'];
    return !!mainUrls.find((url) => this.router.url.includes(url));
  }
}
