import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { IconsService } from '@core/services';

export interface IChecklistOption {
  label: string;
  route?: string;
  checked?: boolean;
}
@Component({
  selector: 'app-onboarding-checklist',
  templateUrl: './onboarding-checklist.component.html',
  styleUrls: ['./onboarding-checklist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingChecklistComponent implements OnInit {
  @Input() options: IChecklistOption[];

  public svgsetIcon = IconsService.svgsetIconUrl;

  constructor() {}

  ngOnInit() {}

  public trackByFn(option: IChecklistOption): string {
    return option.label;
  }

  public getOuterRoute(route: string): string {
    return route.search(/http/) === 0 ? route : null;
  }
}
