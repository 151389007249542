import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ModalModule } from '@app/core/components/modal/modal.module';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ClipboardModule } from 'ngx-clipboard';
import { OnUiInputModule } from 'onclass-ui';
import { PublishedModalComponent } from './published-modal.component';

@NgModule({
  declarations: [PublishedModalComponent],
  imports: [
    CommonModule,
    ModalModule,
    TranslateModule,
    LazyLoadImageModule,
    RouterModule,
    OnUiInputModule,
    ClipboardModule,
    ReactiveFormsModule,
  ],
  exports: [PublishedModalComponent],
})
export class PublishedModalModule {}
