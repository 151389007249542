import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { GridSizeSelectorComponent } from './grid-size-selector.component';

@NgModule({
  declarations: [GridSizeSelectorComponent],
  imports: [CommonModule],
  exports: [GridSizeSelectorComponent],
})
export class GridSizeSelectorModule {}
