import { isPlatformServer } from '@angular/common';
import { Directive, Inject, inject, InjectionToken, PLATFORM_ID, TemplateRef, ViewContainerRef } from '@angular/core';

export const IS_SERVER_PLATFORM = new InjectionToken<boolean>('Is server?', {
  factory() {
    return isPlatformServer(inject(PLATFORM_ID));
  },
});

@Directive({
  selector: '[ifIsServer]',
})
export class IfIsServerDirective {
  public constructor(
    @Inject(IS_SERVER_PLATFORM) isServer: boolean,
    templateRef: TemplateRef<any>,
    viewContainer: ViewContainerRef,
  ) {
    if (isServer) {
      viewContainer.createEmbeddedView(templateRef);
    }
  }
}

@Directive({
  selector: '[ifIsBrowser]',
})
export class IfIsBrowserDirective {
  public constructor(
    @Inject(IS_SERVER_PLATFORM) isServer: boolean,
    templateRef: TemplateRef<any>,
    viewContainer: ViewContainerRef,
  ) {
    if (!isServer) {
      viewContainer.createEmbeddedView(templateRef);
    }
  }
}
