import { ChangeDetectorRef, Directive, ViewRef } from '@angular/core';
import { PlatformService } from '@core/services/platform.service';
import { DestroyableComponent } from './destroyable.component';

@Directive()
export abstract class ChangableComponent extends DestroyableComponent {
  public constructor(protected readonly cdr: ChangeDetectorRef, protected readonly platform?: PlatformService) {
    super(platform);
  }

  protected detectChanges() {
    if (this.cdr && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  protected markForCheck() {
    if (this.cdr && !(this.cdr as ViewRef).destroyed) {
      this.cdr.markForCheck();
    }
  }
}
