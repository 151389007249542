<div *ngIf="isBrowser"
     class="alert"
     [class.inline]="inline">
  <div class="window" [ngClass]="type" (clickOutside)="closeAlert($event)" [clickOutsideEnabled]="clickOutsideEnabled">
    <div class="alert-icon"><ng-content select="[alert-icon]"></ng-content></div>
    <div class="alert-title"><ng-content select="[alert-title]"></ng-content></div>
    <div class="alert-body"><ng-content select="[alert-body]"></ng-content></div>
    <div class="alert-buttons" [ngClass]="btnposition">
      <ng-content select="[alert-buttons]"></ng-content>
    </div>
  </div>
</div>
