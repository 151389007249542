import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DragScrollComponent, DragScrollItemDirective } from '@le2xx/ngx-drag-scroll';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideModule } from 'ng-click-outside';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { OnUiAudioPlayerModule, OnUiButtonModule, OnUiProgressBarModule } from 'onclass-ui';
import { MediaGalleryEffects } from 'src/app/store/effects/media-gallery.effects';
import * as mediaGalleryReducer from 'src/app/store/reducers/media-gallery.reducer';
import { AlertSingleModule } from '../alert-single/alert-single.module';
import { SpinnersModule } from '../spinners/spinners.module';
import { GalleryWrapComponent } from './image-gallery-wrap/gallery-wrap.component';
import { MediaFileSelectorComponent } from './media-file-selector.component';
import { MediaImageGalleryComponent } from './media-image-gallery/media-image-gallery.component';

@NgModule({
  declarations: [MediaFileSelectorComponent, MediaImageGalleryComponent, GalleryWrapComponent],
  imports: [
    CommonModule,
    AlertSingleModule,
    DragScrollComponent,
    DragScrollItemDirective,
    SpinnersModule,
    DropzoneModule,
    TranslateModule,
    ClickOutsideModule,
    StoreModule.forFeature(mediaGalleryReducer.mediaGalleryFeatureKey, mediaGalleryReducer.reducer),
    EffectsModule.forFeature([MediaGalleryEffects]),
    OnUiButtonModule,
    OnUiAudioPlayerModule,
    OnUiProgressBarModule,
  ],
  exports: [MediaFileSelectorComponent],
})
export class MediaFileSelectorModule {}
