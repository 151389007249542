import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { CachedService, CacheTtlRule, FeaturesService } from '@core/services';
import { environment } from '@env/environment';
import { DestroyableComponent } from '@models/destroyable.component';
import {
  IAccountCheck,
  IAccountQuiz,
  IFindAccountsFilter,
  IFindTeachersFilter,
  IPagedResults,
  IPagination,
  IStatusResponse,
  IUserAccess,
  TAccountQuizAnswer,
  TeacherStatusEnum,
  User,
  UserAchievementEnum,
  UserProfile,
  UserRoleEnum,
  UserSegmentEnum,
  UserStatusEnum,
  AccountDetailsType,
  FeatureEnum,
} from 'lingo2-models';
import { intersection } from 'lodash-es';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, first, map, takeUntil } from 'rxjs/operators';

export { AccountDetailsType };

/**
 * Перечень дейстий в рамках системы прав доступа
 */
export enum AccessActionEnum {
  // TODO сильно сильно расширить список прав доступа

  /** настраивать рабочее время */
  canSetupWorkingSchedule,

  /** настраивать режим отпуска */
  canSetupVacation,

  /** Возможность работать с индивидуальными занятиями */
  useUserServices,

  /** Может создавать митинги */
  canCreateMeeting,

  /** Может создавать митинги без оформления */
  canCreateDraftMeeting,
}

/** Статусы преподавателей */
export const teachableStatues = [
  TeacherStatusEnum.limited_tutor,
  TeacherStatusEnum.tutor,
  TeacherStatusEnum.teacher,
  TeacherStatusEnum.limited_teacher,
];

/**
 * Геоданные
 */
export class GeoInfo {
  /**
   * Двухбуквенный код страны по кодировке согласно ISO-3166-1 alpha-2 codes
   *
   * Кроме этого Cloudflare также использует дополнительные коды:
   * XX - Used for clients without country code data
   * T1 - Used for clients using the Tor network
   *
   * @example 'RU', 'US'
   */
  public country: string;

  /**
   * Название населённого пункта
   *
   * @example 'Novosibirsk'
   */
  public city: string;
}

@Injectable({
  providedIn: 'root',
})
export class AccountService extends DestroyableComponent implements OnDestroy {
  private _subscribed: { [key: string]: ReplaySubject<boolean> } = {};

  public constructor(
    private http: HttpClient,
    private features: FeaturesService,
    private cachedService: CachedService,
  ) {
    super();
  }

  public static accountRoute(account: Partial<User>, extra: string[] = []): string[] {
    return account ? ['/u', account?.slug, ...extra] : [];
  }

  public static getUserName(user: Partial<User>): string {
    if (!user) {
      return '';
    }
    const first_names = (user.first_name || '').split(/\s+/).filter((s) => s.length);
    const last_name = (user.last_name || '').trim();

    return [first_names[0] || '', last_name].filter((s) => s.length).join(' ');
  }

  public static getUserCropName(user: Partial<User>): string {
    if (!user) {
      return '';
    }
    const first_name = (user.first_name || '').trim();
    const last_name = (user.last_name || '').trim();

    return `${first_name[0]}. ${last_name}`;
  }

  public static getUserFullName(user: Partial<User>): string {
    if (!user) {
      return '';
    }

    return [user.first_name || '', user.last_name || ''].join(' ').replace(/\s+/g, ' ').trim();
  }

  public static getUserEditedName(user: Partial<User>): string {
    if (!user) {
      return '';
    }
    const firstName = (user.first_name || '').substring(0, 1) + '.';
    return [firstName, user.last_name || ''].join(' ').replace(/\s+/g, ' ').trim();
  }

  public static hasRole(me: Partial<User>, ...roles: UserRoleEnum[]): boolean {
    return intersection(me?.roles || [], roles).length > 0;
  }

  public static hasSegment(me: Partial<User>, segment: UserSegmentEnum): boolean {
    return (me?.segments || []).some((s) => s.segment === segment);
  }

  public static hasAchievement(me: Partial<User>, achievement: UserAchievementEnum): boolean {
    return (me?.achievements || []).some((s) => s.achievement === achievement);
  }

  public static isTeacherAllStepsFilled(
    me: Partial<User>,
    profile: Partial<UserProfile>,
    settings: Partial<IUserAccess>,
  ): boolean {
    return this.isTeacher(me) || this.isAsIfTeacher(me)
      ? !!(me?.first_name && me?.last_name && me?.country_id && me?.userpic_id) &&
          !!(me?.spoken_languages.length > 0 && profile?.birth_date && profile?.about) &&
          !!(settings?.email && settings?.email_confirmed) &&
          profile?.education_schools.length > 0 &&
          (profile?.work_experience === null || profile?.work_experience?.length > 0)
      : !!(me?.first_name && me?.last_name && me?.country_id && me?.userpic_id) &&
          !!(me?.spoken_languages.length > 0 && profile?.birth_date && profile?.about) &&
          !!(settings?.email && settings?.email_confirmed);
  }

  public static isOnline(user: Partial<User>): boolean {
    if (user && user.last_active_at) {
      const minutes = (Date.now() - new Date(user.last_active_at).getTime()) / (60 * 1000);
      return minutes < 5;
    }
    return false;
  }

  /** Права на операции или группы операций */
  public can(action: AccessActionEnum, me: User): boolean {
    switch (action) {
      case AccessActionEnum.canSetupWorkingSchedule:
        return AccountService.isAsIfTeacher(me) && this.features.isAvailable(FeatureEnum.schedule_working_schedule, me);

      case AccessActionEnum.canSetupVacation:
        return AccountService.isAsIfTeacher(me) && this.features.isAvailable(FeatureEnum.schedule_vacation, me);

      case AccessActionEnum.useUserServices:
        return AccountService.isAsIfTeacher(me);

      case AccessActionEnum.canCreateMeeting:
        return (
          AccountService.isAsIfTeacher(me) &&
          (this.features.isAvailable(FeatureEnum.free_meetings, me) ||
            this.features.isAvailable(FeatureEnum.credits_meetings, me) ||
            this.features.isAvailable(FeatureEnum.safe_deal_meetings, me))
        );

      case AccessActionEnum.canCreateDraftMeeting:
        return AccountService.isAsIfTeacher(me) && this.features.isAvailable(FeatureEnum.draft_meetings, me);
    }
  }

  /** Преподаватель? */
  public static isTeacher(user: Partial<User>): boolean {
    return user && teachableStatues.includes(user.teacher_status);
  }

  /** Ещё не верифицированый преподаватель? */
  public static isNotVerifiedTeacher(user: Partial<User>): boolean {
    return user && user.teacher_status === TeacherStatusEnum.not_verified;
  }

  /** Как бы преподаватель? */
  public static isAsIfTeacher(me: Partial<User>): boolean {
    return (
      AccountService.isTeacher(me) ||
      AccountService.isNotVerifiedTeacher(me) ||
      AccountService.hasSegment(me, UserSegmentEnum.as_if_teacher)
    );
  }

  /** Как бы студент? */
  public static isAsIfStudent(me: Partial<User>): boolean {
    return AccountService.hasSegment(me, UserSegmentEnum.as_if_student);
  }

  /** Как бы методист? */
  public static isAsIfMethodist(me: Partial<User>): boolean {
    return AccountService.hasSegment(me, UserSegmentEnum.as_if_methodist);
  }

  /** Студент? */
  public static isStudent(me: Partial<User>): boolean {
    return !AccountService.isAsIfTeacher(me);
  }

  /** Гость? */
  public static isGuest(user: Partial<User>): boolean {
    return user ? user.is_guest || user.status === UserStatusEnum.guest : false;
  }

  public getUserById(user_id: string, ttl: CacheTtlRule = false): Observable<User> {
    if (!user_id) {
      return of(null);
    }
    const url = `${environment.account_url}/account/${user_id}`;
    const params = new HttpParams();
    const source$ = this.http.get<User>(url, { params, observe: 'response' }).pipe(
      map((r) => this.handleItemResponse(r)),
      catchError(() => of(null)),
    );

    return !ttl ? source$ : this.cachedService.shared$(['Account', user_id], source$, ttl).pipe(first());
  }

  public getUserBySlug(user_slug: string, details?: AccountDetailsType[]): Observable<User> {
    const url = `${environment.account_url}/account/${user_slug}`;
    let params = new HttpParams();
    if (details && details.length) {
      params = params.set('details', JSON.stringify(details));
    }
    return this.http.get<User>(url, { params, observe: 'response' }).pipe(map((s) => this.handleItemResponse(s)));
  }

  public getGeo(): Observable<GeoInfo> {
    const url = `${environment.account_url}/geo`;
    const source$ = this.http.get<GeoInfo>(url, { observe: 'body' }).pipe(catchError(() => of(null)));
    return this.cachedService.shared$('GeoInfo', source$, 'FOREVER').pipe(first());
  }

  /**
   * @alias AccountService.addSegment()
   */
  public markAsTeacher(): Observable<User> {
    return this.addSegment(UserSegmentEnum.as_if_teacher);
  }

  /**
   * @alias AccountService.addSegment()
   */
  public markAsStudent(): Observable<User> {
    return this.addSegment(UserSegmentEnum.as_if_student);
  }

  public markAsHaveCard(): Observable<User> {
    return this.addSegment(UserSegmentEnum.has_approved_bank_card);
  }

  public addSegment(segment: UserSegmentEnum): Observable<User> {
    const url = `${environment.account_url}/account/segment/${segment}`;
    return this.http.put<User>(url, {}, { observe: 'response' }).pipe(map(this.handleItemResponse));
  }

  public removeSegment(segment: UserSegmentEnum): Observable<User> {
    const url = `${environment.account_url}/account/segment/${segment}`;
    return this.http.delete<User>(url, { observe: 'response' }).pipe(map(this.handleItemResponse));
  }

  /**
   * Список преподавателей по специфическим условиям поиска
   */
  public findTeachers(
    filter: Partial<IFindTeachersFilter>,
    pagination: IPagination,
    details?: AccountDetailsType[],
  ): Observable<IPagedResults<User[]>> {
    const url = `${environment.account_url}/teachers`;
    let params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter', JSON.stringify(filter));
    if (details && details.length) {
      params = params.set('details', JSON.stringify(details));
    }
    return this.http.get<User[]>(url, { params, observe: 'response' }).pipe(map(this.handleItemsResponse));
  }

  /**
   * Список пользователей по специческим условиям поиска
   */
  public findAccounts(
    filter: Partial<IFindAccountsFilter>,
    pagination: IPagination,
    details?: AccountDetailsType[],
  ): Observable<IPagedResults<User[]>> {
    const url = `${environment.account_url}/accounts`;
    let params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter', JSON.stringify(filter));
    if (details && details.length) {
      params = params.set('details', JSON.stringify(details));
    }
    return this.http.get<User[]>(url, { params, observe: 'response' }).pipe(map(this.handleItemsResponse));
  }

  /**
   * Список подписчиков
   */
  public getSubscribers(
    filter: Partial<IFindAccountsFilter>,
    pagination: IPagination,
    details?: AccountDetailsType[],
  ): Observable<IPagedResults<User[]>> {
    const url = `${environment.account_url}/subscribers`;
    let params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter', JSON.stringify(filter));
    if (details && details.length) {
      params = params.set('details', JSON.stringify(details));
    }
    return this.http.get<User[]>(url, { params, observe: 'response' }).pipe(map(this.handleItemsResponse));
  }

  /**
   * Является ли подписчиком
   *
   * @param user_id string
   */
  public getIsSubscribed(user_id): Observable<boolean> {
    if (!(user_id in this._subscribed)) {
      this._subscribed[user_id] = this.register(new ReplaySubject<boolean>(1));
      const url = `${environment.account_url}/${user_id}/subscribed`;

      this.http
        .get<boolean>(url, { observe: 'response' })
        .pipe(map((response) => (response.body as any).result))
        .pipe(takeUntil(this.destroyed$))
        .subscribe((subscribed) => {
          this.setIsSubscribed(user_id, subscribed);
        });
    }

    return this._subscribed[user_id];
  }

  protected setIsSubscribed(user_id: string, subscribed: boolean) {
    if (!(user_id in this._subscribed)) {
      this._subscribed[user_id] = this.register(new ReplaySubject<boolean>(1));
    }
    this._subscribed[user_id].next(subscribed);
  }

  /**
   * Добавить пользователя в подписки
   *
   * @param user_id string
   */
  public subscribe(user_id): Observable<any> {
    const url = `${environment.account_url}/${user_id}/subscribe`;
    return this.http.post(url, {}, { observe: 'response' }).pipe(
      map((response) => {
        this.setIsSubscribed(user_id, true);
        return response.body;
      }),
    );
  }

  /**
   * Удалить пользователя из подписок
   *
   * @param user_id string
   */
  public unsubscribe(user_id): Observable<any> {
    const url = `${environment.account_url}/${user_id}/unsubscribe`;
    return this.http.post(url, {}, { observe: 'response' }).pipe(
      map((response) => {
        this.setIsSubscribed(user_id, false);
        return response.body;
      }),
    );
  }

  /**
   * Список моих подписок
   */
  public getSubscriptions(
    filter: Partial<IFindAccountsFilter>,
    pagination: IPagination,
    details?: AccountDetailsType[],
  ): Observable<IPagedResults<User[]>> {
    const url = `${environment.account_url}/subscriptions`;
    let params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter', JSON.stringify(filter));
    if (details && details.length) {
      params = params.set('details', JSON.stringify(details));
    }
    return this.http.get<User[]>(url, { params, observe: 'response' }).pipe(map(this.handleItemsResponse));
  }

  /**
   * Добавить в закладки
   *
   * @param id user id
   */
  public addBookmark(id: string): Observable<IStatusResponse> {
    const url = `${environment.account_url}/${id}/bookmark`;
    return this.http.post<IStatusResponse>(url, {}, { observe: 'body' });
  }

  /**
   * Удалить из закладок
   *
   * @param id user id
   */
  public removeBookmark(id: string): Observable<IStatusResponse> {
    const url = `${environment.account_url}/${id}/bookmark`;
    return this.http.delete<IStatusResponse>(url, { observe: 'body' });
  }

  /**
   * Список моих преподавателей
   */
  public findMyTeachers(
    filter: Partial<IFindAccountsFilter>,
    pagination: IPagination,
    details?: AccountDetailsType[],
  ): Observable<IPagedResults<User[]>> {
    const url = `${environment.account_url}/my-teachers`;
    let params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter', JSON.stringify(filter));
    if (details && details.length) {
      params = params.set('details', JSON.stringify(details));
    }
    return this.http.get<User[]>(url, { params, observe: 'response' }).pipe(map(this.handleItemsResponse));
  }

  /**
   * Список моих учеников
   *
   * @param filter filter
   * @param pagination pagination
   */
  public findMyStudents(
    filter: Partial<IFindAccountsFilter>,
    pagination: IPagination,
    details?: AccountDetailsType[],
  ): Observable<IPagedResults<User[]>> {
    const url = `${environment.account_url}/my-students`;
    let params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter', JSON.stringify(filter));
    if (details && details.length) {
      params = params.set('details', JSON.stringify(details));
    }
    return this.http.get<User[]>(url, { params, observe: 'response' }).pipe(map(this.handleItemsResponse));
  }

  public updateAccount(profile: Partial<User>): Observable<User> {
    const url = `${environment.account_url}/account`;
    return this.http.put<User>(url, profile, { observe: 'response' }).pipe(map(this.handleItemResponse));
  }

  public accountCheck(): Observable<IAccountCheck> {
    const url = `${environment.account_url}/check`;
    return this.http.get<IAccountCheck>(url, { observe: 'response' }).pipe(map((response) => response.body));
  }

  /** Найти все опросники аккаунта */
  public findQuizes(): Observable<IAccountQuiz[]> {
    const url = `${environment.account_url}/quiz`;
    return this.http.get<IAccountQuiz[]>(url, { observe: 'body' });
  }

  /** Найти содержимое опросника */
  public findQuiz(form_id: string): Observable<IAccountQuiz> {
    const url = `${environment.account_url}/quiz/${form_id}`;
    return this.http.get<IAccountQuiz>(url, { observe: 'body' });
  }

  /** Создать опросник */
  public createQuiz(form_id: string, results: Record<string, TAccountQuizAnswer>): Observable<IAccountQuiz> {
    const url = `${environment.account_url}/quiz/${form_id}`;
    return this.http.post<IAccountQuiz>(url, { form_id, results }, { observe: 'body' });
  }

  /** Обновить опросник */
  public updateQuiz(form_id: string, results: Record<string, TAccountQuizAnswer>): Observable<IAccountQuiz> {
    const url = `${environment.account_url}/quiz/${form_id}`;
    return this.http.put<IAccountQuiz>(url, { form_id, results }, { observe: 'body' });
  }

  /** Удалить опросник */
  public deleteQuiz(form_id: string): Observable<void> {
    const url = `${environment.account_url}/quiz/${form_id}`;
    return this.http.delete<void>(url, { observe: 'body' });
  }

  public get2FACode(): Observable<string> {
    const url = `${environment.account_url}/auth/2fa/generate`;
    return this.http.post(url, {}, { responseType: 'text' });
  }

  public turnOn2FACode(body): Observable<any> {
    const url = `${environment.account_url}/auth/2fa/turn-on`;
    return this.http.post(url, body, { observe: 'body' });
  }

  public turnOff2FACode(body): Observable<any> {
    const url = `${environment.account_url}/auth/2fa/turn-off`;
    return this.http.post(url, body, { observe: 'body' });
  }

  public get2FAStatus(): Observable<any> {
    const url = `${environment.account_url}/auth/2fa/status`;
    return this.http.get(url, { observe: 'body' });
  }

  private handleItemResponse(response: HttpResponse<User>): User {
    return new User(response.body);
  }

  private handleItemsResponse(response: HttpResponse<User[]>): IPagedResults<User[]> {
    const pagination: IPagination = {
      page: +response.headers.get('X-Pagination-Page'),
      pageSize: +response.headers.get('X-Pagination-PageSize'),
      total: +response.headers.get('X-Pagination-Total'),
      totalPages: +response.headers.get('X-Pagination-TotalPages'),
    };
    // const totalRecords = +res.headers.get('X-InlineCount');
    return {
      results: response.body.map((i) => new User(i)),
      pagination,
      ...pagination,
    };
  }
}
