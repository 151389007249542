import { trigger, transition, useAnimation } from '@angular/animations';
import { Component, OnInit, Input } from '@angular/core';
import { UtilsService } from '@app/content-editor/content-form-page/_services';
import { moveFadeOut, moveFadeIn } from '@core/animations';
import { IconsService, PlatformService } from '@core/services';

import { DestroyableComponent } from '@models/destroyable.component';
import { takeUntil, tap } from 'rxjs/operators';
import { IVariantPopoverConfig, ToolbarItemTypeEnum } from './task-answer-variant-popover.models';

@Component({
  selector: 'app-task-answer-variant-popover',
  templateUrl: './task-answer-variant-popover.component.html',
  styleUrls: ['./task-answer-variant-popover.component.scss'],
  animations: [
    trigger('toolbar', [
      transition(':enter', useAnimation(moveFadeIn, { params: { time: '200ms', slide: '5px' } })),
      transition(':leave', useAnimation(moveFadeOut)),
    ]),
  ],
})
export class TaskAnswerVariantPopoverComponent extends DestroyableComponent implements OnInit {
  @Input() config: IVariantPopoverConfig;
  @Input() public onClose: () => void;
  @Input() public onSelect: (type?: ToolbarItemTypeEnum) => void;
  @Input() public onCheck: () => void;
  @Input() public onDelete: () => void;

  public itemTypes = ToolbarItemTypeEnum;
  public variantActions = false;

  // TODO: use native cdk click outside
  public clickOutsideEnabled = false;

  public svgsetIcon = IconsService.svgsetIconUrl;

  public constructor(protected utilsService: UtilsService, protected readonly platform: PlatformService) {
    super(platform);
  }

  public ngOnInit() {
    this.utilsService.lessonSlideActions$
      .pipe(
        tap((action) => {
          this.variantActions = action;
        }),
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe();
    this.setTimeout(() => {
      this.clickOutsideEnabled = true;
    }, 100);
  }

  public close(): void {
    this.onClose();
  }

  public onOutsideClick(): void {
    this.onClose();
  }

  public openModal(value: any): void {}

  public select(type: ToolbarItemTypeEnum): void {
    this.onSelect(type);
  }

  public check(): void {
    this.onCheck();
  }

  public delete(): void {
    this.onDelete();
  }
}
