import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import {
  IMeetingStartedNotice,
  INoticeParams,
  MeetingTypeEnum
} from 'lingo2-models';
import {EModifier, IBaseNotification} from '../../base-notification/base-notification.component';

@Component({
  selector: 'app-meeting-started',
  templateUrl: './meeting-started.component.html',
  styles: [''],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeetingStartedComponent {
  public params: IMeetingStartedNotice;
  public base: IBaseNotification;
  public meetingType = MeetingTypeEnum;

  @Input() set notification(notification: INoticeParams) {
    this.params = notification.params;
    this.base = {
      title: 'notifications.meeting_started.title',
      icon: 'notice-clock',
      created_at: notification.created_at,
      modifier: EModifier.blue,
    };
  }

  public get teacher() {
    return `<a class='user-fullname-link' target='_blank' href='${this.params.author_url}'>${this.params.author_fullname}</a>`;
  }

  public get title() {
    return `<a class='link-base' href='${this.params.meeting_join_url}'>${this.params.meeting_title}</a>`;
  }
}
