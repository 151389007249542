import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TinyMCEConfig } from '@core/components/wyisiwyg-editor/tinymce.config';
import { ChangableComponent } from '@models/changable.component';
import { TranslateService } from '@ngx-translate/core';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';

import { richTextTinyMCEConfig } from './rich-editor-tinymce.config';

@Component({
  selector: 'app-slide-rich-text',
  templateUrl: './slide-rich-text.component.html',
  styleUrls: ['./slide-rich-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SlideRichTextComponent),
      multi: true,
    },
  ],
})
export class SlideRichTextComponent extends ChangableComponent implements OnInit, OnChanges, ControlValueAccessor {
  @ViewChild(EditorComponent) editorComp: EditorComponent;
  // TODO: отрефакторить работу с модалками
  @Input() isModalOpened: boolean;
  @Input() isError = false;
  @Input() hideAuthFocus: boolean;
  @Output() modalOpened = new EventEmitter<boolean>();

  @Input() isRichText: boolean;
  @Input() isCentered = false;

  @Input() placeholder = '';
  @Input() maxlength = 2000;

  @Input() fitText = false;

  @Output() changed = new EventEmitter<string>();
  @Output() blured = new EventEmitter<void>();

  public isEditable = false;

  public defaultConfig = {
    ...TinyMCEConfig,
    quickbars_insert_toolbar: '',
    quickbars_selection_toolbar: '',
    toolbar: '',
    contextmenu: '',
    auto_focus: true,
  };
  public editorId = 'toolbar_' + (Math.random() * new Date().getTime()).toFixed();

  // TODO: кастомизировать внешний вид редактора
  public dialogConfig = {
    ...richTextTinyMCEConfig,
    placeholder: this.translate.instant('constructor2.placeholder.gaps-content'),
    auto_focus: true,
    menubar: false,
    toolbar: `undo redo
      | formatselect fontsizeselect
      | hr columns
      | bold italic underline strikethrough
      | quicktable
      | blockquote-colors marker-colors pen-colors
      | align bullist
      | additional`,
    quickbars_insert_toolbar: '',
    quickbars_selection_toolbar: '',
    fixed_toolbar_container: '#' + this.editorId,
  };

  public formControl = new UntypedFormControl();
  public formControlDialog = new UntypedFormControl();

  public value = '';
  public dialogValue = '';
  public isDisabled: boolean;
  public isFocused = false;
  public editorTextEl: any;

  constructor(private translate: TranslateService, protected readonly cdr: ChangeDetectorRef) {
    super(cdr);
  }

  ngOnInit(): void {
    if (this.hideAuthFocus) {
      this.defaultConfig.auto_focus = false;
      this.detectChanges();
    }

    this.formControl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      this.value = value;
      this.propagateChange(value);
      this.changed.emit(value);
      this.detectChanges();
    });

    // Синхронизируем значения между модальным окном и инлайновым редактором
    this.formControlDialog.valueChanges
      .pipe(
        filter((value) => value !== this.dialogValue),
        debounceTime(200),
        takeUntil(this.destroyed$),
      )
      .subscribe((value) => {
        this.dialogValue = value;
        if (this.dialogValue !== this.value) {
          this.value = this.dialogValue;
          this.formControl.setValue(value);
          this.propagateChange(value);
          this.changed.emit(value);
          this.detectChanges();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isModalOpened && !changes.isModalOpened.firstChange && changes.isModalOpened.currentValue) {
      this.formControlDialog.setValue(this.value);
      this.detectChanges();
    }
  }

  private propagateChange: any = () => {};
  private propagateTouched: any = () => {};

  writeValue(value: string): void {
    this.value = value;
    this.dialogValue = value;
    this.formControl.setValue(value);
    this.formControlDialog.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onBlur(): void {
    this.propagateTouched();
    this.blur();
    this.blured.emit();
  }

  focus(): void {
    this.isFocused = true;
    this.setCursor();
    this.detectChanges();
  }

  blur(): void {
    this.isFocused = false;
    this.detectChanges();
  }

  public closeModal() {
    this.modalOpened.emit(false);
    this.propagateTouched();
    this.blured.emit();
  }

  private setCursor(): void {
    if (this.editorComp) {
      const editor = this.editorComp.editor;
      editor.targetElm.focus();
    }
  }

  initEditor(e) {
    if (!this.fitText) {
      return;
    }
    this.editorTextEl = e.editor.contentAreaContainer;
    this.fGummaFontSize();
  }

  changeEditor(e) {
    if (!this.fitText) {
      return;
    }
    this.editorTextEl = e.target;
    this.fGummaFontSize();
  }

  onPasteEditor(e) {
    if (!this.fitText) {
      return;
    }
    this.fGummaFontSize();
  }

  /* Функция пересчёта размера шрифта */
  private fGummaFontSize() {
    const element = this.editorTextEl;
    /* Увеличиваем размер шрифта, до появления прокрутки */
    while (element.scrollHeight <= element.clientHeight) {
      const fontsize = parseFloat(getComputedStyle(element).fontSize);
      if (fontsize < 18) {
        element.style.fontSize = String(fontsize + 1) + 'px';
      } else {
        break;
      }
    }

    /* Уменьшаем размер шрифта, пока прокрутка не исчезнет */
    while (element.scrollHeight > element.clientHeight) {
      const fontsize = parseFloat(getComputedStyle(element).fontSize);
      if (fontsize > 10) {
        element.style.fontSize = String(fontsize - 1) + 'px';
      } else {
        break;
      }
    }
  }
}
