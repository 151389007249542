<div class="question" [class.error]="isError">
  <div class="label"><strong>{{ 'constructor2.slide-question.label' | translate }}</strong></div>
  <div class="control">
    <app-slide-rich-text
      class="text"
      [placeholder]="placeholder | translate"
      [appTaskAnswerVariantPopover]="questionMultyInputConfig"
      (selected)="onVariantTypeSelected($event)"
      [formControl]="formControl"
      [isModalOpened]="isModalOpened"
      [isRichText]="isRichText"
      [isError]="isError"
      (modalOpened)="onModalOpened($event)"
      (changed)="onChanged()"
      (blured)="onBlur()">
    </app-slide-rich-text>
  </div>
</div>
