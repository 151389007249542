import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { AboutVerificationWidgetComponent } from './about-verification-widget.component';

@NgModule({
  declarations: [AboutVerificationWidgetComponent],
  imports: [CommonModule, TranslateModule],
  exports: [AboutVerificationWidgetComponent],
})
export class AboutVerificationWidgetModule {}
