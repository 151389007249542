import { CommonModule, DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ContextService, IconsService, LanguageService, PlatformService } from '@app/core/services';
import { ChangableComponent } from '@app/models/changable.component';
import { IControlOption } from '@app/shared';
import { logger } from '@core/helpers/logger';
import { environment } from '@env/environment';
import { addHours } from 'date-fns';
import { Language } from 'lingo2-models';
import { uniq } from 'lodash';
import { CookieService } from 'ngx-cookie';
import { OnUiButtonModule, OnUiSelectModule } from 'onclass-ui';
import { combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-suggest-language-change',
  standalone: true,
  imports: [CommonModule, OnUiSelectModule, OnUiButtonModule, FormsModule],
  templateUrl: './suggest-language-change.component.html',
  styleUrls: ['./suggest-language-change.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestLanguageChangeComponent extends ChangableComponent implements OnInit {
  public svgSetIcon = IconsService.svgsetIconUrl;
  public displaySelector = false;
  public languagesOptions: IControlOption[] = [];
  public selectedLanguageId: number;
  public isActive = false;

  private languages: Language[] = [];
  private userLanguage: Language;
  private detectedLanguages: Language[] = [];
  private firstDetectedLanguage: Language;
  private currentLanguage: Language;
  private temporaryLanguageCodeKey = 'APP_TEMP_LANG';

  constructor(
    protected contextService: ContextService,
    protected languageService: LanguageService,
    @Inject(DOCUMENT) private htmlDocument: HTMLDocument,
    private cookie: CookieService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngOnInit(): void {
    if (this.isServer) {
      logger.debug('SuggestLanguageChangeComponent -> server -> skip suggest');
      return;
    }

    combineLatest([this.contextService.me$, this.languageService.language$, this.languageService.languages$])
      .pipe(
        filter(([me, language, languages]) => !!me && !!language && !!languages),
        takeUntil(this.destroyed$),
      )
      .subscribe(([me, currentLanguage, languages]) => {
        this.userLanguage = languages.find((a) => a.code === me.ui_language);
        if (this.userLanguage?.id === currentLanguage.id) {
          logger.debug('SuggestLanguageChangeComponent -> same ui_language -> skip');
          return; // пользователь уже на том языке, который он выбирал - ничего менять не надо
        }
        this.languages = languages;
        this.currentLanguage = currentLanguage;
        this.init();
      });
  }

  public get suggestedContent(): string {
    if (!this.selectedLanguage) {
      return '???';
    }

    return (this.selectedLanguage.domain_switch_description || '{{detected}} {{suggested}}')
      .replace('{{detected}}', this.selectedLanguage.title_native)
      .replace('{{suggested}}', this.selectedLanguage.title_native);
  }

  public get buttonTitle(): string {
    if (this.selectedLanguage.code === 'ru') {
      return 'Подтвердить';
    }
    return 'Confirm';
  }

  /** Сменить язык пользователя на язык домена */
  public acceptSuggestedLanguage() {
    this.languageService.changeLanguage(this.selectedLanguage);
    this.storeSuggestLanguageCode = null; // забыть, на каком языке пользователь в прошлый раз выбирал disableLanguageSuggest()
    this.isActive = false; // закрыть виджет, даже если не произошёл редирект
    this.detectChanges();
  }

  /** Отказаться от подсказок на смену языка */
  public disableLanguageSuggest() {
    this.isActive = false;
    this.patchPageStyles(false);
    this.storeSuggestLanguageCode = this.currentLanguage.code; // запомнить, какой именно язык был текущим
    this.detectChanges();
  }

  private detectLanguages(): Language[] {
    const _navigatorLanguages = navigator.languages === undefined ? [navigator.language] : navigator.languages;
    logger.debug('SuggestLanguageChangeComponent navigatorLanguages', _navigatorLanguages);
    const navigatorLanguages = uniq([
      this.userLanguage?.code,
      ..._navigatorLanguages.map((code) => code.toLowerCase()),
      ..._navigatorLanguages.map((code) => code.split('-')[0].toLowerCase()), // для кодов вида 'en-GB' добавляются простые коды вида 'en'
      environment.default_language,
    ]);

    // заполнение массива языков в порядке следования navigatorLanguages
    const languages: Language[] = [];
    navigatorLanguages.map((code) => {
      const lang = this.languages.find((_l) => _l.code.toLowerCase() === code);
      if (lang && !languages.find((_l) => _l.id === lang.id)) {
        languages.push(lang);
      }
    });

    return languages;
  }

  private get selectedLanguage(): Language {
    return this.languages.find((_l) => _l.id === this.selectedLanguageId);
  }

  private patchPageStyles(enabled: boolean) {
    // TODO реализовать на подписках, а не патчить стили харкорно
    const elem = document.querySelector('.app-body');
    if (!elem) {
      return;
    }
    if (enabled) {
      elem.classList.add('suggest-language-change');
    } else {
      elem.classList.remove('suggest-language-change');
    }
  }

  private init() {
    if (this.storeSuggestLanguage) {
      logger.debug('SuggestLanguageChangeComponent -> suppress for language ' + this.storeSuggestLanguage.code);
      if (this.storeSuggestLanguage.id === this.currentLanguage.id) {
        logger.debug('SuggestLanguageChangeComponent -> user temp language change -> skip');
        return;
      }
    }

    this.detectedLanguages = this.detectLanguages();
    logger.debug('SuggestLanguageChangeComponent:init detectedLanguages', this.detectedLanguages);
    if (!this.detectedLanguages.length) {
      // мы не смогли определить, какой язык показать пользователю
      logger.debug('SuggestLanguageChangeComponent -> empty detectedLanguages -> skip');
      return;
    }

    this.firstDetectedLanguage = this.detectedLanguages[0];
    if (this.firstDetectedLanguage.id === this.currentLanguage.id) {
      // предполагаемый язык уже соотвествует текущему языку
      logger.debug('SuggestLanguageChangeComponent -> already on suggested language -> skip');
      return;
    }

    // для автотестов
    if (this.isAutotestAgent) {
      return;
    }

    this.selectedLanguageId = this.firstDetectedLanguage.id;
    this.prepareLanguagesOptions();
    this.isActive = true;
    this.patchPageStyles(true);
    this.detectChanges();
  }

  private prepareLanguagesOptions() {
    const index: number[] = [this.currentLanguage.id];
    this.languagesOptions = [];

    this.detectedLanguages.map((_l) => {
      if (index.includes(_l.id)) {
        return;
      }

      index.push(_l.id);
      this.languagesOptions.push({
        title: this.languageTitle(_l),
        value: _l.id,
      });
    });

    this.languages.map((_l) => {
      if (index.includes(_l.id)) {
        return;
      }

      index.push(_l.id);
      this.languagesOptions.push({
        title: this.languageTitle(_l),
        value: _l.id,
      });
    });
  }

  private languageTitle(language: Language): string {
    return language.title_native + (language.title_native !== language.title ? ` (${language.title})` : '');
  }

  private get storeSuggestLanguage(): Language {
    const code = this.storeSuggestLanguageCode;
    return code ? this.languages.find((_l) => _l.code === code) : null;
  }

  private get storeSuggestLanguageCode(): string {
    return this.cookie.get(this.temporaryLanguageCodeKey);
  }

  private set storeSuggestLanguageCode(code: string) {
    if (code) {
      this.cookie.put(this.temporaryLanguageCodeKey, code, {
        expires: addHours(new Date(), 24), // запомнить на 24 часа
      });
    } else {
      this.cookie.remove(this.temporaryLanguageCodeKey);
    }
  }
}
