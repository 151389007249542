import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DirectivesModule } from '@app/core/directives/directives.module';
import {
  ModalActionsComponent,
  ModalComponent,
  ModalAdComponent,
  ModalBodyComponent,
  ModalTitleComponent,
} from './modal.component';

@NgModule({
  declarations: [ModalComponent, ModalAdComponent, ModalBodyComponent, ModalTitleComponent, ModalActionsComponent],
  imports: [CommonModule, DirectivesModule],
  exports: [ModalComponent, ModalAdComponent, ModalBodyComponent, ModalTitleComponent, ModalActionsComponent],
  providers: [],
})
export class ModalModule {}
