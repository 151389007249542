export const stepsList = [
  {
    count: 1,
    text: 'first-contact.steps.steps-list.text1',
    image: 'classes',
  },
  {
    count: 2,
    text: 'first-contact.steps.steps-list.text2',
    image: 'subjects',
  },
  {
    count: 3,
    text: 'first-contact.steps.steps-list.text3',
    image: 'user-services',
  },
  {
    count: 4,
    text: 'first-contact.steps.steps-list.text4',
    image: 'schedule',
  },
  {
    count: 5,
    text: 'first-contact.steps.steps-list.text5',
    image: 'payment',
  },
  {
    count: 6,
    text: 'first-contact.steps.steps-list.text6',
    image: 'app',
  },
];
