<section class="first-contact-processes" [class.mobile]="deviceService.isMobile()">
  <h2 class="title">{{ 'first-contact.processes.title' | translate }}</h2>
  <p class="subtitle" [innerHTML]="'first-contact.processes.subtitle' | translate"></p>

  <div class="process-list">
    <div *ngFor="let item of processesList()" class="process-item">
      <h3 class="process-title" [innerHTML]="item.title | translate"></h3>
      <div class="wrap">
        <div class="image" [ngClass]="item.image"></div>
        <div class="text-wrap">
          <p class="process-text">{{ item.text | translate }}</p>
          <a class="process-link" [routerLink]="item.link">{{ item.linkLabel | translate }}</a>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="selectedRole === 'methodist'">
    <div class="methodist-wrap">
      <h3 class="methodist-title">{{ 'first-contact.processes.methodist-title' | translate }}</h3>

      <on-ui-button class="secondary"
                    label="first-contact.processes.methodist-action"
                    (onclick)="onNavigate('/me/finance/cards')">
      </on-ui-button>
    </div>
  </ng-container>
</section>
