import { addMinutes } from 'date-fns';
import { getTimezoneOffset, utcToZonedTime } from 'date-fns-tz';

/**
 * @param date
 * @param timeZone
 */
export function formatDateByTimeZone(date: Date, timeZone: string): Date {
  if (date && timeZone) {
    return utcToZonedTime(date, timeZone);
  }
  return date;
}

/**
 * @param date
 * @param timezone
 */
export function normalizeZonedDate(date: Date, timezone: string): Date {
  if (!date) {
    return null;
  }
  const zoneOffset = getTimezoneOffset(timezone, date) / 1000 / 60;
  const myTimezoneOffset = -1 * date.getTimezoneOffset();
  return addMinutes(date, myTimezoneOffset - zoneOffset);
}
