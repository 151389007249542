<app-drawer [opened]="opened" [end]="true" (closed)="close()" *ngIf="isDeveloper || (debug$ | async) || isDevEnv">
  <div header><h3>Debug panel</h3></div>
  <div content>

    <section class="panel" [class.expanded]="isExpanded('userid')">
      <div class="panel-header" (click)="toggleExpand('userid')">My UserID</div>
      <div class="panel-body">
        <input class="form-control" readonly type="text" [value]="me?.id" onclick="this.select()">
      </div>
    </section>

    <section class="panel" [class.expanded]="isExpanded('tenant')">
      <div class="panel-header" (click)="toggleExpand('tenant')">Tenant</div>
      <div class="panel-body">
        <ng-container [ngTemplateOutlet]="tenantRef"></ng-container>
      </div>
    </section>

    <section class="panel" [class.expanded]="isExpanded('account-check')">
      <div class="panel-header" (click)="toggleExpand('account-check')">Account Check</div>
      <div class="panel-body">
        <ng-container [ngTemplateOutlet]="accountCheckRef"></ng-container>
      </div>
    </section>

    <section class="panel" [class.expanded]="isExpanded('roles')">
      <div class="panel-header" (click)="toggleExpand('roles')">Roles</div>
      <div class="panel-body">
        <ng-container [ngTemplateOutlet]="rolesRef"></ng-container>
      </div>
    </section>

    <section class="panel" [class.expanded]="isExpanded('segments')">
      <div class="panel-header" (click)="toggleExpand('segments')">Segments</div>
      <div class="panel-body">
        <ng-container [ngTemplateOutlet]="segmentsRef"></ng-container>
      </div>
    </section>

    <section class="panel" [class.expanded]="isExpanded('geo')">
      <div class="panel-header" (click)="toggleExpand('geo')">GEO</div>
      <div class="panel-body">
        <ng-container [ngTemplateOutlet]="geoRef"></ng-container>
      </div>
    </section>

    <section class="panel" [class.expanded]="isExpanded('remoteConfig')">
      <div class="panel-header" (click)="toggleExpand('remoteConfig')">Remote Config</div>
      <div class="panel-body">
        <div class="panel-toolbar">
          <div class="btn btn-primary btn-small" (click)="reloadRemoteConfig()">update</div>
        </div>
        <ng-container [ngTemplateOutlet]="remoteConfigRef"></ng-container>
      </div>
    </section>
  </div>
</app-drawer>

<div class="toggler" *ngIf="isDevEnv || isDeveloper || (debug$ | async)" (click)="toggle()">⚙️</div>

<ng-template #tenantRef>
  <div class="btn-group">
    <div class="btn"
         [ngClass]="!tenantOverride ? 'btn-primary' : 'btn-gray'"
         (click)="setTenantOverride(null)">{{ 'auto (' + defaultTenant + ')' }}</div>

    <div class="btn"
         [ngClass]="tenantOverride === tenants.onclass_me ? 'btn-primary' : 'btn-gray'"
         (click)="setTenantOverride(tenants.onclass_me)">onclass.me</div>

    <div class="btn"
         [ngClass]="tenantOverride === tenants.onclass_com ? 'btn-primary' : 'btn-gray'"
         (click)="setTenantOverride(tenants.onclass_com)">onclass.com</div>
  </div>
</ng-template>

<ng-template #accountCheckRef>
<pre>{{ accountCheck | json }}</pre>
</ng-template>

<ng-template #rolesRef>
  <span class="badge" *ngFor="let role of roles">{{ role }}</span>
</ng-template>

<ng-template #segmentsRef>
  <span class="badge" *ngFor="let segment of segments">{{ segment }}</span>
</ng-template>

<ng-template #geoRef>
  <pre>{{ geo | json }}</pre>
</ng-template>

<ng-template #remoteConfigRef>
  <pre>{{ remoteConfig | json }}</pre>
  <hr/>
  <div class="strong mt">promo_a_config</div>
  <pre>{{ promo_a_config | json }}</pre>

  <strong class="mt">promo_b_config</strong>
  <pre>{{ promo_b_config | json }}</pre>

  <strong class="mt">promo_c_config</strong>
  <pre>{{ promo_c_config | json }}</pre>

  <strong class="mt">promo_d_config</strong>
  <pre>{{ promo_d_config | json }}</pre>
</ng-template>
