import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestService } from '@core/services/request.service';
import { environment } from '@env/environment';
import { ORIGIN_PARAM, VERSION_HASH_PARAM, VERSION_NUMBER_PARAM } from '@models/constants';
import { DestroyableComponent } from '@models/destroyable.component';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IconsService extends DestroyableComponent {
  constructor(private http: HttpClient, private request: RequestService) {
    super();
  }

  public static svgsetIconUrl(icon: string): string {
    return `#${icon}`;
  }

  /** Код домена, с которого отправляется запрос, слегка упрощённый, чтобы было проще преобразовывать в URL */
  protected get host(): string {
    return this.request.host.replace('http://', '').replace('https://', 'https-').replace(':', '-');
  }

  public inject() {
    const params = new URLSearchParams({
      [ORIGIN_PARAM]: this.host,
      [VERSION_NUMBER_PARAM]: environment.versions.version,
      [VERSION_HASH_PARAM]: environment.versions.gitCommitHash,
    });
    this.http
      .get(this.assetsUrl(this.request.host + `/assets/icons/svgset.svg?${params}`), {
        responseType: 'text',
        withCredentials: false,
        observe: 'response',
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        if (response.body) {
          const div = document.createElement('div');
          div.setAttribute('style', 'display: none;');
          div.innerHTML = response.body;
          document.getElementsByTagName('body')[0].appendChild(div);
        }
      });
  }
}
