<app-onboarding-user-status
  [onboardingStatus]="status"
  [time]="verification_sent_at">
</app-onboarding-user-status>

<!-- <app-onboarding-progressbar [max]="4" [value]="4" *ngIf="!isExpanded"></app-onboarding-progressbar> -->

<div class="expandable" *ngIf="isExpanded$ | async">

  <!-- <app-onboarding-title icon="emoji-party">
    Start teaching in 4 simple steps
  </app-onboarding-title>

  <div class="progressbar">
    <app-onboarding-progressbar [max]="4" [value]="4" [rounded]="true"></app-onboarding-progressbar>
  </div>

  <div class="on-moderation">
    Awaiting moderation
  </div> -->

  <app-onboarding-title [title]="'onboarding.start-teaching-and-earn' | translate">
  </app-onboarding-title>

  <app-onboarding-checklist
    [options]="checklistOptions">
  </app-onboarding-checklist>

  <app-onboarding-qr-code></app-onboarding-qr-code>

  <app-onboarding-list-panel [options]="panelOptions" title="onboarding.now-available"></app-onboarding-list-panel>

</div>