import { ClassroomParticipantsBlockType, IFindClassroomParticipantsFilter } from '@core/services';
import { Action, createReducer, on } from '@ngrx/store';
import { ClassroomParticipantDetailsType, ClassroomParticipantRoleEnum, MeetingAcceptStatusEnum } from 'lingo2-models';
import * as ParticipantsAction from './participants.actions';

const DEFAULT_DETAILS: ClassroomParticipantDetailsType[] = ['id', 'user_id', 'accept_status', 'joined_at', 'user:sm'];

const DEFAULT_FILTER: Partial<IFindClassroomParticipantsFilter> = {
  role: ClassroomParticipantRoleEnum.student,
  accept_status: MeetingAcceptStatusEnum.accepted,
};

export interface ClassroomParticipantsState extends ClassroomParticipantsBlockType {
  error: string;
  details: ClassroomParticipantDetailsType[];
  filter: Partial<IFindClassroomParticipantsFilter>;
  infiniteScroll: boolean;
}

const PAGE_SIZE = 10;

const initialState: ClassroomParticipantsState = {
  items: [],
  loading: false,
  loaded: false,
  filter: DEFAULT_FILTER,
  pagination: {
    page: 1,
    pageSize: PAGE_SIZE,
    total: 0,
    totalPages: 0,
  },
  error: null,
  details: DEFAULT_DETAILS,
  infiniteScroll: true,
};

const contentReducer = createReducer(
  initialState,

  on(ParticipantsAction.requestFail, (state, { error }) => ({ ...state, loading: false, error })),

  on(ParticipantsAction.loadClassroomParticipants, (state) => ({ ...state, loading: true, error: null })),

  on(ParticipantsAction.loadClassroomParticipantsSuccess, (state, { response }) => {
    const uniqueItems = response.results.filter((x) => !state.items.some((item) => item.id === x.id));
    return {
      ...state,
      items: state.infiniteScroll ? [...state.items, ...uniqueItems] : [...uniqueItems],
      pagination: {
        ...state.pagination,
        total: response.total,
        totalPages: response.totalPages,
        page: response.page,
      },
      loading: false,
      loaded: true,
    };
  }),

  on(ParticipantsAction.clearClassroomParticipants, (state) => ({ ...initialState })),

  on(ParticipantsAction.loadNextPageClassroomParticipants, (state) => {
    const isAvailable = state.pagination.page < state.pagination.totalPages;
    const page = isAvailable ? state.pagination.page + 1 : state.pagination.page;
    return {
      ...state,
      pagination: {
        ...state.pagination,
        page,
      },
    };
  }),

  on(ParticipantsAction.pushClassroomParticipants, (state, { participants }) => {
    const uniqueItems = participants.filter((x) => !state.items.some((item) => item.id === x.id));
    return { ...state, items: [...uniqueItems, ...state.items] };
  }),
);

export function reducer(state = initialState, action: Action) {
  return contentReducer(state, action);
}
