import { FilePurposeEnum } from 'lingo2-models';

import { environment } from '../../../../environments/environment';

const getCookie = (key: string) => {
  const matches = document.cookie.match(
    new RegExp('(?:^|; )' + key.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'),
  );
  return matches ? decodeURIComponent(matches[1]) : null;
};

const authPrefix = 'Bearer';

export const example_image_upload_handler = (blobInfo, success, failure, progress) => {
  const purpose = FilePurposeEnum.content;
  const url = `${environment.files_url}/file/upload+process?purpose=${purpose}`;
  const access_token_key = environment.access_token_key || 'APP_AUTH_ACCESS';
  const accessToken = getCookie(access_token_key);

  const xhr = new XMLHttpRequest();
  xhr.open('POST', url);
  xhr.setRequestHeader('Authorization', `${authPrefix} ${accessToken}`);
  // xhr.withCredentials = true;
  // xhr.setRequestHeader('Access-Control-Allow-Credentials', true);

  xhr.upload.onprogress = function(e) {
    progress((e.loaded / e.total) * 100);
  };

  xhr.onload = function() {
    if (xhr.status === 403) {
      failure('HTTP Error: ' + xhr.status, { remove: true });
      return;
    }

    if (xhr.status < 200 || xhr.status >= 300) {
      failure('HTTP Error: ' + xhr.status);
      return;
    }

    const json = JSON.parse(xhr.responseText);

    if (!json || !json.storage_file_url) {
      failure('Invalid JSON: ' + xhr.responseText);
      return;
    }

    success(json.storage_file_url);
  };

  xhr.onerror = function() {
    failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
  };

  const formData = new FormData();
  formData.append('file', blobInfo.blob(), blobInfo.filename());

  xhr.send(formData);
};
