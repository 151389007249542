import { Injectable } from '@angular/core';
import { AccountService, FeaturesService } from '@core/services';
import { DestroyableComponent } from '@models/destroyable.component';
import { FeatureEnum, User, UserSegmentEnum } from 'lingo2-models';
import { BehaviorSubject } from 'rxjs';
import { BulletEnum as BE, IListPanelOption } from './models';
import { IChecklistOption } from './shared/onboarding-checklist/onboarding-checklist.component';

const isStatusExpandedKey = 'onboardingIsStatusExpanded';
const isPanelExpandedKey = 'onboardingIsPanelExpanded';
@Injectable({
  providedIn: 'root',
})
export class OnboardingWidgetService extends DestroyableComponent {
  private _isStatusExpanded = false;
  private isStatusExpanded = this.register(new BehaviorSubject<boolean>(this._isStatusExpanded));
  public isStatusExpanded$ = this.isStatusExpanded.asObservable();

  private _isPanelExpanded = false;
  private isPanelExpanded = this.register(new BehaviorSubject<boolean>(this._isPanelExpanded));
  public isPanelExpanded$ = this.isPanelExpanded.asObservable();

  constructor(private features: FeaturesService) {
    super();
    if (localStorage || false) {
      this._isStatusExpanded =
        localStorage.getItem(isStatusExpandedKey) !== null
          ? JSON.parse(localStorage.getItem(isStatusExpandedKey))
          : true;
      this.isStatusExpanded.next(this._isStatusExpanded);

      this._isPanelExpanded =
        localStorage.getItem(isPanelExpandedKey) !== null
          ? JSON.parse(localStorage.getItem(isPanelExpandedKey))
          : false;
      this.isPanelExpanded.next(this._isPanelExpanded);
    }
  }

  public toggleStatusExpanded(): void {
    this._isStatusExpanded = !this._isStatusExpanded;
    if (localStorage || false) {
      localStorage.setItem(isStatusExpandedKey, JSON.stringify(this._isStatusExpanded));
    }
    this.isStatusExpanded.next(this._isStatusExpanded);
  }

  public togglePanelExpanded(): void {
    this._isPanelExpanded = !this._isPanelExpanded;
    if (localStorage || false) {
      localStorage.setItem(isPanelExpandedKey, JSON.stringify(this._isPanelExpanded));
    }
    this.isPanelExpanded.next(this._isPanelExpanded);
  }

  protected generateSegments(me: User): { [key: string]: boolean } {
    return {
      has_verified_teacher_profile: AccountService.hasSegment(me, UserSegmentEnum.has_verified_teacher_profile),
      has_user_services: AccountService.hasSegment(me, UserSegmentEnum.has_user_services),
      can_acceptance_payments:
        AccountService.hasSegment(me, UserSegmentEnum.has_approved_bank_card) ||
        AccountService.hasSegment(me, UserSegmentEnum.can_acceptance_payments),
      specified_working_days:
        AccountService.hasSegment(me, UserSegmentEnum.has_free_hours_30days) ||
        AccountService.hasSegment(me, UserSegmentEnum.has_busy_hours_30days),
      use_mobile_app: AccountService.hasSegment(me, UserSegmentEnum.use_mobile_app),
    };
  }

  // TODO: Надо упростить, но сейчас я делаю запрос для того, чтобы
  //  получить сервисы пользователя, так как статистика не пересчитывается или пересчитывается долго
  async getCheckOptions(me: User): Promise<IChecklistOption[]> {
    return this.generateChecklistOptions(me);
  }

  generateChecklistOptions(me: User): IChecklistOption[] {
    const segments = this.generateSegments(me);

    const options = [
      {
        label: 'onboarding.badges.verification',
        route: '/profile',
        checked: segments.has_verified_teacher_profile,
      },
      {
        label: 'onboarding.badges.services',
        route: '/profile/services',
        checked: segments.has_user_services,
      },
      {
        label: 'onboarding.badges.billing',
        route: '/me/finance/cards',
        checked: segments.can_acceptance_payments,
      },
      {
        label: 'onboarding.badges.schedule',
        route: '/schedule/working-hours',
        checked: segments.specified_working_days,
      },
    ];

    if (this.features.isAvailable(FeatureEnum.mobile_apps, me)) {
      options.push({
        label: 'onboarding.badges.mobile-app',
        route: '/profile/application',
        checked: segments.use_mobile_app,
      });
    }

    return options;
  }

  public isMobileNotUsed(me: User): boolean {
    if (this.features.isAvailable(FeatureEnum.mobile_apps, me)) {
      const segments = this.generateSegments(me);
      return !segments.use_mobile_app;
    }
    return false;
  }

  public generateListPanelOptions(me: User, defaultOptions: IListPanelOption[] = []): IListPanelOption[] {
    const segments = this.generateSegments(me);

    const options: IListPanelOption[] = [
      {
        label: 'onboarding.rank-panel.private-lessons',
        bullet: BE.check,
      },
      {
        label: 'onboarding.rank-panel.publishing',
        bullet: !segments.has_verified_teacher_profile ? BE.cross : BE.check,
      },
      {
        label: 'onboarding.rank-panel.find-students',
        bullet: !segments.has_verified_teacher_profile ? BE.cross : BE.check,
      },
      {
        label: 'onboarding.rank-panel.host-webinars',
        bullet: !segments.has_verified_teacher_profile ? BE.cross : BE.check,
      },
      {
        label: 'onboarding.rank-panel.payments',
        bullet: !segments.has_verified_teacher_profile || !segments.can_acceptance_payments ? BE.cross : BE.check,
      },
      {
        label: 'onboarding.rank-panel.grades',
        bullet: !segments.has_verified_teacher_profile ? BE.cross : BE.check,
      },
      {
        label: 'onboarding.rank-panel.homeworks',
        bullet: !segments.has_verified_teacher_profile ? BE.cross : BE.check,
      },
      {
        label: 'onboarding.rank-panel.support',
        bullet: !segments.has_verified_teacher_profile ? BE.cross : BE.check,
      },
      {
        label: 'onboarding.rank-panel.income',
        bullet:
          !segments.has_verified_teacher_profile ||
          !segments.has_user_services ||
          !segments.can_acceptance_payments ||
          !segments.specified_working_days
            ? BE.cross
            : BE.check,
      },
      {
        label: 'onboarding.rank-panel.tools',
        bullet: !segments.has_verified_teacher_profile ? BE.cross : BE.check,
      },
    ];

    if (this.features.isAvailable(FeatureEnum.mobile_apps, me)) {
      options.push({
        label: 'onboarding.rank-panel.respond',
        bullet: !segments.has_verified_teacher_profile || !segments.use_mobile_app ? BE.cross : BE.check,
      });
    }

    return [...options, ...defaultOptions].sort((a, b) => a.bullet - b.bullet);
  }
}
