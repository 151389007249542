<app-base-notification [base]="base">

  <div [innerHTML]="'notifications.teacher_rescheduled_meeting.text' | translate: {
          'teacher': ('<a class=\'user-fullname-link\' href=\'' + (params.author_url) + '\'>' + (params.author_fullname) + '</a>'),
          'title': ('<a class=\'link-base\' href=\'' + (params.meeting_join_url) + '\'>' + (params.meeting_title) + '</a>'),
          'old': ('<a class=\'link-base\' href=\'' + (params.meeting_join_url) + '\'>' + (params.meeting_begin_at | date : 'MM/dd/yyyy HH:mm') + '</a>'),
          'new': ('<a class=\'link-base\' href=\'' + (params.meeting_join_url) + '\'>' + (params.begin_at | date : 'MM/dd/yyyy HH:mm') + '</a>')
        }"></div>

  <on-ui-button class="primary outline"
                label="notifications.classroom-invite.action"
                [routerLink]="'/my-classes/events'">
  </on-ui-button>

</app-base-notification>