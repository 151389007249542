import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { OnUiPopoverModule } from 'onclass-ui';
import { TeacherBadgesWidgetComponent } from './teacher-badges-widget.component';

@NgModule({
  declarations: [TeacherBadgesWidgetComponent],
  imports: [CommonModule, OnUiPopoverModule, TranslateModule, RouterModule],
  exports: [TeacherBadgesWidgetComponent],
})
export class TeacherBadgesWidgetModule {}
