import { Component, Input } from '@angular/core';

@Component({
  selector: 'spinner3',
  template: `
    <svg
      class="spinner3"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="30px"
      height="30px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid">
      <g transform="rotate(0 50 50)">
        <rect x="48.5" y="12" rx="1.5" ry="5.6000000000000005" width="3" height="28">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.875s"
            repeatCount="indefinite"></animate>
        </rect>
      </g>
      <g transform="rotate(45 50 50)">
        <rect x="48.5" y="12" rx="1.5" ry="5.6000000000000005" width="3" height="28">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.75s"
            repeatCount="indefinite"></animate>
        </rect>
      </g>
      <g transform="rotate(90 50 50)">
        <rect x="48.5" y="12" rx="1.5" ry="5.6000000000000005" width="3" height="28">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.625s"
            repeatCount="indefinite"></animate>
        </rect>
      </g>
      <g transform="rotate(135 50 50)">
        <rect x="48.5" y="12" rx="1.5" ry="5.6000000000000005" width="3" height="28">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.5s"
            repeatCount="indefinite"></animate>
        </rect>
      </g>
      <g transform="rotate(180 50 50)">
        <rect x="48.5" y="12" rx="1.5" ry="5.6000000000000005" width="3" height="28">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.375s"
            repeatCount="indefinite"></animate>
        </rect>
      </g>
      <g transform="rotate(225 50 50)">
        <rect x="48.5" y="12" rx="1.5" ry="5.6000000000000005" width="3" height="28">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.25s"
            repeatCount="indefinite"></animate>
        </rect>
      </g>
      <g transform="rotate(270 50 50)">
        <rect x="48.5" y="12" rx="1.5" ry="5.6000000000000005" width="3" height="28">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="-0.125s"
            repeatCount="indefinite"></animate>
        </rect>
      </g>
      <g transform="rotate(315 50 50)">
        <rect x="48.5" y="12" rx="1.5" ry="5.6000000000000005" width="3" height="28">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="1s"
            begin="0s"
            repeatCount="indefinite"></animate>
        </rect>
      </g>
    </svg>
  `,
  styleUrls: ['./spinner3.scss'],
})
export class Spinner3 {
  @Input() public active = false;
}
