import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ImageUploaderModule } from '@app/shared';
import { MediaFileSelectorModule } from '@core/components/media-file-selector/media-file-selector.module';
import { MobileModalModule } from '@core/components/mobile-modal/mobile-modal.module';
import { ModalModule } from '@core/components/modal/modal.module';
import { TranslateModule } from '@ngx-translate/core';
import { SkeletonComponent } from '@shared/skeleton/skeleton.component';
import { TimezoneComponent } from '@shared/timezone/timezone.component';
import {
  FinancePlanActivateFormModule,
  FinancePlanActivateFormV2Module,
  FinancePlanBuyCreditsFormModule,
  FinancePlanBuyCreditsFormV2Module,
  MeetingFormModule,
} from 'lingo2-forms';
import { MeetingFormWrapperComponent } from './meeting-form-wrapper.component';

@NgModule({
  declarations: [MeetingFormWrapperComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ModalModule,
    MobileModalModule,
    MediaFileSelectorModule,
    MeetingFormModule,
    FinancePlanActivateFormModule,
    FinancePlanBuyCreditsFormModule,
    ImageUploaderModule,
    ReactiveFormsModule,
    TimezoneComponent,
    FinancePlanBuyCreditsFormV2Module,
    FinancePlanActivateFormV2Module,
    SkeletonComponent,
  ],
  exports: [MeetingFormWrapperComponent],
})
export class MeetingFormWrapperModule {}
