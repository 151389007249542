import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { ERedPointsEvents } from '@store/models';
import { getRedPoints } from '@store/reducers/profile.reducer';
import { filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-red-point',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './red-point.component.html',
  styleUrls: ['./red-point.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RedPointComponent extends ChangableComponent implements AfterViewInit {
  @Input() set event(event: ERedPointsEvents) {
    this._event = event;
  }
  public visible: boolean;
  private _event: ERedPointsEvents;

  constructor(private store: Store, protected cdr: ChangeDetectorRef, protected platform: PlatformService) {
    super(cdr, platform);
  }

  ngAfterViewInit() {
    this.store
      .select(getRedPoints)
      .pipe(
        filter((redPoints) => redPoints[this._event] !== undefined),
        takeUntil(this.destroyed$),
      )
      .subscribe((res) => {
        this.visible = res[this._event];
        this.detectChanges();
      });
  }
}
