import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { IFileType, PlatformService } from '@core/services';
import { DestroyableComponent } from '@models/destroyable.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DragScrollComponent } from '@le2xx/ngx-drag-scroll';

@Component({
  selector: 'app-gallery-wrap',
  templateUrl: './gallery-wrap.component.html',
  styleUrls: ['./gallery-wrap.component.scss'],
})
export class GalleryWrapComponent extends DestroyableComponent {
  @Input() disableStars: boolean;
  @Input() containerWidth: number;
  @Input() images: IFileType[] = [];
  @Output() onchoose = new EventEmitter<IFileType>();
  @ViewChild('gallery', { read: DragScrollComponent }) ds: DragScrollComponent;

  public isDragScrollEnabled = !(this.deviceService.isMobile() || this.deviceService.isTablet());
  public reachesLeftFlag: boolean;
  public reachesRightFlag: boolean;

  public galleryScrollWidth = 0;
  public galleryScrollPages: any[] = [0];

  constructor(public deviceService: DeviceDetectorService, protected readonly platform: PlatformService) {
    super(platform);
  }

  public onChooseImage(file: IFileType) {
    this.onchoose.emit(file);
  }

  public onGalleryConfigured(config: any) {
    this.setTimeout(() => {
      const { galleryScrollWidth, galleryScrollPages } = config;
      this.galleryScrollWidth = galleryScrollWidth;
      this.galleryScrollPages = [...Array(galleryScrollPages)];
    }, 0);
  }

  public moveLeft() {
    this.ds.moveLeft();
  }

  public moveRight() {
    this.ds.moveRight();
  }
}
