import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-file-loader',
  templateUrl: './file-loader.component.html',
  styleUrls: ['./file-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileLoaderComponent {
  @Input() percent = 0;
  private min = 433.32;

  public get progress() {
    return this.min - (this.min / 100) * this.percent;
  }

  public get percentCeil(): number {
    return Math.floor(this.percent);
  }
}
