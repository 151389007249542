import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';
import { logger } from '@core/helpers/logger';
import { environment } from '@env/environment';
import * as Sentry from '@sentry/browser';
import { AnyType, User, UserSegmentEnum } from 'lingo2-models';
import { isString } from 'lodash-es';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { ContextService } from '../context.service';
import { AccountService } from '../lingo2-account/account.service';
import {
  RemoteConfigType,
  promo_a_config,
  promo_b_config,
  promo_c_config,
  promo_d_config,
  remoteConfigDefaults,
} from './constants';

@Injectable({
  providedIn: 'root',
})
export class RemoteConfigService implements OnDestroy {
  public updated$ = new Subject<void>();
  public config$: Observable<RemoteConfigType>;
  private _config: BehaviorSubject<RemoteConfigType>;

  private config: RemoteConfigType;
  private firebaseEnabled = false;
  private inited = false;
  private destroyed$ = new Subject<boolean>();
  private _me: User;

  public constructor(private fireRemoteConfig: AngularFireRemoteConfig, private contextService: ContextService) {
    this.config = remoteConfigDefaults;
    this._config = new BehaviorSubject(this.config);
    this.config$ = this._config.asObservable();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public init() {
    if (this.inited) {
      return;
    }
    this.inited = true;

    this.contextService.me$
      .pipe(
        tap((me) => {
          this._me = me;
          this.reconfigureForMe();
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe();

    if (environment.firebaseOptions && this.fireRemoteConfig) {
      this.firebaseEnabled = true;
      this.prepareRemoteConfig()
        .then(() => this.readRemoteConfig())
        .then(() => {
          this.fireRemoteConfig.changes
            .pipe(
              debounceTime(100),
              tap(() => this.reload()),
              takeUntil(this.destroyed$),
            )
            .subscribe();
        })
        .catch(() => null);
    }
  }

  public reload() {
    void this.readRemoteConfig();
  }

  protected async prepareRemoteConfig() {
    if (this.firebaseEnabled) {
      // await this.fireRemoteConfig.defaultConfig(remoteConfigDefaults);
      // await this.fireRemoteConfig.configSettings({
      //   isDeveloperModeEnabled: __DEV__
      // });
      try {
        await this.fireRemoteConfig.fetch();
        await this.fireRemoteConfig.activate();
      } catch (err) {
        Sentry.captureException(err);
      }
    }
  }

  protected async readRemoteConfig() {
    if (this.firebaseEnabled) {
      try {
        const values = await this.fireRemoteConfig.getAll();
        const config = {} as RemoteConfigType;
        Object.entries(values).forEach(([key, value]) => {
          const _value = this.readValue(key, (value as AnyType)._value);
          if (_value !== null) {
            config[key] = _value;
          }
        });
        this.config = { ...this.config, ...config };
        this._config.next(this.config);
        this.updated$.next();
      } catch (err) {
        Sentry.captureException(err);
      }
    }

    this.reconfigureForMe();
  }

  protected readValue(valuePath: string, value: number | boolean | string): number | boolean | string | object {
    if ('[object Object]' === value) {
      // Я представления не имею как получилось, что некоторые значения сохранилась в таком виде
      // в Firebase Remote Config и не хотят удаляться. Остаётся только пропустить.
      return null;
    }
    if (isString(value)) {
      const _value = value.trim();
      if (_value.toLowerCase() === 'true') {
        return true;
      }
      if (_value.toLowerCase() === 'false') {
        return false;
      }

      const _num = Number(_value);
      if (!isNaN(_num)) {
        return _num;
      }

      if (_value.length > 0 && ('[' === _value[0] || '{' === _value[0])) {
        try {
          const _obj = JSON.parse(_value);
          if (typeof _obj !== 'undefined') {
            return _obj;
          }
        } catch (e) {
          logger.error(`RemoteConfigService: unable to parse value for remote config '${valuePath}'`, value);
          return null;
        }
      }
    }
    return value;
  }

  protected reconfigureForMe() {
    let changed = false;
    if (AccountService.hasSegment(this._me, UserSegmentEnum.promo_a_config)) {
      this.config = { ...this.config, ...promo_a_config };
      changed = true;
    }
    if (AccountService.hasSegment(this._me, UserSegmentEnum.promo_b_config)) {
      this.config = { ...this.config, ...promo_b_config };
      changed = true;
    }
    if (AccountService.hasSegment(this._me, UserSegmentEnum.promo_c_config)) {
      this.config = { ...this.config, ...promo_c_config };
      changed = true;
    }
    if (AccountService.hasSegment(this._me, UserSegmentEnum.promo_d_config)) {
      this.config = { ...this.config, ...promo_d_config };
      changed = true;
    }

    if (changed) {
      this._config.next(this.config);
      this.updated$.next();
    }
  }
}
