import { createAction, props } from '@ngrx/store';
import { Classroom } from 'lingo2-models';

export const requestFail = createAction('[Classroom] Classroom request: fail', props<{ error: string }>());

export const loadClassroomBySlug = createAction('[Classroom] Load classroom by slug', props<{ slug: string }>());
export const loadClassroomBySlugSuccess = createAction(
  '[Classroom] Load classroom by slug: success',
  props<{ classroom: Classroom }>(),
);

export const setClassroom = createAction('[Classroom] Set classroom', props<{ classroom: Classroom }>());
