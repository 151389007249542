import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IControlOption } from '@app/shared';
import { logger } from '@core/helpers/logger';
import { IconsService } from '@core/services';
import { DestroyableComponent } from '@models/destroyable.component';

@Component({
  selector: 'app-microphone-setting',
  templateUrl: './microphone-setting.component.html',
  styleUrls: ['./microphone-setting.component.scss'],
})
export class MicrophoneSettingComponent extends DestroyableComponent implements OnInit {
  @ViewChild('video') videoElement: ElementRef<HTMLVideoElement>;
  @ViewChild('micLevel') micLevel: ElementRef<HTMLElement>;
  audioInputs: IControlOption[] = [];
  audioOutputs: IControlOption[] = [];
  videosInputs: IControlOption[] = [];
  svgSetIcon = IconsService.svgsetIconUrl;
  isCanPlay = false;
  settingModel = {
    audioInput: 'default',
    audioOutput: 'default',
    videosInput: '',
  };

  private stream: any;

  constructor() {
    super();
  }

  onCanPlay(): void {
    this.isCanPlay = true;
  }

  ngOnInit(): void {
    this.changeVideoInput();
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      logger.warn('enumerateDevices() not supported.');
      return;
    }
    navigator.mediaDevices
      .enumerateDevices()
      .then((deviceInfos) => this.gotDevices(deviceInfos))
      .catch(handleError);
  }

  gotDevices(deviceInfos) {
    this.audioInputs = [];
    this.audioOutputs = [];
    this.videosInputs = [];
    for (let i = 0; i !== deviceInfos.length; ++i) {
      const deviceInfo = deviceInfos[i];
      const option: IControlOption = { value: '', title: '' };
      option.value = deviceInfo.deviceId;
      if (deviceInfo.kind === 'audioinput') {
        option.title = deviceInfo.label || `microphone ${this.audioInputs.length + 1}`;
        this.audioInputs.push(option);
      } else if (deviceInfo.kind === 'audiooutput') {
        option.title = deviceInfo.label || `speaker ${this.audioOutputs.length + 1}`;
        this.audioOutputs.push(option);
      } else if (deviceInfo.kind === 'videoinput') {
        option.title = deviceInfo.label || `camera ${this.videosInputs.length + 1}`;
        this.videosInputs.push(option);
        this.settingModel.videosInput = this.videosInputs[0].value;
      }
    }
  }

  changeAudioDestination(value: string) {
    attachSinkId(this.videoElement.nativeElement, value);
  }

  changeVideoInput() {
    if (this.stream) {
      this.stream.getTracks().forEach((track) => track.stop());
    }
    const audioInput = this.settingModel.audioInput;
    const videoSource = this.settingModel.videosInput;
    const constraints = {
      video: { deviceId: videoSource ? { exact: videoSource } : undefined },
      audio: { deviceId: audioInput ? { exact: audioInput } : undefined },
    };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((res) => this.gotStream(res))
      .then((deviceInfos) => this.gotDevices(deviceInfos))
      .catch(handleError);
  }

  private audioAnalyser(): void {
    const audioContext = new AudioContext();
    const analyser = audioContext.createAnalyser();
    const microphone = audioContext.createMediaStreamSource(this.stream);
    const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);

    analyser.smoothingTimeConstant = 0.8;
    analyser.fftSize = 1024;

    microphone.connect(analyser);
    analyser.connect(scriptProcessor);
    scriptProcessor.connect(audioContext.destination);
    scriptProcessor.onaudioprocess = () => {
      const array = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(array);
      const arraySum = array.reduce((a, value) => a + value, 0);
      const average = arraySum / array.length;
      this.colorPids(average);
    };
  }

  private gotStream(stream) {
    this.stream = stream; // make stream available to console
    this.videoElement.nativeElement.srcObject = stream;
    this.audioAnalyser();
    // Refresh button list in case labels have become available
    return navigator.mediaDevices.enumerateDevices();
  }

  private colorPids(vol: number) {
    const children = Array.from(this.micLevel.nativeElement.children);
    const numberOfPidsToColor = Math.round((vol * 20) / 100);
    const pidsToColor = children.slice(0, numberOfPidsToColor);
    for (const pid of children) {
      pid.classList.remove('active');
    }
    for (const pid of pidsToColor) {
      pid.classList.add('active');
    }
  }
}

// Attach audio output device to video element using device/sink ID.
function attachSinkId(element, sinkId) {
  if (typeof element.sinkId !== 'undefined') {
    element
      .setSinkId(sinkId)
      .then()
      .catch((error) => {
        let errorMessage = error;
        if (error.name === 'SecurityError') {
          errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
        }
        logger.error(errorMessage);
        // Jump back to first output device in the list as it's the default.
      });
  } else {
    logger.warn('Browser does not support output device selection.');
  }
}

function handleError(e): void {
  // Move to Sentry
}
