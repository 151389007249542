import { createAction, props } from '@ngrx/store';
import { IPagedResults, Meeting } from 'lingo2-models';

export const requestFail = createAction('[Classroom Meetings] Request: fail', props<{ error: string }>());

export const loadClassroomMeetings = createAction('[Classroom Meetings] Load meetings');
export const loadClassroomMeetingsSuccess = createAction(
  '[Classroom Meetings] Load meetings: success',
  props<{ response: IPagedResults<Meeting[]> }>(),
);

export const clearClassroomMeetings = createAction('[Classroom Meetings] Clear');

export const loadNextPageClassroomMeetings = createAction('[Classroom Meetings] Load next page');

export const pushClassroomMeeting = createAction('[Classroom Meetings] Add meeting', props<{ meeting: Meeting }>());
