import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AccountService, ContextService, FeaturesService, IconsService } from '@core/services';
import { environment } from '@env/environment';
import { ChangableComponent } from '@models/changable.component';
import { FeatureEnum, User, UserSegmentEnum } from 'lingo2-models';
import { takeUntil, tap } from 'rxjs/operators';

export type Mode = 'dark' | 'light';

export enum Badges {
  verification = 'verification',
  services = 'services',
  calendar = 'calendar',
  bank_card = 'bank_card',
  mobile_app = 'mobile_app',
}

@Component({
  selector: 'app-teacher-badges-widget',
  templateUrl: './teacher-badges-widget.component.html',
  styleUrls: ['./teacher-badges-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeacherBadgesWidgetComponent extends ChangableComponent implements OnInit {
  @Input() mode: Mode = 'light';
  public svgsetIcon = IconsService.svgsetIconUrl;
  public badges: string[] = Object.values(Badges);
  public verificationList: string[] = [
    'teacher-badges-widget.verification.item1',
    'teacher-badges-widget.verification.item2',
    'teacher-badges-widget.verification.item3',
    'teacher-badges-widget.verification.item4',
    'teacher-badges-widget.verification.item5',
  ];
  public servicesList: string[] = [
    'teacher-badges-widget.services.item1',
    'teacher-badges-widget.services.item2',
    'teacher-badges-widget.services.item3',
  ];
  public calendarList: string[] = ['teacher-badges-widget.calendar.item1', 'teacher-badges-widget.calendar.item2'];
  public bankCardList: string[] = ['teacher-badges-widget.bank-card.item1', 'teacher-badges-widget.bank-card.item2'];
  public mobileAppList: string[] = ['teacher-badges-widget.mobile-app.item1', 'teacher-badges-widget.mobile-app.item2'];

  public mobileAppUrl = environment.mobile_app_url;

  // private activeBadges: string[] = [Badges.bank_card, Badges.verification];
  private activeBadges = {
    [Badges.bank_card]: false,
    [Badges.calendar]: false,
    [Badges.mobile_app]: false,
    [Badges.services]: false,
    [Badges.verification]: false,
  };

  private hasSegment = AccountService.hasSegment;
  protected _me: User;

  public constructor(
    private contextService: ContextService,
    private features: FeaturesService,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  public ngOnInit() {
    this.contextService.me$
      .pipe(
        tap((me) => {
          this._me = me;
          this.initSegments(me);
          this.detectChanges();
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  public isActive(badge: string): boolean {
    return this.activeBadges[badge];
  }

  public isAvailableMobileApps(): boolean {
    return this.features.isAvailable(FeatureEnum.mobile_apps, this._me);
  }

  public isAvailableUserServices(): boolean {
    return this.features.isAvailable(FeatureEnum.user_services, this._me);
  }

  protected initSegments(me) {
    if (!this.isAvailableUserServices) {
      return;
    }
    this.activeBadges = {
      [Badges.verification]: this.hasSegment(me, UserSegmentEnum.has_verified_teacher_profile),
      [Badges.services]: this.hasSegment(me, UserSegmentEnum.has_user_services),
      [Badges.calendar]:
        this.hasSegment(me, UserSegmentEnum.has_free_hours_30days) ||
        this.hasSegment(me, UserSegmentEnum.has_busy_hours_30days),
      [Badges.bank_card]:
        this.hasSegment(me, UserSegmentEnum.has_approved_bank_card) ||
        this.hasSegment(me, UserSegmentEnum.can_acceptance_payments),
      [Badges.mobile_app]: this.hasSegment(me, UserSegmentEnum.use_mobile_app),
    };
    this.detectChanges();
  }
}
