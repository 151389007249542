import { Injectable } from '@angular/core';
import { DestroyableComponent } from '@models/destroyable.component';
import { BehaviorSubject } from 'rxjs';
import { IProfileForm } from '../profile-form-page.component';

@Injectable()
export class ProfileFormService extends DestroyableComponent {
  private _profileForm: IProfileForm = {
    about: null,
    contacts: null,
    education: null,
    experience: null,
    teaching: null,
  };
  private profileForm = this.register(new BehaviorSubject<IProfileForm>(this._profileForm));
  public profileForm$ = this.profileForm.asObservable();

  private verificationRequested = this.register(new BehaviorSubject<boolean>(false));
  public verificationRequested$ = this.verificationRequested.asObservable();

  public setProfileForm(value: Partial<IProfileForm>): void {
    this._profileForm = { ...this._profileForm, ...value };
    this.profileForm.next(this._profileForm);
  }

  public verify(requested: boolean): void {
    this.verificationRequested.next(requested);
  }

  // Функция удаления HTML тэгов
  public stripHtml(html): string {
    const tmp = document.createElement('div');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  public static validateFormGroup(formGroup) {
    (Object as any).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      control.updateValueAndValidity();
      if (control.controls) {
        this.validateFormGroup(control);
      }
    });
  }
}
