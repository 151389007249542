<app-loading-bar></app-loading-bar>
<router-outlet></router-outlet>
<app-auth-modal></app-auth-modal>
<app-seo-helper></app-seo-helper>
<app-cookie-notifier></app-cookie-notifier>
<on-ui-sprite></on-ui-sprite>
<app-debug-drawer></app-debug-drawer>
<app-nearest-meet-widget *ngIf="!isNonWidgetPage"></app-nearest-meet-widget>
<app-last-version></app-last-version>

<app-application-dialogs>
<!-- самоуправляемая компонента, порождаает разного рода диалоги -->
</app-application-dialogs>

