import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { IconsService } from '@core/services';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnInit {
  @Input() icon: string;
  @Input() width;
  @Input() height;
  public svgsetIcon = IconsService.svgsetIconUrl;

  constructor() {}

  ngOnInit() {}
}
