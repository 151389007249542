import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable, OnDestroy, TransferState } from '@angular/core';

import { Router } from '@angular/router';
import { NotFoundError } from '@app/lingo2-errors';
import { NotificationsService } from '@app/notifications/notifications.service';
import { ContextService } from '@core/services/context.service';
import { PlatformService } from '@core/services/platform.service';
import { environment } from '@env/environment';
import { DestroyableComponent } from '@models/destroyable.component';
import { Store } from '@ngrx/store';
import jwtDecode from 'jwt-decode';
import { resetMe, setMe } from 'lingo2-chat-app';
import {
  AnyType,
  IAuthErrorResponse,
  IAuthTokensResponse,
  IChangePasswordAvailabilityRequest,
  IConfirmResetPasswordRequest,
  IResetPasswordRequest,
  ISigninRequest,
  ISignupRequest,
  ISocialSignupRequest,
  User,
  UserStatusEnum,
} from 'lingo2-models';
import { isObject, isString } from 'lodash';
import { CookieService } from 'ngx-cookie';
import { BehaviorSubject, Observable, of, ReplaySubject, Subscription, throwError, timer } from 'rxjs';
import { catchError, filter, first, map, takeUntil, tap } from 'rxjs/operators';
// import { loadMe, loadMeSuccess } from 'src/app/store/actions/profile.actions';

export type AuthModalModeType =
  | 'social'
  | 'signin'
  | 'signup'
  | 'restore'
  | 'restored'
  | 'smsFlow'
  | 'emailRestoredFlow'
  | 'signout';

export type AuthUserStateType = 'guest' | 'signedIn' | 'signedUp' | 'signedOut';

export interface IAccountChangeResult {
  id: string;
  status: 'changed' | 'code_required' | 'error' | 'code_failed';
  model?: 'password' | 'new_password';
  error?: string;
}

const oneMinute = 60 * 1000;
const oneHour = 60 * oneMinute;

const access_token_key = environment.access_token_key || 'APP_AUTH_ACCESS';

export interface AuthModalOptions {
  /** Откуда вызвали */
  caller: string;

  /** Причина вызова */
  reason: string;

  /** Режим отображения */
  mode?: AuthModalModeType;
}

type AuthAuthSuccessFnType = (user?: User) => void;

@Injectable({
  providedIn: 'root',
})
export class AuthService extends DestroyableComponent implements OnDestroy {
  private modalVisible = false;
  private modalVisibleSubject = this.register(new BehaviorSubject<boolean>(this.modalVisible));
  public modalVisible$ = this.modalVisibleSubject.asObservable();

  private modalMode: AuthModalModeType = 'social';
  private modalModeSubject = this.register(new BehaviorSubject<AuthModalModeType>(this.modalMode));
  public modalMode$ = this.modalModeSubject.asObservable();

  private modalOptionsSubject = this.register(new BehaviorSubject<AuthModalOptions>(null));
  public modalOptions$ = this.modalOptionsSubject.asObservable();

  /* пользователь */
  private userSubject = this.register(new ReplaySubject<User>(1));
  private _user: User;

  /** статус авторизации пользователя */
  public stateSubject = this.register(new ReplaySubject<AuthUserStateType>(1));

  protected access_token_key = access_token_key;
  protected refresh_token_key = `${access_token_key}_REFRESH`;
  protected recreate_interval_key = `${access_token_key}_RECREATED_AT`;
  protected recreate_interval = 12 * oneHour; // интервал повторной генерации токенов = 12 hours
  protected online_status_interval = 5 * oneMinute; // интервал оповещения о статусе 'онлайн'
  protected back_url_key = 'APP_AUTH_BACK_URL';
  protected _backUrl: string;
  protected _authProcessing = false;
  protected _lastAccessToken = '';
  private inited = false;
  private onAuthSuccessFn: AuthAuthSuccessFnType;
  private sendingOnlineStatus = false;

  constructor(
    private cookieService: CookieService,
    private transferState: TransferState,

    /** @deprecated */
    private contextService: ContextService,
    private router: Router,
    private http: HttpClient,
    private readonly store: Store, // избавиться
    private notificationsService: NotificationsService, // избавиться
    protected readonly platform: PlatformService,
  ) {
    super(platform);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.onAuthSuccessFn = null;
  }

  public get user$(): Observable<User> {
    this.init(); // ленивая инициализация
    return this.userSubject.asObservable();
  }

  public get state$(): Observable<AuthUserStateType> {
    this.init(); // ленивая инициализация
    return this.stateSubject.asObservable();
  }

  public get isAuthenticated(): boolean {
    return this._user && this._user.status !== UserStatusEnum.guest;
  }

  public get accessToken(): string {
    let accessToken = '';
    if (this.isBrowser) {
      accessToken = this.cookieService.get(this.access_token_key) || '';
      // if (!accessToken) {
      //   accessToken = this.transferState.get(makeStateKey(this.access_token_key), '');
      // }
    } else {
      accessToken =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWJqZWN0Ijp7InNwb2tlbl9sYW5ndWFnZXMiOltdLCJzdWJqZWN0X3ByZWZlcmVuY2VzIjpbXSwicm9sZXMiOltdLCJyYXRpbmciOjAsImlkIjoiNDUxYTVhYjAtZGRhZS00M2IzLWJjNWYtZjc4M2QwYjQzYjA5Iiwic2x1ZyI6Im5vdHVzZXIiLCJmaXJzdF9uYW1lIjoiTm90VXNlciIsImxhc3RfbmFtZSI6IiJ9LCJpYXQiOjE2MzQ2MzI5OTcsImV4cCI6MTkyNjIwMTYwMCwiYXVkIjoiaW9zIiwiaXNzIjoibGluZ28yLWFjY291bnQ6MS4wLjAifQ.oyZNNGY-NLBX2cQ3L6AzUeRsOz3Yfne6-enEoBpgpSw';
    }
    return accessToken.length ? accessToken : null;
  }

  /** Принудительная проверка авторизации и обновление токенов */
  public assertAuth(onAuth: () => void, onError?: (msg: string) => void) {
    const _refresh = this.refreshToken;
    if (!this.isBrowser || !_refresh) {
      if (onError) {
        onError('No refresh token');
      }
      return;
    }

    const url = `${environment.auth_url}/recreate-token`;
    this.http
      .post<IAuthTokensResponse>(
        url,
        { refresh: _refresh },
        {
          observe: 'response',
          withCredentials: true,
          params: { domain: this.cookieDomain, key: this.access_token_key },
        },
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (response: HttpResponse<IAuthTokensResponse & IAuthErrorResponse>) => {
          const { access, refresh, error } = response.body;
          if (error) {
            // logger.log('AuthService:assertAuth failed', error);
            this.logOut(true).then(() => {
              if (onError) {
                onError(error);
              }
            });
          } else {
            this.setTokens(access, refresh);
            onAuth();
          }
        },
        (e: any) => {
          console.error('AuthService:assertAuth failed', e);
          this.logOut(true).then(() => {
            if (onError) {
              onError(e);
            }
          });
        },
      );
  }

  /** форсированное обновление токена */
  public forceRecreateToken() {
    if (!this.isBrowser || !localStorage) {
      return;
    }
    this.recreateToken(true);
  }

  public showAuthModal(onAuthSuccessFn?: AuthAuthSuccessFnType, options?: AuthModalOptions) {
    if (onAuthSuccessFn) {
      this.backUrl = null;
      this.onAuthSuccessFn = onAuthSuccessFn;
    } else {
      this.onAuthSuccessFn = null;
    }
    if (this.isAuthenticated) {
      this.onAuthSuccess(this._user);
      return;
    }

    /** @see AuthModalComponent */
    this.modalOptionsSubject.next(options);
    if (options?.mode) {
      this.modalModeSubject.next(options.mode);
    }
    this.modalVisible = true;
    this.modalVisibleSubject.next(this.modalVisible);
  }

  public goAuth(backUrl?: string) {
    this.onAuthSuccessFn = null;
    if (backUrl) {
      this.backUrl = backUrl;
      // logger.log('set backUrl', backUrl);
    }
  }

  public get isShowAuthModal(): boolean {
    return this.modalVisible;
  }

  public setAuthModalMode(mode: AuthModalModeType) {
    this.modalMode = mode;
    this.modalModeSubject.next(this.modalMode);
  }

  public hideAuthModal() {
    this.modalVisible = false;
    this.modalVisibleSubject.next(this.modalVisible);

    this.setAuthModalMode('social');
  }

  public get backUrl(): string {
    if (this._backUrl !== null) {
      return this._backUrl;
    }
    if (this.isBrowser && (localStorage || false)) {
      this._backUrl = localStorage.getItem(this.back_url_key);
    }
    return this._backUrl;
  }

  public set backUrl(url: string) {
    this._backUrl = url;
    if (this.isBrowser && (localStorage || false)) {
      if (!url) {
        localStorage.removeItem(this.back_url_key);
      } else {
        localStorage.setItem(this.back_url_key, url);
      }
    }
  }

  public signIn(email: string, password: string, remember = false): Promise<boolean> {
    const self = this;
    const url = `${environment.auth_url}/user-token`;

    const values: ISigninRequest = {
      email,
      password,
    };

    return new Promise((resolve, reject) => {
      this.http
        .post(url, values, {
          observe: 'response',
          withCredentials: true,
          params: { domain: this.cookieDomain, key: this.access_token_key },
        })
        .pipe(
          map((response: HttpResponse<IAuthTokensResponse & IAuthErrorResponse>) => {
            const { error, model, details, access, refresh } = response.body;
            if (error) {
              reject(response.body);
            } else {
              resolve(true);
              return { access, refresh };
            }
          }),
          catchError(this.handleLoginError),
        )
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (tokens: IAuthTokensResponse) => {
            if (!tokens) {
              return;
            }
            self.setTokens(tokens.access, tokens.refresh);

            if (typeof window !== 'undefined') {
              if (window.location.hostname?.includes('localhost')) {
                this.cookieService.put(this.access_token_key, tokens.access);
              }
            }

            this._user = this.parseUserFromAccessToken(tokens.access);
            this.userSubject.next(this._user);
            this.stateSubject.next('signedIn');

            // TODO вынести
            this.notificationsService.pushWelcomeGreeting();
          },
          (err: any) => {},
        );
    });
  }

  public logOut(force?: boolean): Promise<boolean> {
    if (!(this._lastAccessToken || force)) {
      return;
    }
    this.onAuthSuccessFn = null;
    const url = `${environment.auth_url}/release-token`;
    return new Promise((resolve, reject) => {
      this.http
        .delete(url, {
          observe: 'response',
          withCredentials: true,
          params: { domain: this.cookieDomain, key: this.access_token_key },
        })
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (response) => {
            this._user = null;
            this.userSubject.next(this._user);
            this.stateSubject.next('signedOut');
            this.resetContext();
            this.getGuestToken();
            // this.goAuth();
            resolve(true);
          },
          (error: any) => {
            reject(error);
          },
        );
    });
  }

  /** регистрация нового пользователя */
  public signup(values: ISignupRequest): Promise<boolean> {
    const self = this;
    const url = `${environment.auth_url}/user`;

    return new Promise((resolve, reject) => {
      this.http
        .put(url, values, {
          observe: 'response',
          withCredentials: true,
          params: { domain: this.cookieDomain, key: this.access_token_key },
        })
        .pipe(
          map((response: HttpResponse<IAuthTokensResponse & IAuthErrorResponse>) => {
            const { error, model, details, access, refresh } = response.body;
            if (error) {
              reject(response.body);
            } else {
              resolve(true);
              return { access, refresh };
            }
          }),
          catchError(this.handleLoginError),
        )
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (tokens: IAuthTokensResponse) => {
            self.setTokens(tokens?.access, tokens?.refresh);

            this._user = this.parseUserFromAccessToken(tokens?.access);
            this.userSubject.next(this._user);
            this.stateSubject.next('signedUp');

            this.onAuthSuccess(this._user);
          },
          (err: any) => {},
        );
    });
  }

  /** регистрация нового пользователя, авторизованного через соц сеть */
  public socialSignup(values: ISocialSignupRequest): Promise<boolean> {
    const self = this;
    const url = `${environment.auth_url}/social-user`;

    return new Promise((resolve, reject) => {
      this.http
        .put(url, values, {
          observe: 'response',
          withCredentials: true,
          params: { domain: this.cookieDomain, key: this.access_token_key },
        })
        .pipe(
          map((response: HttpResponse<IAuthTokensResponse & IAuthErrorResponse>) => {
            const { error, model, details, access, refresh } = response.body;
            if (error) {
              reject(response.body);
            } else {
              resolve(true);
              return { access, refresh };
            }
          }),
          catchError(this.handleLoginError),
        )
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (tokens: IAuthTokensResponse) => {
            self.setTokens(tokens.access, tokens.refresh);

            this._user = this.parseUserFromAccessToken(tokens.access);
            this.userSubject.next(this._user);
            this.stateSubject.next('signedIn');

            this.onAuthSuccess(this._user);
          },
          (err: any) => {},
        );
    });
  }

  public emergencySignin(id: string, code: string) {
    const self = this;
    const url = `${environment.auth_url}/emergency-token`;

    return this.http
      .post(
        url,
        { id, code },
        {
          observe: 'body',
          withCredentials: true,
          params: { domain: this.cookieDomain, key: this.access_token_key },
        },
      )
      .pipe(
        tap((response: IAuthTokensResponse & IAuthErrorResponse) => {
          const { error, model, details, access, refresh } = response;
          if (error) {
            throw new Error(error);
          } else {
            self.setTokens(access, refresh);
            this._user = this.parseUserFromAccessToken(access);
            this.userSubject.next(this._user);
            this.stateSubject.next('signedIn');
          }
        }),
      );
  }

  private onAuthSuccess(user: User) {
    if (!!user && this.onAuthSuccessFn) {
      const fn = this.onAuthSuccessFn;
      this.onAuthSuccessFn = null;
      fn(user);
    }
  }

  private init() {
    if (this.inited) {
      return;
    }
    this.inited = true;

    this.state$.pipe(takeUntil(this.destroyed$)).subscribe((state) => {
      switch (state) {
        case 'signedIn':
          this.store.dispatch(setMe({ me: this._user }));
          break;
        case 'guest':
        case 'signedOut':
        case 'signedUp':
        default:
          this.store.dispatch(resetMe({}));
          break;
      }
    });

    // logger.log('AuthService.init');
    let user: User;
    if (this.accessToken) {
      user = this.parseUserFromAccessToken(this.accessToken);
      if (user) {
        this._lastAccessToken = this.accessToken;
        this.refreshUser(user);
      }
    }

    this.onBrowserOnly(() => {
      if (!user) {
        this.recreateToken();
      }

      this.setInterval(() => {
        this.autoReLogin();
      }, 10 * 1000); // авто-выход в этой сесси, если был выход в соседней вкладке

      this.setInterval(() => {
        this.autoRecreateToken();
      }, this.recreate_interval); // проверять наличие и свежесть токенов, при необходмости запросить новые

      // отправить статус онлайн сейчас и через интервал
      timer(1500, this.online_status_interval)
        .pipe(
          filter(() => !!this.isAuthenticated),
          tap(() => this.sendOnlineStatus()),
          takeUntil(this.destroyed$),
        )
        .subscribe();
    });
  }

  protected setTokens(accessToken: string, refreshToken: string) {
    // logger.log('AuthService.setTokens');
    const user = this.parseUserFromAccessToken(accessToken);
    if (user) {
      this._lastAccessToken = accessToken;
      this.refreshToken = refreshToken;
      this.refreshUser(user, (account) => {
        this.onAuthSuccess(account);
      });
    }
  }

  protected resetContext() {
    if (this.isBrowser && localStorage) {
      localStorage.removeItem(this.recreate_interval_key);
    }
    this._lastAccessToken = '';
    this.refreshToken = '';
    // TODO вынести
    this.contextService.updateMe(null);
    this.contextService.updateProfile(null);
  }

  protected parseUserFromAccessToken(token: string): User {
    try {
      const token_decoded = jwtDecode<any>(token);
      const me = new User(token_decoded.subject);
      return me;
    } catch (e) {
      console.error('AuthService:parseUserFromAccessToken', e);
    }
  }

  private recreateToken$$: Subscription;
  protected recreateToken(force = false) {
    // logger.log('AuthService.recreateToken', { authProcessing: this._authProcessing, force });
    const _refresh = this.refreshToken;
    if (!this.isBrowser || !_refresh || !(localStorage || false)) {
      this.getGuestToken();
      return;
    }

    if (this._authProcessing && !force) {
      return;
    }

    if (this.recreateToken$$) {
      this.recreateToken$$.unsubscribe();
    }

    const url = `${environment.auth_url}/recreate-token`;
    this._authProcessing = true;
    this.recreateToken$$ = this.http
      .post<IAuthTokensResponse>(
        url,
        { refresh: _refresh },
        {
          observe: 'response',
          withCredentials: true,
          params: { domain: this.cookieDomain, key: this.access_token_key },
        },
      )
      .pipe(
        map((response: HttpResponse<IAuthTokensResponse & IAuthErrorResponse>) => {
          const { access, refresh, error } = response.body;
          if (error) {
            // сервер не принял refresh токен или иная причина
            // logger.log('AuthService:recreateToken failed', error);
            // Возможно, дисконект. Обновим в другой раз this.logOut();
          }
          return { access, refresh };
        }),
        catchError(this.handleError),
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (tokens: IAuthTokensResponse) => {
          this._authProcessing = false;
          if (tokens.access && tokens.refresh) {
            if (this.isBrowser && (localStorage || false)) {
              localStorage.setItem(this.recreate_interval_key, new Date().toString());
            }
            this.setTokens(tokens.access, tokens.refresh);
          } else {
            this.getGuestToken();
          }
        },
        (e: any) => {
          this._authProcessing = false;
          console.error('AuthService:recreateToken failed', e);
          // Возможно, дисконект. Обновим в другой раз this.logOut();
        },
      );
  }

  protected autoReLogin() {
    const access = this.accessToken;
    if (this._lastAccessToken !== access) {
      // если токен изменился (токен устарел или пользователь залогинился/разлогинился в другой вкладке браузера)
      if (!access) {
        // logger.log('AuthService:autoReLogin', 'accessToken is absent -> logout');
        this.logOut();
      } else {
        // logger.log('AuthService:autoReLogin', 'accessToken is changed, reload user');
        const user = this.parseUserFromAccessToken(access);
        if (user) {
          // logger.log('AuthService:autoReLogin', 'success');
          this._lastAccessToken = access;
          this.refreshUser(user);
        } else {
          // если токен сбойный
          // logger.log('AuthService:autoReLogin', 'fail -> logout');
          this.logOut();
        }
      }
    }
  }

  protected autoRecreateToken() {
    // logger.log('AuthService.autoRecreateToken');
    try {
      const refresh = this.refreshToken;
      if (!this.isBrowser || !refresh || !localStorage) {
        return;
      }

      const lastRecreatedAt = localStorage.getItem(this.recreate_interval_key);
      if (
        !this.accessToken ||
        !lastRecreatedAt ||
        Date.now() - new Date(lastRecreatedAt).getTime() > this.recreate_interval
      ) {
        this.recreateToken();
      }
    } catch (e) {}
  }

  private getUserById$$: Subscription;
  public refreshUser(user: User, onSuccess?: (account: User) => void) {
    if (this.getUserById$$) {
      this.getUserById$$.unsubscribe();
    }

    this.getUserById$$ = this.getUserById(user.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (account) => {
          // TODO вынести
          this.contextService.updateMe(account);
          // this.store.dispatch(loadMeSuccess({ me: account }));
          this._user = account;
          this.userSubject.next(this._user);
          if (onSuccess) {
            onSuccess(account);
          }
        },
        (err: any) => {
          if (err instanceof NotFoundError) {
            // не найден пользователь с таким user.id
            // logger.log('refreshUser: user not found ' + user.id + ', logout');
            this.logOut(true);
          }
        },
      );
  }

  private getUserById(user_id: string): Observable<User> {
    const url = `${environment.account_url}/account/${user_id}`;
    const params = new HttpParams();
    // let params = new HttpParams();
    // if (details && details.length) {
    //   params = params
    //     .set('details', JSON.stringify(details));
    // }
    return this.http.get<User>(url, { params, observe: 'response' }).pipe(
      map((response) => new User(response.body)),
      catchError(() => of(null)),
    );
  }

  protected getGuestToken() {
    // logger.log('AuthService.getGuestToken');
    if (this._authProcessing) {
      return;
    }
    this._authProcessing = true;
    const url = `${environment.auth_url}/guest-token`;
    // logger.log('AuthService:getGuestToken', { url });
    this.http
      .post<IAuthTokensResponse>(
        url,
        {},
        {
          observe: 'response',
          withCredentials: true,
          params: { domain: this.cookieDomain, key: this.access_token_key },
        },
      )
      .pipe(
        map((response: HttpResponse<IAuthTokensResponse>) => {
          const { access, refresh } = response.body;
          return { access, refresh };
        }),
        catchError(this.handleError),
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (tokens: IAuthTokensResponse) => {
          this._authProcessing = false;
          this.setTokens(tokens.access, tokens.refresh);
        },
        (e: any) => {
          this._authProcessing = false;
          // logger.log('AuthService:getGuestToken failed', e);
        },
      );
  }

  protected set refreshToken(token: string) {
    if (this.isBrowser && (localStorage || false)) {
      if (token) {
        localStorage.setItem(this.refresh_token_key, token);
      } else {
        localStorage.removeItem(this.refresh_token_key);
      }
    }
  }

  protected get refreshToken(): string {
    if (this.isBrowser && (localStorage || false)) {
      return localStorage.getItem(this.refresh_token_key);
    }
    return '';
  }

  public resetPasswordAvailability(values: IResetPasswordRequest): Observable<IChangePasswordAvailabilityRequest> {
    const url = `${environment.auth_url}/password/reset-availability`;
    return this.http.put<IChangePasswordAvailabilityRequest>(url, values, { observe: 'body' });
  }

  public resetPassword(values: IResetPasswordRequest): Observable<IAccountChangeResult> {
    const url = `${environment.auth_url}/password/reset`;
    return this.http
      .put<IAccountChangeResult>(url, values, { observe: 'response' })
      .pipe(map((response) => response.body));
  }

  public checkResetPassword(values: Partial<IConfirmResetPasswordRequest>): Observable<IAccountChangeResult> {
    const url = `${environment.auth_url}/password/check`;
    return this.http
      .post<IAccountChangeResult>(url, values, { observe: 'response' })
      .pipe(map((response) => response.body));
  }

  public confirmResetPassword(values: IConfirmResetPasswordRequest): Observable<IAccountChangeResult> {
    const url = `${environment.auth_url}/password/confirm`;
    return this.http
      .put<IAccountChangeResult>(url, values, { observe: 'response' })
      .pipe(map((response) => response.body));
  }

  public sendOnlineStatus() {
    if (!this.isAuthenticated || this.sendingOnlineStatus) {
      return;
    }

    this.sendingOnlineStatus = true;
    const url = `${environment.auth_url}/status-online`;
    this.http
      .put(url, {}, { observe: 'body' })
      .pipe(first())
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => (this.sendingOnlineStatus = false));
  }

  private handleError(error: HttpErrorResponse) {
    console.error('server error:', error);
    if (error.error instanceof Error) {
      const errMessage = error.error.message;
      return throwError(errMessage);
      // Use the following instead if using lite-server
      // return throwError(err.text() || 'backend server error');
    }
    return throwError(error || 'Node.js server error');
  }

  private handleLoginError(error: HttpErrorResponse) {
    return throwError({
      error: error ? error : 'Unexpected error, please try later',
    });
  }

  protected get cookieDomain(): string {
    return '.' + environment.cookie_domain;
  }
}
