import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { REQUEST } from '../../../express.tokens';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,

    /** @see https://expressjs.com/ru/api.html#req */
    @Optional() @Inject(REQUEST) private request: any,
  ) {}

  /** http://hostname:port -> http://hostname:port */
  public get host(): string {
    if (this.isBrowser) {
      const browserWindow: any = window || {};
      const location: any = browserWindow.location || {};
      return location.origin;
    } else {
      return `${this.request.protocol}://${this.request.get('host')}`;
    }
  }

  /** http://hostname:port -> hostname */
  public get hostname(): string {
    if (this.isBrowser) {
      const browserWindow: any = window || {};
      const location: any = browserWindow.location || {};
      return location.hostname || '';
    } else {
      return this.request.hostname;
    }
  }

  private get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }
}
