/**
 * https://support.google.com/analytics/answer/13316687?hl=ru#zippy=%2C%D1%81%D0%B0%D0%B9%D1%82%D1%8B
 * https://data.ga4spy.com/ga4-events-parameters
 *
 * Все события с описаниями
 * https://docs.google.com/spreadsheets/d/1jOs-hSi-tv-sHoyz_YS_BuvWhxv7OG7nIai7s6GnM24/edit#gid=0
 */

export enum EventActionEnum {
  view_landing = 'view_landing',
  view_first_contact = 'view_first_contact',

  /** Авторизация */
  auth_opened = 'auth_opened',
  signin_succeed = 'signin_succeed',
  signup_succeed = 'signup_succeed',
  signup_social_succeed = 'signup_social_succeed',
  become_teacher = 'become_teacher',
  become_student = 'become_student',
  become_methodist = 'become_methodist',
  become_school_owner = 'become_school_owner',

  /** Верификация преподавателей */
  verification_sent = 'verification_sent',
  verification_resent = 'verification_resent',
  verification_canceled = 'verification_canceled',

  /** Создание интерактивных уроков */
  lesson_creating = 'lesson_creating',
  lesson_created = 'lesson_created',
  lesson_publishing = 'lesson_publishing',
  lesson_published = 'lesson_published',
  lesson_overview_opened = 'lesson_overview_opened',
  lesson_game_started = 'lesson_game_started',
  lesson_game_finished = 'lesson_game_finished',
  lesson_game_unfinished = 'lesson_game_unfinished',

  /** Планирование онлайн-занятий */
  meeting_scheduling = 'meeting_scheduling',
  meeting_scheduled = 'meeting_scheduled',
  meeting_updating = 'meeting_updating',
  meeting_updated = 'meeting_updated',
  meeting_quick_creating = 'meeting_quick_creating',
  meeting_quick_created = 'meeting_quick_created',
  meeting_rescheduled = 'meeting_rescheduled',
  meeting_canceled = 'meeting_canceled',
  meeting_inviting = 'meeting_inviting',
  opened_invite_to_meeting = 'opened_invite_to_meeting',
  invited_to_meeting = 'invited_to_meeting',
  joined_meeting = 'joined_meeting',
  conference_opening = 'conference_opening',
  conference_opened = 'conference_opened',

  /** Создание коллекций интерактивных уроков */
  collection_creating = 'collection_creating',
  collection_created = 'collection_created',
  collection_publishing = 'collection_publishing',
  collection_published = 'collection_published',

  /** Продажи, подписки */
  paywall_opened = 'paywall_opened',
  paywall_card_linking = 'paywall_card_linking',
  paywall_card_linked = 'paywall_card_linked',
  paywall_payment_started = 'paywall_payment_started',
  paywall_payment_succeed = 'paywall_payment_succeed',
  meeting_purchased = 'meeting_purchased',
  school_meeting_purchased = 'school_meeting_purchased',
  plan_subscribed = 'plan_subscribed',
  plan_auto_prolonged = 'plan_auto_prolonged',
  plan_selected = 'plan_selected',
  plan_cancelled = 'plan_cancelled',
  plan_auto_cancelled = 'plan_auto_cancelled',
}

export enum EventCategoryEnum {
  /** События связанные с вовлечением пользователей в продукт */
  engagement = 'engagement',

  /** События связанные с электронной коммерцией */
  ecommerce = 'ecommerce',

  /** События связанные с генерацией пользовательского контента */
  content = 'content',

  /** События связанные с оказанием услуг между пользователями */
  service = 'service',
}
