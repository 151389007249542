import { Injectable, Injector } from '@angular/core';
import { EntityExtenderService, Extensions } from '@core/services';
import { EventsSequence, Meeting, MeetingDetailsType, User, UserService, UserServiceContract } from 'lingo2-models';
import { Observable, of, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserServiceContractDetailsType } from '../lingo2-content/user-service-contracts.service';
import { AccountDetailsType } from './account.service';

export class EventsSequenceExtended extends EventsSequence {
  public account?: User;
  public meeting?: Meeting;
  public user_service?: UserService;
  public user_service_contract?: UserServiceContract;

  public get meetingsLimit(): number {
    return this.user_service?.options?.meetings_limit || 1;
  }

  public get can() {
    return this.user_service_contract?.can;
  }

  public get is() {
    return this.user_service_contract?.is;
  }
}

export type ExtendEventsSequenceType = 'account' | 'meeting' | 'user_service';

@Injectable({
  providedIn: 'root',
})
export class EventsSequenceExtendedService extends EntityExtenderService {
  protected accountDetails: AccountDetailsType[] = ['id', 'slug', 'first_name', 'last_name'];
  protected meetingDetails: MeetingDetailsType[] = [
    'id',
    'title',
    'slug',
    'type',
    'subject',
    'participants_limit',
    'participants_count',
    'classroom',
    'is',
  ];
  protected userServiceContractDetails: UserServiceContractDetailsType[] = [
    'id',
    'user_service_terms',
    'user_service',
    'can',
  ];

  public constructor(protected injector: Injector) {
    super(injector);
  }

  public extend$(
    events: EventsSequence[],
    details: ExtendEventsSequenceType[] = [],
  ): Observable<EventsSequenceExtended[]> {
    return zip(
      this.findUserServiceContracts$(events),
      details.includes('account') ? this.findAccounts$(events) : of([]),
      details.includes('meeting') ? this.findMeetings$(events) : of([]),
    ).pipe(
      map(([user_service_contracts, accounts, meetings]) =>
        events.map((event) => this.mapper(event, { user_service_contracts, accounts, meetings })),
      ),
    );
  }

  protected mapper(event: EventsSequence, extensions: Extensions): EventsSequenceExtended {
    const { user_service_contracts, accounts, meetings } = extensions;
    const _event = new EventsSequenceExtended(event);
    _event.user_service_contract = user_service_contracts.find((item) => item.id === event.user_service_contract_id);
    _event.user_service = _event.user_service_contract?.user_service;
    _event.account = accounts.find((item) => item.id === event.account_id);
    _event.meeting = meetings.find((item) => item.id === event.meeting_id);
    _event.events = _event.events.map((__event) => {
      __event.meeting = meetings.find((item) => item.id === __event.meeting_id);
      return __event;
    });
    return _event;
  }
}
