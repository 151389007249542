import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  AccountService,
  ContextService,
  FeaturesService,
  FilesService,
  MeetingsService,
  PlatformService,
} from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { differenceInSeconds } from 'date-fns';
import { EDashboardMeetingGroup, FeatureEnum, Meeting, otherSubjectId, User } from 'lingo2-models';
import { Router as ContentRouter } from 'lingo2-models';
import { OnUiCover } from 'onclass-ui';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface MeetData {
  id?: number;
  link?: string;
  autorLink?: string;
  cover?: OnUiCover;
  title?: string;
  onAir?: boolean;
  description?: string;
  participants_count?: number;
  participants_limit?: number;
  meetingTypeName?: string;
  is_author?: boolean;
  authorFullName?: string;
  beginDate?: Date;
}

@Component({
  selector: 'app-nearest-meet-btn',
  templateUrl: './nearest-meet-btn.component.html',
  styleUrls: ['./nearest-meet-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NearestMeetBtnComponent extends ChangableComponent implements OnInit {
  _user: User;
  public myMeetings: Observable<Meeting[]>;
  private _meeting: Meeting;
  public meetData: MeetData = {};
  public dopInfoOpen = false;

  @Input() set meeting(meeting: Meeting) {
    this._meeting = meeting;
    this.constructMeetObject(meeting);
  }
  get meetings(): Meeting {
    return this._meeting;
  }

  constructor(
    private contextService: ContextService,
    private meetingsService: MeetingsService,
    private router: Router,
    private features: FeaturesService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
    @Inject(PLATFORM_ID) private platformId: object,
  ) {
    super(cdr, platform);
  }

  ngOnInit(): void {
    this.contextService.me$.pipe(takeUntil(this.destroyed$)).subscribe((me) => (this._user = me));
  }

  toggleDopInfo() {
    this.dopInfoOpen = !this.dopInfoOpen;
  }

  public get isVisible() {
    return this.features.isAvailable(FeatureEnum.nearest_meet_widget);
  }

  public get isStartCountdown(): boolean {
    return this.meetData.beginDate && differenceInSeconds(this.meetData.beginDate, new Date()) < 3600;
  }

  private setMeetStatusOnAirForce() {
    this.setTimeout(() => {
      this.meetData.onAir = true;
    }, differenceInSeconds(this.meetData.beginDate, new Date()) * 1000);
  }

  constructMeetObject(data) {
    this.meetData.id = data?.id;
    this.meetData.autorLink = '/u/' + data?.author?.slug + '/teaching';
    this.meetData.cover = {
      img: FilesService.getFileUrlBySize(data.cover_id),
      type: 'cover',
    };
    this.meetData.title = data?.subject
      ? +data.subject?.id === otherSubjectId
        ? data.subject_other_name
        : data.subject?.title
      : null;
    this.meetData.onAir = data?.has?.meetingGroup === EDashboardMeetingGroup.going;
    this.meetData.beginDate = data?.begin_at as Date;
    if (!this.meetData.onAir) {
      this.setMeetStatusOnAirForce();
    }
    this.meetData.description = data?.description;
    this.meetData.participants_count = data?.participants_count;
    this.meetData.participants_limit = data?.participants_limit;
    this.meetData.meetingTypeName = MeetingsService.meetingTypeName(data);
    this.meetData.is_author = data?.meetData?.visit_info?.is_author;
    if (!this.meetData.is_author) {
      this.meetData.authorFullName = AccountService.getUserFullName(data?.author);
    }
    this.meetData.link = this.router.createUrlTree(ContentRouter.meetingJoinRoute(data)).toString();
  }
}
