import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Lesson, LessonSlide, ILessonSlideSection, LessonSlideContentTypeEnum } from 'lingo2-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConstructorService {
  constructor(private http: HttpClient) {}

  // Lessons

  /**
   * Урок
   *
   * @param id string
   */
  public getLesson(id: string): Observable<Lesson> {
    const url = `${environment.content_url}/constructor/${id}`;
    return this.http.get<Lesson>(url, { observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  /**
   * Создать урок
   */
  public createLesson(): Observable<Lesson> {
    const url = `${environment.content_url}/constructor/`;
    return this.http.post<Lesson>(url, {}, { observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  /**
   * Обновить урок
   *
   * @param id string
   * @param values Partial<Lesson>
   */
  public updateLesson(id: string, values: Partial<Lesson>): Observable<Lesson> {
    const url = `${environment.content_url}/constructor/${id}`;
    return this.http.put<Lesson>(url, values, { observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  /**
   * Удалить урок
   *
   * @param id string
   */
  public removeLesson(id: string): Observable<Lesson> {
    const url = `${environment.content_url}/constructor/${id}`;
    return this.http.delete<Lesson>(url, { observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  // Slides

  /**
   * Слайд урока
   *
   * @param id string
   */
  public getSlide(id: string): Observable<LessonSlide> {
    const url = `${environment.content_url}/constructor/slide/${id}`;
    return this.http.get<LessonSlide>(url, { observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  /**
   * Создать слайд
   *
   * @param lesson_id string
   */
  public createSlide(lesson_id: string, values?: Partial<LessonSlide>): Observable<LessonSlide> {
    const url = `${environment.content_url}/constructor/${lesson_id}/slide`;
    return this.http.post<LessonSlide>(url, values || {}, { observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  /**
   * Обновить слайд
   *
   * @param id string
   * @param values Partial<LessonSlide>
   */
  public updateSlide(id: string, values: Partial<LessonSlide>): Observable<LessonSlide> {
    const url = `${environment.content_url}/constructor/slide/${id}`;
    return this.http.put<LessonSlide>(url, values, { observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  /**
   * Удалить слайд
   *
   * @param id string
   */
  public removeSlide(id: string): Observable<LessonSlide> {
    const url = `${environment.content_url}/constructor/slide/${id}`;
    return this.http.delete<LessonSlide>(url, { observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  // Sections

  /**
   * Секция слайда
   *
   * @param id string
   */
  public getSection(id: string): Observable<ILessonSlideSection> {
    const url = `${environment.content_url}/constructor/section/${id}`;
    return this.http.get<ILessonSlideSection>(url, { observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  /**
   * Создать секцию
   *
   * @param slide_id string
   * @param type LessonSlideContentTypeEnum
   */
  public createSection(slide_id: string, type: LessonSlideContentTypeEnum): Observable<ILessonSlideSection> {
    const url = `${environment.content_url}/constructor/slide/${slide_id}/section`;
    return this.http.post<ILessonSlideSection>(url, { type }, { observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  /**
   * Обновить секцию
   *
   * @param id string
   * @param values Partial<ILessonSlideSection>
   */
  public updateSection(id: string, values: Partial<ILessonSlideSection>): Observable<ILessonSlideSection> {
    const url = `${environment.content_url}/constructor/section/${id}`;
    return this.http.put<ILessonSlideSection>(url, values, { observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  /**
   * Удалить секцию
   *
   * @param id string
   */
  public removeSection(id: string): Observable<ILessonSlideSection> {
    const url = `${environment.content_url}/constructor/section/${id}`;
    return this.http.delete<ILessonSlideSection>(url, { observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  /**
   * Сконвертировать урок
   *
   * @param id string
   */
  public convertOldContent(id: string): Observable<Lesson> {
    const url = `${environment.content_url}/constructor/convert-old-content/${id}`;
    return this.http.post<Lesson>(url, { observe: 'response' }).pipe(
      map((response) => response),
      // catchError(this.handleError),
    );
  }
}
