export const ChooseTutorList = [
  {
    type: 'additional-materials',
    title: 'first-contact.choose-tutor.list.title1',
    text: 'first-contact.choose-tutor.list.text1',
  },
  {
    type: 'verified-online-schools',
    title: 'first-contact.choose-tutor.list.title2',
    text: 'first-contact.choose-tutor.list.text2',
  },
  {
    type: 'safety-and-reliability',
    title: 'first-contact.choose-tutor.list.title3',
    text: 'first-contact.choose-tutor.list.text3',
  },
  {
    type: 'comfortable-conditions',
    title: 'first-contact.choose-tutor.list.title4',
    text: 'first-contact.choose-tutor.list.text4',
  },
];
