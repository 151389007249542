import { DefaultUrlSerializer } from '@angular/router';
import { LibraryRouter } from '@app/models/library.router';
import { Action, createReducer, createSelector } from '@ngrx/store';
import {
  EContentModeType,
  EContentOrder,
  EContentPanelRoute,
  Subject as BaseSubject,
  SubjectCategoryEnum,
} from 'lingo2-models';
import { uniq as _uniq } from 'lodash';
import { CLibraryFilterData } from '../models';
import { getLanguages, getSubjects } from './content.reducer';
import { getMyProfile, getUserLanguages } from './profile.reducer';
import { getMergedRoute } from './router.reducer';

export const KEY_SUBJECT_CODES_FOR_SAVE = 'subjectCodes';

function extractSubjectsAndTypes(url: string, subjects: BaseSubject[], fullUrl?: string): Partial<CLibraryFilterData> {
  let contentMode: EContentModeType = EContentModeType.all;
  let _url = url;
  Object.entries(LibraryRouter.contentModeTypeUrlMap).find(([mode, path]) => {
    if (path === '') {
      return false;
    }
    const i = _url.lastIndexOf(`/${path}`);
    if (i >= 0) {
      contentMode = mode as any;
      _url = _url.substring(0, i);
      return true;
    }
    return false;
  });
  const urlTree = new DefaultUrlSerializer().parse(_url);

  if (!urlTree) {
    return {
      contentMode,
      subjectCodes: [],
      contentTypeCodes: [],
    };
  }

  let subjectCodes: string[] = [];
  let contentTypeCodes: string[] = [];
  const contentOrder = urlTree.queryParams.order || EContentOrder.popularity;
  const languageCodes: string[] =
    urlTree.queryParams.languages !== undefined ? urlTree.queryParams.languages.split(',') : null; // 2 - English

  if (urlTree.root.children.primary) {
    const segments = urlTree.root.children.primary.segments;

    if (segments.length > 0) {
      const subjectsFromPath = segments[0].path;
      if (subjectsFromPath !== '-') {
        subjectCodes = subjectsFromPath.split(',');
      }
    }

    if (segments.length > 1) {
      const typeFromPath = segments[1].path;
      contentTypeCodes = typeFromPath.split(',');
    }
  }

  subjectCodes = filterSubjects(subjectCodes, subjects);

  /**
   * Костыль для сохранения выбранной дисциплины
   * Задача: https://app.clickup.com/t/85zrxkvjv
   */
  const urlIncludeList: string[] = [
    EContentPanelRoute.main,
    EContentPanelRoute.tutors,
    EContentPanelRoute.collections,
    EContentPanelRoute.schools,
    EContentPanelRoute.classes,
    EContentPanelRoute.lessons,
  ];
  const urlExcludeList: string[] = [
    EContentPanelRoute['my-library'],
    EContentPanelRoute['my-classes'],
    EContentPanelRoute['favorites'],
    'my-feed',
  ];
  if (localStorage || false) {
    if (subjectCodes.length > 0) {
      localStorage.setItem(KEY_SUBJECT_CODES_FOR_SAVE, JSON.stringify(subjectCodes));
    } else {
      if (
        !!urlIncludeList.find((url) => fullUrl.includes(url)) &&
        !urlExcludeList.find((url) => fullUrl.includes(url))
      ) {
        if (localStorage.getItem(KEY_SUBJECT_CODES_FOR_SAVE)) {
          try {
            subjectCodes = JSON.parse(localStorage.getItem(KEY_SUBJECT_CODES_FOR_SAVE));
          } catch (e) {
            subjectCodes = [];
          }
        }
      } else {
        subjectCodes = [];
      }
    }
  }
  /**
   * Конец костыля
   */

  return {
    contentMode,
    subjectCodes,
    contentTypeCodes,
    contentOrder,
    languageCodes,
  };
}

function filterSubjects(codes: string[], subjects: BaseSubject[]) {
  const subjectCodes = subjects.map((subject) => subject.code);
  return (codes || []).filter((code) => subjectCodes.includes(code));
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface State {}

const initialState: State = {};

const libraryReducer = createReducer(initialState);

export function reducer(state: State | undefined, action: Action) {
  return libraryReducer(state, action);
}

let latestUrl = '';
export const libraryFeatureKey = 'library';

export const getLibraryRouteData = createSelector(
  getMergedRoute,
  getMyProfile,
  getUserLanguages,
  getLanguages,
  getSubjects,
  (route, profile, userLanguages, languages, subjects): CLibraryFilterData => {
    /**
     * https://app.clickup.com/t/3889ezt
     *
     * @todo попробуем без этой проверки, мешает кастомным страницам
     * и во многих местах где блоки основывают свое поведение на роутинге
     * if (!route || !route.url || route.url === latestUrl || !subjects || subjects.length === 0) {
     *   return null;
     * }
     * */

    latestUrl = route.url;

    const panelRoutes = Object.keys(EContentPanelRoute).sort((a, b) => b.length - a.length);
    let baseRoute: EContentPanelRoute = EContentPanelRoute.main;

    let [url, query] = route.url.split('?');

    let isRouteFound = false;
    let routeNumber = 0;

    const tempUrlArray = url.split('/').filter((item) => item && item !== '');
    while (!isRouteFound && routeNumber < panelRoutes.length) {
      const routeName = panelRoutes[routeNumber];
      /**
       * Тоже дикий костыль  @See: https://app.clickup.com/t/85ztejr3j
       * если количество елементов в урле большое
       * то мы ищем тип контента только в первых двух
       * исходя из расчета, что тип контента или первый елемент или второй (если используется дисциплина)
       */
      if (tempUrlArray.length > 2) {
        if (tempUrlArray.slice(0, 2).indexOf(routeName) > -1) {
          isRouteFound = true;
          baseRoute = routeName as EContentPanelRoute;
          url = url.replace(`/${baseRoute}`, '');
        }
      } else {
        if (url.indexOf('my-feed') > -1) {
          isRouteFound = true;
          baseRoute = url.substring(1) as EContentPanelRoute;
          url = url.replace(`/${baseRoute}`, '');
        } else if (url.indexOf(routeName) > -1) {
          isRouteFound = true;
          baseRoute = routeName as EContentPanelRoute;
          url = url.replace(`/${baseRoute}`, '');
        }
      }
      /**
       * Конец дикого костыля
       */

      routeNumber++;
    }

    let segments = url.split('/').splice(1);

    let contentMode: EContentModeType = EContentModeType.all;

    // Get mode
    if (route.queryParams.term) {
      contentMode = EContentModeType.search;
    }

    /**
     * @todo:
     * ВНИМАНИЕ КОСТЫЛь ДЛЯ https://app.clickup.com/t/256wbe8
     * Не повторять! Опасно!
     */
    if (baseRoute === EContentPanelRoute.classrooms) {
      url = `/-/-${url}`;
      segments = url.split('/');
    }
    /** Конец костыля */

    // Subjects and types
    query = query ? `?${query}` : '';
    const routeData = extractSubjectsAndTypes(`${url}${query}`, subjects, route.url);
    let languageCodes: string[] = [];
    let isDefaultOnlyLanguages = true;
    if (routeData.languageCodes instanceof Array) {
      languageCodes = languageCodes.concat(routeData.languageCodes);
      isDefaultOnlyLanguages = false;
    } else {
      languageCodes = [...userLanguages];
      let langSubjects: BaseSubject[] = [];
      if (routeData.subjectCodes && routeData.subjectCodes.length > 0) {
        langSubjects = langSubjects.concat(
          subjects.filter(
            (s) =>
              [SubjectCategoryEnum.native_language, SubjectCategoryEnum.foreign_language].includes(s.category_id) &&
              routeData.subjectCodes.includes(s.code),
          ),
        );
      }
      if (profile) {
        if (profile.spoken_languages) {
          profile.spoken_languages.forEach((ul) => {
            const language = languages.find((l) => l.id.toString() === ul.language_id.toString());
            if (language) {
              languageCodes.push(language.code);
            }
          });
        }
        if (profile.teaching_subjects && profile.teaching_subjects.length > 0) {
          const subjectsIds = profile.teaching_subjects.map((ts) => ts.subject_id);
          langSubjects = langSubjects.concat(
            subjects.filter(
              (s) =>
                [SubjectCategoryEnum.native_language, SubjectCategoryEnum.foreign_language].includes(s.category_id) &&
                subjectsIds.includes(s.id),
            ),
          );
        }
      }
      for (const langSubject of langSubjects) {
        const language = languages.find((l) => l.id.toString() === langSubject.language_id.toString());
        if (language) {
          languageCodes.push(language.code);
        }
      }
    }
    let subjectsMap = routeData.subjectsMap || [];
    if (routeData.subjectCodes && routeData.subjectCodes.length > 0) {
      subjectsMap = routeData.subjectCodes.map((sc) => subjects.find((s) => s.code === sc)?.id);
    }
    languageCodes = _uniq(languageCodes);

    // Item
    let item = '';
    /** Говнокод продакшн */
    if (segments.length > 2) {
      let itemSegmentId = segments.length - 1;
      while (itemSegmentId > 2 && segments[itemSegmentId].indexOf('-') === -1) {
        itemSegmentId--;
      }
      const itemSegment = segments[itemSegmentId];
      if (itemSegment.indexOf('-') > -1) {
        item = itemSegment.split('-').pop();
      }
    }
    /** End of Говнокод продакшн */

    const data: CLibraryFilterData = {
      // Default values
      url: encodeURIComponent(route.url),
      baseRoute,
      term: route.queryParams.term,
      subjectCodes: [],
      contentTypeCodes: [],
      contentOrder: EContentOrder.popularity_week,
      // Extracted values
      ...routeData,
      subjectsMap,
      contentMode,
      languageCodes,
      item,
      isDefaultOnlyLanguages,
    };

    return data;
  },
);
