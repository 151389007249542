import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { IBaseNotification } from '@core/components/notification/base-notification/base-notification.component';
import { ChangableComponent } from '@models/changable.component';
import { NoticeTypeEnum, INoticeParams } from 'lingo2-models';
import { takeUntil } from 'rxjs/operators';
import { ContextService } from 'src/app/core/services';
import { NotificationService } from './notification.service';

/**
 * Когда добавляешь новый тип уведомления, добавь его в список поддерживаемых типов уведомений
 *
 * @see supportedNotices
 */

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  providers: [NotificationService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent extends ChangableComponent implements OnInit {
  @Input() notification: INoticeParams;
  @Input() hasClose: boolean;
  @Output() closed = new EventEmitter<boolean>();

  public noticeTypes = NoticeTypeEnum;
  public type: string;
  public debug$ = this.context.debug$;
  public base: IBaseNotification;

  public constructor(
    private context: ContextService,
    private notificationService: NotificationService,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  public ngOnInit() {
    this.type = NoticeTypeEnum[this.notification.type];
    this.notificationService.setHasClose(this.hasClose);

    this.notificationService.closed$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.closed.emit();
    });
  }
}
