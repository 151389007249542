import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FinanceCardsPageModule } from '@app/account/finance-page/finance-cards-page/finance-cards-page.module';
import { AlertModule } from '@core/components/alert/alert.module';
import { MobileModalModule } from '@core/components/mobile-modal/mobile-modal.module';
import { ModalModule } from '@core/components/modal/modal.module';
import { AccountService, IconsService, PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { getMe } from '@store/reducers/profile.reducer';
import { differenceInDays } from 'date-fns';
import { IAccountCheck } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiButtonModule } from 'onclass-ui';
import { interval, tap, takeUntil, switchMap, of } from 'rxjs';

@Component({
  selector: 'app-suggest-add-card',
  standalone: true,
  imports: [
    CommonModule,
    OnUiButtonModule,
    AlertModule,
    TranslateModule,
    ModalModule,
    FinanceCardsPageModule,
    MobileModalModule,
  ],
  templateUrl: './suggest-add-card.component.html',
  styleUrls: ['./suggest-add-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestAddCardComponent extends ChangableComponent implements OnInit {
  public svgsetIcon = IconsService.svgsetIconUrl;
  public openAlert = false;
  public newCardOpen = false;

  private _lastClosedSuggestAddCard: Date;
  private _userServiceCreated: boolean;
  private isTeacher: boolean;
  private accountCheck: IAccountCheck;
  private noBillingDataType: 'card' | 'beneficiary';

  constructor(
    private store: Store,
    private router: Router,
    private accountService: AccountService,
    public deviceService: DeviceDetectorService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  public ngOnInit() {
    this.store
      .select(getMe)
      .pipe(
        tap((me) => {
          this.isTeacher = AccountService.isTeacher(me) || AccountService.isAsIfTeacher(me);
          if (this.isTeacher) {
            this.loadAccountCheck();
          }
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  private loadAccountCheck() {
    this.accountService
      .accountCheck()
      .pipe(
        switchMap((accountCheck) => {
          this.accountCheck = accountCheck;
          return this.localstorageWatcher$;
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  public get visibleSuggest(): boolean {
    if (this.accountCheck?.no_payouts && this.isTeacher && this._userServiceCreated && this.differenceOneDayOrMore()) {
      this.noBillingDataType = 'beneficiary';
      this.patchPageStyles(true);
      return true;
    } else {
      this.patchPageStyles(false);
      return false;
    }
  }

  public get isNoCard() {
    return this.noBillingDataType === 'card';
  }

  public get isNoBeneficiary() {
    return this.noBillingDataType === 'beneficiary';
  }

  public openNewCardForm() {
    this.setLast();
    void this.router.navigate(['/profile/payout']).then();
    this.detectChanges();
  }

  public closeNewCardForm() {
    this.newCardOpen = false;
    this.detectChanges();
  }

  public onToggleAlert() {
    this.openAlert = !this.openAlert;
    this.detectChanges();
  }

  public closeSuggest() {
    this.setLast();
    this.onToggleAlert();
  }

  private setLast() {
    if (this.isBrowser && localStorage) {
      localStorage.setItem('lastClosedSuggestAddCard', new Date().toString());
    }
  }

  private differenceOneDayOrMore(): boolean {
    return differenceInDays(new Date(), this._lastClosedSuggestAddCard) >= 1;
  }

  private get localstorageWatcher$() {
    return this.isBrowser && localStorage
      ? interval(1000).pipe(
          tap(() => {
            this._lastClosedSuggestAddCard = new Date(localStorage.getItem('lastClosedSuggestAddCard'));
            this._userServiceCreated = localStorage.getItem('userServiceCreated') === 'true';
            this.detectChanges();
          }),
        )
      : of('is server');
  }

  private patchPageStyles(enabled: boolean) {
    // TODO реализовать на подписках, а не патчить стили харкорно
    const elem = document.querySelector('.app-body');
    if (!elem) {
      return;
    }
    if (enabled) {
      elem.classList.add('suggest-add-card');
    } else {
      elem.classList.remove('suggest-add-card');
    }
  }
}
