import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { IconsService } from '@core/services';

@Component({
  selector: 'app-onboarding-title',
  templateUrl: './onboarding-title.component.html',
  styleUrls: ['./onboarding-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingTitleComponent implements OnInit {
  @Input() icon: string;
  @Input() title: string;

  public svgsetIcon = IconsService.svgsetIconUrl;

  constructor() {}

  ngOnInit() {}
}
