<div class="verification-widget-status"
     [ngClass]="displayMode"
     [class.mobile]="deviceService.isMobile()">
  <div class="description" [ngSwitch]="statusDescriptions[verification_status]" [ngClass]="displayMode">

    <span *ngSwitchCase="'incomplete'">
      {{ 'verification-widget.description.incomplete' | translate }}
    </span>

    <span *ngSwitchCase="'about'" class="link" [appPopover]="popoverAbout">
      {{ 'verification-widget.description.about' | translate }}
    </span>

    <span *ngSwitchCase="'changed'">{{ 'verification-widget.description.changed' | translate }}</span>

    <span *ngSwitchCase="'resent'">{{ 'verification-widget.description.resent' | translate }}</span>

    <span *ngSwitchCase="'declined'">
      {{ 'verification-widget.description.declined-1' | translate }} <span class="declined">
      {{ 'verification-widget.description.declined-2' | translate }}</span>
    </span>

  </div>

  <div class="title">
    <span [ngClass]="statuses[verification_status]">{{ statusTitles[verification_status] | translate }}</span>
  </div>

  <!-- Popovers -->

  <ng-template #popoverAbout>
    <app-about-verification-widget></app-about-verification-widget>
  </ng-template>
</div>
