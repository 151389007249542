<div class="panel" [class.panel_expanded]="isExpanded$ | async">

  <div class="panel__title" (click)="toggleExpand()">
    <b>{{ title | translate }}</b>
    <svg class="panel__chevron">
      <use [attr.xlink:href]="svgsetIcon('chevron-right')"></use>
    </svg>
  </div>

  <div class="panel__list">
    <div class="option" *ngFor="let option of options; trackBy: trackByFn">
      
      <div class="option__icon">
        <svg *ngIf="option.bullet === bullets.check" width="9" height="9" viewBox="0 0 6 6">
          <use [attr.xlink:href]="svgsetIcon('badge-daw')"></use>
        </svg>
        <svg *ngIf="option.bullet === bullets.cross" width="8" height="8" viewBox="0 0 8 8">
          <use [attr.xlink:href]="svgsetIcon('badge-cross')"></use>
        </svg>
        <div *ngIf="option.bullet === bullets.dot" class="dot"></div>
      </div>

      <span class="option__label">{{ option.label | translate }}</span>
      
    </div>
  </div>
</div>