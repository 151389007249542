<!-- lingo2-web/src/assets/icons/aspect-ratio.svg -->
<div class="aspect-ratio-selector">
  <svg width="202" height="38" viewBox="0 0 210 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 10L196 10" stroke="#5AB3E4" stroke-width="2" stroke-linecap="round"></path>
    <path d="M75 7L75 13" stroke="#5AB3E4" stroke-width="2" stroke-linecap="round"></path>
    <path d="M14 7L14 13" stroke="#5AB3E4" stroke-width="2" stroke-linecap="round"></path>
    <path d="M197 7L197 13" stroke="#5AB3E4" stroke-width="2" stroke-linecap="round"></path>
    <path d="M136 7L136 13" stroke="#5AB3E4" stroke-width="2" stroke-linecap="round"></path>
    <rect x="68" y="26" width="14" height="8" rx="1" stroke="#5AB3E4" stroke-width="2"></rect>
    <rect x="1" y="26" width="26" height="8" rx="1" stroke="#5AB3E4" stroke-width="2"></rect>
    <rect x="130" y="24" width="12" height="12" rx="1" stroke="#5AB3E4" stroke-width="2"></rect>
    <rect x="193" y="37" width="14" height="8" rx="1" transform="rotate(-90 193 37)" stroke="#5AB3E4" stroke-width="2"></rect>
    <circle class="aspect-ratio-pointer2" [attr.cx]="aspectRatioRangeCx" cx="10" cy="10" r="10" fill="#5AB3E4" opacity="0.3" />
    <circle class="aspect-ratio-pointer" [attr.cx]="aspectRatioRangeCx" cx="0" cy="10" r="6" stroke="#5AB3E4" stroke-width="2"></circle>
  </svg>

  <div class="aspect-ratio-options">
    <div class="aspect-range-option option1" (click)="setAspectRatio('wide', $event)"></div>
    <div class="aspect-range-option option2" (click)="setAspectRatio('horizontal', $event)"></div>
    <div class="aspect-range-option option3" (click)="setAspectRatio('square', $event)"></div>
    <div class="aspect-range-option option4" (click)="setAspectRatio('vertical', $event)"></div>
  </div>
</div>
