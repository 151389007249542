import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { getLesson } from '@app/content-editor/store/lesson/lesson.selectors';
import { markFormGroupTouched } from '@app/shared';
import { Store } from '@ngrx/store';
import { otherSubjectId, otherSubjectLevelId } from 'lingo2-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class DescriptionFormService {
  form: UntypedFormGroup;
  private inputMaxLength = 50;

  constructor(private fb: UntypedFormBuilder, private store: Store) {}

  initForm(): Observable<any> {
    return this.store.select(getLesson).pipe(
      map((value) => {
        if (value && !value.level_id && value.level_other_name) {
          value.level_id = otherSubjectLevelId;
        }
        this.form = this.fb.group(
          {
            subject_id: [
              {
                value: '',
              },
              {
                updateOn: 'change',
                validators: [Validators.required],
              },
            ],
            subject_other_name: [
              {
                value: '',
                disabled: value?.subject_id !== otherSubjectId,
              },
              {
                validators: [Validators.maxLength(50), Validators.required],
              },
            ],
            title: [
              '',
              {
                updateOn: 'change',
                validators: [Validators.maxLength(this.inputMaxLength), Validators.required],
              },
            ],
            sub_title: [
              '',
              {
                updateOn: 'change',
                validators: [Validators.maxLength(this.inputMaxLength), Validators.required],
              },
            ],
            level_id: [
              {
                value: '',
                disabled: !value?.subject_id,
              },
              {
                updateOn: 'change',
                validators: [Validators.required],
              },
            ],
            level_other_name: [
              {
                value: '',
                disabled: value?.level_id !== otherSubjectLevelId,
              },
              {
                validators: [Validators.maxLength(50), Validators.required],
              },
            ],
            language_id: [
              { value: '' },
              {
                updateOn: 'change',
                validators: [Validators.required],
              },
            ],
            description: [
              { value: '' },
              {
                updateOn: 'change',
                validators: [Validators.minLength(30), Validators.maxLength(2000), Validators.required],
              },
            ],
            cover_id: [
              { value: '' },
              {
                updateOn: 'change',
                validators: [Validators.required],
              },
            ],
          },
          { updateOn: 'blur' },
        );
        this.form.patchValue(value);
        if (
          value &&
          (value.subject_id ||
            value.title ||
            value.sub_title ||
            value.level_id ||
            value.language_id ||
            value.description ||
            value.cover_id)
        ) {
          markFormGroupTouched(this.form);
        }
      }),
    );
  }
}
