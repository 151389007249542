import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { EventActionEnum, EventCategoryEnum } from '@app/core/services/analytics';
import { ChangableComponent } from '@app/models/changable.component';
import { AccountService, AnalyticsService, ContextService } from '@core/services';
import { takeUntil } from 'rxjs/operators';

import { OnboardingStatusesEnum as OSE } from '../onboarding-widget.component';

@Component({
  selector: 'app-onboarding-unknown',
  templateUrl: './onboarding-unknown.component.html',
  styleUrls: ['./onboarding-unknown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingUnknownComponent extends ChangableComponent {
  public status = OSE.guest;

  constructor(
    private accountService: AccountService,
    private contextService: ContextService,
    private analytics: AnalyticsService,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  /** пометить меня как 'хочу стать преподом' */
  public becomeTeacher(): void {
    this.analytics.event(EventActionEnum.become_teacher, EventCategoryEnum.engagement, 'app-onboarding-unknown');

    this.accountService
      .markAsTeacher()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (account) => {
          this.contextService.updateMe(account); // обновить мой аккаунт
        },
        (err: any) => {
          console.error('OnboardingUnknownComponent::becomeTeacher()', err);
        },
      );
  }

  /** пометить меня как 'хочу стать учеником' */
  public becomeStudent(): void {
    this.analytics.event(EventActionEnum.become_student, EventCategoryEnum.engagement, 'app-onboarding-unknown');

    this.accountService
      .markAsStudent()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (account) => {
          this.contextService.updateMe(account); // обновить мой аккаунт
        },
        (err: any) => {
          console.error('OnboardingUnknownComponent::becomeStudent()', err);
        },
      );
  }
}
