<section class="first-contact" [class.mobile]="deviceService.isMobile()">
  <app-first-contact-selector [selectedRole]="selectedRole"
                              (selectRole)="onSelectedRole($event)">
  </app-first-contact-selector>
</section>
<ng-container [ngSwitch]="selectedRole">
  <ng-container *ngSwitchCase="'tutor'">
    <ng-container *ngTemplateOutlet="tutor"></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'student'">
    <ng-container *ngTemplateOutlet="student"></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'methodist'">
    <ng-container *ngTemplateOutlet="methodist"></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'school'">
    <ng-container *ngTemplateOutlet="school"></ng-container>
  </ng-container>
</ng-container>

<ng-template #tutor>
  <app-first-contact-tools [selectedRole]="selectedRole"></app-first-contact-tools>
  <app-first-contact-constructor [selectedRole]="selectedRole"></app-first-contact-constructor>
  <app-first-contact-additional-tools [selectedRole]="selectedRole"></app-first-contact-additional-tools>
  <ng-template [ngIf]="!isLimitedVersion">
    <app-first-contact-user-services [selectedRole]="selectedRole"></app-first-contact-user-services>
    <app-first-contact-meeting-types [selectedRole]="selectedRole"></app-first-contact-meeting-types>
    <app-first-contact-processes [selectedRole]="selectedRole"></app-first-contact-processes>
    <app-first-contact-capabilities [selectedRole]="selectedRole"></app-first-contact-capabilities>
    <app-first-contact-faq [selectedRole]="selectedRole"></app-first-contact-faq>
  </ng-template>
</ng-template>

<ng-template #student>
  <app-first-contact-tools [selectedRole]="selectedRole"></app-first-contact-tools>
  <ng-template [ngIf]="!isLimitedVersion">
    <app-first-contact-free-lesson [selectedRole]="selectedRole"></app-first-contact-free-lesson>
  </ng-template>
  <app-first-contact-constructor [selectedRole]="selectedRole"></app-first-contact-constructor>
  <ng-template [ngIf]="!isLimitedVersion">
    <app-first-contact-additional-tools [selectedRole]="selectedRole"></app-first-contact-additional-tools>
    <app-first-contact-meeting-types [selectedRole]="selectedRole"></app-first-contact-meeting-types>
    <app-first-contact-steps [selectedRole]="selectedRole"></app-first-contact-steps>
    <app-first-contact-choose-tutor></app-first-contact-choose-tutor>
    <app-first-contact-faq [selectedRole]="selectedRole"></app-first-contact-faq>
  </ng-template>
</ng-template>

<ng-template #methodist>
  <app-first-contact-tools [selectedRole]="selectedRole"></app-first-contact-tools>
  <app-first-contact-constructor [selectedRole]="selectedRole"></app-first-contact-constructor>
  <app-first-contact-additional-tools [selectedRole]="selectedRole"></app-first-contact-additional-tools>
  <app-first-contact-meeting-types [selectedRole]="selectedRole"></app-first-contact-meeting-types>
  <app-first-contact-capabilities [selectedRole]="selectedRole"></app-first-contact-capabilities>
  <app-first-contact-processes [selectedRole]="selectedRole"></app-first-contact-processes>
</ng-template>

<ng-template #school>
  <app-first-contact-tools [selectedRole]="selectedRole"></app-first-contact-tools>
  <app-first-contact-constructor [selectedRole]="selectedRole"></app-first-contact-constructor>
  <app-first-contact-additional-tools [selectedRole]="selectedRole"></app-first-contact-additional-tools>
  <app-first-contact-meeting-types [selectedRole]="selectedRole"></app-first-contact-meeting-types>
  <app-first-contact-school-tools></app-first-contact-school-tools>
  <app-first-contact-capabilities [selectedRole]="selectedRole"></app-first-contact-capabilities>
  <app-first-contact-school-advantages></app-first-contact-school-advantages>
</ng-template>
