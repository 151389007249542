import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
  SocialUser,
} from '@abacritt/angularx-social-login';
import { transition, trigger, useAnimation } from '@angular/animations';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NavigationEnd, Router, UrlSegment } from '@angular/router';
import { ProfileFormService } from '@app/account/profile-form-page/_services/profile-form.service';
import { EventActionEnum, EventCategoryEnum } from '@app/core/services/analytics';
import { IControlOption } from '@app/shared';
import { FacebookAppId, GoogleClientId } from '@core/services/social-auth.service';
import { environment } from '@env/environment';
import { ChangableComponent } from '@models/changable.component';
import { LibraryRouter } from '@models/library.router';
import {
  EContentPanelRoute,
  ISignupRequest,
  ISocialSignupRequest,
  SocialNetworkEnum,
  TeacherStatusEnum,
  IChangePasswordAvailabilityRequest,
  UserSegmentEnum,
  Country,
} from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CountryISO } from 'ngx-intl-phone-input';
import { combineLatest, of } from 'rxjs';
import { catchError, filter, first, takeUntil, tap } from 'rxjs/operators';
import { moveFadeIn, moveFadeOut } from '../../animations';
import {
  AccountService,
  AnalyticsService,
  AuthModalModeType,
  AuthService,
  ConfigService,
  FeaturesService,
  IconsService,
  PlatformService,
  ProfileService,
  ScreenService,
} from '../../services/';

// custom validator to check that two fields match
function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

function getEnumKeyByEnumValue(enumObj: any, enumValue: any): string {
  const keys = Object.keys(enumObj).filter((k) => enumObj[k] === enumValue);
  return keys.length > 0 ? keys[0] : null;
}

function countryTitle(country: Country) {
  const parts: string[] = [country.title_native];
  if (country.title && country.title_native !== country.title) {
    parts.push(`(${country.title})`);
  }
  return parts.join(' ');
}

function countriesToOptions(countries: Country[]): IControlOption[] {
  return (countries || [])
    .map((country) => ({
      title: countryTitle(country),
      value: country.id.toString(),
    }))
    .sort((a, b) => a.title.localeCompare(b.title));
}

class AuthActionState {
  public error: boolean;
  public progress: boolean;
  public done: boolean;
  public percent = 0;

  public get default(): boolean {
    return !this.error && !this.progress && !this.done;
  }

  public reset(timeout = 1000) {
    const self = this;
    setTimeout(() => {
      self.error = false;
      self.progress = false;
      self.done = false;
      self.percent = 0;
    }, timeout);
  }

  public setProgress(percent: number, timeout = 100) {
    const self = this;
    setTimeout(() => {
      self.progress = true;
      self.done = false;
      self.percent = percent;
    }, timeout);
  }
}

@Component({
  selector: 'app-auth-modal',
  templateUrl: './auth-modal.component.html',
  styleUrls: ['./auth-modal.component.scss'],
  animations: [
    trigger('auth', [
      transition(':enter', useAnimation(moveFadeIn, { params: { time: '200ms', slide: '5px' } })),
      transition(':leave', useAnimation(moveFadeOut)),
    ]),
  ],
})
export class AuthModalComponent extends ChangableComponent implements OnInit, OnDestroy {
  @Input()
  public standalone = false;

  @Input()
  public set mode(mode: AuthModalModeType) {
    this._mode = mode;
    this.showValidation = false;
    this.showValidationEmailOnly = false;
    this.action_error = null;
    this.restoreComplete = false;
  }
  public get mode(): AuthModalModeType {
    return this._mode;
  }

  /** Откуда вызвали */
  @Input()
  public caller: string;

  /** Причина вызова */
  @Input()
  public reason: string;

  @Output()
  public modeChanged = new EventEmitter<AuthModalModeType>();

  @Output()
  public authSucceed = new EventEmitter<void>();

  @Output()
  public authCanceled = new EventEmitter<void>();

  public modalVisible = false;
  public hideSocialLinks = false;
  public form: UntypedFormGroup;
  public actionState = new AuthActionState();
  public action_error: string;
  public email_exist: boolean;
  public slug_exist: boolean;
  public showValidation = false;
  public showRoleValidation = false;
  public showValidationEmailOnly = false;
  public restoreComplete = false;
  public showRolesList = false;
  public isPhoneSuccess = false;
  public isCodeError = false;
  public verifyPhone = false;
  public isCaptcha = false;
  public isPoll = false;
  public isEmailAvailable = false;
  public isPhoneAvailable = false;
  public isChooseRestoreType = false;
  public isSendAgain = false;
  public selectedRole: IRoleReferences;
  public pollOptions = {};
  public selectedOption: number;
  public verify_code_response_info: object;
  public verified_code: string;
  public mainLink: Partial<UrlSegment>;
  public siteKey = environment.re_captcha_site_key;
  public roleTypes: Array<{ role: number; label: string; icon_id: string }> = [
    { role: TeacherStatusEnum.not_teacher, label: 'auth.signup.student', icon_id: 'student' },
    { role: TeacherStatusEnum.teacher, label: 'auth.signup.teacher', icon_id: 'teacher' },
  ];
  public userAlreadyRegister = false;
  public countriesOptions: IControlOption[];
  public countries: Country[];
  public selectedCountryCode: CountryISO;
  public pollOptions1 = Array.from({ length: 5 }, (_, i) => i + 1);
  public pollOptions2 = Array.from({ length: 10 }, (_, i) => i + 6);
  public svgSetIcon = IconsService.svgsetIconUrl;
  public codeLength = 5; // Длина отправленного кода

  private _mode: AuthModalModeType = 'social';
  private _socialUser: SocialUser;
  private libRouter = new LibraryRouter();
  private validateFormGroup = ProfileFormService.validateFormGroup;

  public constructor(
    private authService: AuthService,
    private profileService: ProfileService,
    private accountService: AccountService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private socialAuthService: SocialAuthService,
    private screenService: ScreenService,
    private analytics: AnalyticsService,
    protected configService: ConfigService,
    protected readonly platform: PlatformService,
    protected readonly cdr: ChangeDetectorRef,
    public deviceService: DeviceDetectorService,
  ) {
    super(cdr, platform);
    this.configService.load(['countries$']);
  }

  public ngOnInit(): void {
    if (!this.isLimitedVersion) {
      this.roleTypes.push({ role: -2, label: 'auth.signup.methodist', icon_id: 'methodist' }); // Is methodist, like teacher but with segment
    }
    this.socialAuthService.authState
      .pipe(
        tap((socialUser) => {
          this._socialUser = socialUser;
          if (socialUser) {
            this.completeSocialLogin(socialUser);
          } else {
            this.authService.logOut(true).then(() => {});
          }
        }),
        catchError(() => {
          this.action_error = 'auth.errors.signup.Authorization not possible';
          return of(null);
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe();

    this.createForm();

    if (this.standalone) {
      this.modalVisible = true;
      this.screenService.setBodyFixed(true);
      this.trackOpenEvent();
    } else {
      this.authService.modalVisible$.pipe(takeUntil(this.destroyed$)).subscribe((visible) => {
        this.modalVisible = visible;
        if (this.modalVisible) {
          this.screenService.setBodyFixed(true);
          this.setTimeout(() => this.trackOpenEvent(), 250);
        } else {
          this.screenService.setBodyFixed(false);
          this.trackCloseEvent();
        }
      });

      this.authService.modalOptions$.pipe(takeUntil(this.destroyed$)).subscribe((options) => {
        this.caller = options?.caller;
        this.reason = options?.reason;
        // TODO if (options?.mode) { this.mode = options?.mode };
        this.setTimeout(() => this.trackOpenEvent(), 250);
      });
      this.authService.modalMode$.pipe(takeUntil(this.destroyed$)).subscribe((mode) => {
        this.mode = mode;
      });
    }
    this.selectedRole = { role: null, label: 'auth.signup.choose-role', icon_id: 'role-question' };
    this.selectedOption = 0;
    for (let i = 1; i <= 16; i++) {
      this.pollOptions['option' + i] = false;
    }

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        takeUntil(this.destroyed$),
      )
      .subscribe((event: NavigationEnd) => {
        if (event.url === '/' || event.url === '/job') {
          this.libRouter.setBaseRoute(EContentPanelRoute.main);
          this.mainLink = this.libRouter.getLink();
        } else {
          this.mainLink = {
            path: event.url,
            parameters: {},
          };
        }
      });

    this.configService.countries$.pipe(takeUntil(this.destroyed$)).subscribe((countries) => {
      this.countries = countries;
      this.countriesOptions = countriesToOptions(this.countries);
      this.detectChanges();
    });

    this.checkGeo();
  }

  ngOnDestroy() {
    this.screenService.setBodyFixed(false);
    super.ngOnDestroy();
  }

  public checkGeo() {
    this.accountService
      .getGeo()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        if (res.country === 'RU' && this.isLimitedVersion) {
          this.hideSocialLinks = true;
          this.detectChanges();
        }
      });
  }

  public selectRoleType(type) {
    this.form.controls['teacher_status'].setValue(type.role);
    this.selectedRole = type;
    this.showRolesList = false;
  }

  public get isTeacherSelected() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    return this.selectedRole.role === TeacherStatusEnum.teacher;
  }

  public changeMode(mode: AuthModalModeType) {
    this.mode = mode;
    this.modeChanged.emit(this.mode);
  }

  public onCancel() {
    if (this.verifyPhone) {
      this.goToMain();
    }
    if (this.standalone) {
      this.authCanceled.emit();
    } else {
      this.authService.hideAuthModal();
      this.resetData();
    }
  }

  public resetData(withoutChangeMode?) {
    if (!withoutChangeMode) {
      this.changeMode('social');
    }
    this.form.reset();
    this.selectedRole = { role: null, label: 'auth.signup.choose-role', icon_id: 'role-question' };
    this.isCaptcha = false;
    this.isPhoneSuccess = false;
    this.isCodeError = false;
    this.verifyPhone = false;
    this.isPoll = false;
    this.isEmailAvailable = false;
    this.isPhoneAvailable = false;
    this.isSendAgain = false;
    this.showRoleValidation = false;
    this.userAlreadyRegister = false;
  }

  // вход через соц сети
  public get isSignInSocial(): boolean {
    return this._mode === 'social';
  }

  // вход через имейл
  public get isSignInEmail(): boolean {
    return this._mode === 'signin';
  }

  // регистрация через email
  public get isSignupEmail(): boolean {
    return this._mode === 'signup';
  }

  // подтверждения номера телефона
  public get isSMSFlow(): boolean {
    return this._mode === 'smsFlow';
  }

  // подтверждения email
  public get isEmailRestoredFlow(): boolean {
    return this._mode === 'emailRestoredFlow';
  }

  // восстановление пароля (шаг 1 процедуры восстановления пароля)
  public get isRestore(): boolean {
    return this._mode === 'restore';
  }

  // восстановление пароля
  public get isRestored(): boolean {
    return this._mode === 'restored';
  }

  private createForm(): void {
    this.form = this.fb.group(
      {
        teacher_status: [null, [Validators.required]],
        first_name: ['', [Validators.required]],
        last_name: ['', [Validators.required]],
        country_id: ['', [Validators.required]],
        mobile_phone: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email, this.conditionalEmailExistValidator.bind(this)]],
        password: ['', [Validators.required]],
        accept: [false, [Validators.required, Validators.requiredTrue]],
        mode: [this.mode || '', [Validators.required]],
        new_password: ['', [Validators.required]],
        confirm_new_password: ['', [Validators.required]],
        slug: [
          '',
          [Validators.required, Validators.pattern('[a-zA-Z0-9_.-]+'), this.conditionalSlugExistValidator.bind(this)],
        ],
      },
      {
        validator: MustMatch('new_password', 'confirm_new_password'),
      },
    );
  }

  private conditionalEmailExistValidator(control: UntypedFormControl): ValidationErrors | null {
    return this.email_exist ? { exist: true } : null;
  }

  private conditionalSlugExistValidator(control: UntypedFormControl): ValidationErrors | null {
    return this.slug_exist ? { exist: true } : null;
  }

  public checkRestoreStep(): void {
    if (this.isPhoneSuccess) {
      this.resetPassword();
    } else {
      this.doRestore();
    }
  }

  public doAction() {
    switch (this.mode) {
      case 'social':
        this.doSignIn();
        break;

      case 'signup':
        this.doTestSignup();
        break;

      case 'restore': // 1ый шаг восстановления доступа
        this.doRestore();
        break;

      default:
        break;
    }
  }

  public get firstNameWarn() {
    return this.form.controls.first_name.invalid;
  }

  public get lastNameWarn() {
    return this.form.controls.last_name.invalid;
  }

  public get phoneNumberWarn() {
    return this.form.controls.mobile_phone.invalid;
  }

  public get countryCodeWarn() {
    return this.form.controls.country_id.invalid;
  }

  public get emailWarn() {
    return this.form.controls.email?.errors?.required || this.form.controls.email?.errors?.email;
  }

  public get passwordWarn() {
    return this.form.controls.password.invalid;
  }

  public get newPasswordWarn() {
    return this.form.controls.new_password.invalid;
  }

  public get confirmNewPasswordWarn() {
    return this.form.controls.confirm_new_password.invalid;
  }

  public get acceptWarn() {
    return !this.form.controls.accept.value;
  }

  public get slugWarn() {
    return this.form.controls.slug?.errors?.required || this.form.controls.slug?.errors?.pattern;
  }

  public doSignIn() {
    if (!this.actionState.default) {
      return;
    }

    if (this.emailWarn || this.passwordWarn) {
      this.showValidation = true;
      this.showValidationEmailOnly = true;
      return;
    }

    this.actionState.progress = true;
    this.actionState.done = false;
    this.action_error = null;
    this.authService
      .signIn(this.form.controls.email.value, this.form.controls.password.value, true)
      .then(() => {
        this.analytics.event(EventActionEnum.signin_succeed, EventCategoryEnum.engagement, this.eventLabels);
        this.actionState.done = true;
        this.actionState.reset();
        this.authSucceed.emit();
        this.goToMain();
      })
      .catch((error) => {
        this.action_error = this.mapError('signin', error.error || error.toString());
        this.actionState.done = false;
        this.actionState.error = true;
        this.actionState.reset();
      })
      .finally(() => {
        this.actionState.progress = false;
      });
  }

  public doFacebookLogin() {
    if (this._socialUser) {
      this.completeSocialLogin(this._socialUser);
      return;
    }

    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then();
  }

  protected completeSocialLogin(socialUser: SocialUser) {
    let { network, network_app_id, access_token } = {} as any;

    switch (socialUser.provider) {
      case GoogleLoginProvider.PROVIDER_ID:
        network = SocialNetworkEnum.google;
        network_app_id = GoogleClientId;
        access_token = socialUser.idToken;
        break;

      case FacebookLoginProvider.PROVIDER_ID:
        network = SocialNetworkEnum.facebook;
        network_app_id = FacebookAppId;
        access_token = socialUser.authToken;
        break;

      default:
        return;
    }

    const values: ISocialSignupRequest = {
      network,
      network_app_id,
      network_account_id: socialUser.id,
      email: socialUser.email,
      first_name: socialUser.firstName,
      last_name: socialUser.lastName,
      picture: socialUser.photoUrl,
      access_token,
    };
    this.authService
      .socialSignup(values)
      .then(() => {
        this.analytics.event(EventActionEnum.signup_social_succeed, EventCategoryEnum.engagement, this.eventLabels);
        this.authSucceed.emit();
        this.actionState.progress = false;
        this.goToMain();
      })
      .catch((error) => {
        this.action_error = this.mapError('signup', error.model || error.error || error.toString());
        this.actionState.progress = false;
        this._socialUser = null;
      });
  }

  public doTestSignup(): void {
    if (!this.actionState.default) {
      return;
    }

    if (this.selectedRole.role === null) {
      this.showRoleValidation = true;
      this.showValidation = false;
      return;
    }

    if (
      this.firstNameWarn ||
      this.lastNameWarn ||
      this.emailWarn ||
      this.countryCodeWarn ||
      this.phoneNumberWarn ||
      this.passwordWarn ||
      this.acceptWarn
    ) {
      this.showValidation = true;
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    if (this.selectedRole.role === TeacherStatusEnum.teacher && this.slugWarn) {
      this.showValidation = true;
      return;
    }

    this.actionState.progress = true;
    this.actionState.done = false;
    this.action_error = null;
    const values: ISignupRequest = {
      email: this.form.controls.email.value,
      country_id: this.form.controls.country_id.value,
      mobile_phone: this.formattedPhoneNumber,
      password: '',
      is_test_query: true,
    };

    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    if (this.selectedRole.role === TeacherStatusEnum.teacher) {
      values.slug = this.form.controls.slug.value;
    }

    if (this.userAlreadyRegister) {
      this.actionState.done = true;
      this.actionState.reset();
      this.isCaptcha = true;
      this.actionState.progress = false;
      return;
    }
    this.authService
      .signup(values)
      .then(() => {
        this.actionState.done = true;
        this.actionState.reset();
        this.isCaptcha = true;
        this.email_exist = false;
        this.slug_exist = false;
        this.validateFormGroup(this.form);

        /*
        this.setTimeout(() => {
          this.isCaptcha = false;
          this.verifyPhone = true;
          this.resolvedCaptcha('smsFlow');
        }, 2000);
        */
      })
      .catch((error) => {
        this.action_error = this.mapError('signup', error.model || error.error || error.toString());

        this.showValidation = false;
        this.email_exist = false;
        this.slug_exist = false;

        if (error.model === 'email') {
          this.showValidation = true;
          this.email_exist = true;
        }

        if (error.model === 'slug') {
          this.showValidation = true;
          this.slug_exist = true;
        }

        this.validateFormGroup(this.form);

        this.actionState.done = false;
        this.actionState.error = true;
        this.actionState.reset();
      })
      .finally(() => {
        this.actionState.progress = false;
      });
  }

  public doSignup() {
    if (!this.actionState.default) {
      return;
    }
    if (
      this.firstNameWarn ||
      this.lastNameWarn ||
      this.emailWarn ||
      this.countryCodeWarn ||
      this.phoneNumberWarn ||
      this.passwordWarn ||
      this.acceptWarn
    ) {
      this.showValidation = true;
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    if (this.selectedRole.role === TeacherStatusEnum.teacher && this.slugWarn) {
      this.showValidation = true;
      return;
    }

    this.actionState.progress = true;
    this.actionState.done = false;
    this.action_error = null;
    const values: ISignupRequest = {
      teacher_status: this.form.controls.teacher_status.value as TeacherStatusEnum,
      email: this.form.controls.email.value,
      mobile_phone: this.formattedPhoneNumber,
      country_id: this.form.controls.country_id.value,
      first_name: this.form.controls.first_name.value,
      last_name: this.form.controls.last_name.value,
      password: this.form.controls.password.value,
    };

    // Проверка если методист - ставим статус учителя и добавляем сегмент "Методист"
    if (values.teacher_status === (-2 as TeacherStatusEnum)) {
      values.teacher_status = 2;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    if (this.selectedRole.role === TeacherStatusEnum.teacher) {
      values.slug = this.form.controls.slug.value;
    }

    if (this.userAlreadyRegister) {
      this.setSegments(values);
      return;
    }

    this.authService
      .signup(values)
      .then(() => {
        this.analytics.event(EventActionEnum.signup_succeed, EventCategoryEnum.engagement, this.eventLabels);
        this.userAlreadyRegister = true;
        this.setSegments(values);
        this.authSucceed.emit();
      })
      .catch((error) => {
        this.action_error = this.mapError('signup', error.model || error.error || error.toString());
        this.actionState.done = false;
        this.actionState.error = true;
        this.actionState.reset();
      })
      .finally(() => {
        this.actionState.progress = false;
      });
  }

  public sendCode() {
    this.profileService
      .changeMobilePhone(this.formattedPhoneNumber)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          this.actionState.done = true;
          this.actionState.progress = false;
          this.actionState.reset();
          this.codeLength = res.code_length;
          this.restoreComplete = true;
          this.verify_code_response_info = res;
          this.changeMode('smsFlow');
        },
        error: (error) => {
          this.action_error = this.mapError('restore', error.error || error.toString());
          this.actionState.done = false;
          this.actionState.error = true;
          this.actionState.progress = false;
          this.actionState.reset();
        },
      });
  }

  public setSegments(values: ISignupRequest) {
    if (values.teacher_status === (2 as TeacherStatusEnum)) {
      combineLatest([
        this.accountService.removeSegment(UserSegmentEnum.as_if_teacher),
        this.accountService.removeSegment(UserSegmentEnum.as_if_student),
        this.accountService.addSegment(UserSegmentEnum.as_if_methodist),
      ])
        .pipe(takeUntil(this.destroyed$))
        .subscribe();
    } else if (values.teacher_status === TeacherStatusEnum.teacher) {
      combineLatest([
        this.accountService.removeSegment(UserSegmentEnum.as_if_methodist),
        this.accountService.removeSegment(UserSegmentEnum.as_if_student),
        this.accountService.addSegment(UserSegmentEnum.as_if_teacher),
      ])
        .pipe(takeUntil(this.destroyed$))
        .subscribe();
    } else if (values.teacher_status === TeacherStatusEnum.not_teacher) {
      combineLatest([
        this.accountService.removeSegment(UserSegmentEnum.as_if_methodist),
        this.accountService.removeSegment(UserSegmentEnum.as_if_teacher),
        this.accountService.addSegment(UserSegmentEnum.as_if_student),
      ])
        .pipe(takeUntil(this.destroyed$))
        .subscribe();
    }
    this.accountService
      .updateAccount(values)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.sendCode();
        this.actionState.progress = false;
      });
  }

  public doRestore() {
    this.showValidation = false;
    if (!this.actionState.default) {
      return;
    }
    if (this.emailWarn) {
      this.showValidation = true;
      return;
    }

    const data = { email: this.form.controls.email.value };
    if (this.isPhoneAvailable) {
      data['mobile_phone'] = this.formattedPhoneNumber;
    }

    this.actionState.progress = true;
    this.actionState.done = false;
    this.action_error = null;
    this.authService
      .resetPassword(data)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          this.actionState.done = true;
          this.actionState.progress = false;
          this.actionState.reset();
          this.restoreComplete = true;
          this.verify_code_response_info = res;
          this.changeMode('smsFlow');
        },
        error: () => {
          if (this.isPhoneAvailable) {
            this.action_error = this.mapError('restore', 'Wrong phone number');
          } else {
            this.action_error = this.mapError('restore', 'Wrong E-mail address');
          }

          this.actionState.done = false;
          this.actionState.progress = false;
          this.actionState.error = true;
          this.actionState.reset();
        },
      });
  }

  protected mapError(mode, error): string {
    const _error = `auth.errors.${mode}.${error}`;

    switch (_error) {
      case 'auth.errors.signin.Account not found':
      case 'auth.errors.signin.Wrong password':
      case 'auth.errors.signup.email':
      case 'auth.errors.signup.slug':
      case 'auth.errors.restore.Wrong phone number':
      case 'auth.errors.restore.Wrong E-mail address':
        return _error;
    }

    return error;
  }

  public resolvedCaptcha(mode) {
    if (mode === 'restore') {
      this.changeMode(mode);
    }

    if (mode === 'smsFlow') {
      this.doSignup();
    }
  }

  public setTypeOfRestore(type: string) {
    this.isChooseRestoreType = false;
    this.isEmailAvailable = type === 'email';
    this.isPhoneAvailable = type === 'mobile';
  }

  public forgotPasswordCheckAvailability() {
    this.showValidation = false;
    if (this.emailWarn) {
      this.showValidationEmailOnly = true;
      return;
    }

    this.action_error = null;
    this.isCaptcha = false;
    this.authService
      .resetPasswordAvailability({ email: this.form.controls['email'].value })
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res: IChangePasswordAvailabilityRequest) => {
          if (res.email_available || res.phone_available) {
            this.isChooseRestoreType = true;
            this.isCaptcha = true;
          }
        },
        error: () => {
          this.action_error = this.mapError('signin', 'auth.errors.signin.Account not found');
          this.actionState.done = false;
          this.actionState.error = true;
          this.actionState.reset();
        },
      });
  }

  public onOtpChange(event: string, type: string) {
    this.isCodeError = false;
    if (event.length === this.codeLength) {
      this.verified_code = event;
      if (this.verifyPhone) {
        this.profileService
          .confirmMobilePhone(this.verify_code_response_info['id'], event)
          .pipe(takeUntil(this.destroyed$))
          .subscribe({
            next: (res) => {
              this.actionState.done = true;
              this.actionState.progress = false;
              this.actionState.reset();
              this.restoreComplete = true;
              if (res.status === 'changed') {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
                if (this.selectedRole.role === TeacherStatusEnum.not_teacher) {
                  this.goToMain();
                } else {
                  this.isPoll = true;
                }
              } else {
                this.isCodeError = true;
              }
            },
            error: (error) => {
              this.action_error = this.mapError('restore', error.error || error.toString());
              this.actionState.done = false;
              this.actionState.progress = false;
              this.actionState.error = true;
              this.actionState.reset();
            },
          });
      } else {
        this.authService
          .checkResetPassword({
            id: this.verify_code_response_info['id'],
            code: event,
          })
          .pipe(takeUntil(this.destroyed$))
          .subscribe({
            next: (res) => {
              this.actionState.done = true;
              this.actionState.progress = false;
              this.actionState.reset();
              this.restoreComplete = true;
              if (res.status === 'changed' || res.status === 'code_required') {
                this.changeMode('restore');
                this.isPhoneSuccess = true;
              } else {
                this.isCodeError = true;
              }
            },
            error: (error) => {
              this.action_error = this.mapError('restore', error.error || error.toString());
              this.actionState.done = false;
              this.actionState.progress = false;
              this.actionState.error = true;
              this.actionState.reset();
            },
          });
      }
    }
  }

  public sendCodeAgain() {
    if (this.verifyPhone) {
      this.profileService
        .resendMobilePhoneChange(this.verify_code_response_info['id'])
        .pipe(first(), takeUntil(this.destroyed$))
        .subscribe(() => {
          this.isSendAgain = false;
        });
    } else {
      const data = { email: this.form.controls.email.value };
      if (this.isPhoneAvailable) {
        data['mobile_phone'] = this.formattedPhoneNumber;
      }
      this.authService
        .resetPassword(data)
        .pipe(first(), takeUntil(this.destroyed$))
        .subscribe((res) => {
          this.isSendAgain = false;
          this.verify_code_response_info = res;
        });
    }
  }

  public over(option: number) {
    this.selectedOption = option;
  }

  public back() {
    this.isCaptcha = false;

    switch (this.mode) {
      case 'social':
        break;
      case 'signup':
        this.changeMode('social');
        break;
      case 'smsFlow':
        if (this.verifyPhone) {
          this.changeMode('signup');
        } else {
          this.changeMode('restore');
        }
        break;
      case 'restore':
        if (this.isPhoneSuccess) {
          this.isPhoneSuccess = false;
          return;
        }
        if (!this.isChooseRestoreType) {
          this.isChooseRestoreType = true;
          this.action_error = null;
          return;
        }
        this.changeMode('social');
        break;
    }
  }

  public goToMain() {
    if (this.standalone) {
      // должно обрабатываться "снаружи" через сигналы authSucceed и authCanceled
      // <app-auth-modal (authSucceed)="..." (authCanceled)="...">
    } else {
      this.router.navigate([this.mainLink.path], { queryParams: this.mainLink.parameters }).then(() => {
        this.authService.hideAuthModal();
        this.resetData();
      });
    }
  }

  public onTimerFinish(e) {
    if (e['action'] === 'done') {
      this.isSendAgain = true;
    }
  }

  public resetPassword() {
    if (!this.actionState.default) {
      return;
    }
    if (this.newPasswordWarn || this.confirmNewPasswordWarn) {
      this.showValidation = true;
      return;
    }

    this.authService
      .confirmResetPassword({
        id: this.verify_code_response_info['id'],
        code: this.verified_code,
        password: this.form.controls['new_password'].value,
      })
      .pipe(first(), takeUntil(this.destroyed$))
      .subscribe(() => {
        this.changeMode('restored');
        this.resetData(true);
      });
  }

  public checkOption(checked: boolean, option: number) {
    this.pollOptions['option' + option] = checked;
  }

  public createQuiz() {
    this.accountService
      .createQuiz('poll-form', this.pollOptions)
      .pipe(first(), takeUntil(this.destroyed$))
      .subscribe(() => {
        this.goToMain();
      });
  }

  public get formattedPhoneNumber(): string {
    return this.form.controls.mobile_phone.value?.e164Number;
  }

  public get isLimitedVersion() {
    return FeaturesService.isLimitedVersion;
  }

  public get showBackButton(): boolean {
    return (this.isCaptcha || !this.isSignInSocial) && !this.isRestored && !this.isPoll;
  }

  public countryChanged(event) {
    const selectedCountry2Code = this.countries.find((country) => country.id === event).alpha2Code.toLowerCase();
    const enumKey = getEnumKeyByEnumValue(CountryISO, selectedCountry2Code);
    this.selectedCountryCode = CountryISO[enumKey];
  }

  protected get eventLabels(): string[] {
    return [this.caller, this.reason];
  }

  private openEventTracked = false;
  private trackOpenEvent() {
    if (this.modalVisible) {
      if (!this.openEventTracked) {
        this.openEventTracked = true;
        this.analytics.event(EventActionEnum.auth_opened, EventCategoryEnum.engagement, this.eventLabels);
      }
    } else {
      this.openEventTracked = false;
    }
  }

  private trackCloseEvent() {
    this.openEventTracked = false;
  }
}

export interface IRoleReferences {
  role: number;
  label: string;
  icon_id: string;
}
