<div class="shade" [class.visible]="visible" (click)="onToggleSidebar()"></div>

<div class="right-sidebar" [class.floating]="floating" [class.visible]="visible" [class.hidden]="isSystemPages">
  <div class="right-sidebar-close"
       [attr.data-test] = "'right-sidebar-close'"
       [class.visible]="visible"
       (click)="onToggleSidebar()" >
    <svg width="56" height="128" viewBox="0 0 56 128">
      <use [attr.xlink:href]="svgsetIcon('sidebar-lug')"></use>
    </svg>
    <div class="close-btn" [class.visible]="visible">
      <div class="top"></div>
      <div class="bottom"></div>
    </div>
  </div>
  <div class="right-sidebar-container">
    <router-outlet #routerOutlet="outlet" name='right-sidebar-top'></router-outlet>

    <div *ngIf="!isOutletActivated(routerOutlet)">
      <!--
      No context content for right sidebar at this page
      -->
    </div>
  </div>
</div>
