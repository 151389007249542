import { HttpParams, HttpResponse } from '@angular/common/http';
import { IPagedResults, IPagination } from 'lingo2-models';

export function createPagedResultsFromResponse<T>(
  response: HttpResponse<T[]>,
  mapper?: (value: T) => T,
): IPagedResults<T[]> {
  let results = response.body;
  if (mapper) {
    results = results.map(mapper);
  }
  return {
    results,
    pagination: {
      page: +response.headers.get('X-Pagination-Page'),
      pageSize: +response.headers.get('X-Pagination-PageSize'),
      total: +response.headers.get('X-Pagination-Total'),
      totalPages: +response.headers.get('X-Pagination-TotalPages'),
      time: new Date(),
    },
    page: +response.headers.get('X-Pagination-Page'),
    pageSize: +response.headers.get('X-Pagination-PageSize'),
    total: +response.headers.get('X-Pagination-Total'),
    totalPages: +response.headers.get('X-Pagination-TotalPages'),
  };
}

const defaultPagination: IPagination = {
  page: 1,
  pageSize: 10,
  total: 0,
  totalPages: 0,
};

export function importPaginationToRequest(pagination: IPagination = defaultPagination) {
  const params = new HttpParams()
    .set('page', pagination.page.toString())
    .set('page-size', pagination.pageSize.toString());
  return params;
}
