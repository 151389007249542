import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createPagedResultsFromResponse } from '@core/helpers/request';
import { AccountService } from '@core/services';
import { IContentBlock } from '@core/services/interfaces';
import { environment } from '@env/environment';
import { IPagedResults, IPagination, otherSubjectId, UserServiceDetailsType, IStatusResponse } from 'lingo2-models';
import { IFindUserServicesFilter, UserService } from 'lingo2-models';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

export type ServicesBlockType = IContentBlock<UserService, IFindUserServicesFilter>;

export const defaultUserServiceDetails: UserServiceDetailsType[] = [
  'cover:md',
  'description',
  'excerpt',
  'id',
  'language',
  'price_tier',
  'school',
  'slug',
  'subject',
  'title',
];

export const fullUserServiceDetails: UserServiceDetailsType[] = [
  'archived_at',
  'author:sm',
  'author_id',
  'can',
  'category',
  'category_id',
  'cover',
  'cover_id',
  'created_at',
  'description',
  'discount',
  'discount_rule',
  'duration',
  'excerpt',
  'id',
  'is',
  'keywords',
  'language',
  'language_id',
  'level',
  'level_id',
  'media_url',
  'moderation_message',
  'moderation_status',
  'options',
  'price_tier',
  'promotion',
  'promotion_status',
  'published_at',
  'participants_limit',
  'teachers',
  'school',
  'school:cover',
  'slug',
  'stats',
  'visit_info',
  'subject',
  'subject_id',
  'subject_other_id',
  'subject_other_name',
  'teacher_ids',
  'teachers',
  'title',
  'type',
];

@Injectable({
  providedIn: 'root',
})
export class UserServicesService {
  constructor(private http: HttpClient) {}

  public userServiceFullTitle(userService: UserService): string {
    if (!userService) {
      return null;
    }

    let subject = '';
    if ('subject' in userService) {
      if (+userService.subject?.id === otherSubjectId) {
        subject = userService.subject_other_name || '---';
      } else {
        subject = (userService.subject ? userService.subject.title : null) || '---';
      }
    }
    // const level = userService.level
    //   ? userService.level.title
    //   : this.translate.instant('subject-levels.any-level');

    const authorName = AccountService.getUserFullName(userService.author);

    // title subject date level author | classes -- OnClass
    const titleParts = [userService.title, subject, userService.category?.title];
    // titleParts.push(', ', level);
    if (authorName) {
      titleParts.push(authorName);
    }
    return titleParts.join(', ');
  }

  public getServices(
    filter: Partial<IFindUserServicesFilter>,
    pagination: IPagination,
    details?: UserServiceDetailsType[],
  ): Observable<IPagedResults<UserService[]>> {
    const url = `${environment.content_url}/services`;
    let params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter', JSON.stringify(filter));
    if (details && details.length) {
      params = params.set('details', JSON.stringify(details));
    }
    return this.http.get<UserService[]>(url, { params, observe: 'response' }).pipe(
      map(this.handleUserServicesResponse),
      // catchError(this.handleError),
    );
  }

  public getById(id: string, details?: UserServiceDetailsType[]): Observable<UserService> {
    const url = `${environment.content_url}/services/${id}`;
    let params = new HttpParams();
    if (details && details.length) {
      params = params.set('details', JSON.stringify(details));
    }
    return this.http.get<UserService>(url, { params, observe: 'response' }).pipe(
      map(this.handleUserServiceResponse),
      // catchError(this.handleError),
    );
  }

  public getServiceBySlug(slug: string, details?: UserServiceDetailsType[]): Observable<UserService> {
    const _slug = slug.split('-').reverse()[0];
    const url = `${environment.content_url}/services/u/${_slug}`;
    let params = new HttpParams();
    if (details && details.length) {
      params = params.set('details', JSON.stringify(details));
    }
    return this.http.get<UserService>(url, { params, observe: 'response' }).pipe(
      map(this.handleUserServiceResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Создать запись
   */
  public createUserService(values: Partial<UserService>): Observable<UserService> {
    const url = `${environment.content_url}/services`;
    return this.http.post<UserService>(url, values, { observe: 'response' }).pipe(
      map(this.handleUserServiceResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Обновить запись
   */
  public updateUserService(id: string, values: Partial<UserService>): Observable<UserService> {
    const url = `${environment.content_url}/services/${id}`;
    return this.http.put<UserService>(url, values, { observe: 'response' }).pipe(
      map(this.handleUserServiceResponse),
      // catchError(this.handleError),
    );
  }

  /**
   * Удалить запись
   */
  public removeUserService(id: string): Observable<UserService> {
    const url = `${environment.content_url}/services/${id}`;
    return this.http.delete<UserService>(url, { observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  /**
   * Восстановить запись
   */
  public restoreUserService(id: string): Observable<UserService> {
    const url = `${environment.content_url}/services/${id}/restore`;
    return this.http.put<UserService>(url, {}, { observe: 'response' }).pipe(map((response) => response.body));
  }

  /**
   * Добавить в закладки
   */
  public addBookmark(id: string): Observable<IStatusResponse> {
    const url = `${environment.content_url}/services/${id}/bookmark`;
    return this.http.post<IStatusResponse>(url, {}, { observe: 'body' });
  }

  /**
   * Удалить из закладок
   */
  public removeBookmark(id: string): Observable<IStatusResponse> {
    const url = `${environment.content_url}/services/${id}/bookmark`;
    return this.http.delete<IStatusResponse>(url, { observe: 'body' });
  }

  private handleUserServiceResponse(response: HttpResponse<UserService>): UserService {
    return new UserService(response.body);
  }

  private handleUserServicesResponse(response: HttpResponse<UserService[]>): IPagedResults<UserService[]> {
    return createPagedResultsFromResponse(response, (values) => new UserService(values));
  }

  private handleError(error: HttpErrorResponse) {
    console.error('UserServicesService:server error:', error);
    if (error.error instanceof Error) {
      const errMessage = error.error.message;
      return throwError(errMessage);
      // Use the following instead if using lite-server
      // return throwError(err.text() || 'backend server error');
    }
    return throwError(error || 'Node.js server error');
  }
}
