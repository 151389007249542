<!-- @see supportedNotices -->
<div class="notification notification_{{base.modifier}}" [class.notification_has-close]="hasClose$ | async">
  <div class="notification__header">
    <span class="notification__title">
      <div class="icon-wrap">
        <svg class="icon" width="20" height="20" viewBox="0 0 20 20">
          <use [attr.xlink:href]="svgSetIcon(base.icon)"></use>
        </svg>
      </div>
      {{ base.title | translate }}
    </span>

    <span class="notification__time"> {{ base.created_at | date : 'HH:mm' }} </span>

    <span class="notification__close" (click)="close()">
      <svg class="icon" width="12" height="12" viewBox="0 0 12 12">
        <use [attr.xlink:href]="svgSetIcon('close-sm')"></use>
      </svg>
    </span>
  </div>

  <div class="notification__content">
    <ng-content></ng-content>
  </div>
</div>
