import { Injectable } from '@angular/core';
import { DestroyableComponent } from '@models/destroyable.component';
import { BehaviorSubject } from 'rxjs';

const _interval = 150;

@Injectable({
  providedIn: 'root',
})
export class LoadingBarService extends DestroyableComponent {
  protected navigationVisibleSubject = this.register(new BehaviorSubject<boolean>(false));
  public navigationVisible$ = this.navigationVisibleSubject.asObservable();

  protected navigationPercentSubject = this.register(new BehaviorSubject<number>(0));
  public navigationPercent$ = this.navigationPercentSubject.asObservable();
  private _navigationPercent = 0;

  protected fetchingVisibleSubject = this.register(new BehaviorSubject<boolean>(false));
  public fetchingVisible$ = this.fetchingVisibleSubject.asObservable();

  protected fetchingPercentSubject = this.register(new BehaviorSubject<number>(0));
  public fetchingPercent$ = this.fetchingPercentSubject.asObservable();
  private _fetchingPercent = 0;

  public navigationStart(percent = 0) {
    this._navigationPercent = 0;
    this.navigationVisibleSubject.next(true);
    this.navigationPercentSubject.next(this._navigationPercent);
    if (percent > 0) {
      this.navigationProgress(percent);
    }
  }

  public navigationProgress(percent: number) {
    const _percent = Math.min(100, Math.max(0, percent));
    if (_percent < this._navigationPercent) {
      return;
    }
    const _percent1 = (_percent + this._navigationPercent) / 2;
    const _percent2 = _percent;
    this._navigationPercent = _percent;

    this.setTimeout(() => {
      this.navigationPercentSubject.next(_percent1);
    }, _interval);
    this.setTimeout(() => {
      this.navigationPercentSubject.next(_percent2);
    }, _interval * 2);
  }

  public navigationComplete() {
    this.navigationProgress(100);
    this.setTimeout(() => {
      this._navigationPercent = 0;
      this.navigationVisibleSubject.next(false);
      this.navigationPercentSubject.next(this._navigationPercent);
    }, _interval * 3);
  }

  public navigationStop() {
    this.navigationVisibleSubject.next(false);
    this.setTimeout(() => {
      this._navigationPercent = 0;
      this.navigationPercentSubject.next(this._navigationPercent);
    }, _interval);
  }

  public fetchingStart(percent = 0) {
    this._fetchingPercent = 0;
    this.fetchingVisibleSubject.next(true);
    this.fetchingPercentSubject.next(this._fetchingPercent);

    if (percent > 0) {
      this.fetchingProgress(percent);
    }
  }

  public fetchingProgress(percent: number) {
    const _percent = Math.min(100, Math.max(0, percent));
    if (_percent < this._fetchingPercent) {
      return;
    }
    const _percent1 = (_percent + this._fetchingPercent) / 2;
    const _percent2 = _percent;
    this._fetchingPercent = _percent;

    this.setTimeout(() => {
      this.fetchingPercentSubject.next(_percent1);
    }, _interval);
    this.setTimeout(() => {
      this.fetchingPercentSubject.next(_percent2);
    }, _interval * 2);
  }

  public fetchingComplete(percent = 100) {
    this.fetchingProgress(percent);

    this.setTimeout(() => {
      this._fetchingPercent = 0;
      this.fetchingVisibleSubject.next(false);
      this.fetchingPercentSubject.next(this._fetchingPercent);
    }, _interval * 3);
  }

  public fetchingStop() {
    this.fetchingVisibleSubject.next(false);
    this.setTimeout(() => {
      this._fetchingPercent = 0;
      this.fetchingPercentSubject.next(this._fetchingPercent);
    }, _interval);
  }
}
