<div class="not-verified">
  <span class="not-verified__title">
    <b>
      {{ 'not-verified-widget.title-1' | translate }} <span class="red"> {{ 'not-verified-widget.title-2' | translate }}</span>
    </b>
  </span>

  <ul class="not-verified__list">
    <li>- {{ 'not-verified-widget.text-1' | translate }}</li>
    <li>- {{ 'not-verified-widget.text-2' | translate }}</li>
    <li>- {{ 'not-verified-widget.text-3' | translate }} <span class="link blue">{{ 'not-verified-widget.about-subscription' | translate }}</span></li>
    <li>- {{ 'not-verified-widget.text-4' | translate }}</li>
  </ul>

  <div class="not-verified__description">
    <span class="red">{{ 'not-verified-widget.description-1' | translate }}</span> 
    {{ 'not-verified-widget.description-2' | translate }} 
    <span class="green">{{ 'not-verified-widget.description-3' | translate }}</span> 
    {{ 'not-verified-widget.description-4' | translate }}
  </div>

</div>