import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { UserRoles } from '@app/first-contact/first-contact.component';
import { PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { titles, capabilitiesList } from './first-contact-capabilities.variables';

@Component({
  selector: 'app-first-contact-capabilities',
  templateUrl: './first-contact-capabilities.component.html',
  styleUrls: ['./first-contact-capabilities.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirstContactCapabilitiesComponent extends ChangableComponent {
  @Input() selectedRole: UserRoles;
  public titles = titles;
  public capabilitiesList = capabilitiesList;

  constructor(
    protected readonly cdr: ChangeDetectorRef,
    public deviceService: DeviceDetectorService,
    protected readonly platform?: PlatformService,
  ) {
    super(cdr, platform);
  }

  public title(): string {
    return this.titles[this.selectedRole];
  }

  public capabilities() {
    return this.capabilitiesList[this.selectedRole];
  }
}
