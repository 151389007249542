<div class="achievements" *ngIf="isAvailableOnboardingTrackers">

  <div class="achievements__charts">
    <!-- Meetings -->
    <app-onboarding-donut-chart class="chart" *ngIf="meetingsTracker as tracker" color="#41A1D9" [max]="tracker.limit"
      [value]="tracker.value">
    </app-onboarding-donut-chart>

    <!-- Lessons -->
    <app-onboarding-donut-chart class="chart" *ngIf="lessonsTracker as tracker" color="#87D37C" [max]="tracker.limit"
      [value]="tracker.value">
    </app-onboarding-donut-chart>

  </div>

  <div class="achievements__days-left" *ngIf="dueDate">
    {{ 'onboarding.time-left' | translate }}: {{ remainDays | dfnsFormatDistanceToNow }}
  </div>

  <div class="achievements__legend">

    <div class="legend legend_meetings">
      <div class="legend__dot"></div>
      {{ (dueDate ? 'onboarding.classes-hosted-per-month' : 'onboarding.classes-hosted') | translate }}
    </div>

    <div class="legend legend_lessons">
      <div class="legend__dot"></div>
      {{ (dueDate ? 'onboarding.lessons-created-per-month' : 'onboarding.lessons-created') | translate }}
    </div>

  </div>

</div>
