<div class="top" [class.mobile]="deviceService.isMobile()">
  <div class="title">{{ 'finance.card-create.title' | translate }}</div>
  <div class="sub-title">{{ 'finance.card-create.sub-title' | translate }}</div>
</div>

<spinner2 *ngIf="loading"></spinner2>

<div *ngIf="error" class="error text-center">{{ error | translate }}</div>

<ng-template [ngIf]="loaded">
  <div class="frame-wrapper" [class.mobile]="deviceService.isMobile()">
    <p>{{ 'finance.card-create.to-confirm' | translate }}</p>

    <iframe [class.mobile]="deviceService.isMobile()" *ngIf="safeTinkoffPaymentUrl" [src]="safeTinkoffPaymentUrl" frameborder="0"
            _allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" _allowfullscreen
            class="tinkoff-frame">
    </iframe>
  </div>

  <!--
  <div class="actions">
    <app-button
      state="default"
      class="success"
      [label]="'Подтвердить'"
      (click)="authorizeCard()">
    </app-button>
  </div>
  -->
</ng-template>
