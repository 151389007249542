import { Directive, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { RelativeUrlPipe } from '@core/components/notification/pipes/relative-url.pipe';

@Directive({
  selector: '[clickToRelativeUrl]',
})
export class ClickToRelativeUrlDirective {
  @HostListener('click', ['$event'])
  public onClick($event) {
    $event.preventDefault();
    try {
      const element: HTMLElement = $event.target;
      const href = element?.getAttribute('href');
      if (!href) {
        return;
      }

      const link = new RelativeUrlPipe().transform(href);
      void this.router.navigate([link]);
    } catch (e) {
      //
    }
  }

  public constructor(private router: Router) {}
}
