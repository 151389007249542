import { Injectable } from '@angular/core';
import { DestroyableComponent } from '@models/destroyable.component';
import { LessonSlideImageAspectRatioEnum } from 'lingo2-models';
import { BehaviorSubject } from 'rxjs';

export interface LessonSlideGrid {
  width: number;
  height: number;
}

@Injectable()
export class UtilsService extends DestroyableComponent {
  public lessonSlideAspectRatio = this.register(
    new BehaviorSubject<LessonSlideImageAspectRatioEnum>(LessonSlideImageAspectRatioEnum.square),
  );
  public lessonSlideGrid = this.register(new BehaviorSubject<LessonSlideGrid>({ width: 1, height: 1 }));
  public lessonSlideAspectRatio$ = this.lessonSlideAspectRatio.asObservable();
  public lessonSlideGrid$ = this.lessonSlideGrid.asObservable();

  public lessonSlideActions = this.register(new BehaviorSubject<boolean>(false));
  public lessonSlideActions$ = this.lessonSlideActions.asObservable();
}
