import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { StepperModule } from '@app/content-editor/content-form-page/_components';
import { DropzoneModule } from '@app/content-editor/content-form-page/constructor-form-page/slide-form-page/_shared';
import { AlertModule } from '@core/components/alert/alert.module';
import { MobileModalModule } from '@core/components/mobile-modal/mobile-modal.module';
import { ModalModule } from '@core/components/modal/modal.module';
import { SpinnersModule } from '@core/components/spinners/spinners.module';
import { TranslateModule } from '@ngx-translate/core';
import { PaymentWidgetMerchantProcessingModule } from '@shared/checkout-wizards/components/payment-widget/payment-widget-merchant-processing/payment-widget-merchant-processing.module';
import { NgxIntlPhoneInputModule } from 'ngx-intl-phone-input';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import {
  OnUiButtonModule,
  OnUiCheckboxModule,
  OnUiDateModule,
  OnUiFormFieldErrorModule,
  OnUiInputModule,
  OnUiSelectModule,
  OnUiTabsModule,
} from 'onclass-ui';
import { FinanceCardComponent } from './finance-card/finance-card.component';
import { FinanceCardCreateComponent } from './finance-card-create/finance-card-create.component';
import { FinanceCardCreateNewComponent } from './finance-card-create-new/finance-card-create-new.component';
import { FinanceCardsPageComponent } from './finance-cards-page.component';

@NgModule({
  declarations: [
    FinanceCardsPageComponent,
    FinanceCardComponent,
    FinanceCardCreateComponent,
    FinanceCardCreateNewComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    ModalModule,
    MobileModalModule,
    SpinnersModule,
    AlertModule,
    OnUiButtonModule,
    StepperModule,
    OnUiInputModule,
    FormsModule,
    ReactiveFormsModule,
    OnUiDateModule,
    OnUiSelectModule,
    DropzoneModule,
    OnUiTabsModule,
    PaymentWidgetMerchantProcessingModule,
    OnUiCheckboxModule,
    OnUiFormFieldErrorModule,
    NgxIntlPhoneInputModule,
  ],
  providers: [NgxMaskDirective, NgxMaskPipe],
  exports: [FinanceCardsPageComponent, FinanceCardComponent, FinanceCardCreateComponent, FinanceCardCreateNewComponent],
})
export class FinanceCardsPageModule {}
