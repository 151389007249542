import { Component } from '@angular/core';
import { LoadingBarService } from './loading-bar.service';

@Component({
  selector: 'app-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss'],
})
export class LoadingBarComponent {
  public navigationVisible$ = this.loadingBar.navigationVisible$;
  public navigationPercent$ = this.loadingBar.navigationPercent$;
  public fetchingVisible$ = this.loadingBar.fetchingVisible$;
  public fetchingPercent$ = this.loadingBar.fetchingPercent$;

  constructor(private loadingBar: LoadingBarService) {}
}
