import { transition, trigger, useAnimation } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LessonSlideGrid, UtilsService } from '@app/content-editor/content-form-page/_services';
import { logger } from '@app/core/helpers/logger';
import { fadeIn, fadeOut } from '@core/animations';
import { ContextService, FilesService, IconsService, IFileType } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { FilePurposeEnum, ImageSizeEnum, LessonSlideImageAspectRatioEnum } from 'lingo2-models';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-slide-image',
  templateUrl: './slide-image.component.html',
  styleUrls: ['./slide-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SlideImageComponent),
      multi: true,
    },
  ],
  animations: [
    trigger('spinner', [
      transition(':enter', useAnimation(fadeIn, { params: { time: '200ms', slide: '5px' } })),
      transition(':leave', useAnimation(fadeOut)),
    ]),
  ],
})
export class SlideImageComponent extends ChangableComponent implements ControlValueAccessor, OnInit {
  @Input() isError = false;
  // TODO: отрефакторить работу с модалками
  @Input() isModalOpened: boolean;
  @Output() modalOpened = new EventEmitter<boolean>();

  @Output() changed = new EventEmitter<string>();

  public value: string;
  public isDisabled: boolean;

  public svgsetIcon = IconsService.svgsetIconUrl;

  public isMediaPrepared = false;
  public isLoading = false;
  public file: IFileType;
  public imageUrl: SafeResourceUrl;
  public filePurpose = FilePurposeEnum.content;
  public showCropImage = false;
  public original_file: IFileType; // Оригинальное изображение
  public aspectRatio: LessonSlideImageAspectRatioEnum;
  public grid: LessonSlideGrid;

  constructor(
    protected sanitizer: DomSanitizer,
    protected filesService: FilesService,
    protected utilsService: UtilsService,
    protected contextService: ContextService,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  ngOnInit() {
    this.utilsService.lessonSlideAspectRatio$
      .pipe(
        tap((aspect) => {
          this.aspectRatio = aspect;
          this.detectChanges();
        }),
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe();

    this.utilsService.lessonSlideGrid$
      .pipe(
        tap((grid) => {
          this.grid = grid;
          this.detectChanges();
        }),
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe();
  }

  private propagateChange: any = () => {};
  private propagateTouched: any = () => {};

  writeValue(value: string): void {
    this.value = value;
    this.loadMedia(this.value);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onChanged(image_id: string): void {
    this.value = image_id;
    this.propagateChange(this.value);
    this.changed.emit(this.value);
    this.propagateTouched();
  }

  onBlur(): void {
    this.propagateTouched();
  }

  protected loadMedia(mediaId: string) {
    if (!mediaId) {
      return;
    }

    this.isLoading = true;
    this.filesService
      .getFileById(mediaId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (file) => {
          this.file = file;
          this.getOriginalImage(this.file.parent_file_id);
          this.prepareMedia();
        },
        (err: any) => {
          logger.error(err);
        },
      );
  }

  public prepareMedia() {
    const imageUrl = FilesService.getFileUrlBySize(this.file.id, ImageSizeEnum.sm);
    const imageFile = (this.file.images || []).find((f) => f.size === 'sm');
    if (imageFile) {
      this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(imageFile.url);
      this.isMediaPrepared = true;
    } else if (imageUrl) {
      this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(imageUrl);
      this.isMediaPrepared = true;
    } else {
      this.isMediaPrepared = false;
    }

    this.isLoading = false;
    this.markForCheck();
  }

  public resetMedia() {
    this.imageUrl = null;
    this.isMediaPrepared = false;
    this.file = null;
    this.markForCheck();
  }

  // Modal

  public setImage(file: IFileType) {
    this.file = file;
    this.loadMedia(file.id);
    this.onChanged(file.id);
  }

  public closeMediaModal() {
    this.utilsService.lessonSlideActions.next(false);
    logger.debug('---closeMediaModal = ');
    // this.isModalOpened = false;
    this.modalOpened.emit(false);
  }

  public openMediaModal(e) {
    this.utilsService.lessonSlideActions.next(true);
    logger.debug('---openMediaModal = ');
    // this.isModalOpened = false;
    this.modalOpened.emit(true);
  }

  public openCropModal(e) {
    this.utilsService.lessonSlideActions.next(true);
    logger.debug('---openCropModal = ');
    this.showCropImage = true;
  }

  public get image() {
    if (!this.original_file) {
      if (!this.file) {
        return null;
      } else {
        return this.file.images.find((image) => image.size === 'original');
      }
    } else {
      return this.original_file.images.find((image) => image.size === 'original');
    }
    // return this.imageUrl.toString();
  }

  public getOriginalImage(id: string) {
    if (!id) {
      return;
    }
    this.filesService
      .getFileById(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (file) => {
          this.original_file = file;
          this.markForCheck();
        },
        (err: any) => {
          logger.warn(err);
          this.original_file = null;
          this.markForCheck();
        },
      );
  }

  onImageChange(evt: { type: string; data?: string }): void {
    if (evt.type === 'imageCropped') {
      let origignalImageId = null;
      if (this.original_file && this.original_file.id) {
        origignalImageId = this.original_file.id;
      } else {
        if (!this.file.parent_file_id) {
          origignalImageId = this.file.id;
        }
      }
      this.filesService
        .uploadFileByData(evt.data, this.filePurpose, String(Date.now()), origignalImageId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((file) => {
          this.setImage(file);
          this.loadMedia(file.id);
        });
      this.showCropImage = false;
      this.utilsService.lessonSlideActions.next(false);
    } else if (evt.type === 'cancelCropped') {
      this.showCropImage = false;
      this.utilsService.lessonSlideActions.next(false);
    }
  }

  public get actionsClasses() {
    if (this.grid.width > 2 && this.aspectRatio === LessonSlideImageAspectRatioEnum.wide) {
      return 'small-horizont';
    }
    if (this.grid.height > 2 && this.aspectRatio === LessonSlideImageAspectRatioEnum.vertical) {
      return 'small-vertical';
    }
  }
}
