import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { OnUiButtonModule } from 'onclass-ui';
import { OnboardingSharedModule } from '../shared/onboarding-shared.module';
import { OnboardingGuestTeacherComponent } from './onboarding-guest-teacher.component';

@NgModule({
  declarations: [OnboardingGuestTeacherComponent],
  imports: [CommonModule, TranslateModule, OnboardingSharedModule, OnUiButtonModule],
  exports: [OnboardingGuestTeacherComponent],
})
export class OnboardingGuestTeacherModule {}
