<div *ngIf="open"
     [@modalAnimation]
     class="mobile-modal"
     [class.footer-visible]="footerVisible">
  <div *ngIf="titleVisible" class="mobile-title-block">
    <app-icon
      class="close"
      icon="close-mobile-modal"
      [width]="11"
      [height]="20"
      (click)="onClose()">
    </app-icon>

    <div class="mobile-title">
      <ng-content select="app-mobile-modal-title"></ng-content>
    </div>
    <app-icon
      class="menu"
      icon="menu"
      [width]="5"
      [height]="19">
    </app-icon>
  </div>

  <div *ngIf="withCloseButton" class="mobile-title-block title-wrap">
    <span class="title bold">{{ customTitle }}</span>
    <app-icon
      (click)="onClose()"
      class="close-btn"
      icon="close-mobile-new"
      [width]="10"
      [height]="10">
    </app-icon>
  </div>

  <ng-content select="app-mobile-modal-ad"></ng-content>
  <ng-content select="app-mobile-modal-body"></ng-content>
  <ng-content select="app-mobile-modal-actions"></ng-content>

  <ng-template [ngIf]="footerVisible">
    <div class="footer"></div>
  </ng-template>
</div>
