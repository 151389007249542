import { Location } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RequestService } from '@core/services';
import { Observable } from 'rxjs';

/**
 * Добавляет заголовок 'X-Requester-Url' = URL страницы, с которой запрашивались данные
 */
@Injectable()
export class RequesterUrlInterceptor implements HttpInterceptor {
  constructor(private location: Location, private router: Router, private request: RequestService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.condition(request)) {
      request = request.clone({
        setHeaders: {
          'X-Requester-Url': this.request.host + this.location.path(true),
        },
      });
    }
    return next.handle(request);
  }

  /**
   * Реализовано пока в отдельных сервисах
   */
  private condition(request: HttpRequest<any>): boolean {
    return (
      request.url.indexOf('/web/account') >= 0 ||
      request.url.indexOf('/web/auth') >= 0 ||
      request.url.indexOf('/web/content') >= 0 ||
      request.url.indexOf('/web/comments') >= 0
    );
  }
}
