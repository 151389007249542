import { createAction, props } from '@ngrx/store';
import { IRedPointsEvents } from '@store/models';
import {
  User,
  IPagedResults,
  Meeting,
  EventStatusEnum,
  UserService,
  IPagination,
  IFindUserServicesFilter,
  IFindMeetingsFilter,
  UserProfile,
  SingleEvent,
  CurrencyEnum,
  PinnedEntity,
  AccountPlan,
} from 'lingo2-models';
import { IUserAccess } from 'lingo2-models/dist/account/interfaces/user-access';

export const userLogout = createAction('[Profile] User logout');

export const loadMe = createAction('[Profile] Load me');
export const loadMeSuccess = createAction('[Profile] Load me success', props<{ me: User }>());
export const loadMyProfileSuccess = createAction(
  '[Profile] Load my profile success',
  props<{ profile: UserProfile }>(),
);
export const loadMySettingsSuccess = createAction(
  '[Profile] Load my profile success',
  props<{ settings: IUserAccess }>(),
);
export const loadMyMeetings = createAction(
  '[Profile] Load my meetings',
  props<{ page?: number; force?: boolean; filter?: Partial<IFindMeetingsFilter> }>(),
);
export const loadMyMeetingsSuccess = createAction(
  '[Profile] Load my meetings success',
  props<{ meetingResponse: IPagedResults<Meeting[]> }>(),
);
export const updateMyGameStats = createAction('[Profile] Update my game stats');
export const setMyScheduleLoading = createAction('[Profile] Set schedule loading', props<{ stopLoading?: boolean }>());
export const loadMySchedule = createAction(
  '[Profile] Load my schedule',
  props<{
    date?: Date;
    weekShift?: number;
    monthShift?: number;
    school_id?: string;
    account_id?: string;
    force?: boolean;
  }>(),
);
export const loadMyScheduleSuccess = createAction(
  '[Profile] Load my schedule success',
  props<{ events: SingleEvent[] }>(),
);
export const markHours = createAction(
  '[Profile] Mark my hours',
  props<{ status: EventStatusEnum; start: Date; end?: Date }>(),
);
export const markHoursSuccess = createAction('[Profile] Mark my hours success');
export const loadMyServices = createAction(
  '[Profile] Load my services',
  props<{ filter?: Partial<IFindUserServicesFilter>; pagination?: IPagination }>(),
);
export const loadMyServicesSuccess = createAction(
  '[Profile] Load my services success',
  props<{ services: IPagedResults<UserService[]> }>(),
);
export const setMyCurrency = createAction('[Profile] Set my currency', props<{ currency_id: CurrencyEnum }>());
export const setMyBillingPlan = createAction('[Profile] Set my billing plan', props<{ myBillingPlan: AccountPlan }>());
export const loadMyBillingPlan = createAction('[Profile] Load my billing plan');
export const loadPins = createAction('[Profile] Load my pins');
export const loadPinsSuccess = createAction('[Profile] Load my pins success', props<{ pins: PinnedEntity[] }>());
export const createPin = createAction('[Profile] Create my pin', props<{ pin: Partial<PinnedEntity> }>());
export const removePin = createAction('[Profile] Remove my pin', props<{ pin_id: string }>());

// TODO: Maybe move to meetings.actions
export const acceptMeeting = createAction('[Profile] Confirm meeting', props<{ meeting_id: string }>());
export const acceptMeetingSuccess = createAction('[Profile] Confirm meeting success', props<{ meeting_id: string }>());
export const acceptMeetingFail = createAction('[Profile] Confirm meeting fail');
export const declineMeeting = createAction('[Profile] Decline meeting', props<{ meeting_id: string }>());
export const declineMeetingSuccess = createAction('[Profile] Decline meeting success');
export const declineMeetingFail = createAction('[Profile] Decline meeting fail');

export const openMeetingRescheduleWindow = createAction(
  '[Profile] Open meeting reschedule window',
  props<{ meeting_id: string }>(),
);
export const closeMeetingRescheduleWindow = createAction('[Profile] Close meeting reschedule window');
export const closeMeetingRescheduleAlert = createAction('[Profile] Close meeting reschedule alert');

export const requestMeetingReschedule = createAction(
  '[Profile] Request meeting reschedule',
  props<{ begin_at: Date; confirm?: boolean }>(),
);
export const requestMeetingRescheduleSuccess = createAction(
  '[Profile] Request meeting reschedule success',
  props<{ meeting_id: string; data: any }>(),
);
export const confirmMeetingRescheduleRequest = createAction('[Profile] Confirm meeting reschedule request');
export const confirmMeetingRescheduleRequestSuccess = createAction(
  '[Profile] Confirm meeting reschedule request success',
);

export const acceptMeetingReschedule = createAction(
  '[Profile] Accept meeting reschedule',
  props<{ meeting_id: string }>(),
);
export const acceptMeetingRescheduleSuccess = createAction(
  '[Profile] Accept meeting reschedule success',
  props<{ meeting_id: string; data?: any }>(),
);
export const rejectMeetingReschedule = createAction(
  '[Profile] Decline meeting reschedule',
  props<{ meeting_id: string }>(),
);
export const declineMeetingRescheduleSuccess = createAction(
  '[Profile] Decline meeting reschedule success',
  props<{ meeting_id: string; data?: any }>(),
);
export const withdrawMeetingReschedule = createAction(
  '[Profile] Cancel requested meeting reschedule',
  props<{ meeting_id: string }>(),
);
export const withdrawMeetingRescheduleSuccess = createAction(
  '[Profile] Cancel requested meeting reschedule success',
  props<{ meeting_id: string; data?: any }>(),
);

export const setLanguages = createAction('[Profile] Set languages', props<{ languages: string[] }>());

export const setRedPoints = createAction('[Profile] Set red points', props<{ points: IRedPointsEvents }>());

export const setProfile = createAction('[Users] Set profile', props<{ profile: UserProfile }>());
