<div class="error">

{{ text | translate }}

<svg class="icon" [appPopover]="popoverContent" position="top" [popoverOnly]="true">
  <use [attr.xlink:href]="svgsetIcon('question-rounded')"></use>
</svg>

<ng-template #popoverContent>
  <img [src]="imageSrc">
</ng-template>

</div>

