export const titles = {
  tutor: 'first-contact.capabilities.titles.tutor',
  student: '',
  methodist: 'first-contact.capabilities.titles.methodist',
  school: 'first-contact.capabilities.titles.school',
};

const tutorList = [
  {
    count: 1,
    title: 'first-contact.capabilities.tutor-list.title1',
    text: 'first-contact.capabilities.tutor-list.text1',
  },
  {
    count: 2,
    title: 'first-contact.capabilities.tutor-list.title2',
    text: 'first-contact.capabilities.tutor-list.text2',
  },
  {
    count: 3,
    title: 'first-contact.capabilities.tutor-list.title3',
    text: 'first-contact.capabilities.tutor-list.text3',
  },
  {
    count: 4,
    title: 'first-contact.capabilities.tutor-list.title4',
    text: 'first-contact.capabilities.tutor-list.text4',
  },
  {
    count: 5,
    title: 'first-contact.capabilities.tutor-list.title5',
    text: 'first-contact.capabilities.tutor-list.text5',
  },
  {
    count: 6,
    title: 'first-contact.capabilities.tutor-list.title6',
    text: 'first-contact.capabilities.tutor-list.text6',
  },
  {
    count: 7,
    title: 'first-contact.capabilities.tutor-list.title7',
    text: 'first-contact.capabilities.tutor-list.text7',
  },
  {
    count: 8,
    title: 'first-contact.capabilities.tutor-list.title8',
    text: 'first-contact.capabilities.tutor-list.text8',
  },
  {
    count: 9,
    title: 'first-contact.capabilities.tutor-list.title9',
    text: 'first-contact.capabilities.tutor-list.text9',
  },
  {
    count: 10,
    title: 'first-contact.capabilities.tutor-list.title10',
    text: 'first-contact.capabilities.tutor-list.text10',
  },
];

const methodistList = [
  {
    count: 1,
    title: 'first-contact.capabilities.methodist-list.title1',
    text: 'first-contact.capabilities.methodist-list.text1',
  },
  {
    count: 2,
    title: 'first-contact.capabilities.methodist-list.title2',
    text: 'first-contact.capabilities.methodist-list.text2',
  },
  {
    count: 3,
    title: 'first-contact.capabilities.methodist-list.title3',
    text: 'first-contact.capabilities.methodist-list.text3',
  },
  {
    count: 4,
    title: 'first-contact.capabilities.methodist-list.title4',
    text: 'first-contact.capabilities.methodist-list.text4',
  },
  {
    count: 5,
    title: 'first-contact.capabilities.methodist-list.title5',
    text: 'first-contact.capabilities.methodist-list.text5',
  },
  {
    count: 6,
    title: 'first-contact.capabilities.methodist-list.title6',
    text: 'first-contact.capabilities.methodist-list.text6',
  },
  {
    count: 7,
    title: 'first-contact.capabilities.methodist-list.title7',
    text: 'first-contact.capabilities.methodist-list.text7',
  },
  {
    count: 8,
    title: 'first-contact.capabilities.methodist-list.title8',
    text: 'first-contact.capabilities.methodist-list.text8',
  },
];

const schoolList = [
  {
    count: 1,
    title: 'first-contact.capabilities.school-list.title1',
    text: 'first-contact.capabilities.school-list.text1',
  },
  {
    count: 2,
    title: 'first-contact.capabilities.school-list.title2',
    text: 'first-contact.capabilities.school-list.text2',
  },
  {
    count: 3,
    title: 'first-contact.capabilities.school-list.title3',
    text: 'first-contact.capabilities.school-list.text3',
  },
  {
    count: 4,
    title: 'first-contact.capabilities.school-list.title4',
    text: 'first-contact.capabilities.school-list.text4',
  },
  {
    count: 5,
    title: 'first-contact.capabilities.school-list.title5',
    text: 'first-contact.capabilities.school-list.text5',
  },
  {
    count: 6,
    title: 'first-contact.capabilities.school-list.title6',
    text: 'first-contact.capabilities.school-list.text6',
  },
  {
    count: 7,
    title: 'first-contact.capabilities.school-list.title7',
    text: 'first-contact.capabilities.school-list.text7',
  },
  {
    count: 8,
    title: 'first-contact.capabilities.school-list.title8',
    text: 'first-contact.capabilities.school-list.text8',
  },
];

export const capabilitiesList = {
  tutor: tutorList,
  student: [],
  methodist: methodistList,
  school: schoolList,
};
