import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { DestroyableComponent } from '@models/destroyable.component';
import { Store } from '@ngrx/store';
import { setLanguages, setSubjects, setUserServiceCategories } from '@store/actions/content.actions';
import {
  Language,
  Country,
  LanguageLevel,
  Subject,
  SubjectsRegistry,
  SubjectLevelsRegistry,
  UserServiceCategory,
  CollectionCategory,
} from 'lingo2-models';
import { uniqBy } from 'lodash';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

export interface ITimezone {
  name: string;
  offset: number;
  cities: string[];
}

export function countryTitle(country: Country): string {
  if (!country) {
    return '';
  }
  const parts: string[] = [country.title_native];
  if (country.title && country.title_native !== country.title) {
    parts.push(`(${country.title})`);
  }
  return parts.join(' ');
}

const emptySubjectsV2: SubjectsRegistry = {
  foreign: [],
  native: [],
  non_categorized: [],
  other: [],
  recent: [],
  user: [],
};

export type ReferenceRequireType =
  | 'subjects$'
  | 'languages$'
  | 'languageLevels$'
  | 'subjectsV2$'
  | 'subjectLevels2$'
  | 'countries$'
  | 'timezones$'
  | 'userServiceCategories$'
  | 'collectionCategories$';

@Injectable({
  providedIn: 'root',
})
export class ConfigService extends DestroyableComponent {
  protected _subjects = this.register(new BehaviorSubject<Subject[]>([]));
  /** Call configService.load(['subjects$']) to initialize value */
  public subjects$ = this._subjects.asObservable();

  protected _subjectsV2 = this.register(new BehaviorSubject<SubjectsRegistry>(emptySubjectsV2));
  /** Call configService.load(['subjectsV2$']) to initialize value */
  public subjectsV2$ = this._subjectsV2.asObservable();

  protected _subjectLevels2 = this.register(new BehaviorSubject<SubjectLevelsRegistry>(null));
  /** Call configService.load(['subjectLevels2$']) to initialize value */
  public subjectLevels2$ = this._subjectLevels2.asObservable();

  protected _languages = this.register(new BehaviorSubject<Language[]>([]));
  /** Call configService.load(['languages$']) to initialize value */
  public languages$ = this._languages.asObservable();

  protected _languageLevels = this.register(new BehaviorSubject<LanguageLevel[]>([]));
  /** Call configService.load(['languageLevels$']) to initialize value */
  public languageLevels$ = this._languageLevels.asObservable();

  protected _userServiceCategories = this.register(new BehaviorSubject<UserServiceCategory[]>(null));
  /** Call configService.load(['userServiceCategories$']) to initialize value */
  public userServiceCategories$ = this._userServiceCategories.asObservable();

  protected _collectionCategories = this.register(new BehaviorSubject<CollectionCategory[]>(null));
  /** Call configService.load(['collectionCategories$']) to initialize value */
  public collectionCategories$ = this._collectionCategories.asObservable();

  protected _countries = this.register(new BehaviorSubject<Country[]>(null));
  /** Call configService.load(['countries$']) to initialize value */
  public countries$ = this._countries.asObservable();

  protected _timezones = this.register(new BehaviorSubject<ITimezone[]>(null));
  /** Call configService.load(['timezones$']) to initialize value */
  public timezones$ = this._timezones.asObservable();

  private locale: string;

  constructor(private http: HttpClient, private readonly store: Store) {
    super();
  }

  public onLocaleChanged(locale: string) {
    this.locale = locale;
    this.loadSubjects(locale);
    this.loadSubjectsV2(locale);
    this.loadLanguages(locale);
    this.loadSubjectLevels2(locale);
    this.loadLanguageLevels();
    this.loadUserServiceCategories(locale);

    if (this._countries.value) {
      this.loadCountries(locale);
    }

    if (this._timezones.value) {
      this.loadTimezones(locale);
    }

    if (this._collectionCategories.value) {
      this.loadCollectionCategories(locale);
    }
  }

  /**
   * Call configService.load([...]) to initialize references Observables
   */
  public load(references: ReferenceRequireType[]) {
    references.map((reference) => {
      switch (reference) {
        case 'subjects$':
        case 'languages$':
        case 'languageLevels$':
        case 'subjectsV2$':
        case 'subjectLevels2$':
        case 'userServiceCategories$':
          // TODO
          break;

        case 'countries$':
          if (!this._countries.value) {
            this.loadCountries(this.locale);
          }
          break;

        case 'timezones$':
          if (!this._timezones.value) {
            this.loadTimezones(this.locale);
          }
          break;

        case 'collectionCategories$':
          if (!this._collectionCategories.value) {
            this.loadCollectionCategories(this.locale);
          }
          break;
      }
    });
  }

  public onVersionChanged(entity: 'languages' | 'subjects' | 'countries', version: string) {
    switch (entity) {
      case 'subjects':
        this.loadSubjects(this.locale);
        this.loadSubjectsV2(this.locale);
        return;

      case 'languages':
        this.loadLanguages(this.locale);
        return;

      case 'countries':
        this.loadCountries(this.locale);
        return;
    }
  }

  protected loadSubjects(locale: string) {
    this.getSubjects(locale)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((subjects) => {
        this._subjects.next(subjects);
      });
  }

  protected loadSubjectsV2(locale: string) {
    this.getSubjectsV2(locale)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((subjects) => {
        this.store.dispatch(
          setSubjects({
            subjects: uniqBy(
              [
                ...subjects.foreign,
                ...subjects.native,
                ...subjects.non_categorized,
                ...subjects.other,
                ...subjects.recent,
                ...subjects.user,
              ],
              'id',
            ),
          }),
        );
        this._subjectsV2.next(subjects);
      });
  }

  protected loadLanguages(locale: string) {
    this.getLanguages(locale)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((languages) => {
        this._languages.next(languages);
      });

    this.getLanguages(locale, true)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((languages) => {
        this.store.dispatch(setLanguages({ languages }));
      });
  }

  protected loadSubjectLevels2(locale: string) {
    this.getSubjectLevels2(locale)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((subjectLevels) => {
        // TODO store.dispatch..,
        this._subjectLevels2.next(subjectLevels);
      });
  }

  protected loadLanguageLevels() {
    this.getLanguageLevels()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((languageLevels) => {
        this._languageLevels.next(languageLevels);
      });
  }

  protected loadUserServiceCategories(locale: string) {
    this.getUserServiceCategories(locale)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((categories) => {
        this.store.dispatch(setUserServiceCategories({ userServiceCategories: categories }));
        this._userServiceCategories.next(categories);
      });
  }

  protected loadCollectionCategories(locale: string) {
    this.getCollectionCategories(locale)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((categories) => {
        // TODO this.store.dispatch(setCollectionCategories({ collectionCategories: categories }));
        this._collectionCategories.next(categories);
      });
  }

  protected loadCountries(locale: string) {
    this.getCountries(locale)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((countries) => {
        this._countries.next(countries);
      });
  }

  protected loadTimezones(locale: string) {
    this.getTimezones(locale)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((timezones) => {
        this._timezones.next(timezones);
      });
  }

  public getSubjects(locale: string, all = false, details: string[] = []): Observable<Subject[]> {
    const url = `${environment.content_url}/reference/subjects`;
    const params = new HttpParams()
      .set('details', JSON.stringify(details))
      .set('locale', locale)
      .set('all', all ? '1' : '0')
      .set('v', environment.versions.gitCommitHash);
    return this.http.get<Subject[]>(url, { params, observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  public getSubjectsV2(locale: string, all = false): Observable<SubjectsRegistry> {
    const url = `${environment.content_url}/reference/subjects/2`;
    const params = new HttpParams()
      .set('locale', locale)
      .set('all', all ? '1' : '0')
      .set('v', environment.versions.gitCommitHash);
    return this.http.get<SubjectsRegistry>(url, { params, observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  public getLanguages(locale: string, all = false): Observable<Language[]> {
    const url = `${environment.content_url}/reference/languages`;
    const params = new HttpParams()
      .set('locale', locale)
      .set('all', all ? '1' : '0')
      .set('v', environment.versions.gitCommitHash);
    return this.http.get<Language[]>(url, { params, observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  public getSubjectLevels2(locale: string, all = false): Observable<SubjectLevelsRegistry> {
    const url = `${environment.content_url}/reference/difficulty-levels/2`;
    const params = new HttpParams()
      .set('locale', locale)
      .set('all', all ? '1' : '0')
      .set('v', environment.versions.gitCommitHash);
    return this.http.get<SubjectLevelsRegistry>(url, { params, observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  public getLanguageLevels(): Observable<LanguageLevel[]> {
    const url = `${environment.content_url}/reference/language-levels`;
    const params = new HttpParams()
      // TODO .set('locale', locale)
      .set('v', environment.versions.gitCommitHash);
    return this.http.get<LanguageLevel[]>(url, { params, observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  public getUserServiceCategories(locale: string): Observable<UserServiceCategory[]> {
    const url = `${environment.content_url}/reference/user-service-categories`;
    const params = new HttpParams().set('locale', locale).set('v', environment.versions.gitCommitHash);
    return this.http.get<UserServiceCategory[]>(url, { params, observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  public getCollectionCategories(locale: string): Observable<CollectionCategory[]> {
    const url = `${environment.content_url}/reference/collection-categories`;
    const params = new HttpParams().set('locale', locale).set('v', environment.versions.gitCommitHash);
    return this.http.get<CollectionCategory[]>(url, { params, observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  public getCountries(locale: string, all = false): Observable<Country[]> {
    const url = `${environment.account_url}/reference/countries`;
    const params = new HttpParams()
      .set('locale', locale)
      .set('all', all ? '1' : '0')
      .set('v', environment.versions.gitCommitHash);
    return this.http.get<Country[]>(url, { params, observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  public getTimezones(locale: string): Observable<ITimezone[]> {
    const url = `${environment.account_url}/reference/timezones`;
    const params = new HttpParams().set('locale', locale).set('v', environment.versions.gitCommitHash);
    return this.http.get<ITimezone[]>(url, { params, observe: 'response' }).pipe(
      map((response) => response.body),
      // catchError(this.handleError),
    );
  }

  public getEnvBillingFrameHost() {
    return environment.stripe_iframe_host;
  }

  public getEnvAccountUrl() {
    return environment.account_url;
  }

  public getEnvBillingPublicUrl() {
    return environment.billing_public_url;
  }
}
