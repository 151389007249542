import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { IconsService } from '../../services';

@Component({
  selector: 'app-button-fab',
  templateUrl: './button-fab.component.html',
  styleUrls: ['./button-fab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonFabComponent implements OnInit {
  @Input() icon: string;
  @Input() counter: number;
  @Input() isToggled: boolean;

  public svgSetIcon = IconsService.svgsetIconUrl;

  constructor() {}

  ngOnInit() {}
}
