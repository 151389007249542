import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RadiosComponent } from './radios.component';

@NgModule({
  declarations: [RadiosComponent],
  imports: [CommonModule, TranslateModule],
  exports: [RadiosComponent],
})
export class RadiosModule {}
