import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { IconsService } from '../../services';

type Position = 'top' | 'center' | 'bottom';

@Component({
  selector: 'app-no-content-stub',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule],
  templateUrl: './no-content-stub.component.html',
  styleUrls: ['./no-content-stub.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoContentStubComponent implements OnInit {
  @Input() position: Position = 'bottom';
  @Input() icon: string;
  @Input() text: string;
  @Input() text2: string;
  @Input() link: string;
  @Output() onClick = new EventEmitter<boolean>();
  public svgsetIcon = IconsService.svgsetIconUrl;

  constructor(protected readonly cdr: ChangeDetectorRef) {}

  ngOnInit() {}

  public onLinkClick() {
    this.onClick.emit(true);
  }
}
