import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { OnboardingSharedModule } from '../shared/onboarding-shared.module';
import { OnboardingOnModerationComponent } from './onboarding-on-moderation.component';

@NgModule({
  declarations: [OnboardingOnModerationComponent],
  imports: [CommonModule, TranslateModule, OnboardingSharedModule],
  exports: [OnboardingOnModerationComponent],
})
export class OnboardingOnModerationModule {}
