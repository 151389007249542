<section class="first-contact-user-services" [class.mobile]="deviceService.isMobile()">
  <h2 class="title">{{ 'first-contact.user-services.title' | translate }}</h2>

  <div class="cards"
       [class.is-viewport]="isViewport"
       inViewport
       (inViewportAction)="onInViewportChange($event)">
    <div class="card1"></div>
    <div class="card2"></div>
  </div>

  <p class="text">{{ 'first-contact.user-services.text' | translate }}</p>
</section>
