import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import {IMeetingInviteNotice, INoticeParams, ISubscriberNotice} from 'lingo2-models';
import {EModifier, IBaseNotification} from '../../base-notification/base-notification.component';

@Component({
  selector: 'app-subscriber',
  templateUrl: './subscriber.component.html',
  styles: [''],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriberComponent {
  public params: ISubscriberNotice;
  public base: IBaseNotification;

  @Input() set notification(notification: INoticeParams) {
    this.params = notification.params;
    this.base = {
      title: 'notifications.subscriber.title',
      icon: 'notice-subscriber',
      created_at: notification.created_at,
      modifier: null,
    };
  }

  public get user() {
    return `<a class='user-fullname-link' target='_blank' href='${this.params.user_url}'>${this.params.user_fullname}</a>`;
  }
}
