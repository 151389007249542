<div class="nearest-meet-widget" [class.on-air]="meetData?.onAir" [class.minimized]="isMinimized" *ngIf="meetData?.id && isVisible">
  <div class="minimize-icon" (click)="toggleMinimize()">
    <div *ngIf="isMinimized">
      <svg width="14" height="14" viewBox="0 0 14 14">
        <use [attr.xlink:href]="svgSetIcon('plus-bold')"></use>
      </svg>
    </div>
    <div *ngIf="!isMinimized">
      <svg width="14" height="14" viewBox="0 0 14 14">
        <use [attr.xlink:href]="svgSetIcon('minus-bold')"></use>
      </svg>
    </div>
  </div>
  <div class="widget-data">
    <div class="image">
      <on-ui-cover [cover]="meetData?.cover"></on-ui-cover>
      <p class="meeting-state" *ngIf="!isMinimized">{{meetData?.onAir ? ('dashboard.nearest-meet-widget.class-started' | translate) : ('dashboard.nearest-meet-widget.nearest-class' | translate)}}</p>
    </div>
    <div class="widget-info">
      <div class="title">
        {{meetData.subject}}
        <div class="on-air-wrap" *ngIf="meetData?.onAir">
          <div class="on-air"></div>
        </div>
      </div>
      <div class="description" *ngIf="meetData.title">{{meetData.title}}</div>
      <ng-container *ngIf="!isMinimized">
        <div class="info" *ngIf="meetData?.is_author">
          <span class="class-type">{{ meetData.meetingTypeName | translate }}</span>&nbsp;
          <span>{{meetData.participants_count}}/{{meetData.participants_limit}}</span>
          <ng-container *ngTemplateOutlet="timer"></ng-container>
        </div>
        <div class="info" *ngIf="!meetData?.is_author">
          <span class="class-type">{{'dashboard.nearest-meet-widget.tutor' | translate}}:</span>&nbsp;
          <a [routerLink]="[meetData.authorLink]">{{meetData.authorFullName}}</a>
          <ng-container *ngTemplateOutlet="timer"></ng-container>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="widget-actions">
    <on-ui-button *ngIf="!isEventsPage && !isMinimized" class="transparent" (click)="goToEvents()"
                  [label]="'dashboard.nearest-meet-widget.show_more' | translate">
    </on-ui-button>
    <a [routerLink]="[meetData?.onAir ? meetData.link : meetData.classroomLink]">
    <on-ui-button class="primary"
                  [label]="'dashboard.nearest-meet-widget.enter_class' | translate">
    </on-ui-button>
    </a>
  </div>
</div>

<ng-template #timer>
  <div class="timer" *ngIf="!meetData?.onAir">
    <div class="separator"></div>
      <span *ngIf="isStartCountdown">{{'dashboard.nearest-meet-widget.start_in' | translate}}
        <app-countdown
          [date]="meetData.beginDate"
          [minSeconds]="0"
          [minTitle]="'dashboard.time-in-minute'">
        </app-countdown>
      </span>
    <span *ngIf="!isStartCountdown">
      <span>{{ meetData.beginDate | zonedDate: me?.timezone | dfnsFormat: 'dd MMMM, HH:mm' }}</span>
    </span>
  </div>
</ng-template>
