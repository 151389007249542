import { Component, OnInit, forwardRef, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { ILessonSlideTaskAnswerVariant } from 'lingo2-models';

import {
  IVariantPopoverConfig,
  ToolbarItemTypeEnum,
} from '../../task-answer-variant-popover/task-answer-variant-popover.models';

@Component({
  selector: 'app-slide-question',
  templateUrl: './slide-question.component.html',
  styleUrls: ['./slide-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SlideQuestionComponent),
      multi: true,
    },
  ],
})
export class SlideQuestionComponent implements OnInit, ControlValueAccessor {
  @Input() isError = false;
  @Input() placeholder = 'constructor2.placeholder.question';
  @Output() changed = new EventEmitter<ILessonSlideTaskAnswerVariant>();

  public value: ILessonSlideTaskAnswerVariant;
  public isDisabled: boolean;

  public formControl: UntypedFormControl;

  public selectedQuestionType: ToolbarItemTypeEnum;
  public variantTypes = ToolbarItemTypeEnum;

  public questionMultyInputConfig: IVariantPopoverConfig = {
    richText: true,
  };

  public isModalOpened = false;

  constructor(protected fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.formControl = this.fb.control('');
  }

  private propagateChange: any = () => {};
  private propagateTouched: any = () => {};

  writeValue(value: ILessonSlideTaskAnswerVariant): void {
    this.value = value;
    this.formControl.patchValue(value);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onChanged(): void {
    this.value = this.formControl.value;
    this.propagateChange(this.value);
    this.changed.emit(this.value);
  }

  onBlur(): void {
    this.propagateTouched();
  }

  public get isRichText() {
    return this.selectedQuestionType === ToolbarItemTypeEnum.richText;
  }

  public onVariantTypeSelected(type: ToolbarItemTypeEnum): void {
    this.selectedQuestionType = type;
    this.isModalOpened = true;
    this.onChanged();
  }

  public onModalOpened(isOpened: boolean): void {
    this.isModalOpened = isOpened;
  }
}
