import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IconModule } from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';
import { OnUiButtonModule } from 'onclass-ui';
import { SimplePieChartComponent } from './components/simple-pie-chart/simple-pie-chart.component';
import { CleanLayoutModule } from './layouts/clean/clean-layout.module';
import { ConstructorLayoutModule } from './layouts/constructor-layout/constructor-layout.module';
import { LandingLayoutModule } from './layouts/landing/landing-layout.module';
import { MainLayoutModule } from './layouts/main/main-layout.module';
import { ProfileLayoutModule } from './layouts/profile/profile-layout.module';

@NgModule({
  declarations: [SimplePieChartComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    CleanLayoutModule,
    ConstructorLayoutModule,
    LandingLayoutModule,
    MainLayoutModule,
    ProfileLayoutModule,
    IconModule,
    OnUiButtonModule,
  ],
  exports: [SimplePieChartComponent],
})
export class CoreModule {}
