import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import {
  BillingApiAccountAttachPaymentCardDto,
  BillingApiAccountAttachPayoutCardDto,
  BillingApiAccountResponseDto,
  BillingApiChargePayByIdDto,
  BillingApiChargePayCheckDto,
  BillingApiChargePayCheckResponseDto,
  BillingApiChargeResponseDto,
  BillingApiContractorDto,
  BillingApiCurrencyRateResponseDto,
  BillingApiMerchantRequirementResponseDto,
  BillingApiMerchantResponseDto,
  BillingApiPaymentIntentAbstractDto,
  BillingApiPaymentResponseDto,
  BillingApiPayoutDto,
  BillingApiPayoutResponseDto,
  BillingApiSupplierDto,
  BillingApiSupplierResponseDto,
  BillingApiTransactionFindQueryDto,
  BillingApiTransactionResponseDto,
} from '@lingo2-billing-sdk/models';
import { Like } from '@lingo2-billing-sdk/models/src/shared/types/like';
import { DestroyableComponent } from '@models/destroyable.component';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { IBillingPaymentMethod } from '@shared/checkout-wizards/components/payment-widget/payment-widget-billing/payment-widget-billing.service';
import { getPriceTier } from '@store/reducers/content.reducer';
import { getMyCurrency } from '@store/reducers/profile.reducer';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { BuyCreditsEndpoint } from 'lingo2-api-models';
import {
  BillingContractorDescriptorEnum,
  CCurrencyAmount,
  currencies,
  CurrencyEnum,
  IPagedResults,
  IPagination,
} from 'lingo2-models';
import { combineLatest, Observable, zip } from 'rxjs';

import { map } from 'rxjs/operators';

const billing_url = `${environment.account_url}/billing`;

type IBillingCustomer = Like<BillingApiPaymentIntentAbstractDto>;

export interface AccountByMerchant {
  [key: string]: BillingApiAccountResponseDto;
}

export interface SuppliersByMerchant {
  [key: string]: BillingApiSupplierResponseDto;
}

export interface IFinanceCardCreateNewOptions {
  /**
   * Объект опций, где создавать поставщика
   */
  where?: {
    /** Провадйер у которого создаётся или редактируется поставщик */
    providerId: string;
    /**
     * Страна для которой создаётся или редактируется поставщик.
     * Проблема: выбор страны находится непосредственно в форме,
     * надо переделать чтобы requirements запрашивались после выбора страны
     * и вид формы обновлялся бы, т.е. нужно убрать countryCode из входных параметров
     */
    countryCode?: string;
  };
  /** Вместо where можно указать конкретного поставщика для редактирования */
  supplierId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class BillingV2Service extends DestroyableComponent {
  private version = 'v2';

  public constructor(private http: HttpClient, private translate: TranslateService, private store: Store) {
    super();
  }

  /** История операций */
  public findTransactions(filters: BillingApiTransactionFindQueryDto, contractor?: BillingApiContractorDto) {
    const url = `${billing_url}/${this.version}/transaction`;

    const params = {
      ...instanceToPlain(filters, { exposeUnsetFields: false }),
      ...instanceToPlain(contractor, { exposeUnsetFields: false }),
    };

    return this.http
      .get<BillingApiTransactionResponseDto[]>(url, { params, observe: 'response' })
      .pipe(map(this.handleBillingTransactionsResponse));
  }

  private handleBillingTransactionsResponse(
    response: HttpResponse<BillingApiTransactionResponseDto[]>,
  ): IPagedResults<BillingApiTransactionResponseDto[]> {
    const pagination: IPagination = {
      page: +response.headers.get('X-Pagination-Page'),
      pageSize: +response.headers.get('X-Pagination-PageSize'),
      total: +response.headers.get('X-Pagination-Total'),
      totalPages: +response.headers.get('X-Pagination-TotalPages'),
    };
    return {
      results: plainToInstance(BillingApiTransactionResponseDto, response.body),
      pagination,
    };
  }

  public formatAmount(amount: string, currency: string): string {
    return +amount + ' ' + this.translate.instant('currency.' + currency);
  }

  public formatAmountNew(amount: number, currency?: CurrencyEnum): string {
    return amount !== null ? Math.round(amount) + ' ' + this.translate.instant(currencies[currency]?.title) : '';
  }

  public formatCurrencyAmount(amount: CCurrencyAmount): string {
    return amount ? this.formatAmountNew(amount.amount, amount.currency_id) : null;
  }

  public formatAmount$(amount: number): Observable<string> {
    return zip([this.store.select(getPriceTier, { id: amount }), this.store.select(getMyCurrency)]).pipe(
      map(([tier, currency]) => {
        const amountRef = tier.getForCurrency(currency);
        return this.formatCurrencyAmount(amountRef);
      }),
    );
  }

  /**
   * Все созданные пользователем поставщики
   * (такое может быть, если пользователь имеет гражданство или ВНЖ в разных
   * странах и располагает сведениями об адресе, телефоне и т.п., имеет счета/карты для
   * разных стран, на практике у большинства пользователей будет один профиль).
   */
  public getSuppliers(schoolId?: string) {
    const url = `${billing_url}/${this.version}/supplier`;
    let params: HttpParams = null;
    if (schoolId) {
      params = new HttpParams({
        fromObject: {
          descriptor: BillingContractorDescriptorEnum.school,
          externalId: schoolId,
        },
      });
    }
    return this.http
      .get<BillingApiSupplierResponseDto[]>(url, { params, observe: 'response' })
      .pipe(map((response) => this.handleBillingResponse<BillingApiSupplierResponseDto[]>(response)));
  }

  /**
   * Конкретный поставщик
   */
  public getSupplier(id: string, schoolId?: string) {
    const url = `${billing_url}/${this.version}/supplier/` + id;
    let params: HttpParams = null;
    if (schoolId) {
      params = new HttpParams({
        fromObject: {
          descriptor: BillingContractorDescriptorEnum.school,
          externalId: schoolId,
        },
      });
    }
    return this.http
      .get<BillingApiSupplierResponseDto>(url, { params, observe: 'response' })
      .pipe(map((response) => plainToInstance(BillingApiSupplierResponseDto, response.body)));
  }

  /**
   * Требования к форме
   */
  public getSupplierRequirements(providerId: string, countryCode: string, type: 'SUPPLIER' | 'ACCOUNT') {
    const url = `${billing_url}/${this.version}/merchant/${providerId}/supplier-requirement`;
    const params: HttpParams = new HttpParams({
      fromObject: {
        countryCode,
        scope: type,
      },
    });

    return this.http
      .get<BillingApiMerchantRequirementResponseDto[]>(url, { params, observe: 'body' })
      .pipe(map((response) => plainToInstance(BillingApiMerchantRequirementResponseDto, response)));
  }

  /**
   * Создание поставщика
   */
  public createSupplier(supplier: BillingApiSupplierDto, schoolId?: string) {
    const url = `${billing_url}/${this.version}/supplier`;
    const payload = instanceToPlain(supplier, { exposeUnsetFields: false });
    let params: HttpParams = null;
    if (schoolId) {
      params = new HttpParams({
        fromObject: {
          descriptor: BillingContractorDescriptorEnum.school,
          externalId: schoolId,
        },
      });
    }
    return this.http
      .post<any>(url, payload, { params })
      .pipe(map((response) => plainToInstance(BillingApiSupplierResponseDto, response)));
  }

  /**
   * Обновление поставщика
   */
  public updateSupplier(supplierId: string, supplier: BillingApiSupplierDto, schoolId?: string) {
    const url = `${billing_url}/${this.version}/supplier/${supplierId}`;
    const payload = instanceToPlain(supplier, { exposeUnsetFields: false });
    let params: HttpParams = null;
    if (schoolId) {
      params = new HttpParams({
        fromObject: {
          descriptor: BillingContractorDescriptorEnum.school,
          externalId: schoolId,
        },
      });
    }
    return this.http
      .put<any>(url, payload, { params })
      .pipe(map((response) => plainToInstance(BillingApiSupplierResponseDto, response.body)));
  }

  /**
   * Обновление поставщика
   */
  public patchSupplier(supplierId: string, supplier: BillingApiSupplierDto, schoolId?: string) {
    const url = `${billing_url}/${this.version}/supplier/${supplierId}`;
    const payload = instanceToPlain(supplier, { exposeUnsetFields: false });
    let params: HttpParams = null;
    if (schoolId) {
      params = new HttpParams({
        fromObject: {
          descriptor: BillingContractorDescriptorEnum.school,
          externalId: schoolId,
        },
      });
    }
    return this.http
      .patch<any>(url, payload, { params })
      .pipe(map((response) => plainToInstance(BillingApiSupplierResponseDto, response.body)));
  }

  /**
   * Удаление поставщика
   */
  public deleteSupplier(supplierId: string) {
    const url = `${billing_url}/${this.version}/supplier/${supplierId}`;
    return this.http
      .delete<any>(url)
      .pipe(map((response) => this.handleBillingResponse<BillingApiSupplierDto>(response)));
  }

  /**
   * Список провайдеров
   */
  public getMerchants() {
    const url = `${billing_url}/${this.version}/merchant`;
    return this.http
      .get<BillingApiMerchantResponseDto[]>(url, { observe: 'response' })
      .pipe(map((response) => this.handleBillingResponse<BillingApiMerchantResponseDto[]>(response)));
  }

  /**
   * Список счетов
   */
  public getAccounts(providerId?: string) {
    const url = `${billing_url}/${this.version}/account`;
    const params = new HttpParams({
      fromObject: {
        purpose: 'PAYMENT',
        type: 'BANK',
      },
    });
    if (providerId) {
      params.append('providerId', providerId);
    }
    return this.http
      .get<BillingApiAccountResponseDto[]>(url, { params, observe: 'response' })
      .pipe(map((response) => plainToInstance(BillingApiAccountResponseDto, response.body)));
  }

  /**
   * Сделать счет по умолчанию
   */
  public setAccountDefault(accountId: string) {
    const url = `${billing_url}/${this.version}/account/${accountId}/set-default`;
    return this.http
      .post<any>(url, {})
      .pipe(
        map((response) =>
          this.handleBillingResponse<BillingApiAccountResponseDto>(response, (response) =>
            plainToInstance(BillingApiAccountResponseDto, response),
          ),
        ),
      );
  }

  /**
   * Удалить счет
   */
  public deleteAccount(accountId: string) {
    const url = `${billing_url}/${this.version}/account/${accountId}`;
    return this.http
      .delete<BillingApiAccountResponseDto[]>(url, { observe: 'response' })
      .pipe(map((response) => this.handleBillingResponse<BillingApiAccountResponseDto[]>(response)));
  }

  /**
   * Загрузка информации о начислениях
   */
  public getCharges(chargeId: string | string[]) {
    const url = `${billing_url}/${this.version}/charge`;
    let params: HttpParams = null;
    if (chargeId) {
      params = new HttpParams({
        fromObject: {
          id: chargeId,
        },
      });
    }

    return this.http
      .get<BillingApiChargeResponseDto[]>(url, {
        observe: 'response',
        params,
      })
      .pipe(map((response) => plainToInstance(BillingApiChargeResponseDto, response.body)));
  }

  /**
   * Пакетная оплата начислений >=1
   */
  public payBulk(
    charges: string[],
    paymentMethod: IBillingPaymentMethod,
    customer: IBillingCustomer,
  ): Observable<BillingApiChargeResponseDto> {
    const url = `${billing_url}/${this.version}/charge/pay`;
    const payload: BillingApiChargePayByIdDto[] = charges.map((id) => {
      const item = new BillingApiChargePayByIdDto();
      item.chargeId = id;
      item.firstName = customer.firstName;
      item.lastName = customer.lastName;
      item.countryCode = customer.countryCode;
      item.email = customer.email ? customer.email : null;
      item.phone = customer.phone ? (customer.phone.indexOf('+') !== -1 ? customer.phone : '+' + customer.phone) : null;
      item.accountId = paymentMethod?.paymentMethod ? paymentMethod?.paymentMethod?.id : null;
      return item;
    });

    return this.http
      .post<any>(url, instanceToPlain(payload, { exposeUnsetFields: false }), {
        observe: 'response',
      })
      .pipe(map((response) => plainToInstance(BillingApiChargeResponseDto, response.body[0])));
  }

  /**
   * Информация о платеже
   * (для отслеживания статуса оплаты)
   */
  public getPayment(id: string) {
    const url = `${billing_url}/${this.version}/payment/` + id;
    return this.http
      .get<BillingApiPaymentResponseDto>(url, { observe: 'response' })
      .pipe(map((response) => plainToInstance(BillingApiPaymentResponseDto, response.body)));
  }

  /**
   * Проверка возможности оплаты + сумма
   */
  public payCheck(
    charges: string[],
    accountId?: string,
    currencyCode?: string,
  ): Observable<BillingApiChargePayCheckResponseDto> {
    const url = `${billing_url}/${this.version}/charge/pay-check`;
    const payload = new BillingApiChargePayCheckDto();
    payload.joinCharge = true;
    payload.joinOrder = true;
    payload.chargeId = charges;
    payload.accountId = accountId || null;
    payload.currencyCode = currencyCode || 'USD';

    return this.http
      .post<BillingApiChargePayCheckResponseDto>(url, instanceToPlain(payload, { exposeUnsetFields: false }), {
        observe: 'response',
      })
      .pipe(map((response) => plainToInstance(BillingApiChargePayCheckResponseDto, response.body)));
  }

  /**
   * Добавить карту
   */
  public addCard(payload: BillingApiAccountAttachPaymentCardDto): Observable<BillingApiPaymentResponseDto> {
    const url = `${billing_url}/${this.version}/account/attach-payment-card`;

    return this.http
      .post<BillingApiChargePayCheckResponseDto>(url, instanceToPlain(payload, { exposeUnsetFields: false }), {
        observe: 'response',
      })
      .pipe(map((response) => plainToInstance(BillingApiPaymentResponseDto, response.body)));
  }

  /**
   * Запрос на выплату
   */
  public payout(payload: BillingApiPayoutDto): Observable<BillingApiPayoutResponseDto> {
    const url = `${billing_url}/${this.version}/payout`;

    return this.http
      .post<BillingApiPayoutResponseDto>(url, instanceToPlain(payload, { exposeUnsetFields: false }), {
        observe: 'response',
      })
      .pipe(map((response) => plainToInstance(BillingApiPayoutResponseDto, response.body)));
  }

  /**
   * Покупка кредитов
   */
  public buyCredits(request: BuyCreditsEndpoint.Body): Observable<BuyCreditsEndpoint.Response> {
    const url = `${environment.account_url}/${BuyCreditsEndpoint.path}`;
    return this.http.post<BuyCreditsEndpoint.Response>(url, request, { observe: 'body' });
  }

  /**
   * Привязка карты для выплаты
   */
  public attachPayoutCard(request: BillingApiAccountAttachPayoutCardDto) {
    const url = `${billing_url}/${this.version}/account/attach-payout-card`;
    return this.http
      .post<BillingApiPaymentResponseDto>(url, request, { observe: 'body' })
      .pipe(map((response) => plainToInstance(BillingApiPaymentResponseDto, response)));
  }

  /**
   * Привязка карты для выплаты
   */
  public getStripeOnboardingUrl(providerId: string, supplierId: string, bank = false) {
    const url = `${environment.stripe_iframe_host}/${providerId}/connect`;
    const params = new HttpParams({
      fromObject: {
        supplierId,
        bank,
      },
    });
    return this.http.get<any[]>(url, { params, observe: 'response' }).pipe(map((response) => response.body));
  }

  /**
   * Список валют и курсов валют
   */
  public getCurrencyRate() {
    const url = `${billing_url}/${this.version}/currency/rate`;
    return this.http
      .get<BillingApiCurrencyRateResponseDto[]>(url, { observe: 'response' })
      .pipe(map((response) => response.body));
  }

  private handleBillingResponse<T>(response: HttpResponse<T>, transformer?: (arg: any) => any): T {
    if (transformer) {
      return transformer(response.body);
    } else {
      return response.body;
    }
  }
}
