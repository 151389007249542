import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TeacherBadgesWidgetModule } from '@core/widgets/teacher-badges-widget/teacher-badges-widget.module';
import { TranslateModule } from '@ngx-translate/core';

import { OnboardingSharedModule } from '../shared/onboarding-shared.module';

import { OnboardingTutorComponent } from './onboarding-tutor.component';

@NgModule({
  declarations: [OnboardingTutorComponent],
  imports: [CommonModule, TranslateModule, TeacherBadgesWidgetModule, OnboardingSharedModule],
  exports: [OnboardingTutorComponent],
})
export class OnboardingTutorModule {}
