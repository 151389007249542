import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UserRoles } from '@app/first-contact/first-contact.component';
import { FeaturesService, PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { lists, listsForMe, texts, titles } from './first-contact-selector.variables';

export interface IUserRoles {
  role: UserRoles;
  label: string;
  selected: boolean;
}

@Component({
  selector: 'app-first-contact-selector',
  templateUrl: './first-contact-selector.component.html',
  styleUrls: ['./first-contact-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirstContactSelectorComponent extends ChangableComponent implements OnInit {
  @Input() set selectedRole(selectedRole: UserRoles) {
    this._selectedRole = selectedRole;
    this.roles = this.roles.map((_sr) => ({ ..._sr, selected: _sr.role === selectedRole }));
  }
  @Output() selectRole = new EventEmitter<UserRoles>();
  public roles: IUserRoles[] = [
    { role: UserRoles.student, label: 'first-contact.first-contact-selector.role.student', selected: false },
    { role: UserRoles.tutor, label: 'first-contact.first-contact-selector.role.tutor', selected: false },
    { role: UserRoles.methodist, label: 'first-contact.first-contact-selector.role.methodist', selected: false },
    { role: UserRoles.school, label: 'first-contact.first-contact-selector.role.school', selected: false },
  ];
  public titles = titles;
  public lists = lists;
  public listsForMe = listsForMe;
  public texts = texts;

  private _selectedRole: UserRoles = UserRoles.tutor;

  constructor(
    protected readonly cdr: ChangeDetectorRef,
    public deviceService: DeviceDetectorService,
    protected readonly platform?: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngOnInit(): void {
    if (this.isLimitedVersion) {
      this.roles = [
        { role: UserRoles.tutor, label: 'first-contact.first-contact-selector.role.tutor', selected: false },
        { role: UserRoles.student, label: 'first-contact.first-contact-selector.role.student', selected: false },
      ];

      this.onSelectedRole(0);
    }
  }

  public onSelectedRole(index: number) {
    const resetRoles = [];
    this.roles.forEach((_r) => {
      resetRoles.push({ ..._r, selected: false });
    });
    this.roles = resetRoles;
    this.roles[index].selected = true;
    this._selectedRole = this.roles[index].role;
    this.selectRole.emit(this._selectedRole);
    this.detectChanges();
  }

  public get title(): string {
    return this.titles[this._selectedRole];
  }

  public get list(): string[] {
    return this.isLimitedVersion ? this.listsForMe[this._selectedRole] : this.lists[this._selectedRole];
  }

  public text(): string {
    return this.texts[this._selectedRole];
  }

  public role(): UserRoles {
    return this._selectedRole;
  }

  public get isLimitedVersion() {
    return FeaturesService.isLimitedVersion;
  }

  public get isStudentOnMe() {
    return this.isLimitedVersion && this._selectedRole === UserRoles.student;
  }

  public stripHtml(html): string {
    const tmp = document.createElement('div');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }
}
