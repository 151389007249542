<div class="settings">
  <div class="left">
    <div class="title"> {{'profile-form.microphone.setting-audio-video' | translate}}</div>
    <div class="list-info">{{'profile-form.microphone.possible-solution' | translate}}</div>
    <ul class="list-of-tip">
      <li> {{'profile-form.microphone.reload' | translate}} </li>
      <li> {{'profile-form.microphone.check-your-setting' | translate}}
        <span class="link-base"> {{'profile-form.microphone.safari-instruction' | translate}} </span>
        <div>
          <svg class="video link-base">
            <use [attr.xlink:href]="svgSetIcon('video-x')"></use>
          </svg>
          <svg class="mic link-base">
            <use [attr.xlink:href]="svgSetIcon('microphone-x')"></use>
          </svg>
          <svg class="lock link-base">
            <use [attr.xlink:href]="svgSetIcon('lock-x')"></use>
          </svg>
        </div>
      </li>
      <li> {{'profile-form.microphone.another-browser' | translate}}
        <span class="link-base">{{'profile-form.microphone.install-chrome' | translate}}</span>
        {{'profile-form.microphone.recommended' | translate}}
      </li>
    </ul>
  </div>
  <div class="right">
    <div class="camera-container">
      <svg *ngIf="!isCanPlay">
        <use [attr.xlink:href]="assetsUrl('/assets/icons/svgset/test-video-avatar.svg#avatar')"></use>
      </svg>
      <video #video
             [ngClass]="!isCanPlay ? 'hidden' : ''"
             (canplay)="onCanPlay()" autoplay></video>
    </div>
    <div class="camera-action">
      <label>
        <span class="label">{{'profile-form.microphone.camera' | translate}}</span>
        <svg *ngIf="!videosInputs.length">
          <use [attr.xlink:href]="assetsUrl('/assets/icons/svgset/close-circle.svg#close-circle')"></use>
        </svg>
        <on-ui-select
          (changed)="changeVideoInput()"
          [(ngModel)]="settingModel.videosInput"
          [options]="videosInputs">
        </on-ui-select>
      </label>

      <!--<label>
        <span class="label">Аудио</span>
        <app-select
          [(ngModel)]="settingModel.audioInput"
          (changed)="changeAudioDestination($event)"
          [options]="audioOutputs">
        </app-select>
      </label>-->

      <label>
        <span class="label">{{'profile-form.microphone.microphone' | translate}}</span>
        <svg *ngIf="audioInputs.length">
          <use [attr.xlink:href]="assetsUrl('/assets/icons/svgset/check-2.svg#check-circle')"></use>
        </svg>
        <on-ui-select
          (changed)="changeVideoInput()"
          [(ngModel)]="settingModel.audioOutput"
          [options]="audioInputs">
        </on-ui-select>
      </label>
    </div>
    <div class="mic-volume" #micLevel>
      <span class="active"></span>
      <span class="active"> </span>
      <span class="active"></span>
      <span class="active"></span>
      <span class="active"></span>
      <span class="active"></span>
      <span class="active"></span>
      <span class="active"></span>
      <span class="active"></span>
      <span class="active"></span>
      <span class="active"></span>
      <span class="active"></span>
      <span class="active"></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</div>
