import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { UserRoles } from '@app/first-contact/first-contact.component';
import { PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { titles, meetingsTypesList } from './first-contact-meeting-types.variables';

@Component({
  selector: 'app-first-contact-meeting-types',
  templateUrl: './first-contact-meeting-types.component.html',
  styleUrls: ['./first-contact-meeting-types.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirstContactMeetingTypesComponent extends ChangableComponent {
  @Input() selectedRole: UserRoles;
  public titles = titles;
  public meetingsTypesList = meetingsTypesList;

  constructor(
    protected readonly cdr: ChangeDetectorRef,
    public deviceService: DeviceDetectorService,
    protected readonly platform?: PlatformService,
  ) {
    super(cdr, platform);
  }

  public title(): string {
    return this.titles[this.selectedRole];
  }

  public typesList() {
    return this.meetingsTypesList[this.selectedRole];
  }

  public iconName(name: string): string {
    return '#meeting-types-' + name;
  }
}
