import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { setPrices, setPriceTiers } from '@app/store/actions/content.actions';
import { setMyCurrency } from '@app/store/actions/profile.actions';
import { environment } from '@env/environment';
import { DestroyableComponent } from '@models/destroyable.component';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BuyCreditsEndpoint, CancelPlanEndpoint } from 'lingo2-api-models';
import {
  AccountBalance,
  AccountCoupon,
  BillingOperation as BaseBillingOperation,
  BillingPayout,
  BillingTransaction as BaseBillingTransaction,
  currencies,
  CurrencyEnum,
  IBillingSettings,
  IPriceTier,
  ICurrencyAmount,
  IFindBillingOperationFilter,
  IFindBillingPayoutFilter,
  IPagedResults,
  Meeting,
  User,
  IBillingCard,
  BillingProfile,
  IPagination,
  IAccountPaymentInfo,
  BillingPlan,
  IBillingResponse,
  IExchangeRate,
  AccountBalanceTypeEnum,
  Wallet,
  UserService,
  Collection,
  IFindBillingSubscriptionFilter,
  SwitchPlanRequest,
  SwitchPlanResponse,
  AccountPlan,
  IResponse,
  ActivatePlanRequest,
} from 'lingo2-models';
import { IBillingSubscription } from 'lingo2-models/dist';
import { BehaviorSubject, Observable, ReplaySubject, Subscription } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';

export interface IBillingDetails {
  user_service_id?: string;
  collection_id?: string;
}

/** @deprecated */
export class BillingTransaction extends BaseBillingTransaction {
  public from_account?: Partial<User>;
  public from_balance?: Partial<AccountBalance>;
  public to_account?: Partial<User>;
  public to_balance?: Partial<AccountBalance>;

  public constructor(values: Partial<BillingTransaction> = {}) {
    super(values);

    const instance: any = this;
    instance.from_account = values.from_account;
    instance.from_balance = values.from_balance;
    instance.to_account = values.to_account;
    instance.to_balance = values.to_balance;
  }
}

/** @deprecated */
export class BillingOperation extends BaseBillingOperation {
  public meeting?: Partial<Meeting>;
  public collection?: Partial<Collection>;
  public userService?: Partial<UserService>;
  public transactions?: BillingTransaction[];
  public details?: IBillingDetails;

  public constructor(values: Partial<BillingOperation> = {}) {
    super(values);

    const instance: any = this;
    instance.meeting = values.meeting;
    instance.collection = values.collection;
    instance.userService = values.userService;
    instance.transactions = values.transactions || [];
    instance.details = values.details || null;
  }
}

// @see https://javascript.ru/php/number_format
// Format a number with grouped thousands
//
// +   original by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
// +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
// +	 bugfix by: Michael White (http://crestidg.com)
export function number_format(value: number, decimals = 2, dec_point = '.', thousands_sep = ' ') {
  let _value;
  let j;

  _value = (+value || 0).toFixed(decimals);
  _value = parseInt(_value, 10) + '';

  if ((j = _value.length) > 3) {
    j = j % 3;
  } else {
    j = 0;
  }

  const km = j ? _value.substr(0, j) + thousands_sep : '';
  const kw = _value.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands_sep);
  // kd = (decimals ? dec_point + Math.abs(number - i).toFixed(decimals).slice(2) : '');
  const kd = decimals
    ? dec_point +
      Math.abs(value - _value)
        .toFixed(decimals)
        .replace(/-/, '0')
        .slice(2)
    : '';
  return km + kw + kd;
}

const billing_url = `${environment.account_url}/billing`;

export interface PaywallModalOptions {
  /** Откуда вызвали */
  caller: string;

  /** Причина вызова */
  reason: string;

  /** Рекомендованный план для покупки (чтобы пропустиь выбор тарифа) */
  recommendedPlan?: BillingPlan;
}

@Injectable({
  providedIn: 'root',
})
export class BillingService extends DestroyableComponent {
  private _paywallOptions = this.register(new BehaviorSubject<PaywallModalOptions>(null));
  public paywallOptions$ = this._paywallOptions.asObservable();
  private settingsSubject = this.register(new BehaviorSubject<IBillingSettings>(null));
  private settings$ = this.settingsSubject.asObservable();
  private _priceTierData$: { [key: string]: ReplaySubject<IPriceTier> } = {};
  private user: User;
  private version = 1;
  private _settingsLoading$$: Subscription;

  public constructor(private http: HttpClient, private readonly store: Store, private translate: TranslateService) {
    super();
  }

  public setUser(user: User) {
    this.user = user;
    this.getSettings$(true).pipe(takeUntil(this.destroyed$)).subscribe();
  }

  /** @deprecated используйте this.store.select(getPriceTier({ id })) */
  public getPriceTier$(price_tier_id: number): Observable<IPriceTier> {
    if (!(price_tier_id in this._priceTierData$)) {
      this._priceTierData$[price_tier_id] = this.register(new ReplaySubject<IPriceTier>(1));
    }
    return this._priceTierData$[price_tier_id];
  }

  private definePriceTier$(price_tier: IPriceTier) {
    if (!(price_tier.id in this._priceTierData$)) {
      this._priceTierData$[price_tier.id] = this.register(new ReplaySubject<IPriceTier>(1));
    }
    this._priceTierData$[price_tier.id].next(price_tier);
  }

  public formatPriceTier(price_tier: IPriceTier): string {
    return this.formatCurrencyAmount(price_tier);
  }

  public formatCurrencyAmount(amount: ICurrencyAmount): string {
    return amount ? this.formatAmount(amount.amount, amount.currency_id) : null;
  }

  public formatAmount(amount: number, currency?: CurrencyEnum): string {
    return amount !== null ? Math.round(amount) + ' ' + this.translate.instant(currencies[currency]?.title) : '';
  }

  public formatAmountFull(amount: number, currency?: CurrencyEnum): string {
    return amount !== null ? Number(amount) + ' ' + this.translate.instant(currencies[currency]?.title) : '';
  }

  public showPaywall(onSuccess: (setting: IBillingSettings) => void, options: PaywallModalOptions) {
    const _continue = () => {
      const settings = this.settingsSubject.value;
      const havePlan = !!settings.plan;
      const isExpired = settings.plan?.expires_at?.getTime() < Date.now();
      const isPaid = !!settings.plan?.plan?.price_tier_id;
      const isTrial = settings.plan?.plan?.is_trial;
      if (havePlan && (isPaid || isTrial) && !isExpired) {
        onSuccess(settings);
      } else {
        this._paywallOptions.next(options);
      }
    };

    if (this._settingsLoading$$) {
      const _waiting = this.setInterval(() => {
        if (!this._settingsLoading$$) {
          this.clearInterval(_waiting);
          _continue();
        }
      }, 2500);
    } else {
      _continue();
    }
  }

  public getSettings$(force = false): Observable<IBillingSettings> {
    if (!this.user) {
      this.settingsSubject.next(null);
      return this.settings$;
    }
    if (this._settingsLoading$$) {
      return this.settings$;
    }
    if (!this.settingsSubject.value || force) {
      this._settingsLoading$$?.unsubscribe();
      this._settingsLoading$$ = this._getSettings()
        .pipe(first(), takeUntil(this.destroyed$))
        .subscribe(
          (settings) => {
            this._settingsLoading$$ = null;
            settings.balance = (settings.balance || []).map((balance) => {
              balance.currency = currencies[balance.currency_id];
              return balance;
            });
            if (settings.plan) {
              settings.plan.expires_at = new Date(settings.plan.expires_at);
            }
            (settings.prices || []).forEach((p) => {
              this.definePriceTier$(p);
            });
            this.store.dispatch(setPrices({ prices: settings.prices }));
            this.store.dispatch(setPriceTiers({ priceTiers: settings.priceTiers }));
            this.store.dispatch(setMyCurrency({ currency_id: settings.currency_id }));

            // TODO settings.coupons = (settings.coupons || []).map((coupon) => new AccountCoupon(_coupon));
            this.settingsSubject.next(settings);
          },
          (err: any) => {
            this._settingsLoading$$ = null;
            console.error(err);
          },
        );
    }
    return this.settings$;
  }

  /** Информация о курсах относительно валюты */
  public getExchangeRate(from_currency_id: CurrencyEnum, to_currency_id: CurrencyEnum): Observable<IExchangeRate> {
    const url = `${billing_url}/${this.version}/exchange_rate/${from_currency_id}/${to_currency_id}`;
    return this.http.get<IExchangeRate>(url, { observe: 'response' }).pipe(map((response) => response.body));
  }

  /** Информация о возможностях оплаты пользователя */
  public getAccountPaymentInfo(account_id: string): Observable<IAccountPaymentInfo> {
    const url = `${environment.account_url}/payment_info/${account_id}`;
    return this.http.get<IAccountPaymentInfo>(url, { observe: 'response' }).pipe(map((response) => response.body));
  }

  /** Профиль пользователя для биллинга */
  public getBillingProfile(): Observable<BillingProfile> {
    const url = `${billing_url}/${this.version}/profile`;
    return this.http
      .get<IBillingResponse<BillingProfile>>(url, { observe: 'response' })
      .pipe(map((response) => this.handleBillingResponse<BillingProfile>(response)));
  }

  public updateBillingProfile(profile: Partial<BillingProfile>): Observable<BillingProfile> {
    const url = `${billing_url}/${this.version}/profile`;
    return this.http
      .put<IBillingResponse<BillingProfile>>(url, profile, { observe: 'response' })
      .pipe(map((response) => this.handleBillingResponse<BillingProfile>(response)));
  }

  public getCards(): Observable<IBillingCard[]> {
    const url = `${billing_url}/${this.version}/cards`;
    return this.http
      .get<IBillingResponse<IBillingCard[]>>(url, { observe: 'response' })
      .pipe(map((response) => this.handleBillingResponse<IBillingCard[]>(response)));
  }

  public getCardsForSchool(school_id: string): Observable<IBillingCard[]> {
    const url = `${billing_url}/${this.version}/cards_by_school/${school_id}`;
    return this.http
      .get<IBillingResponse<IBillingCard[]>>(url, { observe: 'response' })
      .pipe(map((response) => this.handleBillingResponse<IBillingCard[]>(response)));
  }

  public updateCard(id: string, values: Partial<IBillingCard>): Observable<IBillingCard> {
    const url = `${billing_url}/${this.version}/card/${id}`;
    return this.http.put<IBillingCard>(url, values, { observe: 'response' }).pipe(map((response) => response.body));
  }

  public removeCard(id: string): Observable<IBillingCard> {
    const url = `${billing_url}/${this.version}/card/${id}`;
    return this.http.delete<IBillingCard>(url, { observe: 'response' }).pipe(map((response) => response.body));
  }

  private _getSettings(): Observable<IBillingSettings> {
    const url = `${billing_url}/${this.version}/settings`;
    return this.http
      .get<IBillingResponse<IBillingSettings>>(url, { observe: 'response' })
      .pipe(map((response) => this.handleBillingResponse<IBillingSettings>(response)));
  }

  /** @deprecated use buyCreditsV2 */
  public buyCredits(amount: number): Observable<BillingTransaction> {
    const url = `${billing_url}/${this.version}/operations/buy-credits?amount=${amount}`;
    return this.http
      .post<IBillingResponse<BillingTransaction>>(url, {}, { observe: 'response' })
      .pipe(map((response) => this.handleBillingResponse<BillingTransaction>(response)));
  }

  public buyCreditsV2(request: BuyCreditsEndpoint.Body): Observable<BuyCreditsEndpoint.Response> {
    const url = `${environment.account_url}/${BuyCreditsEndpoint.path}`;
    return this.http.post<BuyCreditsEndpoint.Response>(url, request, { observe: 'body' });
  }

  /** Plans */
  public getPlans(): Observable<BillingPlan[]> {
    const url = `${billing_url}/2/plans`;
    return this.http.get<BillingPlan[]>(url, { observe: 'response' }).pipe(map((response) => response.body));
  }

  /** @deprecated */
  public switchToPlan(request: SwitchPlanRequest): Observable<SwitchPlanResponse> {
    return this.switchToPlanV2(request);
  }
  public switchToPlanV2(request: SwitchPlanRequest): Observable<SwitchPlanResponse> {
    const url = `${billing_url}/2/plans/switch`;
    return this.http
      .post<SwitchPlanResponse>(url, request, { observe: 'body' })
      .pipe(map((response) => new SwitchPlanResponse(response)));
  }

  public activatePlanV2(request: ActivatePlanRequest): Observable<ActivatePlanRequest> {
    const url = `${billing_url}/2/plans/activate`;
    return this.http.post<ActivatePlanRequest>(url, request, { observe: 'body' }).pipe(map((response) => response));
  }

  public cancelPlanV2(): Observable<CancelPlanEndpoint.Response> {
    const url = `${environment.account_url}/${CancelPlanEndpoint.path}`;
    return this.http.post<CancelPlanEndpoint.Response>(url, {}, { observe: 'body' });
  }

  public getActivePlan(): Observable<AccountPlan | null> {
    const url = `${billing_url}/2/plans/current`;
    return this.http
      .get<IResponse<AccountPlan>>(url, { observe: 'response' })
      .pipe(map((response) => response.body.data));
  }

  public getRecommendedPlan(): Observable<BillingPlan | null> {
    const url = `${billing_url}/2/plans/recommended`;
    return this.http
      .get<IResponse<BillingPlan>>(url, { observe: 'response' })
      .pipe(map((response) => response.body.data));
  }

  public getDefaultPlan(): Observable<BillingPlan | null> {
    const url = `${billing_url}/2/plans/default`;
    return this.http
      .get<IResponse<BillingPlan>>(url, { observe: 'response' })
      .pipe(map((response) => response.body.data));
  }

  /** Получить тарифный план с триалом для текущего пользователя */
  public getActualTrialPlan(): Observable<BillingPlan | null> {
    const url = `${billing_url}/2/plans/actual-trial`;
    return this.http
      .get<IResponse<BillingPlan>>(url, { observe: 'response' })
      .pipe(map((response) => response.body.data));
  }

  public restorePlan(plan_id: string) {
    const payload = {
      plan_id,
    };

    const url = `${billing_url}/2/plans/restore`;
    return this.http
      .post<IBillingResponse<BillingTransaction>>(url, payload, { observe: 'response' })
      .pipe(map((response) => this.handleBillingResponse<BillingTransaction>(response)));
  }

  public getUserBalancesForCurrency(currency_id: CurrencyEnum) {
    const url = `${billing_url}/2/account-balance/${currency_id}`;
    return this.http.get<Record<AccountBalanceTypeEnum, number>>(url, { observe: 'body' });
  }

  public getAvailableAmount(currency_id: CurrencyEnum): Observable<number> {
    return this.getUserBalancesForCurrency(currency_id).pipe(
      map((balances) => {
        const wallet = new Wallet(currency_id);
        wallet.initBalances(balances);
        return wallet.getAvailableBalance();
      }),
    );
  }

  /** Мои подписки */
  public findSubscriptions(
    pagination: IPagination,
    filter: Partial<IFindBillingSubscriptionFilter>,
    details?: Array<IBillingSubscription | string>,
  ): Observable<IPagedResults<IBillingSubscription[]>> {
    const url = `${billing_url}/${this.version}/subscriptions`;
    let params = new HttpParams()
      .set('filter', JSON.stringify(filter))
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString());
    if (details && details.length) {
      params = params.set('details', JSON.stringify(details));
    }
    return this.http.get<IBillingResponse<IBillingSubscription[]>>(url, { params, observe: 'response' }).pipe(
      map((response) => this.handlePagedBillingResponse(response)),
      // catchError(this.handleError),
    );
  }

  /** Отменить подписку */
  public deleteSubscription(id: string): Observable<IBillingSubscription> {
    const url = `${billing_url}/${this.version}/subscriptions/${id}`;
    return this.http.delete<IBillingSubscription>(url, { observe: 'response' }).pipe(map((response) => response.body));
  }

  /** История операций */
  public findOperations(
    filter: Partial<IFindBillingOperationFilter>,
    pagination: IPagination,
  ): Observable<IPagedResults<BillingOperation[]>> {
    const url = `${billing_url}/${this.version}/operations`;
    const params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter', JSON.stringify(filter));
    return this.http.get<IBillingResponse<BillingOperation[]>>(url, { params, observe: 'response' }).pipe(
      map(this.handleBillingOperationsResponse),
      // catchError(this.handleError),
    );
  }

  /** Одна операция */
  public getBillingOperation(id: string): Observable<BillingOperation> {
    const url = `${billing_url}/${this.version}/operations/${id}`;
    return this.http.get<IBillingResponse<BillingOperation>>(url, { observe: 'response' }).pipe(
      map((response) => this.handleBillingResponse<BillingOperation>(response)),
      // catchError(this.handleError),
    );
  }

  public prepareCardAttachment(details?: { school_id?: string; language?: string }): Observable<BillingTransaction> {
    const url = `${billing_url}/${this.version}/operations/init-card-attachment`;
    return this.http
      .post<IBillingResponse<BillingTransaction>>(url, details, { observe: 'response' })
      .pipe(map((response) => this.handleBillingResponse(response, (values) => new BillingTransaction(values))));
  }

  public purchaseMeeting(meeting_id: string, billing_card_id?: string): Observable<BillingOperation> {
    const url = `${billing_url}/${this.version}/operations/purchase-meeting`;
    return this.http
      .post<IBillingResponse<BillingOperation>>(url, { meeting_id, billing_card_id }, { observe: 'response' })
      .pipe(map((response) => this.handleBillingResponse(response)));
  }

  /** История выплат */
  public getPayouts(
    filter: Partial<IFindBillingPayoutFilter>,
    pagination: IPagination,
  ): Observable<IPagedResults<BillingPayout[]>> {
    const url = `${billing_url}/${this.version}/payouts`;
    const params = new HttpParams()
      .set('page', pagination.page.toString())
      .set('page-size', pagination.pageSize.toString())
      .set('filter', JSON.stringify(filter));
    return this.http.get<IBillingResponse<BillingPayout[]>>(url, { params, observe: 'response' }).pipe(
      map(this.handleBillingPayoutsResponse),
      // catchError(this.handleError),
    );
  }

  /** Проверка купона по его коду */
  public checkCoupon(couponCode: string): Observable<AccountCoupon> {
    const url = `${billing_url}/${this.version}/coupons/${couponCode}/check`;
    return this.http
      .post<IBillingResponse<AccountCoupon>>(url, {}, { observe: 'response' })
      .pipe(map(this.handleAccountCouponResponse));
  }

  public completeOperation(billing_operation_id: string, billing_card_id?: string): Observable<BillingOperation> {
    const url = `${billing_url}/${this.version}/operation/${billing_operation_id}/complete`;
    return this.http
      .post<IBillingResponse<BillingOperation>>(url, { billing_card_id }, { observe: 'response' })
      .pipe(map((response) => this.handleBillingResponse(response, (values) => new BillingOperation(values))));
  }

  private handleBillingResponse<T>(response: HttpResponse<IBillingResponse<T>>, transformer?: (arg: any) => any): T {
    if (transformer) {
      return transformer(response.body.payload);
    } else {
      return response.body.payload;
    }
  }

  private handlePagedBillingResponse<T>(
    response: HttpResponse<IBillingResponse<T[]>>,
    transformer?: (arg: T) => T,
  ): IPagedResults<T[]> {
    transformer = transformer || ((arg) => arg);
    const content = (response.body.payload || []).map((item) => transformer(item));
    return {
      results: content,
      page: +response.headers.get('X-Pagination-Page'),
      pageSize: +response.headers.get('X-Pagination-PageSize'),
      total: +response.headers.get('X-Pagination-Total'),
      totalPages: +response.headers.get('X-Pagination-TotalPages'),
    };
  }

  /** @deprecated Сделать более универсальным способом */
  private handleBillingOperationsResponse(
    response: HttpResponse<IBillingResponse<BillingOperation[]>>,
  ): IPagedResults<BillingOperation[]> {
    const content = (response.body.payload || []).map((item) => new BillingOperation(item));
    return {
      results: content,
      page: +response.headers.get('X-Pagination-Page'),
      pageSize: +response.headers.get('X-Pagination-PageSize'),
      total: +response.headers.get('X-Pagination-Total'),
      totalPages: +response.headers.get('X-Pagination-TotalPages'),
    };
  }

  /** @deprecated Сделать более универсальным способом */
  private handleBillingPayoutsResponse(
    response: HttpResponse<IBillingResponse<BillingPayout[]>>,
  ): IPagedResults<BillingPayout[]> {
    const content = (response.body.payload || []).map((item) => new BillingPayout(item));
    return {
      results: content,
      page: +response.headers.get('X-Pagination-Page'),
      pageSize: +response.headers.get('X-Pagination-PageSize'),
      total: +response.headers.get('X-Pagination-Total'),
      totalPages: +response.headers.get('X-Pagination-TotalPages'),
    };
  }

  private handleAccountCouponResponse(response: HttpResponse<IBillingResponse<AccountCoupon>>): AccountCoupon {
    return response.body.payload ? new AccountCoupon(response.body.payload) : null;
  }
}
