<div class="rules-content" [class.tablet]="deviceService.isTablet()" [class.mobile]="deviceService.isMobile()">
  <img [src]="assetsUrl('/assets/images/rules-header.jpg')">

  <div class="info">
    <p class="title">{{'rules.page.title1' | translate}}</p>
    <p class="desc">{{'rules.page.text1' | translate}}</p>
    <p class="desc">{{'rules.page.text2' | translate}}</p>
    <p class="desc">{{'rules.page.text3' | translate}}</p>
    <ol>
      <li>{{'rules.page.option1-1' | translate}}</li>
      <li>{{'rules.page.option1-2' | translate}}</li>
    </ol>

    <p class="title">{{'rules.page.title2' | translate}}</p>

    <ul>
      <li>{{'rules.page.option2-1' | translate}}</li>
      <li>{{'rules.page.option2-2' | translate}}</li>
      <li>{{'rules.page.option2-3' | translate}}</li>
      <li>{{'rules.page.option2-4' | translate}}</li>
    </ul>
    <div [formGroup]="form" class="footer">
      <div class="agreement">
        <app-agreement-terms-field
          formControlName="agreement"
          [agreementText]="'rules.page.accept' | translate"
          [invalid]="form.get('agreement').invalid">
        </app-agreement-terms-field>
      </div>
      <div class="action">
        <on-ui-button state="default"
                      class="primary"
                      [disabled]="!form.get('agreement').value"
                      (click)="hideWidget()"
                      [label]="'rules.page.informed' | translate">
        </on-ui-button>
      </div>
    </div>
  </div>
</div>
