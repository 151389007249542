import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TeacherBadgesWidgetModule } from '@core/widgets/teacher-badges-widget/teacher-badges-widget.module';
import { TranslateModule } from '@ngx-translate/core';

import { OnboardingSharedModule } from '../shared/onboarding-shared.module';

import { OnboardingStudentComponent } from './onboarding-student.component';

@NgModule({
  declarations: [OnboardingStudentComponent],
  imports: [CommonModule, TranslateModule, TeacherBadgesWidgetModule, OnboardingSharedModule],
  exports: [OnboardingStudentComponent],
})
export class OnboardingStudentModule {}
