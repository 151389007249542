import { trigger, transition, useAnimation } from '@angular/animations';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { fadeIn, fadeOut } from '@core/animations';

@Component({
  selector: 'app-blocking-spinner',
  templateUrl: './blocking-spinner.component.html',
  styleUrls: ['./blocking-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('spinner', [
      transition(':enter', useAnimation(fadeIn, { params: { time: '200ms', slide: '5px' } })),
      transition(':leave', useAnimation(fadeOut)),
    ]),
  ],
})
export class BlockingSpinnerComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
