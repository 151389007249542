import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  createNgModule,
  Injector,
  NgModule,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { SendToModalData } from '@app/library/panes/grid-card-universal/grid-card.service';
import { logger } from '@core/helpers/logger';
import { PaywallModalOptions, PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import {
  ApplicationDialogsService,
  UserServiceCheckoutOptions,
} from '@shared/application-dialogs/application-dialogs.service';
import { AnyType } from 'lingo2-models';
import { filter, mergeAll, of, Subscription, tap } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-application-dialogs',
  templateUrl: './application-dialogs.component.html',
  styles: [''],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationDialogsComponent extends ChangableComponent implements OnInit {
  @ViewChild('meetingReschedule', { read: ViewContainerRef })
  public meetingRescheduleContainerRef: ViewContainerRef;

  @ViewChild('userServiceCheckout', { read: ViewContainerRef })
  public userServiceCheckoutContainerRef: ViewContainerRef;

  @ViewChild('gridCardModals', { read: ViewContainerRef })
  public gridCardModalsContainerRef: ViewContainerRef;

  @ViewChild('paywallModal', { read: ViewContainerRef })
  public paywallModalContainerRef: ViewContainerRef;

  private meetingRescheduleComponentRef: ComponentRef<AnyType>;
  private userServiceCheckoutComponentRef: ComponentRef<AnyType>;
  private gridCardModalsComponentRef: ComponentRef<AnyType>;
  private paywallModalComponentRef: ComponentRef<AnyType>;

  private userServiceCheckoutClosed$$: Subscription;
  private gridCardModalsClosed$$: Subscription;
  private paywallModalClosed$$: Subscription;

  public constructor(
    protected dialogsService: ApplicationDialogsService,
    protected injector: Injector,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  public ngOnInit(): void {
    if (!this.isBrowser) {
      return;
    }

    of(
      ...[
        this.watchMeetingToRescheduleId$,
        this.watchUserServiceCheckoutOpened$,
        this.watchUserServiceCheckoutOptions$,
        this.watchGridCardModalOptions$,
        this.watchPaywallModalOpened$,
        this.watchPaywallModalOptions$,
      ],
    )
      .pipe(mergeAll(), takeUntil(this.destroyed$))
      .subscribe();
  }

  protected get watchMeetingToRescheduleId$() {
    return this.dialogsService.meetingToRescheduleId$.pipe(
      filter((id) => !!id),
      first(),
      tap((meetingId) => void this.loadMeetingRescheduleComponent(meetingId)),
    );
  }

  // Ленивая загрузка компонента MeetingRescheduleComponent
  protected async loadMeetingRescheduleComponent(meetingId: string): Promise<void> {
    if (!this.meetingRescheduleContainerRef) {
      return;
    }

    // тут динамически инициируется компонента MeetingRescheduleComponent
    // что соотвествует такому статическому HTML шаблону
    // <app-meeting-reschedule></app-meeting-reschedule>

    const module = await import('../../meetings/meeting-reschedule/meeting-reschedule.component');
    this.meetingRescheduleContainerRef?.clear();
    this.meetingRescheduleComponentRef = this.meetingRescheduleContainerRef?.createComponent(
      module.MeetingRescheduleComponent,
    );
    this.meetingRescheduleComponentRef.changeDetectorRef.detectChanges();
    this.meetingRescheduleComponentRef.instance.meetingId = meetingId;
  }

  protected get watchUserServiceCheckoutOpened$() {
    return this.dialogsService.userServiceCheckoutOpened$.pipe(
      filter((visible) => !visible),
      tap(() => this.resetUserServiceCheckoutWizardComponent()),
    );
  }

  protected get watchGridCardModalOptions$() {
    return this.dialogsService.gridCardModalOptions$.pipe(
      filter((options) => !!options),
      tap((options) => void this.loadGridCardModalComponent(options)),
    );
  }

  protected get watchUserServiceCheckoutOptions$() {
    return this.dialogsService.userServiceCheckoutOptions$.pipe(
      filter((options) => !!options),
      tap((options) => void this.loadUserServiceCheckoutWizardComponent(options)),
    );
  }

  protected get watchPaywallModalOpened$() {
    return this.dialogsService.paywallModalOpened$.pipe(
      filter((visible) => !visible),
      tap(() => this.resetPaywallModalComponent()),
    );
  }

  protected get watchPaywallModalOptions$() {
    return this.dialogsService.paywallModalOptions$.pipe(
      tap((options) => void this.loadPaywallModalComponent(options)),
    );
  }

  /** Создание компоненты <app-user-service-checkout-wizard-dialog> */
  protected async loadUserServiceCheckoutWizardComponent(options: UserServiceCheckoutOptions) {
    if (!this.userServiceCheckoutContainerRef) {
      return;
    }

    // тут динамически инициируется компонента UserServiceCheckoutWizardDialogComponent
    // что соотвествует такому статическому HTML шаблону
    // <app-user-service-checkout-wizard-dialog
    //   (closed)="resetUserServiceCheckoutWizardComponent()"
    //   [teacherId]="options.teacherId"
    //   [userServiceId]="options.userServiceId"
    // ></app-user-service-checkout-wizard-dialog>

    logger.debug('*UserServiceCheckoutWizardDialogComponent init');
    const { UserServiceCheckoutWizardDialogModule } = await import(
      '../checkout-wizards/user-service-checkout-wizard-dialog/user-service-checkout-wizard-dialog.module'
    );
    const moduleRef = createNgModule(UserServiceCheckoutWizardDialogModule, this.injector);
    this.resetUserServiceCheckoutWizardComponent();
    this.userServiceCheckoutComponentRef = this.userServiceCheckoutContainerRef?.createComponent(
      UserServiceCheckoutWizardDialogModule.UserServiceCheckoutWizardDialogComponent,
      { ngModuleRef: moduleRef },
    );
    Object.keys(options).map((key) => this.userServiceCheckoutComponentRef.setInput(key, options[key]));
    this.userServiceCheckoutClosed$$ = this.userServiceCheckoutComponentRef.instance.closed
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.resetUserServiceCheckoutWizardComponent());
    this.userServiceCheckoutComponentRef.instance.initOnDemand();
    this.userServiceCheckoutComponentRef.changeDetectorRef.detectChanges();
    logger.debug('*UserServiceCheckoutWizardDialogComponent inited');
  }

  /** Уничтожение компоненты <app-user-service-checkout-wizard-dialog> */
  protected resetUserServiceCheckoutWizardComponent() {
    this.userServiceCheckoutContainerRef?.clear();
    this.userServiceCheckoutClosed$$?.unsubscribe();
  }

  /** Создание компоненты <app-grid-card-modal> */
  protected async loadGridCardModalComponent(options: SendToModalData) {
    if (!this.gridCardModalsContainerRef) {
      return;
    }

    logger.debug('*GridCardModalComponent init');
    const { GridCardModalModule } = await import(
      '../../library/panes/grid-card-universal/components/grid-card-modal/grid-card-modal.module'
    );
    const moduleRef = createNgModule(GridCardModalModule, this.injector);
    this.resetGridCardModalComponent();
    this.gridCardModalsComponentRef = this.gridCardModalsContainerRef?.createComponent(
      GridCardModalModule.GridCardModalComponent,
      {
        ngModuleRef: moduleRef,
      },
    );
    this.gridCardModalsComponentRef.instance.modalOptions = options;
    this.gridCardModalsClosed$$ = this.gridCardModalsComponentRef.instance.closed
      .pipe(takeUntil(this.destroyed$))
      .subscribe((closed) => {
        this.gridCardModalsComponentRef.instance.modalOptions = {
          data: {
            openedData: null,
            route: closed ? closed : null,
          },
          type: null,
          state: false,
        };
        return this.resetUserServiceCheckoutWizardComponent();
      });
    this.gridCardModalsComponentRef.changeDetectorRef.detectChanges();
    logger.debug('*GridCardModalComponent inited');
  }

  /** Уничтожение компоненты <app-grid-card-modal> */
  protected resetGridCardModalComponent() {
    this.gridCardModalsContainerRef?.clear();
    this.gridCardModalsClosed$$?.unsubscribe();
  }

  protected async loadPaywallModalComponent(options: PaywallModalOptions) {
    if (!this.paywallModalContainerRef || !options) {
      return;
    }

    // тут динамически инициируется компонента UserServiceCheckoutWizardDialogComponent
    // что соотвествует такому статическому HTML шаблону
    // <app-paywall-modal
    //   [options]="options"
    //   (closed)="resetPaywallModalComponent()"
    // ></app-paywall-wrapper>

    const { PaywallModalModule } = await import(
      '../../account/finance-page/components/paywall-modal/paywall-modal.module'
    );
    const moduleRef = createNgModule(PaywallModalModule, this.injector);
    this.resetPaywallModalComponent();
    this.paywallModalComponentRef = this.paywallModalContainerRef?.createComponent(
      PaywallModalModule.PaywallModalComponent,
      { ngModuleRef: moduleRef },
    );
    this.paywallModalComponentRef.instance.options = options;
    this.paywallModalClosed$$ = this.paywallModalComponentRef.instance.closed
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.resetPaywallModalComponent());
    this.paywallModalComponentRef.instance.initOnDemand();
    this.paywallModalComponentRef.changeDetectorRef.detectChanges();
  }

  /** Уничтожение компоненты <app-paywall-modal> */
  protected resetPaywallModalComponent() {
    this.paywallModalContainerRef?.clear();
    this.paywallModalClosed$$?.unsubscribe();
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [ApplicationDialogsComponent],
  exports: [ApplicationDialogsComponent],
})
export class ApplicationDialogsModule {}
