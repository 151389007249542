import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { User } from 'lingo2-models';
import { OnboardingStatusesEnum as OSE } from '../onboarding-widget.component';

import { OnboardingWidgetService } from '../onboarding-widget.service';
import { IChecklistOption } from '../shared/onboarding-checklist/onboarding-checklist.component';

@Component({
  selector: 'app-onboarding-student',
  templateUrl: './onboarding-student.component.html',
  styleUrls: ['./onboarding-student.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingStudentComponent implements OnInit {
  @Input() set me(me: User) {
    this.checklistOptions = this.generateChecklistOptions(me);
    this.checkedCount = this.checklistOptions.filter((x) => x.checked).length;
  }

  public status = OSE.student;
  public isExpanded$ = this.onboardingWidgetService.isStatusExpanded$;

  public checklistOptions: IChecklistOption[];

  public checkedCount: number;

  constructor(private onboardingWidgetService: OnboardingWidgetService) {}

  ngOnInit() {}

  private generateChecklistOptions(me: User): IChecklistOption[] {
    return [
      { label: 'onboarding.checklist.sign-in', checked: true },
      { label: 'onboarding.checklist.name', route: '/profile', checked: !!(me.first_name && me.last_name) },
      { label: 'onboarding.checklist.photo', route: '/profile', checked: !!me.userpic_id },
    ];
  }
}
