import { Action, createReducer, on } from '@ngrx/store';
import { Classroom, ClassroomDetailsType } from 'lingo2-models';

const DEFAULT_DETAILS: ClassroomDetailsType[] = [
  'id',
  'slug',
  'access',
  'author_id',
  'archived_at',
  'author',
  'author:userpic',
  'title',
  'description',
  'type',
  'cover_id',
  'cover:md',
  'stats',
  'can',
  'visit_info',
  // 'last_meeting',
  'user_service',
  'user_service_contract_id',
  'user_service_contract',
];

import * as ClassroomAction from './classroom.actions';

export interface ClassroomState {
  entity: Classroom;
  loading: boolean;
  loaded: boolean;
  details: ClassroomDetailsType[];
  error: string;
}

const initialState: ClassroomState = {
  entity: null,
  loading: false,
  loaded: false,
  details: DEFAULT_DETAILS,
  error: null,
};

const contentReducer = createReducer(
  initialState,

  on(ClassroomAction.requestFail, (state, { error }) => ({ ...state, loading: false, error, loaded: true })),

  on(ClassroomAction.loadClassroomBySlug, (state) => ({ ...state, loading: true, error: null, loaded: false })),

  on(ClassroomAction.loadClassroomBySlugSuccess, (state, { classroom }) => ({
    ...state,
    entity: classroom,
    loading: false,
    loaded: true,
  })),

  on(ClassroomAction.setClassroom, (state, { classroom }) => ({ ...state, entity: classroom })),
);

export function reducer(state = initialState, action: Action) {
  return contentReducer(state, action);
}
