import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { InViewportModule } from 'ng-in-viewport';
import { OnUiButtonModule } from 'onclass-ui';
import { FirstContactAdditionalToolsComponent } from './first-contact-additional-tools/first-contact-additional-tools.component';
import { FirstContactCapabilitiesComponent } from './first-contact-capabilities/first-contact-capabilities.component';
import { FirstContactChooseTutorComponent } from './first-contact-choose-tutor/first-contact-choose-tutor.component';
import { FirstContactConstructorComponent } from './first-contact-constructor/first-contact-constructor.component';
import { FirstContactFaqComponent } from './first-contact-faq/first-contact-faq.component';
import { FirstContactFreeLessonComponent } from './first-contact-free-lesson/first-contact-free-lesson.component';
import { FirstContactMeetingTypesComponent } from './first-contact-meeting-types/first-contact-meeting-types.component';
import { FirstContactProcessesComponent } from './first-contact-processes/first-contact-processes.component';
import { FirstContactSchoolAdvantagesComponent } from './first-contact-school-advantages/first-contact-school-advantages.component';
import { FirstContactSchoolToolsComponent } from './first-contact-school-tools/first-contact-school-tools.component';
import { FirstContactSelectorComponent } from './first-contact-selector/first-contact-selector.component';
import { FirstContactStepsComponent } from './first-contact-steps/first-contact-steps.component';
import { FirstContactToolsComponent } from './first-contact-tools/first-contact-tools.component';
import { FirstContactUserServicesComponent } from './first-contact-user-services/first-contact-user-services.component';
import { FirstContactComponent } from './first-contact.component';

@NgModule({
  declarations: [
    FirstContactComponent,
    FirstContactSelectorComponent,
    FirstContactToolsComponent,
    FirstContactConstructorComponent,
    FirstContactAdditionalToolsComponent,
    FirstContactUserServicesComponent,
    FirstContactMeetingTypesComponent,
    FirstContactFaqComponent,
    FirstContactCapabilitiesComponent,
    FirstContactProcessesComponent,
    FirstContactStepsComponent,
    FirstContactFreeLessonComponent,
    FirstContactChooseTutorComponent,
    FirstContactSchoolAdvantagesComponent,
    FirstContactSchoolToolsComponent,
  ],
  exports: [FirstContactComponent],
  imports: [CommonModule, RouterModule, TranslateModule, InViewportModule, OnUiButtonModule],
})
export class FirstContactModule {}
