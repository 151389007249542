<section class="first-contact-additional-tools" [class.mobile]="deviceService.isMobile()">
  <div class="cards">
    <div class="card card1">
      <span class="title" [innerHTML]="title[0] | translate"></span>
    </div>
    <div class="card card2">
      <span class="title" [innerHTML]="title[1] | translate"></span>
    </div>
    <div class="card card3">
      <span class="title" [innerHTML]="title[2] | translate"></span>
    </div>
  </div>

  <ng-container [ngSwitch]="selectedRole">
    <ng-container *ngSwitchCase="'tutor'">
      <div class="action-wrap">
        <on-ui-button class="secondary"
                      label="first-contact.first-contact-additional-tools.action3"
                      (onclick)="onCreateLesson()">
        </on-ui-button>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'student'">
      <span class="text">{{ 'first-contact.first-contact-additional-tools.text' | translate }}</span>
    </ng-container>
    <ng-container *ngSwitchCase="'methodist'">
      <div class="action-wrap">
        <on-ui-button class="secondary"
                      label="first-contact.first-contact-additional-tools.action1"
                      (onclick)="onCreateLesson()">
        </on-ui-button>
      </div>

      <span class="text2" [innerHTML]="'first-contact.first-contact-additional-tools.text2' | translate"></span>
    </ng-container>
    <ng-container *ngSwitchCase="'school'">
      <div class="action-wrap">
        <on-ui-button class="secondary"
                      label="first-contact.first-contact-additional-tools.action2"
                      (onclick)="onNavigate('schools')">
        </on-ui-button>
      </div>
    </ng-container>
  </ng-container>
</section>
