<div class="nearest-meet-btn-mobile" [ngClass]="{show: isStartCountdown}" *ngIf="meetData?.id && isVisible">
  <div class="nearest-meet-btn-info-holder" [ngClass]="{hide: !dopInfoOpen}" (click)="toggleDopInfo()"></div>

  <div class="nearest-meet-btn-mobile-btn-user-view" [ngClass]="{hide: dopInfoOpen}">
    <div class="nearest-meet-btn-mobile-btn-wrap" (click)="toggleDopInfo()">
      <on-ui-cover [cover]="meetData?.cover"></on-ui-cover>
    </div>
    <div class="onair-wrap" [ngClass]="{hide: !meetData?.onAir}">
      <div class="onair"></div>
    </div>
  </div>


  <div class="nearest-meet-btn-info-data" [ngClass]="{hide: !dopInfoOpen}">
    <div class="onair-wrap" [ngClass]="{hide: !meetData?.onAir}">
      <div class="onair"></div>
    </div>
    <div class="nearest-meet-btn-data-wrap">
      <div class="nearest-meet-btn-data-image">
        <on-ui-cover [cover]="meetData?.cover"></on-ui-cover>
      </div>
      <div class="nearest-meet-btn-data-info">
        <div class="nearest-meet-btn-data-timer">
          <span *ngIf="isStartCountdown && !meetData?.onAir">
            {{'dashboard.nearest-meet-widget.start_in' | translate}} <app-countdown [date]="meetData.beginDate"
                                    [minSeconds]="70"
                                    [minTitle]="'dashboard.time-in-minute'"></app-countdown>
          </span>
          <span *ngIf="!isStartCountdown && !meetData?.onAir">
            <span>{{ meetData.beginDate | dfnsFormat: 'dd MMMM yyyy' }}</span>
          </span>
          <span *ngIf="meetData?.onAir">
            <span>{{'dashboard.nearest-meet-widget.on_air' | translate}}</span>
          </span>
        </div>
        <div class="nearest-meet-btn-data-info-title">{{meetData.title}}</div>
        <div class="nearest-meet-btn-data-info-info" *ngIf="meetData?.is_author">
          <span class="class-type">{{ meetData.meetingTypeName | translate }}</span>&nbsp;
          <span>{{meetData.participants_count}}/{{meetData.participants_limit}}</span>
        </div>
        <div class="nearest-meet-btn-data-info-info" *ngIf="!meetData?.is_author">
          <span class="class-type">{{'dashboard.nearest-meet-widget.tutor' | translate}}:</span>&nbsp;
          <a [routerLink]="[meetData.autorLink]">{{meetData.authorFullName}}</a>
        </div>
      </div>
    </div>
    <div class="nearest-meet-btn-data-info-desc" *ngIf="meetData.description">{{meetData.description}}</div>
    <div class="nearest-meet-btn-data-info-link">
      <on-ui-button class="secondary"
                    label="dashboard.nearest-meet-widget.enter_class"
                    [routerLink]="[meetData.link]"
                    (onclick)="toggleDopInfo()">
      </on-ui-button>
    </div>
  </div>
</div>
