<app-base-notification [base]="base">

  <div [innerHTML]="'notifications.meeting_invite.text' | translate: {'teacher': teacher,'title': title}"></div>

  <on-ui-button class="primary outline"
                [label]="'notifications.meeting_started.action'"
                [routerLink]="params.meeting_join_url | relativeUrl">
  </on-ui-button>

</app-base-notification>
