import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ChooseTutorList } from './first-contact-choose-tutor.variables';

@Component({
  selector: 'app-first-contact-choose-tutor',
  templateUrl: './first-contact-choose-tutor.component.html',
  styleUrls: ['./first-contact-choose-tutor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirstContactChooseTutorComponent extends ChangableComponent {
  public chooseTutorList = ChooseTutorList;

  constructor(
    protected readonly cdr: ChangeDetectorRef,
    public deviceService: DeviceDetectorService,
    protected readonly platform?: PlatformService,
  ) {
    super(cdr, platform);
  }
}
