import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Event, Router } from '@angular/router';
import { EventActionEnum, EventCategoryEnum } from '@app/core/services/analytics';
import { RemoteConfigType } from '@app/core/services/remote-config/constants';
import { RemoteConfigService } from '@app/core/services/remote-config/remote-config.service';
import { NotificationsService } from '@app/notifications/notifications.service';
import { loadPins, removePin } from '@app/store/actions/profile.actions';
import { CLibraryFilterData, ERedPointsEvents } from '@app/store/models';
import { getMyBillingPlan, getMyPins } from '@app/store/reducers/profile.reducer';
import {
  AccessActionEnum,
  AccountService,
  AnalyticsService,
  AuthService,
  BillingService,
  ClassroomsService,
  ConfigService,
  ContextService,
  CrispChatService,
  FeaturesService,
  FilesService,
  IconsService,
  PlatformService,
  RequestService,
  ScreenService,
  WidgetsEnum,
  WidgetService,
} from '@core/services';
import { PinService } from '@core/services/lingo2-content/pin.service';
import { ChangableComponent } from '@models/changable.component';
import { LibraryRouter } from '@models/library.router';
import { Store } from '@ngrx/store';
import { getLibraryRouteData } from '@store/reducers/library.reducer';
import {
  AccountPlan,
  BillingSubscriptionStatusEnum,
  EContentPanelRoute,
  FeatureEnum,
  ImageSizeEnum,
  PinnedEntity,
  Subject as BaseSubject,
  SubjectCategoryEnum,
  SubjectsRegistry,
  User,
  UserStatusEnum,
} from 'lingo2-models';
import { Router as ContentRouter } from 'lingo2-models/dist/content/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiCover } from 'onclass-ui';
import { combineLatest, debounceTime, filter, map, mergeMap, Subject, takeUntil, tap } from 'rxjs';
import { bottomMenuList, classesSubmenuList, IMenuItem, topMenuList } from './left-sidebar.constants';
import { SidebarService } from './sidebar.service';

const nativeSpokenLevel = 1; // TODO вынести в константы lingo2-models

@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeftSidebarComponent extends ChangableComponent implements OnInit {
  @Input() floating = true;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onClickItem = new EventEmitter<Event>();
  @ViewChild('scroll') scrollRef: ElementRef;

  public routes = EContentPanelRoute;
  public pinnedClassesList: Array<Partial<PinnedEntity>>;
  public me$ = this.contextService.me$;
  public crispChatEnabled$ = this.crispChat.enabled$;
  public svgsetIcon = IconsService.svgsetIconUrl;
  public accountRoute = AccountService.accountRoute;
  public visible = false;
  public userpicUrl: string;
  public name: string;
  public country: string;
  public isGuest: boolean;
  public isTeacher: boolean;
  public isSubjectsFilter = false;
  public isClassesRoute = false;
  public subjectTabState: 'foreign' | 'non_categorized' = 'non_categorized';
  public searchSubjectsTerm = '';
  public searchSubjectsList: BaseSubject[] = [];
  public subjectCategoryEnum = SubjectCategoryEnum;
  public contentFilter: CLibraryFilterData;
  public scrollActive = false;
  public displayAction = false;
  public unreadNotificationsCount$ = this.notificationsService.unreadCount$;
  public width: number;
  public classroomRoute = ClassroomsService.classroomRoute;
  public meetingFormOpened = false;
  public plan: AccountPlan;
  public openResumePlanModal: boolean;
  public currentDayOfWeek: Date = new Date();
  public meetingJoinRoute = ContentRouter.meetingJoinRoute;

  private libRouter: LibraryRouter = new LibraryRouter();
  private _clickOutsideEnabled = false;
  private _timer: any;
  private _me: User;
  private _subjects: SubjectsRegistry;
  private _nativeSubjects: BaseSubject[] = [];
  private _filterData: CLibraryFilterData;
  private search$ = this.register(new Subject<boolean>());
  private remoteConfig: RemoteConfigType;

  constructor(
    private accountService: AccountService,
    private billingService: BillingService,
    private authService: AuthService,
    private contextService: ContextService,
    private configService: ConfigService,
    private features: FeaturesService,
    private screenService: ScreenService,
    private sidebarService: SidebarService,
    private crispChat: CrispChatService,
    private notificationsService: NotificationsService,
    private widgetService: WidgetService,
    private classroomsService: ClassroomsService,
    private router: Router,
    private requestService: RequestService,
    private pinService: PinService,
    private readonly store: Store,
    private remoteConfigService: RemoteConfigService,
    private analytics: AnalyticsService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
    public deviceService: DeviceDetectorService,
  ) {
    super(cdr, platform);
  }

  ngOnInit() {
    this.sidebarService.leftState$.pipe(takeUntil(this.destroyed$)).subscribe((visible) => {
      this.visible = visible;
      this.screenService.setBodyFixed(visible);
      this.clickOutsideEnabled = visible;
      this.detectChanges();
    });

    this.screenService.width$.pipe(takeUntil(this.destroyed$)).subscribe((width) => {
      this.width = width;
      this.detectChanges();
    });

    combineLatest([this.configService.subjectsV2$, this.me$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([subjects, me]) => {
        this._subjects = subjects;
        this._me = me;
        this.prepare();
        this.detectChanges();
      });

    this.store
      .select(getMyBillingPlan)
      .pipe(
        tap((plan) => {
          this.plan = plan;
          this.detectChanges();
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe();

    this.store
      .select(getLibraryRouteData)
      .pipe(filter((v) => !!v))
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        this._filterData = data;
        this.contentFilter = data;
        this.libRouter.setBaseRoute(this.contentFilter.baseRoute);
        // this.isSubjectsFilter = this.setIsLibraryRouter();
        this.detectChanges();
      });

    if (this.features.isAvailable(FeatureEnum.pins)) {
      this.store
        .select(getMyPins)
        .pipe(
          filter((pinned) => !!pinned),
          map((pinned) => {
            this.pinnedClassesList = pinned;
            return pinned;
          }),
          mergeMap(() => this.screenService.height$),
          takeUntil(this.destroyed$),
        )
        .subscribe(() => {
          const offsetHeight = this.scrollRef?.nativeElement.offsetHeight;
          const offsetHeightList = this.scrollRef?.nativeElement?.querySelector('.menu-with-pins')?.offsetHeight;
          this.displayAction = offsetHeight < offsetHeightList;
          this.scrollActive = true;
          this.detectChanges();
        });
      this.store.dispatch(loadPins());
    }

    this.search$.pipe(debounceTime(500), takeUntil(this.destroyed$)).subscribe(() => this.filterSubjectsByTerm());

    this.features.tenant$.pipe(takeUntil(this.destroyed$)).subscribe(() => this.detectChanges());

    this.remoteConfigService.config$.pipe(takeUntil(this.destroyed$)).subscribe((config) => {
      this.remoteConfig = config;
      this.detectChanges();
    });
  }

  public getPinCover(cover_id): OnUiCover {
    return { img: FilesService.getFileUrlBySize(cover_id, ImageSizeEnum.wide), aspect: '16/9' };
  }

  public get topMenuList() {
    if (this.isMobileMeVersion) {
      return topMenuList.filter((item) => item.name === 'banner-plan');
    }
    return topMenuList
      .filter((item) => {
        switch (item.name) {
          case 'sidebar-teachers-room':
            return this.isTeacher;

          case 'menu-home':
            return this.features.isAvailable(FeatureEnum.main);

          case 'my-library':
            return !!this.isTeacher;

          case 'banner-plan':
            return (
              this.features.isAvailable(FeatureEnum.finance_plans) &&
              !!this.isTeacher &&
              !!this.remoteConfig?.appLeftSidebar__bannerPlan__show
            );

          case 'sidebar-classrooms':
            return this.features.isAvailable(FeatureEnum.classrooms);

          case 'my-feed':
            return !this.isLimitedVersion;

          case 'sidebar-favorites2':
            return this.features.isAvailable(FeatureEnum.favorites_page);

          case 'sidebar-users':
            return this.features.isAvailable(FeatureEnum.following);

          case 'sidebar-notifications':
            return this.features.isAvailable(FeatureEnum.notifications) && this.deviceService.isMobile();

          case 'sidebar-quick-class':
            return (
              this.accountService.can(AccessActionEnum.canCreateDraftMeeting, this._me) &&
              !!this.remoteConfig?.appLeftSidebar__quickMeeting__show
            );

          case 'sidebar-schedule-class':
            return (
              this.accountService.can(AccessActionEnum.canCreateMeeting, this._me) &&
              !!this.remoteConfig?.appLeftSidebar__scheduleMeeting__show
            );

          case 'plan':
            return this.features.isAvailable(FeatureEnum.finance_plans);

          default:
            return true;
        }
      })
      .map((item) => {
        switch (item.name) {
          case 'sidebar-profile2':
            item.route = `/u/${this._me?.slug}`;
            break;
        }

        return item;
      });
  }

  public get isLimitedVersion(): boolean {
    return FeaturesService.isLimitedVersion;
  }

  public get isMobileMeVersion() {
    return this.isLimitedVersion && !this.deviceService.isDesktop();
  }

  public get bottomMenuList() {
    return bottomMenuList;
  }

  public get classesSubmenuList() {
    return classesSubmenuList;
  }

  public onScroll() {
    const scrollElem = this.scrollRef.nativeElement;
    if (this.scrollActive) {
      scrollElem.scrollTop = 0;
    } else {
      scrollElem.scrollTop = scrollElem.scrollHeight - scrollElem.offsetHeight;
    }
    this.scrollActive = !this.scrollActive;
    this.detectChanges();
  }

  public currentSubjectsList(): BaseSubject[] {
    if (!this._subjects) {
      return [];
    }

    if (!!this.searchSubjectsTerm) {
      return this.searchSubjectsList;
    }
    if (this.subjectTabState === 'foreign') {
      return this._subjects.foreign;
    }
    if (this.subjectTabState === 'non_categorized') {
      return [...this._subjects.non_categorized, ...this._nativeSubjects].sort(
        (a, b) => (a.title > b.title && 1) || -1,
      );
    }
  }

  public toggleResumePlan() {
    this.openResumePlanModal = !this.openResumePlanModal;
    this.detectChanges();
  }

  public onCheckScheduleMeeting() {
    if (this.plan?.subscription_status !== BillingSubscriptionStatusEnum.not_active) {
      this.onScheduleMeeting();
    } else {
      this.openResumePlanModal = true;
    }
  }

  public onScheduleMeeting() {
    if (this.floating) {
      this.closeSidebar();
    }

    const options = {
      caller: 'app-left-sidebar',
      reason: 'schedule meeting',
    };

    this.analytics.event(EventActionEnum.meeting_scheduling, EventCategoryEnum.service, options.caller);

    const _continue = () => {
      if (this.remoteConfig?.scheduleMeeting__paywall) {
        // показать выбор тарифа, а если тариф есть, то отправить на целевую страницу
        this.billingService.showPaywall(() => this.openMeetingForm(), options);
      } else {
        // сразу отправить на целевую страницу
        this.openMeetingForm();
      }
    };

    if (this.remoteConfig?.scheduleMeeting__auth) {
      // показать диалог авторизации, а после авторизации продолжить
      this.authService.showAuthModal(() => _continue(), options);
    } else {
      // продолжить даже если не авторизован
      _continue();
    }
  }

  /** Создание быстрого митинга */
  public onQuickMeeting() {
    if (this.floating) {
      this.closeSidebar();
    }

    const options = {
      caller: 'app-left-sidebar',
      reason: 'create quick meeting',
    };

    this.analytics.event(EventActionEnum.meeting_quick_creating, EventCategoryEnum.service, options.caller);

    const _continue = () => {
      if (this.remoteConfig?.quickMeeting__paywall) {
        // показать выбор тарифа, а если тариф есть, то отправить на целевую страницу
        this.billingService.showPaywall(() => this.goDraftMeeting(), options);
      } else {
        // сразу отправить на целевую страницу
        this.goDraftMeeting();
      }
    };

    if (this.remoteConfig?.quickMeeting__auth) {
      // показать диалог авторизации, а после авторизации продолжить
      this.authService.showAuthModal(() => _continue(), options);
    } else {
      // продолжить даже если не авторизован
      _continue();
    }
  }

  public onMenuClick() {
    if (this.floating) {
      this.closeSidebar();
    }
  }

  public openMeetingForm() {
    this.meetingFormOpened = true;
    this.detectChanges();
  }

  public closeMeetingForm() {
    this.meetingFormOpened = false;
    this.detectChanges();
  }

  public onSearchSubjects() {
    this.search$.next(true);
  }

  private goDraftMeeting() {
    try {
      const url = this.requestService.host + this.router.createUrlTree(['/go/class']).toString();
      window.open(url, '_blank');
    } catch (e) {
      this.router.navigateByUrl('/go/class');
    }
  }

  private filterSubjectsByTerm() {
    const filtered: BaseSubject[] = [];

    if (!!this.searchSubjectsTerm) {
      [...this._subjects.foreign, ...this._subjects.non_categorized, ...this._nativeSubjects].map((_item) => {
        if (_item.title.toLowerCase().indexOf(this.searchSubjectsTerm.toLowerCase()) >= 0) {
          filtered.push(_item);
        }
      });
    }

    this.searchSubjectsList = filtered.sort((a, b) => +b.title - +a.title);
    this.detectChanges();
  }

  public onClearSearch() {
    this.searchSubjectsList = [];
    this.searchSubjectsTerm = '';
  }

  public trackByFn(item) {
    return item.fullName;
  }

  public trackMenuByFn(index, item: IMenuItem): string {
    return item.name;
  }

  public get clickOutsideEnabled(): boolean {
    return this._clickOutsideEnabled;
  }

  public set clickOutsideEnabled(value: boolean) {
    this.onBrowserOnly(() => {
      if (value) {
        this.clearTimeout(this._timer);
        this._timer = this.setTimeout(() => {
          this._clickOutsideEnabled = true;
          this.detectChanges();
        }, 200);
      } else {
        this._clickOutsideEnabled = false;
        this.detectChanges();
      }
    });
  }

  public onClick(e: Event) {
    this.onClickItem.emit(e);
  }

  public selectedSubjects(subject: BaseSubject): boolean {
    const subjectCodes = this._filterData ? this._filterData.subjectCodes || [] : [];
    return subjectCodes.includes(subject.code);
  }

  public closeSidebar() {
    this.sidebarService.setLeftSidebarState(false);
  }

  public openCrispChat(e: MouseEvent) {
    e.preventDefault();
    e.cancelBubble = true;
    this.crispChat.openChat();
  }

  public openNotifications(): void {
    this.closeSidebar();
    this.widgetService.setActive(WidgetsEnum.notifications);
    this.notificationsService.openWidget();
  }

  public authorize() {
    this.authService.showAuthModal();
  }

  public get isAuthenticated() {
    return this.authService.isAuthenticated;
  }

  public onSelectSubjectsTab(tab: 'foreign' | 'non_categorized') {
    this.subjectTabState = tab;
    this.detectChanges();
  }

  public routeLinkByItem(subject: BaseSubject) {
    this.libRouter.setSubject(subject.code);
    return this.libRouter.getLink();
  }

  public redPointEvent(name: string): ERedPointsEvents {
    switch (name) {
      case 'my-classes':
        return ERedPointsEvents.sidebar_my_classes;
      case 'my-library':
        return ERedPointsEvents.sidebar_my_library;
      case 'sidebar-favorites2':
        return ERedPointsEvents.bookmark;
      default:
        return null;
    }
  }

  private prepare() {
    this.isGuest = this._me && this._me.status === UserStatusEnum.guest;
    this.isTeacher = AccountService.isAsIfTeacher(this._me);
    this.userpicUrl =
      this._me && this._me.userpic && this._me.userpic.sm ? this.assetsUrl(this._me.userpic.sm.url) : null;
    this.name = AccountService.getUserFullName(this._me);
    this.country = this._me && this._me.country ? this._me.country.title : null;
    if (this._me) {
      this.nativeSubjectPrepared();
    }
  }

  /**
   * Список ID языков, которыми владеет текущий пользователь
   */
  private get nativeSpokenLanguageIds(): number[] {
    return this._me.spoken_languages
      .filter((lang) => +lang.level_id === nativeSpokenLevel)
      .map((lang) => +lang.language_id);
  }

  private nativeSubjectPrepared() {
    if (!this._subjects || !this._subjects.native || !this._subjects.native.length) {
      return;
    }
    const nativeSpokenIds = this.nativeSpokenLanguageIds;
    this._nativeSubjects = this._subjects.native.filter((_s) =>
      nativeSpokenIds.find((_nId) => _nId === +_s.language_id),
    );
  }

  public trackPinByFn(index, item: PinnedEntity): string {
    return item.id;
  }

  public unpinClass(pin: PinnedEntity): void {
    this.store.dispatch(removePin({ pin_id: pin.id }));
  }

  public navigate(id: string) {
    this.classroomsService
      .getClassroomById(id, ['title', 'slug'])
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        this.router.navigate(this.classroomRoute(res));
        this.detectChanges();
      });
  }

  public isActiveRoute(itemMenu: IMenuItem) {
    if (
      itemMenu.route === '/my-classes/dashboard' ||
      itemMenu.route === '/my-library/lessons' ||
      itemMenu.route === '/favorites/lessons' ||
      itemMenu.route === '/my-vocabulary/main'
    ) {
      const baseRoute = itemMenu.route.split('/')[1];
      return this.router.url.split('/')[1].includes(baseRoute);
    }
    return false;
  }
}
