import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { IconModule } from '@app/shared';
import { PipesModule } from '@core/pipes/pipes.module';
import { ConfigService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { getMe } from '@store/reducers/profile.reducer';
import { combineLatest, distinctUntilChanged, map, tap } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-timezone',
  standalone: true,
  imports: [CommonModule, TranslateModule, IconModule, PipesModule],
  templateUrl: './timezone.component.html',
  styleUrls: ['./timezone.component.scss'],
})
export class TimezoneComponent extends ChangableComponent implements OnInit {
  public timeZone: string;

  constructor(private configService: ConfigService, private store: Store, public cdr: ChangeDetectorRef) {
    super(cdr);
  }

  ngOnInit() {
    this.onBrowserOnly(() => {
      this.configService.load(['timezones$']);
      combineLatest([this.watchMyTimezone$, this.watchTimezones$])
        .pipe(
          tap(([myTimezone, timezones]) => {
            const tz = timezones.find((el) => el.name === myTimezone);
            if (tz) {
              this.timeZone = `(${this.timezoneOffsetToHours(tz.offset)}) ${tz.name}`;
            } else {
              this.timeZone = null;
            }
            this.detectChanges();
          }),
          takeUntil(this.destroyed$),
        )
        .subscribe();
    });
  }

  public timezoneOffsetToHours(offset: number): string {
    const sign = offset < 0 ? '-' : '+';
    const hhmm = new Date(Math.abs(offset) * 60 * 1000).toISOString().substr(11, 5);
    return sign + hhmm;
  }

  protected get watchMyTimezone$() {
    return this.store.select(getMe).pipe(
      filter((me) => !!me),
      map((me) => me?.timezone),
      distinctUntilChanged(),
    );
  }

  protected get watchTimezones$() {
    return this.configService.timezones$.pipe(filter((timezones) => !!timezones));
  }
}
