<section class="first-contact-school-tools" [class.mobile]="deviceService.isMobile()">
  <h2 class="title">{{ 'first-contact.school-tools.title' | translate }}</h2>

  <div class="school-tools-list">
    <div *ngFor="let item of toolsList" class="school-tools-item">
      <h3 class="item-title">{{ item.title | translate }}</h3>
      <div class="image" [ngClass]="item.type"></div>
      <p class="item-text">{{ item.text | translate }}</p>
    </div>
  </div>

  <span class="text" [innerHTML]="'first-contact.school-tools.text' | translate"></span>

  <on-ui-button class="secondary"
                label="first-contact.school-tools.action"
                (onclick)="onNavigate('/schools')">
  </on-ui-button>
</section>
