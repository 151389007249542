<div class="http-error-notifier">
  <div class="notifications__button" (click)="openError()" *ngIf="isDesktop">
    <div class="btn-image"></div>
    <div class="error-counter">1</div>
  </div>


  <app-modal class="no-padding" *ngIf="showError" [canClose]="false" (closed)="closeError()">
    <app-modal-body>
      <div class="notification error red">
        <p class="phrase-text normal">{{ error }}</p>

        <div class="actions-wrap">
          <on-ui-button (click)="refreshPage()"
                        class="transparent"
                        state="default"
                        label="Обновить">
          </on-ui-button>
        </div>
      </div>
    </app-modal-body>
  </app-modal>

</div>
