import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '@core/pipes/pipes.module';

import { OnboardingTitleComponent } from './onboarding-title.component';

@NgModule({
  declarations: [OnboardingTitleComponent],
  imports: [CommonModule, PipesModule],
  exports: [OnboardingTitleComponent],
})
export class OnboardingTitleModule {}
