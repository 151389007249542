<div class="button bold" (click)="openModal()">{{ label }}</div>

<ng-template #languagesSelector let-isTitle="isTitle">
  <div class="languages-selector">
    <ng-template [ngIf]="isTitle">
      <div class="title bold">{{ 'language-selector.choose-language' | translate }}</div>
    </ng-template>
    <div class="lists-wrap">
      <div *ngFor="let lang of languages" class="lang-list">
        <div class="lang-item medium" [class.active]="language.id === lang.id" (click)="setLanguage(lang)">{{ lang.title | translate }}</div>
      </div>
    </div>
  </div>
</ng-template>

<app-modal *ngIf="modalOpen && !isSmallScreen" (closed)="closeModal()">
  <app-modal-body>
    <ng-container *ngTemplateOutlet="languagesSelector; context:{ isTitle: true }"></ng-container>
  </app-modal-body>
</app-modal>

<app-mobile-modal *ngIf="isSmallScreen && modalOpen" (closed)="closeModal()">
  <app-mobile-modal-title>{{ 'language-selector.choose-language' | translate }}</app-mobile-modal-title>
  <app-mobile-modal-body>
    <ng-container *ngTemplateOutlet="languagesSelector; context:{ isTitle: false }"></ng-container>
  </app-mobile-modal-body>
</app-mobile-modal>

