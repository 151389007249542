import { createAction, props } from '@ngrx/store';
import { IPagedResults, ClassroomParticipant } from 'lingo2-models';

export const requestFail = createAction('[Classroom Meetings] Request: fail', props<{ error: string }>());

export const loadClassroomParticipants = createAction('[Classroom Participants] Load participants');
export const loadClassroomParticipantsSuccess = createAction(
  '[Classroom Participants] Load participants: success',
  props<{ response: IPagedResults<ClassroomParticipant[]> }>(),
);

export const clearClassroomParticipants = createAction('[Classroom Participants] Clear');

export const loadNextPageClassroomParticipants = createAction('[Classroom Participants] Load next page');

export const pushClassroomParticipants = createAction(
  '[Classroom Participants] Push participants',
  props<{ participants: ClassroomParticipant[] }>(),
);
