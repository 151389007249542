import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ImageCroppedEvent } from 'ngx-image-cropper';

export type CropType = 'square' | 'rectangle' | 'wide' | 'wide-banner' | 'vertical-wide';

@Component({
  selector: 'app-image-crop',
  templateUrl: './image-crop.component.html',
  styleUrls: ['./image-crop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageCropComponent extends ChangableComponent {
  @Input() imageURL = '';
  @Input() aspectRatio?: CropType = 'square';

  @Output() changeImage: EventEmitter<{ type: string; data?: string }> = new EventEmitter();
  croppedImage = '';
  loading = true;
  ready = false;
  _autoCrop = false;

  constructor(
    protected readonly cdr: ChangeDetectorRef,
    public readonly deviceService: DeviceDetectorService,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded(): void {
    this.loading = false;
  }

  onCropImage(): void {
    this.changeImage.emit({ type: 'imageCropped', data: this.croppedImage });
  }

  onClose(): void {
    this.changeImage.emit({ type: 'cancelCropped' });
  }

  public get calcAspectRatio() {
    switch (this.aspectRatio) {
      case 'square':
        return 1;
      case 'rectangle':
        return Number((290 / 190).toFixed(2));
      case 'wide':
        return Number((16 / 9).toFixed(2));
      case 'wide-banner':
        return Number((830 / 200).toFixed(2));
      case 'vertical-wide':
        return Number((9 / 16).toFixed(2));
      default:
        return 1;
    }
  }
}
