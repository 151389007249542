import { Directive, OnDestroy } from '@angular/core';
import { PlatformService } from '@core/services/platform.service';
import { environment } from '@env/environment';
import { addMilliseconds } from 'date-fns';
import { trimEnd } from 'lodash';
import { interval, Subject, Subscription, timer } from 'rxjs';
import { first, take, takeUntil } from 'rxjs/operators';

type TimerLike = number | Subscription;

@Directive()
export abstract class DestroyableComponent implements OnDestroy {
  /**
   * NOTE(!) Если требуется логика с ChangeDetectorRef - используй ChangableComponent
   *
   * @see ChangableComponent
   */

  public assets_url: string = trimEnd(environment.assets_url, '/');
  public readonly isBrowser: boolean;
  public readonly isServer: boolean;
  public readonly isAutotestAgent: boolean;
  protected readonly completable: Array<Subject<any>> = [];
  protected readonly destroyed$ = new Subject<boolean>();

  public constructor(protected readonly platform?: PlatformService) {
    if (platform) {
      this.isBrowser = platform.isBrowser;
      this.isServer = platform.isServer;
    } else {
      // По умолчанию считаем, что мы в браузере
      this.isServer = false;
      this.isBrowser = true;
    }

    this.isAutotestAgent = this.isBrowser ? navigator.userAgent?.includes('functional-test') : false;
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();

    this.completable.map((s) => s.complete());
    this.completable.splice(0);
  }

  public assetsUrl(url: string): string {
    if (!url) {
      return null;
    }
    const isAbsolute = url.indexOf('http://') === 0 || url.indexOf('https://') === 0 || url.indexOf('//') === 0;
    return isAbsolute ? url : this.assets_url + url;
  }

  protected register<T extends Subject<any>>(instance: T): T {
    this.completable.push(instance);
    return instance;
  }

  protected setTimeout(func: () => void, timeout: number): Subscription {
    if (this.isBrowser === null || this.isBrowser === undefined) {
      throw new Error(`RuntimeError: 'platform' not injected in ${this.constructor.name}`);
    }

    const dueTime = addMilliseconds(new Date(), timeout);

    if (this.isBrowser) {
      return timer(dueTime).pipe(first(), takeUntil(this.destroyed$)).subscribe(func);
    } else {
      func();
      return null;
    }
  }

  protected clearTimeout(timerLike: TimerLike) {
    if (!timerLike) {
      return;
    }
    if (typeof timerLike === 'number') {
      clearTimeout(timerLike);
    } else {
      if ('unsubscribe' in timerLike) {
        timerLike.unsubscribe();
      }
    }
  }

  protected setInterval(func: () => void, period: number, count = 0): Subscription {
    if (this.isBrowser === null || this.isBrowser === undefined) {
      throw new Error(`RuntimeError: 'platform' not injected in ${this.constructor.name}`);
    }

    if (this.isBrowser) {
      let _interval = interval(period);
      if (count > 0) {
        _interval = _interval.pipe(take(count));
      }
      return _interval.pipe(takeUntil(this.destroyed$)).subscribe(func);
    } else {
      func();
      return null;
    }
  }

  protected clearInterval(intervalLike: TimerLike) {
    if (!intervalLike) {
      return;
    }
    if (typeof intervalLike === 'number') {
      clearInterval(intervalLike);
    } else {
      if ('unsubscribe' in intervalLike) {
        intervalLike.unsubscribe();
      }
    }
  }

  protected onBrowserOnly(fn: () => void) {
    if (this.isBrowser === null || this.isBrowser === undefined) {
      throw new Error(`RuntimeError: 'platform' not injected in ${this.constructor.name}`);
    }

    if (this.isBrowser) {
      fn();
    }
  }

  protected onServerOnly(fn: () => void) {
    if (this.isBrowser === null || this.isBrowser === undefined) {
      throw new Error(`RuntimeError: 'platform' not injected in ${this.constructor.name}`);
    }

    if (this.isServer) {
      fn();
    }
  }
}
