<figure class="donut-chart">
  <svg width="100%" height="100%" viewBox="2 2 38 38" class="donut">
    <circle cx="21" cy="21" r="15.91549430918952" fill="transparent" stroke="#EEEEEE" stroke-width="2.5">
    </circle>
    <circle cx="21" cy="21" r="15.91549430918952" fill="transparent" [attr.stroke]="color" stroke-width="2.5"
      [attr.stroke-dasharray]="getValue" stroke-dashoffset="25">
    </circle>
    <g>
      <text x="55%" y="60%" class="donut-chart__text">{{ value }}/{{ max }}</text>
    </g>
  </svg>
</figure>