<div class="image-bg" *ngIf="standalone"></div>
<app-modal [authPage]="standalone" [auth]="!isPoll" [wide2]="isPoll" *ngIf="modalVisible && !deviceService.isMobile()" [canClose]="false" (closed)="onCancel()">
  <app-modal-body>
    <ng-container *ngTemplateOutlet="authTemplate"></ng-container>
  </app-modal-body>
</app-modal>

<app-mobile-modal *ngIf="modalVisible && deviceService.isMobile()"
                  (closed)="onCancel()"
                  [titleVisible]="false"
                  [withCloseButton]="true">
  <app-mobile-modal-body>
    <ng-container *ngTemplateOutlet="authTemplate"></ng-container>
  </app-mobile-modal-body>
</app-mobile-modal>

<ng-template #authTemplate>
  <div class="back" [class.mobile]="deviceService.isMobile()" *ngIf="showBackButton" (click)="back()">
    <svg viewBox="0 0 11 18">
      <use [attr.xlink:href]="svgSetIcon('arrow-right-back')"></use>
    </svg>
  </div>

  <div [@auth] class="auth-wrap">
    <div>
      <div [formGroup]="form" class="auth" *ngIf="!isPoll">
    <div class="form-block" [class.mobile]="deviceService.isMobile()">
      <svg class="logo" viewBox="0 0 45 45">
        <use [attr.xlink:href]="svgSetIcon('logo')"></use>
      </svg>

      <div *ngIf="isSignInSocial" class="form-content signin">
        <div>
          <p class="text sign-in">{{ 'auth.signin.action1' | translate }}</p>

          <app-auth-input-field [form]="form"
                                dataTestName="email"
                                type="email"
                                [placeholder]="'auth.email'"
                                controlName="email"
                                [showValidation]="showValidationEmailOnly"
                                (onEnter)="doAction()">
          </app-auth-input-field>

          <app-auth-input-field [form]="form"
                                dataTestName="password"
                                type="password"
                                [placeholder]="'auth.password'"
                                controlName="password"
                                [showValidation]="showValidation"
                                (onEnter)="doAction()">
          </app-auth-input-field>
          <p class="text-no-remember"
             (click)="forgotPasswordCheckAvailability()">{{ 'auth.signin.forgot-password' | translate }}</p>

          <ng-container *ngIf="isCaptcha">
            <re-captcha (resolved)="resolvedCaptcha('restore')"
                        [siteKey]="siteKey">
            </re-captcha>
          </ng-container>

          <button *ngIf="!isCaptcha"
                  data-test="submit"
                  type="button" class="button signin normal"
                  [disabled]="!actionState.default"
                  [class.error]="actionState.error" (click)="doAction()">
            <ng-template [ngIf]="actionState.default">{{ 'auth.signin.action1' | translate }}</ng-template>
            <ng-template [ngIf]="actionState.progress"> . . .</ng-template>
            <ng-template [ngIf]="actionState.done">OK</ng-template>
            <ng-template [ngIf]="actionState.error">( x_x )</ng-template>
          </button>
          <button *ngIf="!form.controls['email'].value" type="button" (click)="changeMode('signup')"
                  class="button new-account normal">
            {{ 'auth.signin.create-new-account' | translate }}
          </button>
          <div class="error-content" *ngIf="action_error">{{ action_error | translate }}</div>
        </div>

        <div class="text-center" *ngIf="!hideSocialLinks">
          <span class="route-btn route-signin normal"
                [innerHTML]="'auth.signin.action2' | translate">
          </span>
          <div class="form-content signin-social">
            <div class="social-btn facebook normal" (click)="doFacebookLogin()">
              <svg class="icon icon-facebook" viewBox="0 0 20 20">
                <use [attr.xlink:href]="svgSetIcon('auth-facebook')"></use>
              </svg>
              Facebook
            </div>
            <asl-google-signin-button type="standard" size="large" width="155"></asl-google-signin-button>
          </div>
        </div>
      </div>

      <div [class.hidden]="!isSignupEmail" class="form-content signup">
        <div>
          <p class="text sign-in">{{ 'auth.signup.sign-up' | translate}}</p>

          <div class="search-group">
            <div class="role_search-type"
                 [class.invalid]="selectedRole.role===null && showRoleValidation"
                 (click)="showRolesList=!showRolesList">
              <div>
                <svg class="role-question-select__icon" [ngClass]="{'rotate': showRolesList}">
                  <use [attr.xlink:href]="svgSetIcon(selectedRole?.icon_id)"></use>
                </svg>
                <span class="role-label">{{ selectedRole?.label | translate }}</span>
              </div>
              <i [ngClass]="{'rotate': showRolesList}">
                <svg class="role-select__icon" width="12" height="7">
                  <use [attr.xlink:href]="svgSetIcon('chevron-down')"></use>
                </svg>
              </i>
            </div>
            <div class="role-list" [ngClass]="{'show-list': showRolesList}">
              <div class="type-list" *ngFor="let type of roleTypes"
                   (click)="type.role!==null ? selectRoleType(type) : ''">
                <div class="list-label">
                  <svg class="role-question-select__icon" [ngClass]="{'rotate': showRolesList}">
                    <use [attr.xlink:href]="svgSetIcon(type?.icon_id)"></use>
                  </svg>
                  <span class="role-label">{{ type.label | translate }}</span>
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="selectedRole.role===null && showRoleValidation">
            <div class="warn-message">{{ 'error.not-chosen' | translate }}</div>
          </ng-container>

          <ng-container *ngIf="form.controls['teacher_status'].value!==null">
            <app-auth-input-field [form]="form"
                                  type="name"
                                  [placeholder]="'auth.name'"
                                  controlName="first_name"
                                  [showValidation]="showValidation">
            </app-auth-input-field>

            <app-auth-input-field [form]="form"
                                  type="name"
                                  [placeholder]="'auth.surname'"
                                  controlName="last_name"
                                  [showValidation]="showValidation">
            </app-auth-input-field>

            <app-auth-input-field [form]="form"
                                  type="country" [options]="countriesOptions"
                                  [placeholder]="'profile-form.about.country'"
                                  controlName="country_id" (onChanged)="countryChanged($event)"
                                  [showValidation]="showValidation">
            </app-auth-input-field>

            <app-auth-input-field [form]="form"
                                  type="phone"
                                  [placeholder]="'auth.phone-number'"
                                  controlName="mobile_phone"
                                  [countryCode]="selectedCountryCode"
                                  [showValidation]="showValidation">
            </app-auth-input-field>

            <app-auth-input-field [form]="form"
                                  type="email"
                                  [placeholder]="'auth.email'"
                                  controlName="email"
                                  [showValidation]="showValidation">
            </app-auth-input-field>

            <app-auth-input-field [form]="form"
                                  type="password"
                                  [placeholder]="'auth.password'"
                                  controlName="password"
                                  [showValidation]="showValidation">
            </app-auth-input-field>

            <app-auth-input-field *ngIf="isTeacherSelected"
                                  [form]="form"
                                  type="link"
                                  [placeholder]="'auth.account-name'"
                                  controlName="slug"
                                  [showValidation]="showValidation">
            </app-auth-input-field>
          </ng-container>
        </div>

        <div *ngIf="!isCaptcha; else isCaptchaSection">
          <app-agreement-terms-field
            *ngIf="form.controls['teacher_status'].value!==null"
            formControlName="accept"
            [agreementText]="'auth.signup.accept-text' | translate"
            [invalid]="showValidation && form.get('accept').invalid">
          </app-agreement-terms-field>

          <button type="button" class="button signup normal"
                  [disabled]="!actionState.default"
                  [class.error]="actionState.error" (click)="doAction()">
            <ng-template [ngIf]="actionState.default">{{ 'auth.signup.action1' | translate }}</ng-template>
            <ng-template [ngIf]="actionState.progress"> . . .</ng-template>
            <ng-template [ngIf]="actionState.done">OK</ng-template>
            <ng-template [ngIf]="actionState.error">( X_x )</ng-template>
          </button>
        </div>
        <div class="error-content" *ngIf="action_error">{{ action_error | translate }}</div>
        <ng-template #isCaptchaSection>
          <re-captcha (resolved)="resolvedCaptcha('smsFlow'); verifyPhone=true"
                      [siteKey]="siteKey">
          </re-captcha>
        </ng-template>
      </div>

      <div *ngIf="isSMSFlow" class="form-content">
        <div>
          <p class="text sign-in">{{ verifyPhone ? ('auth.signin.action1' | translate) : ('auth.restore.password-recovery' | translate) }}</p>
          <ng-container *ngIf="isPhoneAvailable || verifyPhone; else isPhoneNotAvailable">
            <p class="text send-message">{{ 'auth.signup.send-message' | translate }}<br/>
              {{formattedPhoneNumber}}</p>
            <ng-otp-input (onInputChange)="onOtpChange($event, 'mobile')"
                          [class.long]="codeLength>5"
                          [config]="{allowNumbersOnly: true, length: codeLength, inputClass: isCodeError ? 'is-error' : ''}"></ng-otp-input>
            <p class="text not-received-message">{{ 'auth.signup.not-received-message' | translate }}</p>
          </ng-container>

          <ng-template #isPhoneNotAvailable>
            <p class="text send-message">{{ 'auth.signup.send-message-email' | translate }}</p>
            <ng-otp-input (onInputChange)="onOtpChange($event, 'email')"
                          [class.long]="codeLength>5"
                          [config]="{allowNumbersOnly: true, length: codeLength, inputClass: isCodeError ? 'is-error' : ''}"></ng-otp-input>
            <p class="text not-received-message">{{ 'auth.signup.not-received-message-email' | translate }}</p>
          </ng-template>
        </div>
        <div>
          <div *ngIf="!isSendAgain; else sendAgainBtn">
            <p class="text send-message get-code-again">
              {{ 'auth.signup.get-code-again' | translate }}
              <countdown [config]="{leftTime: 59, format: 'ss'}" (event)="onTimerFinish($event)"></countdown>
              {{ 'auth.signup.sec' | translate }}...
            </p>
          </div>
          <ng-template #sendAgainBtn>
            <button type="button" class="button restore" (click)="sendCodeAgain()">
              {{ 'auth.signup.get-code' | translate }}
            </button>
          </ng-template>
        </div>
      </div>

      <div *ngIf="isEmailRestoredFlow" class="form-content">
        <div>
          <p class="text sign-in" [innerHTML]="'auth.restore.text-success' | translate"></p>
        </div>
        <div>
          <button type="button" class="button restore" (click)="changeMode('social'); isCaptcha=false">
            {{ 'auth.restore.login-with-new-password' | translate }}
          </button>
        </div>
      </div>

      <div *ngIf="isRestore" class="form-content">
        <div *ngIf="isChooseRestoreType">
          <p class="text sign-in">{{ 'auth.restore.password-recovery-method' | translate }}</p>
          <div class="type-block" (click)="setTypeOfRestore('email')">{{ 'auth.restore.password-recovery-with-email' | translate }}</div>
          <div class="type-block" (click)="setTypeOfRestore('mobile')">{{ 'auth.restore.password-recovery-with-phone' | translate }}</div>
        </div>
        <div *ngIf="!isChooseRestoreType">
          <p class="text sign-in">{{ 'auth.restore.password-recovery' | translate }}</p>
          <ng-container *ngIf="!isPhoneSuccess && isPhoneAvailable">
            <p class="text send-message"
               [innerHTML]="'auth.restore.password-recovery-text' | translate"></p>
            <app-auth-input-field [form]="form"
                                  type="phone"
                                  [placeholder]="'auth.phone-number'"
                                  controlName="mobile_phone"
                                  [countryCode]="selectedCountryCode"
                                  [showValidation]="showValidation"
                                  (onEnter)="doAction()">
            </app-auth-input-field>
          </ng-container>

          <ng-container *ngIf="!isPhoneSuccess && isEmailAvailable && !isPhoneAvailable">
            <p class="text send-message"
               [innerHTML]="'auth.restore.password-recovery-text-email' | translate"></p>
            <app-auth-input-field [form]="form"
                                  type="email"
                                  [placeholder]="'auth.email'"
                                  controlName="email"
                                  [showValidation]="showValidation"
                                  (onEnter)="doAction()">
            </app-auth-input-field>
          </ng-container>

          <ng-container *ngIf="isPhoneSuccess">
            <p class="text send-message"
               [innerHTML]="'auth.restore.password-recovery-success-text' | translate"></p>

            <app-auth-input-field *ngIf="isPhoneSuccess"
                                  [form]="form"
                                  type="password"
                                  [placeholder]="'auth.password'"
                                  controlName="new_password"
                                  [showValidation]="showValidation">
            </app-auth-input-field>
            <app-auth-input-field *ngIf="isPhoneSuccess"
                                  [form]="form"
                                  type="password"
                                  [placeholder]="'auth.repeat-password'"
                                  controlName="confirm_new_password"
                                  [showValidation]="showValidation">
            </app-auth-input-field>
          </ng-container>

          <p class="text send-message email-label"
             *ngIf="!isPhoneSuccess && isPhoneAvailable">
            {{form.controls['email'].value}}
          </p>

          <div class="error-content" *ngIf="action_error">{{ action_error | translate }}</div>
        </div>
        <div>
          <button type="button" class="button restore"
                  [disabled]="!actionState.default"
                  [class.error]="actionState.error" (click)="checkRestoreStep()">
            <ng-template [ngIf]="actionState.default">{{ 'auth.restore.action-continue' | translate }}</ng-template>
            <ng-template [ngIf]="actionState.progress"> . . .</ng-template>
            <ng-template [ngIf]="actionState.done">OK</ng-template>
            <ng-template [ngIf]="actionState.error">( x_x )</ng-template>
          </button>
        </div>
      </div>

      <div *ngIf="isRestored" class="form-content">
        <div></div>
        <div>
          <p class="text sign-in">{{ 'auth.restore.access-restored' | translate }}</p>
        </div>
        <div>
          <button type="button" class="button restore" (click)="changeMode('social'); isCaptcha=false">
            {{ 'auth.restore.login-with-new-password' | translate }}
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="isSignInEmail" class="question-block">
      <span class="route-btn" (click)="changeMode('restore')">{{ 'auth.signin.action4' | translate }}</span>
    </div>
  </div>

  <div class="poll" *ngIf="isPoll">
    <div class="poll-list" [class.mobile]="deviceService.isMobile()">
      <p class="poll-title">{{ 'pull.poll-title1' | translate }}</p>
      <div class="list-1">
        <section class="checkbox">
          <label class="checkbox-wrap" *ngFor="let option of pollOptions1" (mouseover)="over(option)">
            <input type="checkbox"
                   class="input"
                   (change)="checkOption($event.target.checked, option)">
            <i class="i-checkbox">
              <svg viewBox="0 0 24 19">
                <use [attr.xlink:href]="svgSetIcon('checkbox-arrow-poll')"></use>
              </svg>
            </i>
            <span>{{ 'pull.option' + option | translate }}</span>
          </label>
        </section>
      </div>

      <p class="poll-title">{{ 'pull.poll-title2' | translate }}</p>
      <div class="list-1">
        <label class="checkbox-wrap" *ngFor="let option of pollOptions2" (mouseover)="over(option)">
          <input type="checkbox"
                 class="input"
                 (change)="checkOption($event.target.checked, option)">
          <i class="i-checkbox">
            <svg viewBox="0 0 24 19">
              <use [attr.xlink:href]="svgSetIcon('checkbox-arrow-poll')"></use>
            </svg>
          </i>
          <span>{{ 'pull.option' + option | translate }}</span>
        </label>
      </div>
      <div class="poll-button">
        <on-ui-button class="secondary"
                      label="pull.start"
                      (click)="createQuiz()">
        </on-ui-button>
      </div>
    </div>
    <div class="poll-content">
      <ng-container *ngIf="selectedOption">
        <img alt="" src="{{assetsUrl('/assets/images/poll-svg/' + selectedOption + '.svg')}}"/>
        <p class="title">{{ 'pull.option' + selectedOption | translate }}</p>
        <img alt="" class="content-image" src="{{assetsUrl('/assets/images/poll/' + selectedOption + '.png')}}"/>
        <p class="content-text">{{ 'pull.content' + selectedOption | translate }}</p>
      </ng-container>
    </div>
  </div>
</div>
</div>
</ng-template>
