import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CountdownModule } from '@app/shared';
import { NearestMeetBtnComponent } from '@core/components/nearest-meet-btn/nearest-meet-btn.component';
import { TranslateModule } from '@ngx-translate/core';
import { DateFnsModule } from 'lingo2-ngx-date-fns';
import { OnUiButtonModule, OnUiCoverModule } from 'onclass-ui';

@NgModule({
  declarations: [NearestMeetBtnComponent],
  imports: [
    CommonModule,
    DateFnsModule,
    TranslateModule,
    RouterModule,
    CountdownModule,
    OnUiCoverModule,
    OnUiButtonModule,
  ],
  exports: [NearestMeetBtnComponent],
})
export class NearestMeetBtnModule {}
