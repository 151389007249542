import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ChangableComponent } from '@models/changable.component';
import { Language } from 'lingo2-models';
import { CurrencyEnum } from 'lingo2-models/dist/account/enums/currency-enum';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountService, AuthService, LanguageService, ScreenService } from '../../services';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectorComponent extends ChangableComponent implements OnInit, OnDestroy {
  @Input() set sideOpenStateSelector(flag: boolean) {
    if (flag) {
      this.openModal();
    } else {
      this.closeModal();
    }
  }
  @Output() changeOpenStateSelector = new EventEmitter<{ selectorState: boolean; label: string }>();

  public label: string;
  public language: Language;
  public languages: Language[];
  public modalOpen = false;
  public isSmallScreen = this.screenService.isSmallScreen;

  private ngUnsubscribe = new Subject();

  constructor(
    private languageService: LanguageService,
    private accountService: AccountService,
    private screenService: ScreenService,
    private authService: AuthService,
    public cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  ngOnInit() {
    combineLatest([this.languageService.languages$, this.languageService.language$])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([languages, language]) => {
        this.languages = languages;
        this.language = language;
        this.label = this.language ? this.language.code.toUpperCase() : '';
        this.changeSelector();
        this.detectChanges();
      });

    this.screenService.width$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((width) => {
      this.isSmallScreen = this.screenService.isSmallScreen;
      this.detectChanges();
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public openModal() {
    this.modalOpen = true;
    this.changeSelector();
    this.detectChanges();
  }

  public closeModal() {
    this.modalOpen = false;
    this.changeSelector();
    this.detectChanges();
  }

  public setLanguage(language: Language) {
    let currency_id = CurrencyEnum.usd;
    let ui_language = 'en';

    if (language.code) {
      ui_language = language.code;
    }

    if (language.code === 'ru') {
      currency_id = CurrencyEnum.rub;
    }

    if (!this.authService.isAuthenticated) {
      this.languageService.changeLanguage(language);
    } else {
      this.accountService
        .updateAccount({ currency_id, ui_language })
        .pipe(takeUntil(this.destroyed$))
        .subscribe((account) => {
          this.languageService.changeLanguage(language);
        });
    }
  }

  private changeSelector() {
    this.changeOpenStateSelector.emit({ selectorState: this.modalOpen, label: this.label });
  }
}
