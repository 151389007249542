import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { IconsService, PlatformService } from '@core/services';

import { OnboardingStatusesEnum as OSE, OnboardingStatusesEnum } from '../../onboarding-widget.component';
import { OnboardingWidgetService } from '../../onboarding-widget.service';

@Component({
  selector: 'app-onboarding-user-status',
  templateUrl: './onboarding-user-status.component.html',
  styleUrls: ['./onboarding-user-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingUserStatusComponent implements OnInit, OnDestroy {
  @Input() onboardingStatus: OnboardingStatusesEnum;
  @Input() set time(time: Date) {
    this._time = time;
    this.cdr.markForCheck();
    this.startIntervalRendering();
  }

  public isExpanded$ = this.onboardingWidgetService.isStatusExpanded$;
  public svgsetIcon = IconsService.svgsetIconUrl;
  public states = OnboardingStatusesEnum;
  public dateFnsOptions = { includeSeconds: true };

  private interval: any;
  private _time: Date;

  constructor(
    private onboardingWidgetService: OnboardingWidgetService,
    private platform: PlatformService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {}

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  toggleExpand(): void {
    this.onboardingWidgetService.toggleStatusExpanded();
  }

  public get moderationTime(): Date {
    return this._time;
  }

  public get isVerified(): boolean {
    const verifiedStatuses = [OSE.limited_tutor, OSE.tutor, OSE.limited_teacher, OSE.teacher];
    return this.onboardingStatus && verifiedStatuses.includes(this.onboardingStatus);
  }

  get showIcon(): boolean {
    return this.onboardingStatus && this.onboardingStatus !== OSE.guest;
  }

  private startIntervalRendering() {
    if (!this.platform.isBrowser) {
      return;
    }
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(() => {
      this.cdr.markForCheck();
    }, 60 * 1000);
  }
}
