import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ModalModule } from '@core/components/modal/modal.module';
import { SpinnersModule } from '@core/components/spinners/spinners.module';
import { TranslateModule } from '@ngx-translate/core';
import { MicrophoneSettingComponent } from '@shared/record-video/microphone-setting/microphone-setting.component';
import { OnUiButtonModule, OnUiSelectModule } from 'onclass-ui';
import { RecordVideoComponent } from './record-video.component';

@NgModule({
  declarations: [RecordVideoComponent, MicrophoneSettingComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ModalModule,
    SpinnersModule,
    FormsModule,
    OnUiSelectModule,
    OnUiButtonModule,
  ],
  exports: [RecordVideoComponent],
})
export class RecordVideoModule {}
