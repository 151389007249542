import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromClassroom from './classroom/classroom.reducer';
import * as fromClassrooms from './classrooms/classrooms.reducer';
import * as fromMeetings from './meetings/meetings.reducer';
import * as fromParticipants from './participants/participants.reducer';

export interface ClassroomsState {
  classroom: fromClassroom.ClassroomState;
  meetings: fromMeetings.MeetingsState;
  participants: fromParticipants.ClassroomParticipantsState;
  classrooms: fromClassrooms.ClassroomsListState;
}

export const reducers: ActionReducerMap<ClassroomsState> = {
  classroom: fromClassroom.reducer,
  meetings: fromMeetings.reducer,
  participants: fromParticipants.reducer,
  classrooms: fromClassrooms.reducer,
};

const thisFeatureKey = 'classrooms';
export const classroomsFeatureKey = thisFeatureKey;

export const getClassroomsState = createFeatureSelector<ClassroomsState>(thisFeatureKey);
