import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FinanceCardsPageModule } from '@app/account/finance-page/finance-cards-page/finance-cards-page.module';
import { ModalModule } from '@core/components/modal/modal.module';
import { TranslateModule } from '@ngx-translate/core';
import { HttpErrorsComponent } from '@shared/http-errors/http-errors.component';
import { OnUiButtonModule } from 'onclass-ui';

@NgModule({
  declarations: [HttpErrorsComponent],
  imports: [CommonModule, TranslateModule, FinanceCardsPageModule, ModalModule, OnUiButtonModule],
  exports: [HttpErrorsComponent],
})
export class HttpErrorsModule {}
