<section class="first-contact-school-advantages" [class.mobile]="deviceService.isMobile()">
  <div class="advantages-list">
    <div *ngFor="let item of advantagesList" class="item">
      <div class="text-wrap">
        <h3 class="advantages-title">{{ item.title | translate }}</h3>
        <p class="advantages-text">{{ item.text | translate }}</p>
      </div>
      <div class="image" [ngClass]="item.type"></div>
    </div>
  </div>
</section>
