<section class="first-contact-steps" [class.mobile]="deviceService.isMobile()">
  <h2 class="title">{{ 'first-contact.steps.title' | translate }}</h2>

  <div class="step-list">
    <div *ngFor="let step of stepsList" class="step-item">
      <span class="count">{{ step.count }}</span>
      <span class="text">{{ step.text | translate }}</span>
      <div class="image" [ngClass]="step.image"></div>
    </div>
  </div>

  <p class="result-text" [innerHTML]="'first-contact.steps.text' | translate"></p>
</section>
