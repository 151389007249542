<div class="user">
  <div class="user__picture">
    <on-ui-cover [cover]="avatar"></on-ui-cover>
    <svg class="user__status-icon" *ngIf="isAsIfTeacher">
      <use [attr.xlink:href]="svgsetIcon(statusIcons[verification_status])"></use>
    </svg>
  </div>

  <div class="user__meta">

    <div class="user__name">
      <ng-container *ngIf="isGuest; else authorized">
        <p>Unauthorized user</p>
      </ng-container>

      <ng-template #authorized>
        <ng-container *ngIf="userFullName">
          <p>{{ user.first_name }}</p>
          <p>{{ user.last_name }}</p>
        </ng-container>

        <a *ngIf="!userFullName" routerLink="/profile/about" class="link">{{ 'verification-widget.no-name' | translate }}</a>

        <div class="user__country" *ngIf="user.country">
          <img *ngIf="user.country.flag_url" [src]="user.country.flag_url" [alt]="user.country.title_native" class="flag">
          <span>{{ user.country.title_native }}</span>
        </div>

      </ng-template>
    </div>
  </div>
</div>
