import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SpinnersModule } from '@app/core/components/spinners/spinners.module';
import { AutoSaveComponent } from './auto-save.component';

@NgModule({
  declarations: [AutoSaveComponent],
  imports: [CommonModule, SpinnersModule],
  exports: [AutoSaveComponent],
})
export class AutoSaveModule {}
