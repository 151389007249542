<section class="first-contact-free-lesson" [class.mobile]="deviceService.isMobile()">
  <h2 class="title" [innerHTML]="'first-contact.first-contact-free-lesson.title' | translate"></h2>
  <div class="image-header"></div>
  <p class="text" [innerHTML]="'first-contact.first-contact-free-lesson.text' | translate"></p>

  <div class="list">
    <div *ngFor="let item of freeLessonsList" class="list-item">
      <div class="free-lessons-wrap">
        <h3 class="free-lessons-title">{{ item.title | translate }}</h3>
        <p class="free-lessons-text">{{ item.text | translate }}</p>
      </div>
      <div class="free-lessons-image" [ngClass]="item.type"></div>
    </div>
  </div>
</section>
