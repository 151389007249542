import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-onboarding-donut-chart',
  templateUrl: './onboarding-donut-chart.component.html',
  styleUrls: ['./onboarding-donut-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingDonutChartComponent implements OnInit {
  @Input() max: number;
  @Input() value: number;
  @Input() color = '#5AB3E4';

  constructor() {}

  ngOnInit() {}

  public get getValue() {
    if (this.max && this.value) {
      const percent = (100 / this.max) * this.value;
      return `${percent} ${100 - percent}`;
    }
    return '0 100';
  }
}
