<div class="image-uploader"
     (click)="showMediaModal($event)"
     [class.rectangular]="display === 'rectangular'"
     [class.wide]="display === 'wide'"
     [class.high]="display === 'high'"
     [class.medium]="display === 'medium'"
     [class.small]="display === 'small'"
     [class.error]="error">
  <div class="dropzone dropzone-container"
       [dropzone]="dropzoneConfig"
       (init)="onUploadInit()"
       (sending)="onUploadStart()"
       (error)="onUploadError()"
       (success)="onUploadSuccess($event)">

    <div class="cover"></div>

    <div class="dz-default dz-message">
      <ng-template [ngIf]="cover_uploading">
        <div class="shade"></div>
      </ng-template>
      <ng-template [ngIf]="coverUrl">
        <img [src]="coverUrl" alt="" class="cover">
      </ng-template>

      <ng-template [ngIf]="!cover_uploading && !coverUrl">
        <div class="placeholder {{textPosition}}">
          <svg class="placeholder-icon" viewBox="0 0 56 56">
            <use [attr.xlink:href]="svgSetIcon('uploader-placeholder')"></use>
          </svg>
          <div class="placeholder-text-wrap" (click)="showMediaModal($event)">
            <span class="placeholder-text">{{ 'meetings.dz-placeholder-text' | translate }}</span>&ensp;
            <span class="placeholder-text action">
              {{ 'meetings.dz-placeholder-link1' | translate }}
            </span>
          </div>
        </div>
      </ng-template>

      <ng-template [ngIf]="cover_uploading && display!=='small'">
        <spinner1></spinner1>
      </ng-template>
    </div>
    <ng-template [ngIf]="coverUrl">
      <div class="crop-actions">
        <div class="crop-action-item" [class.disabled]="!coverUrl" (click)="onCrop($event)">
          {{ 'meetings.select-area' | translate }}
        </div>
        <div class="crop-action-item" (click)="showMediaModal($event)">
          {{ 'meetings.edit' | translate }}
        </div>
        <div *ngIf="canRemove" class="crop-action-item" (click)="removeCover($event)">
          {{ 'profile-form.remove' | translate }}
        </div>
      </div>
    </ng-template>
  </div>
</div>

<app-media-file-selector *ngIf="mediaModal.opened"
                         type="image"
                         [purpose]="filePurpose"
                         (changed)="setCover($event, 'loaded')"
                         (closed)="closeMediaModal()">
</app-media-file-selector>

<app-image-crop *ngIf="showCropImage"
                [imageURL]="wideCoverUrl"
                [aspectRatio]="cropRatio"
                (changeImage)="onImageChange($event)">
</app-image-crop>
